import { AnyAction } from 'redux'
import { ConferenceViewDisplayType } from '../../entities'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IConferenceState } from './model'

const initialState: IConferenceState = {
  totalConferences: 0,
  conferences: { total: 0, items: [] },
  conferencesTimeline: { total: 0, items: [] },
  conferenceViewDisplayType: ConferenceViewDisplayType.TIMELINE,
  conferenceViewDate: null,
  vods: { total: 0, items: [] },
  lives: { total: 0, items: [] },
  recommendedConferences: [],
  conference: null,
  conferenceEmbeded: null,
  editMode: false,
  loading: false,
  loadingEmbeded: false,
  loadingVods: false,
  loadedVods: false,
  loadingLives: false,
  loadedLives: false,
  updating: false,
  loadingRecommended: false,
  filters: {},
  loadingFilters: false,
  addingConferenceToFavorites: {},
  removingConferenceFromFavorites: {},
  openExhibitorsChoice: {
    open: false,
    autoRedirect: false
  },
  sendingNotificationShowRelated: false,
  relatedContent: {
    exhibitors: [],
    items: []
  }
}

const actionHandlers: IReducerHandler<IConferenceState> = {
  [ActionsTypes.SET_CONFERENCES_TOTAL]: (
    state: IConferenceState | undefined,
    { totalConferences }: AnyAction
  ): IConferenceState => ({
    ...state!,
    totalConferences
  }),

  [ActionsTypes.SET_CONFERENCE_SEARCH]: (
    state: IConferenceState | undefined,
    { searchConferences }: AnyAction
  ): IConferenceState => ({
    ...state!,
    searchConferences
  }),

  [ActionsTypes.SET_CONFERENCE_LOADING]: (
    state: IConferenceState | undefined,
    { loading }: AnyAction
  ): IConferenceState => ({
    ...state!,
    loading
  }),

  [ActionsTypes.SET_CONFERENCE_EMBEDED_LOADING]: (
    state: IConferenceState | undefined,
    { loadingEmbeded }: AnyAction
  ): IConferenceState => ({
    ...state!,
    loadingEmbeded
  }),

  [ActionsTypes.SET_VODS_LOADING]: (
    state: IConferenceState | undefined,
    { loadingVods }: AnyAction
  ): IConferenceState => ({
    ...state!,
    loadingVods
  }),

  [ActionsTypes.SET_VODS_LOADED]: (
    state: IConferenceState | undefined,
    { loadedVods }: AnyAction
  ): IConferenceState => ({
    ...state!,
    loadedVods
  }),

  [ActionsTypes.SET_LIVES_LOADING]: (
    state: IConferenceState | undefined,
    { loadingLives }: AnyAction
  ): IConferenceState => ({
    ...state!,
    loadingLives
  }),

  [ActionsTypes.SET_LIVES_LOADED]: (
    state: IConferenceState | undefined,
    { loadedLives }: AnyAction
  ): IConferenceState => ({
    ...state!,
    loadedLives
  }),

  [ActionsTypes.SET_CONFERENCE_UPDATING]: (
    state: IConferenceState | undefined,
    { updating }: AnyAction
  ): IConferenceState => ({
    ...state!,
    updating
  }),

  [ActionsTypes.SET_CONFERENCES]: (
    state: IConferenceState | undefined,
    { conferences }: AnyAction
  ): IConferenceState => ({
    ...state!,
    conferences
  }),

  [ActionsTypes.SET_CONFERENCES_TIMELINE]: (
    state: IConferenceState | undefined,
    { conferencesTimeline }: AnyAction
  ): IConferenceState => ({
    ...state!,
    conferencesTimeline
  }),

  [ActionsTypes.SET_CONFERENCE_VIEW_DISPLAY_TYPE]: (
    state: IConferenceState | undefined,
    { conferenceViewDisplayType }: AnyAction
  ): IConferenceState => ({
    ...state!,
    conferenceViewDisplayType
  }),

  [ActionsTypes.SET_CONFERENCE_VIEW_DATE]: (
    state: IConferenceState | undefined,
    { conferenceViewDate }: AnyAction
  ): IConferenceState => ({
    ...state!,
    conferenceViewDate
  }),

  [ActionsTypes.SET_VODS]: (
    state: IConferenceState | undefined,
    { vods }: AnyAction
  ): IConferenceState => ({
    ...state!,
    vods
  }),

  [ActionsTypes.SET_LIVES]: (
    state: IConferenceState | undefined,
    { lives }: AnyAction
  ): IConferenceState => ({
    ...state!,
    lives
  }),

  [ActionsTypes.SET_RECOMMENDED_CONFERENCE_LOADING]: (
    state: IConferenceState | undefined,
    { loadingRecommended }: AnyAction
  ): IConferenceState => ({
    ...state!,
    loadingRecommended
  }),

  [ActionsTypes.SET_RECOMMENDED_CONFERENCES]: (
    state: IConferenceState | undefined,
    { recommendedConferences }: AnyAction
  ): IConferenceState => ({
    ...state!,
    recommendedConferences
  }),

  [ActionsTypes.RESET_CONFERENCE]: (state: IConferenceState | undefined): IConferenceState => ({
    ...state!,
    conference: null
  }),

  [ActionsTypes.SET_CONFERENCE]: (
    state: IConferenceState | undefined,
    { conference }: AnyAction
  ): IConferenceState => ({
    ...state!,
    conference
  }),

  [ActionsTypes.SET_CONFERENCE_EMBEDED]: (
    state: IConferenceState | undefined,
    { conferenceEmbeded }: AnyAction
  ): IConferenceState => ({
    ...state!,
    conferenceEmbeded
  }),

  [ActionsTypes.SET_CONFERENCE_EDIT_MODE]: (
    state: IConferenceState | undefined,
    { editMode }: AnyAction
  ): IConferenceState => ({
    ...state!,
    editMode
  }),

  [ActionsTypes.MERGE_CONFERENCE]: (
    state: IConferenceState | undefined,
    { conferenceUpdate }: AnyAction
  ): IConferenceState => ({
    ...state!,
    conference: state && state.conference ? { ...state.conference, ...conferenceUpdate } : null
  }),

  [ActionsTypes.MERGE_CONFERENCE_ROOM]: (
    state: IConferenceState | undefined,
    { roomUpdated }: AnyAction
  ): IConferenceState => ({
    ...state!,
    conference:
      state && state.conference
        ? {
            ...state.conference,
            rooms: state.conference.rooms.map((room) =>
              room.id === roomUpdated.id ? roomUpdated : room
            )
          }
        : null
  }),

  [ActionsTypes.SET_CONFERENCE_ERROR]: (
    state: IConferenceState | undefined,
    { error }: AnyAction
  ): IConferenceState => ({
    ...state!,
    error
  }),

  [ActionsTypes.SET_CONFERENCE_EMBEDED_ERROR]: (
    state: IConferenceState | undefined,
    { errorEmbeded }: AnyAction
  ): IConferenceState => ({
    ...state!,
    errorEmbeded
  }),

  [ActionsTypes.SET_VODS_ERROR]: (
    state: IConferenceState | undefined,
    { errorVods }: AnyAction
  ): IConferenceState => ({
    ...state!,
    errorVods
  }),

  [ActionsTypes.SET_LIVES_ERROR]: (
    state: IConferenceState | undefined,
    { errorLives }: AnyAction
  ): IConferenceState => ({
    ...state!,
    errorLives
  }),

  [ActionsTypes.SET_CONFERENCE_FILTERS]: (
    state: IConferenceState | undefined,
    { filters }: AnyAction
  ): IConferenceState => ({
    ...state!,
    filters
  }),

  [ActionsTypes.SET_LOADING_CONFERENCE_FILTERS]: (
    state: IConferenceState | undefined,
    { loadingFilters }: AnyAction
  ): IConferenceState => ({
    ...state!,
    loadingFilters
  }),

  [ActionsTypes.SET_ERROR_CONFERENCE_FILTERS]: (
    state: IConferenceState | undefined,
    { errorFilters }: AnyAction
  ): IConferenceState => ({
    ...state!,
    errorFilters
  }),

  [ActionsTypes.UPDATE_CONFERENCES_FAVORITE]: (
    state: IConferenceState | undefined,
    { conferenceId, isFollowed }: AnyAction
  ): IConferenceState => ({
    ...state!,
    recommendedConferences:
      state && state.recommendedConferences
        ? state.recommendedConferences.map((conference) =>
            conference.id === conferenceId ? { ...conference, isFollowed } : conference
          )
        : [],
    conferences:
      state && state.conferences
        ? {
            total: state.conferences.total,
            items: state.conferences.items.map((conference) =>
              conference.id === conferenceId ? { ...conference, isFollowed } : conference
            )
          }
        : { total: 0, items: [] },
    vods:
      state && state.vods
        ? {
            total: state.vods.total,
            items: state.vods.items.map((conference) =>
              conference.id === conferenceId ? { ...conference, isFollowed } : conference
            )
          }
        : { total: 0, items: [] },
    lives:
      state && state.lives
        ? {
            total: state.lives.total,
            items: state.lives.items.map((conference) =>
              conference.id === conferenceId ? { ...conference, isFollowed } : conference
            )
          }
        : { total: 0, items: [] }
  }),

  [ActionsTypes.SET_ADDING_CONFERENCE_TO_FAVORITES]: (
    state: IConferenceState | undefined,
    { conferenceId, addingConferenceToFavorites }: AnyAction
  ): IConferenceState => ({
    ...state!,
    addingConferenceToFavorites:
      state && state.addingConferenceToFavorites
        ? { ...state.addingConferenceToFavorites, [conferenceId]: addingConferenceToFavorites }
        : {}
  }),

  [ActionsTypes.SET_ERROR_ADDING_CONFERENCE_TO_FAVORITES]: (
    state: IConferenceState | undefined,
    { errorAddingConferenceToFavorites }: AnyAction
  ): IConferenceState => ({
    ...state!,
    errorAddingConferenceToFavorites
  }),

  [ActionsTypes.SET_REMOVING_CONFERENCE_FROM_FAVORITES]: (
    state: IConferenceState | undefined,
    { conferenceId, removingConferenceFromFavorites }: AnyAction
  ): IConferenceState => ({
    ...state!,
    removingConferenceFromFavorites:
      state && state.addingConferenceToFavorites
        ? { ...state.addingConferenceToFavorites, [conferenceId]: removingConferenceFromFavorites }
        : {}
  }),

  [ActionsTypes.SET_ERROR_REMOVING_CONFERENCE_FROM_FAVORITES]: (
    state: IConferenceState | undefined,
    { errorRemovingConferenceFromFavorites }: AnyAction
  ): IConferenceState => ({
    ...state!,
    errorRemovingConferenceFromFavorites
  }),

  [ActionsTypes.UPDATE_CONFERENCE_ROOM_USERS_ONLINE]: (
    state: IConferenceState | undefined,
    { userIds, isOnline }: AnyAction
  ): IConferenceState => ({
    ...state!,
    conference:
      state && state.conference
        ? {
            ...state.conference,
            rooms: state.conference.rooms.map((room) => {
              if (room.userPresence && userIds.indexOf(room.userPresence.user.id) > -1) {
                return {
                  ...room,
                  userPresence: {
                    user: room.userPresence.user,
                    isOnline
                  }
                }
              } else {
                return room
              }
            })
          }
        : null
  }),

  [ActionsTypes.SET_OPEN_EXHIBITORS_CHOICE]: (
    state: IConferenceState | undefined,
    { openExhibitorsChoice }: AnyAction
  ): IConferenceState => ({
    ...state!,
    openExhibitorsChoice
  }),

  [ActionsTypes.SET_SENDING_NOTIFICATION_SHOW_RELATED]: (
    state: IConferenceState | undefined,
    { sendingNotificationShowRelated }: AnyAction
  ): IConferenceState => ({
    ...state!,
    sendingNotificationShowRelated
  }),

  [ActionsTypes.SET_RELATED_CONTENT]: (
    state: IConferenceState | undefined,
    { relatedContent }: AnyAction
  ): IConferenceState => ({
    ...state!,
    relatedContent
  })
}

export const reducer = createReducer(initialState, actionHandlers)
