import {
  IChatChannel,
  IMessage,
  INotificationParams,
  MagicStreamAnnounceSegment
} from '../entities/chat'
import settings from '../settings'
import { apiPatchRequest, apiPostRequest, apiRequest } from './requests'

export const getChannels = async () => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/chat/channels?eventGroupId=${settings.eventGroupId}`
  )
  return { items: response['hydra:member'] as IChatChannel[], total: response['hydra:totalItems'] }
}

export const getGroupChannels = async () => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/chat/channels?eventGroupId=${settings.eventGroupId}`
  )
  return (response['hydra:member'].reverse() as IChatChannel[]).filter((channel) => !!channel.group)
  // .sort((c1, c2) => new Date(c2.updateDate).getTime() - new Date(c1.updateDate).getTime())
}

export const getUserChannels = async () => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/chat/channels?eventGroupId=${settings.eventGroupId}`
  )
  return (response['hydra:member'].reverse() as IChatChannel[]).filter((channel) => !!channel.user)
  // .sort((c1, c2) => new Date(c2.updateDate).getTime() - new Date(c1.updateDate).getTime())
}

export const patchReadChannel = async (contactId: number) => {
  await apiPatchRequest(`${settings.url.api}/chat/channels/user/${contactId}`, {})
}

export const getMessages = async (userId: number, lastMessageId?: number) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/chat/messages?user_id=${userId}&group_id=${settings.eventGroupId}${lastMessageId ? `&cursor=${lastMessageId}` : ''
    }`
  )
  return response['hydra:member'] as IMessage[]
}

export const postMessage = async (message: string, recipientIds: number[]) => {
  const response = await apiPostRequest<IMessage>(`${settings.url.api}/chat/messages`, {
    message,
    recipientIds
  })
  return response
}

export const postInvitation = async (
  message: string,
  url: string,
  recipientIds: number[],
  roomNumber?: number
) => {
  const response = await apiPostRequest<IMessage>(`${settings.url.api}/chat/messages`, {
    message,
    recipientIds,
    embed: JSON.stringify({
      [roomNumber ? 'queue_invitation' : 'invitation']: {
        expiration_date: new Date().getTime() + 30000,
        url,
        room_id: roomNumber || undefined, // TODO: remove when ready
        table_queue_id: roomNumber || undefined
      }
    })
  })
  return response
}

export const postResponse = async (message: string, anwser: 0 | 1 | 2, recipientIds: number[]) => {
  const response = await apiPostRequest<IMessage>(`${settings.url.api}/chat/messages`, {
    message,
    recipientIds,
    embed: JSON.stringify({ response: { value: anwser } })
  })
  return response
}

export const postNotification = async (
  groupId: string | number,
  message: string,
  type: string,
  params?: INotificationParams
) => {
  const response = await apiPostRequest<IMessage>(`${settings.url.api}/chat/messages`, {
    message,
    group: `/groups/${groupId}`,
    embed: JSON.stringify({ toast: { type, params } })
  })
  return response
}

export const postAnnouncement = async (
  groupId: string | number,
  message: string,
  params?: INotificationParams,
  segmentAll?: boolean,
  isHighPriority?: boolean
) => {
  const response = await apiPostRequest<IMessage>(`${settings.url.api}/announcement/`, {
    group: `/groups/${groupId}`,
    title: message,
    url: params?.url || '',
    priority: isHighPriority ? 'high' : 'low',
    segment: segmentAll
      ? MagicStreamAnnounceSegment.SEGMENT_ALL
      : MagicStreamAnnounceSegment.SEGMENT_GROUP_LIVE_AND_MATCHING
  })
  return response
}

export const postTakeAppointment = async (
  message: string,
  recipientIds: number[],
  date: Date,
  url?: string,
  messageOnly?: boolean,
  endDate?: Date
) => {
  // const response = await apiPostRequest<IMessage>(`${settings.url.api}/chat/messages`, {
  //   message,
  //   recipientIds
  // })
  // return response
  const response = await Promise.all(
    recipientIds.map(
      (recipientId) =>
        new Promise((resolve, reject) => {
          apiPostRequest<IMessage>(`${settings.url.api}/chat/messages`, {
            message,
            recipientIds: [recipientId],
            embed: messageOnly
              ? undefined
              : JSON.stringify({
                appointment: {
                  datetime: date.toISOString(),
                  datetimeEnd: endDate ? endDate.toISOString() : undefined,
                  url
                }
              })
          })
            .then(resolve)
            .catch(reject)
        })
    )
  )
  return response
}
