import styled from '@emotion/styled'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import React from 'react'

const AppLoading: React.FC<CircularProgressProps> = ({ ...props }) => {
  return (
    <Container>
      <CircularProgress {...props} />
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

export default AppLoading
