import { Locale } from 'date-fns'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import formatRelative from 'date-fns/formatRelative'
import { enUS, fr } from 'date-fns/locale'
import { useSelector } from 'react-redux'
import { LanguageKeys } from '../entities'
import { IRootState } from '../redux'

export const useDateFns = () => {
  const locale = useSelector((state: IRootState) => state.appState.locale)

  const locales: { [key: string]: Locale } = { fr, enUS }

  return {
    format: (dateStr: string, formatStr: string) =>
      dateStr &&
      format(new Date(dateStr), formatStr, {
        locale: locales[locale]
      }),
    formatRelativeToday: (dateStr: string) =>
      dateStr &&
      formatRelative(new Date(dateStr).setHours(0, 0, 0, 0), new Date().setHours(0, 0, 0, 0), {
        locale: locales[locale],
        weekStartsOn: 1
      })
        .replace(locale === LanguageKeys.fr ? ' à 00:00' : ' at 12:00 AM', '')
        .replace(locale === LanguageKeys.fr ? ' prochain' : '', ''),
    formatDistanceToNow: (dateStr: string) =>
      dateStr &&
      formatDistanceToNow(new Date(dateStr), {
        locale: locales[locale],
        addSuffix: true
      }),
    differenceInMinutes: (dateLeft: string, dateRight: string) =>
      dateLeft && differenceInMinutes(new Date(dateLeft), new Date(dateRight))
  }
}
