import useGTM from '@elgorditosalsero/react-gtm-hook'
import { ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IContact } from '../../../entities'
import { useThunkDispatch } from '../../../hooks'
import { addFavoriteContact } from '../../../store/participant/thunks'

type IProps = {
  contact: IContact
  onClose?: (event: React.MouseEvent<EventTarget>) => void
}

const ActionAddContact: React.FC<IProps> = ({ contact, onClose }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const dispatch = useThunkDispatch()

  const handleAddFavoriteContact = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    if (contact.id) {
      sendDataToGTM({ event: 'add-person', cardType: 'Contact Popper' })
      dispatch(addFavoriteContact(contact.id, contact.userPresence.isOnline))
    }
    onClose && onClose(event)
  }

  const memoNotFavoriteContact = useMemo(() => !contact.isContact, [contact])

  return (
    <>
      {/* // add as favorite contact */}
      {memoNotFavoriteContact && (
        <MenuItem onClick={handleAddFavoriteContact}>
          <ListItemIcon>
            <PersonAddIcon
              fontSize="large"
              titleAccess={intl.formatMessage({
                id: 'titleAccess.ContactPopperAction.PersonAddIcon'
              })}
            />
          </ListItemIcon>
          <Typography variant="inherit">
            <FormattedMessage id="contactpopper.button_follow" />
          </Typography>
        </MenuItem>
      )}
    </>
  )
}

export default ActionAddContact
