import styled from '@emotion/styled'
import { InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface ISelectSortByProps {
  value?: string
  onChange: (value: string) => void
  date?: boolean
  recommandation?: boolean
  alphabetic?: boolean
  popularity?: boolean
}

const SelectSortBy: FC<ISelectSortByProps> = ({
  value,
  onChange,
  date,
  recommandation,
  alphabetic,
  popularity
}) => {
  const intl = useIntl()

  const [list, setList] = useState<{ label: string; value: string }[]>([])

  useEffect(() => {
    const l = []
    if (date) {
      l.push({ label: intl.formatMessage({ id: 'sort.by.date' }), value: 'date' })
    }
    if (recommandation) {
      l.push({
        label: intl.formatMessage({ id: 'sort.by.recommendation' }),
        value: 'recommandation'
      })
    }
    if (alphabetic) {
      l.push({ label: intl.formatMessage({ id: 'sort.by.alphabetic' }), value: 'alphabetic' })
    }
    if (popularity) {
      l.push({ label: intl.formatMessage({ id: 'sort.by.popularity' }), value: 'popularity' })
    }

    setList(l)
  }, [intl, date, recommandation, alphabetic, popularity])

  return (
    <StyledSelectSortBy>
      <InputLabel id="sort-by">
        <FormattedMessage id="sort.by" />
      </InputLabel>
      <Select
        labelId="sort-by"
        value={(list.find((orderBy) => orderBy.value === value) && value) || ''}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          onChange(event.target.value as string)
        }
      >
        {list.map((orderBy) => (
          <MenuItem key={orderBy.value} value={orderBy.value}>
            {orderBy.label}
          </MenuItem>
        ))}
      </Select>
    </StyledSelectSortBy>
  )
}

const StyledSelectSortBy = styled.div`
  display: flex;
  flex-direction: column;
`

export default SelectSortBy
