import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
// import { ReactComponent as UserFavorite } from '../../assets/images/icons/user-favorite.svg'
import { IExhibitor } from '../../entities/exhibitor'
import { useDetectImageExist, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setPageReferral } from '../../store/gtm'
import { BACKGROUND_PRIMARY_OPACITY_8, WHITE } from '../../theme/colors'
import ExhibitorFavoriteButton from '../exhibitor/ExhibitorFavoriteButton'
import LiveCounterSvg from '../globals/LiveCounterSvg/LiveCounterSvg'
import LiveTag from '../globals/LiveTag/LiveTag'

interface IExhibitorCardProps {
  exhibitor: IExhibitor
  goBackList?: boolean
}

const ExhibitorCard: React.FC<IExhibitorCardProps> = ({ exhibitor, goBackList }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const image = useDetectImageExist(exhibitor.imageUrl, false, '&size=small')
  const dispatch = useThunkDispatch()

  const onClickCard = () => {
    dispatch(setPageReferral(`${pageReferral}::card-exhibitor`))
    sendDataToGTM({ event: 'click-card', cardType: 'Exposant', cardName: `${exhibitor.name}` })
    browserHistory.push({
      pathname: `/exposant/${exhibitor.id}`,
      state: { goBack: goBackList || false }
    })
  }

  return (
    <StyledCard elevation={1} onClick={onClickCard}>
      <StyledCardActionArea>
        {exhibitor.enableFeatureRooms && (
          <StyledFeatureRooms>
            <span>
              <FormattedMessage id="feature.rooms" />
            </span>
          </StyledFeatureRooms>
        )}
        <StyledCardMediaContainer>
          {/*<StyledCardMediaBg image={image} title={exhibitor.shortBody} />*/}
          <StyledCardMedia image={image} title={exhibitor.shortBody} />
        </StyledCardMediaContainer>
        {exhibitor.live && <LiveTag />}
        <LiveCounterSvg
          display={exhibitor.nbUsers > 0}
          contentText={intl.formatMessage({ id: 'total.visitor' }, { visitors: exhibitor.nbUsers })}
        />
      </StyledCardActionArea>
      <StyledCardContent>
        <StyledText>
          <StyledTitle>{exhibitor.name}</StyledTitle>
          <StyledDescription>{exhibitor.shortBody}</StyledDescription>
        </StyledText>
        <StyledBottomText>
          {/*<StyledUsersFavorite>*/}
          {/*  <UserFavorite />*/}
          {/*  {intl.formatMessage(*/}
          {/*    { id: 'person.interested' },*/}
          {/*    { count: Math.floor(Math.random() * 100) }*/}
          {/*  )}*/}
          {/*</StyledUsersFavorite>*/}
          <ExhibitorFavoriteButton exhibitor={exhibitor} />
        </StyledBottomText>
      </StyledCardContent>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  & {
    width: calc(100% - 16px);
    margin: 8px;
    height: calc(100% - 16px);
    cursor: pointer;
  }
`

const StyledCardActionArea = styled(CardActionArea)`
  & {
    position: relative;
  }
`

const StyledCardMediaContainer = styled.div`
  & {
    position: relative;
    height: 138px;
  }
`

const StyledFeatureRooms = styled.div< { right?: boolean }>`
  & {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 31px;
    ${props => props.right ? 'right: -31px' : 'left: -31px'};
    border-bottom: 25px solid ${BACKGROUND_PRIMARY_OPACITY_8};
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    height: 0;
    width: 150px;
    transform: ${props => props.right ? 'rotate(45deg)' : 'rotate(-45deg)'};
    z-index: 1;

    & > span {
      color: ${WHITE};
      padding-top: 5px;
    }
  } 
`

// const StyledCardMediaBg = styled(CardMedia)`
//   & {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center center;
//     filter: blur(4px);
//   }
// `

const StyledCardMedia = styled(CardMedia)`
  & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
`

const StyledCardContent = styled(CardContent)`
  & {
    position: relative;
    padding: 12px;
    display: flex;
    flex-direction: column;

    &:hover {
      cursor: pointer;
    }
  }
`

const StyledText = styled.div`
  & {
    flex-grow: 1;
  }
`

const StyledTitle = styled.h3`
  & {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    max-width: calc(100% - 30px);
    height: 72px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const StyledDescription = styled.div`
  & {
    margin-top: 6px;
    font-size: 14px;
    line-height: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 28px;
  }
`

const StyledBottomText = styled.div`
  & {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`

// const StyledUsersFavorite = styled.div`
//   & {
//     color: ${GRAY};
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 18px;
//
//     svg {
//       margin-right: 5px;
//       color: ${GRAY};
//     }
//   }
// `

export default ExhibitorCard
