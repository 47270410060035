import styled from '@emotion/styled'
import { alpha } from '@material-ui/core'
import RssFeedIcon from '@material-ui/icons/RssFeed'
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import React, { FC, useMemo } from 'react'
import { AnimatedList } from 'react-animated-list'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { MagicStreamType, ROLES } from '../../entities'
import { IRootState } from '../../redux'
import { PRIMARY_COLOR } from '../../theme'
import { BLACK_BLUE, LIGHT_GRAY } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import CssAnimationInsert from '../CssAnimationInsert'
import Loading from '../Loading'
import StreamAnnounce from './StreamAnnounce'
import StreamAnnouncePremium from './StreamAnnouncePremium'
import StreamAppointment from './StreamAppointment'
import StreamCall from './StreamCall'
import StreamConference from './StreamConference'
import StreamInvitation from './StreamInvitation'
import StreamNewMessage from './StreamNewMessage'
import StreamTableQueue from './StreamTableQueue'
import StreamToast from './StreamToast'

interface IStreamsPanelContentProps {
  priorityHigh: boolean
  onlyAppointment: boolean
  streamType: string
  onlyLast?: boolean
}

const StreamsPanelContent: FC<IStreamsPanelContentProps> = ({
  priorityHigh,
  onlyAppointment,
  streamType,
  onlyLast
}) => {
  const user = useSelector((state: IRootState) => state.appState.user)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const magicStreams = useSelector((state: IRootState) => state.chatState.magicStreams)
  const loadingMagicStreams = useSelector(
    (state: IRootState) => state.chatState.loadingMagicStreams
  )

  const isAnimator = useMemo(
    () =>
      user &&
      user.roles &&
      ((eventCo && eventCo.isBroadcaster) ||
        user.roles.indexOf(ROLES.ROLE_ADMIN) > -1 ||
        user.roles.indexOf(ROLES.ROLE_SUPER_ADMIN) > -1),
    [user, eventCo]
  )

  const hasMagicStreams = useMemo(() => magicStreams.length > 0, [magicStreams])

  const isHidden = useMemo(
    () =>
      magicStreams.length > 0 &&
      magicStreams.reduce(
        (prev, next) =>
          // tslint:disable-next-line: no-bitwise
          prev &
          ((onlyAppointment && next.streamType.indexOf(MagicStreamType.APPOINTMENT) === -1) ||
            (priorityHigh && next.priority === 'low') ||
            (streamType !== 'all' && next.streamType.indexOf(streamType) === -1)
            ? 1
            : 0),
        1
      ) === 1,
    [streamType, priorityHigh, onlyAppointment, magicStreams]
  )

  const magicStreamsMemo = useMemo(() => {
    let filtered = magicStreams.filter((stream) => stream.streamType !== MagicStreamType.TOAST_ERROR || !isAnimator)
    if (onlyLast) {
      filtered = filtered.slice(0, 9) // keep only 10 last
    }
    return filtered
  }, [onlyLast, magicStreams, isAnimator])

  return (
    <StyledContent hasMagicStreams={hasMagicStreams && !isHidden}>
      {loadingMagicStreams && <Loading position="center" />}
      {!loadingMagicStreams && !hasMagicStreams && (
        <StyledEmpty>
          <RssFeedIcon />
          <span>
            <FormattedMessage id="magic.streams.empty" />
          </span>
        </StyledEmpty>
      )}
      {!loadingMagicStreams && hasMagicStreams && (
        <AnimatedList>
          {magicStreamsMemo.map((stream, index) => (
            <StyledMagicStreams
              key={index}
              animation={index === 0}
              hidden={
                (onlyAppointment &&
                  stream.streamType.indexOf(MagicStreamType.APPOINTMENT) === -1) ||
                (priorityHigh && stream.priority === 'low') ||
                (streamType !== 'all' && stream.streamType.indexOf(streamType) === -1)
              }
            >
              {stream.streamType === MagicStreamType.INVITATION_RECEIVED ? (
                <StreamCall stream={stream} />
              ) : stream.streamType === MagicStreamType.INVITATION_SENT ? (
                <StreamInvitation stream={stream} />
              ) : stream.streamType === MagicStreamType.REMINDER_CONFERENCE ||
                stream.streamType === MagicStreamType.SUGGEST_CONFERENCE ? (
                <StreamConference stream={stream} />
              ) : stream.streamType === MagicStreamType.APPOINTMENT ? (
                <StreamAppointment stream={stream} />
              ) : stream.streamType === MagicStreamType.NEW_MESSAGE ? (
                <StreamNewMessage stream={stream} />
              ) : stream.streamType === MagicStreamType.TABLE_QUEUE ? (
                <StreamTableQueue stream={stream} />
              ) : stream.content && stream.streamType === MagicStreamType.ANNOUNCE ? (
                <StreamAnnounce stream={stream} />
              ) : stream.content && stream.streamType === MagicStreamType.ANNOUNCE_PREMIUM ? (
                <StreamAnnouncePremium stream={stream} />
              ) : stream.content &&
                stream.streamType !== MagicStreamType.ANNOUNCE &&
                stream.streamType !== MagicStreamType.ANNOUNCE_PREMIUM ? (
                <StreamToast stream={stream} />
              ) : null}
            </StyledMagicStreams>
          ))}
        </AnimatedList>
      )}
      {!loadingMagicStreams && hasMagicStreams && isHidden && (
        <StyledEmpty>
          <WbSunnyIcon htmlColor="#F28C38" fontSize="large" />
        </StyledEmpty>
      )}
    </StyledContent>
  )
}

const StyledContent = styled.div<{ hasMagicStreams: boolean }>`
  position: relative;

  ${(props) =>
    props.hasMagicStreams
      ? `
          &:before {
            content: '';
            position: absolute;
            left: 45px;
            background-color: ${LIGHT_GRAY};
            top: 0;
            height: 100%;
            width: 1px;
            z-index: 0;
            transition: all .3s;
          }
          &:hover {
            &:before {
              background-color: ${PRIMARY_COLOR};
            }
          }
        `
      : ``}
`

const StyledMagicStreams = styled.div<{ animation: boolean }>`
  & {
    position: relative;
    padding: 3px 5px 3px 10px;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: ${BORDER_RADIUS_CARD};
    ${(props) => (props.animation ? CssAnimationInsert : '')}

    &:hover {
      transition: all 0.3s;
      background-color: ${alpha(BLACK_BLUE, 0.05)};

      &:after {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`

const StyledEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  white-space: normal;
  text-align: center;
`

export default StreamsPanelContent
