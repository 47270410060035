import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IDeleteQuestion, INewQuestion, IUpdateQuestion } from '../../entities/questions'
import { useMagicStream, useThunkDispatch, useTooltip } from '../../hooks'
import { IRootState } from '../../redux'
import {
  addQuestion,
  deleteQuestion,
  loadQuestions,
  newQuestion,
  removeAllQuestion,
  setCountUp,
  setQuestionError
} from '../../store/questions'
import { RED } from '../../theme/colors'
import consoleUtils from '../../utils/consoleUtils'
import Loading from '../Loading'
import ConfirmDialog from '../modals/ConfirmDialog'
import InputText from '../ui/InputText/InputText'
import QuestionBox from './QuestionList/QuestionBox'
import QuestionList from './QuestionList/QuestionList'

type IQuestionsProps = {
  groupId: number
  editMode?: boolean
  title?: string
  handleLeave?: () => void
  handleRenameRoom?: (value: string) => void
}

const Questions: React.FC<IQuestionsProps> = ({
  groupId,
  editMode,
  title,
  handleLeave,
  handleRenameRoom
}) => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const [text, setText] = useState('')
  const [confirmDeleteAll, setConfirmDeleteAll] = useState(false)
  const [scrollBottom, setScrollBottom] = useState(false)
  const user = useSelector((state: IRootState) => state.appState.user)
  const questions = useSelector((state: IRootState) => state.questionsState.questions)
  const deleting = useSelector((state: IRootState) => state.questionsState.deleting)
  const error = useSelector((state: IRootState) => state.questionsState.error)
  const dispatch = useThunkDispatch()

  const { anchorRef: anchorRefDelete, setOpen: setOpenDelete, Tooltip: TooltipDelete } = useTooltip(
    intl.formatMessage({ id: 'question.button.deleteAll' })
  )

  const { anchorRef: anchorRefExit, setOpen: setOpenExit, Tooltip: TooltipExit } = useTooltip(
    intl.formatMessage({ id: 'actions.leave.room' }, { title })
  )

  const { anchorRef: anchorRefTitle, setOpen: setOpenTitle, Tooltip: TooltipTitle } = useTooltip(
    intl.formatMessage({ id: 'action.edit.body' })
  )

  const handleTextChange = useCallback(
    (e: any) => {
      if (e.keyCode === 13 && text.length > 0 && user) {
        dispatch(newQuestion(text, groupId))
        setText('')
        setScrollBottom(true)
      } else {
        setText(e.target.value)
      }
    },
    [text, user, groupId, dispatch]
  )

  const deleteAllQuestion = () => {
    dispatch(removeAllQuestion(groupId))
  }

  useEffect(() => {
    if (window.pusher && user && groupId) {
      const channel = window.pusher.channel(`group-${groupId}`)
      if (channel) {
        channel.bind('newQuestion', (data: INewQuestion) => {
          if (data.author.id !== user.id) {
            consoleUtils.log(`[newQuestion] Message reçu sur group ${groupId}=`, data)
            dispatch(
              addQuestion({
                id: data.id,
                haveVoted: false,
                countUpVotes: 0,
                groupId: `/groups/${groupId}`,
                question: data.message,
                user: data.author,
                createDate: data.createDate
              })
            )
          }
        })
        channel.bind('updateQuestion', (data: IUpdateQuestion) => {
          consoleUtils.log(`[updateQuestion] Message reçu sur group ${groupId}=`, data)
          dispatch(setCountUp(data.id, data.votes))
        })
        channel.bind('removeQuestion', (data: IDeleteQuestion) => {
          consoleUtils.log(`[removeQuestion] Message reçu sur group ${groupId}=`, data)
          dispatch(deleteQuestion(data.id))
        })
      }
    }
    return () => {
      if (window.pusher && groupId) {
        const channel = window.pusher.channel(`group-${groupId}`)
        if (channel) {
          channel.unbind('question')
          channel.unbind('questionUpdate')
        }
      }
    }
  }, [user, groupId, dispatch])

  useEffect(() => {
    if (groupId) {
      dispatch(loadQuestions(groupId))
    }
  }, [groupId, dispatch])

  useEffect(() => {
    if (error) {
      magicStream.error(error)
      dispatch(setQuestionError(undefined))
    }
  }, [error, magicStream, dispatch])

  if (deleting) return <Loading />

  return (
    <QuestionsContainer>
      <QuestionsToolbar>
        <QuestionsToolbarLeft>
          {title && (
            <span>
              {editMode && handleRenameRoom ? (
                <InputText
                  inputRef={anchorRefTitle}
                  onMouseOver={() => setOpenTitle(true)}
                  onMouseOut={() => setOpenTitle(false)}
                  onClick={() => setOpenTitle(false)}
                  onChangeValue={(value: string) => handleRenameRoom(value)}
                  placeholder={intl.formatMessage({
                    id: 'room.name.placeholder'
                  })}
                  value={title}
                  multiline={false}
                  inputProps={{ maxLength: 15 }}
                  hideEditable
                />
              ) : (
                <>{title}</>
              )}
              {editMode && <TooltipTitle />}
            </span>
          )}
        </QuestionsToolbarLeft>
        <QuestionsToolbarRight>
          {editMode && questions.length > 0 && (
            <StyledIconDelete
              ref={anchorRefDelete}
              onMouseOver={() => setOpenDelete(true)}
              onMouseOut={() => setOpenDelete(false)}
              onClick={() => setConfirmDeleteAll(true)}
            >
              <DeleteIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.Question.DeleteIconAll' })}
              />
            </StyledIconDelete>
          )}
          {handleLeave && (
            <IconButton
              ref={anchorRefExit}
              onMouseOver={() => setOpenExit(true)}
              onMouseOut={() => setOpenExit(false)}
              onClick={handleLeave}
            >
              <ExitToAppIcon
                titleAccess={intl.formatMessage({
                  id: 'titleAccess.OpenTok.ExitToAppIcon'
                })}
              />
            </IconButton>
          )}
          <TooltipDelete />
          <TooltipExit />
        </QuestionsToolbarRight>
      </QuestionsToolbar>
      <QuestionList
        questions={questions}
        editMode={editMode}
        scrollBottom={scrollBottom}
        setScrollBottom={setScrollBottom}
      />
      <QuestionBox text={text} handleTextChange={handleTextChange} />
      <ConfirmDialog
        open={confirmDeleteAll}
        message={intl.formatMessage({ id: 'confirm.delete.all.questions' })}
        okEvent={() => {
          setConfirmDeleteAll(false)
          deleteAllQuestion()
        }}
        cancelEvent={() => {
          setConfirmDeleteAll(false)
        }}
      />
    </QuestionsContainer>
  )
}

const QuestionsContainer = styled.div`
  height: 100%;
`

const QuestionsToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
`

const QuestionsToolbarLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
`

const QuestionsToolbarRight = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledIconDelete = styled(IconButton)`
  svg {
    color: ${RED};
  }
`

export default Questions
