import { AnyAction, Dispatch } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IBaseRootState } from '..'
import {
  followConference,
  getConference as getApiConference,
  getConferences as getApiConferences,
  getConferencesTimeline as getApiConferencesTimeline,
  getFiltersEvent as getApiFiltersConference,
  getLives as getApiLives,
  getRoomsOccupationConference as getApiRoomsOccupation,
  getVods as getApiVods,
  triggerPopin,
  unFollowConference,
  updateConference as updateApiConference
} from '../../api'
import { IConferenceUpdate } from '../../entities'
import { ILocationUser } from '../../entities/pusher'
import consoleUtils from '../../utils/consoleUtils'
import pusherUtils from '../../utils/pusherUtils'
import { loadAgenda, setGroupId } from '../app'
import { setLocations } from '../stand'
import {
  mergeConference,
  setAddingConferenceToFavorites,
  setConference,
  setConferenceEmbeded,
  setConferenceEmbededError,
  setConferenceEmbededLoading,
  setConferenceError,
  setConferenceFilters,
  setConferenceLoading,
  setConferences,
  setConferencesSearch,
  setConferencesTimeline,
  setConferenceUpdating,
  setErrorAddingConferenceToFavorites,
  setErrorConferenceFilters,
  setErrorRemovingConferenceFromFavorites,
  setLives,
  setLivesError,
  setLivesLoaded,
  setLivesLoading,
  setLoadingConferenceFilters,
  setRecommendedConferenceLoading,
  setRecommendedConferences,
  setRemovingConferenceFromFavorites,
  setVods,
  setVodsError,
  setVodsLoaded,
  setVodsLoading,
  updateConferencesFavorite
} from './actions'
import { IConferenceFilters } from './model'

export const getConferences =
  (filters: IConferenceFilters) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setConferenceLoading(true))
    if (filters.location) {
      dispatch(setConferences({ total: 0, items: [] }))
      dispatch(setConferencesSearch(filters.location))
    }
    try {
      // get conferences data
      const conferences = await getApiConferences(
        filters.page,
        filters.search,
        filters.sortBy,
        filters.keyword,
        filters.type,
        filters.followed,
        filters.matching,
        false,
        filters.keywordsX
      )
      dispatch(setConferences(conferences))
    } catch (e) {
      dispatch(setConferenceError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setConferenceLoading(false))
  }

export const getConferencesTimeline =
  (viewDate: string | null) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setConferenceLoading(true))
    try {
      // get conferences timeline data
      const conferences = await getApiConferencesTimeline(viewDate)
      dispatch(setConferencesTimeline(conferences))
    } catch (e) {
      dispatch(setConferenceError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setConferenceLoading(false))
  }

export const getLives = (reload?: boolean) => async (dispatch: Dispatch<AnyAction>) => {
  if (!reload) dispatch(setLivesLoading(true))
  try {
    // get lives data
    const lives = await getApiLives()
    dispatch(setLives(lives))
    dispatch(setLivesLoaded(true))
  } catch (e) {
    dispatch(setLivesError((e as any).message))
    consoleUtils.error(e)
  }
  if (!reload) dispatch(setLivesLoading(false))
}

export const getVods = (reload?: boolean) => async (dispatch: Dispatch<AnyAction>) => {
  if (!reload) dispatch(setVodsLoading(true))
  try {
    // get vods data
    const vods = await getApiVods()
    dispatch(setVods(vods))
    dispatch(setVodsLoaded(true))
  } catch (e) {
    dispatch(setVodsError((e as any).message))
    consoleUtils.error(e)
  }
  if (!reload) dispatch(setVodsLoading(false))
}

export const getRecommendedConferences = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setRecommendedConferenceLoading(true))
  try {
    // get conferences data
    const recommendedConferences = await getApiConferences(
      1,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      50
    )
    dispatch(setRecommendedConferences(recommendedConferences.items))
  } catch (e) {
    dispatch(setConferenceError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setRecommendedConferenceLoading(false))
}

export const getConference =
  (conferenceId: string, userId: number) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setConferenceLoading(true))
    try {
      // get conference data
      const conference = await getApiConference(conferenceId)

      // pusher subscribes
      pusherUtils.subscribe(`group-${conference.group.id}`)
      pusherUtils.subscribe(`private-group-${conference.group.id}-user-${userId}`)

      // set conference and groupid after subscribe group
      dispatch(setConference(conference))
      dispatch(setGroupId(conference.group.id))

      // get locations of user
      const roomsOccupation = await getApiRoomsOccupation(conference.group.id)
      const locations: ILocationUser[] = []
      roomsOccupation.forEach((roomOccupation) => {
        roomOccupation.chairOccupations.forEach((chairOccupation) => {
          if (chairOccupation.id === 'lobby') {
            // lobby
            chairOccupation.users.forEach((user) => {
              if (user.id !== userId) {
                locations.push({
                  user,
                  roomId: '-1',
                  chairId: '-1',
                  broadcaster: false
                })
              }
            })
          } else if (!chairOccupation.isFree) {
            if (chairOccupation.users[0].id !== userId) {
              locations.push({
                user: chairOccupation.users[0],
                roomId: roomOccupation.id,
                chairId: chairOccupation.id,
                broadcaster: false
              })
            }
          } else {
            // room broadcast / non broadcaster
            chairOccupation.users.forEach((user) => {
              if (user.id !== userId) {
                locations.push({
                  user,
                  roomId: roomOccupation.id,
                  chairId: chairOccupation.id,
                  broadcaster: false
                })
              }
            })
            // room broadcast / broadcaster
            chairOccupation.broadcasters.forEach((user) => {
              if (user.id !== userId) {
                locations.push({
                  user,
                  roomId: roomOccupation.id,
                  chairId: chairOccupation.id,
                  broadcaster: true
                })
              }
            })
          }
        })
      })
      consoleUtils.log('[CONFERENCE, setLocations] locations=', locations)
      dispatch(setLocations(locations))
    } catch (e) {
      dispatch(setConferenceError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setConferenceLoading(false))
  }

export const loadConferenceEmbeded =
  (conferenceId: string) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setConferenceEmbededLoading(true))
    try {
      // get conference data
      const conference = await getApiConference(conferenceId)
      dispatch(setConferenceEmbeded(conference))
    } catch (e) {
      dispatch(setConferenceEmbededError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setConferenceEmbededLoading(false))
  }

export const updateConference =
  (conferenceId: number, conference: IConferenceUpdate) =>
  async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setConferenceUpdating(true))
    try {
      // update conference data
      const conferenceUpdated = await updateApiConference(conferenceId, conference)
      dispatch(mergeConference(conferenceUpdated))
    } catch (e) {
      dispatch(setConferenceError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setConferenceUpdating(false))
  }

export const loadFiltersConference = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoadingConferenceFilters(true))
  try {
    // load filters for conference
    const filtersAPIResult = await getApiFiltersConference()
    dispatch(setConferenceFilters(filtersAPIResult))
  } catch (e) {
    dispatch(setErrorConferenceFilters((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingConferenceFilters(false))
}

export const addConferenceToFavorite =
  (conferenceId: number) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setAddingConferenceToFavorites(conferenceId, true))
    try {
      await followConference(conferenceId)
      dispatch(updateConferencesFavorite(conferenceId, true))
      dispatch(loadAgenda())
    } catch (e) {
      dispatch(setErrorAddingConferenceToFavorites((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setAddingConferenceToFavorites(conferenceId, false))
  }

export const removeConferenceFromFavorite =
  (conferenceId: number) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setRemovingConferenceFromFavorites(conferenceId, true))
    try {
      await unFollowConference(conferenceId)
      dispatch(updateConferencesFavorite(conferenceId, false))
      dispatch(loadAgenda())
    } catch (e) {
      dispatch(setErrorRemovingConferenceFromFavorites((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setRemovingConferenceFromFavorites(conferenceId, false))
  }

export const triggerPopinOnConference =
  (conferenceId: number, conference: IConferenceUpdate) =>
  async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    try {
      consoleUtils.log('Prigger poppin for conferenceId=', conferenceId)
      await triggerPopin(conferenceId, conference)
    } catch (e) {
      consoleUtils.error(e)
    }
  }
