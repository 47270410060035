import { css, keyframes } from '@emotion/react'
import { PRIMARY } from '../theme/colors'

const insertItem = keyframes`
  0% {
    transform: translateY(-50px);
    opacity: 0.0;
    background-color: ${PRIMARY};
  }
  100% {
    transform: translateY(0);
    opacity: 1.0;
    background-color: transparent;
  }
`

const CssAnimationInsert = css`
  & {
    animation: ${insertItem} 500ms;
  }
`

export default CssAnimationInsert
