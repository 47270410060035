import useGTM from '@elgorditosalsero/react-gtm-hook'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AppLoading from '../components/ui/AppLoading/AppLoading'
import { useMagicStream, useThunkDispatch } from '../hooks'
import { browserHistory, IRootState } from '../redux'
import { initializeApp as initializeAppRedux } from '../store/app/thunks'
import { loadContacts } from '../store/participant/thunks'
import { setEventError } from '../store/settings'
import { formatName } from '../utils'

const WithLoggedInUserData: React.FC = ({ children }) => {
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [started, setStarted] = useState(false)
  const token = useSelector((state: IRootState) => state.authState.token)
  const user = useSelector((state: IRootState) => state.appState.user)
  const loadingApp = useSelector((state: IRootState) => state.appState.loading)
  const error = useSelector((state: IRootState) => state.appState.error)
  const loadingEvent = useSelector((state: IRootState) => state.settingsState.loadingEvent)
  const errorEvent = useSelector((state: IRootState) => state.settingsState.errorEvent)
  const [gtmUserInfo, setGtmUserInfo] = useState(false)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    if (token && !user && !loadingApp && !loadingEvent && !error) {
      dispatch(initializeAppRedux())
    }
    if (loadingApp) {
      setStarted(true)
    }
  }, [token, user, loadingApp, loadingEvent, error, dispatch])

  useEffect(() => {
    if (token && user && !loadingApp && dispatch) {
      dispatch(loadContacts())
    }
  }, [token, user, loadingApp, dispatch])

  useEffect(() => {
    if (!gtmUserInfo && user && !loadingApp) {
      setGtmUserInfo(true)
      sendDataToGTM({
        userName: formatName(user).full,
        userEnterprise: user.partner_id.name,
        userFunction: user.function
      })
    }
  }, [user, gtmUserInfo, loadingApp, setGtmUserInfo, sendDataToGTM])

  useEffect(() => {
    if (error) {
      magicStream.error(error)
      browserHistory.push('/erreur')
    }
  }, [error, magicStream, dispatch])

  useEffect(() => {
    if (errorEvent) {
      magicStream.error(errorEvent)
      dispatch(setEventError(undefined))
    }
  }, [errorEvent, magicStream, dispatch])

  return (
    <>
      {started && !loadingApp && !loadingEvent && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          {children}
        </motion.div>
      )}
      {(loadingApp || loadingEvent || !user) && <AppLoading />}
    </>
  )
}

export default WithLoggedInUserData
