import { IContact } from '../../entities'
import {
  ICall,
  IChatChannel,
  IConversation,
  IMagicStream,
  IMessage,
  INotification
} from '../../entities/chat'

export enum ActionsTypes {
  SET_LOADED_CONVERSATIONS = 'SET_LOADED_CONVERSATIONS',
  SET_LOADING_CONVERSATIONS = 'SET_LOADING_CONVERSATIONS',
  SET_ERROR_CONVERSATIONS = 'SET_ERROR_CONVERSATIONS',
  SET_LOADING_CONVERSATION = 'SET_LOADING_CONVERSATION',
  SET_ERROR_CONVERSATION = 'SET_ERROR_CONVERSATION',
  SET_LOADING_MESSAGES = 'SET_LOADING_MESSAGES',
  SET_ERROR_MESSAGES = 'SET_ERROR_MESSAGES',
  SET_OPEN_CHAT = 'SET_OPEN_CHAT',
  SET_MINIMIZE_CHAT = 'SET_MINIMIZE_CHAT',
  SET_ERROR_SENDING = 'SET_ERROR_SENDING',
  SET_SENDING = 'SET_SENDING',
  SET_SENDED = 'SET_SENDED',
  SET_SENDED_LIVE = 'SET_SENDED_LIVE',
  SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION',
  SET_CHANNELS_TO_BIND = 'SET_CHANNELS_TO_BIND',
  SET_LOADING_CHANNELS = 'SET_LOADING_CHANNELS',
  SET_ERROR_CHANNELS = 'SET_ERROR_CHANNELS',
  ADD_CONVERSATION = 'ADD_CONVERSATION',
  ADD_MESSAGE_TO_CONVERSATION = 'ADD_MESSAGE_TO_CONVERSATION',
  ADD_MESSAGES_TO_CONVERSATION = 'ADD_MESSAGES_TO_CONVERSATION',
  INCREMENT_UNREAD = 'INCREMENT_UNREAD',
  DECREMENT_UNREAD = 'DECREMENT_UNREAD',
  SET_CALL = 'SET_CALL',
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  SET_INVITATION_IN_PROGRESS = 'SET_INVITATION_IN_PROGRESS',
  SET_MAGIC_STREAMS = 'SET_MAGIC_STREAMS',
  ADD_MAGIC_STREAM = 'ADD_MAGIC_STREAM',
  SET_LOADING_MAGIC_STREAMS = 'SET_LOADING_MAGIC_STREAMS',
  SET_ERROR_MAGIC_STREAMS = 'SET_ERROR_MAGIC_STREAMS',
  SET_MAGIC_STREAMS_USER = 'SET_MAGIC_STREAMS_USER',
  SET_LOADING_MAGIC_STREAMS_USER = 'SET_LOADING_MAGIC_STREAMS_USER',
  SET_ERROR_MAGIC_STREAMS_USER = 'SET_ERROR_MAGIC_STREAMS_USER',
  SET_NEW_MESSAGE_CLICKED = 'SET_NEW_MESSAGE_CLICKED',
  SET_CALL_ENDED = 'SET_CALL_ENDED',
  SET_CONTACT_APPOINTMENT = 'SET_CONTACT_APPOINTMENT',
  SET_OPEN_NOTIFICATIONS = 'SET_OPEN_NOTIFICATIONS'
}

export interface IChatState {
  loadedConversations: boolean
  loadingConversations: boolean
  errorConversations?: string
  loadingConversation: boolean
  errorConversation?: string
  loading: boolean
  errorMessages?: string
  openChat: boolean
  minimizeChat: boolean
  sending: boolean
  errorSending?: string
  sended: boolean
  sendedLive: boolean
  invitationInProgress: boolean
  currentConversation: number
  conversations: IConversation[]
  channelsToBind: IChatChannel[]
  loadingChannels: boolean
  errorChannels?: string
  unread: IMessage[]
  call: ICall
  notification?: INotification | null
  magicStreams: IMagicStream[]
  loadingMagicStreams: boolean
  errorMagicStreams?: string
  magicStreamsUser: IMagicStream[]
  loadingMagicStreamsUser: boolean
  errorMagicStreamsUser?: string
  newMessageClicked: { [key: number]: boolean }
  callEnded: { [key: number]: boolean }
  contactAppointment?: IContact | null
  openNotifications: boolean
}
