import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'

const LiveTag = () => {
  return (
    <StyledLiveCounterSvg>
      <FormattedMessage id="tag.live" />
    </StyledLiveCounterSvg>
  )
}

const StateBlinkLive = keyframes`
  0%   {background-color:#e81a1a; box-shadow:0 0 10px #da6464;}
  50%  {background-color:#da6464; box-shadow:0 0 0 #da6464;}
  100% {background-color:#e81a1a; box-shadow:0 0 10px #da6464;}
`

const StyledLiveCounterSvg = styled.span`
  & {
    position: absolute;
    top: 12px;
    left: 12px;
    padding: 5px 15px;
    color: #fff;
    background-color: #d03d3d;
    animation: ${StateBlinkLive} 3s infinite;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    border-radius: ${BORDER_RADIUS_CARD};
    z-index: 1;
  }
`

export default LiveTag
