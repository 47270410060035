import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { alpha, AppBar, IconButton, Toolbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import React, { FC, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useSound from 'use-sound'
import newSfx from '../../../assets/sounds/new_magic_stream.mp3'
import { IContact, IMessage, IRoom, IUser } from '../../../entities'
import {
  IMagicStream,
  MagicStreamStatus,
  MagicStreamType,
  NotificationType
} from '../../../entities/chat'
import { useEffectOnce, useIsMobile, useMagicStream, useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { loadAgenda, setOpenMenu, setOpenMenuContact } from '../../../store/app'
import {
  addMagicStream,
  initConversations,
  loadChannels,
  newMessageReceived,
  parseMessage,
  readMessage,
  setCurrentConversation,
  setErrorConversations,
  setLoadedConversations,
  setMinimizeChat,
  setOpenChat,
  setOpenNotifications
} from '../../../store/chat'
import { addRoomQueue, removeRoomQueue } from '../../../store/room'
import { PRIMARY_COLOR } from '../../../theme'
import { BLACK_BLUE, GRAY_ICON, WHITE } from '../../../theme/colors'
import { HEADER_HEIGHT } from '../../../theme/sizes'
import { formatName } from '../../../utils'
import consoleUtils from '../../../utils/consoleUtils'
import pusherUtils from '../../../utils/pusherUtils'
import { breakpoints, breakpointsValues } from '../../breakpoints'
import ElevationScroll from '../../ElevationScroll'
import AnnounceRender from '../AnnounceRender'
import HeaderActions from '../HeaderActions/HeaderActions'
import UserMenu from '../UserMenu/UserMenu'
import HeaderLogo from './HeaderLogo'

const Header: FC = () => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [playNew] = useSound(newSfx)
  const isMobile = useIsMobile(breakpointsValues.md)
  const user = useSelector((state: IRootState) => state.appState.user)
  const openMenu = useSelector((state: IRootState) => state.appState.openMenu)
  const channels = useSelector((state: IRootState) => state.chatState.channelsToBind)
  const openChat = useSelector((state: IRootState) => state.chatState.openChat)
  const unread = useSelector((state: IRootState) => state.chatState.unread)
  const minimizeChat = useSelector((state: IRootState) => state.chatState.minimizeChat)
  const loadedConversations = useSelector(
    (state: IRootState) => state.chatState.loadedConversations
  )
  const errorConversations = useSelector((state: IRootState) => state.chatState.errorConversations)
  const conversations = useSelector((state: IRootState) => state.chatState.conversations)
  const currentConversation = useSelector(
    (state: IRootState) => state.chatState.currentConversation
  )
  const dispatch = useThunkDispatch()

  const toggleOpenMenu = () => {
    sendDataToGTM({ event: 'click-header-burger-button' })
    dispatch(setOpenChat(false))
    dispatch(setOpenMenuContact(false))
    dispatch(setOpenMenu(!openMenu))
  }

  const toggleOpenMenuChat = useCallback(
    (unreadId?: number) => {
      sendDataToGTM({ event: 'click-header-chat' })
      dispatch(setOpenMenu(false))
      dispatch(setOpenMenuContact(false))
      if (openChat && minimizeChat) {
        dispatch(setMinimizeChat(false))
      } else {
        dispatch(setOpenChat(!openChat))
        dispatch(setMinimizeChat(false))
      }
      if (unreadId) {
        dispatch(setCurrentConversation(unreadId))
      } else if (unread && unread.length > 0) {
        dispatch(setCurrentConversation(unread[0].author.id))
      } else if (conversations && conversations.length > 0 && conversations[0].contact.id) {
        dispatch(setCurrentConversation(conversations[0].contact.id))
      }
    },
    [conversations, minimizeChat, openChat, unread, sendDataToGTM, dispatch]
  )

  useEffect(() => {
    if (window.pusher && user) {
      // subscribe to channel for chat
      pusherUtils.subscribe(`private-user-${user.id}`)
      // listen for 'message' event on all channels
      window.pusher.bind('message', (data: IMessage) => {
        consoleUtils.log(`[GLOBAL] Message reçu de ${data.author.id}=`, data)
        if (data.embed) {
          dispatch(
            parseMessage(
              user,
              data.author,
              data.message,
              data.embed,
              () => magicStream.info(intl.formatMessage({ id: 'invitation.accepted' })),
              () => magicStream.warning(intl.formatMessage({ id: 'invitation.refused' }))
            )
          )
        } else if (
          user.id !== data.author.id &&
          (currentConversation !== data.author.id || !openChat || minimizeChat)
        ) {
          dispatch(
            newMessageReceived(
              {
                id: data.author.id,
                userPresence: {
                  isOnline: true,
                  user: data.author
                }
              },
              conversations,
              data
            )
          )
          magicStream.newMessage(
            intl.formatMessage(
              { id: 'new.message.received' },
              {
                user: `<b>${formatName(data.author).full}</b>`,
                message: `${data.message.substring(0, 20)}${data.message.length > 20 ? '...' : ''}`
              }
            ),
            () => toggleOpenMenuChat(data.author.id)
          )
          playNew()
          // dispatch(setOpenNotifications(true))
        }
      })
      // listen for 'magicStream' event on all channels
      window.pusher.bind('magicStream', (newMagicStream: IMagicStream) => {
        consoleUtils.log(`>>> MagicStream received=`, newMagicStream)
        if (
          newMagicStream.streamType === MagicStreamType.ANNOUNCE_PREMIUM &&
          `${newMagicStream.objectId}` === `${settings.eventGroupId}` &&
          newMagicStream.priority === 'high'
        ) {
          const toastContent = (
            <AnnounceRender
              notification={{
                type: NotificationType.GLOBAL_ANNOUNCE,
                params: {
                  url:
                    newMagicStream.ctas?.video ||
                    newMagicStream.ctas?.internal ||
                    newMagicStream.ctas?.external,
                  user: newMagicStream.creatorUser,
                  createDate: newMagicStream.createDate
                },
                message: newMagicStream.content as string
              }}
            />
          )
          toast(toastContent, {
            position: 'top-center',
            autoClose: 150000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            bodyStyle: {
              color: PRIMARY_COLOR
            }
          })
        }
        dispatch(addMagicStream(newMagicStream))
        // reload agend
        if (newMagicStream.streamType === MagicStreamType.APPOINTMENT) {
          dispatch(loadAgenda())
        }
        // play sound when new magic stream
        if (
          [
            MagicStreamType.ANNOUNCE,
            MagicStreamType.ANNOUNCE_PREMIUM,
            MagicStreamType.INVITATION_RECEIVED
          ].indexOf(newMagicStream.streamType) > -1 ||
          ((newMagicStream.streamType === MagicStreamType.APPOINTMENT ||
            newMagicStream.streamType === MagicStreamType.TABLE_QUEUE) &&
            (newMagicStream.status === MagicStreamStatus.INVITED ||
              newMagicStream.status === MagicStreamStatus.RUNNING))
        ) {
          playNew()
        }
        if (newMagicStream.priority === 'high') {
          dispatch(setOpenNotifications(true))
        }
      })
      // listen for 'queue' event on all channels
      window.pusher.bind(
        'VideoRoomQueue',
        (eventQueue: { id: number; lifecycle: string; user: IUser; videoRoom: IRoom }) => {
          if (eventQueue.lifecycle === 'create') {
            dispatch(
              addRoomQueue(eventQueue.id, {
                room_id: eventQueue.videoRoom.id,
                user: eventQueue.user
              })
            )
            // playNew()
          } else if (eventQueue.lifecycle === 'delete') {
            dispatch(removeRoomQueue(eventQueue.id))
          }
        }
      )
      // window.pusher.bind('enterRoom', (event: any) => {
      //   // FIXME: cannot catch event 'enterRoom'
      //   console.log('[enterRoom] event=', event)
      // })
    }
    return () => {
      if (window.pusher && user) {
        window.pusher.unbind('message')
        window.pusher.unbind('magicStream')
        window.pusher.unbind('VideoRoomQueue')
      }
    }
  }, [
    user,
    intl,
    currentConversation,
    openChat,
    minimizeChat,
    conversations,
    magicStream,
    toggleOpenMenuChat,
    playNew,
    dispatch
  ])

  useEffect(() => {
    if (openChat && !minimizeChat && currentConversation > -1) {
      const conversation = conversations.find((conv) => conv.contact.id === currentConversation)
      if (conversation && conversation.messages.length > 0) {
        dispatch(readMessage(currentConversation))
      }
    }
  }, [openChat, minimizeChat, currentConversation, conversations, dispatch])

  useEffectOnce(() => {
    dispatch(loadChannels())
  })

  useEffect(() => {
    if (!loadedConversations && channels.length) {
      const conversationsToInit = channels
        .map((channel) => ({
          id: channel.user.id,
          userPresence: {
            isOnline: false,
            user: channel.user
          }
        }))
        .reverse() as IContact[]
      if (conversationsToInit.length > 0) {
        dispatch(initConversations(conversationsToInit))
      } else {
        dispatch(setLoadedConversations(true))
      }
    }
  }, [loadedConversations, channels, dispatch])

  useEffect(() => {
    if (errorConversations) {
      magicStream.error(errorConversations)
      dispatch(setErrorConversations(undefined))
    }
  }, [errorConversations, magicStream, dispatch])

  return (
    <ElevationScroll>
      <StyledAppBar position="fixed" color="inherit">
        <StyledToolbar>
          <StyledLeftToolbar>
            <StyledMenuButton
              color="inherit"
              aria-label={intl.formatMessage({ id: 'ariaLabel.Header.MenuButton' })}
              onClick={toggleOpenMenu}
              edge="start"
            >
              {openMenu ? (
                <CloseIcon
                  fontSize="large"
                  titleAccess={intl.formatMessage({ id: 'titleAccess.Header.CloseIcon' })}
                />
              ) : (
                <MenuIcon
                  fontSize="large"
                  titleAccess={intl.formatMessage({ id: 'titleAccess.Header.MenuIcon' })}
                />
              )}
            </StyledMenuButton>
            <HeaderLogo />
          </StyledLeftToolbar>
          <StyledRightToolbar openMenu={openMenu}>
            <HeaderActions />
            {!isMobile && <UserMenu />}
          </StyledRightToolbar>
          <StyledContainerButtonMobile>
            {!isMobile && <HeaderActions />}
            <UserMenu />
          </StyledContainerButtonMobile>
        </StyledToolbar>
      </StyledAppBar>
    </ElevationScroll>
  )
}

const StyledAppBar = styled(AppBar)`
  & {
    z-index: 1203;
    height: ${HEADER_HEIGHT};
    flex-direction: row;
    box-shadow: 0 1px 2px 0 ${alpha(BLACK_BLUE, 0.1)};
  }
`

const StyledMenuButton = styled(IconButton)`
  & {
    margin-right: 8px;

    @media (min-width: ${breakpoints.lg}) {
      display: none;
    }
    svg {
      color: ${GRAY_ICON};
    }
  }
`

const StyledToolbar = styled(Toolbar)`
  & {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`

const StyledLeftToolbar = styled.div`
  & {
    display: flex;
    align-items: center;
  }
  a {
    text-decoration: none;
    color: ${WHITE};
    display: flex;
    margin-right: 10px;

    @media (min-width: ${breakpoints.lg}) {
      margin-right: 80px;
    }
  }
`

const StyledRightToolbar = styled.div<{ openMenu: boolean }>`
  & {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: ${breakpoints.lg}) {
      position: fixed;
      left: ${(props) => `${props.openMenu ? '0' : '-100%'}`};
      bottom: 0;
      width: 100%;
      justify-content: center;
      background-color: ${WHITE};
      transition: all 0.3s;
    }
  }
`

const StyledContainerButtonMobile = styled.div`
  & {
    display: flex;
    flex-direction: row;

    @media (min-width: ${breakpoints.lg}) {
      display: none;
    }
  }
`

export default Header
