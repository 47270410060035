import styled from '@emotion/styled'
import { alpha, Button } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgEntry } from '../../../assets/images/icons/entry.svg'
import { ReactComponent as SvgExit } from '../../../assets/images/icons/exit.svg'
import { IChairs, IRoom, RoomType } from '../../../entities'
import { browserHistory, IRootState } from '../../../redux'
import { PRIMARY_COLOR } from '../../../theme'
import { BLACK_BLUE, WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import { formatName } from '../../../utils'
import InvitePerson from '../InvitePerson'
import MyAvatar from '../MyAvatar/MyAvatar'
import UserAvatar from '../UserAvatar/UserAvatar'
import UserAvatarGroup from '../UserAvatarGroup/UserAvatarGroup'

interface IRoomContentProps {
  index: number
  room: IRoom
  divRef: React.RefObject<HTMLDivElement>
  chairs: IChairs
  active: number
  isEditable: boolean
  onClick: (room: IRoom, index: number) => void
}

const RoomContent: React.FC<IRoomContentProps> = ({
  index,
  room,
  divRef,
  chairs,
  active,
  isEditable,
  onClick
}) => {
  const intl = useIntl()
  const [isSmall, setSmall] = useState(false)
  const user = useSelector((state: IRootState) => state.appState.user)
  const locations = useSelector((state: IRootState) => state.standState.locations)
  const chairId = useSelector((state: IRootState) => state.standState.chairId)
  const broadcaster = useSelector((state: IRootState) => state.roomState.broadcaster)
  const aroundUsersCount = useSelector(
    (state: IRootState) => state.participantState.aroundUsersCount
  )

  const memoBroadcasterLocation = useMemo(
    () =>
      locations.filter((location) => `${location.roomId}` === `${room.id}` && location.broadcaster),
    [room, locations]
  )

  const memoStackedLocation = useMemo(
    () =>
      locations.filter(
        (location) => `${location.roomId}` === `${room.id}` && !location.broadcaster
      ),
    [room, locations]
  )

  const memoTotalStackedLocation = useMemo(
    () =>
      aroundUsersCount -
      locations.filter((location) => `${location.roomId}` !== `${room.id}` || location.broadcaster)
        .length,
    [room, aroundUsersCount, locations]
  )

  // detect if show user at office
  const memoShowOfficeLocation = useMemo(
    () =>
      // room with user
      room.userPresence &&
      // is user connected room & not enter in
      ((room.userPresence.user.id === user?.id && active !== index) ||
        // or other user room
        (room.userPresence.user.id !== user?.id &&
          // and not on the stand
          (locations.filter((location) => `${room.userPresence!.user.id}` === `${location.user.id}`)
            .length === 0 ||
            // or on the stand but not entered
            locations.filter(
              (location) =>
                `${room.userPresence!.user.id}` === `${location.user.id}` &&
                (location.roomId === '-1' || `${location.roomId}` !== `${room.id}`)
            ).length > 0))),
    [room, active, index, user, locations]
  )

  // detect if user is at office
  const memoIsAtOffice = useMemo(
    () =>
      // room with user
      room.userPresence &&
      // is user connected room
      ((room.userPresence.user.id === user?.id && (!chairId || active === index)) ||
        // or other user room
        (room.userPresence.user.id !== user?.id &&
          // and on the stand
          locations.filter(
            (location) =>
              `${room.userPresence!.user.id}` === `${location.user.id}` &&
              (location.roomId === '-1' || `${location.roomId}` === `${room.id}`)
          ).length > 0)),
    [room, active, index, user, chairId, locations]
  )

  const handleResize = useCallback(() => {
    if (divRef) {
      const target = divRef.current as any
      if (target) {
        setSmall(target.offsetHeight * target.offsetWidth < 120 * 120)
      }
    }
  }, [divRef])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <StyledRoomContent
      stackedAvatarGroup={
        (room.type === RoomType.Broadcast ||
          room.type === RoomType.JitsiBroadcast ||
          ((room.type === RoomType.Zoom ||
            room.type === RoomType.ZoomMeeting ||
            room.type === RoomType.ZoomWebinar) &&
            chairs[room.roomClassName].length === 0)) &&
        active > -1
      }
    >
      {(active === -1 || active !== index) && (
        <StyledRoomTitle isSmall={isSmall} className="room-title">
          {room.userPresence
            ? formatName(room.userPresence.user).limited
            : room.title || room.roomClassName}
        </StyledRoomTitle>
      )}
      {(!room.userPresence || !isSmall) && (
        <StyledButtonEntryExit small={isSmall}>
          <Button
            className={active === index ? 'room-button-leave' : 'room-button-enter'}
            size="small"
            variant="contained"
            startIcon={active === index ? <SvgExit /> : <SvgEntry />}
            color={active === index ? 'inherit' : 'primary'}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              event.preventDefault()
              event.stopPropagation()
              onClick(room, index)
            }}
          >
            {!isSmall &&
              intl.formatMessage({
                id: active === index ? 'actions.leave.room' : 'room.click'
              })}
          </Button>
        </StyledButtonEntryExit>)}
      {false && isEditable && room.type === RoomType.PslEvent && (
        <Button
          className="room-button-bo"
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            browserHistory.push(`/conference/${room.sessionId}`)
          }}
        >
          <FormattedMessage id="bo.conference" />
        </Button>
      )}
      {memoShowOfficeLocation && room.userPresence && (
        <UserAvatar
          user={room.userPresence.user}
          showDetails
          showModerator
          showMe
          isOffice
          isOnline={room.userPresence.isOnline}
          isNotHere={!memoIsAtOffice}
          isSmall={isSmall}
          disabledAnimation={!memoIsAtOffice}
          onEnterOffice={() => onClick(room, index)}
        />
      )}
      {active === index && !room.userPresence && <InvitePerson />}
      {/* broadcaster avatargroup */}
      {(room.type === RoomType.Broadcast || room.type === RoomType.JitsiBroadcast) && (
        <StyledBroadcasterUserAvatarGroup
          max={5}
          spacing={10}
          total={memoBroadcasterLocation.length}
        >
          {active === index && broadcaster && <MyAvatar showModerator showMe hasRollIn />}
          {memoBroadcasterLocation.map((location, indexLocation) => (
            <UserAvatar
              key={indexLocation}
              user={location.user}
              showDetails
              showModerator
              showMe
              showNote
              hasRollIn
              isOnline
              isSmall={isSmall}
            />
          ))}
        </StyledBroadcasterUserAvatarGroup>
      )}
      {/* stacked avatargroup */}
      {(room.type === RoomType.Broadcast ||
        room.type === RoomType.JitsiBroadcast ||
        ((room.type === RoomType.PslEvent ||
          room.type === RoomType.Zoom ||
          room.type === RoomType.ZoomMeeting ||
          room.type === RoomType.ZoomWebinar) &&
          chairs[room.roomClassName].length === 0)) && (
          <StyledViewerUserAvatarGroup max={5} spacing={15} total={memoTotalStackedLocation}>
            {active === index && !broadcaster && <MyAvatar showModerator showMe hasRollIn />}
            {memoStackedLocation.map((location, indexLocation) => (
              <UserAvatar
                key={indexLocation}
                user={location.user}
                showDetails
                showModerator
                showMe
                showNote
                hasRollIn
                isOnline
                isSmall={isSmall}
              />
            ))}
          </StyledViewerUserAvatarGroup>
        )}
    </StyledRoomContent>
  )
}

const StyledButtonEntryExit = styled.div<{ small: boolean }>`
  & {    
    z-index: 1;
    .MuiButton-root {
      ${props => props.small ? 'min-width: 40px;' : ''} 
    }
    .MuiButton-startIcon {
      ${props => props.small ? 'margin: 0 !important;' : ''} 
    }
    svg {
      color: ${WHITE};
      width: 20px;
      height: 20px;
    }
  }
`

const StyledRoomContent = styled.div<{ stackedAvatarGroup: boolean }>`
  & {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${(props) => (props.stackedAvatarGroup ? 'flex-start' : 'center')};
    height: 100%;
    width: 100%;
  }
`

const StyledRoomTitle = styled.span<{ isSmall: boolean }>`
  & {
    font-size: ${(props) => (props.isSmall ? '10px' : '16px')};
    text-shadow: ${(props) => (props.isSmall ? 'none' : `2px 2px 3px ${alpha(BLACK_BLUE, 0.14)}`)};
    font-weight: bold;
    background-color: rgba(244, 247, 252, 0.3);
    padding: 0 4px;
    border-radius: ${BORDER_RADIUS_CARD};
    white-space: nowrap;
  }
`

const StyledBroadcasterUserAvatarGroup = styled(UserAvatarGroup)`
  & {
    position: absolute;
    top: -40px;

    & .MuiAvatarGroup-avatar {
      border: 2px solid ${PRIMARY_COLOR};
    }
  }
`

const StyledViewerUserAvatarGroup = styled(UserAvatarGroup)`
  & {
    position: absolute;
    bottom: 10px;
  }
`

export default RoomContent
