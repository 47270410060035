import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as EnFlagIcon } from '../../../assets/images/flags/en.svg'
import { ReactComponent as FrFlagIcon } from '../../../assets/images/flags/fr.svg'
import { LanguageKeys } from '../../../entities'
import { useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import { changeLocale } from '../../../store/app'
import asyncLocalStorage from '../../../utils/asyncLocalstorage'

interface SelectLanguageHeaderProps {
  onValueChange?: (value: LanguageKeys) => void
}

const SelectLanguageHeader: React.FC<SelectLanguageHeaderProps> = ({ onValueChange }) => {
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const locale = useSelector((state: IRootState) => state.appState.locale)
  const user = useSelector((state: IRootState) => state.appState.user)
  const open = Boolean(anchorEl)
  const id = open ? 'select-language-popover' : undefined
  const dispatch = useThunkDispatch()

  const languageChange = async (value: LanguageKeys) => {
    await dispatch(changeLocale(asyncLocalStorage, value, user))
    if (onValueChange) onValueChange(value)
    window.location.reload()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <StyledSelectLanguage>
      <StyledIconButton onClick={handleClick}>
        {locale === LanguageKeys.fr ?
          <FrFlagIcon title={intl.formatMessage({ id: 'language.fr' })} />
          :
          <EnFlagIcon title={intl.formatMessage({ id: 'language.en' })} />
        }
        <ExpandMoreIcon fontSize="small" />
      </StyledIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {locale === LanguageKeys.fr ?
          <StyledIconButton onClick={() => languageChange(LanguageKeys.en)}>
            <EnFlagIcon title={intl.formatMessage({ id: 'language.en' })} />
          </StyledIconButton>
          :
          <StyledIconButton onClick={() => languageChange(LanguageKeys.fr)}>
            <FrFlagIcon title={intl.formatMessage({ id: 'language.fr' })} />
          </StyledIconButton>
        }
      </Popover>
    </StyledSelectLanguage>
  )
}

const StyledSelectLanguage = styled.div`
  display: flex;
  align-items: center;
`

const StyledIconButton = styled(IconButton)`
  & svg {
    height: 30px;
  }
`

export default SelectLanguageHeader
