import styled from '@emotion/styled'
import { Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useEffectOnce, useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { getConferencesTimeline, setConferenceError } from '../../store/conference'
import { PRIMARY } from '../../theme/colors'
import ConferenceCard from '../cards/ConferenceCard'
import LoadingCard from '../LoadingCard'
import Slider from '../ui/Slider/Slider'
import Title from '../ui/Title/Title'

const ConferenceTimeline = () => {
  const magicStream = useMagicStream()
  const conferencesTimeline = useSelector(
    (state: IRootState) => state.conferenceState.conferencesTimeline
  )
  const conferenceViewDate = useSelector(
    (state: IRootState) => state.conferenceState.conferenceViewDate
  )
  const error = useSelector((state: IRootState) => state.conferenceState.error)
  const loading = useSelector((state: IRootState) => state.conferenceState.loading)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const dispatch = useThunkDispatch()

  const eventFinished = useMemo(() => eventCo?.endDate && new Date() > new Date(eventCo?.endDate), [
    eventCo
  ])

  const memoTimelineConferences = useMemo(
    () =>
      conferencesTimeline && conferencesTimeline.items.length
        ? conferencesTimeline.items.filter((item) => item.events.length > 0)
        : [],
    [conferencesTimeline]
  )

  const conferenceViewTime = useMemo(() => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    if (
      conferenceViewDate &&
      memoTimelineConferences &&
      memoTimelineConferences.length > 0 &&
      eventCo &&
      eventCo.dates.length > 0
    ) {
      const date = new Date(conferenceViewDate)
      date.setHours(0, 0, 0, 0)
      if (today.getTime() < date.getTime() && conferenceViewDate === eventCo.dates[0]) {
        // event not started and on first day
        return memoTimelineConferences[0].key
      } else if (today.getTime() === date.getTime()) {
        // event current day, calcul the good hour
        // format dates of timeline into date object
        const timelineDates = memoTimelineConferences
          .filter((timeline) => !!timeline.key)
          .map((timeline) => {
            const timelineDate = new Date(conferenceViewDate)
            const hoursMins = timeline.key.split('h')
            timelineDate.setHours(parseInt(hoursMins[0], 10), parseInt(hoursMins[1], 10) || 0, 0, 0)
            return timelineDate
          })
        // new date with current time
        const todayTime = new Date()
        if (todayTime.getTime() <= timelineDates[0].getTime()) {
          // first hour of day
          return memoTimelineConferences[0].key
        } else if (timelineDates[timelineDates.length - 1].getTime() <= todayTime.getTime()) {
          // last hour of day
          return memoTimelineConferences[memoTimelineConferences.length - 1].key
        } else {
          // day in progress
          for (let i = 0; i < timelineDates.length - 1; i++) {
            if (
              timelineDates[i].getTime() <= todayTime.getTime() &&
              todayTime.getTime() > timelineDates[i + 1].getTime()
            ) {
              return memoTimelineConferences[i].key
            }
          }
        }
        // always first hour by default
        return memoTimelineConferences[0].key
      } else {
        // event is finished, always unselected
        return false
      }
    }
  }, [eventCo, conferenceViewDate, memoTimelineConferences])

  useEffect(() => {
    dispatch(getConferencesTimeline(conferenceViewDate))
  }, [conferenceViewDate, dispatch])

  useEffect(() => {
    if (error) {
      magicStream.error(error)
      dispatch(setConferenceError(undefined))
    }
  }, [error, magicStream, dispatch])

  useEffectOnce(() => {
    window.scrollTo(0, 0)
  })

  return (
    <TimelineConferencePage>
      <StyledTitle>
        <Title
          variant="h2"
          titleId={`conferenceTimeline.title_list${eventFinished ? '.after' : ''}`}
        />
        {/* <StyledSubTitle>
          <FormattedMessage
            id={`conferenceTimeline.subtitle_list${eventFinished ? '.after' : ''}`}
          />
        </StyledSubTitle> */}
      </StyledTitle>
      <TimelineConferenceContent>
        {loading &&
          Array(5)
            .fill(0)
            .map((value, i) => (
              <TimelineItem key={i}>
                <Typography variant="h3">{` `}</Typography>
                <Slider>
                  {Array(5)
                    .fill(0)
                    .map((conference, ii) => (
                      <div key={ii}>
                        <LoadingCard cardType="conference" />
                      </div>
                    ))}
                </Slider>
              </TimelineItem>
            ))}
        {!loading &&
          memoTimelineConferences.length > 0 &&
          memoTimelineConferences.map((timeline, i) => (
            <TimelineItem key={i} selected={conferenceViewTime === timeline.key}>
              <StyledTypography variant="h3">{timeline.key || 'XXh'}</StyledTypography>
              <Slider>
                {timeline.events.map((conference, ii) => (
                  <div key={ii}>
                    <ConferenceCard conference={conference} />
                  </div>
                ))}
              </Slider>
            </TimelineItem>
          ))}
        {!loading && memoTimelineConferences.length === 0 && (
          <StyledGridEmptyList item xs={12}>
            <Alert severity="warning">
              <FormattedMessage id="conference.list.empty" />
            </Alert>
          </StyledGridEmptyList>
        )}
      </TimelineConferenceContent>
    </TimelineConferencePage>
  )
}

const TimelineConferencePage = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`

const TimelineConferenceContent = styled.div``

const TimelineItem = styled.div<{ selected?: boolean }>`
  position: relative;
  padding-bottom: 30px;
  display: flex;
  align-items: flex-start;

  .MuiTypography-root {
    position: relative;
    flex: 0 0 94px;
    padding-left: 50px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
  }
  .slick-slider {
    flex-grow: 1;
    width: calc(100% - 100px);
  }

  /* vertical line */
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    left: 30px;
    top: 0;
    width: 2px;
    background-color: ${PRIMARY};
  }
  &:last-child:before {
    background-color: transparent;
  }
  /* oval */
  &:after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    border: 5px solid ${PRIMARY};
    border-radius: 50%;
    background-color: ${(props) => (props.selected ? '#ffffff' : PRIMARY)};
    margin-left: 19px;
  }
`

const StyledGridEmptyList = styled(Grid)`
  margin-top: 1em;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
`

const StyledTypography = styled(Typography)`
  white-space: nowrap;
`

const StyledTitle = styled.div`
  margin-left: 100px;
  margin-bottom: 42px;
`

// const StyledSubTitle = styled.div`
//   font-size: 16px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: 19px;
//   margin-bottom: 42px;
// `

export default ConferenceTimeline
