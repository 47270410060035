import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Badge, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ForumIcon from '@material-ui/icons/Forum'
import PeopleIcon from '@material-ui/icons/People'
import React, { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ROLES } from '../../../entities'
import { useThunkDispatch, useTooltip } from '../../../hooks'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { setOpenAgenda, setOpenMenu, setOpenMenuContact } from '../../../store/app'
import { setCurrentConversation, setMinimizeChat, setOpenChat } from '../../../store/chat'
import { GRAY_ICON } from '../../../theme/colors'
import { breakpoints } from '../../breakpoints'
import SelectLanguageHeader from '../../ui/SelectLanguage/SelectLanguageHeader'
import Announce from '../Announce'

const HeaderActions: FC = () => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const user = useSelector((state: IRootState) => state.appState.user)
  const openAgenda = useSelector((state: IRootState) => state.appState.openAgenda)
  const openMenuContact = useSelector((state: IRootState) => state.appState.openMenuContact)
  const agendaToRespond = useSelector((state: IRootState) => state.appState.agendaToRespond)
  const unread = useSelector((state: IRootState) => state.chatState.unread)
  const openChat = useSelector((state: IRootState) => state.chatState.openChat)
  const minimizeChat = useSelector((state: IRootState) => state.chatState.minimizeChat)
  const channelsToBind = useSelector((state: IRootState) => state.chatState.channelsToBind)
  const loadingChannels = useSelector((state: IRootState) => state.chatState.loadingChannels)
  const errorChannels = useSelector((state: IRootState) => state.chatState.errorChannels)
  const conversations = useSelector((state: IRootState) => state.chatState.conversations)
  const contacts = useSelector((state: IRootState) => state.participantState.contacts)
  const loadedConversations = useSelector(
    (state: IRootState) => state.chatState.loadedConversations
  )
  const loadingConversations = useSelector(
    (state: IRootState) => state.chatState.loadingConversations
  )
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const { anchorRef, setOpen, Tooltip } = useTooltip(
    intl.formatMessage({
      id: errorChannels
        ? 'error.channels'
        : (conversations.length === 0 && !loadingConversations && loadedConversations) ||
          (channelsToBind.length === 0 && !loadingChannels)
          ? 'no.conversations'
          : !loadedConversations || conversations.length === 0
            ? 'loading'
            : 'button.chat'
    })
  )
  const {
    anchorRef: anchorRefAgenda,
    setOpen: setOpenTooltipAgenda,
    Tooltip: TooltipAgenda
  } = useTooltip(
    intl.formatMessage({
      id: openAgenda
        ? 'titleAccess.Stream.DateRangeIcon.disabled'
        : 'titleAccess.Stream.DateRangeIcon'
    })
  )
  const dispatch = useThunkDispatch()

  const isAnimator = useMemo(
    () =>
      user &&
      user.roles &&
      ((eventCo && eventCo.isBroadcaster) ||
        user.roles.indexOf(ROLES.ROLE_ADMIN) > -1 ||
        user.roles.indexOf(ROLES.ROLE_SUPER_ADMIN) > -1),
    [user, eventCo]
  )

  const toggleOpenMenuContact = (agendaMode?: boolean) => {
    sendDataToGTM({ event: 'click-header-contact' })
    dispatch(setOpenMenu(false))
    dispatch(setOpenChat(false))
    dispatch(setOpenMenuContact(agendaMode ? !openAgenda : openAgenda ? true : !openMenuContact))
    dispatch(setOpenAgenda(agendaMode ? !openAgenda : false))
  }

  const toggleOpenMenuChat = () => {
    sendDataToGTM({ event: 'click-header-chat', label: 'Toggle chat' })
    if (openChat && minimizeChat) {
      dispatch(setMinimizeChat(false))
    } else {
      dispatch(setOpenChat(!openChat))
      dispatch(setMinimizeChat(false))
    }
    if (unread && unread.length > 0) {
      dispatch(setCurrentConversation(unread[0].author.id))
    } else if (conversations && conversations.length > 0 && conversations[0].contact.id) {
      dispatch(setCurrentConversation(conversations[0].contact.id))
    }
    dispatch(setOpenMenuContact(false))
    dispatch(setOpenAgenda(false))
  }

  return (
    <StyledNotifications>
      {isAnimator && (
        <Announce groupId={settings.eventGroupId} displayIcon onEnd={() => setOpen(false)} />
      )}
      <IconButton
        ref={anchorRefAgenda}
        onMouseOver={() => setOpenTooltipAgenda(true)}
        onMouseOut={() => setOpenTooltipAgenda(false)}
        aria-label={intl.formatMessage({
          id: openAgenda
            ? 'titleAccess.Stream.DateRangeIcon.disabled'
            : 'titleAccess.Stream.DateRangeIcon'
        })}
        color="primary"
        onClick={() => toggleOpenMenuContact(true)}
        edge="end"
      >
        {openMenuContact && openAgenda ? (
          <CloseIcon
            fontSize="large"
            titleAccess={intl.formatMessage({ id: 'titleAccess.Header.CloseIcon' })}
          />
        ) : (
          <Badge badgeContent={agendaToRespond} color="secondary">
            <DateRangeIcon
              fontSize="large"
              titleAccess={intl.formatMessage({
                id: openAgenda
                  ? 'titleAccess.Stream.DateRangeIcon.disabled'
                  : 'titleAccess.Stream.DateRangeIcon'
              })}
            />
          </Badge>
        )}
        <TooltipAgenda />
      </IconButton>
      {!settings.disableFeatureChat && (
        <IconButton
          ref={anchorRef}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
          aria-label={intl.formatMessage({ id: 'new.messages' }, { unread: unread.length })}
          color={conversations.length === 0 ? 'inherit' : 'primary'}
          onClick={conversations.length > 0 ? toggleOpenMenuChat : undefined}
          edge="end"
        >
          {openChat ? (
            <CloseIcon
              fontSize="large"
              titleAccess={intl.formatMessage({ id: 'titleAccess.Header.CloseIcon' })}
            />
          ) : (
            <Badge badgeContent={unread.length} color="secondary">
              <ForumIcon
                fontSize="large"
                titleAccess={intl.formatMessage({ id: 'titleAccess.HeaderActions.ChatIcon' })}
              />
            </Badge>
          )}
          <Tooltip />
        </IconButton>
      )}
      <IconButtonMobile
        color={contacts.length === 0 ? 'inherit' : 'primary'}
        aria-label={intl.formatMessage({ id: 'ariaLabel.Header.ButtonContact' })}
        onClick={() => toggleOpenMenuContact(false)}
        edge="end"
      >
        {openMenuContact && !openAgenda ? (
          <CloseIcon
            fontSize="large"
            titleAccess={intl.formatMessage({ id: 'titleAccess.Header.CloseIcon' })}
          />
        ) : (
          <PeopleIcon
            fontSize="large"
            titleAccess={intl.formatMessage({ id: 'titleAccess.Header.PeopleIcon' })}
          />
        )}
      </IconButtonMobile>
      {settings.eventLanguages.split(',').length > 1 && <SelectLanguageHeader />}
    </StyledNotifications>
  )
}

const StyledNotifications = styled.div`
  & {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    color: ${GRAY_ICON};

    & > * {
      margin-left: 10px;
    }
    .MuiBadge-anchorOriginTopRightRectangle {
      top: 4px;
      right: 4px;
    }
  }
`

const IconButtonMobile = styled(IconButton)`
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`

export default HeaderActions
