import styled from '@emotion/styled'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IMagicStream } from '../../entities'
import { useDateFns } from '../../hooks'
import { PRIMARY_COLOR } from '../../theme'
import { VERY_LIGHT_GRAY, WHITE } from '../../theme/colors'
import { formatName } from '../../utils'
import UserAvatar from '../globals/UserAvatar/UserAvatar'

type IStreamInvitationProps = {
  stream: IMagicStream
}

const StreamInvitation: React.FC<IStreamInvitationProps> = ({ stream }) => {
  const intl = useIntl()
  const dateFns = useDateFns()

  return (
    <StreamInvitationContainer>
      <StyledIcon className="icon-hover">
        <ContactPhoneIcon
          fontSize="large"
          titleAccess={intl.formatMessage({
            id: 'titleAccess.ContactPopperAction.ContactPhoneIcon'
          })}
          htmlColor={PRIMARY_COLOR}
        />
      </StyledIcon>
      <StreamInvitationContent>
        <FormattedMessage
          id="invitation.sent"
          values={{
            user: stream.subjectUser
              ? formatName(stream.subjectUser).full
              : '',
            date: dateFns.formatDistanceToNow(stream.writeDate || stream.createDate)
          }}
        />
        {stream.subjectUser && (
          <StreamInvitationAvatar>
            <UserAvatar
              popper={{ placement: 'left' }}
              user={stream.subjectUser}
              showDetails
              disabledAnimation
            />
          </StreamInvitationAvatar>
        )}
      </StreamInvitationContent>
    </StreamInvitationContainer>
  )
}

const StreamInvitationContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  padding: 7px 0;

  &:hover {
    .icon-hover {
      background-color: ${WHITE};
    }
  }
`

const StreamInvitationContent = styled.div`
  margin-left: 12px;
`

const StyledIcon = styled.div`
  background-color: ${VERY_LIGHT_GRAY};
  border-radius: 50%;
  padding: 5px;
`

const StreamInvitationAvatar = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

export default StreamInvitation
