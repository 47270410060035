import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { GOLD, PRIMARY, WHITE } from '../../theme/colors'

const pulse = (scale: number) => keyframes`
  to {
    opacity: 0;
    transform: scale(${scale});
  }
`

export const StyledModerator = styled.img`
  & {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 6px);
    background-color: ${WHITE};
    border: 1px solid ${WHITE};
    max-height: 18px;
    max-width: 40px;
    object-fit: contain;
  }
`

export const CssModeratorContainer = css`
  & {
    position: relative;

    .MuiAvatar-root {
      border: 5px solid ${GOLD};
    }
  }
`

export const CssPresenterContainer = css`
  & {
    position: relative;

    .MuiAvatar-root {
      border: 5px solid ${PRIMARY};
    }
  }
`

export const CssMoveAvatar = (scale: number, borderColor: string, borderWidth: string) => css`
  & {
    position: relative;

    .MuiAvatar-root {
      border: 2px solid ${borderColor};

      &::before,
      &::after {
        content: ' ';
        position: absolute;
        left: -40%;
        top: -40%;
        width: 180%;
        height: 180%;
        border-radius: 9999px;
        box-sizing: border-box;
        opacity: 0.6;
        transform: scale(0.714);
        z-index: 1;
        border: ${borderColor} solid 8px;
        animation: ${pulse(scale)} 2s linear infinite;
      }
      &::after {
        animation-delay: 1s;
      }
      .avatar:hover::after {
        animation-delay: 0.5s;
      }
    }
  }
`
