import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { MenuItem, Select } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import SearchIcon from '@material-ui/icons/Search'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
// import { ReactComponent as SvgBulletPoint } from '../../assets/images/icons/bullet point.svg'
// import { ReactComponent as SvgCalendar } from '../../assets/images/icons/calendar.svg'
import ConferenceList from '../../components/conference/ConferenceList'
import ConferenceTimeline from '../../components/conference/ConferenceTimeline'
import { ConferenceViewDisplayType } from '../../entities'
import { useDateFns, useEffectOnce, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { setConferenceViewDate, setConferenceViewDisplayType } from '../../store/conference'
import { setPageReferral } from '../../store/gtm'
import { checkEventCoDates } from '../../store/settings/thunks'
import { PRIMARY, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'

const ConferenceListPage = () => {
  const intl = useIntl()
  const dateFns = useDateFns()
  const { sendDataToGTM } = useGTM()
  const conferenceViewDisplayType = useSelector(
    (state: IRootState) => state.conferenceState.conferenceViewDisplayType
  )
  const conferenceViewDate = useSelector(
    (state: IRootState) => state.conferenceState.conferenceViewDate
  )
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const eventCoDates = useSelector((state: IRootState) => state.settingsState.eventCoDates)
  const eventCoDatesLoaded = useSelector((state: IRootState) => state.settingsState.eventCoDatesLoaded)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const dispatch = useThunkDispatch()

  const handleChangeDate = (newDate: string | null) => {
    if (newDate !== null) {
      dispatch(setConferenceViewDate(newDate))
    }
  }

  const handleChangeView = (
    event: React.MouseEvent<HTMLElement>,
    newDisplayType: string | null
  ) => {
    if (newDisplayType !== null) {
      dispatch(setConferenceViewDisplayType(newDisplayType))
    }
  }

  // calcul the day to display
  useEffect(() => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    if (eventCo && eventCo.dates.length > 0) {
      const eventFirstDate = new Date(eventCo.dates[0])
      eventFirstDate.setHours(0, 0, 0, 0)
      const eventLastDate = new Date(eventCo.dates[eventCo.dates.length - 1])
      eventLastDate.setHours(0, 0, 0, 0)
      if (today.getTime() < eventFirstDate.getTime()) {
        // not started
        dispatch(setConferenceViewDate(eventCo.dates[0]))
      } else if (today.getTime() === eventFirstDate.getTime()) {
        // first day
        dispatch(setConferenceViewDate(eventCo.dates[0]))
      } else if (today.getTime() > eventLastDate.getTime()) {
        // event finished
        dispatch(setConferenceViewDate(eventCo.dates[0]))
      } else {
        for (let i = 1; i < eventCo.dates.length; i++) {
          const eventDate = new Date(eventCo.dates[i])
          eventDate.setHours(0, 0, 0, 0)
          if (today.getTime() === eventDate.getTime()) {
            // other event date
            dispatch(setConferenceViewDate(eventCo.dates[i]))
          }
        }
      }
    }
  }, [eventCo, dispatch])

  useEffect(() => {
    if (eventCo?.dates && !eventCoDatesLoaded) {
      dispatch(checkEventCoDates(eventCo?.dates))
    }
  }, [eventCo, eventCoDatesLoaded, dispatch])

  // gtm DATA
  useEffectOnce(() => {
    const title = intl.formatMessage({ id: 'gtm.listConferences.title' })
    dispatch(setPageReferral(title))
    sendDataToGTM({
      event: 'page-view',
      pageTitle: title,
      pageType: intl.formatMessage({ id: 'gtm.listConferences.type' }),
      pageReferral
    })
  })

  return (
    <ConferencesPage>
      <StyledButtonContainer>
        {conferenceViewDisplayType === ConferenceViewDisplayType.TIMELINE &&
          eventCoDates &&
          (eventCoDates.length < 5 ? (
            <StyledToggleButtonGroupDates
              value={conferenceViewDate}
              exclusive
              onChange={(event: React.MouseEvent<HTMLElement>, newDate: string | null) =>
                handleChangeDate(newDate)
              }
              aria-label={intl.formatMessage({ id: 'conference.list.ariaLabel.ToggleButtonGroup' })}
            >
              {eventCoDates.map((date) => (
                <ToggleButton key={date} value={date} aria-label={date}>
                  {dateFns.format(date, intl.formatMessage({ id: 'date.format.small' }))}
                </ToggleButton>
              ))}
            </StyledToggleButtonGroupDates>
          ) : (
            <Select
              value={conferenceViewDate}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                handleChangeDate(event.target.value as string)
              }}
            >
              {eventCoDates.map((date) => (
                <MenuItem key={date} value={date}>
                  {dateFns.format(date, intl.formatMessage({ id: 'date.format.small' }))}
                </MenuItem>
              ))}
            </Select>
          ))}
        <StyledToggleButtonGroup
          value={conferenceViewDisplayType}
          exclusive
          onChange={handleChangeView}
          aria-label={intl.formatMessage({ id: 'conference.list.ariaLabel.ToggleButtonGroup' })}
        >
          <ToggleButton
            value="timeline"
            aria-label={intl.formatMessage({ id: 'conference.list.view.timeline' })}
          >
            {/* <SvgCalendar title={intl.formatMessage({ id: 'conference.list.view.timeline' })} /> */}
            <CalendarTodayIcon
              fontSize="large"
              titleAccess={intl.formatMessage({ id: 'conference.list.view.timeline' })}
            />
          </ToggleButton>
          <ToggleButton
            value="list"
            aria-label={intl.formatMessage({ id: 'conference.list.view.list' })}
          >
            {/* <SvgBulletPoint title={intl.formatMessage({ id: 'conference.list.view.list' })} /> */}
            <SearchIcon
              fontSize="large"
              titleAccess={intl.formatMessage({ id: 'conference.list.view.list' })}
            />
          </ToggleButton>
        </StyledToggleButtonGroup>
      </StyledButtonContainer>
      {conferenceViewDisplayType === ConferenceViewDisplayType.TIMELINE && <ConferenceTimeline />}
      {conferenceViewDisplayType === ConferenceViewDisplayType.LIST && <ConferenceList />}
      <Helmet>
        <title>
          {`${intl.formatMessage({ id: 'gtm.listConferences.title' })} - ${settings.theme.header.title
            }`}
        </title>
      </Helmet>
    </ConferencesPage>
  )
}

const ConferencesPage = styled.div`
  & {
    position: relative;
  }
`

const StyledButtonContainer = styled.div`
  & {
    display: flex;
    justify-content: flex-end;
    padding: 15px 15px 0;
  }
`

const StyledToggleButtonGroupDates = styled(ToggleButtonGroup)`
  & {
    background-color: transparent;

    .MuiToggleButton-root {
      border-top: hidden !important;
      border-left: hidden !important;
      border-right: hidden !important;
      padding: 10px 20px !important;
      border-radius: ${BORDER_RADIUS_CARD} ${BORDER_RADIUS_CARD} 0 0;
    }

    .MuiToggleButton-root.Mui-selected {
      background-color: ${WHITE};
      border: 3px solid ${PRIMARY};

      span {
        color: ${PRIMARY};
      }
    }
  }
`

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  & {
    background-color: transparent;
    margin-left: 30px;

    .MuiToggleButton-root {
      border: none !important;
      border-radius: ${BORDER_RADIUS_CARD} !important;
      margin-left: 10px;
      background-color: ${WHITE};

      span {
        color: ${PRIMARY};
      }
    }

    .MuiToggleButton-root.Mui-selected {
      background-color: ${PRIMARY};

      span {
        color: ${WHITE};
      }
    }
  }
`

export default ConferenceListPage
