import styled from '@emotion/styled'
import { AppBar, Button, Grid, Toolbar, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { LanguageKeys } from '../../../entities'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import AppPlayer from '../../globals/AppPlayer/AppPlayer'
import Loading from '../../Loading'
import OnboardingModal from './OnboardingModal'
import OnboardingStepper from './OnboardingStepper'

interface IOnboardingWelcomeModalProps {
  step: number
  total: number
  title: string
  opened?: boolean
  onNext?: () => void
}

const OnboardingWelcomeModal: React.FC<IOnboardingWelcomeModalProps> = ({
  step,
  total,
  title,
  opened,
  onNext
}) => {
  const intl = useIntl()
  const [isOpen, setOpen] = useState(false)
  const userUpdating = useSelector((state: IRootState) => state.appState.userUpdating)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const locale = useSelector((state: IRootState) => state.appState.locale)

  const localizedImage = useMemo(() => {
    let image = settings.theme.header.image
    if (locale === LanguageKeys.en && settings.theme.header.image_en) {
      image = settings.theme.header.image_en
    }
    return image
  }, [locale])

  useEffect(() => {
    if (opened) setOpen(opened)
  }, [opened])

  return (
    <OnboardingModal isOpen={isOpen} ariaLabelledby="onboardingwelcomemodal-title">
      <StyledAppBar>
        <Toolbar>
          <StyledLogo>
            <img alt={settings.theme.header.title} src={localizedImage} />
          </StyledLogo>
          <StyledTitle id="onboardingwelcomemodal-title">
            <FormattedMessage id={title} />
          </StyledTitle>
          <Button
            id="next-step"
            variant="contained"
            color="secondary"
            onClick={() => {
              if (!onNext || onNext()) {
                setOpen(false)
              }
            }}
          >
            {userUpdating ? (
              <Loading color="inherit" />
            ) : (
              <FormattedMessage id="onboarding.step1.next" />
            )}
          </Button>
        </Toolbar>
      </StyledAppBar>
      <OnboardingStepper step={step} total={total} />
      <StyledContent>
        <Grid container>
          <Grid item xs={12}>
            {settings.theme.onboarding && settings.theme.onboarding.video && (
              <AppPlayer url={settings.theme.onboarding.video} controls playing={isOpen} />
            )}
            {(!settings.theme.onboarding || !settings.theme.onboarding.video) && (
              <StyledMessage
                dangerouslySetInnerHTML={{
                  __html: `<b>${intl.formatMessage(
                    { id: 'missing.onboarding.video' },
                    { title: eventCo?.name }
                  )}</b>`
                }}
              ></StyledMessage>
            )}
          </Grid>
        </Grid>
      </StyledContent>
    </OnboardingModal>
  )
}

const StyledAppBar = styled(AppBar)`
  position: relative;
`

const StyledLogo = styled.div`
  img {
    height: 50px;
    display: block;
    margin-left: 1em;
  }
`

const StyledTitle = styled(Typography)`
  margin-left: 1em;
  flex: 1;
  font-size: 20px;
`

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;

  & > div {
    max-width: 600px;
    width: 100%;
  }
`

const StyledMessage = styled.div`
  font-size: 30px;
  text-align: center;
`

export default OnboardingWelcomeModal
