import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { setAgendaToRespond, setLocale } from '.'
import {
  createDataUser,
  deleteUserKeywords,
  getKeywords,
  getKeywordsByType,
  getMe,
  getUserKeywords,
  postUserKeywords,
  unsyncCalendar,
  updateDataUser,
  updateUser as updateUserApi
} from '../../api'
import { getAgenda, getAgendaSlots, getAgendaToRespond } from '../../api/agenda'
import { AlertType, IEventCo, IUser, IUserUpdate, LanguageKeys } from '../../entities'
import { IKeyword, IKeywordDisplay } from '../../entities/keyword'
import { FrameRateType, IDevice, VideoResolution } from '../../entities/mediaStream'
import IStorageAccess from '../../entities/storageAccess'
import settings from '../../settings'
import { IBaseRootState } from '../../store'
import consoleUtils from '../../utils/consoleUtils'
import { resetAlert, setAlert } from '../alerts'
import { resetToken } from '../auth'
import { setQualityTestResultsOpentok } from '../settings'
import {
  mergeUser,
  resetDefaultAudioSource,
  resetDefaultVideoSource,
  setAgenda,
  setAgendaError,
  setAgendaInitDate,
  setAgendaInitDateLoading,
  setAgendaLoading,
  setAgendaSlots,
  setAgendaSlotsError,
  setAgendaSlotsLoading,
  setAudioInputDevices,
  setDefaultAudioSource,
  setDefaultResolution,
  setDefaultVideoSource,
  setError,
  setHasPassedTunnel,
  setKeywords,
  setKeywordsByUser,
  setKeywordsByUserError,
  setKeywordsByUserLoading,
  setKeywordsError,
  setKeywordsLoading,
  setKeywordsX,
  setKeywordsXError,
  setKeywordsXLabel,
  setKeywordsXLoaded,
  setKeywordsXLoading,
  setKeywordsY,
  setKeywordsYError,
  setKeywordsYLabel,
  setKeywordsYLoaded,
  setKeywordsYLoading,
  setLoading,
  setPlanning,
  setPlanningLength,
  setPlanningOrder,
  setPreferedVideoDisplay,
  setRecommendedFrameRate,
  setRecommendedResolution,
  setUser,
  setUserUpdating,
  setVideoInputDevices
} from './actions'
import { StorageKeys } from './model'

export const initializeApp =
  () => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setLoading(true))
    try {
      // Loading first me
      const me = await getMe()
      dispatch(setUser(me))
      if (me.dataUser && me.dataUser.preferredLanguage) {
        const eventLanguages = (settings.eventLanguages as string).split(',')
        if (eventLanguages.length > 0 && eventLanguages.includes(me.dataUser.preferredLanguage)) {
          dispatch(setLocale(me.dataUser.preferredLanguage as LanguageKeys))
        }
      }
    } catch (e) {
      dispatch(setError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setLoading(false))
  }

export const clearStore = () => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
  await Promise.all([dispatch(resetAlert()), dispatch(resetToken())])
}

export const readFromStorage =
  (storage: IStorageAccess, userId: number) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      try {
        const hasPassedTunnel = await storage.getItem(StorageKeys.HAS_PASSED_TUNNEL + '_' + userId)
        dispatch(setHasPassedTunnel(!!hasPassedTunnel))
        const defaultVideoSource = await storage.getItem(StorageKeys.DEFAULT_VIDEO_SOURCE)
        if (defaultVideoSource) dispatch(setDefaultVideoSource(defaultVideoSource))
        const defaultAudioSource = await storage.getItem(StorageKeys.DEFAULT_AUDIO_SOURCE)
        if (defaultAudioSource) dispatch(setDefaultAudioSource(defaultAudioSource))
        const defaultResolution = await storage.getItem(StorageKeys.DEFAULT_RESOLUTION)
        if (defaultResolution) dispatch(setDefaultResolution(defaultResolution as VideoResolution))
        const recommendedFrameRate = await storage.getItem(StorageKeys.RECOMMENDED_FRAMERATE)
        if (recommendedFrameRate) {
          dispatch(setRecommendedFrameRate(parseInt(recommendedFrameRate, 10) as FrameRateType))
        }
        const recommendedResolution = await storage.getItem(StorageKeys.RECOMMENDED_RESOLUTION)
        if (recommendedResolution) {
          dispatch(setRecommendedResolution(defaultResolution as VideoResolution))
        }
        const preferedVideoDisplay = await storage.getItem(StorageKeys.PREFERED_VIDEO_DISPLAY)
        dispatch(
          setPreferedVideoDisplay(
            preferedVideoDisplay === 'true'
              ? true
              : preferedVideoDisplay === 'false'
                ? false
                : settings.opentok.autoStart
          )
        )
        const opentokQualityTestResults = await storage.getItem(StorageKeys.QUALITY_TEST_RESULTS)
        if (opentokQualityTestResults) {
          dispatch(setQualityTestResultsOpentok(JSON.parse(opentokQualityTestResults)))
        }
      } catch (e) {
        consoleUtils.error(e)
      }
    }

export const setHasPassedTunnelFromStorage =
  (storage: IStorageAccess, userId: number) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      try {
        await storage.setItem(StorageKeys.HAS_PASSED_TUNNEL + '_' + userId, 'true')
        dispatch(setHasPassedTunnel(true))
      } catch (e) {
        consoleUtils.error(e)
      }
    }

export const saveDefaultVideoSource =
  (storage: IStorageAccess, deviceId: string) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      try {
        await storage.setItem(StorageKeys.DEFAULT_VIDEO_SOURCE, deviceId)
        dispatch(setDefaultVideoSource(deviceId))
      } catch (e) {
        consoleUtils.error(e)
      }
    }

export const saveDefaultAudioSource =
  (storage: IStorageAccess, deviceId: string) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      try {
        await storage.setItem(StorageKeys.DEFAULT_AUDIO_SOURCE, deviceId)
        dispatch(setDefaultAudioSource(deviceId))
      } catch (e) {
        consoleUtils.error(e)
      }
    }

export const saveDefaultResolution =
  (storage: IStorageAccess, resolution: VideoResolution) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      try {
        await storage.setItem(StorageKeys.DEFAULT_RESOLUTION, resolution)
        dispatch(setDefaultResolution(resolution))
      } catch (e) {
        consoleUtils.error(e)
      }
    }

export const saveRecommendedFrameRate =
  (storage: IStorageAccess, frameRate: FrameRateType) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      try {
        await storage.setItem(StorageKeys.RECOMMENDED_FRAMERATE, `${frameRate}`)
        dispatch(setRecommendedFrameRate(frameRate))
      } catch (e) {
        consoleUtils.error(e)
      }
    }

export const saveRecommendedResolution =
  (storage: IStorageAccess, resolution: VideoResolution) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      try {
        await storage.setItem(StorageKeys.RECOMMENDED_RESOLUTION, resolution)
        dispatch(setRecommendedResolution(resolution))
      } catch (e) {
        consoleUtils.error(e)
      }
    }

export const savePreferedVideoDisplay =
  (storage: IStorageAccess, preferedVideoDisplay: boolean) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      try {
        await storage.setItem(
          StorageKeys.PREFERED_VIDEO_DISPLAY,
          preferedVideoDisplay ? 'true' : 'false'
        )
        dispatch(setPreferedVideoDisplay(preferedVideoDisplay))
      } catch (e) {
        consoleUtils.error(e)
      }
    }

export const updateUser =
  (
    user: IUser,
    userUpdate: IUserUpdate,
    keywords?: IKeywordDisplay[],
    success?: () => void,
    error?: (error: Error) => void
  ) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setUserUpdating(true))
      try {
        const userUpdated = await updateUserApi(user.id, userUpdate)
        if (keywords) {
          await Promise.all(
            keywords.map((keywordToAdd) => {
              return new Promise((resolve, reject) => {
                if (
                  user.keywords &&
                  user.keywords.find((userKeyword) => userKeyword.keyword_id.id === keywordToAdd.id)
                ) {
                  // ignore existant
                  resolve(null)
                } else {
                  // post each new keyword
                  postUserKeywords(keywordToAdd.id).then(resolve).catch(reject)
                }
              })
            })
          )
          if (user.keywords) {
            await Promise.all(
              user.keywords.map((keywordToRemove) => {
                return new Promise((resolve, reject) => {
                  if (keywords.find((k) => k.id === keywordToRemove.keyword_id.id)) {
                    // ignore existant
                    resolve(null)
                  } else {
                    // delete removed keyword
                    deleteUserKeywords(keywordToRemove.id).then(resolve).catch(reject)
                  }
                })
              })
            )
          }
        }
        dispatch(mergeUser(userUpdated))
      } catch (e) {
        consoleUtils.error(e)
        dispatch(setUserUpdating(false))
        error && error(e as Error)
      }
      dispatch(setUserUpdating(false))
      success && success()
    }

export const loadKeywords =
  () => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setKeywordsLoading(true))
    try {
      const keywords = await getKeywords()
      if (keywords.total > 0) {
        dispatch(setKeywords(keywords))
      }
    } catch (e) {
      dispatch(setKeywordsError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setKeywordsLoading(false))
  }

export const loadKeywordsByUser =
  (userId: number) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setKeywordsByUserLoading(true))
    try {
      const keywords = await getUserKeywords(userId)
      dispatch(mergeUser({}, keywords && keywords.items ? keywords.items : []))
    } catch (e) {
      dispatch(setKeywordsByUserError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setKeywordsByUserLoading(false))
  }

export const getKeywordsByUser =
  (userId: number) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setKeywordsByUserLoading(true))
    try {
      const keywords = await getUserKeywords(userId)
      dispatch(setKeywordsByUser(keywords))
    } catch (e) {
      dispatch(setKeywordsByUserError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setKeywordsByUserLoading(false))
  }

export const updateDefaultSources =
  (storage: IStorageAccess, devices: IDevice[]) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(
        setVideoInputDevices(
          devices.filter((element) => {
            return element.kind === 'videoInput'
          })
        )
      )
      dispatch(
        setAudioInputDevices(
          devices.filter((element) => {
            return element.kind === 'audioInput'
          })
        )
      )
      // verify if default video is accessible
      const defaultVideoSource = await storage.getItem(StorageKeys.DEFAULT_VIDEO_SOURCE)
      const defaultVideoDevicePresent = devices.find(
        (device) => device.kind === 'videoInput' && device.deviceId === defaultVideoSource
      )
      if (defaultVideoSource && !defaultVideoDevicePresent) {
        dispatch(resetDefaultVideoSource())
        await storage.removeItem(StorageKeys.DEFAULT_VIDEO_SOURCE)
      }
      // verify if default audio is accessible
      const defaultAudioSource = await storage.getItem(StorageKeys.DEFAULT_AUDIO_SOURCE)
      const defaultAudioDevicePresent = devices.find(
        (device) => device.kind === 'audioInput' && device.deviceId === defaultAudioSource
      )
      if (defaultAudioSource && !defaultAudioDevicePresent) {
        dispatch(resetDefaultAudioSource())
        await storage.removeItem(StorageKeys.DEFAULT_AUDIO_SOURCE)
      }
    }

export const loadKeywordsXByType =
  (type: IKeyword) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setKeywordsXLoading(true))
    try {
      const keywords = await getKeywordsByType(type.id)
      dispatch(setKeywordsX(keywords.items))
      dispatch(setKeywordsXLabel(type.title))
      dispatch(setKeywordsXLoaded(true))
    } catch (e) {
      dispatch(setKeywordsXError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setKeywordsXLoading(false))
  }

export const loadKeywordsYByType =
  (type: IKeyword) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setKeywordsYLoading(true))
    try {
      const keywords = await getKeywordsByType(type.id)
      dispatch(setKeywordsY(keywords.items))
      dispatch(setKeywordsYLabel(type.title))
      dispatch(setKeywordsYLoaded(true))
    } catch (e) {
      dispatch(setKeywordsYError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setKeywordsYLoading(false))
  }

export const loadAgenda = () => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
  dispatch(setAgendaLoading(true))
  try {
    const agenda = await getAgenda()
    dispatch(setPlanningOrder([]))
    dispatch(setPlanning({}))
    dispatch(setPlanningLength(0))
    dispatch(setAgenda(agenda))
    const agendaToRespond = await getAgendaToRespond()
    dispatch(setAgendaToRespond(agendaToRespond))
  } catch (e) {
    dispatch(setAgendaError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setAgendaLoading(false))
}

export const getInitDateAgendaSlots =
  (eventCo: IEventCo, userId: number) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setAgendaInitDateLoading(true))
      try {
        const todayDate = new Date()
        const initDate = eventCo?.startDate &&
          new Date().setHours(0, 0, 0, 0) <= new Date(eventCo?.startDate).setHours(0, 0, 0, 0)
          ? new Date(eventCo?.startDate)
          : todayDate
        const datePlus7 = new Date()
        datePlus7.setDate(todayDate.getDate() + 7)
        const endDate = eventCo?.endDate &&
          new Date().setHours(0, 0, 0, 0) <= new Date(eventCo?.endDate).setHours(0, 0, 0, 0)
          ? new Date(eventCo?.endDate)
          : datePlus7
        const dateI = initDate
        let foundSlots = false
        let finalInitDate = initDate
        while (!foundSlots && dateI <= endDate) {
          const agendaSlots = await getAgendaSlots(userId, dateI)
          // try to find a valid slot this day
          const hasSlot = agendaSlots.calendarSlots.find(agendaSlot => {
            return (new Date(agendaSlot.dateStart)).getTime() > (new Date()).getTime()
          })
          if (hasSlot) {
            // ok for this day
            foundSlots = true
            finalInitDate = dateI
          } else {
            // next day
            dateI.setDate(dateI.getDate() + 1)
          }
        }
        dispatch(setAgendaInitDate(finalInitDate))
      } catch (e) {
        dispatch(setAgendaSlotsError((e as any).message))
        consoleUtils.error(e)
      }
      dispatch(setAgendaInitDateLoading(false))
    }

export const loadAgendaSlots =
  (userId: number, date?: Date) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setAgendaSlotsLoading(true))
      try {
        const agendaSlots = await getAgendaSlots(userId, date)
        dispatch(setAgendaSlots(agendaSlots))
      } catch (e) {
        dispatch(setAgendaSlotsError((e as any).message))
        consoleUtils.error(e)
      }
      dispatch(setAgendaSlotsLoading(false))
    }

export const changeLocale =
  (storage: IStorageAccess, locale: LanguageKeys, user: IUser | null | undefined = null) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      await storage.setItem(StorageKeys.PREFERED_LANGUAGE, locale)
      dispatch(setLocale(locale))
      if (user && user.dataUser && user.dataUser.id) {
        await updateDataUser(user.dataUser.id, { preferredLanguage: locale })
        dispatch(setUser({ ...user, dataUser: { id: user.dataUser.id, preferredLanguage: locale } }))
      } else if (user) {
        const dataUser = await createDataUser({
          userId: `/users/${user.id}`,
          preferredLanguage: locale
        })
        if (dataUser && dataUser.id) {
          dispatch(setUser({ ...user, dataUser: { id: dataUser.id, preferredLanguage: locale } }))
        }
      }
    }

export const unsync =
  (userId: number) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    try {
      if (userId) {
        const user = await unsyncCalendar(userId)
        user.isCalendarSynchronized = false
        dispatch(setUser(user))
        dispatch(
          setAlert({
            id: new Date().getTime().toString(),
            intlKey: 'success.unsyncCalendar',
            type: AlertType.success
          })
        )
      }
    } catch (e) {
      consoleUtils.error(e)
    }
  }
