import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { IContactKeyword } from '../../entities/keyword'
import { GRAY, PRIMARY, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'

interface IProps {
  tag: IContactKeyword
  onClickTag?: (keyword: IContactKeyword) => void | undefined
  color?: 'default' | 'primary' | undefined
  icon?: ReactNode | undefined
}

const ContactTag: React.FC<IProps> = ({ tag, onClickTag, color, icon }) => {
  const handleClickTag = (keyword: IContactKeyword) => {
    if (onClickTag) {
      onClickTag(keyword)
    }
  }

  return (
    <Tag
      clickable={!!onClickTag}
      onClick={() => handleClickTag(tag)}
      color={color || 'primary'}
    >
      {!!icon ? icon : ''}
      {tag.keyword?.title}
    </Tag>
  )
}

const TagClickable = css`
  cursor: pointer;
  transition: all 0.3s;

  svg {
    margin-right: 3px;
    visibility: hidden;
    opacity: 0;
    width: 0;
    overflow: 0;
    transition: all 0.3s;
  }
  &:hover {
    svg {
      opacity: 1;
      visibility: visible;
      width: auto;
    }
  }
`

const TagClickablePrimary = css`
  background-color: ${PRIMARY};

  &:hover {
    background-color: ${GRAY};
  }
`

const TagClickableDefault = css`
  background-color: ${GRAY};

  &:hover {
    background-color: ${PRIMARY};
  }
`

const Tag = styled.span<{ clickable: boolean; color: 'default' | 'primary' }>`
  & {
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    color: ${WHITE};
    border-radius: ${BORDER_RADIUS_CARD};
    padding: 3px 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    white-space: pre-wrap;
    margin-right: 5px;
    margin-bottom: 3px;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    ${(props) => (props.clickable ? TagClickable : '')}
    ${(props) => (props.clickable && props.color === 'default' ? TagClickableDefault : '')}
    ${(props) => (props.clickable && props.color === 'primary' ? TagClickablePrimary : '')}
    ${(props) =>
    !props.clickable && props.color === 'primary' ? `background-color: ${PRIMARY};` : ''}
    ${(props) =>
    !props.clickable && props.color === 'default' ? `background-color: ${GRAY};` : ''}
  }
`

export default ContactTag
