import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper, { PopperProps } from '@material-ui/core/Popper'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContact } from '../../entities'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { setErrorUpdateContact } from '../../store/participant'
import ContactNoteTag from './ContactNoteTag'

interface IProps extends Omit<PopperProps, 'children'> {
  open: boolean
  anchorRef: React.RefObject<HTMLDivElement> | React.RefObject<HTMLButtonElement>
  contact: IContact
  setOpen: (value: boolean) => void
  handleClose: (event: React.MouseEvent<EventTarget>) => void
}

const ContactPopperNoteTags: React.FC<IProps> = ({
  open,
  anchorRef,
  contact,
  setOpen,
  handleClose,
  ...props
}) => {
  const magicStream = useMagicStream()
  const errorUpdateContact = useSelector(
    (state: IRootState) => state.participantState.errorUpdateContact
  )
  const dispatch = useThunkDispatch()

  useEffect(() => {
    if (errorUpdateContact) {
      magicStream.error(errorUpdateContact)
      dispatch(setErrorUpdateContact(undefined))
    }
  }, [errorUpdateContact, dispatch, magicStream])

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      style={{ zIndex: 1300 }}
      {...props}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <StyledPopperContent>
                <Typography variant="h4">
                  <FormattedMessage id="contacts.noteTags.title" />
                </Typography>
                <ContactNoteTag contact={contact} showLabel />
              </StyledPopperContent>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

const StyledPopperContent = styled.div`
  & {
    width: 200px;
    padding: 10px;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 1rem;
      margin-bottom: 10px;
    }
    textarea {
      margin-bottom: 10px;
    }
  }
`

export default ContactPopperNoteTags
