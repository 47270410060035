import styled from '@emotion/styled'
import { Input } from '@material-ui/core'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../redux'

interface IQuestionBoxProps {
  text: string
  handleTextChange: (e: any) => void
}

const QuestionBox: FC<IQuestionBoxProps> = ({ text, handleTextChange }) => {
  const intl = useIntl()
  const sending = useSelector((state: IRootState) => state.questionsState.sending)

  return (
    <StyledQuestionBox>
      <StyledInput
        disabled={sending}
        type="text"
        value={text}
        placeholder={intl.formatMessage({ id: 'question.input' })}
        className="form-control"
        onChange={handleTextChange}
        onKeyDown={handleTextChange}
      />
    </StyledQuestionBox>
  )
}

const StyledQuestionBox = styled.div`
  display: flex;
  justify-content: center;
`

const StyledInput = styled(Input)`
  width: 100%;
  margin: 0 5px;
`

export default QuestionBox
