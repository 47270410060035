import { IExhibitorUpdate } from '../entities'
import settings from '../settings'
import { apiPatchRequest } from './requests'

export const followExhibitor = async (exhibitorId: number) => {
  const response = await apiPatchRequest<IExhibitorUpdate>(
    `${settings.url.api}/e_events/exhibitor/${exhibitorId}/follow`,
    {
      isFollowed: true
    }
  )
  return response
}

export const followConference = async (conferenceId: number) => {
  const response = await apiPatchRequest<IExhibitorUpdate>(
    `${settings.url.api}/conf/${conferenceId}/follow`,
    {
      isFollowed: true
    }
  )
  return response
}

export const unFollowExhibitor = async (exhibitorId: number) => {
  const response = await apiPatchRequest<IExhibitorUpdate>(
    `${settings.url.api}/e_events/exhibitor/${exhibitorId}/unfollow`,
    {
      isFollowed: false
    }
  )
  return response
}

export const unFollowConference = async (conferenceId: number) => {
  const response = await apiPatchRequest<IExhibitorUpdate>(
    `${settings.url.api}/conf/${conferenceId}/unfollow`,
    {
      isFollowed: false
    }
  )
  return response
}
