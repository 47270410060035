import { IExhibitorDocumentPost } from '../entities'
import settings from '../settings'
import consoleUtils from '../utils/consoleUtils'
import { apiFormRawRequest, apiRequest } from './requests'

export const addExhibitorDocument = async (document: IExhibitorDocumentPost) => {
  try {
    const documentForm = new FormData()
    documentForm.append('documentFile', document.documentFile)
    documentForm.append('businessEventExhibitor', document.businessEventExhibitor.toString())
    const response = await apiFormRawRequest<FormData>(
      'POST',
      `${settings.url.api}/documents`,
      documentForm,
      true
    )
    return response
  } catch (e) {
    consoleUtils.error(e)
  }
}

export const deleteExhibitorDocument = async (documentId: number) => {
  const response = await apiRequest<boolean>(
    'DELETE',
    `${settings.url.api}/documents/${documentId}`
  )
  return response
}
