import styled from '@emotion/styled'
import { Button, IconButton } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useTooltip } from '../../../../hooks'
import { IRootState } from '../../../../redux'
import { WHITE } from '../../../../theme/colors'

interface IZoomButtonProps {
  externalMeetingId: number | string
  password?: string
  displayIcon?: boolean
}

const ZoomButton: React.FC<IZoomButtonProps> = ({ externalMeetingId, password, displayIcon }) => {
  const intl = useIntl()
  const user = useSelector((state: IRootState) => state.appState.user)
  const { anchorRef: anchorJoinApp, setOpen: setOpenJoinApp, Tooltip: TooltipJoinApp } = useTooltip(
    intl.formatMessage({
      id: 'actions.zoom.join.app'
    })
  )

  const joinApp = () => {
    window.open(
      `https://zoom.us/j/${externalMeetingId}?email=${user?.email}${password ? `&pwd=${password}&` : ''}`,
      '_blank'
    )
  }

  return (
    <StyledDiv displayIcon={displayIcon}>
      {displayIcon ? (
        <IconButton
          ref={anchorJoinApp}
          onMouseOver={() => setOpenJoinApp(true)}
          onMouseOut={() => setOpenJoinApp(false)}
          onClick={joinApp}
        >
          <ExitToAppIcon
            fontSize="large"
            titleAccess={intl.formatMessage({
              id: 'titleAccess.JoinAppIcon'
            })}
          />
          <TooltipJoinApp />
        </IconButton>
      ) : (
        <StyledButton
          variant="contained"
          startIcon={<ExitToAppIcon />}
          color="secondary"
          onClick={joinApp}
        >
          <FormattedMessage id="actions.zoom.join.app" />
        </StyledButton>
      )}
    </StyledDiv>
  )
}

const StyledDiv = styled.div<{ displayIcon?: boolean }>`
  & {
    margin-bottom: ${(props) => (props.displayIcon ? '0' : '10px')};
  }
`

const StyledButton = styled(Button)`
  & {
    text-transform: uppercase;

    svg {
      height: 20px;
      width: 20px;
      color: ${WHITE};
    }
  }
`

export default ZoomButton
