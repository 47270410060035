import styled from '@emotion/styled'
import { alpha, IconButton } from '@material-ui/core'
import CallEndIcon from '@material-ui/icons/CallEnd'
import React from 'react'
import { FullScreenHandle } from 'react-full-screen'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgFullScreen } from '../../../../assets/images/icons/expand.svg'
import { ReactComponent as SvgFullScreenExit } from '../../../../assets/images/icons/reduce.svg'
import { IEnterRoomZoom } from '../../../../entities'
import { useThunkDispatch, useTooltip } from '../../../../hooks'
import { IRootState } from '../../../../redux'
import { setFullscreen } from '../../../../store/stand'
import { BLACK_BLUE, RED, WHITE } from '../../../../theme/colors'
import { BORDER_RADIUS_CARD, CONTACTS_MENU_WIDTH } from '../../../../theme/sizes'
import ZoomButton from './ZoomButton'

interface IProps {
  zoomRoom: IEnterRoomZoom | null | undefined
  handleLeave?: () => void
  handleFullscreen?: FullScreenHandle
}

const ZoomActions: React.FC<IProps> = ({ zoomRoom, handleLeave, handleFullscreen }) => {
  const intl = useIntl()
  const fullscreen = useSelector((state: IRootState) => state.standState.fullscreen)
  const {
    anchorRef: anchorRefFullscreen,
    setOpen: setOpenFullscreen,
    Tooltip: TooltipFullscreen
  } = useTooltip(
    intl.formatMessage({
      id: fullscreen ? 'actions.fullscreen.exit' : 'actions.fullscreen.enter'
    })
  )
  const { anchorRef: anchorRefHangup, setOpen: setOpenHangup, Tooltip: TooltipHangup } = useTooltip(
    intl.formatMessage({
      id: 'actions.hangup'
    })
  )
  const dispatch = useThunkDispatch()

  const toggleFullscreen = () => {
    dispatch(setFullscreen(!fullscreen))
    if (handleFullscreen) {
      if (fullscreen) {
        handleFullscreen.exit()
      } else {
        handleFullscreen.enter()
      }
    }
  }

  return (
    <StyledZoomActions fullscreen={fullscreen}>
      <ToolbarActions>
        <ActionsContainer>
          <Actions>
            {zoomRoom && (
              <ZoomButton
                externalMeetingId={zoomRoom.externalMeetingId}
                password={zoomRoom.password}
                displayIcon
              />
            )}
            <IconButton
              ref={anchorRefFullscreen}
              onMouseOver={() => setOpenFullscreen(true)}
              onMouseOut={() => setOpenFullscreen(false)}
              onClick={toggleFullscreen}
            >
              {fullscreen ? <SvgFullScreenExit /> : <SvgFullScreen />}
              <TooltipFullscreen />
            </IconButton>
          </Actions>
        </ActionsContainer>
        {handleLeave && (
          <HangUpContainer>
            <IconButton
              ref={anchorRefHangup}
              onMouseOver={() => setOpenHangup(true)}
              onMouseOut={() => setOpenHangup(false)}
              onClick={handleLeave}
            >
              <CallEndIcon
                fontSize="large"
                titleAccess={intl.formatMessage({
                  id: 'titleAccess.CallEndIcon'
                })}
              />
              <TooltipHangup />
            </IconButton>
          </HangUpContainer>
        )}
      </ToolbarActions>
    </StyledZoomActions>
  )
}

const StyledZoomActions = styled.div<{ fullscreen: boolean }>`
  position: fixed;
  z-index: 1500;
  bottom: 10px;
  ${(props) => (props.fullscreen ? 'right: 215px;' : `right: calc(${CONTACTS_MENU_WIDTH} + 35px)`)};
  @media (max-width: 675px) {
    right: 120px;
  }
  @media (max-width: 375px) {
    right: 70px;
  }
  display: flex;
  flex-direction: column;
`

const ToolbarActions = styled.div`
  display: flex;
  flex-direction: row;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 300px;
  background-color: ${WHITE};
  box-shadow: 0 0 30px 0 ${alpha(BLACK_BLUE, 0.14)};
  padding: 10px;
  border-radius: ${BORDER_RADIUS_CARD};
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const HangUpContainer = styled.div`
  background-color: ${RED};
  box-shadow: 0 0 30px 0 ${alpha(BLACK_BLUE, 0.14)};
  padding: 10px;
  margin-left: 10px;
  border-radius: ${BORDER_RADIUS_CARD};

  & .MuiSvgIcon-root {
    fill: ${WHITE};
  }
`

export default ZoomActions
