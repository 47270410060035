import styled from '@emotion/styled'
import { Drawer } from '@material-ui/core'
import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { setErrorPresences } from '../../store/participant'
import { BACKGROUND_PRIMARY_LIGHT, BLACK_BLUE, GRAY_BORDER, WHITE } from '../../theme/colors'
import {
  BORDER_RADIUS_CARD,
  CONTACTS_MENU_WIDTH,
  HEADER_CONTACTS_HEIGHT,
  HEADER_HEIGHT
} from '../../theme/sizes'
import { formatName } from '../../utils'
import { breakpoints } from '../breakpoints'
import ContactsPanel from './ContactsPanel'
import PersonsPanel from './PersonsPanel'
import StreamsPanel from './StreamsPanel'
import VisitorsPanel from './VisitorsPanel'

const ContactsDrawer: FC = () => {
  const magicStream = useMagicStream()
  const user = useSelector((state: IRootState) => state.appState.user)
  const groupId = useSelector((state: IRootState) => state.appState.groupId)
  const openAgenda = useSelector((state: IRootState) => state.appState.openAgenda)
  const openMenuContact = useSelector((state: IRootState) => state.appState.openMenuContact)
  const contacts = useSelector((state: IRootState) => state.participantState.contacts)
  const presenceUsers = useSelector((state: IRootState) => state.participantState.presenceUsers)
  const errorPresences = useSelector((state: IRootState) => state.participantState.errorPresences)
  const aroundUsers = useSelector((state: IRootState) => state.participantState.aroundUsers)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const dispatch = useThunkDispatch()

  const isAppointmentMode = useMemo(() => eventCo && eventCo.eventcoAppointment, [eventCo])

  const memoPresenceUsersNotUser = useMemo(
    () => user && presenceUsers.filter((presenceUser) => presenceUser.id !== user.id),
    [presenceUsers, user]
  )

  const memoPresenceUsersIsNotEmpty = useMemo(
    () => user && presenceUsers.filter((presenceUser) => presenceUser.id !== user.id).length > 0,
    [presenceUsers, user]
  )

  const memoAroundUsersNotUser = useMemo(
    () => user && aroundUsers.filter((aroundUser) => aroundUser.id !== user.id),
    [aroundUsers, user]
  )

  const memoAroundUsersIsNotEmpty = useMemo(
    () => user && aroundUsers.filter((aroundUser) => aroundUser.id !== user.id).length > 0,
    [aroundUsers, user]
  )

  const memoContacts = useMemo(
    () =>
      contacts
        .filter((contact) => contact.id !== user!.id)
        .sort((c1, c2) => {
          if (
            (c1.userPresence.isOnline && c2.userPresence.isOnline) ||
            (!c1.userPresence.isOnline && !c2.userPresence.isOnline)
          ) {
            return formatName(c1.userPresence.user).full.localeCompare(formatName(c2.userPresence.user).full)
          } else if (c1.userPresence.isOnline) {
            return -1
          } else if (c2.userPresence.isOnline) {
            return 1
          }
          return 0
        }),
    [contacts, user]
  )

  useEffect(() => {
    if (errorPresences) {
      magicStream.error(errorPresences)
      dispatch(setErrorPresences(undefined))
    }
  }, [errorPresences, magicStream, dispatch])

  return (
    <StyledDrawer anchor="right" variant="permanent" open={openMenuContact} agendamode={openAgenda}>
      {/* Magic stream */}
      <StreamsPanel />
      {/* Personnes autour */}
      {!isAppointmentMode &&
        groupId !== settings.eventGroupId &&
        memoAroundUsersNotUser &&
        memoAroundUsersIsNotEmpty && (
          <PersonsPanel
            half={!!memoContacts}
            titleKey="persons.around"
            persons={memoAroundUsersNotUser}
          />
        )}
      {/* Personnes sur l'app */}
      {!isAppointmentMode &&
        groupId === settings.eventGroupId &&
        memoPresenceUsersNotUser &&
        memoPresenceUsersIsNotEmpty && (
          <PersonsPanel
            half={!!memoContacts}
            titleKey="persons.on.app"
            persons={memoPresenceUsersNotUser}
            listMode
          />
        )}
      {/* Contacts Panel */}
      {!isAppointmentMode && (
        <>
          {!eventCo?.eventcoRemoteOffice && !settings.disableFeatureContacts ? (
            <ContactsPanel
              half={
                (groupId !== settings.eventGroupId &&
                  !!memoAroundUsersNotUser &&
                  !!memoAroundUsersIsNotEmpty) ||
                (groupId === settings.eventGroupId &&
                  !!memoPresenceUsersNotUser &&
                  !!memoPresenceUsersIsNotEmpty)
              }
            />
          ) : (
            <VisitorsPanel
              half={
                (groupId !== settings.eventGroupId &&
                  !!memoAroundUsersNotUser &&
                  !!memoAroundUsersIsNotEmpty) ||
                (groupId === settings.eventGroupId &&
                  !!memoPresenceUsersNotUser &&
                  !!memoPresenceUsersIsNotEmpty)
              }
            />
          )}
        </>
      )}
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer) <{ open: boolean; agendamode: boolean }>`
  & {
    flex-shrink: 0;

    ul {
      height: 100%;
    }

    > div {
      right: ${(props) => `${props.open ? '0' : '-100vw'}`};
      margin-top: ${HEADER_HEIGHT};
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      overflow-x: hidden;
      overflow-y: ${(props) => `${props.agendamode ? 'hidden' : 'auto'}`};
      white-space: nowrap;
      background-color: ${BACKGROUND_PRIMARY_LIGHT};
      transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        right 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      border: none;
      z-index: 1201;

      @media (min-width: ${breakpoints.lg}) {
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        right: 20px;
        width: calc(${CONTACTS_MENU_WIDTH} - 20px);
      }
    }
    .MuiListItem-button {
      white-space: nowrap !important;
      width: 100%;
    }
    .MuiListSubheader-root {
      position: relative;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${BLACK_BLUE};
      padding: 15px;
      font-weight: bold;
      font-size: 18px;
      height: ${HEADER_CONTACTS_HEIGHT};
      line-height: 21px;
      border-bottom: 1px solid ${GRAY_BORDER};
      border-radius: ${BORDER_RADIUS_CARD} ${BORDER_RADIUS_CARD} 0 0;
      background-color: ${WHITE};
    }
  }
`

export default ContactsDrawer
