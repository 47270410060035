import {
  ICustomEventsFilters,
  ICustomExhibitorsFilters,
  ICustomProductsFilters,
  ICustomUsersFilters
} from '../entities/filters'
import settings from '../settings'
import { apiRequest } from './requests'

export const getFiltersUser = async () => {
  const response = await apiRequest<ICustomUsersFilters>(
    'GET',
    `${settings.url.api}/filters?groupId=${settings.eventGroupId}&filtersType=user`
  )
  return response.customUsersFilters
}

export const getFiltersExhibitor = async () => {
  const response = await apiRequest<ICustomExhibitorsFilters>(
    'GET',
    `${settings.url.api}/filters?groupId=${settings.eventGroupId}&filtersType=exhibitor`
  )
  return response.customExhibitorsFilters
}

export const getFiltersEvent = async () => {
  const response = await apiRequest<ICustomEventsFilters>(
    'GET',
    `${settings.url.api}/filters?groupId=${settings.eventGroupId}&filtersType=event`
  )
  return response.customEventsFilters
}

export const getFiltersProduct = async () => {
  const response = await apiRequest<ICustomProductsFilters>(
    'GET',
    `${settings.url.api}/filters?groupId=${settings.eventGroupId}&filtersType=product`
  )
  return response.customProductsFilters
}
