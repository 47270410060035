import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PROFILINGS } from '../../entities'
import { WHITE } from '../../theme/colors'
import ProfilingFullscreen from '../profile/ProfilingFullscreen'

type IProfilingProps = {
  type: PROFILINGS
  exhibitorId?: number
  conferenceId?: number
  onClose?: () => void
}

const Profiling: React.FC<IProfilingProps> = ({ type, exhibitorId, conferenceId, onClose }) => {
  const [profiling, setProfiling] = useState(false)

  const handleDoProfiling = () => {
    setProfiling(true)
  }

  const handleClose = () => {
    setProfiling(false)
    onClose && onClose()
  }

  return (
    <StyledDiv>
      <StyledButton
        variant="contained"
        startIcon={<AssignmentIndIcon />}
        color="secondary"
        onClick={handleDoProfiling}
      >
        <FormattedMessage id="button.profiling" />
      </StyledButton>

      <ProfilingFullscreen
        opened={profiling}
        type={type}
        exhibitorId={exhibitorId}
        conferenceId={conferenceId}
        onClose={handleClose}
      />
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  margin-bottom: 10px;
`

const StyledButton = styled(Button)`
  & {
    text-transform: uppercase;
    text-align: left;

    svg {
      height: 20px;
      width: 20px;
      color: ${WHITE};
    }
  }
`

export default Profiling
