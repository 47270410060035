import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IBaseRootState } from '..'
import {
  addContactKeyword as addContactKeywordApi,
  addContactNote as addContactNoteApi,
  addFavoriteContact as addFavoriteContactApi,
  deleteContact as deleteContactApi,
  deleteKeyword as deleteKeywordApi,
  editKeyword as editKeywordApi,
  getContact as getContactApi,
  getContacts,
  getFiltersUser as getApiFiltersUser,
  getFollowers,
  getKeywordsCreatedByUser,
  getParticipants,
  getPresenceUsers,
  getTotalUsersConnected,
  removeContactKeyword as removeContactKeywordApi,
  removeContactNote as removeContactNoteApi,
  updateContactNote as updateContactNoteApi
} from '../../api'
import { IContact, IContactNote } from '../../entities'
import { IContactKeyword, IKeyword } from '../../entities/keyword'
import consoleUtils from '../../utils/consoleUtils'
import { updateConferenceRoomUsersOnline } from '../conference'
import { updateExhibitorRoomUsersOnline } from '../exhibitor'
import {
  addContactRefreshed,
  addNewContactKeyword,
  addNewContactNote,
  deleteContactKeyword,
  deleteContactNote,
  editContactKeywordUsers,
  editContactNote,
  editKeywordCreatedByUser,
  mergeContact,
  refreshContact,
  removeContact,
  removeContactKeywordUsers,
  removeKeywordCreatedByUser,
  setAdding,
  setAroundUsers,
  setAroundUsersCount,
  setContacts,
  setContactsFiltered,
  setDeleting,
  setErrorAdding,
  setErrorContacts,
  setErrorContactsFiltered,
  setErrorDeleting,
  setErrorFollowers,
  setErrorParticipants,
  setErrorParticipantsFiltered,
  setErrorPresences,
  setErrorUpdateContact,
  setErrorUserFilters,
  setFollowers,
  setKeywordsCreatedByUser,
  setKeywordsCreatedByUserError,
  setKeywordsCreatedByUserLoaded,
  setKeywordsCreatedByUserLoading,
  setLoading,
  setLoadingContacts,
  setLoadingContactsFiltered,
  setLoadingFollowers,
  setLoadingParticipantsFiltered,
  setLoadingUpdateNote,
  setLoadingUpdateTags,
  setLoadingUserFilters,
  setParticipants,
  setParticipantsFiltered,
  setParticipantsSearch,
  setPresenceUsers,
  setPresenceUsersCount,
  setRefreshingContact,
  setUserFilters,
  updateContactIsContact,
  updateContactOnline
} from './actions'
import { IParticipantFilters } from './model'

export const loadParticipants = (filters: IParticipantFilters) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoading(true))
  if (filters.location) {
    dispatch(setParticipants({ total: 0, items: [] }))
    dispatch(setParticipantsSearch(filters.location))
  }
  try {
    // Loading participants
    const participants = await getParticipants(
      filters.page,
      filters.search,
      filters.functionName,
      filters.partnerType,
      filters.isOnline,
      filters.isContact,
      filters.tags,
      filters.keywordsX,
      filters.keywordsY,
      filters.sortBy,
      filters.roles
    )
    dispatch(setParticipants(participants))
  } catch (e) {
    consoleUtils.error(e)
    dispatch(
      setErrorParticipants(
        'Erreur lors du chargement des participants, veuillez rafraichir la page'
      )
    )
  }
  dispatch(setLoading(false))
}

export const loadParticipantsFiltered = (filters: IParticipantFilters) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoadingParticipantsFiltered(true))
  try {
    // Loading participants
    const participants = await getParticipants(
      filters.page,
      filters.search,
      filters.functionName,
      filters.partnerType,
      filters.isOnline,
      filters.isContact,
      filters.tags,
      filters.keywordsX,
      filters.keywordsY,
      filters.sortBy
    )
    dispatch(setParticipantsFiltered(participants))
  } catch (e) {
    consoleUtils.error(e)
    dispatch(
      setErrorParticipantsFiltered(
        'Erreur lors du chargement des participants, veuillez rafraichir la page'
      )
    )
  }
  dispatch(setLoadingParticipantsFiltered(false))
}

export const loadContacts = () => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoadingContacts(true))
  try {
    // Loading contacts
    const contacts = await getContacts()
    dispatch(setContacts(contacts))
  } catch (e) {
    consoleUtils.error(e)
    dispatch(
      setErrorContacts('Erreur lors du chargement des contacts, veuillez rafraichir la page')
    )
  }
  dispatch(setLoadingContacts(false))
}

export const loadContactsFiltered = (search?: string, tags?: string, keywordsX?: string, keywordsY?: string) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoadingContactsFiltered(true))
  try {
    // Loading contacts
    const contactsFiltered = await getContacts(search, tags, keywordsX, keywordsY)
    dispatch(setContactsFiltered(contactsFiltered))
  } catch (e) {
    consoleUtils.error(e)
    dispatch(
      setErrorContactsFiltered(
        'Erreur lors du chargement des contacts filtrés, veuillez rafraichir la page'
      )
    )
  }
  dispatch(setLoadingContactsFiltered(false))
}

export const loadFollowers = (type: string, id: number) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoadingFollowers(true))
  try {
    const followers = await getFollowers(type, id)
    dispatch(setFollowers(followers))
  } catch (e) {
    dispatch(setErrorFollowers((e as any).message))
  }
  dispatch(setLoadingFollowers(false))
}

export const addFavoriteContact = (id: number, isOnline: boolean) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setAdding(id))
  try {
    // add contact
    const contact = await addFavoriteContactApi(id)
    contact.userPresence.isOnline = isOnline
    contact.isContact = true
    dispatch(mergeContact(contact))
    dispatch(updateContactIsContact(id, true))
  } catch (e) {
    dispatch(setErrorAdding((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setAdding(null))
}

export const deleteContact = (id: number) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setDeleting(id))
  try {
    // delete contact
    await deleteContactApi(id)
    dispatch(removeContact(id))
    dispatch(updateContactIsContact(id, false))
  } catch (e) {
    dispatch(setErrorDeleting((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setDeleting(null))
}

export const updateContactsOnline = (userIds: number[], isOnline: boolean) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  try {
    consoleUtils.log(`set users "'${userIds.join(', ')}'" isOnline to '${isOnline}'`)
    // update structure with contacts
    dispatch(updateContactOnline(userIds, isOnline))
    // update exhibitor and conference rooms with user
    dispatch(updateExhibitorRoomUsersOnline(userIds, isOnline))
    dispatch(updateConferenceRoomUsersOnline(userIds, isOnline))
  } catch (e) {
    consoleUtils.error(e)
  }
}

export const refreshContactInfo = (contactId: number) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setRefreshingContact(contactId))
  try {
    const contactRefreshed = await getContactApi(contactId)
    // store contact refreshed
    dispatch(addContactRefreshed(contactRefreshed))
    // refresh occurence of contact
    dispatch(refreshContact(contactRefreshed))
  } catch (e) {
    consoleUtils.error(e)
  }
  dispatch(setRefreshingContact(null))
}

export const loadPresenceUsers = (groupId: number | string) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  try {
    // Loading presence users
    const presenceUsers = await getPresenceUsers(groupId)
    consoleUtils.log('> loadPresenceUsers de groupId=', groupId)
    dispatch(setPresenceUsers(presenceUsers.items))
  } catch (e) {
    consoleUtils.error(e)
    dispatch(
      setErrorPresences(
        'Erreur lors du chargement des personnes sur le site, veuillez rafraichir la page'
      )
    )
  }
}

export const loadTotalUsersConnected = () => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  try {
    const total = await getTotalUsersConnected()
    dispatch(setPresenceUsersCount(total || 1))
  } catch (e) {
    consoleUtils.error(e)
  }
}

export const loadAroundUsers = (groupId: number | string) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  try {
    // Loading around users
    const aroundUsers = await getPresenceUsers(groupId)
    consoleUtils.log('> loadAroundUsers de groupId=', groupId)
    dispatch(setAroundUsers(aroundUsers.items))
    dispatch(setAroundUsersCount(aroundUsers.total))
  } catch (e) {
    consoleUtils.error(e)
    dispatch(
      setErrorPresences(
        'Erreur lors du chargement des personnes "autour de moi", veuillez rafraichir la page'
      )
    )
  }
}

export const loadFiltersUsers = () => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoadingUserFilters(true))
  try {
    // load filters for users
    const filtersAPIResult = await getApiFiltersUser()
    dispatch(setUserFilters(filtersAPIResult))
  } catch (e) {
    dispatch(setErrorUserFilters((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingUserFilters(false))
}

export const addContactKeyword = (contact: IContact, keyword: IKeyword) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoadingUpdateTags(contact.id!, true))
  try {
    // add contact
    const contactKeyword = await addContactKeywordApi(contact, keyword)
    dispatch(addNewContactKeyword(contact.id!, contactKeyword))
  } catch (e) {
    dispatch(setErrorUpdateContact((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingUpdateTags(contact.id!, false))
}

export const removeContactKeyword = (contact: IContact, contactKeyword: IContactKeyword) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoadingUpdateTags(contact.id!, true))
  try {
    // add contact
    await removeContactKeywordApi(contactKeyword)
    dispatch(deleteContactKeyword(contact.id!, contactKeyword))
  } catch (e) {
    dispatch(setErrorUpdateContact((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingUpdateTags(contact.id!, false))
}

export const loadKeywordsCreatedByUser = (userId: number) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setKeywordsCreatedByUserLoading(true))
  try {
    const keywords = await getKeywordsCreatedByUser(userId)
    dispatch(setKeywordsCreatedByUser(keywords.items))
    dispatch(setKeywordsCreatedByUserLoaded(true))
  } catch (e) {
    dispatch(setKeywordsCreatedByUserError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setKeywordsCreatedByUserLoading(false))
}

export const removeKeywordForUser = (userId: number, keyword: IKeyword) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoading(true))
  dispatch(setLoadingContacts(true))
  dispatch(setLoadingContactsFiltered(true))
  dispatch(setLoadingParticipantsFiltered(true))
  dispatch(setKeywordsCreatedByUserLoading(true))
  try {
    await deleteKeywordApi(keyword.id!)
    dispatch(removeKeywordCreatedByUser(keyword))
    dispatch(removeContactKeywordUsers(keyword))
    dispatch(setKeywordsCreatedByUserLoaded(true))
  } catch (e) {
    dispatch(setKeywordsCreatedByUserError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoading(false))
  dispatch(setLoadingContacts(false))
  dispatch(setLoadingContactsFiltered(false))
  dispatch(setLoadingParticipantsFiltered(false))
  dispatch(setKeywordsCreatedByUserLoading(false))
}

export const editKeywordForUser = (userId: number, keyword: IKeyword, title: string) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoading(true))
  dispatch(setLoadingContacts(true))
  dispatch(setLoadingContactsFiltered(true))
  dispatch(setLoadingParticipantsFiltered(true))
  dispatch(setKeywordsCreatedByUserLoading(true))
  try {
    const newKeyword = await editKeywordApi(keyword.id!, title)
    dispatch(editKeywordCreatedByUser(newKeyword))
    dispatch(editContactKeywordUsers(newKeyword))
    dispatch(setKeywordsCreatedByUserLoaded(true))
  } catch (e) {
    dispatch(setKeywordsCreatedByUserError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoading(false))
  dispatch(setLoadingContacts(false))
  dispatch(setLoadingContactsFiltered(false))
  dispatch(setLoadingParticipantsFiltered(false))
  dispatch(setKeywordsCreatedByUserLoading(false))
}

export const addContactNote = (contact: IContact, note: string) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoadingUpdateNote(contact.id!, true))
  try {
    const returnedNote = await addContactNoteApi(contact, note)
    dispatch(addNewContactNote(contact.id!, returnedNote))
  } catch (e) {
    dispatch(setErrorUpdateContact((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingUpdateNote(contact.id!, false))
}

export const updateContactNote = (
  contact: IContact,
  note: IContactNote,
  noteNewValue: string
) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
  dispatch(setLoadingUpdateNote(contact.id!, true))
  try {
    const returnedNote = await updateContactNoteApi(note, noteNewValue)

    dispatch(editContactNote(contact.id!, returnedNote))
  } catch (e) {
    dispatch(setErrorUpdateContact((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingUpdateNote(contact.id!, false))
}

export const removeContactNote = (contact: IContact, note: IContactNote) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoadingUpdateNote(contact.id!, true))
  try {
    await removeContactNoteApi(note)

    dispatch(deleteContactNote(contact.id!))
  } catch (e) {
    dispatch(setErrorUpdateContact((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingUpdateNote(contact.id!, false))
}
