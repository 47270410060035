import styled from '@emotion/styled'
import { Button, Grid } from '@material-ui/core'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import React, { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import imageLogoIdealco from '../../../assets/images/logo-idealco.png'
import { GRAY_ICON } from '../../../theme/colors'

const Footer: FC = () => {
  const intl = useIntl()

  return (
    <StyledFooter>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            target="_blank"
            href={intl.formatMessage({ id: 'footer.generals.conditions.link' })}
          >
            <FormattedMessage id="footer.generals.conditions.label" />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button target="_blank" href={intl.formatMessage({ id: 'footer.mentions.legals.link' })}>
            <FormattedMessage id="footer.mentions.legals.label" />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button target="_blank" href={intl.formatMessage({ id: 'footer.email.link' })}>
            <FormattedMessage id="footer.email.label" />
          </Button>
        </Grid>
        <StyledGridBaseline item xs={12}>
          <FormattedMessage
            id="footer.presented.by"
            values={{
              icon: <InsertEmoticonIcon fontSize="small" />,
              logo: <StyledLogoIdealCo src={imageLogoIdealco} />
            }}
          />
        </StyledGridBaseline>
      </Grid>
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  & {
    width: 100%;
    margin: 70px auto 0;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: ${GRAY_ICON};

    > div {
      max-width: 1280px;
      display: flex;
      align-items: center;
    }
    svg {
      color: ${GRAY_ICON};
    }
    .MuiButton-label {
      color: ${GRAY_ICON};
      font-weight: normal;
    }
  }
`

const StyledLogoIdealCo = styled.img`
  & {
    height: 30px;
  }
`

const StyledGridBaseline = styled(Grid)`
  margin-top: 20px;
`

export default Footer
