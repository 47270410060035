import { IQuestion } from '../../entities/questions'

export enum ActionsTypes {
  SET_LOADING_QUESTIONS = 'SET_LOADING_QUESTIONS',
  SET_ERROR_QUESTIONS = 'SET_ERROR_QUESTIONS',
  SET_SENDING_QUESTION = 'SET_SENDING_QUESTION',
  SET_UPDATING_QUESTION = 'SET_UPDATING_QUESTION',
  SET_DELETING_QUESTION = 'SET_DELETING_QUESTION',
  SET_QUESTIONS = 'SET_QUESTIONS',
  ADD_QUESTION = 'ADD_QUESTION',
  DELETE_QUESTION = 'DELETE_QUESTION',
  SET_COUNT_UP = 'SET_COUNT_UP',
  INCREMENT_COUNT_UP = 'INCREMENT_COUNT_UP',
  DECREMENT_COUNT_UP = 'DECREMENT_COUNT_UP'
}

export interface IQuestionState {
  loading: boolean
  sending: boolean
  updating: { [key: number]: boolean }
  deleting: boolean
  error?: string
  questions: IQuestion[]
}
