import React from 'react'

const SvgHelp: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M47.278 29.389c1.778-3 2.722-6.445 2.722-9.945C50 8.722 41.278 0 30.556 0 19.833 0 11.11 8.722 11.11 19.444c0 1.167.111 2.334.333 3.5C5 23.834 0 29.444 0 36.111c0 2.333.611 4.667 1.778 6.667l-1.5 5.333c-.111.389 0 .833.278 1.111.222.222.5.334.777.334.111 0 .223 0 .334-.056l5.5-1.611a13.41 13.41 0 0 0 6.222 1.555c6.555 0 12.055-4.777 13.111-11 1.333.278 2.667.445 4.111.445 3.222 0 6.445-.833 9.278-2.333L48.333 39c.111.056.223.056.334.056.277 0 .555-.112.777-.334.278-.278.39-.722.278-1.11l-2.444-8.223zM13.333 47.222c-1.944 0-3.833-.5-5.555-1.444a.939.939 0 0 0-.556-.167c-.11 0-.222 0-.333.056l-4 1.166L4 43c.111-.278.056-.611-.111-.889a11.224 11.224 0 0 1-1.722-5.944c0-5.667 4.277-10.334 9.722-11a19.441 19.441 0 0 0 12.333 12.722c-.778 5.278-5.333 9.333-10.889 9.333zM40 34.278a.886.886 0 0 0-.833.11 17.152 17.152 0 0 1-8.556 2.279c-9.5 0-17.222-7.723-17.222-17.223S21.11 2.222 30.61 2.222s17.222 7.722 17.222 17.222c0 3.278-.944 6.445-2.666 9.223-.167.277-.223.61-.111.889L47 36.278l-7-2zm-2.278-19.556c.39 3.556-1.778 6.778-5.222 7.778-.5.167-.833.556-.833 1.056v1.388c0 .612-.5 1.112-1.111 1.112-.612 0-1.112-.5-1.112-1.112v-1.388-.278c.112-1.39 1.056-2.556 2.445-2.945 2.333-.666 3.889-2.944 3.611-5.389-.278-2.277-2.111-4.166-4.389-4.388a4.928 4.928 0 0 0-3.889 1.222 5.057 5.057 0 0 0-1.666 3.722c0 .389-.223.722-.5.944a1.12 1.12 0 0 1-.612.167c-.61 0-1.11-.5-1.11-1.111 0-2.056.888-4 2.388-5.389a7.227 7.227 0 0 1 5.611-1.778c3.334.39 6.056 3.111 6.39 6.39zm-6.389 13.945c.223.222.334.5.334.777 0 .278-.111.556-.334.778-.222.222-.5.334-.777.334-.278 0-.556-.112-.778-.334a1.097 1.097 0 0 1-.334-.778c0-.277.112-.555.334-.777.389-.445 1.166-.445 1.555 0z"
    />
  </svg>
)

export default SvgHelp
