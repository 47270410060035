import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IRoom } from '../../../entities'
import { useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import { loadConferenceEmbeded } from '../../../store/conference'
import { BLACK_BLUE } from '../../../theme/colors'
import pusherUtils from '../../../utils/pusherUtils'
import ConferencePlayer from '../../conference/ConferencePlayer'
import Loading from '../../Loading'

export interface IEventRoomProps {
  room: IRoom
  handleLeave?: () => void
  handleRenameRoom?: (value: string) => void
}

const EventRoom: React.FC<IEventRoomProps> = ({ room, handleLeave, handleRenameRoom }) => {
  const [videoPlaying, setVideoPlaying] = useState(true)
  const [videoVolume, setVideoVolume] = useState(1)
  const conference = useSelector((state: IRootState) => state.conferenceState.conferenceEmbeded)
  const loading = useSelector((state: IRootState) => state.conferenceState.loadingEmbeded)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    dispatch(loadConferenceEmbeded(room.sessionId))
    window.scrollTo(0, 0)
  }, [room.sessionId, dispatch])

  // subscribe group
  useEffect(() => {
    if (conference) {
      pusherUtils.subscribe(`group-${conference.group.id}`)
    }
    return () => {
      if (conference) {
        pusherUtils.unsubscribe(`group-${conference.group.id}`)
      }
    }
  }, [conference])

  return (
    <StyledEvent>
      {conference && !loading && (
        <ConferencePlayer
          conferenceId={room.sessionId}
          conference={conference}
          localGroupId={conference.group.id}
          videoPlaying={videoPlaying}
          videoVolume={videoVolume}
          title={room.title || room.roomClassName}
          handleLeave={handleLeave}
          handleRenameRoom={handleRenameRoom}
          setVideoVolume={setVideoVolume}
          setVideoPlaying={setVideoPlaying}
        />
      )}
      {loading && <Loading position="center" />}
    </StyledEvent>
  )
}

const StyledEvent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  color: ${BLACK_BLUE};
`

export default EventRoom
