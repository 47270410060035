import styled from '@emotion/styled'
import { alpha, Button, MenuList } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContact } from '../../entities'
import { useEffectOnce, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { getKeywordsByUser } from '../../store/app'
import { refreshContactInfo } from '../../store/participant/thunks'
import { PRIMARY_COLOR } from '../../theme'
import { BLACK_BLUE } from '../../theme/colors'
import { formatName } from '../../utils'
import ContactAvatar from '../globals/ContactAvatar/ContactAvatar'
import Loading from '../Loading'
import ContactDescriptionMore from './ContactDescriptionMore'
import ContactKeywordsMore from './ContactKeywordsMore'
import ContactPopperActions from './ContactPopperActions'

type IProps = {
  contact: IContact
  disabledActions?: boolean | null
  disabledSendMessage?: boolean | null
  isOnline?: boolean | undefined
  isOffice?: boolean | undefined
  onClose?: (event: React.MouseEvent<EventTarget>) => void
  onEnterOffice?: () => void
}

const ContactPopperContent = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const dispatch = useThunkDispatch()
  const {
    contact,
    disabledActions,
    disabledSendMessage,
    isOnline,
    isOffice,
    onEnterOffice,
    onClose
  } = props
  const user = useSelector((state: IRootState) => state.appState.user)
  const keywordsByUser = useSelector((state: IRootState) => state.appState.keywordsByUser)
  const loadingKeywordsByUser = useSelector(
    (state: IRootState) => state.appState.loadingKeywordsByUser
  )
  const userLocation = useSelector((state: IRootState) => state.appState.location)
  const refreshingContact = useSelector(
    (state: IRootState) => state.participantState.refreshingContact
  )
  const contactsRefreshed = useSelector(
    (state: IRootState) => state.participantState.contactsRefreshed
  )
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)

  const memoNotMe = useMemo(() => user && user.id !== contact.id, [user, contact])

  const memoContact = useMemo(() => (contact.id && contactsRefreshed[contact.id]) || contact, [
    contactsRefreshed,
    contact
  ])

  const showLocation = useMemo(
    () =>
      memoContact &&
      memoContact.userStatus &&
      memoContact.userStatus.appName === settings.theme.name &&
      memoContact.userStatus.url &&
      memoContact.userStatus.url !== userLocation,
    [memoContact, userLocation]
  )

  const sameLocation = useMemo(
    () =>
      memoContact &&
      memoContact.userStatus &&
      memoContact.userStatus.appName === settings.theme.name &&
      memoContact.userStatus.url &&
      memoContact.userStatus.url === userLocation,
    [memoContact, userLocation]
  )

  useEffectOnce(() => {
    if (contact.id) {
      dispatch(refreshContactInfo(contact.id))
      dispatch(getKeywordsByUser(contact.id))
    }
  })

  return (
    <StyledContactPopperContent ref={ref}>
      <StyledContactPopperContentTop>
        <ContactAvatar contact={memoContact} />
      </StyledContactPopperContentTop>
      <StyledUserInfo>
        <StyledUserName>
          {formatName(memoContact.userPresence.user).full}
        </StyledUserName>
        {memoContact.userPresence.user.description && (
          <ContactDescriptionMore description={memoContact.userPresence.user.description} />
        )}
        {memoContact.userPresence.user.function && (
          <StyledUserFunction>{memoContact.userPresence.user.function}</StyledUserFunction>
        )}
        {memoContact.userPresence.user.partner_id && (
          <StyledEnterprise>{memoContact.userPresence.user.partner_id.name}</StyledEnterprise>
        )}
        {!loadingKeywordsByUser && keywordsByUser && keywordsByUser.total > 0 && (
          <ContactKeywordsMore keywords={keywordsByUser.items} />
        )}
        {memoNotMe && refreshingContact && <Loading />}
        {!eventCo?.eventcoAppointment && memoNotMe && showLocation && memoContact.userStatus && (
          <StyledUserLocation>{memoContact.userStatus.description}</StyledUserLocation>
        )}
        {!eventCo?.eventcoAppointment && memoNotMe && sameLocation && (
          <StyledUserLocation>
            <FormattedMessage id="pathname.description.same" />
          </StyledUserLocation>
        )}
      </StyledUserInfo>
      {!disabledActions && (
        <StyledMenuList>
          <ContactPopperActions
            contact={memoContact}
            status={memoContact.userStatus}
            disabledSendMessage={disabledSendMessage}
            isOnline={isOnline}
            isOffice={isOffice}
            onEnterOffice={onEnterOffice}
            onClose={onClose}
          />
        </StyledMenuList>
      )}
      <StyledButton onClick={onClose}>
        <CloseIcon />
      </StyledButton>
    </StyledContactPopperContent>
  )
})

const StyledButton = styled(Button)`
  & {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    min-width: 40px;
  }
`

const StyledContactPopperContent = styled.div`
  & {
    position: relative;
    width: 250px;
    background-color: #fff;
    box-shadow: 0 9px 12px 0 ${alpha(BLACK_BLUE, 0.14)};
  }
`

const StyledContactPopperContentTop = styled.div`
  & {
    position: relative;
    height: 55px;
    background-color: ${PRIMARY_COLOR};
    margin-bottom: 30px;

    & > div,
    & > span {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 60px;
      width: 60px;
    }
    & > div .MuiAvatar-root,
    & > span .MuiAvatar-root {
      height: 60px;
      width: 60px;
    }
  }
`

const StyledUserInfo = styled.div`
  & {
    padding: 15px;
  }
`

const StyledUserName = styled.div`
  & {
    text-align: center;
    line-height: 19px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`

const StyledUserFunction = styled.div`
  & {
    text-align: center;
    line-height: 14px;
    font-size: 12px;
    margin-bottom: 5px;
  }
`

const StyledEnterprise = styled.div`
  & {
    display: block;
    max-height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
  }
`

const StyledUserLocation = styled.div`
  & {
    white-space: normal;
    line-height: 14px;
    font-size: 12px;
    color: ${alpha(BLACK_BLUE, 0.38)};
    margin-top: 10px;
  }
`

const StyledMenuList = styled(MenuList)`
  & {
    padding: 0;
    .MuiButtonBase-root {
      width: 100%;
    }
    .MuiListItemIcon-root {
      min-width: 46px;
    }
    svg {
      color: ${alpha(BLACK_BLUE, 0.38)};
    }
  }
`

export default ContactPopperContent
