import React from 'react'

const SvgArrowUp: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M24.576 15.85a2.414 2.414 0 0 0-1.603.66L7.888 30.991a2.42 2.42 0 0 0 3.356 3.489L24.651 21.6 38.058 34.48a2.42 2.42 0 0 0 3.356-3.489L26.329 16.51a2.414 2.414 0 0 0-1.753-.66z"
    />
  </svg>
)

export default SvgArrowUp
