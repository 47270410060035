import styled from '@emotion/styled'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgConferences } from '../../assets/images/icons/conferences.svg'
import { IMagicStream, MagicStreamType } from '../../entities'
import { useDateFns, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setExtendedPanel } from '../../store/app'
import { setPageReferral } from '../../store/gtm'
import { PRIMARY_COLOR } from '../../theme'
import { GRAY, VERY_LIGHT_GRAY, WHITE } from '../../theme/colors'

type IStreamConferenceProps = {
  stream: IMagicStream
}

const StreamConference: React.FC<IStreamConferenceProps> = ({ stream }) => {
  const intl = useIntl()
  const dateFns = useDateFns()
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const dispatch = useThunkDispatch()

  return (
    <StreamConferenceContainer
      onClick={() => {
        if (stream.objectId) {
          dispatch(setPageReferral(`${pageReferral}::ms-conference`))
          browserHistory.push(`/conference/${stream.objectId}`)
          dispatch(setExtendedPanel('magic.streams', false))
        }
      }}
    >
      <StyledIcon className="icon-hover">
        <SvgConferences />
      </StyledIcon>
      <StreamConferenceContent>
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              {
                id:
                  stream.streamType === MagicStreamType.REMINDER_CONFERENCE
                    ? 'reminder.conference'
                    : 'suggest.conference'
              },
              { title: `<b>${stream.content}</b>` }
            )
          }}
        />
        <StyledDate>{dateFns.formatDistanceToNow(stream.writeDate || stream.createDate)}</StyledDate>
      </StreamConferenceContent>
    </StreamConferenceContainer>
  )
}

const StreamConferenceContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  padding: 7px 0;
  cursor: pointer;

  &:hover {
    span {
      opacity: 1;
    }
    .icon-hover {
      background-color: ${WHITE};
    }
  }
`

const StreamConferenceContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`

const StyledIcon = styled.div`
  background-color: ${VERY_LIGHT_GRAY};
  border-radius: 50%;
  padding: 11px;

  & > svg {
    height: 16px;
    width: 16px;
    color: ${PRIMARY_COLOR};
  }
`

const StyledDate = styled.span`
  position: absolute;
  right: 3px;
  bottom: 1px;
  opacity: 0;
  font-size: 10px;
  color: ${GRAY};
`

export default StreamConference
