import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IJitsiState } from './model'

const initialState: IJitsiState = {
  loadingJitsi: false,
  jitsiRoom: null
}

const actionHandlers: IReducerHandler<IJitsiState> = {
  [ActionsTypes.SET_LOADING_JITSI]: (
    state: IJitsiState | undefined,
    { loadingJitsi }: AnyAction
  ): IJitsiState => ({
    ...state!,
    loadingJitsi
  }),

  [ActionsTypes.SET_JITSI_ROOM]: (
    state: IJitsiState | undefined,
    { jitsiRoom }: AnyAction
  ): IJitsiState => ({
    ...state!,
    jitsiRoom
  }),

  [ActionsTypes.SET_ERROR_JITSI]: (
    state: IJitsiState | undefined,
    { errorJitsi }: AnyAction
  ): IJitsiState => ({
    ...state!,
    errorJitsi
  })
}

export const reducer = createReducer(initialState, actionHandlers)
