import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { IChairs, IContactUser, IRoom, RoomType } from '../../../entities'
import { ILocationUser } from '../../../entities/pusher'
import { useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { setAroundUsers } from '../../../store/participant'
import { setRoomAccessDenied } from '../../../store/room'
import { addLocation, removeLocation } from '../../../store/stand'
import { formatName } from '../../../utils'
import consoleUtils from '../../../utils/consoleUtils'
import { breakpoints } from '../../breakpoints'
import BroadcasterDialog from '../../modals/BroadcasterDialog'
import ConfirmDialog from '../../modals/ConfirmDialog'
import ViewDialog from '../../modals/ViewDialog'
import MyAvatar from '../MyAvatar/MyAvatar'
import UserAvatar from '../UserAvatar/UserAvatar'
import UserAvatarGroup from '../UserAvatarGroup/UserAvatarGroup'
import RoomContent from './RoomContent'

interface ISvgBackgroundProps {
  id: string
  height: number
  width: number
  svgString: string
  rooms: IRoom[]
  chairs: IChairs
  active: number
  isEditable: boolean
  isBroadcaster: boolean
  totalUsers: number
  onClickRoom: (index: number, broadcasterEnabled?: boolean) => Promise<boolean>
  handleRenameRoom?: (room: IRoom, value: string) => void
}

interface IPosition {
  [key: string]: { top: number; left: number; height: number; width: number }
}

const SvgBackground: React.FC<ISvgBackgroundProps> = ({
  id,
  height,
  width,
  svgString,
  rooms,
  chairs,
  active,
  isEditable,
  isBroadcaster,
  totalUsers,
  onClickRoom,
  handleRenameRoom
}) => {
  const intl = useIntl()
  const [ownerMissingView, setOwnerMissingView] = useState<IContactUser | null>(null)
  const [broadcasterChoice, setBroadcasterChoice] = useState(-1)
  const [confirmEnterRoomLockedChoice, setConfirmEnterRoomLockedChoice] = useState(-1)
  const [confirmEnterRoomOfficeChoice, setConfirmEnterRoomOfficeChoice] = useState(-1)
  const user = useSelector((state: IRootState) => state.appState.user)
  const aroundUsers = useSelector((state: IRootState) => state.participantState.aroundUsers)
  const roomsLocked = useSelector((state: IRootState) => state.roomState.roomsLocked)
  const standChannel = useSelector((state: IRootState) => state.standState.standChannel)
  const locations = useSelector((state: IRootState) => state.standState.locations)
  const chairId = useSelector((state: IRootState) => state.standState.chairId)
  const [roomPositions, setRoomPositions] = useState<IPosition>({})
  const [chairPositions, setChairPositions] = useState<IPosition>({})
  const roomAccessDenied = useSelector((state: IRootState) => state.roomState.roomAccessDenied)
  const dispatch = useThunkDispatch()

  consoleUtils.log(roomAccessDenied)
  // init rooms & chairs refs
  const refs: { [key: string]: React.RefObject<SVGRectElement> } = {}
  const divRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {}
  rooms.forEach((room) => {
    refs[`room-${room.id}`] = React.createRef<SVGRectElement>()
    divRefs[`room-div-${room.id}`] = React.createRef<HTMLDivElement>()
  })
  const chairsArray = Object.values(chairs).flat()
  chairsArray.forEach((chair) => {
    refs[`chair-${chair.id}`] = React.createRef<SVGRectElement>()
  })

  const cssRules = useMemo(
    () =>
      rooms
        .map((room) =>
          room.color ? `#${room.roomClassName} { fill: ${room.color} !important; }` : ''
        )
        .join(''),
    [rooms]
  )

  const memoLobby = useMemo(() => rooms.find((room) => room.type === RoomType.Lobby), [rooms])

  const memoOffices = useMemo(
    () => rooms.filter((room) => room.userPresence && room.userPresence.user.id),
    [rooms]
  )

  const memoMeOnLobby = useMemo(
    () => !chairId && !memoOffices.find((office) => office.userPresence!.user.id === user!.id),
    [user, chairId, memoOffices]
  )

  const memoOfficesUserIds = useMemo(
    () => memoOffices.map((office) => office.userPresence!.user.id),
    [memoOffices]
  )

  const memoLobbyLocation = useMemo(
    () =>
      locations.filter(
        (location) =>
          `${user?.id}` !== `${location.user.id}` && // not me
          location.roomId === '-1' && // no location
          memoOfficesUserIds.indexOf(location.user.id) === -1 // and no office
      ),
    [user, locations, memoOfficesUserIds]
  )

  const memoOtherLocation = useMemo(
    () => locations.filter((location) => location.roomId !== '-1'),
    [locations]
  )

  const onClickSvgRoom = (room: IRoom, index: number) => {
    if (roomsLocked[room.id] || (roomsLocked[room.id] === undefined && room.locked)) {
      if (room.userPresence) {
        if (room.userPresence.user.id !== user?.id) {
          // owner not on the stand
          if (
            locations.filter(
              (location) => `${room.userPresence!.user.id}` === `${location.user.id}`
            ).length === 0
          ) {
            setOwnerMissingView(room.userPresence!.user)
          } else {
            setConfirmEnterRoomOfficeChoice(index)
          }
        } else {
          onClickRoom(index)
        }
      } else {
        setConfirmEnterRoomLockedChoice(index)
      }
    } else {
      if (
        (room.type === RoomType.Broadcast || room.type === RoomType.JitsiBroadcast) &&
        isBroadcaster &&
        active !== index
      ) {
        setBroadcasterChoice(index)
      } else {
        onClickRoom(index)
      }
    }
  }

  const locationBind = useCallback(
    (data: ILocationUser) => {
      consoleUtils.log(`LocationBind 'client-location-${id}' > data=`, data)
      if (user && data.user) {
        dispatch(addLocation(data))
      }
    },
    [id, user, dispatch]
  )

  const leaveBind = useCallback(
    (data: ILocationUser) => {
      consoleUtils.log(`LocationBind 'client-leave-${id}' > data=`, data)
      if (user && data.user) {
        // directly remove from aroundUsers
        const aroundUsersFiltered = aroundUsers.filter(
          (aroundUser) => data.user.id !== aroundUser.id
        )
        dispatch(setAroundUsers(aroundUsersFiltered))
        // dispatch(setAroundUsersCount(aroundUsersFiltered.length))
        // remove location
        dispatch(removeLocation(data))
      }
    },
    [id, user, aroundUsers, dispatch]
  )

  useEffect(() => {
    if (id && standChannel && settings.debug.locations) {
      standChannel.bind(`client-location-${id}`, locationBind)
      standChannel.bind(`client-leave-${id}`, leaveBind)
    }
    return () => {
      // destroy
      if (id && standChannel && settings.debug.locations) {
        standChannel.unbind(`client-location-${id}`)
        standChannel.unbind(`client-leave-${id}`)
      }
    }
  }, [id, standChannel, locationBind, leaveBind])

  const handleOwnerMissingOk = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    setOwnerMissingView(null)
  }

  // init positions of rooms and chairs
  const initPositions = () => {
    if (Object.keys(roomPositions).length === 0 && Object.keys(chairPositions).length === 0) {
      const newRoomPositions: IPosition = {}
      const newChairPositions: IPosition = {}
      Object.keys(refs).forEach((key) => {
        if (refs[key]) {
          const target = refs[key].current as any
          if (target && target.elemRefs[0]) {
            if (key.indexOf('chair-room') > -1) {
              newChairPositions[key] = {
                top: (target.elemRefs[0].y.baseVal.value / height) * 100,
                left: (target.elemRefs[0].x.baseVal.value / width) * 100,
                height: (target.elemRefs[0].height.baseVal.value / height) * 100,
                width: (target.elemRefs[0].width.baseVal.value / width) * 100
              }
            } else {
              newRoomPositions[key] = {
                top: (target.elemRefs[0].y.baseVal.value / height) * 100,
                left: (target.elemRefs[0].x.baseVal.value / width) * 100,
                height: (target.elemRefs[0].height.baseVal.value / height) * 100,
                width: (target.elemRefs[0].width.baseVal.value / width) * 100
              }
            }
          }
        }
      })
      setRoomPositions(newRoomPositions)
      setChairPositions(newChairPositions)
    }
  }

  // find all positions of room/chair into svg
  const onSVGReady = () => {
    if (refs) {
      initPositions()
    }
  }

  return (
    <StyledSvgBackgroundContainer cssRules={cssRules}>
      <StyledSvgLoaderContainer>
        <SvgLoader svgXML={svgString} onSVGReady={onSVGReady}>
          {rooms.map((room, index) => (
            <SvgProxy
              key={`room-${index}`}
              ref={refs[`room-${room.id}`]}
              selector={`#${room.roomClassName}`}
              fill="transparent"
            />
          ))}
          {chairsArray.map((chair, index) => (
            <SvgProxy
              key={`chair-${index}`}
              ref={refs[`chair-${chair.id}`]}
              selector={`#${chair.id}`}
              fill="transparent"
            />
          ))}
        </SvgLoader>
        {chairsArray.map(
          (chair) =>
            chairPositions[`chair-${chair.id}`] && (
              <StyledChair
                key={chair.id}
                top={chairPositions[`chair-${chair.id}`].top}
                left={chairPositions[`chair-${chair.id}`].left}
                width={chairPositions[`chair-${chair.id}`].width}
                height={chairPositions[`chair-${chair.id}`].height}
              >
                {chairId === chair.id && <MyAvatar showModerator showMe hasRollIn />}
                {locations
                  .filter(
                    (location) => location.chairId === chair.id && location.chairId !== chairId
                  )
                  .map((location, indexLocation) => (
                    <UserAvatar
                      key={indexLocation}
                      user={location.user}
                      showDetails
                      showModerator
                      showMe
                      showNote
                      isOnline
                      isSpeak
                      hasRollIn
                    />
                  ))}
              </StyledChair>
            )
        )}
        {rooms.map(
          (room, index) =>
            roomPositions[`room-${room.id}`] &&
            room.type !== RoomType.Lobby && (
              <StyledRoom
                ref={divRefs[`room-div-${room.id}`]}
                key={room.id}
                top={roomPositions[`room-${room.id}`].top}
                left={roomPositions[`room-${room.id}`].left}
                width={roomPositions[`room-${room.id}`].width}
                height={roomPositions[`room-${room.id}`].height}
                onClick={() => onClickSvgRoom(room, index)}
              >
                <RoomContent
                  index={index}
                  room={room}
                  divRef={divRefs[`room-div-${room.id}`]}
                  chairs={chairs}
                  active={active}
                  isEditable={isEditable}
                  onClick={onClickSvgRoom}
                />
              </StyledRoom>
            )
        )}
        {/* Lobby */}
        {memoLobby && roomPositions[`room-${memoLobby.id}`] && (
          <StyledRoom
            key={memoLobby.id}
            top={roomPositions[`room-${memoLobby.id}`].top}
            left={roomPositions[`room-${memoLobby.id}`].left}
            width={roomPositions[`room-${memoLobby.id}`].width}
            height={roomPositions[`room-${memoLobby.id}`].height}
          >
            <UserAvatarGroup max={15} spacing={10} total={memoLobbyLocation.length}>
              {memoMeOnLobby && <MyAvatar showModerator showMe hasRollIn />}
              {memoLobbyLocation.map((location, index) => (
                <UserAvatar
                  key={index}
                  user={location.user}
                  showDetails
                  showModerator
                  showMe
                  showNote
                  isOnline
                  hasRollIn
                />
              ))}
            </UserAvatarGroup>
          </StyledRoom>
        )}
        {isBroadcaster && (
          <>
            <BroadcasterDialog
              open={broadcasterChoice > -1}
              onClose={(broadcasterEnabled) => {
                setBroadcasterChoice(-1)
                onClickRoom(broadcasterChoice, broadcasterEnabled)
              }}
              room={broadcasterChoice > -1 ? rooms[broadcasterChoice] : null}
              handleRenameRoom={handleRenameRoom}
            />
          </>
        )}
        {roomAccessDenied && (
          <>
            <ConfirmDialog
              open={roomAccessDenied}
              okEvent={() => {
                dispatch(setRoomAccessDenied(false))
              }}
              message={intl.formatMessage({ id: 'confirm.enter.room.accessDenied' })}
            />
          </>
        )}
        <ConfirmDialog
          open={confirmEnterRoomLockedChoice > -1 || confirmEnterRoomOfficeChoice > -1}
          message={intl.formatMessage(
            {
              id:
                confirmEnterRoomOfficeChoice > -1
                  ? 'confirm.enter.room.office'
                  : 'confirm.enter.room.locked'
            },
            confirmEnterRoomOfficeChoice > -1
              ? { user: formatName(rooms[confirmEnterRoomOfficeChoice].userPresence?.user).full }
              : {}
          )}
          okEvent={() => {
            setConfirmEnterRoomLockedChoice(-1)
            setConfirmEnterRoomOfficeChoice(-1)
            onClickRoom(
              confirmEnterRoomLockedChoice > -1
                ? confirmEnterRoomLockedChoice
                : confirmEnterRoomOfficeChoice
            )
          }}
          cancelEvent={() => {
            setConfirmEnterRoomLockedChoice(-1)
            setConfirmEnterRoomOfficeChoice(-1)
          }}
        />
        {ownerMissingView && (
          <ViewDialog
            title={intl.formatMessage({ id: 'enter.room.office.title' })}
            open={!!ownerMissingView}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            okEvent={handleOwnerMissingOk}
          >
            <Typography>
              <FormattedMessage
                id="view.enter.room.office.message"
                values={{ name: formatName(ownerMissingView).full }}
              />
            </Typography>
          </ViewDialog>
        )}
      </StyledSvgLoaderContainer>
      {!memoLobby && (
        <Bottom>
          <UserAvatarGroup
            max={10}
            spacing={15}
            total={Math.max(memoLobbyLocation.length, totalUsers - memoOtherLocation.length)}
          >
            {memoMeOnLobby && <MyAvatar showModerator showMe hasRollIn />}
            {memoLobbyLocation.map((location, index) => (
              <UserAvatar
                key={index}
                user={location.user}
                showDetails
                showModerator
                showMe
                showNote
                isOnline
                hasRollIn
              />
            ))}
          </UserAvatarGroup>
        </Bottom>
      )}
    </StyledSvgBackgroundContainer>
  )
}

const StyledSvgBackgroundContainer = styled.div<{ cssRules: string }>`
  ${(props) => props.cssRules};
`

const StyledSvgLoaderContainer = styled.div`
  & {
    position: relative;
    z-index: 0;
  }
`

const StyledRoom = styled.div<{
  top: number
  left: number
  width: number
  height: number
}>`
  & {
    position: absolute;
    top: ${(props) => `${props.top}%`};
    left: ${(props) => `${props.left}%`};
    width: ${(props) => `${props.width}%`};
    height: ${(props) => `${props.height}%`};
    cursor: pointer;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    & .room-title {
      display: none;
    }

    & .room-button-leave.MuiButton-colorInherit {
      background-color: red;
      color: white;
    }

    & button {
      white-space: nowrap;
    }

    /* show button enter in first index */
    :hover {
      & .room-button-enter,
      & .room-button-leave,
      & .room-button-bo,
      & .room-button-invite {
        z-index: 1;
      }
    }

    /* play only with display on md+ screen */
    @media (min-width: ${breakpoints.md}) {
      & .room-title {
        display: flex;
      }
      & .room-button-enter {
        display: none;
      }

      :hover {
        & .room-title {
          display: none;
        }
        & .room-button-enter {
          display: flex;
        }
      }
    }
  }
`

const StyledChair = styled.div<{
  top: number
  left: number
  width: number
  height: number
}>`
  & {
    position: absolute;
    top: ${(props) => `${props.top}%`};
    left: ${(props) => `${props.left}%`};
    width: ${(props) => `${props.width}%`};
    height: ${(props) => `${props.height}%`};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    > .MuiAvatar-root {
      width: 100%;
      height: 100%;
    }
  }
`

const Bottom = styled.div`
  & {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > div {
      margin-right: 1em;
    }
  }
`

export default SvgBackground
