import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { breakpoints } from '../../components/breakpoints'
import ContactsList from '../../components/contacts/ContactsList'
import Title from '../../components/ui/Title/Title'
import VisitorFiltersTri from '../../components/visitor/VisitorFiltersTri'
import { LanguageKeys } from '../../entities'
import { useEffectOnce, useMagicStream, usePagination, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import settings from '../../settings'
import { setPageReferral } from '../../store/gtm'
import { setErrorParticipants, setParticipantsSearch } from '../../store/participant'
import { loadParticipants } from '../../store/participant/thunks'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import { convertQueryParamToObject } from '../../utils'

const VisitorListPage = () => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const { sendDataToGTM } = useGTM()
  const location = useLocation()
  const { count, page, setPage } = usePagination(
    parseInt(new URLSearchParams(location.search).get('page') || '1', 10)
  )
  const [filterSearch, setFilterSearch] = useState(
    new URLSearchParams(location.search).get('name') || ''
  )
  const [filterIsOnline, setFilterIsOnline] = useState(
    new URLSearchParams(location.search).get('online') === 'true' || false
  )
  const [filterIsContact, setFilterIsContact] = useState(
    new URLSearchParams(location.search).get('contact') === 'true' || false
  )
  const [filterUserRoles, setFilterUserRoles] = useState(
    new URLSearchParams(location.search).get('roles') || ''
  )
  const [filterCustom, setFilterCustom] = useState<{
    [key: string]: number | string | { id: number; name: string }
  }>({})
  const [filterTags, setFilterTags] = useState<{ [key: string]: boolean }>(
    convertQueryParamToObject(new URLSearchParams(location.search).get('tags')) || {}
  )
  const [sortBy, setSortBy] = useState(
    new URLSearchParams(location.search).get('sortBy') || 'recommandation'
  )
  const participants = useSelector((state: IRootState) => state.participantState.participants)
  const loading = useSelector((state: IRootState) => state.participantState.loading)
  const searchParticipants = useSelector(
    (state: IRootState) => state.participantState.searchParticipants
  )
  const errorParticipants = useSelector(
    (state: IRootState) => state.participantState.errorParticipants
  )
  const [filterKeywordsX, setFilterKeywordsX] = useState<{ [key: string]: boolean }>(
    convertQueryParamToObject(new URLSearchParams(location.search).get('keywordsX')) || {}
  )
  const [filterKeywordsY, setFilterKeywordsY] = useState<{ [key: string]: boolean }>(
    convertQueryParamToObject(new URLSearchParams(location.search).get('keywordsY')) || {}
  )
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const locale = useSelector((state: IRootState) => state.appState.locale)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    const search = `?page=${page}&roles=${filterUserRoles}&search=${filterSearch}&sortBy=${sortBy}&online=${filterIsOnline}&contact=${filterIsContact}${
      filterTags
        ? `&tags=${Object.keys(filterTags)
            .filter((id) => filterTags[id])
            .join(',')}`
        : ''
    }${
      filterKeywordsX
        ? `&keywordsX=${Object.keys(filterKeywordsX)
            .filter((id) => filterKeywordsX[id])
            .join(',')}`
        : ''
    }${
      filterKeywordsY
        ? `&keywordsY=${Object.keys(filterKeywordsY)
            .filter((id) => filterKeywordsY[id])
            .join(',')}`
        : ''
    }`
    // refresh url only if search as changed and is different of location
    if (
      searchParticipants !== search &&
      ((location.search.indexOf('magicstream=true') > -1 &&
        !location.search.endsWith(`${search}&magicstream=true`)) ||
        (location.search.indexOf('magicstream=true') === -1 && !location.search.endsWith(search)))
    ) {
      browserHistory.replace(
        `/participant${search}${
          location.search.indexOf('magicstream=true') > -1 ? '&magicstream=true' : ''
        }${location.search.indexOf('appointment=true') > -1 ? '&appointment=true' : ''}`
      )
    }
  }, [
    filterUserRoles,
    filterSearch,
    filterIsOnline,
    filterIsContact,
    filterTags,
    filterKeywordsX,
    filterKeywordsY,
    page,
    sortBy,
    location,
    searchParticipants
  ])

  // read url location to load data
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if ((location.search || searchParticipants) && location.search !== searchParticipants) {
        const locationSearch = new URLSearchParams(location.search)
        const currentPage = parseInt(locationSearch.get('page') || '1', 10)
        setPage(currentPage)
        const currentUserRoles = locationSearch.get('roles') || ''
        setFilterUserRoles(currentUserRoles)
        const currentSearch = locationSearch.get('search') || ''
        setFilterSearch(currentSearch)
        const currentIsOnline = locationSearch.get('online') === 'true' || false
        setFilterIsOnline(currentIsOnline)
        const currentIsContact = locationSearch.get('contact') === 'true' || false
        setFilterIsContact(currentIsContact)
        const currentSortBy = locationSearch.get('sortBy') || 'recommandation'
        setSortBy(currentSortBy)
        const currentTags = locationSearch.get('tags') || ''
        setFilterTags(convertQueryParamToObject(currentTags) || {})
        const currentKeywordsX = locationSearch.get('keywordsX') || ''
        setFilterKeywordsX(convertQueryParamToObject(currentKeywordsX) || {})
        const currentKeywordsY = locationSearch.get('keywordsY') || ''
        setFilterKeywordsY(convertQueryParamToObject(currentKeywordsY) || {})
        dispatch(
          loadParticipants({
            page: currentPage,
            search: currentSearch,
            functionName: filterCustom.functions as string,
            partnerType: filterCustom.partner_types
              ? (filterCustom.partner_types as { id: number; name: string })
              : undefined,
            sortBy: currentSortBy,
            isOnline: currentIsOnline,
            isContact: currentIsContact,
            location: location.search,
            tags: currentTags,
            keywordsX: currentKeywordsX,
            keywordsY: currentKeywordsY,
            roles: currentUserRoles
          })
        )
      }
    }, 1000)

    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [location, filterCustom, searchParticipants, setPage, dispatch])

  // clean location
  useEffect(() => {
    return () => {
      dispatch(setParticipantsSearch(undefined))
    }
  }, [dispatch])

  // gtm DATA
  useEffectOnce(() => {
    const title = intl.formatMessage({ id: 'gtm.listVisitors.title' })
    dispatch(setPageReferral(title))
    sendDataToGTM({
      event: 'page-view',
      pageTitle: title,
      pageType: intl.formatMessage({ id: 'gtm.listVisitors.type' }),
      pageReferral
    })
  })

  // error
  useEffect(() => {
    if (errorParticipants) {
      magicStream.error(errorParticipants)
      dispatch(setErrorParticipants(undefined))
    }
  }, [errorParticipants, magicStream, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  const localizedBanner = useMemo(() => {
    let banner = settings.theme.header.banner
    if (locale === LanguageKeys.en && settings.theme.header.banner_en) {
      banner = settings.theme.header.banner_en
    }
    return banner
  }, [locale])

  return (
    <StyledVisitorListPage>
      {eventCo?.eventcoAppointment && localizedBanner && (
        <StyledBanner src={localizedBanner} alt={settings.theme.header.title} />
      )}
      <StyledVisitorHeader>
        <Title
          titleId="visitor.title.main"
          variant="h1"
          suffix={participants.total > 0 ? ` (${participants.total})` : ''}
        />
        <VisitorFiltersTri sortBy={sortBy} setSortBy={setSortBy} />
      </StyledVisitorHeader>
      <ContactsList
        contacts={participants}
        loading={loading}
        count={count}
        page={page}
        setPage={setPage}
        setFilterSearch={setFilterSearch}
        filterSearch={filterSearch}
        setFilterCustom={(value: any) => {
          dispatch(setParticipantsSearch(undefined))
          setFilterCustom(value)
        }}
        filterCustom={filterCustom}
        setFilterIsOnline={setFilterIsOnline}
        filterIsOnline={filterIsOnline}
        setFilterIsContact={setFilterIsContact}
        filterIsContact={filterIsContact}
        filterTags={filterTags}
        setFilterTags={setFilterTags}
        filterKeywordsX={filterKeywordsX}
        setFilterKeywordsX={setFilterKeywordsX}
        filterKeywordsY={filterKeywordsY}
        setFilterKeywordsY={setFilterKeywordsY}
        filterUserRoles={filterUserRoles}
        setFilterUserRoles={setFilterUserRoles}
      />
      <Helmet>
        <title>
          {`${intl.formatMessage({ id: 'gtm.listVisitors.title' })} - ${
            settings.theme.header.title
          }`}
        </title>
      </Helmet>
    </StyledVisitorListPage>
  )
}

const StyledVisitorListPage = styled.div`
  & {
    max-width: 1280px;
    margin: 0 auto;
  }
`

const StyledBanner = styled.img`
  & {
    display: block;
    object-fit: contain;
    width: 100%;
    margin: 10px auto 0;
    border-radius: ${BORDER_RADIUS_CARD};
  }
`

const StyledVisitorHeader = styled.div`
  & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;

    @media (min-width: ${breakpoints.lg}) {
      flex-direction: row;
    }
    label {
      display: none;
    }
  }
`

export default VisitorListPage
