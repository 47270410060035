import { IGroup } from './eventco'
import { IChairs, IRoom } from './rooms'
import { IContact } from './user'

export type IConference = {
  id: number
  title: string
  description: string
  summary: string
  picture: string
  start_date: string
  pub_date: string
  end_date: string
  duration: number
  goals: string
  imageUrl: string
  urlVimeo: string
  eventCoUrlVimeo: string
  status: string
  nbUsers: number
  redirect_url: string
  isFollowed: boolean
  enableVideoControls: boolean
}

export type IConferenceExhibitors = {
  businessEventExhibitor: {
    chairs: IChairs[]
    editable: boolean
    height: number
    id: number
    isBroadcaster: boolean
    rooms: IRoom[]
    name: string
    imageUrl?: string
    svgString: string
    svgUrlS3: string
    width: number
  }
  description: string
  is_auto_redirected: boolean
  position: number
}

export type IRelatedEvent = {
  description: string
  position: number
  title: string
  url: string
  is_auto_redirected: false
  image?: string
}

export type IRelatedContent = {
  exhibitors: IConferenceExhibitors[]
  items: IRelatedEvent[]
}

export type IConferenceFull = {
  [key: string]: any
  id: number
  title: string
  description: string
  goals: string
  principal_group: null
  ticket_id: number
  audience: string
  status: string
  start_date: string
  end_date: string
  pub_date: string
  duration: number
  eventCoUrlVimeo: string
  urlVimeo: string
  videoAlternativeLink: string
  live_vimeo_id: string
  live_vimeo_chat_id: string
  imageUrl: string
  color?: string
  svgString: string
  vodUrl?: string
  chairs: IChairs
  rooms: IRoom[]
  group: IGroup
  width: number
  height: number
  editable: boolean
  presenters: IContact[]
  followersLite: IContact[]
  nbUsers: number
  redirect_url: string
  bannerUrl: string
  business_event_exhibitors?: IConferenceExhibitors[]
  isFollowed: boolean
  relatedContent: IRelatedContent
  bannerColor?: string | null
  enableVideoControls?: boolean
}

export type IConferenceUpdate = {
  title?: string
  description?: string
  goals?: string
  color?: string | null
  imageUrlS3?: string
  vodUrl?: string
  status?: string
  live_vimeo_id?: string
  urlVimeo?: string
  eventCoUrlVimeo?: string
  bannerUrlS3?: string | null
  bannerColor?: string | null
}

export type IConferenceTimeline = {
  key: string
  events: IConference[]
}

export enum ConferenceStatus {
  OPEN = 'inscriptions_ouvertes',
  IN_PROGRESS = 'en_cours',
  ARCHIVE = 'archive'
}

export enum ConferenceViewDisplayType {
  TIMELINE = 'timeline',
  LIST = 'list'
}
