import { AnyAction, Dispatch } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IBaseRootState } from '..'
import {
  addExhibitorDocument as addApiExhibitorDocument,
  deleteExhibitorDocument as deleteApiExhibitorDocument,
  followExhibitor,
  getExhibitor as getApiExhibitor,
  getExhibitors as getApiExhibitors,
  getFiltersExhibitor as getApiFiltersExhibitor,
  getPremiumExhibitors as getApiPremiumExhibitors,
  getRoomsOccupationExhibitor as getApiRoomsOccupation,
  getScoredExhibitors as getApiScoredExhibitors,
  getShowrooms,
  unFollowExhibitor,
  updateExhibitor as updateApiExhibitor
} from '../../api'
import { IExhibitorDocument, IExhibitorDocumentPost, IExhibitorUpdate } from '../../entities'
import { ILocationUser } from '../../entities/pusher'
import consoleUtils from '../../utils/consoleUtils'
import pusherUtils from '../../utils/pusherUtils'
import { loadAgenda, setGroupId } from '../app'
import { setLocations } from '../stand'
import {
  mergeExhibitor,
  setAddingExhibitorToFavorites,
  setErrorAddingExhibitorToFavorites,
  setErrorExhibitorFilters,
  setErrorRemovingExhibitorFromFavorites,
  setExhibitor,
  setExhibitorError,
  setExhibitorFilters,
  setExhibitorLoading,
  setExhibitors,
  setExhibitorsError,
  setExhibitorsLoading,
  setExhibitorsSearch,
  setExhibitorUpdating,
  setLoadingExhibitorFilters,
  setPremiumExhibitorError,
  setPremiumExhibitorLoading,
  setPremiumExhibitors,
  setRecommendedExhibitorError,
  setRecommendedExhibitorLoading,
  setRecommendedExhibitors,
  setRemovingExhibitorFromFavorites,
  setScoredExhibitorError,
  setScoredExhibitorLoading,
  setScoredExhibitors,
  setShowrooms,
  setShowroomsError,
  setShowroomsLoading,
  updateExhibitorsFavorite
} from './actions'
import { IExhibitorFilters } from './model'

export const getExhibitors = (filters: IExhibitorFilters) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setExhibitorsLoading(true))
  if (filters.location) {
    dispatch(setExhibitors({ total: 0, items: [] }))
    dispatch(setExhibitorsSearch(filters.location))
  }
  try {
    // get exhibitor data
    const exhibitors = await getApiExhibitors(
      filters.page,
      filters.search,
      filters.sortBy,
      filters.keyword,
      filters.followed,
      filters.matching,
      filters.keywordsX
    )
    dispatch(setExhibitors(exhibitors))
  } catch (e) {
    dispatch(setExhibitorsError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setExhibitorsLoading(false))
}

export const loadScoredExhibitors = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setScoredExhibitorLoading(true))
  try {
    // get recommendedExhibitors data
    const scoredExhibitors = await getApiScoredExhibitors()
    dispatch(setScoredExhibitors(scoredExhibitors))
  } catch (e) {
    dispatch(setScoredExhibitorError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setScoredExhibitorLoading(false))
}

export const loadRecommendedExhibitors = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setRecommendedExhibitorLoading(true))
  try {
    // get recommendedExhibitors data
    const recommendedExhibitors = await getApiExhibitors(
      1,
      undefined,
      undefined,
      undefined,
      undefined,
      50,
      undefined,
      true
    )
    dispatch(setRecommendedExhibitors(recommendedExhibitors.items))
  } catch (e) {
    dispatch(setRecommendedExhibitorError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setRecommendedExhibitorLoading(false))
}

export const loadPremiumExhibitors = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setPremiumExhibitorLoading(true))
  try {
    // get premiumExhibitors data
    const premiumExhibitors = await getApiPremiumExhibitors()
    dispatch(setPremiumExhibitors(premiumExhibitors))
  } catch (e) {
    dispatch(setPremiumExhibitorError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setPremiumExhibitorLoading(false))
}

export const getExhibitor = (exhibitorId: string, userId: number) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setExhibitorLoading(true))
  try {
    // get exhibitor data
    const exhibitor = await getApiExhibitor(exhibitorId)

    // pusher subscribes
    pusherUtils.subscribe(`group-${exhibitor.group.id}`)
    pusherUtils.subscribe(`private-group-${exhibitor.group.id}-user-${userId}`)

    // set exhibitor and groupid after subscribe group
    dispatch(setExhibitor(exhibitor))
    dispatch(setGroupId(exhibitor.group.id))

    // get locations of user
    const roomsOccupation = await getApiRoomsOccupation(exhibitor.group.id)
    const locations: ILocationUser[] = []
    roomsOccupation.forEach((roomOccupation) => {
      roomOccupation.chairOccupations.forEach((chairOccupation) => {
        if (chairOccupation.id === 'lobby') {
          // lobby
          chairOccupation.users.forEach((user) => {
            if (user.id !== userId) {
              locations.push({
                user,
                roomId: '-1',
                chairId: '-1',
                broadcaster: false
              })
            }
          })
        } else if (!chairOccupation.isFree) {
          if (chairOccupation.users[0].id !== userId) {
            locations.push({
              user: chairOccupation.users[0],
              roomId: roomOccupation.id,
              chairId: chairOccupation.id,
              broadcaster: false
            })
          }
        } else {
          // room broadcast / non broadcaster
          chairOccupation.users.forEach((user) => {
            if (user.id !== userId) {
              locations.push({
                user,
                roomId: roomOccupation.id,
                chairId: chairOccupation.id,
                broadcaster: false
              })
            }
          })
          // room broadcast / broadcaster
          chairOccupation.broadcasters.forEach((user) => {
            if (user.id !== userId) {
              locations.push({
                user,
                roomId: roomOccupation.id,
                chairId: chairOccupation.id,
                broadcaster: true
              })
            }
          })
        }
      })
    })
    consoleUtils.log('[EXHIBITOR, setLocations] locations=', locations)
    dispatch(setLocations(locations))
  } catch (e) {
    dispatch(setExhibitorError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setExhibitorLoading(false))
}

export const updateExhibitor = (exhibitorId: number, exhibitor: IExhibitorUpdate) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setExhibitorUpdating(true))
  try {
    // update exhibitor data
    const exhibitorUpdated = await updateApiExhibitor(exhibitorId, exhibitor)
    dispatch(mergeExhibitor(exhibitorUpdated))
  } catch (e) {
    dispatch(setExhibitorError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setExhibitorUpdating(false))
}

export const loadFiltersExhibitors = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoadingExhibitorFilters(true))
  try {
    // load filters for exhibitor
    const filtersAPIResult = await getApiFiltersExhibitor()
    dispatch(setExhibitorFilters(filtersAPIResult))
  } catch (e) {
    dispatch(setErrorExhibitorFilters((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingExhibitorFilters(false))
}

export const addExhibitorDocument = (document: IExhibitorDocumentPost) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setExhibitorUpdating(true))
  try {
    // update exhibitor data
    await addApiExhibitorDocument(document)
    const exhibitor = await getApiExhibitor(document.businessEventExhibitor.toString())
    dispatch(mergeExhibitor(exhibitor))
  } catch (e) {
    dispatch(setExhibitorError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setExhibitorUpdating(false))
}

export const deleteExhibitorDocument = (
  document: IExhibitorDocument,
  exhibitorId: number
) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setExhibitorUpdating(true))
  try {
    await deleteApiExhibitorDocument(document.id)
    const exhibitor = await getApiExhibitor(exhibitorId.toString())
    dispatch(mergeExhibitor(exhibitor))
  } catch (e) {
    dispatch(setExhibitorError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setExhibitorUpdating(false))
}

export const addExhibitorToFavorite = (exhibitorId: number) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setAddingExhibitorToFavorites(exhibitorId, true))
  try {
    await followExhibitor(exhibitorId)
    dispatch(updateExhibitorsFavorite(exhibitorId, true))
    dispatch(loadAgenda())
  } catch (e) {
    dispatch(setErrorAddingExhibitorToFavorites((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setAddingExhibitorToFavorites(exhibitorId, false))
}

export const removeExhibitorFromFavorite = (exhibitorId: number) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setRemovingExhibitorFromFavorites(exhibitorId, true))
  try {
    await unFollowExhibitor(exhibitorId)
    dispatch(updateExhibitorsFavorite(exhibitorId, false))
    dispatch(loadAgenda())
  } catch (e) {
    dispatch(setErrorRemovingExhibitorFromFavorites((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setRemovingExhibitorFromFavorites(exhibitorId, false))
}

export const loadShowrooms = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setShowroomsLoading(true))
  try {
    const showrooms = await getShowrooms()
    dispatch(setShowrooms(showrooms.items))
  } catch (e) {
    dispatch(setShowroomsError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setShowroomsLoading(false))
}
