import React from 'react'

const SvgHouse: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M39.5153633,44.374444 L39.9026289,44.374444 C41.3914943,44.374444 42.6028381,43.1632502 42.6028381,41.6742348 L42.6028381,25.4729792 C42.6028381,24.9753906 43.0053193,24.5729094 43.5029079,24.5729094 L44.4002774,24.5729094 C44.9268183,24.5729094 45.4260569,24.3433916 45.7688335,23.9434606 C46.4140335,23.1911523 46.3261267,22.051964 45.5727683,21.405864 L26.6722035,5.20460836 C26.020103,4.64566504 24.9784223,4.64566504 24.329772,5.2038583 L5.42650698,21.405864 C4.67329861,22.051964 4.58629186,23.1902522 5.23224192,23.9443607 C5.85284002,24.6686168 7.04213219,24.7626741 7.77073866,24.1386257 L24.9143673,9.44483697 C25.2500933,9.15471449 25.749332,9.15471449 26.085058,9.44483697 L40.484524,21.7873435 C40.8624033,22.1109186 40.9063567,22.6787126 40.5820315,23.0558418 C40.2595065,23.4346212 39.6908125,23.4776745 39.3136832,23.1533494 L25.4997127,11.3135318 L8.9415794,25.5055317 C8.28842878,26.0653751 7.45706435,26.3730489 6.59914786,26.3730489 C5.54606624,26.3730489 4.549389,25.9141633 3.86458593,25.1152014 C2.57343587,23.6077346 2.74834942,21.3311582 4.25581625,20.039108 L23.1572811,3.8387525 C24.4563818,2.72071586 26.5421434,2.71981579 27.8448444,3.83965257 L46.7445091,20.0400081 C48.2510759,21.3311582 48.4268895,23.6086347 47.1355894,25.1152014 C46.4500363,25.9141633 45.4541091,26.3721488 44.4029777,26.3730489 L44.4029777,41.6742348 C44.4029777,44.1555771 42.3839712,46.1745835 39.9026289,46.1745835 L39.0025591,46.1745835 C39.0000776,46.1745835 38.9975985,46.1745735 38.9951218,46.1745535 L11.1003367,46.1745535 C8.6189944,46.1745535 6.59998792,44.1555471 6.59998792,41.6742047 L6.59998792,29.0732282 C6.59998792,28.5757896 7.00261913,28.1731584 7.50005768,28.1731584 C7.99749623,28.1731584 8.40012743,28.5757896 8.40012743,29.0732282 L8.40012743,41.6742047 C8.40012743,43.1632201 9.6113213,44.374414 11.1003367,44.374414 L12.0004065,44.374414 L39.5079282,44.374414 C39.5104089,44.374414 39.5128873,44.374424 39.5153633,44.374444 Z"
    />
  </svg>
)

export default SvgHouse
