import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMagicStream, useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import { getHighlight, setHighlightError } from '../../../store/hightlight'
import ConferenceCard from '../../cards/ConferenceCard'
import ExhibitorCard from '../../cards/ExhibitorCard'
import Slider from '../../ui/Slider/Slider'

interface IHighlightProps {
  type: 'homepage' | 'exhibitors' | 'events'
}

const Highlight: React.FC<IHighlightProps> = ({ type }) => {
  const magicStream = useMagicStream()
  const highlight = useSelector((state: IRootState) => state.highlightState.highlight)
  const loading = useSelector((state: IRootState) => state.highlightState.loading)
  const loaded = useSelector((state: IRootState) => state.highlightState.loaded)
  const error = useSelector((state: IRootState) => state.highlightState.error)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    if (!loaded) {
      dispatch(getHighlight(type))
    }
  }, [type, loaded, dispatch])

  useEffect(() => {
    if (error) {
      magicStream.error(error)
      dispatch(setHighlightError(undefined))
    }
  }, [error, magicStream, dispatch])

  if (loading) return <></>

  return (
    <div>
      {highlight.items.map((highlightItem, i) => (
        <HighlightContent key={i}>
          <Typography variant="h2">{highlightItem.title}</Typography>
          <Slider slidesToShow={4}>
            {highlightItem.highlightItems.map((item, ii) => (
              <div key={`${i}-${ii}`}>
                {item.event != null && <ConferenceCard conference={item.event} />}
                {item.businessEventExhibitor != null && (
                  <ExhibitorCard exhibitor={item.businessEventExhibitor} />
                )}
              </div>
            ))}
          </Slider>
        </HighlightContent>
      ))}
    </div>
  )
}

const HighlightContent = styled.div`
  & {
    margin-top: 30px;
  }
`

export default Highlight
