import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContact, MagicStreamType } from '../../entities'
import { useEffectOnce, useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { loadMagicStreamsUser, setErrorMagicStreamsUser } from '../../store/chat'
import { formatName } from '../../utils'
import Loading from '../Loading'
import { Label } from '../ui/LabelWrapper/LabelWrapper'
import AgendaHistoryItem from './AgendaHistoryItem'

interface IAgendaHistoryProps {
  contact: IContact
}

const AgendaHistory: React.FC<IAgendaHistoryProps> = ({ contact }) => {
  const magicStream = useMagicStream()
  const [showAll, setShowAll] = useState(false)
  const magicStreamsUser = useSelector((state: IRootState) => state.chatState.magicStreamsUser)
  const loadingMagicStreamsUser = useSelector(
    (state: IRootState) => state.chatState.loadingMagicStreamsUser
  )
  const errorMagicStreamsUser = useSelector(
    (state: IRootState) => state.chatState.errorMagicStreamsUser
  )
  const dispatch = useThunkDispatch()

  const appointment = useMemo(
    () =>
      magicStreamsUser.filter(
        (magicStreamUser) => magicStreamUser.streamType === MagicStreamType.APPOINTMENT
      ),
    [magicStreamsUser]
  )

  useEffectOnce(() => {
    dispatch(loadMagicStreamsUser(contact.id))
  })

  useEffect(() => {
    if (errorMagicStreamsUser) {
      magicStream.error(errorMagicStreamsUser)
      dispatch(setErrorMagicStreamsUser(undefined))
    }
  }, [errorMagicStreamsUser, magicStream, dispatch])

  if (loadingMagicStreamsUser) return <Loading />

  return (
    <StyledAgendaHistory>
      {!loadingMagicStreamsUser && magicStreamsUser.length > 0 && (
        <>
          <Label>
            <FormattedMessage
              id="rdv.already"
              values={{ user: formatName(contact.userPresence.user).first }}
            />
          </Label>
          {appointment.slice(0, 1).map((magicStreamUser, index) => (
            <AgendaHistoryItem key={index} stream={magicStreamUser} />
          ))}
          {appointment.length > 1 && !showAll && (
            <StyledButton onClick={() => setShowAll(true)}>
              <FormattedMessage id="history.view.more" />
            </StyledButton>
          )}
          {showAll &&
            appointment
              .slice(1)
              .map((magicStreamUser, index) => (
                <AgendaHistoryItem key={index} stream={magicStreamUser} />
              ))}
        </>
      )}
    </StyledAgendaHistory>
  )
}

const StyledAgendaHistory = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  width: 200px;
  margin: 0 auto;
`

export default AgendaHistory
