import * as Sentry from '@sentry/react'
import { ConsoleLevel, IConsole } from '../entities/console'
import settings from '../settings'

const consoleUtils: IConsole = {
  log: (message?: any, ...optionalParams: any[]) => {
    if (ConsoleLevel[settings.debug.level] <= 0) {
      console.log(message, ...optionalParams)
    }
  },
  info: (message?: any, ...optionalParams: any[]) => {
    if (ConsoleLevel[settings.debug.level] <= 1) {
      console.log(message, ...optionalParams)
    }
  },
  warn: (message?: any, ...optionalParams: any[]) => {
    if (ConsoleLevel[settings.debug.level] <= 2) {
      console.warn(message, ...optionalParams)
    } else {
      Sentry.captureMessage(message)
    }
  },
  error: (message?: any, ...optionalParams: any[]) => {
    if (ConsoleLevel[settings.debug.level] <= 3) {
      console.error(message, ...optionalParams)
    } else {
      Sentry.captureMessage(message)
    }
  }
}

export default consoleUtils
