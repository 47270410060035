import { Button, Typography } from '@material-ui/core'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ViewDialog from '../modals/ViewDialog'

const InvitePerson: React.FC = () => {
  const intl = useIntl()
  const [invitePerson, setInvitePerson] = useState(false)

  const handleDoInvitePerson = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    setInvitePerson(true)
  }

  const handleOk = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    setInvitePerson(false)
  }

  return (
    <div>
      <Button
        className="room-button-invite"
        color="primary"
        variant="contained"
        size="small"
        title={intl.formatMessage({ id: 'button.invitePerson' })}
        onClick={handleDoInvitePerson}
        startIcon={<ContactPhoneIcon fontSize="small" />}
      >
        <FormattedMessage id="help.invitePerson" />
      </Button>
      <ViewDialog
        title={intl.formatMessage({ id: 'button.invitePerson' })}
        open={invitePerson}
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        okEvent={handleOk}
      >
        <Typography>
          <FormattedMessage id="invitePerson.message" />
        </Typography>
      </ViewDialog>
    </div>
  )
}

export default InvitePerson
