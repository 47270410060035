import styled from '@emotion/styled'
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { LanguageKeys } from '../../../entities'
import { useThunkDispatch } from '../../../hooks'
import { OpentokStream } from '../../../media/OpentokStream'
import { StreamSession } from '../../../media/StreamSession'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { loadOpentokSettings } from '../../../store/settings/thunks'
import Publisher from '../../globals/Media/Publisher'
import PublisherSettings from '../../globals/Media/PublisherSettings'
import Loading from '../../Loading'
import OnboardingModal from './OnboardingModal'
import OnboardingStepper from './OnboardingStepper'

interface IOnboardingOpenTokModalProps {
  step: number
  total: number
  title: string
  opened?: boolean
  onNext?: () => void
}

const OnboardingOpenTokModal: React.FC<IOnboardingOpenTokModalProps> = ({
  step,
  total,
  title,
  opened,
  onNext
}) => {
  const opentokStream = new OpentokStream()
  const [loaded, setLoaded] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const publisherRef = useRef<any>(null)
  const opentokSettings = useSelector((state: IRootState) => state.settingsState.opentokSettings)
  const locale = useSelector((state: IRootState) => state.appState.locale)
  const dispatch = useThunkDispatch()

  const localizedImage = useMemo(() => {
    let image = settings.theme.header.image
    if (locale === LanguageKeys.en && settings.theme.header.image_en) {
      image = settings.theme.header.image_en
    }
    return image
  }, [locale])
  const handleClose = () => {
    if (!onNext || onNext()) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (opentokStream && !loaded) {
      opentokStream.preload(() => {
        setLoaded(true)
      })
    }
  }, [opentokStream, loaded])

  useEffect(() => {
    if (opened) setOpen(opened)
  }, [opened])

  useEffect(() => {
    if (isOpen) {
      dispatch(loadOpentokSettings())
    }
  }, [dispatch, isOpen])

  return (
    <OnboardingModal isOpen={isOpen} ariaLabelledby="opentoksettingsmodal-title">
      <StyledAppBar>
        <Toolbar>
          <StyledLogo>
            <img alt={settings.theme.header.title} src={localizedImage} />
          </StyledLogo>
          <StyledTitle id="opentoksettingsmodal-title">
            <FormattedMessage id={title} />
          </StyledTitle>
          <Button id="finish-step" variant="contained" color="secondary" onClick={handleClose}>
            <FormattedMessage id="onboarding.step.finish" />
          </Button>
        </Toolbar>
      </StyledAppBar>
      <OnboardingStepper step={step} total={total} />
      {loaded && (
        <StyledContent>
          <PublisherSettings mediaStream={opentokStream} disableTest />
          <div>
            {opentokSettings && (
              <StreamSession
                mediaStream={opentokStream}
                apiKey={`${settings.opentok.apiKey}`}
                sessionId={opentokSettings.sessionId}
                token={opentokSettings.token}
              >
                <Publisher
                  mediaStream={opentokStream}
                  publisherRef={publisherRef}
                  defaultDisplay
                  height={300}
                />
              </StreamSession>
            )}
            {!opentokSettings && <Loading position="center" />}
          </div>
        </StyledContent>
      )}
      {!loaded && (
        <StyledContent>
          <Loading />
        </StyledContent>
      )}
    </OnboardingModal>
  )
}

const StyledAppBar = styled(AppBar)`
  position: relative;
`

const StyledLogo = styled.div`
  img {
    height: 50px;
    display: block;
    margin-left: 1em;
  }
`

const StyledTitle = styled(Typography)`
  margin-left: 1em;
  flex: 1;
  font-size: 20px;
`

const StyledContent = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;

  & > div {
    width: 100%;
  }
`

export default OnboardingOpenTokModal
