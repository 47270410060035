import React from 'react'

const SvgPlus: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M25.5 1.785a2.496 2.496 0 0 0-2.496 2.497v18.722H4.282a2.496 2.496 0 1 0 0 4.992h18.722v18.722a2.496 2.496 0 0 0 4.992 0V27.996h18.722a2.496 2.496 0 1 0 0-4.992H27.996V4.282A2.496 2.496 0 0 0 25.5 1.785z"
    />
  </svg>
)

export default SvgPlus
