import styled from '@emotion/styled'
import { alpha, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useEffectOnce, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { loadFiltersProducts } from '../../store/product'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../theme'
import { BLACK_BLUE, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD, FILTERS_HEIGHT_BUTTON_MOBILE } from '../../theme/sizes'
import { breakpoints } from '../breakpoints'
import SearchFilters from '../ui/SearchFilters/SearchFilters'
import SearchInput from '../ui/SearchInput/SearchInput'

interface IProductFiltersSearch {
  setPage: (page: number) => void
  setFilterSearch: (value: string) => void
  filterSearch: any
  setFilterCustom: (value: any) => void
  filterCustom: { [key: string]: number | string | { id: number; name: string } }
}

const ProductFiltersSearch: React.FC<IProductFiltersSearch> = ({
  setPage,
  setFilterSearch,
  filterSearch,
  setFilterCustom,
  filterCustom
}) => {
  const intl = useIntl()
  const [openMenu, setOpenMenu] = useState(false)
  const [currentFilterSearch, setCurrentFilterSearch] = useState(filterSearch)
  const products = useSelector((state: IRootState) => state.productState.products)
  const filters = useSelector((state: IRootState) => state.productState.filters)
  const loading = useSelector((state: IRootState) => state.productState.loadingFilters)
  const error = useSelector((state: IRootState) => state.productState.errorFilters)
  const dispatch = useThunkDispatch()

  const handleToggleMenu = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault()
    event.stopPropagation()
    setOpenMenu(!openMenu)
  }

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setFilterSearch(currentFilterSearch)
      if (currentFilterSearch) {
        setPage(1)
      }
    }, 200)

    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [setFilterSearch, setPage, currentFilterSearch])

  useEffectOnce(() => {
    if (Object.keys(filters).length === 0) {
      dispatch(loadFiltersProducts())
    }
  })

  return (
    <StyledFilters>
      <StyledTitleMobile>
        <StyledTitleSearch variant="h2">
          <FormattedMessage id="visitor.title.search" />
        </StyledTitleSearch>
      </StyledTitleMobile>
      {openMenu && <StyledOverlayOpenMobile onClick={handleToggleMenu} />}
      <StyledButtonOpenMobile open={openMenu} onClick={handleToggleMenu}>
        {openMenu && (
          <>
            <FormattedMessage id="filters.close" /> ({products.total})
          </>
        )}
        {!openMenu && (
          <>
            <FormattedMessage id="filters.filter" />
          </>
        )}
      </StyledButtonOpenMobile>
      <StyledContentOpenMobile open={openMenu}>
        <SearchInput
          name="productName"
          defaultValue={currentFilterSearch}
          placeholder={intl.formatMessage({ id: 'exhibitor.search' })}
          handleChange={setCurrentFilterSearch}
          resetSearch={() => setCurrentFilterSearch('')}
        />
        <SearchFilters
          filters={filters}
          loading={loading}
          error={error}
          filterCustom={filterCustom}
          onChangeValue={setFilterCustom}
        />
      </StyledContentOpenMobile>
    </StyledFilters>
  )
}

const StyledFilters = styled.div`
  & {
    @media (min-width: ${breakpoints.lg}) {
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;
      padding: 1em;
      background-color: ${WHITE};
      border-radius: ${BORDER_RADIUS_CARD};
    }
  }
`

const StyledTitleSearch = styled(Typography)`
  & {
    margin-top: 0;
    font-size: 18px;
    line-height: 21px;
  }
`

const StyledOverlayOpenMobile = styled.div`
  & {
    @media (max-width: ${breakpoints.lg}) {
      z-index: 1000;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${alpha(BLACK_BLUE, 0.38)};
    }
  }
`

const StyledTitleMobile = styled.div`
  & {
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.lg}) {
      display: none;
    }
  }
`

const StyledButtonOpenMobile = styled.div<{ open: boolean }>`
  & {
    display: none;

    @media (max-width: ${breakpoints.lg}) {
      z-index: ${(props) => (props.open ? `1050` : '950')};
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      height: ${FILTERS_HEIGHT_BUTTON_MOBILE};
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${WHITE};
      border-top: 1px solid ${SECONDARY_COLOR};
      background-color: ${PRIMARY_COLOR};
      font-size: 18px;
    }
  }
`

const StyledContentOpenMobile = styled.div<{ open: boolean }>`
  & {
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.lg}) {
      z-index: ${(props) => (props.open ? `1049` : '949')};
      position: fixed;
      left: 0;
      bottom: ${(props) => (props.open ? `${FILTERS_HEIGHT_BUTTON_MOBILE}` : '-100vh')};
      right: 0;
      padding: 15px;
      background-color: ${WHITE};
      transition: bottom 0.3s ease-in;
      max-height: calc(100vh - ${FILTERS_HEIGHT_BUTTON_MOBILE});
    }
  }
`

export default ProductFiltersSearch
