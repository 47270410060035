import { IContact } from '../../entities'
import { IFilters } from '../../entities/filters'
import { IKeyword } from '../../entities/keyword'

export enum ActionsTypes {
  SET_SEARCH_PARTICIPANT = 'SET_SEARCH_PARTICIPANT',
  SET_LOADING_PARTICIPANT = 'SET_LOADING_PARTICIPANT',
  SET_ADDING_PARTICIPANT = 'SET_ADDING_PARTICIPANT',
  SET_DELETING_PARTICIPANT = 'SET_DELETING_PARTICIPANT',
  SET_REFRESHING_CONTACT = 'SET_REFRESHING_CONTACT',
  SET_ERROR_CONTACTS = 'SET_ERROR_CONTACTS',
  SET_ERROR_ADDING = 'SET_ERROR_ADDING',
  SET_ERROR_DELETING = 'SET_ERROR_DELETING',
  SET_ERROR_PRESENCES = 'SET_ERROR_PRESENCES',
  SET_ERROR_PARTICIPANTS = 'SET_ERROR_PARTICIPANTS',
  SET_PARTICIPANTS = 'SET_PARTICIPANTS',
  SET_PRESENCE_USERS_COUNT = 'SET_PRESENCE_USERS_COUNT',
  INCREMENT_PRESENCE_USERS_COUNT = 'INCREMENT_PRESENCE_USERS_COUNT',
  DECREMENT_PRESENCE_USERS_COUNT = 'DECREMENT_PRESENCE_USERS_COUNT',
  SET_PRESENCE_USERS = 'SET_PRESENCE_USERS',
  SET_AROUND_USERS_COUNT = 'SET_AROUND_USERS_COUNT',
  INCREMENT_AROUND_USERS_COUNT = 'INCREMENT_AROUND_USERS_COUNT',
  DECREMENT_AROUND_USERS_COUNT = 'DECREMENT_AROUND_USERS_COUNT',
  SET_AROUND_USERS = 'SET_AROUND_USERS',
  SET_CONTACTS = 'SET_CONTACTS',
  SET_CONTACTS_FILTERED = 'SET_CONTACTS_FILTERED',
  SET_LOADING_CONTACTS_FILTERED = 'SET_LOADING_CONTACTS_FILTERED',
  SET_LOADING_CONTACTS = 'SET_LOADING_CONTACTS',
  SET_ERROR_CONTACTS_FILTERED = 'SET_ERROR_CONTACTS_FILTERED',
  REFRESH_CONTACT = 'REFRESH_CONTACT',
  UPDATE_CONTACT_ONLINE = 'UPDATE_CONTACT_ONLINE',
  UPDATE_CONTACT_IS_CONTACT = 'UPDATE_CONTACT_IS_CONTACT',
  MERGE_CONTACT = 'MERGE_CONTACT',
  REMOVE_CONTACT = 'REMOVE_CONTACT',
  SET_USERS_FILTERS = 'SET_USERS_FILTERS',
  SET_ERROR_USERS_FILTERS = 'SET_ERROR_USERS_FILTERS',
  SET_LOADING_USERS_FILTERS = 'SET_LOADING_USERS_FILTERS',
  ADD_USERS_KEYWORD = 'ADD_USERS_KEYWORD',
  EDIT_USERS_KEYWORD = 'EDIT_USERS_KEYWORD',
  REMOVE_USERS_KEYWORD = 'REMOVE_USERS_KEYWORD',
  SET_USERS_KEYWORDS = 'SET_USERS_KEYWORDS',
  SET_ERROR_USERS_KEYWORDS = 'SET_ERROR_USERS_KEYWORDS',
  SET_LOADED_USERS_KEYWORDS = 'SET_LOADED_USERS_KEYWORDS',
  SET_LOADING_USERS_KEYWORDS = 'SET_LOADING_USERS_KEYWORDS',
  SET_UPDATE_CONTACT = 'SET_UPDATE_CONTACT',
  SET_LOADING_UPDATE_CONTACT = 'SET_LOADING_UPDATE_CONTACT',
  SET_LOADING_UPDATE_NOTE = 'SET_LOADING_UPDATE_NOTE',
  SET_LOADING_UPDATE_TAGS = 'SET_LOADING_UPDATE_TAGS',
  SET_ERROR_UPDATE_CONTACT = 'SET_ERROR_UPDATE_CONTACT',
  ADD_CONTACT_REFRESHED = 'ADD_CONTACT_REFRESHED',
  SET_LOADING_PARTICIPANTS_FILTERED = 'SET_LOADING_PARTICIPANTS_FILTERED',
  SET_PARTICIPANTS_FILTERED = 'SET_PARTICIPANTS_FILTERED',
  SET_ERROR_PARTICIPANTS_FILTERED = 'SET_ERROR_PARTICIPANTS_FILTERED',
  REMOVE_CONTACT_KEYWORD_USERS = 'REMOVE_CONTACT_KEYWORD_USERS',
  EDIT_CONTACT_KEYWORD_USERS = 'EDIT_CONTACT_KEYWORD_USERS',
  REMOVE_CONTACT_KEYWORD = 'REMOVE_CONTACT_KEYWORD',
  ADD_NEW_CONTACT_KEYWORD = 'ADD_NEW_CONTACT_KEYWORD',
  ADD_NEW_CONTACT_NOTE = 'ADD_NEW_CONTACT_NOTE',
  EDIT_CONTACT_NOTE = 'EDIT_CONTACT_NOTE',
  REMOVE_CONTACT_NOTE = 'REMOVE_CONTACT_NOTE',
  SET_ERROR_FOLLOWERS = 'SET_ERROR_FOLLOWERS',
  SET_LOADING_FOLLOWERS = 'SET_LOADING_FOLLOWERS',
  SET_FOLLOWERS = 'SET_FOLLOWERS'
}

export interface IParticipantState {
  errorContacts?: string
  errorContactsFiltered?: string
  errorPresences?: string
  errorParticipants?: string
  errorParticipantsFiltered?: string
  errorFilters?: string
  errorKeywordsCreatedByUser?: string
  errorAdding?: string
  errorDeleting?: string
  errorUpdateContact?: string
  searchParticipants?: string
  participants: { total: number; items: IContact[] }
  participantsFiltered: { total: number; items: IContact[] }
  presenceUsersCount: number
  presenceUsers: IContact[]
  aroundUsers: IContact[]
  aroundUsersCount: number
  contacts: IContact[]
  contactsFiltered: IContact[]
  loadingParticipantsFiltered: boolean
  loadingContactsFiltered: boolean
  loadingContacts: boolean
  loading: boolean
  adding: number | null
  deleting: number | null
  refreshingContact: number | null
  contactsRefreshed: { [key: string]: IContact }
  filters: IFilters
  loadingFilters: boolean
  keywordsCreatedByUser: IKeyword[]
  loadedKeywordsCreatedByUser: boolean
  loadingKeywordsCreatedByUser: boolean
  loadingUpdateContact: boolean
  loadingUpdateNote: { [key: number]: boolean }
  loadingUpdateTags: { [key: number]: boolean }
  errorFollowers?: string
  loadingFollowers: boolean
  followers: IContact[]
}

export interface IParticipantFilters {
  isContact?: boolean
  presenceIn?: number
  memberOf?: number
  page?: number
  search?: string
  functionName?: string
  partnerType?: { id: number; name: string }
  isOnline?: boolean
  location?: string
  tags?: string
  keywordsX?: string
  keywordsY?: string
  sortBy?: string
  roles?: string
}
