import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Card, CardActionArea, CardMedia } from '@material-ui/core'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IExhibitorDemo } from '../../entities/exhibitor'
import { useDetectImageExist, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setPageReferral } from '../../store/gtm'
import { BLACK_BLUE, WHITE } from '../../theme/colors'
import DemoTag from '../globals/DemoTag/DemoTag'
import LiveCounterSvg from '../globals/LiveCounterSvg/LiveCounterSvg'

interface ShowroomCardProps {
  showroom: IExhibitorDemo
  goBackList?: boolean
}

const ShowroomCard: React.FC<ShowroomCardProps> = ({ showroom, goBackList }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const image = useDetectImageExist(showroom.businessEventExhibitor.imageUrl, false, '&size=small')
  const dispatch = useThunkDispatch()

  const onClickCard = () => {
    dispatch(setPageReferral(`${pageReferral}::card-showroom`))
    sendDataToGTM({
      event: 'click-card',
      cardType: 'Showroom',
      cardName: `${showroom.businessEventExhibitor.name} - ${showroom.name ? showroom.name : 'Table sans nom'
        }`
    })
    browserHistory.push({
      pathname: `/exposant/${showroom.businessEventExhibitor.id}`,
      state: { goBack: goBackList || false }
    })
  }

  return (
    <StyledCard elevation={1} onClick={onClickCard}>
      <StyledCardActionArea>
        <StyledCardMedia image={image} title={showroom.businessEventExhibitor.shortBody} />
        <StyledOverlay />
        {showroom.businessEventExhibitor.live && <DemoTag />}
        <LiveCounterSvg
          display={showroom.businessEventExhibitor.nbUsers > 0}
          contentText={intl.formatMessage(
            { id: 'total.visitor' },
            { visitors: showroom.businessEventExhibitor.nbUsers }
          )}
          color="secondary"
        />
        <StyledPlayCircleOutlineIcon
          titleAccess={intl.formatMessage({ id: 'titleAccess.LiveCard.PlayCircleOutlineIcon' })}
        />
        <StyledText>
          <StyledTitle>{showroom.name}</StyledTitle>
        </StyledText>
      </StyledCardActionArea>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  & {
    width: calc(100% - 16px);
    margin: 8px;
    height: 200px;
    cursor: pointer;

    button {
      height: 100%;
    }
  }
`

const StyledCardActionArea = styled(CardActionArea)`
  & {
    position: relative;
  }
`
const StyledOverlay = styled.div`
  & {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.71;
    background-color: ${BLACK_BLUE};
  }
`

const StyledPlayCircleOutlineIcon = styled(PlayCircleOutlineIcon)`
  & {
    position: absolute;
    top: 50%;
    left: 50%;
    color: ${WHITE};
    height: 50px;
    width: 50px;
    transform: translate(-50%, -50%);
  }
`

const StyledCardMedia = styled(CardMedia)`
  & {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
`

const StyledText = styled.div`
  & {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 8px;
    max-height: 70px;
    color: ${WHITE};
  }
`

const StyledTitle = styled.h3`
  & {
    margin-top: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    max-width: calc(100% - 30px);
  }
`

export default ShowroomCard
