export interface IAlert {
  id: string
  content?: string
  type: AlertType
  intlKey?: string
}

export enum AlertType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info'
}
