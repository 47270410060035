import { IEnterRoomZoom } from '../../entities'
import { ActionsTypes } from './model'

export const setZoomLoading = (loadingZoom: boolean) => ({
  type: ActionsTypes.SET_LOADING_ZOOM,
  loadingZoom
})

export const setZoomRoom = (zoomRoom?: IEnterRoomZoom) => ({
  type: ActionsTypes.SET_ZOOM_ROOM,
  zoomRoom
})

export const setZoomError = (errorZoom?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_ZOOM,
  errorZoom
})
