import styled from '@emotion/styled'
import {
  alpha,
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch,
  Typography
} from '@material-ui/core'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import SearchInput from '../../components/ui/SearchInput/SearchInput'
import { GROUP_ROLES } from '../../entities'
import { useEffectOnce, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { loadFiltersUsers, loadKeywordsCreatedByUser } from '../../store/participant/thunks'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../theme'
import { BLACK_BLUE, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD, FILTERS_HEIGHT_BUTTON_MOBILE } from '../../theme/sizes'
import { breakpoints } from '../breakpoints'
import { Label } from '../ui/LabelWrapper/LabelWrapper'
import SearchFilters from '../ui/SearchFilters/SearchFilters'
import SearchByTagContact from './SearchByTagContact'

interface IVisitorFiltersSearch {
  setPage: (page: number) => void
  setFilterSearch: (value: string) => void
  filterSearch: string
  setFilterCustom: (value: {
    [key: string]: number | string | { id: number; name: string }
  }) => void
  filterCustom: { [key: string]: number | string | { id: number; name: string } }
  setFilterIsOnline: (value: boolean) => void
  filterIsOnline: boolean
  setFilterIsContact: (value: boolean) => void
  filterIsContact: boolean
  filterTags: { [key: string]: boolean }
  setFilterTags: (value: { [key: string]: boolean }) => void
  filterKeywordsX: { [key: string]: boolean }
  setFilterKeywordsX: (value: { [key: string]: boolean }) => void
  filterKeywordsY: { [key: string]: boolean }
  setFilterKeywordsY: (value: { [key: string]: boolean }) => void
  filterUserRoles?: string
  setFilterUserRoles?: (value: string) => void
}

const VisitorFiltersSearch: React.FC<IVisitorFiltersSearch> = ({
  setPage,
  setFilterSearch,
  filterSearch,
  setFilterCustom,
  filterCustom,
  setFilterIsOnline,
  filterIsOnline,
  setFilterIsContact,
  filterIsContact,
  filterTags,
  setFilterTags,
  filterKeywordsX,
  setFilterKeywordsX,
  filterKeywordsY,
  setFilterKeywordsY,
  filterUserRoles,
  setFilterUserRoles
}) => {
  const intl = useIntl()
  const [openMenu, setOpenMenu] = useState(false)
  const [currentFilterSearch, setCurrentFilterSearch] = useState(filterSearch)
  const participants = useSelector((state: IRootState) => state.participantState.participants)
  const filters = useSelector((state: IRootState) => state.participantState.filters)
  const loading = useSelector((state: IRootState) => state.participantState.loadingFilters)
  const error = useSelector((state: IRootState) => state.participantState.errorFilters)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const user = useSelector((state: IRootState) => state.appState.user)
  const [roles, setRoles] = useState<{ [key: string]: boolean }>({})
  const dispatch = useThunkDispatch()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRoles = { ...roles, [event.target.name]: event.target.checked }
    setRoles(newRoles)
    if (setFilterUserRoles) {
      setFilterUserRoles(
        Object.keys(newRoles)
          .filter((newRole) => newRoles[newRole])
          .join(',')
      )
    }
  }

  const handleToggleMenu = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault()
    event.stopPropagation()
    setOpenMenu(!openMenu)
  }

  useEffectOnce(() => {
    if (user?.id) {
      // prepare roles
      const userRoles = user.userGroupRoles
        ? user.userGroupRoles.map((userRole) => userRole.role).join(',')
        : ''
      const initRoles: { [key: string]: boolean } = {}
      if (settings.useUserRole.indexOf(GROUP_ROLES.ROLE_VISITOR) > -1) {
        initRoles[GROUP_ROLES.ROLE_VISITOR] = filterUserRoles
          ? filterUserRoles.indexOf(GROUP_ROLES.ROLE_VISITOR) > -1
          : false /* should be true */
      }
      if (settings.useUserRole.indexOf(GROUP_ROLES.ROLE_EXHIBITOR) > -1) {
        initRoles[GROUP_ROLES.ROLE_EXHIBITOR] = filterUserRoles
          ? filterUserRoles.indexOf(GROUP_ROLES.ROLE_EXHIBITOR) > -1
          : false /* should be true */
      }
      if (settings.useUserRole.indexOf(GROUP_ROLES.ROLE_SPEAKER) > -1) {
        initRoles[GROUP_ROLES.ROLE_SPEAKER] = filterUserRoles
          ? filterUserRoles.indexOf(GROUP_ROLES.ROLE_SPEAKER) > -1
          : false /* should be true */
      }
      if (settings.useUserRole.indexOf(GROUP_ROLES.ROLE_CONTRACTOR) > -1) {
        initRoles[GROUP_ROLES.ROLE_CONTRACTOR] = filterUserRoles
          ? filterUserRoles.indexOf(GROUP_ROLES.ROLE_CONTRACTOR) > -1
          : userRoles.indexOf(GROUP_ROLES.ROLE_ORDERTAKER) > -1
      }
      if (settings.useUserRole.indexOf(GROUP_ROLES.ROLE_ORDERTAKER) > -1) {
        initRoles[GROUP_ROLES.ROLE_ORDERTAKER] = filterUserRoles
          ? filterUserRoles.indexOf(GROUP_ROLES.ROLE_ORDERTAKER) > -1
          : userRoles.indexOf(GROUP_ROLES.ROLE_CONTRACTOR) > -1
      }
      if (settings.useUserRole.indexOf(GROUP_ROLES.ROLE_EXPERT) > -1) {
        initRoles[GROUP_ROLES.ROLE_EXPERT] = filterUserRoles
          ? filterUserRoles.indexOf(GROUP_ROLES.ROLE_EXPERT) > -1
          : userRoles.indexOf(GROUP_ROLES.ROLE_VISITOR) > -1
      }
      setRoles(initRoles)
      if (setFilterUserRoles) {
        setFilterUserRoles(
          Object.keys(initRoles)
            .filter((newRole) => initRoles[newRole])
            .join(',')
        )
      }
    }
  })

  useEffect(() => {
    if (user?.id) {
      dispatch(loadKeywordsCreatedByUser(user.id))
    }
  }, [user, dispatch])

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setFilterSearch(currentFilterSearch)
      if (currentFilterSearch) {
        setPage(1)
      }
    }, 200)

    return () => window.clearTimeout(timer)
  }, [setFilterSearch, setPage, currentFilterSearch])

  useEffectOnce(() => {
    if (Object.keys(filters).length === 0) {
      dispatch(loadFiltersUsers())
    }
  })

  return (
    <StyledFilters>
      <StyledTitleMobile>
        <StyledTitleSearch variant="h2">
          <FormattedMessage id="visitor.title.search" />
        </StyledTitleSearch>
      </StyledTitleMobile>
      {openMenu && <StyledOverlayOpenMobile onClick={handleToggleMenu} />}
      <StyledButtonOpenMobile open={openMenu} onClick={handleToggleMenu}>
        {openMenu && (
          <>
            <FormattedMessage id="filters.close" /> ({participants.total})
          </>
        )}
        {!openMenu && (
          <>
            <FormattedMessage id="filters.filter" />
          </>
        )}
      </StyledButtonOpenMobile>
      <StyledContentOpenMobile open={openMenu}>
        {!filterIsOnline && (
          <SearchInput
            name="visitorName"
            defaultValue={currentFilterSearch}
            placeholder={intl.formatMessage({ id: 'visitor.filter.relevance' })}
            handleChange={setCurrentFilterSearch}
            resetSearch={() => setCurrentFilterSearch('')}
          />
        )}
        {Object.keys(roles).length > 1 && (
          <FormControl>
            <Label>
              <FormattedMessage id="filter.userRole" />
            </Label>
            {Object.keys(roles).map((role, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox checked={roles[role]} onChange={handleChange} name={role} />}
                label={intl.formatMessage({ id: `profileForm.role.${role}` })}
              />
            ))}
          </FormControl>
        )}
        {!eventCo?.eventcoAppointment && (
          <FormControl>
            <Label id="is-online-label">
              <FormattedMessage id="is.online" />
            </Label>
            <StyledSwitch
              aria-labelledby="is-online-label"
              checked={filterIsOnline}
              color="primary"
              title={intl.formatMessage({ id: filterIsOnline ? 'is.online' : 'not.online' })}
              onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                setFilterIsOnline(checked)
                setPage(1)
              }}
              name="isOnline"
            ></StyledSwitch>
          </FormControl>
        )}
        {!eventCo?.eventcoRemoteOffice && !settings.disableFeatureContacts && (
          <FormControl>
            <Label id="is-contact-label">
              <FormattedMessage id="is.contact" />
            </Label>
            <StyledSwitch
              aria-labelledby="is-contact-label"
              checked={filterIsContact}
              color="primary"
              title={intl.formatMessage({ id: filterIsContact ? 'is.contact' : 'not.contact' })}
              onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                setFilterIsContact(checked)
                setPage(1)
              }}
              name="isContact"
            ></StyledSwitch>
          </FormControl>
        )}
        {!filterIsOnline && (
          <SearchFilters
            filters={filters}
            loading={loading}
            error={error}
            filterCustom={filterCustom}
            onChangeValue={(value) => {
              setFilterCustom(value)
              setPage(1)
            }}
          />
        )}
        {!filterIsOnline && (
          <SearchByTagContact
            filterTags={filterTags}
            setFilterTags={setFilterTags}
            filterKeywordsX={filterKeywordsX}
            setFilterKeywordsX={setFilterKeywordsX}
            filterKeywordsY={filterKeywordsY}
            setFilterKeywordsY={setFilterKeywordsY}
          />
        )}
      </StyledContentOpenMobile>
    </StyledFilters>
  )
}

const StyledFilters = styled.div`
  & {
    @media (min-width: ${breakpoints.lg}) {
      margin-bottom: 1em;
      padding: 1em;
      background-color: ${WHITE};
      border-radius: ${BORDER_RADIUS_CARD};
    }
  }
`

const StyledOverlayOpenMobile = styled.div`
  & {
    @media (max-width: ${breakpoints.lg}) {
      z-index: 1000;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${alpha(BLACK_BLUE, 0.38)};
    }
  }
`

const StyledTitleMobile = styled.div`
  & {
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.lg}) {
      display: none;
    }
  }
`

const StyledButtonOpenMobile = styled.div<{ open: boolean }>`
  & {
    display: none;

    @media (max-width: ${breakpoints.lg}) {
      z-index: ${(props) => (props.open ? `1050` : '950')};
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      height: ${FILTERS_HEIGHT_BUTTON_MOBILE};
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${WHITE};
      border-top: 1px solid ${SECONDARY_COLOR};
      background-color: ${PRIMARY_COLOR};
      font-size: 18px;
    }
  }
`

const StyledContentOpenMobile = styled.div<{ open: boolean }>`
  & {
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.lg}) {
      z-index: ${(props) => (props.open ? `1049` : '949')};
      position: fixed;
      left: 0;
      bottom: ${(props) => (props.open ? `${FILTERS_HEIGHT_BUTTON_MOBILE}` : '-100vh')};
      right: 0;
      padding: 15px;
      background-color: ${WHITE};
      transition: bottom 0.3s ease-in;
      max-height: calc(100vh - ${FILTERS_HEIGHT_BUTTON_MOBILE});
    }
  }
`

const StyledTitleSearch = styled(Typography)`
  & {
    margin-top: 0;
    font-size: 18px;
    line-height: 21px;
  }
`

const StyledSwitch = styled(Switch)`
  & {
    margin-bottom: 10px;
  }
`

export default VisitorFiltersSearch
