import styled from '@emotion/styled'
import React from 'react'
import { IMagicStream, NotificationType } from '../../entities'
import AnnounceRender from '../globals/AnnounceRender'

type IStreamAnnouncePremiumProps = {
  stream: IMagicStream
}

const StreamAnnouncePremium: React.FC<IStreamAnnouncePremiumProps> = ({ stream }) => {
  return (
    <StreamAnnouncePremiumContainer>
      <AnnounceRender
        notification={{
          type: NotificationType.PREMIUM_ANNOUNCE,
          params: {
            url: stream.ctas?.video || stream.ctas?.internal || stream.ctas?.external,
            user: stream.creatorUser,
            createDate: stream.createDate
          },
          message: stream.content as string
        }}
      />
    </StreamAnnouncePremiumContainer>
  )
}

const StreamAnnouncePremiumContainer = styled.div`
  & {
    position: relative;
    overflow: hidden;
    display: flex;
    white-space: pre-wrap;
    margin: 10px 0;

    > .MuiAvatar {
      flex: 0 0 50px;
      width: 50px;
    }
  }
`

export default StreamAnnouncePremium
