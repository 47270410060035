import styled from '@emotion/styled'
import { Button, FormControl, FormHelperText } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContact } from '../../entities'
import { useDateFns, useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { getInitDateAgendaSlots } from '../../store/app'
import {
  setContactAppointment,
  setErrorSending,
  setSended,
  takeAppointment
} from '../../store/chat'
import { formatName, getPlainText } from '../../utils'
import AgendaHistory from '../agenda/AgendaHistory'
import UserAvatar from '../globals/UserAvatar/UserAvatar'
import UserAvatarGroup from '../globals/UserAvatarGroup/UserAvatarGroup'
import Editor from '../ui/Editor/Editor'
import FormContainer from '../ui/Form/FormContainer'
import { Label } from '../ui/LabelWrapper/LabelWrapper'
import ContactAppointmentPlace from './ContactAppointmentPlace'
import DateSlotPicker from './DateSlotPicker'

interface IContactTakeAppointmentFormProps {
  contact: IContact
  showDate?: boolean
  onlyMessage?: boolean
  withSubmit?: boolean
  sent?: boolean
  setSent?: (value: boolean) => void
  setDisableSend?: (value: boolean) => void
  onAppointmentSended?: () => void
}

const ContactTakeAppointmentForm: React.FC<IContactTakeAppointmentFormProps> = ({
  contact,
  showDate,
  onlyMessage,
  withSubmit,
  sent,
  setSent,
  setDisableSend,
  onAppointmentSended
}) => {
  const intl = useIntl()
  // const { sendDataToGTM } = useGTM()
  const dateFns = useDateFns()
  const magicStream = useMagicStream()
  const [message, setMessage] = useState('')
  const [date, setDate] = useState<Date>()
  const [dateTime, setDateTime] = useState<Date>()
  const [conferenceId, setConferenceId] = useState<number | undefined>(undefined)
  const [exhibitorId, setExhibitorId] = useState<number | undefined>(undefined)
  const [roomId, setRoomId] = useState<number | undefined>(undefined)
  const [customMessage, setCustomMessage] = useState<string | undefined>('')
  const user = useSelector((state: IRootState) => state.appState.user)
  const agendaInitDate = useSelector((state: IRootState) => state.appState.agendaInitDate)
  const sending = useSelector((state: IRootState) => state.chatState.sending)
  const sended = useSelector((state: IRootState) => state.chatState.sended)
  const errorSending = useSelector((state: IRootState) => state.chatState.errorSending)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const dispatch = useThunkDispatch()

  const send = useCallback(() => {
    if (user && contact && dateTime) {
      // TODO Math : sendDataToGTM({
      //   event: 'send-appointment',
      //   appointmentType: 'appointment',
      //   appointmentModerator: formatName(to.userPresence.user).full,
      //   appointmentDate: `${dateFns.format(
      //     date.toString(),
      //     intl.formatMessage({ id: 'date.format' })
      //   )}`,
      //   appointmentMessage: `${getPlainText(message)}`
      // })
      const chatMessage = intl.formatMessage(
        { id: 'rdv.commercial' },
        {
          user: `<b>${formatName(user).full}</b>`,
          message: `<i>${getPlainText(message)}</i>${
            customMessage ? getPlainText(`<br/><br/><b>${customMessage}</b>`) : ''
          }`,
          date: dateFns.format(dateTime.toString(), intl.formatMessage({ id: 'date.format' }))
        }
      )
      dispatch(
        takeAppointment(
          [contact.id],
          onlyMessage
            ? chatMessage
            : `${getPlainText(message)}${customMessage ? ` (${getPlainText(customMessage)})` : ''}`,
          dateTime,
          conferenceId
            ? `/conference/${conferenceId}`
            : exhibitorId
            ? `/exposant/${exhibitorId}${roomId ? `?table=${roomId}` : ''}`
            : undefined,
          onlyMessage
        )
      )
    }
  }, [
    contact,
    dateFns,
    dateTime,
    intl,
    message,
    onlyMessage,
    customMessage,
    roomId,
    user,
    conferenceId,
    exhibitorId,
    dispatch
  ])

  useEffect(() => {
    if (eventCo && contact.id) {
      dispatch(getInitDateAgendaSlots(eventCo, contact.id))
    }
  }, [eventCo, contact, dispatch])

  useEffect(() => {
    if (agendaInitDate) {
      setDate(agendaInitDate)
    }
  }, [agendaInitDate])

  // auto send when submit is outside of component
  useEffect(() => {
    if (sent) {
      send()
      if (setSent) {
        setSent(false)
      }
    }
  }, [sent, send, setSent])

  useEffect(() => {
    if (setDisableSend) {
      setDisableSend(!message || !dateTime || sending)
    }
  }, [message, dateTime, sending, setDisableSend])

  useEffect(() => {
    if (sended && contact && agendaInitDate) {
      setMessage('')
      setDate(agendaInitDate)
      dispatch(setContactAppointment(null))
      dispatch(setSended(false))
      onAppointmentSended && onAppointmentSended()
    }
  }, [intl, sended, agendaInitDate, contact, onAppointmentSended, dispatch])

  useEffect(() => {
    if (errorSending && contact) {
      magicStream.error(errorSending)
      dispatch(setContactAppointment(null))
      dispatch(setErrorSending(undefined))
    }
  }, [errorSending, contact, magicStream, dispatch])

  return (
    <FormContainer box={withSubmit}>
      <AgendaHistory contact={contact} />
      {withSubmit && (
        <Label>
          <FormattedMessage id="rdv.title" />
        </Label>
      )}
      {!withSubmit && (
        <FormControl>
          <Label>
            <FormattedMessage
              id="rdv.to.user"
              values={{
                user: formatName(contact.userPresence.user).full
              }}
            />
          </Label>
          <UserAvatarGroup>
            <UserAvatar user={contact.userPresence.user} showDetails />
          </UserAvatarGroup>
        </FormControl>
      )}
      <StyledFormDiv>
        <Label>
          <FormattedMessage id="rdv.message" />
        </Label>
        <Editor
          content={message}
          readonly={sending}
          placeholder={
            user?.isOnSite && contact.isOnSite && settings.meetingLocation
              ? intl.formatMessage({ id: 'rdv.placeholder.withoutLocation' })
              : intl.formatMessage({ id: 'rdv.placeholder' })
          }
          onContentChange={setMessage}
          startupFocus
        />
        {!message && (
          <FormHelperText error>
            <FormattedMessage
              id="form.isRequired"
              values={{ field: intl.formatMessage({ id: 'rdv.message' }) }}
            />
          </FormHelperText>
        )}
      </StyledFormDiv>
      {showDate && (
        <StyledFormDiv>
          <DateSlotPicker
            userId={contact.id}
            date={date}
            setDate={setDate}
            setDateTime={setDateTime}
          />
          {!dateTime && (
            <FormHelperText error>
              {date ? (
                <FormattedMessage
                  id="form.isRequired"
                  values={{ field: intl.formatMessage({ id: 'slots.picker' }) }}
                />
              ) : (
                <FormattedMessage
                  id="form.isRequired"
                  values={{ field: intl.formatMessage({ id: 'rdv.date.title' }) }}
                />
              )}
            </FormHelperText>
          )}
        </StyledFormDiv>
      )}
      <ContactAppointmentPlace
        setConferenceId={setConferenceId}
        setExhibitorId={setExhibitorId}
        setRoomId={setRoomId}
        customMessage={customMessage}
        setCustomMessage={setCustomMessage}
        isOnsite={user?.isOnSite && contact.isOnSite}
      />
      {withSubmit && (
        <StyledButton
          color="primary"
          variant="contained"
          disabled={!message || !dateTime || sending}
          onClick={() => send()}
        >
          <FormattedMessage id="rdv.submit" />
        </StyledButton>
      )}
    </FormContainer>
  )
}

const StyledButton = styled(Button)`
  width: 200px;
  margin: 0 auto;
`

const StyledFormDiv = styled.div`
  margin-bottom: 20px;
`

export default ContactTakeAppointmentForm
