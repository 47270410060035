import styled from '@emotion/styled'
import { IconButton, Input, InputAdornment } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import React from 'react'
import { useIntl } from 'react-intl'
import { GRAY_ICON } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'

interface SearchInputProps {
  placeholder?: string
  name: string
  defaultValue?: string
  handleChange?: (searchValue: string) => void
  resetSearch?: () => void
}

const SearchInput: React.FC<SearchInputProps> = ({
  name,
  defaultValue,
  placeholder,
  handleChange,
  resetSearch
}) => {
  const intl = useIntl()
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value as string
    if (handleChange && value !== defaultValue) {
      handleChange(value)
    }
  }

  return (
    <StyledInput
      type="text"
      name={name}
      value={defaultValue || ''}
      onChange={onChange}
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon
            titleAccess={intl.formatMessage({ id: 'titleAccess.SearchInput.SearchIcon' })}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          {defaultValue && (
            <StyledIconButton
              aria-label={intl.formatMessage({ id: 'ariaLabel.SearchInput.ClearIcon' })}
              onClick={resetSearch}
            >
              <ClearIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.SearchInput.ClearIcon' })}
              />
            </StyledIconButton>
          )}
        </InputAdornment>
      }
    />
  )
}

// language=SCSS
const StyledInput = styled(Input)`
  & {
    position: relative;
    margin-bottom: 15px;
    width: 100%;
    font-size: 18px;

    .MuiInput-input {
      font-size: 16px;
      border-radius: ${BORDER_RADIUS_CARD};
      transition: all 0.3s;
    }
    svg {
      color: ${GRAY_ICON};
    }
  }
`

// language=SCSS
const StyledIconButton = styled(IconButton)`
  & {
    padding: 2px;
  }
`

export default SearchInput
