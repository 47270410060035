import { IconButton, ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IContact } from '../../../entities'
import { useThunkDispatch } from '../../../hooks'
import { setOpenMenuContact } from '../../../store/app'
import { setContactAppointment } from '../../../store/chat'

type IProps = {
  contact: IContact
  iconOnly?: boolean
  onClose?: (event: React.MouseEvent<EventTarget>) => void
}

const ActionTakeAppointment: React.FC<IProps> = ({ contact, iconOnly, onClose }) => {
  const intl = useIntl()
  const dispatch = useThunkDispatch()

  const handleTakeAppointment = (
    event: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(setContactAppointment(contact))
    dispatch(setOpenMenuContact(false))
    onClose && onClose(event)
  }

  return (
    <>
      {/* // take appointment */}
      {iconOnly ? (
        <IconButton onClick={handleTakeAppointment}>
          <EventIcon
            fontSize="large"
            titleAccess={intl.formatMessage({
              id: 'titleAccess.ContactPopperAction.TakeAppointmentIcon'
            })}
          />
        </IconButton>
      ) : (
        <MenuItem onClick={handleTakeAppointment}>
          <ListItemIcon>
            <EventIcon
              fontSize="large"
              titleAccess={intl.formatMessage({
                id: 'titleAccess.ContactPopperAction.TakeAppointmentIcon'
              })}
            />
          </ListItemIcon>
          <Typography variant="inherit">
            <FormattedMessage id="contactpopper.button_takeappointment" />
          </Typography>
        </MenuItem>
      )}
    </>
  )
}

export default ActionTakeAppointment
