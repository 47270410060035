import styled from '@emotion/styled'
import { Chip } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IKeywordDisplay } from '../../../entities/keyword'
import { useMagicStream, useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import { loadKeywords, setKeywordsError } from '../../../store/app'
import Loading from '../../Loading'
import { Label } from '../LabelWrapper/LabelWrapper'

interface ISearchKeywords {
  onChangeValue: (value: string) => void
}

const SearchKeywordsCheckbox: React.FC<ISearchKeywords> = ({ onChangeValue }) => {
  const magicStream = useMagicStream()
  const keywordsList = useSelector((state: IRootState) => state.appState.keywords)
  const loading = useSelector((state: IRootState) => state.appState.loadingKeywords)
  const error = useSelector((state: IRootState) => state.appState.errorKeywords)
  const [keywords, setKeywords] = useState<IKeywordDisplay[]>([])
  const dispatch = useThunkDispatch()

  const toggleKeyword = useCallback(
    (selected: number) => {
      const keywordValueTab: number[] = []
      const keywordsTab: IKeywordDisplay[] = keywords.map((keyword, index) => {
        if (index === selected) {
          keyword = { ...keyword, selected: !keyword.selected }
        }
        if (keyword.selected) {
          keywordValueTab.push(keyword.id)
        }
        return keyword
      })
      setKeywords(keywordsTab)
      onChangeValue(keywordValueTab.length ? keywordValueTab.join(',') : '')
    },
    [keywords, setKeywords, onChangeValue]
  )

  useEffect(() => {
    if (keywordsList.total === 0) {
      dispatch(loadKeywords())
    }
  }, [keywordsList, dispatch])

  useEffect(() => {
    if (error) {
      magicStream.error(error)
      dispatch(setKeywordsError(undefined))
    }
  }, [error, magicStream, dispatch])

  useEffect(() => {
    if (keywordsList && keywordsList.total > 0 && keywords.length === 0) {
      setKeywords(
        keywordsList.items.map((k) => ({
          id: k.id,
          title: k.title,
          selected: false
        }))
      )
    }
  }, [keywordsList, keywords])

  if (loading) return <Loading />
  if (!loading && keywords && keywords.length === 0) {
    return <React.Fragment />
  }

  return (
    <StyledChipContainer>
      <Label>
        <FormattedMessage id="label.themes" />
      </Label>
      {keywords &&
        keywords.map((keyword, index) => (
          <Chip
            key={index}
            label={keyword.title}
            color={keyword.selected ? 'primary' : 'default'}
            clickable
            onClick={() => toggleKeyword(index)}
          />
        ))}
    </StyledChipContainer>
  )
}

// language=SCSS
const StyledChipContainer = styled.div`
  & {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    & > * {
      margin: 3px;
    }
    label {
      display: block;
      margin-bottom: 10px;
      width: 100%;
    }
  }
`

export default SearchKeywordsCheckbox
