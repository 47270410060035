import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IProductState } from './model'

const initialState: IProductState = {
  products: { total: 0, items: [] },
  loading: false,
  deleting: {},
  product: null,
  updating: false,
  productAdding: false,
  productUpdating: false,
  filters: {},
  loadingFilters: false
}

const actionHandlers: IReducerHandler<IProductState> = {
  [ActionsTypes.SET_PRODUCT_SEARCH]: (
    state: IProductState | undefined,
    { searchProducts }: AnyAction
  ): IProductState => ({
    ...state!,
    searchProducts
  }),

  [ActionsTypes.SET_PRODUCT_ERROR]: (
    state: IProductState | undefined,
    { error }: AnyAction
  ): IProductState => ({
    ...state!,
    error
  }),

  [ActionsTypes.SET_PRODUCT_LOADING]: (
    state: IProductState | undefined,
    { loading }: AnyAction
  ): IProductState => ({
    ...state!,
    loading
  }),

  [ActionsTypes.SET_PRODUCT_DELETING]: (
    state: IProductState | undefined,
    { key, deleting }: AnyAction
  ): IProductState => ({
    ...state!,
    deleting: { ...state?.deleting, ...{ [key]: deleting } }
  }),

  [ActionsTypes.SET_PRODUCTS]: (
    state: IProductState | undefined,
    { products }: AnyAction
  ): IProductState => ({
    ...state!,
    products
  }),

  [ActionsTypes.DELETE_PRODUCT]: (
    state: IProductState | undefined,
    { productId }: AnyAction
  ): IProductState => ({
    ...state!,
    products:
      state && state.products.items.length > 0
        ? {
          total: state.products.total--,
          items: state.products.items.filter((p) => p.id !== productId)
        }
        : { total: 0, items: [] }
  }),

  [ActionsTypes.RESET_PRODUCT]: (state: IProductState | undefined): IProductState => ({
    ...state!,
    product: null
  }),

  [ActionsTypes.SET_PRODUCT]: (
    state: IProductState | undefined,
    { product }: AnyAction
  ): IProductState => ({
    ...state!,
    product
  }),

  [ActionsTypes.SET_PRODUCT_FILTERS]: (
    state: IProductState | undefined,
    { filters }: AnyAction
  ): IProductState => ({
    ...state!,
    filters
  }),

  [ActionsTypes.SET_LOADING_PRODUCT_FILTERS]: (
    state: IProductState | undefined,
    { loadingFilters }: AnyAction
  ): IProductState => ({
    ...state!,
    loadingFilters
  }),

  [ActionsTypes.SET_ERROR_PRODUCT_FILTERS]: (
    state: IProductState | undefined,
    { errorFilters }: AnyAction
  ): IProductState => ({
    ...state!,
    errorFilters
  }),

  [ActionsTypes.SET_PRODUCT_UPDATING]: (
    state: IProductState | undefined,
    { productUpdating }: AnyAction
  ): IProductState => ({
    ...state!,
    productUpdating
  }),

  [ActionsTypes.SET_PRODUCT_ADDING]: (
    state: IProductState | undefined,
    { productAdding }: AnyAction
  ): IProductState => ({
    ...state!,
    productAdding
  }),

  [ActionsTypes.SET_PRODUCT_KEYWORDS]: (
    state: IProductState | undefined,
    { productKeywords }: AnyAction
  ): IProductState => ({
    ...state!,
    productKeywords
  }),

  [ActionsTypes.SET_ERROR_PRODUCT_KEYWORDS]: (
    state: IProductState | undefined,
    { errorProductKeywords }: AnyAction
  ): IProductState => ({
    ...state!,
    errorProductKeywords
  }),

  [ActionsTypes.MERGE_PRODUCT]: (
    state: IProductState | undefined,
    { product }: AnyAction
  ): IProductState => ({
    ...state!,
    products:
      state && state.products.items
        ? {
          total: state.products.total++,
          items: { ...state.products.items, ...product }
        }
        : { total: 0, items: [] }
  }),

  [ActionsTypes.UPDATE_PRODUCT]: (
    state: IProductState | undefined,
    { product }: AnyAction
  ): IProductState => ({
    ...state!,
    products:
      state && state.products
        ? {
          total: state.products.total++,
          items: state.products.items.map((p) => {
            if (product.id === p.id) {
              return product
            } else {
              return p
            }
          })
        }
        : { total: 0, items: [] }
  })
}

export const reducer = createReducer(initialState, actionHandlers)
