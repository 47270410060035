import { createTheme } from '@material-ui/core/styles'
import { ThemeOptions } from '@material-ui/core/styles/createTheme'
import { BLACK_BLUE, PRIMARY, SECONDARY, WHITE } from './colors'
import { BORDER_RADIUS_CARD, BOX_SHADOW } from './sizes'

const TRANSITION_BASE_DURATION = 0.3

const fontFamily = [
  'Montserrat',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
].join(', ')

export const TRANSITION_DURATION = (value: number = 1) => `${TRANSITION_BASE_DURATION * value}s`

export const DISABLED_TRANSPARENCY = 0.7

export const PRIMARY_COLOR = PRIMARY
export const SECONDARY_COLOR = SECONDARY

export const FONT_LIGHT = WHITE
export const FONT_DARK = BLACK_BLUE

const config: ThemeOptions = {
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      contrastText: FONT_LIGHT
    },
    secondary: {
      main: SECONDARY_COLOR,
      contrastText: FONT_LIGHT
    },
    text: {
      primary: `${BLACK_BLUE}`
    }
  },
  typography: {
    fontFamily: `${fontFamily}`,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    h1: {
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: '1.1',
      marginTop: '30px',
      marginBottom: '15px',
      '@media (min-width:600px)': {
        fontSize: 35
      }
    },
    h2: {
      fontSize: 20,
      fontWeight: 'bold',
      lineHeight: '1.1',
      marginTop: '30px',
      marginBottom: '15px',
      '@media (min-width:768px)': {
        fontSize: 35
      }
    },
    h3: {
      fontSize: 20,
      lineHeight: '1.1',
      fontWeight: 'bold',
      marginTop: '10px',
      marginBottom: '10px'
    },
    button: {
      textTransform: undefined,
      fontSize: '15px',
      fontWeight: 600
    }
  },
  overrides: {
    MuiAvatar: {
      root: {
        overflow: 'inherit',
        '& img': {
          borderRadius: `50%`
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: `${BORDER_RADIUS_CARD}`
      }
    },
    MuiButtonBase: {
      root: {
        borderRadius: `${BORDER_RADIUS_CARD}`
      }
    },
    MuiIconButton: {
      root: {
        borderRadius: `${BORDER_RADIUS_CARD}`
      }
    },
    MuiCard: {
      root: {
        borderRadius: `${BORDER_RADIUS_CARD}`
      }
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: `inherit`
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '20px',
        transform: 'none',
        position: 'relative'
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '20px'
      }
    },
    MuiInput: {
      root: {},
      input: {
        '&:focus': {
          borderColor: `${PRIMARY_COLOR}`
        }
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: '20px',
        '& .MuiInput-formControl': {
          marginTop: '5px'
        }
      }
    },
    MuiTextField: {
      root: {
        '& label': {
          position: 'relative',
          transform: 'none',
          color: `${BLACK_BLUE}`
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: `${WHITE}`,
        color: `${BLACK_BLUE}`,
        fontSize: 14,
        boxShadow: BOX_SHADOW
      }
    }
  }
}

export const Theme = createTheme(config)
