import styled from '@emotion/styled'
import React, { ReactNode, useCallback, useEffect } from 'react'
import { breakpoints } from '../../breakpoints'
import { fullscreenStyle } from '../Room/FullscreenStyle'

interface IProps {
  children: ReactNode
  fullscreen?: boolean
  defaultDisplay?: boolean | undefined
  defaultWidth: number
  defaultHeight: number
  setCurrentWidth: (width: number) => void
  setCurrentHeight: (height: number) => void
}

const VideoContainer: React.FC<IProps> = ({
  children,
  fullscreen,
  defaultDisplay,
  defaultWidth,
  defaultHeight,
  setCurrentWidth,
  setCurrentHeight
}) => {
  const refVideoContainer = React.createRef<HTMLDivElement>()

  const handleResize = useCallback(() => {
    if (fullscreen) {
      setCurrentWidth(refVideoContainer.current?.clientWidth!)
      setCurrentHeight(refVideoContainer.current?.clientHeight!)
    }
  }, [fullscreen, refVideoContainer, setCurrentWidth, setCurrentHeight])

  useEffect(() => {
    if (!defaultDisplay) {
      if (fullscreen) {
        handleResize()
      } else {
        setCurrentWidth(defaultWidth)
        setCurrentHeight(defaultHeight)
      }
    }
  }, [
    defaultDisplay,
    fullscreen,
    handleResize,
    defaultWidth,
    defaultHeight,
    setCurrentWidth,
    setCurrentHeight
  ])

  useEffect(() => {
    if (!defaultDisplay) {
      window.addEventListener('resize', handleResize)
      handleResize()

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [defaultDisplay, handleResize])

  return (
    <StyledVideoContainer ref={refVideoContainer} fullscreen={fullscreen}>
      {children}
    </StyledVideoContainer>
  )
}

const StyledVideoContainer = styled.div<{
  fullscreen?: boolean
}>`
  position: relative;
  background-color: black;
  ${(props) => props.fullscreen && fullscreenStyle}

  @media (min-width: ${breakpoints.md}) {
    .actions-hover {
      opacity: 0;
    }
    &:hover {
      .actions-hover {
        opacity: 1;
      }
    }
  }
`

export default VideoContainer
