import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IChat } from '../../../entities/chat'
import { IRootState } from '../../../redux'
import pusherUtils from '../../../utils/pusherUtils'
import ChatBox from '../Chat/ChatBox'
import ChatList from '../Chat/ChatList'

const VideoChat: React.FC = () => {
  const user = useSelector((state: IRootState) => state.appState.user)
  const standRoomChannel = useSelector((state: IRootState) => state.standState.standRoomChannel)
  const [chats, setChats] = useState<IChat[]>([])
  const [newMessage, setNewMessage] = useState(false)

  const handleTextChange = useCallback(
    (text: string) => {
      if (user) {
        const payload = {
          id: user.id,
          user: {
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            isOnline: true, // sender is online
            avatarPath: user.avatarPath
          },
          message: text,
          createDate: new Date().toUTCString()
        }
        setChats([payload, ...chats])
        // trigger message to all user in room
        if (standRoomChannel) {
          pusherUtils.triggerClientMessage(standRoomChannel, 'client-message-chat', payload)
        }
      }
    },
    [standRoomChannel, user, chats]
  )

  useEffect(() => {
    if (standRoomChannel) {
      standRoomChannel.bind('client-message-chat', (data: IChat) => {
        setChats([data, ...chats])
        setNewMessage(true)
      })
    }
    return () => {
      if (standRoomChannel) {
        standRoomChannel.unbind('client-message-chat')
      }
    }
  }, [standRoomChannel, chats])

  return (
    <ChatContainer>
      <ChatList chats={chats} newMessage={newMessage} setNewMessage={setNewMessage} sendings={[]} />
      <ChatBox handleTextChange={handleTextChange} />
    </ChatContainer>
  )
}

const ChatContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export default VideoChat
