import useGTM from '@elgorditosalsero/react-gtm-hook'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { alpha } from '@material-ui/core'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import React from 'react'
import { useIntl } from 'react-intl'
import { ReactPlayerProps } from 'react-player'
import { useSelector } from 'react-redux'
import { IConference } from '../../entities'
import { useDetectImageExist, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setPageReferral } from '../../store/gtm'
import { BLACK_BLUE, WHITE } from '../../theme/colors'
import {
  BORDER_RADIUS_CARD,
  LIVE_CARD_LARGE_HEIGHT,
  LIVE_CARD_SMALL_HEIGHT
} from '../../theme/sizes'
import { getProgressBarNumber } from '../conference/ConferenceUtils'
import LiveCounterSvg from '../globals/LiveCounterSvg/LiveCounterSvg'
import ProgressBar from '../ui/ProgressBar/ProgressBar'

interface ILiveCardProps extends ReactPlayerProps {
  size?: 'small' | 'large'
  live: IConference
}

const LiveCard: React.FC<ILiveCardProps> = ({ size, live }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const image = useDetectImageExist(live.imageUrl)
  const dispatch = useThunkDispatch()

  const onClickCard = () => {
    dispatch(setPageReferral(`${pageReferral}::card-live`))
    sendDataToGTM({ event: 'click-card', cardType: 'Live', cardTitle: `${live.title}` })
    // auto enter room with redirect_url
    const [pathname, search] = live.redirect_url ? live.redirect_url.split('?') : []
    browserHistory.push({
      pathname: live.redirect_url ? pathname : `/conference/${live.id}`, // or url of conference
      search: live.redirect_url ? search : undefined
    })
  }

  return (
    <StyledLiveCard size={size} onClick={onClickCard}>
      <StyledContainer size={size}>
        <BackgroundImage backgroundImage={image} />
        <OverlayBackgroundImage />
        <LiveCounterSvg display={live.nbUsers > 0} contentText={`${live.nbUsers}`} />
        {size === 'small' && (
          <StyledContentSmall>
            <PlayCircleOutlineIcon
              titleAccess={intl.formatMessage({ id: 'titleAccess.LiveCard.PlayCircleOutlineIcon' })}
            />
            <StyledTextSmall>
              <StyledTitle>{live.title}</StyledTitle>
              <StyledDescription>{live.summary}</StyledDescription>
            </StyledTextSmall>
          </StyledContentSmall>
        )}
        {size !== 'small' && (
          <>
            <StyledPlayCircleOutlineIcon
              titleAccess={intl.formatMessage({ id: 'titleAccess.LiveCard.PlayCircleOutlineIcon' })}
            />
            <StyledTextLarge title={live.title}>
              <StyledTitle>{live.title}</StyledTitle>
              <StyledDescription>{live.summary}</StyledDescription>
            </StyledTextLarge>
          </>
        )}
        <ProgressBar progress={getProgressBarNumber(live)} />
      </StyledContainer>
    </StyledLiveCard>
  )
}

const StyledLiveCard = styled.div<{ size?: 'small' | 'large' }>`
  & {
    position: relative;

    .MuiAvatar-circle {
      height: 30px;
      width: 30px;
    }
  }
`

const CssBorderRadius = css`
  border-radius: ${BORDER_RADIUS_CARD};
  overflow: hidden;
`

const StyledContainer = styled.div<{ size?: 'small' | 'large' }>`
  & {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: ${(props) =>
    props.size === 'small' ? LIVE_CARD_SMALL_HEIGHT : LIVE_CARD_LARGE_HEIGHT};
    ${CssBorderRadius}
  }
`

const BackgroundImage = styled.div<{ backgroundImage: string }>`
  & {
    width: 100%;
    height: 100%;
    background-color: ${BLACK_BLUE};
    background-image: ${(props) =>
    props.backgroundImage ? `url("${props.backgroundImage}")` : 'none'};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.7;
    ${CssBorderRadius}
  }
`

const OverlayBackgroundImage = styled.div`
  & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.71;
    background-color: ${BLACK_BLUE};
    ${CssBorderRadius}
  }
`

const StyledTextLarge = styled.div`
  & {
    position: absolute;
    left: 15px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #fff;
    max-width: calc(100% - 30px);
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const StyledTextSmall = styled.div`
  & {
    color: #fff;
    padding-left: 10px;
  }
`

const StyledTitle = styled.h3`
  & {
    margin-top: 0;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 5px;
  }
`

const StyledDescription = styled.div`
  & {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.24px;
  }
`

const StyledContentSmall = styled.div`
  & {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10px;
    display: flex;
    align-items: center;

    svg {
      flex: 0 0 60px;
      width: 60px;
      font-size: 50px;
      color: ${alpha(WHITE, 0.47)};
    }
  }
`

const StyledPlayCircleOutlineIcon = styled(PlayCircleOutlineIcon)`
  & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 100px;
    color: ${WHITE};
  }
`

export default LiveCard
