import React from 'react'

const SvgPencil: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M36.23 8a5.334 5.334 0 0 0-3.796 1.572L11.15 30.858a1.193 1.193 0 0 0-.306.525l-2.8 10.105a1.193 1.193 0 0 0 1.469 1.469l10.105-2.8c.198-.055.38-.16.525-.306l21.286-21.286A5.334 5.334 0 0 0 43 14.77a5.334 5.334 0 0 0-1.572-3.796l-1.4-1.4A5.335 5.335 0 0 0 36.23 8zM13.749 31.634l17.421-17.421 5.618 5.618-17.42 17.42-5.619-5.617zm-1.122 2.252l4.488 4.488-6.209 1.72 1.72-6.208zm27.988-19.117c0 .797-.31 1.546-.874 2.11l-1.265 1.265-5.619-5.619 1.266-1.265a2.963 2.963 0 0 1 2.109-.874c.796 0 1.546.31 2.109.874l1.4 1.4c.563.563.874 1.312.874 2.109z"
    />
  </svg>
)

export default SvgPencil
