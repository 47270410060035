import { QualityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testQuality'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { setEventCoDates, setEventCoDatesLoaded } from '.'
import { IBaseRootState } from '..'
import { getEventCo, getOpentokSettings, hasConferencesTimeline } from '../../api'
import { IPromiseReject, IPromiseResolve } from '../../entities/promise'
import IStorageAccess from '../../entities/storageAccess'
import settings from '../../settings'
import consoleUtils from '../../utils/consoleUtils'
import promiseUtils from '../../utils/promiseUtils'
import { StorageKeys } from '../app'
import { setConferencesTotal } from '../conference'
import { setExhibitorsTotal } from '../exhibitor'
import {
  setEventCo,
  setEventError,
  setEventLoading,
  setOpentokTestsError,
  setOpentokTestsLoading,
  setQualityTestResultsOpentok,
  setSettingsError,
  setSettingsLoading,
  setSettingsOpentok,
  setTestsOpentok
} from './actions'

export const loadOpentokSettings = () => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setSettingsLoading(true))
  try {
    const opentokSettings = await getOpentokSettings()
    dispatch(setSettingsOpentok(opentokSettings))
  } catch (e) {
    dispatch(setSettingsError(e))
    consoleUtils.error(e)
  }
  dispatch(setSettingsLoading(false))
}

export const loadOpentokTests = () => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setOpentokTestsLoading(true))
  try {
    const opentokSettings = await getOpentokSettings()
    dispatch(setTestsOpentok(opentokSettings))
  } catch (e) {
    dispatch(setOpentokTestsError(e))
    consoleUtils.error(e)
  }
  dispatch(setOpentokTestsLoading(false))
}

export const resolveEvent = () => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setEventLoading(true))
  try {
    const eventCo = await getEventCo()
    // uncomment next line to test appointment mode in local
    eventCo.eventcoAppointment = settings.prod
      ? eventCo.eventcoAppointment
      : settings.theme.appointment || false
    dispatch(setEventCo(eventCo))
    window.lightTheme = settings.prod
      ? eventCo?.eventcoRemote || eventCo?.eventcoAppointment
      : settings.theme.light
    dispatch(setConferencesTotal(eventCo.nbConferences))
    dispatch(setExhibitorsTotal(eventCo.nbExhibitors))
    dispatch(setEventLoading(false))
    return eventCo
  } catch (e) {
    dispatch(
      setEventError(
        `Problème lors du chargement des informations de l'event (groupId:${settings.eventGroupId})`
      )
    )
    consoleUtils.error(e)
    dispatch(setEventLoading(false))
    return e
  }
}

export const saveOpentokQualityTestResults = (
  storage: IStorageAccess,
  opentokQualityTestResults: QualityTestResults
) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
  try {
    await storage.setItem(
      StorageKeys.QUALITY_TEST_RESULTS,
      JSON.stringify(opentokQualityTestResults)
    )
    dispatch(setQualityTestResultsOpentok(opentokQualityTestResults))
  } catch (e) {
    dispatch(setOpentokTestsError(e))
    consoleUtils.error(e)
  }
}

export const resetOpentokQualityTestResults = (storage: IStorageAccess) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  try {
    await storage.removeItem(StorageKeys.QUALITY_TEST_RESULTS)
    dispatch(setQualityTestResultsOpentok(null))
  } catch (e) {
    dispatch(setOpentokTestsError(e))
    consoleUtils.error(e)
  }
}

export const checkEventCoDates = (eventCoDates: string[]) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  try {
    // get conferences timeline data
    const dates: string[] = []
    promiseUtils
      .waterfall(
        eventCoDates.map((date) => {
          return (resolve: IPromiseResolve, reject: IPromiseReject) => {
            hasConferencesTimeline(date)
              .then((hasConferences) => {
                if (hasConferences) {
                  dates.push(date)
                }
                resolve()
              })
              .catch((raison) => reject(raison))
          }
        })
      )
      .then(() => {
        dispatch(setEventCoDates(dates))
        dispatch(setEventCoDatesLoaded(true))
      })
  } catch (e) {
    dispatch(
      setEventError(
        `Problème lors du chargement des dates de l'event (groupId:${settings.eventGroupId})`
      )
    )
    consoleUtils.error(e)
  }
}