import styled from '@emotion/styled'
import { Button, FormControl, TextField } from '@material-ui/core'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { CirclePicker, ColorResult } from 'react-color'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContactUser, IRoom, RoomType } from '../../../entities'
import { useMagicStream, useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { updateRoom } from '../../../store/room'
import { PRIMARY_COLOR } from '../../../theme'
import AutocompleteVisitors from '../../contacts/AutocompleteVisitors'
import FormContainer from '../../ui/Form/FormContainer'
import { Label } from '../../ui/LabelWrapper/LabelWrapper'
import SelectRoomType from '../Room/SelectRoomType'

interface IRoomEditDialogContentProps {
  room: IRoom
  doSubmit?: boolean
  setDoSubmit?: (value: boolean) => void
  onSubmit?: () => void
}

const RoomEditDialogContent: React.FC<IRoomEditDialogContentProps> = ({
  room,
  doSubmit,
  setDoSubmit,
  onSubmit
}) => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const [isDirty, setDirty] = useState(false)
  const [title, setTitle] = useState(room.title)
  const [type, setType] = useState(room.type)
  const [sessionId, setSessionId] = useState(room.sessionId)
  const [user, setUser] = useState<IContactUser | undefined>(
    room.userPresence ? room.userPresence.user : undefined
  )
  const [color, setColor] = useState(room.color)
  const me = useSelector((state: IRootState) => state.appState.user)
  const dispatch = useThunkDispatch()

  const canEditRoomType = useMemo(
    () => settings.video.svgEditable && me && me.roles && me.roles.indexOf('edit_group_svg_*') > -1,
    [me]
  )

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTitle(event.target.value)
    setDirty(true)
  }

  const handleChangeType = (value: RoomType) => {
    setType(value)
    setDirty(true)
  }

  const handleChangeSessionId = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSessionId(event.target.value)
    setDirty(true)
  }

  const handleChangeUser = (newUser: IContactUser | undefined) => {
    setUser(newUser)
    setDirty(true)
  }

  const handleChangeColor = (newColor: ColorResult) => {
    setColor(newColor.hex)
    setDirty(true)
  }

  const handleResetColor = () => {
    setColor(undefined)
    setDirty(true)
  }

  const handleSubmit = useCallback(() => {
    if (isDirty) {
      if (title && type) {
        // call api to save form
        dispatch(
          updateRoom(
            room.id,
            {
              title,
              type,
              sessionId: sessionId || undefined,
              userId: (user && user.id) || undefined,
              color: color || undefined
            },
            // success
            () => {
              setDirty(false)
              magicStream.success(intl.formatMessage({ id: 'room.updated' }, { title: room.title }))
              onSubmit && onSubmit()
            },
            // error
            (error) => {
              magicStream.warning(error.message)
            }
          )
        )
      }
    } else {
      onSubmit && onSubmit()
    }
  }, [room, intl, isDirty, title, type, sessionId, user, color, magicStream, onSubmit, dispatch])

  useEffect(() => {
    if (doSubmit) {
      handleSubmit()
      setDoSubmit && setDoSubmit(false)
    }
  }, [doSubmit, setDoSubmit, handleSubmit])

  return (
    <StyledRoomEditDialogContent>
      <FormContainer>
        <FormControl>
          <TextField
            id="title"
            label={<FormattedMessage id="roomForm.title" />}
            value={title || ''}
            error={!title}
            onChange={handleChangeTitle}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: false, disableAnimation: true }}
          />
        </FormControl>
        <FormControl>
          <SelectRoomType roomType={type} disabled={!canEditRoomType} onChange={handleChangeType} />
        </FormControl>
        <FormControl>
          <TextField
            id="sessionId"
            label={<FormattedMessage id="roomForm.sessionId" />}
            placeholder={intl.formatMessage({ id: 'roomForm.sessionId.placeholder' })}
            value={sessionId}
            disabled={!canEditRoomType}
            onChange={handleChangeSessionId}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: false, disableAnimation: true }}
          />
        </FormControl>
        <FormControl>
          <AutocompleteVisitors contact={user} onChange={handleChangeUser} />
        </FormControl>
        <FormControl>
          <Label id="room-color">
            <FormattedMessage id="roomForm.color" />
          </Label>
          <CirclePicker color={color || PRIMARY_COLOR} onChange={handleChangeColor} />
          <Button onClick={handleResetColor} color="primary">
            <FormattedMessage id="roomForm.color.reset" />
          </Button>
        </FormControl>
      </FormContainer>
    </StyledRoomEditDialogContent>
  )
}

const StyledRoomEditDialogContent = styled.div`
  & {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    #room-color {
      margin-bottom: 10px;
    }
  }
`

export default RoomEditDialogContent
