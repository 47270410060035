import { Channel, PresenceChannel } from 'pusher-js'
import { AnyAction, Dispatch } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IBaseRootState } from '..'
import {
  deleteRoomQueue,
  enterRoom as enterRoomApi,
  enterRoomBroadcaster as enterRoomBroadcasterApi,
  enterRoomExternal as enterRoomExternalApi,
  getRoomQueue,
  lockUnlockRoom,
  renameRoom as renameRoomApi,
  updateRoom as updateRoomApi,
  canAccessRoom as canAccessRoomApi
} from '../../api'
import { IConferenceFull, IExhibitorFull, IRoom, IRoomUpdate, IUser } from '../../entities'
import { formatName } from '../../utils'
import consoleUtils from '../../utils/consoleUtils'
import pusherUtils from '../../utils/pusherUtils'
import { mergeConference, mergeConferenceRoom, setConferenceUpdating } from '../conference'
import { mergeExhibitor, mergeExhibitorRoom, setExhibitorUpdating } from '../exhibitor'
import { resetChairId, setChairId, setChairUid, setFullscreen, setStandRoomChannel } from '../stand'
import {
  removeRoomQueue,
  resetRoomToken,
  setErrorRemoveRoomQueue,
  setErrorRoomQueue,
  setExternalError,
  setExternalLoading,
  setLoadingRemoveRoomQueue,
  setLoadingRoomQueue,
  setOfficeRoomIndex,
  setOnOfficeRoom,
  setOpentokError,
  setRoomAccessDenied,
  setRoomError,
  setRoomNumber,
  setRoomQueue,
  setRoomQueued,
  setRoomToken,
  setRoomTokenLoading,
  setRoomUpdating,
  updateRoomLocked
} from './actions'

export const enterRoom =
  (
    num: number,
    roomId: number,
    opentokId: string,
    standChannel: Channel,
    userConnected: IUser,
    broadcaster: boolean | undefined
  ) =>
  async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setRoomTokenLoading(true))
    try {
      dispatch(setRoomNumber(num))
      const response = broadcaster
        ? await enterRoomBroadcasterApi(roomId)
        : await enterRoomApi(roomId)
      dispatch(setRoomToken(response.token))
      dispatch(setRoomTokenLoading(false))
      dispatch(setChairId(response.chair.id))
      dispatch(setChairUid(response.chair.uid))
      dispatch(setRoomQueued(response.isQueued))

      // subscribe to opentok room
      const channel = (await pusherUtils.subscribe(
        `presence-room-${opentokId}-${roomId}`
      )) as PresenceChannel
      dispatch(setStandRoomChannel(channel))

      pusherUtils.triggerClientLocation(standChannel, `client-location-${opentokId}`, {
        user: {
          id: userConnected.id,
          username: formatName(userConnected).full,
          firstname: userConnected.firstname,
          lastname: userConnected.lastname,
          avatarPath: userConnected.avatarPath,
          isOnline: true
        },
        roomId: `${roomId}`,
        chairId: response.chair ? response.chair.id : '-1',
        broadcaster: !!broadcaster
      })

      pusherUtils.subscribe(`private-chair-${response.chair.uid}`)
      pusherUtils.subscribe(`private-chair-${response.chair.uid}-user-${userConnected.id}`)
    } catch (e) {
      // TODO : trouver un moyen de récupérer le message de l'api
      dispatch(setOpentokError('Aucune chaise trouvée sur cette table'))
      consoleUtils.error(e)
    }
  }

export const enterRoomExternal =
  (num: number, roomId: number, opentokId: string, standChannel: Channel, userConnected: IUser) =>
  async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setExternalLoading(true))
    try {
      dispatch(setRoomNumber(num))
      const response = await enterRoomExternalApi(roomId)
      dispatch(setChairId(response.chair.id))
      dispatch(setChairUid(response.chair.uid))
      dispatch(setRoomQueued(response.isQueued))

      // subscribe to opentok room
      const channel = (await pusherUtils.subscribe(
        `presence-room-${opentokId}-${roomId}`
      )) as PresenceChannel
      dispatch(setStandRoomChannel(channel))

      pusherUtils.triggerClientLocation(standChannel, `client-location-${opentokId}`, {
        user: {
          id: userConnected.id,
          username: formatName(userConnected).full,
          firstname: userConnected.firstname,
          lastname: userConnected.lastname,
          avatarPath: userConnected.avatarPath,
          isOnline: true
        },
        roomId: `${roomId}`,
        chairId: response.chair ? response.chair.id : '-1',
        broadcaster: false
      })

      pusherUtils.subscribe(`private-chair-${response.chair.uid}`)
      pusherUtils.subscribe(`private-chair-${response.chair.uid}-user-${userConnected.id}`)
    } catch (e) {
      // TODO : trouver un moyen de récupérer le message de l'api
      dispatch(setExternalError('Aucune chaise trouvée sur cette table'))
      consoleUtils.error(e)
    }
    dispatch(setExternalLoading(false))
  }

export const leaveRoom =
  (
    roomId: number,
    chairUid: string,
    opentokId: string,
    standChannel: Channel,
    userConnected: IUser,
    isChangeRoom: boolean,
    leaveOffice: boolean
  ) =>
  async (dispatch: Dispatch<AnyAction>) => {
    if (leaveOffice) {
      dispatch(setOnOfficeRoom(false))
      dispatch(setOfficeRoomIndex(-1))
    }
    dispatch(setFullscreen(false))
    dispatch(setRoomNumber(-1))
    dispatch(resetRoomToken())
    dispatch(setRoomQueued(false))
    // leave room
    pusherUtils.unsubscribe(`presence-room-${opentokId}-${roomId}`)
    if (chairUid) {
      pusherUtils.unsubscribe(`private-chair-${chairUid}`)
      pusherUtils.unsubscribe(`private-chair-${chairUid}-user-${userConnected.id}`)
    }
    if (!isChangeRoom) {
      dispatch(resetChairId())
      // trigger client location
      pusherUtils.triggerClientLocation(standChannel, `client-location-${opentokId}`, {
        user: {
          id: userConnected.id,
          username: formatName(userConnected).full,
          firstname: userConnected.firstname,
          lastname: userConnected.lastname,
          avatarPath: userConnected.avatarPath,
          isOnline: true
        },
        roomId: '-1',
        chairId: '-1',
        broadcaster: false
      })
    }
  }

export const canAccessRoom =
  (room: IRoom, success?: () => void) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const hasAccess = await canAccessRoomApi(room.id)
      console.log(hasAccess)
      dispatch(setRoomAccessDenied(!hasAccess))
      success && success()
    } catch (e) {
      consoleUtils.error(e)
      dispatch(setRoomAccessDenied(true))
    }
  }

export const changeLockUnlockRoom =
  (roomId: number, locked: boolean) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setRoomUpdating(true))
    try {
      // lock / unlock room
      await lockUnlockRoom(roomId, locked)
      dispatch(updateRoomLocked(roomId, locked))
    } catch (e) {
      dispatch(setRoomError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setRoomUpdating(false))
  }

export const renameExhibitorRoom =
  (room: IRoom, name: string, exhibitor: IExhibitorFull) =>
  async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setExhibitorUpdating(true))
    dispatch(setRoomUpdating(true))
    try {
      // rename room
      const roomUpdated = await renameRoomApi(room.id, name)
      const rooms = exhibitor.rooms.map((r, i) => (r.id === roomUpdated.id ? roomUpdated : r))
      const exhibitorUpdated = { ...exhibitor, rooms }
      dispatch(mergeExhibitor(exhibitorUpdated))
    } catch (e) {
      dispatch(setRoomError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setRoomUpdating(false))
    dispatch(setExhibitorUpdating(false))
  }

export const renameConferenceRoom =
  (room: IRoom, name: string, conference: IConferenceFull) =>
  async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setConferenceUpdating(true))
    dispatch(setRoomUpdating(true))
    try {
      // rename room
      const roomUpdated = await renameRoomApi(room.id, name)
      const rooms = conference.rooms.map((r, i) => (r.id === roomUpdated.id ? roomUpdated : r))
      const conferenceUpdated = { ...conference, rooms }
      dispatch(mergeConference(conferenceUpdated))
    } catch (e) {
      dispatch(setRoomError((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setRoomUpdating(false))
    dispatch(setConferenceUpdating(false))
  }

export const loadRoomQueue = (roomId: number) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoadingRoomQueue(true))
  try {
    const roomQueue = await getRoomQueue(roomId)
    dispatch(setRoomQueue(roomQueue))
  } catch (e) {
    dispatch(setErrorRoomQueue((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingRoomQueue(false))
}

export const refuseRoomQueue = (roomQueueId: string) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoadingRemoveRoomQueue(true))
  try {
    const deleted = await deleteRoomQueue(roomQueueId)
    if (deleted) {
      dispatch(removeRoomQueue(roomQueueId))
    }
  } catch (e) {
    dispatch(setErrorRemoveRoomQueue((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingRemoveRoomQueue(false))
}

export const updateRoom =
  (roomId: number, roomUpdate: IRoomUpdate, success?: () => void, error?: (error: Error) => void) =>
  async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setRoomUpdating(true))
    try {
      const roomUpdated = await updateRoomApi(
        roomId,
        roomUpdate.title,
        roomUpdate.type,
        roomUpdate.sessionId,
        roomUpdate.userId,
        roomUpdate.color
      )
      dispatch(mergeExhibitorRoom(roomUpdated))
      dispatch(mergeConferenceRoom(roomUpdated))
      success && success()
    } catch (e) {
      consoleUtils.error(e)
      dispatch(setRoomUpdating(false))
      error && error(e)
    }
    dispatch(setRoomUpdating(false))
  }
