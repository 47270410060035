import { IHighlight } from '../entities/highlight'
import settings from '../settings'
import { apiRequest } from './requests'

export const getHighlight = async (type: string) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/highlight?eventGroupId=${settings.eventGroupId}&page=1&targetName=${type}`
  )
  return { items: response['hydra:member'] as IHighlight[], total: response['hydra:totalItems'] }
}
