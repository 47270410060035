import styled from '@emotion/styled'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import React from 'react'
import { IMagicStream, MagicStreamType } from '../../entities'
import { useDateFns } from '../../hooks'
import { GRAY, WHITE } from '../../theme/colors'

type IStreamToastProps = {
  stream: IMagicStream
}

const StreamToast: React.FC<IStreamToastProps> = ({ stream }) => {
  const dateFns = useDateFns()

  return (
    <StreamToastContainer>
      <StyledIcon className="icon-hover">
        {stream.streamType === MagicStreamType.TOAST_GLOBAL && (
          <InfoIcon fontSize="large" htmlColor="orange" />
        )}
        {stream.streamType === MagicStreamType.TOAST_INFO && (
          <InfoIcon fontSize="large" htmlColor="blue" />
        )}
        {stream.streamType === MagicStreamType.TOAST_SUCCESS && (
          <CheckCircleIcon fontSize="large" htmlColor="green" />
        )}
        {stream.streamType === MagicStreamType.TOAST_WARNING && (
          <WarningIcon fontSize="large" htmlColor="orange" />
        )}
        {stream.streamType === MagicStreamType.TOAST_ERROR && (
          <ErrorIcon fontSize="large" htmlColor="red" />
        )}
      </StyledIcon>
      <StyledContent>
        <StyledText>{stream.content}</StyledText>
        <StyledDate>{dateFns.formatDistanceToNow(stream.writeDate || stream.createDate)}</StyledDate>
      </StyledContent>
    </StreamToastContainer>
  )
}

const StreamToastContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  font-size: 12px;
  transition: all 0.3s;
  padding: 7px 0;

  &:hover {
    span {
      opacity: 1;
    }
    .icon-hover {
      background-color: ${WHITE};
    }
  }
`

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`

const StyledIcon = styled.div`
  background-color: ${WHITE};
  border-radius: 50%;
  padding: 5px;
`

const StyledText = styled.div``

const StyledDate = styled.span`
  position: absolute;
  right: 3px;
  bottom: 1px;
  opacity: 0;
  font-size: 10px;
  color: ${GRAY};
`

export default StreamToast
