import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import React, { useEffect, useMemo, useState } from 'react'
import { FullScreenHandle } from 'react-full-screen'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  IAudioLevelUpdatedEvent,
  IOTProperties,
  MediaDevice,
  MediaError,
  VideoResolution,
  VideoSourceType
} from '../../../entities'
import { useAudioDetection, useMagicStream, useThunkDispatch } from '../../../hooks'
import { IMediaStream } from '../../../media/IMediaStream'
import { StreamPublisher } from '../../../media/StreamPublisher'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { setVideoSource, updateDefaultSources, updateUser } from '../../../store/app'
import { setOpentokStreaming } from '../../../store/room'
import { PRIMARY_COLOR } from '../../../theme'
import { WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import asyncLocalStorage from '../../../utils/asyncLocalstorage'
import consoleUtils from '../../../utils/consoleUtils'
import Loading from '../../Loading'
import ConfirmDialog from '../../modals/ConfirmDialog'
import PublisherActions from './PublisherActions'
import TalkingBorder from './TalkingBorder'
import VideoContainer from './VideoContainer'

interface IProps {
  mediaStream?: IMediaStream
  publisherRef: React.RefObject<any>
  height?: number
  width?: number
  broadcaster?: boolean
  viewer?: boolean
  defaultDisplay?: boolean
  handleLeave?: () => void
  handleFullscreen?: FullScreenHandle
}

const Publisher: React.FC<IProps> = ({
  mediaStream,
  publisherRef,
  height,
  width,
  broadcaster,
  viewer,
  defaultDisplay,
  handleLeave,
  handleFullscreen
}) => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const defaultWidth = width || 200 * (16 / 9)
  const defaultHeight = height || 200
  // const screenShareRef = useRef<any>(null)
  const [error, setError] = useState<MediaError>()
  const [forceNoCamera, setForceNoCamera] = useState(false)
  const preferedVideoDisplay = useSelector(
    (state: IRootState) => state.appState.preferedVideoDisplay
  )
  const recommendedFrameRate = useSelector(
    (state: IRootState) => state.appState.recommendedFrameRate
  )
  const recommendedResolution = useSelector(
    (state: IRootState) => state.appState.recommendedResolution
  )
  const [confirmTakePhoto, setConfirmTakePhoto] = useState(false)
  const [photo, setPhoto] = useState('')
  const [publishVideo, setPublishVideo] = useState(viewer ? false : preferedVideoDisplay)
  const [publishAudio, setPublishAudio] = useState(viewer ? false : settings.opentok.autoStart)
  const [audioTrack, setAudioTrack] = useState<{
    withMic: MediaStreamTrack | undefined
    withoutMic: MediaStreamTrack | undefined
  }>({ withMic: undefined, withoutMic: undefined })
  const [videoTrack, setVideoTrack] = useState<any>()
  const [accessDialogOpen, setAccessDialogOpen] = useState(true)
  const [currentWidth, setCurrentWidth] = useState(defaultWidth)
  const [currentHeight, setCurrentHeight] = useState(defaultHeight)
  const user = useSelector((state: IRootState) => state.appState.user)
  const userUpdating = useSelector((state: IRootState) => state.appState.userUpdating)
  const videoInputDevices = useSelector((state: IRootState) => state.appState.videoInputDevices)
  const audioInputDevices = useSelector((state: IRootState) => state.appState.audioInputDevices)
  const defaultVideoSource = useSelector((state: IRootState) => state.appState.defaultVideoSource)
  const defaultAudioSource = useSelector((state: IRootState) => state.appState.defaultAudioSource)
  const defaultResolution = useSelector((state: IRootState) => state.appState.defaultResolution)
  const videoSource = useSelector((state: IRootState) => state.appState.videoSource)
  const audioDetection = useAudioDetection()
  const dispatch = useThunkDispatch()

  const publisherProperties: IOTProperties = useMemo(
    () => ({
      mirror: false,
      showControls: settings.opentok.audioDetection ? false : true,
      fitMode: 'contain',
      frameRate: recommendedFrameRate || 15,
      style: {
        audioLevelDisplayMode: 'auto'
      }
    }),
    [recommendedFrameRate]
  )

  const audioSourceMemo = useMemo(
    () =>
      viewer
        ? undefined
        : defaultAudioSource ||
        (audioInputDevices && audioInputDevices.length > 0
          ? audioInputDevices[0].deviceId
          : 'default'),
    [viewer, defaultAudioSource, audioInputDevices]
  )

  const videoSourceMemo = useMemo(
    () =>
      viewer
        ? undefined
        : forceNoCamera
          ? false
          : defaultVideoSource ||
          (videoInputDevices && videoInputDevices.length > 0
            ? videoInputDevices[0].deviceId
            : false),
    [viewer, defaultVideoSource, videoInputDevices, forceNoCamera]
  )

  const publisherEventHandlers = {
    audioLevelUpdated: viewer
      ? (event: IAudioLevelUpdatedEvent) => undefined
      : settings.opentok.audioDetection
        ? audioDetection.audioLevelUpdated
        : (event: IAudioLevelUpdatedEvent) => undefined,
    accessDenied: () => {
      dispatch(setVideoSource(VideoSourceType.CAMERA))
      setError(undefined)
      dispatch(setOpentokStreaming(true))
    },
    accessAllowed: () => {
      if (mediaStream) {
        // refresh devices list when access is allowed
        mediaStream.getDevices(
          (devices: MediaDevice[]) => {
            if (devices && devices.length > 0) {
              dispatch(updateDefaultSources(asyncLocalStorage, devices))
              setError(undefined)
            } else {
              setError({ name: 'No media devices', message: 'No media devices' })
            }
          },
          (err: MediaError) => {
            consoleUtils.error('ACCESS ALLOWED err=', err.message)
            // setError(err)
          }
        )
      }
    },
    accessDialogClosed: () => {
      setAccessDialogOpen(false)
    },
    streamCreated: (event: any) => {
      dispatch(setOpentokStreaming(true))
      if (
        !event.stream.hasAudio &&
        settings.opentok.autoStart &&
        event.stream.videoType !== VideoSourceType.SCREEN
      ) {
        // console.log('>>> streamCreated, hasAudio=', event.stream.hasAudio)
        setPublishAudio(false)
      }
    },
    streamDestroyed: (event: any) => {
      if (event.reason === 'mediaStopped' && videoSource === VideoSourceType.SCREEN) {
        // user cancel sharing screen with outside action
        dispatch(setVideoSource(VideoSourceType.CAMERA))
        dispatch(setOpentokStreaming(true))
      }
    },
    disconnected: () => {
      dispatch(setOpentokStreaming(false))
    }
  }

  const onInit = () => {
    consoleUtils.info('Init publisher Success')
  }

  const onPublish = () => {
    consoleUtils.info('Publish Success')
  }

  const onPublishError = (err: MediaError) => {
    consoleUtils.error('[Publish] err=', err)
    if (err.name === 'OT_CHROME_MICROPHONE_ACQUISITION_ERROR') {
      // Failed to acquire microphone. Please completely quit and restart your browser
      consoleUtils.warn(err.message)
      magicStream.warning(intl.formatMessage({ id: err.name }))
    } else if (err.name === 'OT_CONSTRAINTS_NOT_SATISFIED') {
      const supportedConstraints = navigator.mediaDevices.getSupportedConstraints()
      consoleUtils.warn('Failed to getUserMedia(), supportedConstraints=', supportedConstraints)
    } else if (
      err.name === 'OT_NOT_SUPPORTED' ||
      err.name === 'OT_HARDWARE_UNAVAILABLE' ||
      err.name === 'OT_UNABLE_TO_CAPTURE_MEDIA' ||
      err.name === 'OT_USER_MEDIA_ACCESS_DENIED'
    ) {
      // The selected voice or video devices are unavailable. Verify that the chosen devices are not in use by another application.
      consoleUtils.warn(err.message)
      magicStream.warning(intl.formatMessage({ id: err.name }))
      dispatch(setOpentokStreaming(true))
      setForceNoCamera(true)
    }
  }

  const handlePublishVideo = () => {
    setPublishVideo(!publishVideo)
  }

  const handlePublishAudio = () => {
    setPublishAudio(!publishAudio)
  }

  const handlePublishVideoSource = async () => {
    setError(undefined)
    if (videoSource === VideoSourceType.CAMERA) {
      // TODO: abstract screen sharing
      try {
        const mediaDevices = navigator.mediaDevices as any
        const stream = await mediaDevices.getDisplayMedia({ audio: true, video: true })
        const streamMic = await mediaDevices.getUserMedia({ audio: true, video: false })
        const context = new AudioContext()
        const destination = context.createMediaStreamDestination()
        try {
          const output = context.createMediaStreamSource(stream)
          output.connect(destination)
          consoleUtils.log('Audio source connected')
        } catch (e) {
          consoleUtils.log('No audio source')
        }
        try {
          const microphone = context.createMediaStreamSource(streamMic)
          microphone.connect(destination)
          consoleUtils.log('Mic source connected')
        } catch (e) {
          consoleUtils.log('No mic source')
        }
        const audioTracksWithMic = destination.stream.getAudioTracks()
        const audioTracksWithoutMic = stream.getAudioTracks()
        const videoTracks = stream.getVideoTracks()
        setAudioTrack({
          withMic: audioTracksWithMic.length ? audioTracksWithMic[0] : undefined,
          withoutMic: audioTracksWithoutMic.length ? audioTracksWithoutMic[0] : undefined
        })
        setVideoTrack(videoTracks.length ? videoTracks[0] : undefined)
        dispatch(setVideoSource(VideoSourceType.SCREEN))
      } catch (e) {
        consoleUtils.error('Screensharing with audio unavailable e=', e)
        magicStream.error(
          `Partage d'écran avec piste audio ne fonctionne pas, basculement sur le partage par défaut`
        )
        window.setTimeout(() => {
          // use screen sharing default from opentok
          setVideoTrack(VideoSourceType.SCREEN)
          dispatch(setVideoSource(VideoSourceType.SCREEN))
        }, 2500)
      }
    } else {
      setAudioTrack({ withMic: undefined, withoutMic: undefined })
      setVideoTrack(undefined)
      dispatch(setVideoSource(VideoSourceType.CAMERA))
    }
  }

  const takePhoto = () => {
    if (mediaStream) {
      setPhoto(mediaStream.takePhoto())
      setConfirmTakePhoto(true)
    }
  }

  const onConfirmTakePhoto = () => {
    if (user) {
      // call api to save form
      dispatch(updateUser(user, { avatar: photo }))
      setConfirmTakePhoto(false)
      magicStream.success(intl.formatMessage({ id: 'take.photo.success' }))
    }
  }

  useEffect(() => {
    if (error && error.message && dispatch) {
      magicStream.error(error.message)
      setError(undefined)
      dispatch(setOpentokStreaming(true))
    }
  }, [error, magicStream, dispatch])

  return (
    <VideoContainer
      defaultDisplay={defaultDisplay}
      defaultWidth={defaultWidth}
      defaultHeight={defaultHeight}
      setCurrentWidth={setCurrentWidth}
      setCurrentHeight={setCurrentHeight}
    >
      <StyledStreamPublisher
        mediaStream={mediaStream}
        properties={{
          ...publisherProperties,
          publishAudio:
            !!(videoSource === VideoSourceType.SCREEN && audioTrack) ||
            defaultDisplay ||
            broadcaster ||
            publishAudio,
          publishVideo:
            !!(videoSource === VideoSourceType.SCREEN && videoTrack) ||
            defaultDisplay ||
            broadcaster ||
            publishVideo,
          audioSource:
            videoSource === VideoSourceType.SCREEN && audioTrack
              ? audioTrack.withMic
              : audioSourceMemo,
          videoSource:
            videoSource === VideoSourceType.SCREEN && videoTrack ? videoTrack : videoSourceMemo,
          resolution:
            videoSource === VideoSourceType.SCREEN && videoTrack
              ? VideoResolution._480_P
              : viewer
                ? VideoResolution._180_P
                : defaultResolution || recommendedResolution || VideoResolution._360_P
        }}
        width={viewer ? 0 : currentWidth}
        height={viewer ? 0 : currentHeight}
        onInit={onInit}
        onPublish={onPublish}
        onError={onPublishError}
        eventHandlers={publisherEventHandlers}
        publisherRef={publisherRef}
        withoutPublish={defaultDisplay || viewer}
      />
      {/* {videoSource === VideoSourceType.SCREEN && videoTrack && (
        <StyledStreamPublisherPip
          mediaStream={mediaStream}
          properties={{
            ...publisherProperties,
            publishAudio,
            audioSource: audioTrack.withMic,
            // or "audioSource: publishAudio ? audioTrack.withMic : audioTrack.withoutMic," but need to refresh publisher to work
            videoSource: videoTrack,
            resolution: VideoResolution._480_P
          }}
          eventHandlers={publisherEventHandlers}
          publisherRef={screenShareRef}
          pip
        />
      )} */}
      {!defaultDisplay && settings.opentok.audioDetection && (
        <TalkingBorder talking={publishAudio && audioDetection.talking} hidden={!!viewer} />
      )}
      {!defaultDisplay && (
        <PublisherActions
          mediaStream={mediaStream}
          viewer={viewer}
          broadcaster={broadcaster}
          handlePublishVideo={handlePublishVideo}
          publishVideo={publishVideo}
          handlePublishAudio={handlePublishAudio}
          publishAudio={publishAudio}
          handlePublishVideoSource={handlePublishVideoSource}
          videoSource={videoSource}
          handleLeave={handleLeave}
          handleFullscreen={handleFullscreen}
        />
      )}
      {defaultDisplay && (
        <StyledButton
          variant="contained"
          color="primary"
          onClick={takePhoto}
          endIcon={<AddAPhotoIcon />}
        >
          <FormattedMessage id="take.photo" />
        </StyledButton>
      )}
      {defaultDisplay && accessDialogOpen && (
        <AccessDialogMessage>
          <FormattedMessage id="access.dialog.message" />
        </AccessDialogMessage>
      )}
      {defaultDisplay && (
        <ConfirmDialog
          open={confirmTakePhoto}
          message={intl.formatMessage({ id: 'take.photo.confirm' })}
          okEvent={onConfirmTakePhoto}
          disableOk={userUpdating}
          cancelEvent={() => setConfirmTakePhoto(false)}
        >
          <StyledImage alt="Moi" src={photo} />
          {userUpdating && <Loading />}
        </ConfirmDialog>
      )}
    </VideoContainer>
  )
}

const StyledStreamPublisher = styled(StreamPublisher) <{
  /*fullscreen: boolean*/
  height?: number
  width?: number
}>`
  display: flex;
  justify-content: center;

  & .OTPublisherContainer {
    z-index: ${/*(props) => (props.fullscreen ? 1200 : 0)*/ 0};
    min-height: ${(props) => props.height}px !important;
    min-width: 100% !important;
    height: ${(props) => props.height}px !important;
    width: 100% !important;
  }
`

// const StyledStreamPublisherPip = styled(StreamPublisher)<{
//   height?: number
//   width?: number
// }>`
//   display: flex;
//   justify-content: center;
//   position: absolute;
//   height: 25%;
//   width: 25%;
//   bottom: 0;
//   right: 0;

//   & .OTPublisherContainer {
//     z-index: 50;
//     min-height: 100% !important;
//     min-width: 100% !important;
//     height: 100% !important;
//     width: 100% !important;
//   }
// `

const AccessDialogMessage = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE};
  padding: 1em;
  font-size: 2em;
  margin: 1em;
  border-radius: ${BORDER_RADIUS_CARD};
  text-align: center;
`
const StyledButton = styled(Button)`
  width: 100%;
`

const StyledImage = styled.img`
  max-width: 300px;
`

export default Publisher
