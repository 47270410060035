import { Channel } from 'pusher-js'
import { IAutoEnterRoom, ILocationUser } from '../../entities'
import { ActionsTypes } from './model'

export const setStandChannel = (standChannel: Channel | null) => ({
  type: ActionsTypes.SET_STAND_CHANNEL,
  standChannel
})

export const setStandRoomChannel = (standRoomChannel: Channel) => ({
  type: ActionsTypes.SET_STAND_ROOM_CHANNEL,
  standRoomChannel
})

export const setAutoEnterRoom = (autoEnterRoom: IAutoEnterRoom) => ({
  type: ActionsTypes.SET_AUTO_ENTER_ROOM,
  autoEnterRoom
})

export const setChairId = (chairId: string) => ({
  type: ActionsTypes.SET_CHAIR_ID,
  chairId
})

export const setChairUid = (chairUid: string) => ({
  type: ActionsTypes.SET_CHAIR_UID,
  chairUid
})

export const resetChairId = () => ({
  type: ActionsTypes.RESET_CHAIR_ID
})

export const setLocations = (locations: ILocationUser[]) => ({
  type: ActionsTypes.SET_USERS_LOCATIONS,
  locations
})

export const addLocation = (location: ILocationUser) => ({
  type: ActionsTypes.ADD_USERS_LOCATIONS,
  location
})

export const removeLocation = (location: ILocationUser) => ({
  type: ActionsTypes.REMOVE_USERS_LOCATIONS,
  location
})

export const setFullscreen = (fullscreen: boolean) => ({
  type: ActionsTypes.SET_FULLSCREEN,
  fullscreen
})

export const setHandleFullscreenExit = (handleFullscreenExit: boolean) => ({
  type: ActionsTypes.SET_HANDLE_FULLSCREEN_EXIT,
  handleFullscreenExit
})

export const setAlarm = (alarm?: string) => ({
  type: ActionsTypes.SET_ALARM,
  alarm
})
