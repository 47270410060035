import React from 'react'

const SvgQuestion: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M25.0003071,35.2 C26.3257911,35.2 27.4003071,36.27448 27.4003071,37.6 C27.4003071,38.92546 26.3257911,40 25.0003071,40 C23.6748231,40 22.6003071,38.92546 22.6003071,37.6 C22.6003071,36.27448 23.6748231,35.2 25.0003071,35.2 Z M25.0003071,10 C29.9432415,10 34.0004077,14.084812 34.0004077,19 C34.0180095,23.396854 31.6964445,25.26691 29.8565571,27.00625 C28.9350009,27.87739 28.1146563,28.64503 27.5878071,29.36875 C27.0609579,30.09253 26.8003071,30.7105 26.8003071,31.6 C26.8137561,32.55094 25.9513647,33.4375 25.0003071,33.4375 C24.0492495,33.4375 23.1868551,32.55094 23.2003071,31.6 C23.2003071,29.87728 23.8498407,28.39252 24.6815571,27.25 C25.5132735,26.10748 26.4962607,25.23688 27.3815571,24.4 C29.1521499,22.72618 30.4217211,21.464848 30.4003071,19 C30.4003071,15.996346 28.0039659,13.6 25.0003071,13.6 C21.9966483,13.6 19.6003071,15.996346 19.6003071,19 C19.6137561,19.950964 18.7513647,20.8375 17.8003071,20.8375 C16.8492495,20.8375 15.9868551,19.950964 16.0001518,19 C16.0001518,14.050762 20.0510619,10 25.0003071,10 Z"
    />
  </svg>
)

export default SvgQuestion
