import React from 'react'

const SvgLogout: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M15.303 10.303v30.394h9.843c.634 0 1.152.518 1.152 1.152 0 .633-.518 1.151-1.152 1.151H14.151A1.155 1.155 0 0 1 13 41.849V9.15C13 8.518 13.518 8 14.151 8h10.995c.634 0 1.152.518 1.152 1.151 0 .634-.518 1.152-1.152 1.152h-9.843zM43.51 24.694l-6.275-6.332a1.113 1.113 0 0 0-1.612 0c-.46.46-.46 1.151 0 1.612l4.318 4.375H22.383c-.633 0-1.151.518-1.151 1.151s.518 1.151 1.151 1.151h17.558l-4.318 4.375c-.46.46-.46 1.21 0 1.612.23.23.518.346.806.346.288 0 .576-.116.806-.346l6.275-6.332a1.226 1.226 0 0 0 0-1.612z"
    />
  </svg>
)

export default SvgLogout
