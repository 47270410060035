import { PresenceChannel } from 'pusher-js'
import { IAutoEnterRoom, ILocationUser } from '../../entities'

export enum ActionsTypes {
  SET_STAND_CHANNEL = 'SET_STAND_CHANNEL',
  SET_STAND_ROOM_CHANNEL = 'SET_STAND_ROOM_CHANNEL',
  SET_AUTO_ENTER_ROOM = 'SET_AUTO_ENTER_ROOM',
  SET_USERS_LOCATIONS = 'SET_USERS_LOCATIONS',
  ADD_USERS_LOCATIONS = 'ADD_USERS_LOCATIONS',
  REMOVE_USERS_LOCATIONS = 'REMOVE_USERS_LOCATIONS',
  SET_CHAIR_ID = 'SET_CHAIR_ID',
  SET_CHAIR_UID = 'SET_CHAIR_UID',
  RESET_CHAIR_ID = 'RESET_CHAIR_ID',
  RESET_CHAIR_UID = 'RESET_CHAIR_UID',
  SET_FULLSCREEN = 'SET_FULLSCREEN',
  SET_HANDLE_FULLSCREEN_EXIT = 'SET_HANDLE_FULLSCREEN_EXIT',
  SET_ALARM = 'SET_ALARM'
}

export interface IStandState {
  standChannel: PresenceChannel | null
  standRoomChannel: PresenceChannel | null
  autoEnterRoom: IAutoEnterRoom
  chairId: string | null
  chairUid: string | null
  locations: ILocationUser[]
  fullscreen: boolean
  handleFullscreenExit: boolean
  alarm?: string
}
