import styled from '@emotion/styled'
import { alpha, IconButton } from '@material-ui/core'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FullScreenHandle } from 'react-full-screen'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgFullScreen } from '../../../assets/images/icons/expand.svg'
import {
  IAudioLevelUpdatedEvent,
  IOTProperties,
  IStream,
  MediaError
} from '../../../entities/mediaStream'
import { useAudioDetection, useThunkDispatch } from '../../../hooks'
import { IMediaStream } from '../../../media/IMediaStream'
import { StreamSubscriber } from '../../../media/StreamSubscriber'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { setFullscreenSubscriber } from '../../../store/room'
import { setFullscreen } from '../../../store/stand'
import { BLACK_BLUE, WHITE } from '../../../theme/colors'
import consoleUtils from '../../../utils/consoleUtils'
import ButtonExitFullScreen from './ButtonExitFullScreen'
import TalkingBorder from './TalkingBorder'
import VideoContainer from './VideoContainer'

interface IProps {
  mediaStream: IMediaStream
  stream: IStream
  height?: number
  width?: number
  hasMicro: { [key: string]: boolean }
  isChangeDetected: boolean
  handleFullscreen?: FullScreenHandle
  onConnected: () => void
  onDestroyed: () => void
}

const Subscriber: React.FC<IProps> = ({
  mediaStream,
  stream,
  height,
  width,
  hasMicro,
  isChangeDetected,
  handleFullscreen,
  onConnected,
  onDestroyed
}) => {
  const intl = useIntl()
  const subscriberRef = useRef<any>(null)
  const defaultWidth = width || 200 * (16 / 9)
  const defaultHeight = height || 200
  const [voiceActive, setVoiceActive] = useState(false)
  const [muted, setMuted] = useState(false)
  // const [error, setError] = useState<MediaError>()
  const [currentWidth, setCurrentWidth] = useState(defaultWidth)
  const [currentHeight, setCurrentHeight] = useState(defaultHeight)
  const [data, setData] = useState<{ username: string } | null>()
  const fullscreen = useSelector((state: IRootState) => state.standState.fullscreen)
  const fullscreenSubscriber = useSelector(
    (state: IRootState) => state.roomState.fullscreenSubscriber
  )
  const audioDetection = useAudioDetection()
  const dispatch = useThunkDispatch()

  const subscriberProperties: IOTProperties = {
    showControls: settings.opentok.audioDetection ? false : true,
    fitMode: 'contain',
    style: {
      audioLevelDisplayMode: 'auto',
      audioBlockedDisplayMode: 'auto'
      // https://tokbox.com/developer/guides/customize-ui/js/#audio-blocking-ui
    }
  }

  const subscriberEventHandlers = useMemo(
    () => ({
      audioLevelUpdated: settings.opentok.audioDetection
        ? audioDetection.audioLevelUpdated
        : (event: IAudioLevelUpdatedEvent) => undefined,
      streamDestroyed: ({ reason }: { reason: string }) => {
        consoleUtils.info(`Subscriber stream destroyed, reason: ${reason}`)
      },
      connected: () => {
        consoleUtils.info('Subscriber connected')
        if (stream) {
          setVoiceActive(stream.hasAudio)
        }
        onConnected()
      },
      disconnected: () => {
        consoleUtils.info('Subscriber disconnected')
      },
      destroyed: () => {
        consoleUtils.info('Subscriber destroyed')
        onDestroyed()
      }
    }),
    [stream, audioDetection, onConnected, onDestroyed]
  )

  const onSubscribe = () => {
    consoleUtils.info('Subscribe Success')
  }

  const onSubscribeError = (err: MediaError) => {
    consoleUtils.error('Subscriber err=', err.message)
    // setError(err)
  }

  const changeFullscreen = (value: string) => {
    dispatch(setFullscreenSubscriber(value))
    if (!fullscreen) {
      dispatch(setFullscreen(value !== ''))
      if (handleFullscreen) {
        if (value === '') {
          handleFullscreen.exit()
        } else {
          handleFullscreen.enter()
        }
      }
    }
  }

  const toggleMuteSubscriber = useCallback(() => {
    if (mediaStream) {
      mediaStream.subscribeToAudio(stream, muted)
      setMuted(!muted)
    }
  }, [mediaStream, stream, muted])

  useEffect(() => {
    if (stream && stream.connection.data) {
      // format : username=Laurent%20BRAULT
      setData({ username: decodeURI(stream?.connection.data.split('=')[1]) })
    }
  }, [stream])

  useEffect(() => {
    setVoiceActive(hasMicro[stream.id])
  }, [isChangeDetected, hasMicro, stream])

  return (
    <VideoContainer
      fullscreen={fullscreenSubscriber === stream!.id}
      defaultWidth={defaultWidth}
      defaultHeight={defaultHeight}
      setCurrentWidth={setCurrentWidth}
      setCurrentHeight={setCurrentHeight}
    >
      {fullscreenSubscriber === stream!.id && (
        <FullScreenToolbar>
          <ButtonExitFullScreen onClick={() => changeFullscreen('')} />
        </FullScreenToolbar>
      )}
      <StyledOTSubscriber
        subscriberRef={subscriberRef}
        mediaStream={mediaStream}
        stream={stream}
        properties={{
          ...subscriberProperties,
          width: currentWidth,
          height: currentHeight
        }}
        width={currentWidth}
        height={currentHeight}
        onSubscribe={onSubscribe}
        onError={onSubscribeError}
        retry={false}
        maxRetryAttempts={0}
        retryAttemptTimeout={2000}
        eventHandlers={subscriberEventHandlers}
        fullscreen={fullscreenSubscriber === stream!.id}
      />
      {settings.opentok.audioDetection && (
        <TalkingBorder
          talking={audioDetection.talking}
          fullscreen={fullscreenSubscriber === stream!.id}
        />
      )}
      <SubscriberActions>
        {data && stream && (
          <div>
            {voiceActive && (
              <MicIcon titleAccess={intl.formatMessage({ id: 'titleAccess.Subscriber.MicIcon' })} />
            )}
            {!voiceActive && (
              <MicOffIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.Subscriber.MicOffIcon' })}
              />
            )}
            <SubscriberName>{data.username}</SubscriberName>
          </div>
        )}
        <div className="actions-hover">
          {fullscreenSubscriber !== stream!.id && (
            <IconButton onClick={() => changeFullscreen(stream!.id)} title="Fullscreen">
              <SvgFullScreen />
            </IconButton>
          )}
          <IconButton onClick={() => toggleMuteSubscriber()} title={muted ? 'Unmute' : 'Mute'}>
            {muted && (
              <VolumeOffIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.Subscriber.VolumeOffIcon' })}
              />
            )}
            {!muted && (
              <VolumeUpIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.Subscriber.VolumeUpIcon' })}
              />
            )}
          </IconButton>
        </div>
      </SubscriberActions>
    </VideoContainer>
  )
}

const StyledOTSubscriber = styled(StreamSubscriber) <{
  fullscreen: boolean
  height?: number
  width?: number
}>`
  & .OTSubscriberContainer {
    z-index: ${(props) => (props.fullscreen ? 1200 : 0)};
    min-height: ${(props) => props.height}px !important;
    min-width: 100% !important;
    height: ${(props) => props.height}px !important;
    width: 100% !important;
  }
`
const FullScreenToolbar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 10002;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  background-color: ${alpha(BLACK_BLUE, 0.3)};
`

const SubscriberActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: ${alpha(BLACK_BLUE, 0.3)};
  color: ${WHITE};
  white-space: nowrap;
  z-index: 101;

  & .MuiIconButton-root {
    color: ${WHITE};
  }
`

const SubscriberName = styled.span`
  max-width: 70px;
  text-overflow: ellipsis;
`

export default Subscriber
