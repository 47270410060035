import { IExhibitorDemo } from '../entities/exhibitor'
import settings from '../settings'
import { apiRequest } from './requests'

export const getShowrooms = async () => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/demo/exhibitor?eventGroupId=${settings.eventGroupId}&limit=${settings.list.itemsPerPage}`
  )
  return {
    items: response['hydra:member'] as IExhibitorDemo[],
    total: response['hydra:totalItems']
  }
}
