import InlineEditor from '@ckeditor/ckeditor5-build-inline'
import '@ckeditor/ckeditor5-build-inline/build/translations/fr'
import CKEditor from '@ckeditor/ckeditor5-react'
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../redux'
import Editable from '../Editable'

interface IEditorProps {
  readonly?: boolean
  content?: string
  placeholder?: string
  startupFocus?: boolean
  onChange?: (value: string) => void
  onContentChange?: (value: string) => void
}

// Not fully implemented
interface IEditor {
  state: string
  locale: string
  editing: {
    view: {
      focus: () => void
    }
  }
  setData: (data: string | null | undefined) => never
  getData: () => string
  updateSourceElement: () => never
}

const Editor: React.FC<IEditorProps> = ({
  readonly,
  content,
  placeholder,
  startupFocus,
  onChange,
  onContentChange
}) => {
  const intl = useIntl()
  const [currentContent, setCurrentContent] = useState(content)
  const [editor, setEditor] = useState<IEditor | null>(null)
  const [editing, setEditing] = useState(false)
  const locale = useSelector((state: IRootState) => state.appState.locale)

  const editMessage = () => {
    setEditing(true)
    if (editor) {
      editor.editing.view.focus()
    }
  }

  const onBlur = () => {
    if (!readonly && content !== currentContent && onChange) {
      onChange(currentContent || '')
    }
    setEditing(false)
  }

  useEffect(() => {
    if (editor && editor.state !== null && editor.state === 'ready') {
      if (editor.getData() !== content) {
        editor.setData(content || '')
      }
    }
  }, [editor, content])

  useEffect(() => {
    // FIXME : semble ne pas fonctionner en prod
    // require(`@ckeditor/ckeditor5-build-inline/build/translations/${locale}`)
    if (editor) {
      editor.locale = locale
    }
  }, [locale, editor])

  return (
    <StyledEditor readonly={readonly}>
      <CKEditor
        config={{
          // ['undo', 'redo', 'bold', 'italic', 'blockQuote', 'ckfinder', 'imageTextAlternative', 'imageUpload', 'heading',
          // 'imageStyle:full', 'imageStyle:side', 'link', 'numberedList', 'bulletedList', 'mediaEmbed', 'insertTable',
          // 'tableColumn', 'tableRow', 'mergeTableCells']
          toolbar: ['heading', '|', 'bold', 'italic', '|', 'link'],
          link: {
            // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
            addTargetToExternalLinks: true
          },
          heading: {
            options: [
              {
                model: 'headingAlert',
                view: {
                  name: 'h1',
                  classes: 'alert'
                },
                title: intl.formatMessage({ id: 'editor.alert' }),
                class: 'ck-heading_heading1_alert',
                // It needs to be converted before the standard 'heading2'.
                converterPriority: 'high'
              },
              {
                model: 'heading1',
                view: 'h1',
                class: 'ck-heading_heading1',
                title: intl.formatMessage({ id: 'editor.heading1' })
              },
              {
                model: 'heading2',
                view: 'h2',
                class: 'ck-heading_heading2',
                title: intl.formatMessage({ id: 'editor.heading2' })
              },
              {
                model: 'paragraph',
                class: 'ck-heading_paragraph',
                title: intl.formatMessage({ id: 'editor.paragraph' })
              }
            ]
          },
          language: locale,
          placeholder
        }}
        editor={InlineEditor}
        disabled={readonly}
        onInit={(ckEditor: any) => {
          setEditor(ckEditor)
          if (startupFocus) {
            // ckEditor.editing.view.focus()
          }
        }}
        onChange={(
          event: any,
          ckEditor: { getData: () => string; editable: () => any; state: string }
        ) => {
          if (!readonly) {
            const data = ckEditor.getData()
            if (onContentChange) {
              onContentChange(data.replaceAll('...', '…')) // fix bug with ellipsis
            } else {
              setCurrentContent(data)
            }
          }
        }}
        onFocus={() => {
          // hack to set currentContent when click on a link, editor already focus in editMode
          const forms = document.getElementsByClassName('ck-link-form')
          if (!forms.length) {
            setCurrentContent(currentContent || content)
          }
          setEditing(true)
        }}
        onBlur={() => {
          // hack to not call onBlur when edit link
          const forms = document.getElementsByClassName('ck-link-form')
          if (!readonly && !forms.length) {
            onBlur()
          }
        }}
      />
      <Editable show={!readonly && !editing} onClick={editMessage} />
    </StyledEditor>
  )
}

const StyledEditor = styled.div<{ readonly?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props.readonly ? '' : '40px auto 20px')};
  width: 100%;

  & > .ck-content {
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.29;

    ul {
      margin-left: 0;
      padding-left: 15px;
    }
  }
  & > .ck-toolbar-container {
    z-index: 1500;
  }
`

export default Editor
