import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import React, { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { setErrorFollowers } from '../../store/participant'
import { loadFollowers } from '../../store/participant/thunks'
import Loading from '../Loading'
import FullScreenDialog from '../modals/FullScreenDialog'
import ContactItem from './ContactItem'

interface IFollowersDialogProps {
  objectId: number
  objectType: string
  open: boolean
  titleKey: string
  onClose: () => void
}

const FollowersDialog: FC<IFollowersDialogProps> = ({
  objectType,
  objectId,
  titleKey,
  open,
  onClose
}) => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const errorFollowers = useSelector((state: IRootState) => state.participantState.errorFollowers)
  const loadingFollowers = useSelector(
    (state: IRootState) => state.participantState.loadingFollowers
  )
  const followers = useSelector((state: IRootState) => state.participantState.followers)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    dispatch(loadFollowers(objectType, objectId))
  }, [objectType, objectId, dispatch])

  useEffect(() => {
    if (errorFollowers) {
      magicStream.error(errorFollowers)
      dispatch(setErrorFollowers(undefined))
    }
  }, [errorFollowers, magicStream, dispatch])

  return (
    <StyledFullScreenDialog
      title={intl.formatMessage({ id: titleKey })}
      open={open}
      fullScreen
      scroll={'body'}
      onClose={onClose}
    >
      {loadingFollowers && <Loading position="center" />}
      {!loadingFollowers && followers.length > 0 && (
        <Grid container spacing={2}>
          {followers.map((follower) => (
            <Grid key={follower.id} item xs={12} sm={6} md={4}>
              <ContactItem contact={follower} add={false} more={false} note={false} />
            </Grid>
          ))}
        </Grid>
      )}
    </StyledFullScreenDialog>
  )
}

const StyledFullScreenDialog = styled(FullScreenDialog)`
  & {
    .MuiDialogContent-root {
      padding: 15px;
    }
    .card-contact {
      height: 100%;

      .MuiButtonBase-root {
        height: 100%;
      }
    }
  }
`

export default FollowersDialog
