import { IRoom } from '../../entities'
import {
  IConference,
  IConferenceFull,
  IConferenceTimeline,
  IConferenceUpdate,
  IRelatedContent
} from '../../entities/conference'
import { IFilters } from '../../entities/filters'
import { ActionsTypes } from './model'

export const setConferencesTotal = (totalConferences?: number) => ({
  type: ActionsTypes.SET_CONFERENCES_TOTAL,
  totalConferences
})

export const setConferencesSearch = (searchConferences?: string | undefined) => ({
  type: ActionsTypes.SET_CONFERENCE_SEARCH,
  searchConferences
})

export const setConferenceLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_CONFERENCE_LOADING,
  loading
})

export const setConferenceEmbededLoading = (loadingEmbeded: boolean) => ({
  type: ActionsTypes.SET_CONFERENCE_EMBEDED_LOADING,
  loadingEmbeded
})

export const setVodsLoading = (loadingVods: boolean) => ({
  type: ActionsTypes.SET_VODS_LOADING,
  loadingVods
})

export const setVodsLoaded = (loadedVods: boolean) => ({
  type: ActionsTypes.SET_VODS_LOADED,
  loadedVods
})

export const setLivesLoading = (loadingLives: boolean) => ({
  type: ActionsTypes.SET_LIVES_LOADING,
  loadingLives
})

export const setLivesLoaded = (loadedLives: boolean) => ({
  type: ActionsTypes.SET_LIVES_LOADED,
  loadedLives
})

export const setConferenceUpdating = (updating: boolean) => ({
  type: ActionsTypes.SET_CONFERENCE_UPDATING,
  updating
})

export const setConferences = (conferences: { total: number; items: IConference[] }) => ({
  type: ActionsTypes.SET_CONFERENCES,
  conferences
})

export const setConferencesTimeline = (conferencesTimeline: {
  total: number
  items: IConferenceTimeline[]
}) => ({
  type: ActionsTypes.SET_CONFERENCES_TIMELINE,
  conferencesTimeline
})

export const setConferenceViewDisplayType = (conferenceViewDisplayType: string | null) => ({
  type: ActionsTypes.SET_CONFERENCE_VIEW_DISPLAY_TYPE,
  conferenceViewDisplayType
})

export const setConferenceViewDate = (conferenceViewDate: string | null) => ({
  type: ActionsTypes.SET_CONFERENCE_VIEW_DATE,
  conferenceViewDate
})

export const setVods = (vods: { total: number; items: IConference[] }) => ({
  type: ActionsTypes.SET_VODS,
  vods
})

export const setLives = (lives: { total: number; items: IConference[] }) => ({
  type: ActionsTypes.SET_LIVES,
  lives
})

export const setRecommendedConferenceLoading = (loadingRecommended: boolean) => ({
  type: ActionsTypes.SET_RECOMMENDED_CONFERENCE_LOADING,
  loadingRecommended
})

export const setRecommendedConferences = (recommendedConferences: IConference[]) => ({
  type: ActionsTypes.SET_RECOMMENDED_CONFERENCES,
  recommendedConferences
})

export const setConference = (conference: IConferenceFull) => ({
  type: ActionsTypes.SET_CONFERENCE,
  conference
})

export const setConferenceEmbeded = (conferenceEmbeded: IConferenceFull) => ({
  type: ActionsTypes.SET_CONFERENCE_EMBEDED,
  conferenceEmbeded
})

export const setEditMode = (editMode: boolean) => ({
  type: ActionsTypes.SET_CONFERENCE_EDIT_MODE,
  editMode
})

export const setConferenceError = (error?: string | undefined) => ({
  type: ActionsTypes.SET_CONFERENCE_ERROR,
  error
})

export const setConferenceEmbededError = (errorEmbeded?: string | undefined) => ({
  type: ActionsTypes.SET_CONFERENCE_EMBEDED_ERROR,
  errorEmbeded
})

export const setVodsError = (errorVods?: string | undefined) => ({
  type: ActionsTypes.SET_VODS_ERROR,
  errorVods
})

export const setLivesError = (errorLives?: string | undefined) => ({
  type: ActionsTypes.SET_LIVES_ERROR,
  errorLives
})

export const mergeConference = (conferenceUpdate: IConferenceUpdate) => ({
  type: ActionsTypes.MERGE_CONFERENCE,
  conferenceUpdate
})

export const mergeConferenceRoom = (roomUpdated: IRoom) => ({
  type: ActionsTypes.MERGE_CONFERENCE_ROOM,
  roomUpdated
})

export const resetConference = () => ({
  type: ActionsTypes.RESET_CONFERENCE
})

export const setConferenceFilters = (filters: IFilters) => ({
  type: ActionsTypes.SET_CONFERENCE_FILTERS,
  filters
})

export const setErrorConferenceFilters = (errorFilters?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_CONFERENCE_FILTERS,
  errorFilters
})

export const setLoadingConferenceFilters = (loadingFilters: boolean) => ({
  type: ActionsTypes.SET_LOADING_CONFERENCE_FILTERS,
  loadingFilters
})

export const updateConferencesFavorite = (conferenceId: number, isFollowed: boolean) => ({
  type: ActionsTypes.UPDATE_CONFERENCES_FAVORITE,
  conferenceId,
  isFollowed
})

export const setAddingConferenceToFavorites = (
  conferenceId: number,
  addingConferenceToFavorites: boolean
) => ({
  type: ActionsTypes.SET_ADDING_CONFERENCE_TO_FAVORITES,
  conferenceId,
  addingConferenceToFavorites
})

export const setErrorAddingConferenceToFavorites = (
  errorAddingConferenceToFavorites?: string | undefined
) => ({
  type: ActionsTypes.SET_ERROR_ADDING_CONFERENCE_TO_FAVORITES,
  errorAddingConferenceToFavorites
})

export const setRemovingConferenceFromFavorites = (
  conferenceId: number,
  removingConferenceFromFavorites: boolean
) => ({
  type: ActionsTypes.SET_REMOVING_CONFERENCE_FROM_FAVORITES,
  conferenceId,
  removingConferenceFromFavorites
})

export const setErrorRemovingConferenceFromFavorites = (
  errorRemovingConferenceFromFavorites?: string | undefined
) => ({
  type: ActionsTypes.SET_ERROR_REMOVING_CONFERENCE_FROM_FAVORITES,
  errorRemovingConferenceFromFavorites
})

export const updateConferenceRoomUsersOnline = (userIds: number[], isOnline: boolean) => ({
  type: ActionsTypes.UPDATE_CONFERENCE_ROOM_USERS_ONLINE,
  userIds,
  isOnline
})

export const setOpenExhibitorsChoice = (openExhibitorsChoice: {
  open: boolean,
  autoRedirect: boolean
}) => ({
  type: ActionsTypes.SET_OPEN_EXHIBITORS_CHOICE,
  openExhibitorsChoice
})

export const setSendingNotificationShowRelated = (sendingNotificationShowRelated: boolean) => ({
  type: ActionsTypes.SET_SENDING_NOTIFICATION_SHOW_RELATED,
  sendingNotificationShowRelated
})
export const setRelatedContent = (relatedContent: IRelatedContent) => ({
  type: ActionsTypes.SET_RELATED_CONTENT,
  relatedContent
})