import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import InputIcon from '@material-ui/icons/Input'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContactUser } from '../../entities'
import { IProduct } from '../../entities/product'
import { useDetectImageExist, useMagicStream, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setPageReferral } from '../../store/gtm'
import { removeProduct } from '../../store/product'
import { PRIMARY_COLOR } from '../../theme'
import { GRAY_ICON, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import { breakpoints } from '../breakpoints'
import ExhibitorProductForm from '../exhibitor/ExhibitorProductForm'
import ExhibitorTakeAppointment from '../exhibitor/ExhibitorTakeAppointment'
import Loading from '../Loading'

interface IProductCardProps {
  product: IProduct
  moderators?: IContactUser[]
  showImage?: boolean
  showTags?: boolean
  hasLinkToExhibitor?: boolean
  editable?: boolean
  lightMode?: boolean
}

const ProductCard: React.FC<IProductCardProps> = ({
  product,
  moderators,
  showImage,
  showTags,
  hasLinkToExhibitor,
  editable,
  lightMode
}) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [openModalDelete, setOpenModalDelete] = React.useState(false)
  const [openModalEdit, setOpenModalEdit] = React.useState(false)
  const deleting = useSelector((state: IRootState) => state.productState.deleting)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const imageExhibitor = useDetectImageExist(product.businessEventExhibitor?.imageUrl, false, '&size=small')
  const image = useDetectImageExist(product.imageResource?.sizes.medium)
  const dispatch = useThunkDispatch()

  const handleOpenModalDelete = () => {
    setOpenModalDelete(true)
  }

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false)
  }

  const handleOpenModalEdit = () => {
    setOpenModalEdit(true)
  }

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false)
  }

  const handleClickExhibitor = () => {
    if (product.businessEventExhibitor?.id) {
      dispatch(setPageReferral(`${pageReferral}::card-product`))
      sendDataToGTM({ event: 'click-card', cardType: 'Produit', cardName: `${product.name}` })
      browserHistory.push(`/exposant/${product.businessEventExhibitor.id}`)
    }
  }

  const handleSubmitDeleteProduct = () => {
    if (product) {
      dispatch(
        removeProduct(
          product.id,
          // success
          () => {
            magicStream.success(
              intl.formatMessage(
                { id: 'product.form.messageSuccessProductRemoving' },
                { productName: `<b>${product.name}</b>` }
              )
            )
          },
          // error
          (error) => {
            magicStream.error(error.message)
          }
        )
      )
      setOpenModalDelete(false)
    }
  }

  return (
    <StyledCard elevation={1}>
      <StyledCardLayout lightmode={!!lightMode}>
        {showImage && (
          <StyledCardActionArea>
            <StyledCardMedia image={image || imageExhibitor} title={product.body} />
          </StyledCardActionArea>
        )}
        <StyledCardContent>
          <div>
            <StyledTitle>{product.name}</StyledTitle>
            {showTags && product.productCategory && (
              <StyledTags>
                <StyledTag>{product.productCategory.name}</StyledTag>
              </StyledTags>
            )}
            <StyledDescription>{product.body}</StyledDescription>
          </div>
        </StyledCardContent>
        {editable ? (
          <StyledCardButtons>
            {deleting[product.id] ? <Loading /> :
              <>
                <StyledIconButton
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  onClick={handleOpenModalDelete}
                  color="inherit"
                  aria-label={intl.formatMessage({ id: 'ariaLabel.ProductCard.DeleteIcon' })}
                  disabled={deleting[product.id]}
                >
                  <DeleteIcon
                    fontSize="large"
                    titleAccess={intl.formatMessage({ id: 'titleAccess.ProductCard.DeleteIcon' })}
                  />
                </StyledIconButton>
                <IconButton
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  onClick={handleOpenModalEdit}
                  disabled={deleting[product.id]}
                >
                  <EditIcon
                    titleAccess={intl.formatMessage({ id: 'titleAccess.EditProductsButton.EditIcon' })}
                  />
                </IconButton>
              </>
            }
            <Dialog
              open={openModalDelete}
              onClose={handleCloseModalDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <FormattedMessage id="product.card.modal.deleteTitle" />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <FormattedMessage id="product.card.modal.deleteContent" />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModalDelete} color="default">
                  <FormattedMessage id="product.card.modal.buttonCancel" />
                </Button>
                <Button onClick={handleSubmitDeleteProduct} color="primary" autoFocus>
                  <FormattedMessage id="product.card.modal.buttonDelete" />
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openModalEdit}
              onClose={handleCloseModalEdit}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <FormattedMessage
                  id="product.card.modal.editTitle"
                  values={{ productName: product.name }}
                />
              </DialogTitle>
              <DialogContent>
                <ExhibitorProductForm product={product} onSubmit={handleCloseModalEdit} />
              </DialogContent>
            </Dialog>
          </StyledCardButtons>
        ) : (
          <StyledCardButtons>
            <ExhibitorTakeAppointment
              exhibitorId={
                product.businessEventExhibitor ? product.businessEventExhibitor.id : undefined
              }
              moderators={moderators}
              iconButton
              title={intl.formatMessage(
                { id: 'product.appointmentDialog.title' },
                { product: `<b>${product.name}</b>` }
              )}
              disabled={!moderators || moderators.length === 0}
              onlyMessage
              appendMessageText={product.name}
            />
            {hasLinkToExhibitor && (
              <StyledIconButton
                aria-controls="fade-menu"
                aria-haspopup="true"
                onClick={handleClickExhibitor}
                color="inherit"
                aria-label={intl.formatMessage({ id: 'ariaLabel.ProductCard.MoreVertIcon' })}
              >
                <InputIcon
                  fontSize="large"
                  titleAccess={intl.formatMessage({ id: 'titleAccess.ProductCard.MoreVertIcon' })}
                />
              </StyledIconButton>
            )}
          </StyledCardButtons>
        )}
      </StyledCardLayout>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  & {
    width: 100%;
    margin-right: 8px;
    margin-bottom: 20px;
  }
`

const StyledCardLayout = styled.div<{ lightmode: boolean }>`
  & {
    display: flex;
    justify-content: center;
    align-items: center;

    ${props => props.lightmode ? '' : `flex-direction: column;`}
    ${props => props.lightmode ? `@media (max-width: ${breakpoints.sm}) { 
      flex-direction: column;
    }` : `@media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.xl}) {
      flex-direction: row;
    }` }
  }
`

const StyledCardActionArea = styled(CardActionArea)`
  & {
    position: relative;
    flex: 0 0 150px;
    max-width: 150px;
    padding: 0 10px;
  }
`

const StyledCardMedia = styled(CardMedia)`
  & {
    height: 130px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
  }
`

const StyledCardContent = styled(CardContent)`
  & {
    flex-grow: 1;
    position: relative;
    padding: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }
`

const StyledTitle = styled.h3`
  & {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    max-width: calc(100% - 30px);
  }
`

const StyledTags = styled.div`
  & {
    margin-bottom: 5px;
  }
`
const StyledTag = styled.span`
  & {
    display: inline-block;
    padding: 3px 5px;
    color: ${WHITE};
    background-color: ${PRIMARY_COLOR};
    border-radius: ${BORDER_RADIUS_CARD};
    margin-bottom: 5px;

    + span {
      margin-left: 10px;
    }
  }
`

const StyledDescription = styled.div`
  & {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
  }
`

const StyledCardButtons = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & svg {
      color: ${GRAY_ICON};
    }
  }
`

const StyledIconButton = styled(IconButton)`
  & {
    min-width: auto;
  }
`

export default ProductCard
