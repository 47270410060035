import styled from '@emotion/styled'
import React from 'react'

export enum IconList {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowLongLeft = 'ArrowLongLeft',
  ArrowLongRight = 'ArrowLongRight',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Calendar = 'Calendar',
  Check = 'Check',
  CheckCircle = 'CheckCircle',
  Cross = 'Cross',
  FavoriteEmpty = 'FavoriteEmpty',
  FavoriteFull = 'FavoriteFull',
  Help = 'Help',
  House = 'House',
  Lock = 'Lock',
  Logout = 'Logout',
  Menu = 'Menu',
  Minus = 'Minus',
  Option = 'Option',
  Pencil = 'Pencil',
  Pin = 'Pin',
  Plus = 'Plus',
  Profile = 'Profile',
  Question = 'Question',
  Settings = 'Settings',
  Share = 'Share',
  Trash = 'Trash',
  Unlock = 'Unlock',
  Warning = 'Warning',
  Wait = 'Wait'
}

const Components = Object.values(IconList).map((i) => {
  return require(`./icons/${i}`).default
})

interface IOwnProps {
  icon: IconList
  className?: string
}

type Props = IOwnProps & React.SVGProps<SVGSVGElement>

const Icon: React.FC<Props> = ({ icon, className, ...restProps }) => {
  const Element = Components[Object.values(IconList).indexOf(icon)]

  return <Element className={className} {...restProps} />
}

export default styled(Icon)``
