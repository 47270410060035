import styled from '@emotion/styled'
import { alpha, Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { IRoom } from '../../../entities'
import { BLACK_BLUE, WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import { formatName } from '../../../utils'
import UserAvatar from '../UserAvatar/UserAvatar'

interface IRoomContentEditModeProps {
  room: IRoom
  divRef: React.RefObject<HTMLDivElement>
  setRoomToEdit: (room: IRoom | null) => void
}

const RoomContentEditMode: React.FC<IRoomContentEditModeProps> = ({
  room,
  divRef,
  setRoomToEdit
}) => {
  const intl = useIntl()
  const [isSmall, setSmall] = useState(false)

  useEffect(() => {
    if (divRef) {
      const target = divRef.current as any
      if (target) {
        setSmall(target.offsetHeight * target.offsetWidth < 120 * 120)
      }
    }
  }, [room, divRef])

  return (
    <StyledRoomContent>
      <StyledRoomTitle isSmall={isSmall} className="room-title">
        {room.userPresence
          ? formatName(room.userPresence.user).limited
          : room.title || room.roomClassName}
      </StyledRoomTitle>
      <StyledButtonEdit
        className="room-button-enter"
        size="small"
        variant="contained"
        startIcon={<EditIcon />}
        color="primary"
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.preventDefault()
          event.stopPropagation()
          setRoomToEdit(room)
        }}
      >
        {!isSmall &&
          intl.formatMessage({
            id: 'room.edit'
          })}
      </StyledButtonEdit>
      {room.userPresence && (
        <UserAvatar
          user={room.userPresence.user}
          showDetails
          showModerator
          showMe
          showNote
          disabledAnimation
          isSmall={isSmall}
        />
      )}
    </StyledRoomContent>
  )
}

const StyledRoomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledRoomTitle = styled.span<{ isSmall: boolean }>`
  & {
    font-size: ${(props) => (props.isSmall ? '10px' : '16px')};
    text-shadow: ${(props) => (props.isSmall ? 'none' : `2px 2px 3px ${alpha(BLACK_BLUE, 0.14)}`)};
    font-weight: bold;
    background-color: rgba(244, 247, 252, 0.3);
    padding: 0 4px;
    border-radius: ${BORDER_RADIUS_CARD};
    white-space: nowrap;
  }
`

const StyledButtonEdit = styled(Button)`
  & {
    svg {
      color: ${WHITE};
      width: 20px;
      height: 20px;
    }
  }
`

export default RoomContentEditMode
