import styled from '@emotion/styled'
import { Button, Link } from '@material-ui/core'
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined'
import React, { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { setExtendedPanel } from '../../store/app'
import { setOpenNotifications } from '../../store/chat'
import { WHITE } from '../../theme/colors'
import StreamsPanelContent from '../magicstream/StreamsPanelContent'
import CustomDialog from './CustomDialog'

const NotificationsDialog: FC = () => {
  const intl = useIntl()
  const openNotifications = useSelector((state: IRootState) => state.chatState.openNotifications)
  const dispatch = useThunkDispatch()

  const onClose = () => {
    dispatch(setOpenNotifications(false))
  }

  const seeAll = () => {
    dispatch(setOpenNotifications(false))
    dispatch(setExtendedPanel('magic.streams', true))
  }

  return (
    <CustomDialog
      open={openNotifications}
      title={intl.formatMessage({ id: 'magic.streams' })}
      icon={<NotificationImportantOutlinedIcon />}
      backdropClickDisabled
      slideDirection="left"
      customCss={`
        position: fixed;
        top: 90px;
        right: 15px;
        width: 450px;
        height: 350px;

        @media (max-width: 450px) {
          width: calc(100% - 30px);
        }
      `}
      footer={
        <StyledFooter>
          <Button component={Link} color="inherit" size="small" onClick={seeAll}>
            <FormattedMessage id="see.all.notifications" />
          </Button>
        </StyledFooter>
      }
      onClose={onClose}>
      <StreamsPanelContent
        priorityHigh
        onlyAppointment={false}
        streamType={'all'}
        onlyLast
      />
    </CustomDialog>
  )
}

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${WHITE};
`

export default NotificationsDialog
