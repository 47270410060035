import styled from '@emotion/styled'
import React, { FC } from 'react'
import { ReactComponent as VisitorIcon } from '../../../assets/images/icons/participants.svg'
import { PRIMARY, SECONDARY, WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'

interface IProps {
  contentText: string
  display: boolean
  relative?: boolean
  color?: 'primary' | 'secondary'
}

const LiveCounterSvg: FC<IProps> = ({ contentText, display, relative, color }) => {
  if (!display) return null
  return (
    <StyledLiveCounterSvg relative={relative} color={color}>
      <StyledText>{contentText}</StyledText>
      <VisitorIcon />
    </StyledLiveCounterSvg>
  )
}

const StyledLiveCounterSvg = styled.span<{ relative?: boolean; color?: 'primary' | 'secondary' }>`
  & {
    position: ${(props) => (props.relative ? 'relative' : 'absolute')};
    top: ${(props) => (props.relative ? '0' : '12px')};
    right: ${(props) => (props.relative ? '0' : '12px')};
    margin-left: ${(props) => (props.relative ? '10px' : '0')};
    padding-left: 10px;
    padding-right: 5px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: right;
    border-radius: ${BORDER_RADIUS_CARD};
    background-color: ${(props) =>
    props.color === 'primary'
      ? `${PRIMARY}`
      : props.color === 'secondary'
        ? `${SECONDARY}`
        : `${PRIMARY}`};

    span {
      color: ${WHITE};
      font-size: 12px;
      line-height: 14px;
    }
    svg {
      height: 18px;
      color: ${WHITE};
    }
  }
`

const StyledText = styled.span`
  white-space: nowrap;
`

export default LiveCounterSvg
