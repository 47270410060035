import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IRoomState } from './model'

const initialState: IRoomState = {
  opentokRecommended: null,
  roomNumber: -1,
  roomToken: null,
  loadingRoomToken: false,
  loadingExternal: false,
  streamingActive: false,
  broadcaster: false,
  fullscreenSubscriber: '',
  screenSharingAvailable: false,
  roomsLocked: {},
  roomUpdating: false,
  loadingRoomQueue: false,
  loadingRemoveRoomQueue: false,
  roomQueue: undefined,
  roomQueued: false,
  startOfficeCall: false,
  onOfficeRoom: false,
  officeRoomIndex: -1,
  roomAccessDenied: false
}

const actionHandlers: IReducerHandler<IRoomState> = {
  [ActionsTypes.SET_OPENTOK_ERROR]: (
    state: IRoomState | undefined,
    { error }: AnyAction
  ): IRoomState => ({
    ...state!,
    error
  }),
  [ActionsTypes.SET_OPENTOK_STREAMING]: (
    state: IRoomState | undefined,
    { streamingActive }: AnyAction
  ): IRoomState => ({
    ...state!,
    streamingActive
  }),
  [ActionsTypes.SET_OPENTOK_BROADCASTER]: (
    state: IRoomState | undefined,
    { broadcaster }: AnyAction
  ): IRoomState => ({
    ...state!,
    broadcaster
  }),
  [ActionsTypes.SET_OPENTOK_RECOMMENDED]: (
    state: IRoomState | undefined,
    { opentokRecommended }: AnyAction
  ): IRoomState => ({
    ...state!,
    opentokRecommended
  }),
  [ActionsTypes.SET_ROOM_TOKEN_LOADING]: (
    state: IRoomState | undefined,
    { loadingRoomToken }: AnyAction
  ): IRoomState => ({
    ...state!,
    loadingRoomToken
  }),
  [ActionsTypes.SET_ROOM_NUMBER]: (
    state: IRoomState | undefined,
    { roomNumber }: AnyAction
  ): IRoomState => ({
    ...state!,
    roomNumber
  }),
  [ActionsTypes.SET_ROOM_TOKEN]: (
    state: IRoomState | undefined,
    { roomToken }: AnyAction
  ): IRoomState => ({
    ...state!,
    roomToken
  }),
  [ActionsTypes.RESET_ROOM_TOKEN]: (state: IRoomState | undefined): IRoomState => ({
    ...state!,
    roomToken: null
  }),
  [ActionsTypes.SET_FULLSCREEN_SUBSCRIBER]: (
    state: IRoomState | undefined,
    { fullscreenSubscriber }: AnyAction
  ): IRoomState => ({
    ...state!,
    fullscreenSubscriber
  }),
  [ActionsTypes.SET_SCREEN_SHARING_AVAILABLE]: (
    state: IRoomState | undefined,
    { screenSharingAvailable }: AnyAction
  ): IRoomState => ({
    ...state!,
    screenSharingAvailable
  }),
  [ActionsTypes.UPDATE_ROOM_LOCKED]: (
    state: IRoomState | undefined,
    { roomId, roomLocked }: AnyAction
  ): IRoomState => ({
    ...state!,
    roomsLocked:
      state && state.roomsLocked
        ? {
            ...state.roomsLocked,
            [roomId]: roomLocked
          }
        : {}
  }),
  [ActionsTypes.SET_ROOM_UPDATING]: (
    state: IRoomState | undefined,
    { roomUpdating }: AnyAction
  ): IRoomState => ({
    ...state!,
    roomUpdating
  }),
  [ActionsTypes.SET_ROOM_ERROR]: (
    state: IRoomState | undefined,
    { roomError }: AnyAction
  ): IRoomState => ({
    ...state!,
    roomError
  }),
  [ActionsTypes.SET_ROOM_QUEUE_LOADING]: (
    state: IRoomState | undefined,
    { loadingRoomQueue }: AnyAction
  ): IRoomState => ({
    ...state!,
    loadingRoomQueue
  }),
  [ActionsTypes.SET_ROOM_QUEUE_ERROR]: (
    state: IRoomState | undefined,
    { errorRoomQueue }: AnyAction
  ): IRoomState => ({
    ...state!,
    errorRoomQueue
  }),
  [ActionsTypes.REMOVE_ROOM_QUEUE_LOADING]: (
    state: IRoomState | undefined,
    { loadingRemoveRoomQueue }: AnyAction
  ): IRoomState => ({
    ...state!,
    loadingRemoveRoomQueue
  }),
  [ActionsTypes.REMOVE_ROOM_QUEUE_ERROR]: (
    state: IRoomState | undefined,
    { errorRemoveRoomQueue }: AnyAction
  ): IRoomState => ({
    ...state!,
    errorRemoveRoomQueue
  }),
  [ActionsTypes.SET_ROOM_QUEUE]: (
    state: IRoomState | undefined,
    { roomQueue }: AnyAction
  ): IRoomState => ({
    ...state!,
    roomQueue
  }),
  [ActionsTypes.ADD_ROOM_QUEUE]: (
    state: IRoomState | undefined,
    { roomQueueId, queue }: AnyAction
  ): IRoomState => ({
    ...state!,
    roomQueue:
      state && state.roomQueue
        ? {
            id: queue.room_id,
            items: [
              {
                id: roomQueueId,
                date: new Date().toISOString(),
                user: queue.user
              },
              ...state.roomQueue.items.filter((item) => item.user.id !== queue.user.id)
            ]
          }
        : {
            id: queue.room_id,
            items: [
              {
                id: roomQueueId,
                date: new Date().toISOString(),
                user: queue.user
              }
            ]
          }
  }),
  [ActionsTypes.REMOVE_ROOM_QUEUE]: (
    state: IRoomState | undefined,
    { roomQueueId }: AnyAction
  ): IRoomState => ({
    ...state!,
    roomQueue:
      state && state.roomQueue
        ? {
            id: state.roomQueue.id,
            items: [...state.roomQueue.items.filter((item) => item.id !== roomQueueId)]
          }
        : {
            id: '0',
            items: []
          }
  }),
  [ActionsTypes.SET_ROOM_QUEUED]: (
    state: IRoomState | undefined,
    { roomQueued }: AnyAction
  ): IRoomState => ({
    ...state!,
    roomQueued
  }),
  [ActionsTypes.SET_EXTERNAL_ERROR]: (
    state: IRoomState | undefined,
    { errorExternal }: AnyAction
  ): IRoomState => ({
    ...state!,
    errorExternal
  }),
  [ActionsTypes.SET_EXTERNAL_LOADING]: (
    state: IRoomState | undefined,
    { loadingExternal }: AnyAction
  ): IRoomState => ({
    ...state!,
    loadingExternal
  }),
  [ActionsTypes.SET_START_OFFICE_CALL]: (
    state: IRoomState | undefined,
    { startOfficeCall }: AnyAction
  ): IRoomState => ({
    ...state!,
    startOfficeCall
  }),
  [ActionsTypes.SET_ON_OFFICE_ROOM]: (
    state: IRoomState | undefined,
    { onOfficeRoom }: AnyAction
  ): IRoomState => ({
    ...state!,
    onOfficeRoom
  }),
  [ActionsTypes.SET_OFFICE_ROOM_INDEX]: (
    state: IRoomState | undefined,
    { officeRoomIndex }: AnyAction
  ): IRoomState => ({
    ...state!,
    officeRoomIndex
  }),
  [ActionsTypes.SET_ROOM_ACCESS_DENIED]: (
    state: IRoomState | undefined,
    { roomAccessDenied }: AnyAction
  ): IRoomState => ({
    ...state!,
    roomAccessDenied
  })
}

export const reducer = createReducer(initialState, actionHandlers)
