import styled from '@emotion/styled'
import { alpha } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import AppLoading from '../../components/ui/AppLoading/AppLoading'
import { PROFILINGS, ROLES } from '../../entities'
import { useThunkDispatch } from '../../hooks'
import useIsProfilingEnabled from '../../hooks/useIsProfilingEnabled'
import { IRootState } from '../../redux'
import { updateExhibitor } from '../../store/exhibitor'
import { BLACK_BLUE, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import Announce from '../globals/Announce'
import Profiling from '../globals/Profiling'
import VideoUrl from '../globals/VideoUrl'
import Loading from '../Loading'
import Editor from '../ui/Editor/Editor'
import ExhibitorDocuments from './ExhibitorDocuments'
import ExhibitorDocumentsList from './ExhibitorDocumentsList'
import ExhibitorFavoriteButton from './ExhibitorFavoriteButton'
import ExhibitorImage from './ExhibitorImage'
import ExhibitorProductsInfos from './ExhibitorProductsInfos'
import ExhibitorShortBodyEdit from './ExhibitorShortBodyEdit'
import ExhibitorTakeAppointment from './ExhibitorTakeAppointment'

const ExhibitorInfos: React.FC = () => {
  const intl = useIntl()
  const [body, setBody] = useState('')
  const [shortBody, setShortBody] = useState('')
  const [name, setName] = useState('')
  const [stand, setStand] = useState('')
  const [vodUrl, setVodUrl] = useState('')
  const user = useSelector((state: IRootState) => state.appState.user)
  const groupId = useSelector((state: IRootState) => state.appState.groupId)
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const loading = useSelector((state: IRootState) => state.exhibitorState.loading)
  const updating = useSelector((state: IRootState) => state.exhibitorState.updating)
  const editMode = useSelector((state: IRootState) => state.exhibitorState.editMode)
  const isProfilingEnabled = useIsProfilingEnabled()
  const dispatch = useThunkDispatch()

  const isPremiumExhibitor = useMemo(
    () => user && user.roles && user.roles.indexOf(ROLES.ROLE_ANNOUNCEMENT_EXHIBITOR) > -1,
    [user]
  )

  const documents = useMemo(() => exhibitor?.documents, [exhibitor])

  const updateExhibitorImage = (base64: string) => {
    if (exhibitor) {
      dispatch(updateExhibitor(exhibitor.id, { imageUrlS3: base64 }))
    }
  }

  const updateExhibitorBody = (value: string) => {
    setBody(value)
    if (exhibitor) {
      dispatch(updateExhibitor(exhibitor.id, { body: value }))
    }
  }

  const updateExhibitorShortBody = (value: string) => {
    setShortBody(value)
    if (exhibitor) {
      dispatch(updateExhibitor(exhibitor.id, { shortBody: value }))
    }
  }

  // const updateExhibitorName = (value: string) => {
  //   setName(value)
  //   if (exhibitor) {
  //     dispatch(updateExhibitor(exhibitor.id, { name: value }))
  //   }
  // }

  const updateExhibitorVodUrl = (value: string | undefined) => {
    if (exhibitor) {
      dispatch(updateExhibitor(exhibitor.id, { vodUrl: value }))
    }
  }

  useEffect(() => {
    if (exhibitor) {
      setBody(exhibitor.body || '')
      setShortBody(exhibitor.shortBody || '')
      if (!name && exhibitor.name) {
        setName(exhibitor.name)
      }
      if (!stand && exhibitor.stand) {
        setStand(exhibitor.stand)
      }
      if (!vodUrl && exhibitor.vodUrl) {
        setVodUrl(exhibitor.vodUrl)
      }
    }
  }, [exhibitor, name, stand, vodUrl])

  if (loading || !exhibitor) return <AppLoading />

  return (
    <StyledExhibitorInfos>
      <StyledExhibitorInfosCard>
        <StyledTopButton>
          <ExhibitorFavoriteButton exhibitor={exhibitor} />
        </StyledTopButton>
        <StyledExhibitorImage>
          <ExhibitorImage
            readonly={!editMode}
            onChangeImage={(base64: string) => updateExhibitorImage(base64)}
          ></ExhibitorImage>
        </StyledExhibitorImage>
        <StyledH1>
          {/* {editMode ? (
            <InputText
              onChangeValue={updateExhibitorName}
              placeholder={intl.formatMessage({
                id: 'exhibitor.name.placeholder'
              })}
              value={name}
            />
          ) : ( */}
          <>{exhibitor.name}</>
          {/* )} */}
        </StyledH1>
        {exhibitor.editable && (
          <>
            <VideoUrl url={vodUrl} onChange={updateExhibitorVodUrl} />
            <Announce groupId={groupId} />
            {isPremiumExhibitor && exhibitor.announcementQuota !== undefined && (
              <Announce groupId={groupId} premium quota={exhibitor.announcementQuota} />
            )}
            {isProfilingEnabled && (
              <Profiling type={PROFILINGS.EXHIBITOR} exhibitorId={exhibitor.id} />
            )}
            <ExhibitorShortBodyEdit shortBody={shortBody} onChange={updateExhibitorShortBody} />
            <ExhibitorDocuments />
          </>
        )}
        <ExhibitorTakeAppointment
          exhibitorId={exhibitor.id}
          moderators={exhibitor.moderators}
          showDate
          disabled={!exhibitor.moderators || exhibitor.moderators.length === 0}
        />
        <Editor
          readonly={!editMode}
          content={body}
          placeholder={intl.formatMessage({ id: 'exhibitor.infos.placeholder' })}
          onChange={(content: string) => updateExhibitorBody(content)}
        />
        {updating && <Loading position="bottomRight" />}
      </StyledExhibitorInfosCard>
      {documents && documents.length > 0 && (
        <StyledExhibitorInfosCard>
          <ExhibitorDocumentsList summary />
        </StyledExhibitorInfosCard>
      )}
      {(exhibitor.enableFeatureProducts && (exhibitor.editable || (!exhibitor.editable && exhibitor.products.length > 0))) && (
        <StyledExhibitorInfosCard>
          <ExhibitorProductsInfos />
        </StyledExhibitorInfosCard>
      )}
    </StyledExhibitorInfos>
  )
}

const StyledTopButton = styled.div`
  & {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row-reverse;
  }
`

const StyledExhibitorInfos = styled.div`
  & {
    position: relative;
    font-size: 10px;
    padding-top: 15px;
  }
`

const StyledExhibitorInfosCard = styled.div`
  & {
    position: relative;
    margin: 10px;
    background-color: ${WHITE};
    border-radius: ${BORDER_RADIUS_CARD};
    padding: 20px;
  }
`

const StyledExhibitorImage = styled.div`
  & {
    position: relative;
    margin-bottom: 10px;
    font-size: 10px;
    padding: 10px;
    box-shadow: 0 1px 3px 0 ${alpha(BLACK_BLUE, 0.2)};
    margin-top: 40px;

    img {
      width: 100%;
      height: 250px;
      object-fit: contain;
    }
  }
`

const StyledH1 = styled.h1`
  &,
  & .MuiInput-root {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 15px;
  }
`

export default ExhibitorInfos
