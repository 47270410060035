import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRootState } from '../redux'
import settings from '../settings'
import { PRIMARY_COLOR } from '../theme'
import { WHITE } from '../theme/colors'

const CheckVersion: React.FC = () => {
  const eventCoCompatibility = useSelector((state: IRootState) => state.appState.eventCoCompatibility)

  const memoApiVersion = useMemo(() => {
    if (eventCoCompatibility) {
      const numbers = eventCoCompatibility.split('.')
      return {
        version: parseInt(numbers[0], 10),
        release: parseInt(numbers[1], 10),
        modification: parseInt(numbers[2], 10)
      }
    } else {
      return {
        version: 0,
        release: 0,
        modification: 0
      }
    }
  }, [eventCoCompatibility])

  const memoFrontVersion = useMemo(() => {
    const version = settings.version.indexOf('-') > -1 ? settings.version.split('-')[1] : settings.version
    const numbers = version.split('.')
    return {
      version: parseInt(numbers[0], 10),
      release: parseInt(numbers[1], 10),
      modification: parseInt(numbers[2], 10)
    }
  }, [])

  const reloadHandler = () => {
    window.location.reload()
  }

  if (memoFrontVersion.version >= memoApiVersion.version &&
    memoFrontVersion.release >= memoApiVersion.release &&
    memoFrontVersion.modification >= memoApiVersion.modification) {
    return <></>
  }

  return (
    <CheckVersionStyled>
      <FormattedMessage id="check.version.message" />
      <StyledButton onClick={reloadHandler}>
        <FormattedMessage id="check.version.button" />
      </StyledButton>
    </CheckVersionStyled>
  )
}

const CheckVersionStyled = styled.div`
  position: fixed;
  top: 10px;
  left: 0;
  width: calc(100% - 10px);
  margin: 0 5px;
  padding: 5px;
  background-color: ${PRIMARY_COLOR};
  border-radius: 5px;
  color: ${WHITE};
  text-align: center;
  z-index: 10000;
`

const StyledButton = styled(Button)`
  margin-left: 10px;
  color: ${WHITE};
`

export default CheckVersion
