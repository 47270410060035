import '@ckeditor/ckeditor5-build-inline/build/translations/fr'
import DateFnsUtils from '@date-io/date-fns'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import enLocale from 'date-fns/locale/en-US'
import frLocale from 'date-fns/locale/fr'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../redux'

function getLocale(localeStr: string) {
  switch (localeStr || 'fr') {
    case 'fr':
      return frLocale
    case 'en':
    default:
      return enLocale
  }
}

interface IDateTimePickerProps extends KeyboardDateTimePickerProps {
  iconMode?: boolean
}

const DateTimePicker: React.FC<IDateTimePickerProps> = ({ iconMode, ...props }) => {
  const locale = useSelector((state: IRootState) => state.appState.locale)

  return (
    <StyledDateTimePicker iconMode={iconMode}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
        <KeyboardDateTimePicker
          margin="normal"
          clearable
          ampm={locale === 'en'}
          KeyboardButtonProps={{
            'aria-label': 'change date/time'
          }}
          clearLabel={<FormattedMessage id="dialog.clear" />}
          cancelLabel={<FormattedMessage id="dialog.cancel" />}
          todayLabel={<FormattedMessage id="dialog.today" />}
          okLabel={<FormattedMessage id="dialog.validate" />}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </StyledDateTimePicker>
  )
}

const CssIconMode = css`
  & > .MuiFormControl-root {
    margin-top: 5px;
  }
  & .MuiInputBase-input,
  & .MuiInput-underline:after,
  & .MuiInput-underline::before,
  & .MuiFormHelperText-root {
    display: none;
  }
`

const StyledDateTimePicker = styled.div<{ iconMode?: boolean }>`
  ${(props) => (props.iconMode ? CssIconMode : '')}
`

export default DateTimePicker
