import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IExhibitorState } from './model'

const initialState: IExhibitorState = {
  totalExhibitors: 0,
  exhibitors: { total: 0, items: [] },
  loadingExhibitors: false,
  loading: false,
  editMode: false,
  premiumExhibitors: [],
  loadingPremium: false,
  scoredExhibitors: [],
  loadingScored: false,
  recommendedExhibitors: [],
  loadingRecommended: false,
  exhibitor: null,
  updating: false,
  filters: {},
  loadingFilters: false,
  addingExhibitorToFavorites: {},
  removingExhibitorFromFavorites: {},
  showrooms: [],
  loadingShowrooms: false
}

const actionHandlers: IReducerHandler<IExhibitorState> = {
  [ActionsTypes.SET_EXHIBITORS_TOTAL]: (
    state: IExhibitorState | undefined,
    { totalExhibitors }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    totalExhibitors
  }),

  [ActionsTypes.SET_EXHIBITOR_SEARCH]: (
    state: IExhibitorState | undefined,
    { searchExhibitors }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    searchExhibitors
  }),

  [ActionsTypes.SET_EXHIBITOR_ERROR]: (
    state: IExhibitorState | undefined,
    { error }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    error
  }),

  [ActionsTypes.SET_EXHIBITOR_LOADING]: (
    state: IExhibitorState | undefined,
    { loading }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    loading
  }),

  [ActionsTypes.SET_EXHIBITORS]: (
    state: IExhibitorState | undefined,
    { exhibitors }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    exhibitors
  }),

  [ActionsTypes.SET_EXHIBITORS_ERROR]: (
    state: IExhibitorState | undefined,
    { errorExhibitors }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    errorExhibitors
  }),

  [ActionsTypes.SET_EXHIBITORS_LOADING]: (
    state: IExhibitorState | undefined,
    { loadingExhibitors }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    loadingExhibitors
  }),

  [ActionsTypes.SET_EXHIBITOR_EDIT_MODE]: (
    state: IExhibitorState | undefined,
    { editMode }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    editMode
  }),

  [ActionsTypes.SET_RECOMMENDED_EXHIBITOR_ERROR]: (
    state: IExhibitorState | undefined,
    { errorRecommended }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    errorRecommended
  }),

  [ActionsTypes.SET_SCORED_EXHIBITOR_LOADING]: (
    state: IExhibitorState | undefined,
    { loadingScored }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    loadingScored
  }),

  [ActionsTypes.SET_SCORED_EXHIBITORS]: (
    state: IExhibitorState | undefined,
    { scoredExhibitors }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    scoredExhibitors
  }),

  [ActionsTypes.SET_SCORED_EXHIBITOR_ERROR]: (
    state: IExhibitorState | undefined,
    { errorScored }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    errorScored
  }),

  [ActionsTypes.SET_RECOMMENDED_EXHIBITOR_LOADING]: (
    state: IExhibitorState | undefined,
    { loadingRecommended }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    loadingRecommended
  }),

  [ActionsTypes.SET_RECOMMENDED_EXHIBITORS]: (
    state: IExhibitorState | undefined,
    { recommendedExhibitors }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    recommendedExhibitors
  }),

  [ActionsTypes.SET_PREMIUM_EXHIBITOR_ERROR]: (
    state: IExhibitorState | undefined,
    { errorPremium }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    errorPremium
  }),

  [ActionsTypes.SET_PREMIUM_EXHIBITOR_LOADING]: (
    state: IExhibitorState | undefined,
    { loadingPremium }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    loadingPremium
  }),

  [ActionsTypes.SET_PREMIUM_EXHIBITORS]: (
    state: IExhibitorState | undefined,
    { premiumExhibitors }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    premiumExhibitors
  }),

  [ActionsTypes.RESET_EXHIBITOR]: (state: IExhibitorState | undefined): IExhibitorState => ({
    ...state!,
    exhibitor: null
  }),

  [ActionsTypes.SET_EXHIBITOR]: (
    state: IExhibitorState | undefined,
    { exhibitor }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    exhibitor
  }),

  [ActionsTypes.MERGE_EXHIBITOR]: (
    state: IExhibitorState | undefined,
    { exhibitorUpdate }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    exhibitor: state && state.exhibitor ? { ...state.exhibitor, ...exhibitorUpdate } : null
  }),

  [ActionsTypes.MERGE_EXHIBITOR_ROOM]: (
    state: IExhibitorState | undefined,
    { roomUpdated }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    exhibitor:
      state && state.exhibitor
        ? {
          ...state.exhibitor,
          rooms: state.exhibitor.rooms.map((room) =>
            room.id === roomUpdated.id ? roomUpdated : room
          )
        }
        : null
  }),

  [ActionsTypes.SET_EXHIBITOR_UPDATING]: (
    state: IExhibitorState | undefined,
    { updating }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    updating
  }),

  [ActionsTypes.SET_EXHIBITOR_FILTERS]: (
    state: IExhibitorState | undefined,
    { filters }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    filters
  }),

  [ActionsTypes.SET_LOADING_EXHIBITOR_FILTERS]: (
    state: IExhibitorState | undefined,
    { loadingFilters }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    loadingFilters
  }),

  [ActionsTypes.SET_ERROR_EXHIBITOR_FILTERS]: (
    state: IExhibitorState | undefined,
    { errorFilters }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    errorFilters
  }),

  [ActionsTypes.ADD_PRODUCT]: (
    state: IExhibitorState | undefined,
    { product }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    exhibitor:
      state && state.exhibitor
        ? {
          ...state.exhibitor,
          products: [...state.exhibitor.products, product]
        }
        : null
  }),

  [ActionsTypes.REMOVE_PRODUCT]: (
    state: IExhibitorState | undefined,
    { productId }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    exhibitor:
      state && state.exhibitor
        ? {
          ...state.exhibitor,
          products: state.exhibitor.products.filter((product) => product.id !== productId)
        }
        : null
  }),

  [ActionsTypes.UPDATE_PRODUCT]: (
    state: IExhibitorState | undefined,
    { product }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    exhibitor:
      state && state.exhibitor
        ? {
          ...state.exhibitor,
          products: state.exhibitor.products.map((p) => {
            if (product.id === p.id) {
              return product
            } else {
              return p
            }
          })
        }
        : null
  }),

  [ActionsTypes.UPDATE_EXHIBITORS_FAVORITE]: (
    state: IExhibitorState | undefined,
    { exhibitorId, isFollowed }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    recommendedExhibitors:
      state && state.recommendedExhibitors
        ? state.recommendedExhibitors.map((exhibitor) =>
          exhibitor.id === exhibitorId ? { ...exhibitor, isFollowed } : exhibitor
        )
        : [],
    premiumExhibitors:
      state && state.premiumExhibitors
        ? state.premiumExhibitors.map((exhibitor) =>
          exhibitor.id === exhibitorId ? { ...exhibitor, isFollowed } : exhibitor
        )
        : []
  }),

  [ActionsTypes.SET_ADDING_EXHIBITOR_TO_FAVORITES]: (
    state: IExhibitorState | undefined,
    { exhibitorId, addingExhibitorToFavorites }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    addingExhibitorToFavorites:
      state && state.addingExhibitorToFavorites
        ? { ...state.addingExhibitorToFavorites, [exhibitorId]: addingExhibitorToFavorites }
        : {}
  }),

  [ActionsTypes.SET_ERROR_ADDING_EXHIBITOR_TO_FAVORITES]: (
    state: IExhibitorState | undefined,
    { errorAddingExhibitorToFavorites }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    errorAddingExhibitorToFavorites
  }),

  [ActionsTypes.SET_REMOVING_EXHIBITOR_FROM_FAVORITES]: (
    state: IExhibitorState | undefined,
    { exhibitorId, removingExhibitorFromFavorites }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    removingExhibitorFromFavorites:
      state && state.addingExhibitorToFavorites
        ? { ...state.addingExhibitorToFavorites, [exhibitorId]: removingExhibitorFromFavorites }
        : {}
  }),

  [ActionsTypes.SET_ERROR_REMOVING_EXHIBITOR_FROM_FAVORITES]: (
    state: IExhibitorState | undefined,
    { errorRemovingExhibitorFromFavorites }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    errorRemovingExhibitorFromFavorites
  }),

  [ActionsTypes.SET_SHOWROOMS_LOADING]: (
    state: IExhibitorState | undefined,
    { loadingShowrooms }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    loadingShowrooms
  }),

  [ActionsTypes.SET_SHOWROOMS]: (
    state: IExhibitorState | undefined,
    { showrooms }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    showrooms
  }),

  [ActionsTypes.SET_SHOWROOMS_ERROR]: (
    state: IExhibitorState | undefined,
    { errorShowrooms }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    errorShowrooms
  }),

  [ActionsTypes.UPDATE_EXHIBITOR_ROOM_USERS_ONLINE]: (
    state: IExhibitorState | undefined,
    { userIds, isOnline }: AnyAction
  ): IExhibitorState => ({
    ...state!,
    exhibitor:
      state && state.exhibitor
        ? {
          ...state.exhibitor,
          rooms: state.exhibitor.rooms.map((room) => {
            if (room.userPresence && userIds.indexOf(room.userPresence.user.id) > -1) {
              return {
                ...room,
                userPresence: {
                  user: room.userPresence.user,
                  isOnline
                }
              }
            } else {
              return room
            }
          })
        }
        : null
  })
}

export const reducer = createReducer(initialState, actionHandlers)
