import styled from '@emotion/styled'
import {
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NetworkTest from 'opentok-network-test-js'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  FrameRateType,
  IOpentokRecommended,
  IOpentokSettings,
  VideoResolution
} from '../../entities/mediaStream'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { saveRecommendedFrameRate, saveRecommendedResolution } from '../../store/app'
import { setOpentokRecommended } from '../../store/room'
import {
  loadOpentokTests,
  resetOpentokQualityTestResults,
  saveOpentokQualityTestResults
} from '../../store/settings/thunks'
import { BLACK_BLUE, TEST_COLORS } from '../../theme/colors'
import asyncLocalStorage from '../../utils/asyncLocalstorage'
import consoleUtils from '../../utils/consoleUtils'
import Transition from '../Transition'
import AppButton from '../ui/AppButton/AppButton'

interface OpenTokModalProps {
  opened: boolean
  setOpened: (opened: boolean) => void
}

const OpenTokModal: React.FC<OpenTokModalProps> = ({ opened, setOpened }) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const [progress, setProgress] = useState(0)
  // const [supported, setSupported] = useState(false)
  const [expanded1, setExpanded1] = useState(false)
  const [expanded2, setExpanded2] = useState(false)
  const opentokTests = useSelector((state: IRootState) => state.settingsState.opentokTests)
  const quality = useSelector((state: IRootState) => state.settingsState.opentokQualityTestResults)
  const dispatch = useThunkDispatch()

  const mosLabel = (value: number | undefined) => {
    if (!value) return ''
    if (value >= 3.8 && value <= 4.5) {
      return intl.formatMessage({ id: 'mos.excellent' })
    } else if (value >= 3.1 && value <= 3.79) {
      return intl.formatMessage({ id: 'mos.good' })
    } else if (value >= 2.4 && value <= 3.09) {
      return intl.formatMessage({ id: 'mos.fair' })
    } else if (value >= 1.7 && value <= 2.39) {
      return intl.formatMessage({ id: 'mos.poor' })
    } else if (value >= 1.0 && value <= 1.69) {
      return intl.formatMessage({ id: 'mos.bad' })
    }
  }

  const test = useCallback(
    async (otSettings: IOpentokSettings) => {
      consoleUtils.log('start otNetworkTest')
      const otNetworkTest = new NetworkTest(OT as any, {
        apiKey: settings.opentok.apiKey,
        sessionId: otSettings.sessionId,
        token: otSettings.token
      })
      let i = 1
      const qualityResults = await otNetworkTest.testQuality((stats) => {
        setProgress(2.5 * i++)
      })
      // This function is called when the quality test is completed.
      consoleUtils.log('OpenTok quality results=', qualityResults)
      setProgress(100)
      dispatch(saveOpentokQualityTestResults(asyncLocalStorage, qualityResults))
    },
    [dispatch]
  )

  const handleClose = () => {
    setOpened(false)
  }

  const restartTest = () => {
    if (opened && opentokTests) {
      try {
        setProgress(0)
        dispatch(resetOpentokQualityTestResults(asyncLocalStorage))
        test(opentokTests)
      } catch (error) {
        consoleUtils.error('OpenTok quality test error', error)
      }
    }
  }

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1)
  }

  const handleExpandClick2 = () => {
    setExpanded2(!expanded2)
  }

  useEffect(() => {
    if (quality) {
      const publisherSettings: IOpentokRecommended = {}
      if (quality.video.reason) {
        consoleUtils.log('Video not supported:', quality.video.reason)
        publisherSettings.videoSource = undefined // audio-only
      } else {
        publisherSettings.videoSource = true
        publisherSettings.frameRate = quality.video.recommendedFrameRate as FrameRateType
        if (publisherSettings.frameRate) {
          dispatch(saveRecommendedFrameRate(asyncLocalStorage, publisherSettings.frameRate))
        }
        publisherSettings.resolution =
          quality.video.recommendedResolution === VideoResolution._240_P
            ? VideoResolution._240_P
            : quality.video.recommendedResolution === VideoResolution._480_P
              ? VideoResolution._480_P
              : quality.video.recommendedResolution === VideoResolution._720_P
                ? VideoResolution._720_P
                : undefined
        if (publisherSettings.resolution) {
          dispatch(saveRecommendedResolution(asyncLocalStorage, publisherSettings.resolution))
        }
      }
      if (!quality.audio.supported) {
        consoleUtils.log('Audio not supported:', quality.audio.reason)
        publisherSettings.audioSource = undefined
        // video-only, but you probably don't want this -- notify the user?
      } else {
        publisherSettings.audioSource = true
      }
      // if (!publisherSettings.videoSource && !publisherSettings.audioSource) {
      //   // Do not publish. Notify the user.
      //   setSupported(false)
      // } else {
      //   // Publish to the "real" session, using the publisherSettings object.
      //   setSupported(true)
      // }
      dispatch(setOpentokRecommended(publisherSettings))
    }
  }, [quality, dispatch])

  useEffect(() => {
    if (opened && opentokTests) {
      if (quality) {
        setProgress(100)
      } else {
        try {
          test(opentokTests)
        } catch (error) {
          consoleUtils.error('OpenTok quality test error', error)
        }
      }
    }
  }, [opened, opentokTests, quality, test])

  useEffect(() => {
    setOpen(opened)
  }, [opened])

  useEffect(() => {
    if (open) {
      dispatch(loadOpentokTests())
    }
  }, [dispatch, open])

  useEffect(() => {
    if (typeof quality?.video.mos === 'number') {
      setExpanded1(quality.video.mos < 2)
    } else {
      setExpanded1(false)
    }
    if (typeof quality?.audio.mos === 'number') {
      setExpanded2(quality.audio.mos < 2)
    } else {
      setExpanded2(false)
    }
  }, [quality, setExpanded1, setExpanded2])

  return (
    <DialogStyled
      open={open}
      maxWidth="md"
      aria-labelledby="opentokmodal-title"
      aria-describedby="opentokmodal-description"
      TransitionComponent={Transition}
    >
      <DialogTitleStyled id="opentokmodal-title">
        <FormattedMessage id="opentok.test.title" />
      </DialogTitleStyled>
      <DialogContent>
        {progress < 100 && (
          <>
            <LinearProgressStyled variant="determinate" value={progress} />
            <div>
              <i>
                <FormattedMessage id="opentok.test.indication" />
              </i>
            </div>
          </>
        )}
        {progress === 100 && quality && (
          <ResultContainer
            audio={quality.audio.mos ? Math.round(quality.audio.mos) : 5}
            video={quality.video.mos ? Math.round(quality.video.mos) : 5}
          >
            <CardStyled className={`card-video`}>
              <CardContentStyled>
                <CardTitleStyled>
                  {quality.video.supported ? (
                    <CheckCircleIcon color="inherit" fontSize="large" />
                  ) : (
                    <CancelIcon color="inherit" fontSize="large" />
                  )}
                  {` `}
                  Video
                </CardTitleStyled>
                <IconButton
                  onClick={handleExpandClick1}
                  aria-expanded={expanded1}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardContentStyled>
              <Collapse in={expanded1} timeout="auto" unmountOnExit>
                <CardContent>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <FormattedMessage id="opentok.test.videoSupported" />
                        </TableCell>
                        <TableCell>
                          {quality.video.supported ? (
                            <FormattedMessage id="opentok.test.yes" />
                          ) : (
                            <FormattedMessage id="opentok.test.no" />
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <FormattedMessage id="opentok.test.videoQuality" />
                        </TableCell>
                        <TableCell>{mosLabel(quality.video.mos)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <FormattedMessage id="opentok.test.packetLossRatio" />
                        </TableCell>
                        <TableCell>{quality.video.packetLossRatio}%</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <FormattedMessage id="opentok.test.recommendedFrameRate" />
                        </TableCell>
                        <TableCell>{quality.video.recommendedFrameRate}</TableCell>
                      </TableRow>
                      {quality.video.recommendedResolution && (
                        <TableRow>
                          <TableCell>
                            <FormattedMessage id="opentok.test.recommendedResolution" />
                          </TableCell>
                          <TableCell>{quality.video.recommendedResolution}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </CardContent>
              </Collapse>
            </CardStyled>
            <CardStyled className={`card-audio`}>
              <CardContentStyled>
                <CardTitleStyled>
                  {quality.audio.supported ? (
                    <CheckCircleIcon color="inherit" fontSize="large" />
                  ) : (
                    <CancelIcon color="inherit" fontSize="large" />
                  )}
                  {` `}
                  Audio
                </CardTitleStyled>
                <IconButton
                  onClick={handleExpandClick2}
                  aria-expanded={expanded2}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardContentStyled>
              <Collapse in={expanded2} timeout="auto" unmountOnExit>
                <CardContent>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <FormattedMessage id="opentok.test.audioSupported" />
                        </TableCell>
                        <TableCell>
                          {quality.audio.supported ? (
                            <FormattedMessage id="opentok.test.yes" />
                          ) : (
                            <FormattedMessage id="opentok.test.no" />
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <FormattedMessage id="opentok.test.audioQuality" />
                        </TableCell>
                        <TableCell>{mosLabel(quality.audio.mos)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <FormattedMessage id="opentok.test.packetLossRatio" />
                        </TableCell>
                        <TableCell>{quality.audio.packetLossRatio}%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Collapse>
            </CardStyled>
            <ButtonsStyled>
              <AppButton onClick={() => restartTest()}>
                <FormattedMessage id="opentok.test.restart" />
              </AppButton>
              <AppButton onClick={() => handleClose()}>
                <FormattedMessage id="opentok.test.close" />
              </AppButton>
            </ButtonsStyled>
          </ResultContainer>
        )}
      </DialogContent>
    </DialogStyled>
  )
}

// language=scss
const ResultContainer = styled.div<{ audio?: number; video?: number }>`
  & {
    .card-video {
      background-color: ${(props) => (props.video ? TEST_COLORS[props.video] : `white`)};
    }
    .card-audio {
      background-color: ${(props) => (props.audio ? TEST_COLORS[props.audio] : `white`)};
    }
  }
`

// language=scss
const DialogStyled = styled(Dialog)`
  & {
    .MuiDialog-paper {
      width: 600px;
      max-width: 90vw;
    }
  }
`

// language=scss
const CardTitleStyled = styled.div`
  & {
    display: flex;
    align-items: center;

    svg {
      margin-right: 15px;
    }
  }
`

// language=scss
const DialogTitleStyled = styled(DialogTitle)`
  & {
    text-align: center;

    .MuiTypography-root {
      font-size: 30px;
      font-weight: bold;
    }
  }
`

// language=scss
const LinearProgressStyled = styled(LinearProgress)`
  & {
    height: 20px;
    margin-bottom: 20px;
  }
`

// language=scss
const CardStyled = styled(Card)`
  & {
    margin-bottom: 15px;
    color: ${BLACK_BLUE} !important;

    .MuiSvgIcon-root,
    .MuiTableCell-body {
      color: ${BLACK_BLUE} !important;
    }
    .MuiCardContent-root {
      padding: 16px !important;
    }
  }
`

// language=scss
const CardContentStyled = styled(CardContent)`
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

// language=scss
const ButtonsStyled = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      + button {
        margin-left: 10px;
      }
    }
  }
`

export default OpenTokModal
