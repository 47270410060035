import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, ISettingsState } from './model'

const initialState: ISettingsState = {
  loadingSettings: false,
  loadingOpentokTests: false,
  loadingEvent: false,
  opentokQualityTestResults: null,
  opentokSettings: null,
  opentokTests: null,
  eventCo: null,
  eventCoDates: [],
  eventCoDatesLoaded: false
}

const actionHandlers: IReducerHandler<ISettingsState> = {
  [ActionsTypes.SET_LOADING_EVENT]: (
    state: ISettingsState | undefined,
    { loadingEvent }: AnyAction
  ): ISettingsState => ({
    ...state!,
    loadingEvent
  }),

  [ActionsTypes.SET_LOADING_SETTINGS]: (
    state: ISettingsState | undefined,
    { loadingSettings }: AnyAction
  ): ISettingsState => ({
    ...state!,
    loadingSettings
  }),

  [ActionsTypes.SET_LOADING_TESTS]: (
    state: ISettingsState | undefined,
    { loadingOpentokTests }: AnyAction
  ): ISettingsState => ({
    ...state!,
    loadingOpentokTests
  }),

  [ActionsTypes.SET_SETTINGS_OPENTOK]: (
    state: ISettingsState | undefined,
    { opentokSettings }: AnyAction
  ): ISettingsState => ({
    ...state!,
    opentokSettings
  }),

  [ActionsTypes.SET_QUALITY_TEST_RESULTS_OPENTOK]: (
    state: ISettingsState | undefined,
    { opentokQualityTestResults }: AnyAction
  ): ISettingsState => ({
    ...state!,
    opentokQualityTestResults
  }),

  [ActionsTypes.SET_TESTS_OPENTOK]: (
    state: ISettingsState | undefined,
    { opentokTests }: AnyAction
  ): ISettingsState => ({
    ...state!,
    opentokTests
  }),

  [ActionsTypes.SET_EVENT_CO]: (
    state: ISettingsState | undefined,
    { eventCo }: AnyAction
  ): ISettingsState => ({
    ...state!,
    eventCo
  }),

  [ActionsTypes.SET_EVENT_CO_DATES]: (
    state: ISettingsState | undefined,
    { eventCoDates }: AnyAction
  ): ISettingsState => ({
    ...state!,
    eventCoDates
  }),

  [ActionsTypes.SET_EVENT_CO_DATES_LOADED]: (
    state: ISettingsState | undefined,
    { eventCoDatesLoaded }: AnyAction
  ): ISettingsState => ({
    ...state!,
    eventCoDatesLoaded
  }),

  [ActionsTypes.SET_SETTINGS_ERROR]: (
    state: ISettingsState | undefined,
    { errorSettings }: AnyAction
  ): ISettingsState => ({
    ...state!,
    errorSettings
  }),

  [ActionsTypes.SET_TESTS_ERROR]: (
    state: ISettingsState | undefined,
    { errorOpentokTests }: AnyAction
  ): ISettingsState => ({
    ...state!,
    errorOpentokTests
  }),

  [ActionsTypes.SET_EVENT_ERROR]: (
    state: ISettingsState | undefined,
    { errorEvent }: AnyAction
  ): ISettingsState => ({
    ...state!,
    errorEvent
  })
}

export const reducer = createReducer(initialState, actionHandlers)
