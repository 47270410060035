import { Channel, PresenceChannel } from 'pusher-js'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IBaseRootState } from '..'
import {
  enterRoomBroadcasterJitsi as enterRoomBroadcasterJitsiApi,
  enterRoomJitsi as enterRoomJitsiApi
} from '../../api'
import { IUser } from '../../entities'
import { formatName } from '../../utils'
import consoleUtils from '../../utils/consoleUtils'
import pusherUtils from '../../utils/pusherUtils'
import { setRoomNumber, setRoomQueued } from '../room'
import { setChairId, setChairUid, setStandRoomChannel } from '../stand'
import { setJitsiError, setJitsiLoading, setJitsiRoom } from './actions'

export const enterRoomJitsi = (
  num: number,
  roomId: number,
  opentokId: string,
  standChannel: Channel,
  userConnected: IUser,
  broadcasterEnabled?: boolean
) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
  dispatch(setJitsiLoading(true))
  try {
    dispatch(setRoomNumber(num))
    const response = broadcasterEnabled
      ? await enterRoomBroadcasterJitsiApi(roomId)
      : await enterRoomJitsiApi(roomId)
    dispatch(setJitsiRoom(response))
    dispatch(setChairId(response.chair.id))
    dispatch(setChairUid(response.chair.uid))
    dispatch(setRoomQueued(response.isQueued))

    // subscribe to opentok room
    const channel = (await pusherUtils.subscribe(
      `presence-room-${opentokId}-${roomId}`
    )) as PresenceChannel
    dispatch(setStandRoomChannel(channel))

    pusherUtils.triggerClientLocation(standChannel, `client-location-${opentokId}`, {
      user: {
        id: userConnected.id,
        username: formatName(userConnected).full,
        firstname: userConnected.firstname,
        lastname: userConnected.lastname,
        avatarPath: userConnected.avatarPath,
        isOnline: true
      },
      roomId: `${roomId}`,
      chairId: response.chair ? response.chair.id : '-1',
      broadcaster: !!broadcasterEnabled
    })

    pusherUtils.subscribe(`private-chair-${response.chair.uid}`)
    pusherUtils.subscribe(`private-chair-${response.chair.uid}-user-${userConnected.id}`)
  } catch (e) {
    // TODO : trouver un moyen de récupérer le message de l'api
    dispatch(setJitsiError('Aucune chaise trouvée sur cette table'))
    consoleUtils.error(e)
  }
  dispatch(setJitsiLoading(false))
}
