import styled from '@emotion/styled'
import { Grid, IconButton, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { AnimatedList } from 'react-animated-list'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useMagicStream, usePagination, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import {
  setContactsFiltered,
  setErrorContacts,
  setErrorContactsFiltered
} from '../../store/participant'
import {
  loadContactsFiltered,
  loadKeywordsCreatedByUser,
  loadParticipants,
  loadParticipantsFiltered
} from '../../store/participant/thunks'
import { GRAY_BORDER, WHITE } from '../../theme/colors'
import { formatName } from '../../utils'
import ExtensiblePanel from '../globals/ExtensiblePanel/ExtensiblePanel'
import LoadingCard from '../LoadingCard'
import ContactItem from './ContactItem'
import ContactsList from './ContactsList'

interface IVisitorsPanelProps {
  half: boolean
}

const VisitorsPanel: FC<IVisitorsPanelProps> = ({ half }) => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const inputRef = useRef<HTMLDivElement>(null)
  const { count, page, setPage } = usePagination(1)
  const [currentSearchText, setCurrentSearchText] = useState('')
  const [openSearchContact, setOpenSearchContact] = useState(false)
  const [extended, setExtended] = useState(false)
  const [filterSearch, setFilterSearch] = useState(currentSearchText)
  const [filterIsOnline, setFilterIsOnline] = useState(false)
  const [filterIsContact, setFilterIsContact] = useState(false)
  const [filterCustom, setFilterCustom] = useState<{
    [key: string]: number | string | { id: number; name: string }
  }>({})
  const [filterTags, setFilterTags] = useState<{ [key: string]: boolean }>({})
  const [filterKeywordsX, setFilterKeywordsX] = useState<{ [key: string]: boolean }>({})
  const [filterKeywordsY, setFilterKeywordsY] = useState<{ [key: string]: boolean }>({})
  const [filterUserRoles, setFilterUserRoles] = useState('')
  const user = useSelector((state: IRootState) => state.appState.user)
  const participants = useSelector((state: IRootState) => state.participantState.participants)
  const participantsFiltered = useSelector(
    (state: IRootState) => state.participantState.participantsFiltered
  )
  const contactsFiltered = useSelector(
    (state: IRootState) => state.participantState.contactsFiltered
  )
  const errorContacts = useSelector((state: IRootState) => state.participantState.errorContacts)
  const errorContactsFiltered = useSelector(
    (state: IRootState) => state.participantState.errorContactsFiltered
  )
  const loading = useSelector((state: IRootState) => state.participantState.loading)
  const loadingParticipantsFiltered = useSelector(
    (state: IRootState) => state.participantState.loadingParticipantsFiltered
  )
  const loadingContactsFiltered = useSelector(
    (state: IRootState) => state.participantState.loadingContactsFiltered
  )

  const dispatch = useThunkDispatch()

  const handleExtend = (extend: boolean) => {
    setExtended(extend)
    if (extend) {
      dispatch(
        loadParticipantsFiltered({
          page,
          search: filterSearch,
          sortBy: 'recommandation',
          functionName: filterCustom.functions as string,
          partnerType: filterCustom.partner_types
            ? (filterCustom.partner_types as { id: number; name: string })
            : undefined,
          isOnline: filterIsOnline,
          isContact: filterIsContact,
          tags: Object.keys(filterTags)
            .filter((id) => filterTags[id])
            .join(','),
          keywordsX: Object.keys(filterKeywordsX)
            .filter((id) => filterKeywordsX[id])
            .join(','),
          keywordsY: Object.keys(filterKeywordsY)
            .filter((id) => filterKeywordsY[id])
            .join(','),
          roles: filterUserRoles
        })
      )
    }
  }

  const handleClickSearchContactButton = () => {
    setOpenSearchContact(!openSearchContact)
    if (!openSearchContact && inputRef.current) {
      inputRef.current?.focus()
    }
  }

  const handleSearchContact = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event.target.value as string
    if (value !== currentSearchText) {
      setCurrentSearchText(value)
    }
  }

  const memoContactsFiltered = useMemo(
    () =>
      contactsFiltered
        .filter((contact) => contact.id !== user!.id)
        .sort((c1, c2) => {
          if (
            (c1.userPresence.isOnline && c2.userPresence.isOnline) ||
            (!c1.userPresence.isOnline && !c2.userPresence.isOnline)
          ) {
            return formatName(c1.userPresence.user).full.localeCompare(formatName(c2.userPresence.user).full)
          } else if (c1.userPresence.isOnline) {
            return -1
          } else if (c2.userPresence.isOnline) {
            return 1
          }
          return 0
        }),
    [contactsFiltered, user]
  )

  useEffect(() => {
    if (user?.id) {
      dispatch(loadKeywordsCreatedByUser(user.id))
      dispatch(
        loadParticipants({
          page: 0,
          functionName: filterCustom.functions as string,
          partnerType: filterCustom.partner_types
            ? (filterCustom.partner_types as { id: number; name: string })
            : undefined
        })
      )
    }
  }, [user, filterCustom, dispatch])

  useEffect(() => {
    if (extended) {
      dispatch(
        loadParticipantsFiltered({
          page,
          search: filterSearch,
          sortBy: 'recommandation',
          functionName: filterCustom.functions as string,
          partnerType: filterCustom.partner_types
            ? (filterCustom.partner_types as { id: number; name: string })
            : undefined,
          isOnline: filterIsOnline,
          isContact: filterIsContact,
          tags: Object.keys(filterTags)
            .filter((id) => filterTags[id])
            .join(','),
          keywordsX: Object.keys(filterKeywordsX)
            .filter((id) => filterKeywordsX[id])
            .join(','),
          keywordsY: Object.keys(filterKeywordsY)
            .filter((id) => filterKeywordsY[id])
            .join(','),
          roles: filterUserRoles
        })
      )
    }
  }, [
    extended,
    filterSearch,
    filterIsOnline,
    filterIsContact,
    filterCustom,
    filterTags,
    filterKeywordsX,
    filterKeywordsY,
    filterUserRoles,
    page,
    dispatch
  ])

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (currentSearchText && currentSearchText.length > 2) {
        dispatch(
          loadContactsFiltered(
            currentSearchText,
            Object.keys(filterTags)
              .filter((id) => filterTags[id])
              .join(','),
            Object.keys(filterKeywordsX)
              .filter((id) => filterKeywordsX[id])
              .join(',')
          )
        )
      } else {
        dispatch(setContactsFiltered([]))
      }
    }, 500)

    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [currentSearchText, filterTags, filterKeywordsX, dispatch])

  useEffect(() => {
    if (errorContacts) {
      magicStream.error(errorContacts)
      dispatch(setErrorContacts(undefined))
    }
  }, [errorContacts, magicStream, dispatch])

  useEffect(() => {
    if (errorContactsFiltered) {
      magicStream.error(errorContactsFiltered)
      dispatch(setErrorContactsFiltered(undefined))
    }
  }, [errorContactsFiltered, magicStream, dispatch])

  if (!participants) return <></>

  return (
    <ExtensiblePanel
      half={half}
      titleKey="my.co.workers"
      subheaderNode={
        <>
          <IconButton onClick={handleClickSearchContactButton}>
            {!openSearchContact && (
              <SearchIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.Contacts.SearchIcon' })}
              />
            )}
            {openSearchContact && (
              <CloseIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.Contacts.CloseIcon' })}
              />
            )}
          </IconButton>
        </>
      }
      contentExtended={
        <ContactsList
          contacts={participantsFiltered}
          loading={loadingParticipantsFiltered}
          count={count}
          page={page}
          setPage={setPage}
          setFilterSearch={setFilterSearch}
          filterSearch={filterSearch}
          setFilterCustom={setFilterCustom}
          filterCustom={filterCustom}
          setFilterIsOnline={setFilterIsOnline}
          filterIsOnline={filterIsOnline}
          setFilterIsContact={setFilterIsContact}
          filterIsContact={filterIsContact}
          filterTags={filterTags}
          setFilterTags={setFilterTags}
          filterKeywordsX={filterKeywordsX}
          setFilterKeywordsX={setFilterKeywordsX}
          filterKeywordsY={filterKeywordsY}
          setFilterKeywordsY={setFilterKeywordsY}
          filterUserRoles={filterUserRoles}
          setFilterUserRoles={setFilterUserRoles}
        />
      }
      onExtend={handleExtend}
    >
      <StyledSearchContact open={openSearchContact}>
        <TextField
          id="searchContactsRightMenu"
          inputRef={inputRef}
          value={currentSearchText}
          onChange={handleSearchContact}
          placeholder={intl.formatMessage({ id: 'contacts.search.placeholder' })}
          InputProps={{ disableUnderline: true }}
        />
      </StyledSearchContact>
      {openSearchContact && currentSearchText.length > 2 ? (
        <>
          {loadingContactsFiltered &&
            Array(10)
              .fill(0)
              .map((value, index) => (
                <Grid key={index} item xs={12}>
                  <LoadingCard cardType="contact" />
                </Grid>
              ))}
          {!loadingContactsFiltered && (
            <AnimatedList>
              {memoContactsFiltered.map((contact) => (
                <ContactItem key={contact.id} contact={contact} add={false} more note />
              ))}
            </AnimatedList>
          )}
        </>
      ) : (
        <>
          {loading &&
            Array(10)
              .fill(0)
              .map((value, index) => (
                <Grid key={index} item xs={12}>
                  <LoadingCard cardType="contact" />
                </Grid>
              ))}
          {!loading && (
            <AnimatedList>
              {participants.items.map((contact) => (
                <ContactItem key={contact.id} contact={contact} add={false} more note />
              ))}
            </AnimatedList>
          )}
        </>
      )}
    </ExtensiblePanel>
  )
}

const StyledSearchContact = styled.div<{ open: boolean }>`
  & {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: ${WHITE};
    transition: all 0.4s;
    visibility: ${(props) => `${props.open ? 'visible' : 'hidden'}`};
    height: ${(props) => `${props.open ? 'auto' : '0'}`};
    opacity: ${(props) => `${props.open ? '1' : '0'}`};
    transform: ${(props) => `${props.open ? 'translateY(0)' : 'translateY(-100%)'}`};
    z-index: 8;
    border-bottom: 1px solid ${GRAY_BORDER};

    .MuiInputBase-root {
      padding: ${(props) => `${props.open ? '10px 15px' : '0 15px'}`};
    }
    .MuiFormControl-root,
    .MuiInputBase-root {
      width: 100%;
      margin: 0;
    }
  }
`

export default VisitorsPanel
