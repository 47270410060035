import styled from '@emotion/styled'
import { alpha, Badge, Tab, Tabs } from '@material-ui/core'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import {
  setCurrentConversation
} from '../../../store/chat'
import { BLACK_BLUE } from '../../../theme/colors'
import UserAvatar from '../UserAvatar/UserAvatar'
import Chat from './Chat'

const a11yProps = (index: any) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

interface IChatTabsProps {
  fullscreen?: boolean
}

const ChatTabs: React.FC<IChatTabsProps> = ({ fullscreen = false }) => {
  const intl = useIntl()
  const conversations = useSelector((state: IRootState) => state.chatState.conversations)
  const minimize = useSelector((state: IRootState) => state.chatState.minimizeChat)
  const unread = useSelector((state: IRootState) => state.chatState.unread)
  const loadedConversations = useSelector(
    (state: IRootState) => state.chatState.loadedConversations
  )
  const currentConversation = useSelector(
    (state: IRootState) => state.chatState.currentConversation
  )
  const dispatch = useThunkDispatch()

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(setCurrentConversation(newValue))
  }

  return (
    <StyledChatTabs minimize={minimize} fullscreen={fullscreen}>
      {currentConversation !== -1 && (
        <StyledTabs
          TabIndicatorProps={{
            style: {
              width: '5px'
            }
          }}
          orientation="vertical"
          value={currentConversation}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label={intl.formatMessage({ id: 'ariaLabel.Conversation.Tabs' })}
          style={{ width: '70px' }}
        >
          {conversations.map((conversation, index) => (
            <Tab
              key={index}
              label={
                <StyledBadge
                  badgeContent={
                    unread.filter((ur) => ur.author.id === conversation.contact.id).length
                  }
                  color="primary"
                >
                  <UserAvatar
                    popper={{ placement: 'left' }}
                    showDetails
                    disabledAnimation
                    user={conversation.contact.userPresence.user}
                  />
                </StyledBadge>
              }
              wrapped
              value={conversation.contact.id}
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>
      )}
      {!minimize && (
        <ChatContainer>
          {conversations.map((conversation, index) => (
            <Chat
              key={index}
              conversation={conversation}
              hidden={currentConversation !== conversation.contact.id}
            />
          ))}
          {loadedConversations && conversations.length === 0 && (
            <EmptyConversationMessage>
              <span>
                <FormattedMessage id="no.conversations" />
              </span>
            </EmptyConversationMessage>
          )}
          {/* {loadingConversations && (
            <EmptyConversationMessage>
              <Loading position="center" />
            </EmptyConversationMessage>
          )} */}
        </ChatContainer>
      )}
    </StyledChatTabs>
  )
}

const StyledChatTabs = styled.div<{ minimize: boolean; fullscreen: boolean }>`
  & {
    position: relative;
    display: flex;
    flex-direction: row;
    height: ${(props) => (props.minimize ? '0' : props.fullscreen ? '100%' : 'calc(100% - 51px)')};
    width: ${(props) => (props.fullscreen ? 'calc(100% + 2*24px)' : '100%')};
    margin-left: ${(props) => (props.fullscreen ? '-24px' : '0')};
    transition: all 0.3s;
  }
`

const ChatContainer = styled.div`
  & {
    display: flex;
    height: 100%;
    width: calc(100% - 70px);
    padding: 5px;
  }
`

const EmptyConversationMessage = styled.div`
  & {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    > span {
      margin-right: 50px;
      text-align: justify;
    }
  }
`

const StyledTabs = styled(Tabs)`
  & {
    background: ${alpha(BLACK_BLUE, 0.05)};

    .MuiTab-root {
      min-width: 50px;
      min-height: 50px;
      padding: 0;
    }
  }
`

const StyledBadge = styled(Badge)`
  & {
    top: 2px;
    right: 2px;
  }
`

export default ChatTabs
