import { IExhibitorUpdate } from '../entities'
import { IConferenceFull, IConferenceUpdate } from '../entities/conference'
import settings from '../settings'
import { apiPatchRequest, apiRequest } from './requests'

export const getConferences = async (
  page: number = 0,
  name?: string,
  sortBy?: string,
  keyword?: string,
  type?: string,
  followed?: boolean,
  matching?: number,
  calendarView?: boolean,
  keywordsX?: string
) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/conf/list?eventGroupId=${settings.eventGroupId}&limit=${
      settings.list.itemsPerPage
    }${keyword && keyword !== 'all' ? `&keyword=${keyword}` : ''}${
      sortBy === 'recommandation'
        ? `&orderByMatching=true&minimumMatching=${matching}`
        : sortBy === 'popularity'
        ? '&orderByReco=true'
        : ''
    }${matching && matching > 0 ? `&orderByMatching=true&minimumMatching=${matching}` : ''}${
      page ? `&page=${page}` : ''
    }${name ? `&search=${name}` : ''}${
      type !== 'all' ? `&nextEventsOnly=true` : '' // only conf en cours ou à venir
    }${followed ? `&followed=${followed}` : ''}${calendarView ? `&calendarView=1` : ''}${
      keywordsX ? `&xKeyword=${keywordsX}` : ''
    }`
  )

  return { items: response['hydra:member'], total: response['hydra:totalItems'] }
}

export const hasConferencesTimeline = async (viewDate: string) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/conf/list?eventGroupId=${settings.eventGroupId}&calendarView=1&calendarViewDate=${viewDate}&limit=1`
  )

  return response['hydra:totalItems'] > 0
}

export const getConferencesTimeline = async (viewDate: string | null) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/conf/list?eventGroupId=${settings.eventGroupId}&calendarView=1${
      viewDate ? `&calendarViewDate=${viewDate}` : ''
    }&limit=150`
  )

  return { items: response['hydra:member'], total: response['hydra:totalItems'] }
}

export const getLives = async () => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/conf/list?eventGroupId=${settings.eventGroupId}&nextEventsOnly=true&limit=100`
  )
  return { items: response['hydra:member'], total: response['hydra:totalItems'] }
}

export const getVods = async () => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/conf/list?eventGroupId=${settings.eventGroupId}&passedEventsOnly=true&limit=100`
  )
  return { items: response['hydra:member'], total: response['hydra:totalItems'] }
}

export const getConference = async (id: string) => {
  const response = await apiRequest<IConferenceFull>('GET', `${settings.url.api}/conf/${id}`)
  return response
}

export const updateConference = async (conferenceId: number, conference: IConferenceUpdate) => {
  const response = await apiPatchRequest<IConferenceUpdate>(
    `${settings.url.api}/conf/${conferenceId}`,
    conference
  )
  return response
}

export const triggerPopin = async (conferenceId: number, conference: IConferenceUpdate) => {
  const response = await apiPatchRequest<IExhibitorUpdate>(
    `${settings.url.api}/conf/${conferenceId}/trigger_popin`,
    conference
  )
  return response
}
