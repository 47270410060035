import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { alpha, IconButton } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRoom, RoomType } from '../../../entities'
import { IStream } from '../../../entities/mediaStream'
import { useMagicStream, useThunkDispatch, useTooltip } from '../../../hooks'
import { IRootState } from '../../../redux'
import { changeLockUnlockRoom, setRoomError } from '../../../store/room'
import { BLACK_BLUE, WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import { formatName } from '../../../utils'
import { breakpoints } from '../../breakpoints'
import Loading from '../../Loading'
import InputText from '../../ui/InputText/InputText'
import Viewers from '../Media/Viewers'
import VideoRoomWaitingList from './VideoRoomWaitingList'

export interface IVideoRoomPanelProps {
  index: number
  room: IRoom
  viewers: number
  isEditable: boolean
  panelChildren: ReactNode
  streams: IStream[]
  onClickRoom: (num: number, broadcasterEnabled?: boolean | undefined) => Promise<boolean>
  handleRenameRoom: (room: IRoom, value: string) => void
}

const VideoRoomPanel: React.FC<IVideoRoomPanelProps> = ({
  index,
  room,
  viewers,
  isEditable,
  panelChildren,
  streams,
  onClickRoom,
  handleRenameRoom
}) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const user = useSelector((state: IRootState) => state.appState.user)
  const roomsLocked = useSelector((state: IRootState) => state.roomState.roomsLocked)
  const roomQueued = useSelector((state: IRootState) => state.roomState.roomQueued)
  const roomUpdating = useSelector((state: IRootState) => state.roomState.roomUpdating)
  const roomError = useSelector((state: IRootState) => state.roomState.roomError)
  const broadcaster = useSelector((state: IRootState) => state.roomState.broadcaster)
  const loadingRoomToken = useSelector((state: IRootState) => state.roomState.loadingRoomToken)
  const fullscreen = useSelector((state: IRootState) => state.standState.fullscreen)
  const [roomLocked, setRoomLocked] = useState(
    roomsLocked[room.id] || (roomsLocked[room.id] === undefined && room.locked) || false
  )
  const dispatch = useThunkDispatch()

  const titleRoom = useMemo(
    () =>
      room.userPresence
        ? `Bureau (${formatName(room.userPresence.user).full})`
        : room.title || room.roomClassName,
    [room]
  )

  const { anchorRef, setOpen, Tooltip } = useTooltip(
    intl.formatMessage({
      id: roomUpdating
        ? 'action.room.updating'
        : roomLocked
          ? 'action.room.unlock'
          : 'action.room.lock'
    })
  )
  const { anchorRef: anchorRefExit, setOpen: setOpenExit, Tooltip: TooltipExit } = useTooltip(
    intl.formatMessage({ id: 'actions.leave.room' }, { title: titleRoom })
  )
  const { anchorRef: anchorRefTitle, setOpen: setOpenTitle, Tooltip: TooltipTitle } = useTooltip(
    intl.formatMessage({ id: 'action.edit.body' })
  )

  const canEdit = useMemo(
    () =>
      (room.userPresence && room.userPresence.user.id === user!.id) ||
      (!room.userPresence && isEditable),
    [isEditable, room, user]
  )

  const showWaitingRoomList = useMemo(() => roomLocked && canEdit, [canEdit, roomLocked])

  const lockUnlockRoom = async () => {
    if (!roomLocked) {
      sendDataToGTM({ event: 'lock-table' })
    }
    setRoomLocked(!roomLocked)
    dispatch(changeLockUnlockRoom(room.id, !roomLocked))
  }

  const onChangeNameTable = (rm: IRoom, value: string) => {
    sendDataToGTM({ event: 'rename-table' })
    handleRenameRoom(rm, value)
  }

  useEffect(() => {
    if (roomError) {
      magicStream.error(roomError)
      setRoomError(undefined)
    }
  }, [roomError, magicStream])

  return (
    <ContainerPanel fullscreen={fullscreen}>
      <Toolbar>
        <span>
          {isEditable ? (
            <InputText
              inputRef={anchorRefTitle}
              onMouseOver={() => setOpenTitle(true)}
              onMouseOut={() => setOpenTitle(false)}
              onClick={() => setOpenTitle(false)}
              onChangeValue={(value: string) => onChangeNameTable(room, value)}
              placeholder={intl.formatMessage({
                id: 'room.name.placeholder'
              })}
              value={titleRoom}
              multiline={false}
              inputProps={{ maxLength: 15 }}
              hideEditable
            />
          ) : (
            <>{titleRoom}</>
          )}
          {isEditable && <TooltipTitle />}
        </span>
        {room.type === RoomType.Broadcast && (broadcaster || streams.length > 0) && (
          <Viewers count={viewers + (broadcaster ? 0 : 1)} max={999} />
        )}
        <Icons>
          {canEdit && isEditable && (
            <>
              <IconButton
                ref={anchorRef}
                onMouseOver={() => setOpen(true)}
                onMouseOut={() => setOpen(false)}
                onClick={lockUnlockRoom}
              >
                {!roomUpdating && roomLocked && <LockIcon />}
                {!roomUpdating && !roomLocked && <LockOpenIcon />}
                {roomUpdating && <Loading size="1rem" />}
              </IconButton>
              <Tooltip />
            </>
          )}
          <IconButton
            ref={anchorRefExit}
            onMouseOver={() => setOpenExit(true)}
            onMouseOut={() => setOpenExit(false)}
            onClick={() => onClickRoom(index)}
          >
            <ExitToAppIcon
              titleAccess={intl.formatMessage({
                id: 'titleAccess.OpenTok.ExitToAppIcon'
              })}
            />
          </IconButton>
          <TooltipExit />
        </Icons>
      </Toolbar>
      {!loadingRoomToken && (
        <ContainerPanelContent>
          {showWaitingRoomList && <VideoRoomWaitingList room={room} />}
          {!roomQueued && (
            <RoomChat>
              <h4>
                <FormattedMessage id="room.chat.title" />
              </h4>
              {panelChildren}
            </RoomChat>
          )}
        </ContainerPanelContent>
      )}
    </ContainerPanel>
  )
}

const ContainerPanel = styled.div<{ fullscreen: boolean }>`
  height: 150px;
  ${(props) => (props.fullscreen ? 'margin-bottom: 90px;' : '')}
  @media (min-width: ${breakpoints.md}) {
    height: auto;
    margin-bottom: 0;
  }
  @media (min-width: ${breakpoints.lg}) {
    flex: 0 0 200px;
  }
  background-color: ${WHITE};
  box-shadow: 0 0 30px 0 ${alpha(BLACK_BLUE, 0.14)};
  border-top-right-radius: ${BORDER_RADIUS_CARD};
  border-bottom-right-radius: ${BORDER_RADIUS_CARD};
`

const Toolbar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
`

const Icons = styled.div`
  display: flex;
  align-items: center;
`

const ContainerPanelContent = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 40px);
  @media (min-width: ${breakpoints.md}) {
    flex-direction: column;
    height: calc(100% - 65px);
  }

  & h4 {
    width: 100%;
    text-align: center;
    margin: 0 !important;
  }
`

const RoomChat = styled.div`
  flex: 1;
  height: calc(100% - 20px);
  @media (min-width: ${breakpoints.md}) {
    height: calc(100% - 100px);
  }
`

export default VideoRoomPanel
