import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IBaseRootState } from '..'
import {
  deleteAllQuestion as deleteApiAllQuestion,
  deleteQuestion as deleteApiQuestion,
  getQuestions,
  postQuestion,
  removeUpVoteQuestion,
  upVoteQuestion
} from '../../api'
import consoleUtils from '../../utils/consoleUtils'
import {
  addQuestion,
  decrementCountUp,
  deleteQuestion,
  incrementCountUp,
  setDeleting,
  setLoading,
  setQuestionError,
  setQuestions,
  setSending,
  setUpdating
} from './actions'

export const loadQuestions = (groupId: number | string) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const questions = await getQuestions(groupId)
    dispatch(setQuestions(questions))
  } catch (e) {
    consoleUtils.error(e)
    dispatch(setQuestionError('Erreur lors de la récupération des questions'))
  }
  dispatch(setLoading(false))
}

export const newQuestion = (question: string, groupId: number | string) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setSending(true))
  try {
    const questionPosted = await postQuestion(question, groupId)
    // add message to conversation
    dispatch(addQuestion(questionPosted))
  } catch (e) {
    consoleUtils.error(e)
    dispatch(setQuestionError(`Erreur lors de l'envoi de la question`))
  }
  dispatch(setSending(false))
}

export const updateQuestion = (id: number, like: boolean) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setUpdating(id, true))
  try {
    if (like) {
      if (await upVoteQuestion(id)) {
        dispatch(incrementCountUp(id))
      }
    } else if (await removeUpVoteQuestion(id)) {
      dispatch(decrementCountUp(id))
    }
  } catch (e) {
    consoleUtils.error(e)
    dispatch(setQuestionError(`Erreur lors de l'update de la question`))
  }
  dispatch(setUpdating(id, false))
}

export const removeQuestion = (id: number) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setUpdating(id, true))
  try {
    await deleteApiQuestion(id)
    dispatch(deleteQuestion(id))
  } catch (e) {
    consoleUtils.error(e)
    dispatch(setQuestionError(`Erreur lors du delete de la question`))
  }
  dispatch(setUpdating(id, false))
}

export const removeAllQuestion = (groupId: number) => async (
  dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>
) => {
  dispatch(setDeleting(true))
  try {
    await deleteApiAllQuestion(groupId)
    dispatch(setQuestions([]))
  } catch (e) {
    consoleUtils.error(e)
    dispatch(setQuestionError(`Erreur lors du delete des questions`))
  }
  dispatch(setDeleting(false))
}
