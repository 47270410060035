import React from 'react'

const SvgLock: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill, stroke, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
      <path
        fill={fill || 'currentColor'}
        fillRule="evenodd"
        d="M36.866 13.541H34.88V8.753C34.88 3.926 30.953 0 26.127 0h-1.795c-4.827 0-8.754 3.926-8.754 8.753v4.788h-1.986A6.6 6.6 0 0 0 7 20.134v23.274A6.6 6.6 0 0 0 13.592 50h23.274a6.6 6.6 0 0 0 6.592-6.592V20.134a6.598 6.598 0 0 0-6.592-6.593zM17.96 8.753a6.38 6.38 0 0 1 6.372-6.372h1.795A6.379 6.379 0 0 1 32.5 8.753v4.788H17.96V8.753zm23.118 34.655a4.217 4.217 0 0 1-4.212 4.211H13.593a4.217 4.217 0 0 1-4.212-4.211V20.134a4.216 4.216 0 0 1 4.212-4.212h23.273a4.217 4.217 0 0 1 4.212 4.212v23.274zM25.229 26.37a4.68 4.68 0 0 0-4.675 4.675 4.68 4.68 0 0 0 3.485 4.516v4.064h2.381v-4.064a4.678 4.678 0 0 0 3.485-4.516 4.68 4.68 0 0 0-4.676-4.675zm0 6.97a2.297 2.297 0 0 1-2.293-2.295 2.297 2.297 0 0 1 2.293-2.294 2.297 2.297 0 0 1 2.294 2.294 2.297 2.297 0 0 1-2.294 2.295z"
      />
    </svg>
  )
}

export default SvgLock
