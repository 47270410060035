import React from 'react'

const SvgArrowLongLeft: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M.94 26.664l14.542 14.543c.485.484 1.091.727 1.697.727.606 0 1.212-.243 1.697-.727.97-.97.97-2.424 0-3.394L8.453 27.391h39.123A2.431 2.431 0 0 0 50 24.967a2.431 2.431 0 0 0-2.424-2.424H8.453l10.423-10.422c.97-.97.97-2.424 0-3.394-.97-.97-2.424-.97-3.394 0L.94 23.27l-.363.364s-.122.121-.122.242-.12.121-.12.243c0 .12 0 .12-.122.242 0 .121 0 .121-.121.242-.121.364-.121.606 0 .97 0 .121 0 .121.121.242 0 .121 0 .121.121.243 0 .12.121.12.121.242 0 .121.122.121.122.242.12-.12.242 0 .363.122z"
    />
  </svg>
)

export default SvgArrowLongLeft
