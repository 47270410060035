import { Popper, Typography } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { WHITE } from '../theme/colors'
import { BOX_SHADOW } from '../theme/sizes'

export const useTooltip = (message: string) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<any>(null)

  const Tooltip = () => {
    return (
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom"
        disablePortal={false}
        keepMounted
        style={{
          backgroundColor: WHITE,
          width: 200,
          padding: 5,
          borderRadius: 5,
          textAlign: 'center',
          boxShadow: BOX_SHADOW,
          zIndex: 1501
        }}
      >
        <Typography>{message}</Typography>
      </Popper>
    )
  }

  return {
    anchorRef,
    setOpen,
    Tooltip
  }
}
