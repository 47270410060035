import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { default as React, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import imageBg from '../../../assets/images/404.jpg'
import astro from '../../../assets/images/astro.png'
import imageLogoIdealco from '../../../assets/images/logo-idealco.png'
import planet from '../../../assets/images/planet.png'
import { PRIMARY_COLOR } from '../../../theme'
import { WHITE } from '../../../theme/colors'
import { breakpoints } from '../../breakpoints'

interface IErrorDisplayProps {
  title: ReactNode
  content: ReactNode
}

const ErrorDisplay: React.FC<IErrorDisplayProps> = ({ title, content }) => {
  return (
    <BackgroundContainer>
      <AstroImage src={astro} />
      <PlanetImage src={planet} />
      <TextContainer>
        <LogoImage src={imageLogoIdealco} />
        <Title>{title}</Title>
        <Content>{content}</Content>
        <Button href="/" color="primary">
          <FormattedMessage id="back.to.home" />
        </Button>
      </TextContainer>
    </BackgroundContainer>
  )
}

const BackgroundContainer = styled.div`
  & {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-image: url("${imageBg}");
    background-repeat: repeat;
  }
`

const AstroImage = styled.img`
  & {
    position: absolute;
    top: 450px;
    right: 10%;
    width: 300px;
    user-select: none;

    @media (min-width: ${breakpoints.lg}) {
      top: 45%;
      left: 45%;
    }
  }
`

const PlanetImage = styled.img`
  & {
    position: absolute;
    top: 55%;
    right: 70%;
    height: 150px;

    @media (max-width: ${breakpoints.lg}) {
      display: none;
    }
  }
`

const TextContainer = styled.div`
  & {
    width: 350px;
    max-width: calc(100vw - 30px);
    margin: 85px auto 0;
    user-select: none;
    z-index: 2;
    text-align: center;
    background-color: ${WHITE};
    padding: 30px;

    @media (min-width: ${breakpoints.lg}) {
      margin: 150px auto 0;
      transform: translateX(-70px);
    }
  }
`

const LogoImage = styled.img`
  & {
    width: 150px;
  }
`

const Title = styled.div`
  & {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bold;
    color: ${PRIMARY_COLOR};
    position: relative;
    font-size: 40px;
  }
`

const Content = styled.div`
  & {
    font-size: 20px;
    margin-bottom: 10px;
  }
`

export default ErrorDisplay
