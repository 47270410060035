import { ReactNode } from 'react'
import { IPresenceUser } from './pusher'
import { IContact, IContactUser, IContactUserPresence, INotificationUser } from './user'

export interface IChat {
  id: number
  user: IPresenceUser
  message: string
  createDate: string
}

export interface IChatChannel {
  id: string
  user: IPresenceUser
  group: string
  updateDate: string
}

export interface IMessage {
  id?: number
  message: string
  createDate: string
  author: IPresenceUser
  recipients: IContactUser[]
  channel: string
  embed?: string
}

export interface IConversation {
  contact: IContact
  messages: IMessage[]
}

export interface ICall {
  author?: INotificationUser
  url?: string
}

export interface INotificationParams {
  url?: string
  user?: INotificationUser
  createDate?: string
  priority?: 'high' | 'low'
}

export enum NotificationType {
  START_LIVE = 'start_live',
  STOP_LIVE = 'stop_live',
  SHOW_RELATED = 'show_related',
  PREMIUM_ANNOUNCE = 'premium_announce',
  GLOBAL_ANNOUNCE = 'global_announce',
  PAGE_ANNOUNCE = 'page_announce'
}

export interface INotification {
  type?: NotificationType
  params?: INotificationParams
  message?: string
}

export enum MagicStreamType {
  INVITATION_SENT = 'invitation_sent',
  INVITATION_RECEIVED = 'invitation_received',
  ANNOUNCE = 'announce',
  ANNOUNCE_PREMIUM = 'announcement',
  APPOINTMENT = 'appointment',
  TABLE_QUEUE = 'table_queue',
  REMINDER_CONFERENCE = 'reminder_conference',
  SUGGEST_CONFERENCE = 'suggest_conference',
  NEW_MESSAGE = 'new_message',
  TOAST_GLOBAL = 'toast_global',
  TOAST_INFO = 'toast_info',
  TOAST_SUCCESS = 'toast_success',
  TOAST_WARNING = 'toast_warning',
  TOAST_ERROR = 'toast_error'
}

export enum MagicStreamStatus {
  WAITING = 'waiting',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  REJECTED = 'rejected',
  INVITED = 'invited',
  RUNNING = 'running'
}

export enum MagicStreamActions {
  ACCEPT = 'accept',
  DECLINE = 'decline',
  RESCHEDULE = 'reschedule'
}

export enum MagicStreamAnnounceSegment {
  SEGMENT_ALL = 'all',
  SEGMENT_GROUP_LIVE = 'group_live',
  SEGMENT_GROUP_LIVE_AND_MATCHING = 'group_live_and_matching',
  SEGMENT_HOME_LIVE = 'home_live'
}

export interface IVideoRoom {
  id: number
  group: {
    id: number
    type: number
    typeId?: number
    typeLabel?: string
  }
  title: string
  locked: boolean
  userPresence: IContactUserPresence
  color: string
}
export interface IMagicStream {
  id: number
  content?: string | ReactNode
  createDate: string
  dueDate?: string
  expirationDate?: string
  writeDate: string
  creatorUser?: INotificationUser
  ctas?: {
    accept: string
    decline: string
    reschedule: string
    video: string
    internal: string
    external: string
    go: string
  }
  status?: MagicStreamStatus
  priority?: 'low' | 'high'
  objectId?: number
  streamType: MagicStreamType
  subjectEvent?: string
  subjectUser?: INotificationUser
  subjectVideoRoom?: IVideoRoom
  clickAction?: () => void
}
