import React from 'react'

const SvgArrowLeft: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M15.001 25.575c.014.598.25 1.169.66 1.603l14.481 15.085a2.42 2.42 0 0 0 3.489-3.356L20.752 25.5l12.879-13.407a2.42 2.42 0 0 0-3.489-3.356L15.661 23.822a2.414 2.414 0 0 0-.66 1.753z"
    />
  </svg>
)

export default SvgArrowLeft
