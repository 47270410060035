import React from 'react'

const SvgCalendar: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M39.192 11.636h-2.709V9.879A.881.881 0 0 0 35.605 9a.881.881 0 0 0-.88.879v1.757H16.788V9.879a.881.881 0 0 0-.88-.879.881.881 0 0 0-.878.879v1.757h-3.221C9.706 11.636 8 13.32 8 15.422v22.134a3.81 3.81 0 0 0 3.808 3.808h27.384A3.81 3.81 0 0 0 43 37.556V15.422c0-2.102-1.706-3.786-3.808-3.786zm2.05 25.92c0 1.128-.915 2.05-2.05 2.05H11.808a2.056 2.056 0 0 1-2.05-2.05V21.374h31.485v16.182zM9.758 19.617v-4.195c0-1.128.916-2.029 2.05-2.029h3.222v1.296c0 .484.396.879.879.879a.881.881 0 0 0 .879-.879v-1.296h17.939v1.296c0 .484.395.879.879.879a.881.881 0 0 0 .878-.879v-1.296h2.71c1.127 0 2.05.9 2.05 2.029v4.195H9.757z"
    />
  </svg>
)

export default SvgCalendar
