import { push } from 'connected-react-router'
import { useDispatch, useStore } from 'react-redux'
import { IRootState } from '../redux'

export const useRouter = () => {
  const store = useStore<IRootState>()
  const dispatch = useDispatch()

  return {
    ...store.getState().router,
    push: (...args: Parameters<typeof push>) => dispatch(push(...args))
  }
}
