import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Badge } from '@material-ui/core'
import { BadgeProps } from '@material-ui/core/Badge/Badge'
import React from 'react'
import { BLUE_GREEN, WHITE } from '../../../theme/colors'

const BadgeOnline: React.FC<BadgeProps> = ({ children, ...props }) => {
  return <StyledBadge {...props}>{children}</StyledBadge>
}

const rippleAnimation = keyframes`
  0% {
    transform: scale(.8);
    opacity: 1;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
`

const StyledBadge = styled(Badge)`
  span {
    background-color: ${BLUE_GREEN};
    color: ${BLUE_GREEN};
    box-shadow: 0 0 0 2px ${WHITE};

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid ${WHITE};
      content: '';
      animation: ${rippleAnimation} 1.2s infinite ease-in-out;
    }
  }
`

export default BadgeOnline
