import { AvatarProps } from '@material-ui/core'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../redux'
import UserAvatar from '../UserAvatar/UserAvatar'

interface IMyAvatarProps extends AvatarProps {
  avatarRef?: React.RefObject<HTMLDivElement>
  showModerator?: boolean | undefined
  showMe?: boolean | undefined
  hasRollIn?: boolean | undefined
  disabledAnimation?: boolean
}

const MyAvatar: FC<IMyAvatarProps> = ({
  avatarRef,
  showModerator,
  showMe,
  hasRollIn,
  disabledAnimation,
  ...props
}) => {
  const user = useSelector((state: IRootState) => state.appState.user)

  return (
    <UserAvatar
      avatarRef={avatarRef}
      user={user!}
      showMe={showMe}
      showModerator={showModerator}
      hasRollIn={hasRollIn}
      disabledAnimation={disabledAnimation}
      {...props}
    />
  )
}

export default MyAvatar
