import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import React from 'react'
import { ReactComponent as SvgFullScreenExit } from '../../../assets/images/icons/reduce.svg'

interface IProps {
  onClick: () => void
}

const ButtonExitFullScreen: React.FC<IProps> = ({ onClick }) => {
  return (
    <StyledExitFullScreen onClick={onClick} title="Exit fullscreen">
      <SvgFullScreenExit />
    </StyledExitFullScreen>
  )
}

const StyledExitFullScreen = styled(IconButton)`
  color: white;
  align-self: flex-end;
`

export default ButtonExitFullScreen
