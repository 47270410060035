export enum VideoSourceType {
  CAMERA = 'camera',
  SCREEN = 'screen'
}

export enum VideoResolution {
  _960_P = '1280x960',
  _720_P = '1280x720',
  _480_P = '640x480',
  _360_P = '640x360',
  _240_P = '320x240',
  _180_P = '320x180'
}
export type VideoResolutionType =
  | '1280x960'
  | '1280x720'
  | '640x480'
  | '640x360'
  | '320x240'
  | '320x180'

export interface MediaActivity {
  timestamp: number
  talking: boolean
}

export type MediaOptions = {
  connectionEventsSuppressed: boolean
  topic?: string
  username?: string
  password?: string
  mode?: 'viewer' | 'default'
}

export type MediaClient = {}

export type MediaError = {
  name: string
  message: string
}

export type MediaDevice = {
  kind: 'audioInput' | 'videoInput'
  deviceId: string
  label: string
}
export interface ISession {
  //  A Capabilities object that includes information about the capabilities of the client. All properties of the capabilities object
  // are undefined until you have connected to a session and the completion handler for the Session.connect() method has been called
  // without error.
  capabilities: ICapabilities
  // The Connection object for this session. The connection property is only available once the completion handler for the Session.
  // connect() method has been called successfully. See the Session.connect() method and the Connection class.
  connection: IConnection
  // The session ID for this session. You pass this value into the OT.initSession() method when you create the Session object. (Note:
  // a Session object is not connected to the OpenTok server until you call the connect() method of the object and its completion
  // handler is called without error. See the OT.initSession() and the Session.connect() methods.) For more information on sessions
  // and session IDs, see Session creation.
  sessionId: string
}

export interface ICapabilities {
  // Specifies whether you can call the Session.forceDisconnect() method (1) or not (0). To call the Session.forceDisconnect() method,
  // the user must have a token that is assigned the role of moderator.
  forceDisconnect: number
  // Specifies whether you can call the Session.forceUnpublish() method (1) or not (0). To call the Session.forceUnpublish() method,
  // the user must have a token that is assigned the role of moderator.
  forceUnpublish: number
  // Specifies whether you can publish to the session (1) or not (0). The ability to publish is based on a few factors. To publish,
  // the user must have a token that is assigned a role that supports publishing. There must be a connected camera and microphone.
  publish: number
  // Specifies whether you can subscribe to streams in the session (1) or not (0). Currently, this capability is available for all
  // users on all platforms.
  subscribe: number
}

export interface ICreateSessionOptions {
  // (Boolean) Set this property to true to support large interactive video sessions. This prevents the Session object from
  // dispatching connectionCreated and connectionDestroyed events when other clients connect to or disconnect from the
  // session. (Also, the OpenTok server does not send these events to the web client.) For more information, see Suppressing
  // connection events in the OpenTok developer guides.
  connectionEventsSuppressed: boolean
}

export interface IPermission {
  publish: 0 | 1
  subscribe: 0 | 1
  forceUnpublish: 0 | 1
  forceDisconnect: 0 | 1
  supportsWebRTC: 0 | 1
}

export interface IConnection {
  id: string
  // The ID of this connection.
  connectionId: string
  // The timestamp for the creation of the connection. This value is calculated in milliseconds. You can convert this value to a Date
  // object by calling new Date(creationTime), where creationTime is the creationTime property of the Connection object.
  creationTime: number
  // A string containing metadata describing the connection. When you generate a user token, you can define connection data (see the
  // Token creation overview).
  data: string
  capabilities: {
    supportsWebRTC: boolean
  }
  permissions: IPermission
  quality: null
}

export interface IChannel {
  id: string
  type: 'audio' | 'video'
  active: boolean
  orientation: -1 | 0 | 1
  width: number
  height: number
  source: VideoSourceType
  fitMode: 'cover'
}

export interface IVideoDimension {
  width: number
  height: number
  orientation: -1 | 0 | 1
}

export interface IStream {
  id: string
  streamId: string
  name: string
  creationTime: number
  connection: IConnection
  channel: IChannel[]
  publisher: null
  hasAudio: boolean
  hasVideo: boolean
  videoType: VideoSourceType
  defaultFitMode: 'cover'
  videoDimensions: IVideoDimension
  destroyed: false
  frameRate: number
}

export interface IStreamPropertyChanged {
  cancelable: boolean
  changedProperty: string
  newValue: string | boolean
  oldValue: string | boolean
  stream: IStream
  target: ISession
  type: string
}

export type FrameRateType = 1 | 15 | 30 | 7 | undefined

export interface IOTProperties {
  insertDefaultUI?: boolean
  name?: string
  width?: string | number | undefined
  height?: string | number | undefined
  // The following are recommended settings:
  // 8,000 - 12,000 for narrowband (NB) speech
  // 16,000 - 20,000 for wideband (WB) speech
  // 28,000 - 40,000 for full-band (FB) speech
  // 48,000 - 64,000 for full-band (FB) music
  // The default value is 40,000.
  audioBitrate?: number
  audioSource?: string | boolean | MediaStreamTrack | null | undefined
  // The desired frame rate, in frames per second, of the video. Valid values are 30, 15, 7, and 1.
  frameRate?: FrameRateType
  //  "cover" — The video is cropped if its dimensions do not match those of the DOM element. This is the default setting for
  // videos publishing a camera feed.
  // "contain" — The video is letterboxed if its dimensions do not match those of the DOM element. This is the default setting
  // for screen-sharing videos.
  fitMode?: 'cover' | 'contain'
  // These controls include the name display, the audio level indicator, and the microphone control button.
  showControls?: boolean
  // Whether to initially publish audio for the stream (default: true). This setting applies when you pass the Publisher object
  // in a call to the Session.publish() method.
  publishAudio?: boolean
  // Whether to initially publish video for the stream (default: true). This setting applies when you pass the Publisher object
  // in a call to the Session.publish() method.
  publishVideo?: boolean
  videoSource?: string | VideoSourceType | boolean | MediaStreamTrack | null | undefined
  // he desired resolution of the video.
  resolution?: VideoResolution
  // Whether the publisher's video image is mirrored in the publisher's page. The default value is true
  // (the video image is mirrored), except for a screen-sharing video
  mirror?: boolean
  style?: {
    audioLevelDisplayMode?: 'auto' | 'on' | 'off'
    // How to display the audio level indicator. Possible values are: "auto" (the indicator is displayed when the video is
    // disabled), "off" (the indicator is not displayed), and "on" (the indicator is always displayed).
    audioBlockedDisplayMode?: 'auto' | 'on' | 'off'
    // How to display the archive status indicator. Possible values are: "auto" (the indicator is displayed when the session is being
    // recorded), "off" (the indicator is not displayed). If you disable the archive status display indicator, you can display your
    // own user interface notifications based on the archiveStarted and archiveStopped events dispatched by the Session object.
    archiveStatusDisplayMode?: 'auto' | 'off'
    // A URI for an image to display as the background image when a video is not displayed.
    backgroundImageURI?: string
    // How to display the microphone controls Possible values are: "auto" (controls are displayed when the stream is first displayed
    // and when the user mouses over the display), "off" (controls are not displayed), and "on" (controls are always displayed).
    buttonDisplayMode?: 'auto' | 'on' | 'off'
    // Whether to display the stream name. Possible values are: "auto" (the name is displayed when the stream is first displayed and
    // when the user mouses over the display), "off" (the name is not displayed), and "on" (the name is always displayed).
    nameDisplayMode?: 'auto' | 'on' | 'off'
  }
}

export interface IOTSubscriber {
  stream: IStream
  session: {
    subscribe: () => void
    unsubscribe: () => void
  }
  className: string
  properties: IOTProperties
  retry: boolean
  maxRetryAttempts: number
  retryAttemptTimeout: number
  eventHandlers: any
  onSubscribe: () => void
  onError: () => void
}

export interface ISubscriber {
  element: HTMLDivElement
  stream: IStream
  streamId: string
  subscribeToAudio: (value: boolean) => void
  getAudioVolume: () => number
  setAudioVolume: (value: number) => void
}

export interface IAudioLevelUpdatedEvent {
  cancelable: boolean
  audioLevel: number
  target: ISubscriber
  type: string
}

export interface IOpentokSettings {
  sessionId: string
  token: string
}

export enum QualityErrors {
  // The updateCallback parameter is invalid. It must be a function that accepts a single parameter.
  INVALID_ON_UPDATE_CALLBACK = 'INVALID_ON_UPDATE_CALLBACK',
  // The test is running on an unsupported browser (see Supported browsers).
  UNSUPPORTED_BROWSER = 'UNSUPPORTED_BROWSER',
  // The test failed to connect to the test OpenTok session due to a network error.
  CONNECT_TO_SESSION_ERROR = 'CONNECT_TO_SESSION_ERROR',
  // The test failed to connect to the test OpenTok session due to an invalid token.
  CONNECT_TO_SESSION_TOKEN_ERROR = 'CONNECT_TO_SESSION_TOKEN_ERROR',
  // The test failed to connect to the test OpenTok session due to an invalid session ID.
  CONNECT_TO_SESSION_ID_ERROR = 'CONNECT_TO_SESSION_ID_ERROR',
  // The test failed to connect to the test OpenTok session due to a network error.
  CONNECT_TO_SESSION_NETWORK_ERROR = 'CONNECT_TO_SESSION_NETWORK_ERROR',
  // The test failed to obtain media devices (a camera or microphone).
  FAILED_TO_OBTAIN_MEDIA_DEVICES = 'FAILED_TO_OBTAIN_MEDIA_DEVICES',
  // The browser cannot access a microphone.
  NO_AUDIO_CAPTURE_DEVICES = 'NO_AUDIO_CAPTURE_DEVICES',
  // The browser cannot access a camera.
  NO_VIDEO_CAPTURE_DEVICES = 'NO_VIDEO_CAPTURE_DEVICES',
  // The test encountered an unknown error while attempting to publish to a session.
  PUBLISH_TO_SESSION_ERROR = 'PUBLISH_TO_SESSION_ERROR',
  // The test failed to initialize a publisher.
  INIT_PUBLISHER_ERROR = 'INIT_PUBLISHER_ERROR',
  // The test failed to publish to the test session because the client was not connected to the session.
  PUBLISH_TO_SESSION_NOT_CONNECTED = 'PUBLISH_TO_SESSION_NOT_CONNECTED',
  // The test failed to publish to the test session due a permissions error or timeout.
  PUBLISH_TO_SESSION_PERMISSION_OR_TIMEOUT_ERROR = 'PUBLISH_TO_SESSION_PERMISSION_OR_TIMEOUT_ERROR',
  // The test encountered an unknown error while attempting to subscribe to a test stream.
  SUBSCRIBE_TO_SESSION_ERROR = 'SUBSCRIBE_TO_SESSION_ERROR',
  // The test failed to get audio and video statistics for the test stream.
  SUBSCRIBER_GET_STATS_ERROR = 'SUBSCRIBER_GET_STATS_ERROR'
}

export interface IOpentokRecommended {
  frameRate?: FrameRateType
  resolution?: VideoResolution
  videoSource?: boolean
  audioSource?: boolean
}

export type IDevice = {
  kind: 'audioInput' | 'videoInput'
  deviceId: string
  label: string
}
