import { Step, StepLabel, Stepper } from '@material-ui/core'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface IOnboardingStepperProps {
  step: number
  total: number
}

const OnboardingStepper: React.FC<IOnboardingStepperProps> = ({ step, total }) => {
  const intl = useIntl()

  return (
    <Stepper alternativeLabel activeStep={step}>
      {Array(total)
        .fill(0)
        .map((value, index) => (
          <Step key={intl.formatMessage({ id: `onboarding.step${index}.title` })}>
            <StepLabel>
              <FormattedMessage id={`onboarding.step${index}.title`} />
            </StepLabel>
          </Step>
        ))}
    </Stepper>
  )
}

export default OnboardingStepper
