import styled from '@emotion/styled'
import { CONTACTS_MENU_WIDTH, HEADER_HEIGHT, LEFT_MENU_WIDTH } from '../../../theme/sizes'
import { breakpoints } from '../../breakpoints'
import { fullscreenStyle } from './FullscreenStyle'

const RoomContainer = styled.div<{
  opened: boolean
  fixed: boolean
  isMenuMobile: boolean
  height: number | undefined
  fullscreen: boolean
  light: boolean
}>`
  display: ${(props) => (props.opened ? 'block' : 'none')};
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  top: ${(props) => (props.fixed ? HEADER_HEIGHT : '0')};
  left: 5px;
  @media (min-width: ${breakpoints.lg}) {
    top: ${(props) => (props.fixed && !props.fullscreen ? HEADER_HEIGHT : '0')};
    left: ${(props) =>
    props.fixed && !props.fullscreen
      ? props.light
        ? '10px'
        : LEFT_MENU_WIDTH
      : props.fullscreen
        ? '0'
        : '0'};
  }
  height: ${(props) => (props.height && !props.fixed ? `${props.height}px` : '350px')};
  @media (max-width: ${breakpoints.md}) {
    height: 350px;
  }
  width: ${(props) =>
    props.fixed
      ? 'calc((100% - ' +
      (props.isMenuMobile ? '5px' : CONTACTS_MENU_WIDTH) +
      ' - ' +
      (props.isMenuMobile ? '5px' : props.light ? '10px' : LEFT_MENU_WIDTH) +
      '))'
      : 'calc(100% - 10px)'};
  padding: 0 10px;
  z-index: ${(props) => (props.fixed ? 1000 : 'auto')};
  ${(props) => props.fullscreen && fullscreenStyle}
`

export default RoomContainer
