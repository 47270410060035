import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { List, ListItem } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setPageReferral } from '../../store/gtm'
import ProductCard from '../cards/ProductCard'
import AppButton from '../ui/AppButton/AppButton'

interface IExhibitorProductsListProps {
  showButtonMore?: boolean
  showImage?: boolean
  showTags?: boolean
  hasLinkToExhibitor?: boolean
  editable?: boolean
}

const ExhibitorProductsList: React.FC<IExhibitorProductsListProps> = ({
  showButtonMore,
  showImage,
  showTags,
  hasLinkToExhibitor,
  editable
}) => {
  const perPage = 3
  const { sendDataToGTM } = useGTM()
  const [page, setPage] = useState(1)
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const dispatch = useThunkDispatch()

  const count = useMemo(() => exhibitor ? Math.ceil(exhibitor.products.length / perPage) : 0, [exhibitor])

  const handleClick = () => {
    dispatch(setPageReferral(`${pageReferral}::exhibitor-page`))
    sendDataToGTM({ event: 'list-products', productName: exhibitor?.name })
    browserHistory.push(`/produit?page=1&exhibitorId=${exhibitor?.id}`)
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  if (!exhibitor || (!exhibitor.editable && exhibitor.products.length === 0)) return <></>

  return (
    <div>
      <List dense disablePadding>
        {exhibitor.products.slice(3 * (page - 1), 3 * page).map((product) => (
          <ListItem key={product.id} disableGutters>
            <ProductCard
              product={product}
              moderators={exhibitor.moderators}
              showImage={showImage}
              showTags={showTags}
              hasLinkToExhibitor={hasLinkToExhibitor}
              editable={editable}
              lightMode={!exhibitor.enableFeatureRooms}
            />
          </ListItem>
        ))}
        {!exhibitor.enableFeatureRooms && exhibitor.products.length === 0 && (
          <FormattedMessage id="exhibitor.productsList.empty" />
        )}
      </List>
      <StyledCenter>
        {!exhibitor.enableFeatureRooms && (
          <Pagination color="primary" count={count} page={page} onChange={handlePageChange} />
        )}
        {exhibitor.enableFeatureRooms && showButtonMore && exhibitor.products.length > 3 && (
          <AppButton onClick={handleClick} ButtonType="primary">
            <FormattedMessage id="exhibitor.productsList.button" />
          </AppButton>
        )}
      </StyledCenter>
    </div>
  )
}

const StyledCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default ExhibitorProductsList
