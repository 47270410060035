import styled from '@emotion/styled'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { IRoom, RoomType } from '../../../entities'
import { IRootState } from '../../../redux'
import { breakpoints } from '../../breakpoints'
import EditDialog from '../../modals/EditDialog'
import RoomContentEditMode from './RoomContentEditMode'
import RoomEditDialogContent from './RoomEditDialogContent'

interface ISvgBackgroundEditModeProps {
  height: number
  width: number
  svgString: string
  rooms: IRoom[]
}

interface IPosition {
  [key: string]: { top: number; left: number; height: number; width: number }
}

const SvgBackgroundEditMode: React.FC<ISvgBackgroundEditModeProps> = ({
  height,
  width,
  svgString,
  rooms
}) => {
  const intl = useIntl()
  const [doSubmit, setDoSubmit] = useState(false)
  const [roomToEdit, setRoomToEdit] = useState<IRoom | null>(null)
  const [roomPositions, setRoomPositions] = useState<IPosition>({})
  const [chairPositions, setChairPositions] = useState<IPosition>({})
  const roomUpdating = useSelector((state: IRootState) => state.roomState.roomUpdating)

  // init rooms & chairs refs
  const refs: { [key: string]: React.RefObject<SVGRectElement> } = {}
  const divRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {}
  rooms.forEach((room) => {
    refs[`room-${room.id}`] = React.createRef<SVGRectElement>()
    divRefs[`room-div-${room.id}`] = React.createRef<HTMLDivElement>()
  })

  const cssRules = useMemo(
    () =>
      rooms
        .map((room) =>
          room.color ? `#${room.roomClassName} { fill: ${room.color} !important; }` : ''
        )
        .join(''),
    [rooms]
  )

  // init positions of rooms and chairs
  const initPositions = () => {
    if (Object.keys(roomPositions).length === 0 && Object.keys(chairPositions).length === 0) {
      const newRoomPositions: IPosition = {}
      const newChairPositions: IPosition = {}
      Object.keys(refs).forEach((key) => {
        if (refs[key]) {
          const target = refs[key].current as any
          if (target && target.elemRefs[0]) {
            if (key.indexOf('chair-room') > -1) {
              newChairPositions[key] = {
                top: (target.elemRefs[0].y.baseVal.value / height) * 100,
                left: (target.elemRefs[0].x.baseVal.value / width) * 100,
                height: (target.elemRefs[0].height.baseVal.value / height) * 100,
                width: (target.elemRefs[0].width.baseVal.value / width) * 100
              }
            } else {
              newRoomPositions[key] = {
                top: (target.elemRefs[0].y.baseVal.value / height) * 100,
                left: (target.elemRefs[0].x.baseVal.value / width) * 100,
                height: (target.elemRefs[0].height.baseVal.value / height) * 100,
                width: (target.elemRefs[0].width.baseVal.value / width) * 100
              }
            }
          }
        }
      })
      setRoomPositions(newRoomPositions)
      setChairPositions(newChairPositions)
    }
  }

  // find all positions of room/chair into svg
  const onSVGReady = () => {
    if (refs) {
      initPositions()
    }
  }

  return (
    <StyledSvgBackgroundEditModeContainer cssRules={cssRules}>
      <StyledSvgLoaderContainer>
        <SvgLoader svgXML={svgString} onSVGReady={onSVGReady}>
          {rooms.map((room, index) => (
            <SvgProxy
              key={`room-${index}`}
              ref={refs[`room-${room.id}`]}
              selector={`#${room.roomClassName}`}
              fill={'transparent'}
            />
          ))}
        </SvgLoader>
        {rooms.map(
          (room, index) =>
            roomPositions[`room-${room.id}`] &&
            room.type !== RoomType.Lobby && (
              <StyledRoom
                ref={divRefs[`room-div-${room.id}`]}
                key={room.id}
                top={roomPositions[`room-${room.id}`].top}
                left={roomPositions[`room-${room.id}`].left}
                width={roomPositions[`room-${room.id}`].width}
                height={roomPositions[`room-${room.id}`].height}
                onClick={() => {
                  setRoomToEdit(room)
                }}
              >
                <RoomContentEditMode
                  room={room}
                  divRef={divRefs[`room-div-${room.id}`]}
                  setRoomToEdit={setRoomToEdit}
                />
              </StyledRoom>
            )
        )}
        {roomToEdit && (
          <EditDialog
            open
            title={intl.formatMessage({ id: 'edit.dialog.room.infos' })}
            saveLoading={roomUpdating}
            saveEvent={() => {
              setDoSubmit(true)
            }}
            cancelEvent={() => {
              setRoomToEdit(null)
            }}
          >
            <RoomEditDialogContent
              room={roomToEdit}
              doSubmit={doSubmit}
              setDoSubmit={setDoSubmit}
              onSubmit={() => {
                setRoomToEdit(null)
              }}
            />
          </EditDialog>
        )}
      </StyledSvgLoaderContainer>
    </StyledSvgBackgroundEditModeContainer>
  )
}

const StyledSvgBackgroundEditModeContainer = styled.div<{ cssRules: string }>`
  ${(props) => props.cssRules};
`

const StyledSvgLoaderContainer = styled.div`
  & {
    position: relative;
    z-index: 0;
  }
`

const StyledRoom = styled.div<{
  top: number
  left: number
  width: number
  height: number
}>`
  & {
    position: absolute;
    top: ${(props) => `${props.top}%`};
    left: ${(props) => `${props.left}%`};
    width: ${(props) => `${props.width}%`};
    height: ${(props) => `${props.height}%`};
    cursor: pointer;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    & .room-title {
      display: none;
    }

    & .room-button-leave.MuiButton-colorInherit {
      background-color: red;
      color: white;
    }

    & button {
      white-space: nowrap;
    }

    /* show button enter in first index */
    :hover {
      & .room-button-enter,
      & .room-button-leave,
      & .room-button-bo,
      & .room-button-invite {
        z-index: 1;
      }
    }

    /* play only with display on md+ screen */
    @media (min-width: ${breakpoints.md}) {
      & .room-title {
        display: block;
      }
      & .room-button-enter {
        display: none;
      }

      :hover {
        & .room-title {
          display: none;
        }
        & .room-button-enter {
          display: block;
        }
      }
    }
  }
`

export default SvgBackgroundEditMode
