import React, { ReactNode, ReactNodeArray, useCallback, useEffect, useState } from 'react'
import { IStream, MediaOptions } from '../entities'
import { IMediaStream } from './IMediaStream'

interface IStreamSessionProps {
  mediaStream: IMediaStream | null
  apiKey: string
  sessionId: string
  token: string
  eventHandlers?: { [key: string]: () => void }
  options?: MediaOptions
  children: ReactNode | ReactNodeArray
  onConnect?: () => void
  onError?: () => void
}

export const StreamContext = React.createContext<{
  mediaStream: IMediaStream | null
  streams: IStream[]
}>({
  mediaStream: null,
  streams: []
})

export const StreamSession: React.FC<IStreamSessionProps> = ({
  mediaStream,
  apiKey,
  sessionId,
  token,
  eventHandlers,
  onConnect,
  onError,
  options,
  children
}) => {
  const [streams, setStreams] = useState<IStream[]>([])

  const createNewSession = useCallback(() => {
    if (mediaStream) {
      mediaStream.preload(() => {
        mediaStream.init({
          apiKey,
          sessionId,
          token,
          onStreamsUpdated: (sts) => {
            setStreams(sts)
          },
          onConnect,
          onError,
          options
        })
        if (eventHandlers) {
          mediaStream.connect(eventHandlers)
        }
        setStreams(mediaStream.getStreams())
      })
    }
  }, [mediaStream, apiKey, eventHandlers, options, sessionId, token, onConnect, onError])

  const destroySession = useCallback(() => {
    if (mediaStream) {
      mediaStream.disconnect()
    }
  }, [mediaStream])

  useEffect(() => {
    createNewSession()

    return () => {
      destroySession()
    }
    // to mount and unmount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, sessionId, token])

  return (
    <StreamContext.Provider value={{ mediaStream, streams }}>{children}</StreamContext.Provider>
  )
}
