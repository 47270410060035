import useGTM from '@elgorditosalsero/react-gtm-hook'
import { Container } from '@material-ui/core'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  IChairs,
  IRoom,
  IStream,
  IStreamPropertyChanged,
  MediaError,
  RoomType,
  VideoSourceType
} from '../../../entities'
import { useIsMobile, useMagicStream, useThunkDispatch } from '../../../hooks'
import { IMediaStream } from '../../../media/IMediaStream'
import { OpentokStream } from '../../../media/OpentokStream'
import { ZoomStream } from '../../../media/ZoomStream'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { setVideoSource } from '../../../store/app'
import { setJitsiError } from '../../../store/jitsi'
import { enterRoomJitsi } from '../../../store/jitsi/thunks'
import {
  canAccessRoom,
  enterRoom,
  enterRoomExternal,
  leaveRoom,
  setFullscreenSubscriber,
  setOpentokError,
  setScreenSharingAvailable
} from '../../../store/room'
import { setAutoEnterRoom, setFullscreen } from '../../../store/stand'
import { setZoomError } from '../../../store/zoom'
import { enterRoomZoom } from '../../../store/zoom/thunks'
import consoleUtils from '../../../utils/consoleUtils'
import pusherUtils from '../../../utils/pusherUtils'
import { breakpointsValues } from '../../breakpoints'
import IncomingCall from '../IncomingCall'
import SvgBackground from '../SvgBackground/SvgBackground'
import SvgContainer from '../SvgContainer/SvgContainer'
import RoomContainer from './RoomContainer'
import VideoRoom from './VideoRoom'

interface IProps {
  id: string
  width: number
  height: number
  trigger: boolean
  svgString: string
  isBroadcaster: boolean
  isEditable: boolean
  rooms: IRoom[]
  chairs: IChairs
  totalUsers: number
  refAppPlayer: React.RefObject<HTMLDivElement>
  panelChildren: ReactNode
  activeVideoAudio: (activeAudio: boolean) => void
  onEnterRoom: (index: number) => void
  onLeaveRoom: (index: number) => void
  handleRenameRoom: (room: IRoom, value: string) => void
}

const VideoStand: React.FC<IProps> = ({
  id,
  width,
  height,
  trigger,
  svgString,
  isBroadcaster,
  isEditable,
  rooms,
  chairs,
  totalUsers,
  refAppPlayer,
  panelChildren,
  activeVideoAudio,
  onEnterRoom,
  onLeaveRoom,
  handleRenameRoom
}) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const location = useLocation()
  const handleFullScreen = useFullScreenHandle()
  const magicStream = useMagicStream()
  const isMobileLg = useIsMobile(breakpointsValues.lg)
  const [error, setError] = useState<MediaError>()
  const [active, setActive] = useState(-1)
  const [roomActive, setRoomActive] = useState<IRoom | null>(null)
  const [streams, setStreams] = useState<IStream[]>([])
  const [viewers, setViewers] = useState(0)
  const [heightPlayer, setHeightPlayer] = useState(350)
  const [isRoomReady, setIsRoomReady] = useState(false)
  const [isChangeDetected, setIsChangeDetected] = useState(false)
  const [hasMicro, setHasMicro] = useState<{ [key: string]: boolean }>({})
  const [mediaStream, setMediaStream] = useState<IMediaStream | null>(null)
  const call = useSelector((state: IRootState) => state.chatState.call)
  const zoomError = useSelector((state: IRootState) => state.zoomState.errorZoom)
  const jitsiError = useSelector((state: IRootState) => state.jitsiState.errorJitsi)
  const opentokError = useSelector((state: IRootState) => state.roomState.error)
  const roomToken = useSelector((state: IRootState) => state.roomState.roomToken)
  const streamingActive = useSelector((state: IRootState) => state.roomState.streamingActive)
  const broadcaster = useSelector((state: IRootState) => state.roomState.broadcaster)
  const officeRoomIndex = useSelector((state: IRootState) => state.roomState.officeRoomIndex)
  const screenSharingAvailable = useSelector(
    (state: IRootState) => state.roomState.screenSharingAvailable
  )
  const standChannel = useSelector((state: IRootState) => state.standState.standChannel)
  const chairUid = useSelector((state: IRootState) => state.standState.chairUid)
  const fullscreen = useSelector((state: IRootState) => state.standState.fullscreen)
  const autoEnterRoom = useSelector((state: IRootState) => state.standState.autoEnterRoom)
  const user = useSelector((state: IRootState) => state.appState.user)

  const [currentlyBroadcaster, setCurrentlyBroadcaster] = useState(false)

  const dispatch = useThunkDispatch()

  const mediaPublisher: React.RefObject<any>[] = []
  rooms.forEach((room, index) => {
    mediaPublisher[index] = React.createRef<any>()
  })

  useEffect(() => {
    if (refAppPlayer && refAppPlayer.current) {
      setHeightPlayer(refAppPlayer.current.clientHeight)
    }
  }, [refAppPlayer])

  useEffect(() => {
    if (!screenSharingAvailable && mediaStream) {
      mediaStream.checkScreenSharingCapability(() => {
        // Screen sharing is available.
        dispatch(setScreenSharingAvailable(true))
        mediaStream.registerScreenSharingExtension()
      })
    }
  }, [screenSharingAvailable, mediaStream, dispatch])

  const sessionEventHandlers = {
    sessionConnected: () => {
      setError(undefined)
    },
    sessionDisconnected: (event: any) => {
      consoleUtils.warn('sessionDisconnected > event=', event)
      // The event is defined by the SessionDisconnectEvent class
      if (event.reason === 'networkDisconnected') {
        consoleUtils.info('Your network connection terminated.')
      }
    },
    streamPropertyChanged: (event: IStreamPropertyChanged) => {
      consoleUtils.log(
        '> streamPropertyChanged, changedProperty=',
        event.changedProperty,
        ', newValue=',
        event.newValue
      )
      // TODO: we are notify a stream have changed (orientation, video, audio, ...)
      if (event.changedProperty === 'hasAudio') {
        hasMicro[event.stream.id] = event.newValue as boolean
        setHasMicro(hasMicro)
        setIsChangeDetected(true)
      }
      if (event.changedProperty === 'hasVideo' && !event.newValue) {
        // stop screen sharing
        dispatch(setVideoSource(VideoSourceType.CAMERA))
      }
    }
  }

  const leaveRoomAction = useCallback(
    async (index: number, isChangeRoom: boolean) => {
      if (user && index > -1) {
        if (!isChangeRoom) {
          activeVideoAudio(true)
        }
        await dispatch(
          leaveRoom(
            rooms[index].id,
            chairUid!,
            id,
            standChannel!,
            user,
            isChangeRoom,
            index === officeRoomIndex || officeRoomIndex === -1 // clean data of office room to re enter again
          )
        )
        // disconnectSession()
        if (mediaStream) {
          mediaStream.disconnect()
        }
      }
    },
    [
      id,
      standChannel,
      rooms,
      chairUid,
      user,
      mediaStream,
      officeRoomIndex,
      activeVideoAudio,
      dispatch
    ]
  )

  const checkRoomAccess = useCallback(
    async (room: IRoom, success?: () => void) => {
      await dispatch(canAccessRoom(room, success))
    },
    [dispatch]
  )

  const isBroadcastStream = (stream: IStream) => {
    return stream.hasVideo
  }

  const onClickRoomCommon = useCallback(
    async (index: number, onEnter: (activeTmp: number) => Promise<void>) => {
      if (
        roomToken &&
        !streamingActive &&
        active > -1 &&
        ((rooms[active].type === RoomType.Broadcast && broadcaster) ||
          (active > -1 && rooms[active].type === RoomType.VideoChat))
      ) {
        // stream is loading and remove it directly cause error so we block
        magicStream.info(intl.formatMessage({ id: 'stream.is.starting' }))
        return false
      }
      if (active > -1 && active !== index) {
        if (rooms[index].type !== RoomType.External) {
          if (!window.confirm(intl.formatMessage({ id: 'room.ask.before.change' }))) {
            return false
          }
        }
        // fix in case of invitation to cancel fullscreen
        dispatch(setFullscreen(false))
        dispatch(setFullscreenSubscriber(''))
        try {
          handleFullScreen.exit()
        } catch (e) {
          consoleUtils.warn('Not in fullscreen')
        }
        // magicStream.info(
        //   intl.formatMessage(
        //     { id: 'room.stop.stream' },
        //     { title: `<b>${rooms[active].title || rooms[active].roomClassName}</b>` }
        //   )
        // )
        await leaveRoomAction(active, true)
      }
      setIsRoomReady(false)

      const activeTmp = active
      if (active !== index) {
        //check if table has access control enabled, if so check if the current user cas access
        if (rooms[index].hasAccessControl) {
          await checkRoomAccess(rooms[index], async () => {
            setActive(index)
            setRoomActive(rooms[index])
            await onEnter(activeTmp)
          })
        } else {
          setActive(index)
          setRoomActive(rooms[index])
          await onEnter(activeTmp)
        }
      } else {
        // magicStream.info(
        //   intl.formatMessage(
        //     { id: 'room.stop.stream' },
        //     { title: `<b>${rooms[index].title || rooms[index].roomClassName}</b>` }
        //   )
        // )
        await leaveRoomAction(index, false)
        setActive(-1)
        setRoomActive(null)
        rooms.forEach((room) => (room.active = false))
        setStreams([])
        setMediaStream(null)
        onLeaveRoom(index)
        try {
          handleFullScreen.exit()
        } catch (e) {
          consoleUtils.warn('Not in fullscreen')
        }
      }
      return true
    },
    [
      intl,
      rooms,
      active,
      roomToken,
      broadcaster,
      streamingActive,
      handleFullScreen,
      magicStream,
      leaveRoomAction,
      onLeaveRoom,
      checkRoomAccess,
      dispatch
    ]
  )

  // click on room
  const onClickRoom = useCallback(
    async (index: number, broadcasterEnabled?: boolean) => {
      /*if (active !== index && rooms[index].type === RoomType.External) {
        // open direct external
        window.open(rooms[index].sessionId, '_blank')
      }*/
      return onClickRoomCommon(index, async (activeTmp: number) => {
        if (active !== index && rooms[index].type === RoomType.External) {
          // open direct external
          window.open(rooms[index].sessionId, '_blank')
        }
        if (
          rooms[index].type === RoomType.Zoom ||
          rooms[index].type === RoomType.ZoomMeeting ||
          rooms[index].type === RoomType.ZoomWebinar
        ) {
          // instance of mediaStream zoom
          const zoomStream = new ZoomStream()
          zoomStream.preload(async () => {
            setMediaStream(zoomStream)
            // enter to room zoom
            await dispatch(enterRoomZoom(index, rooms[index].id, id, standChannel!, user!))
          })
          sendDataToGTM({
            event: 'enter-room',
            roomTitle: `${rooms[index].title}`,
            roomType: `${rooms[index].type}`
          })
        } else if (
          rooms[index].type === RoomType.JitsiVideoChat ||
          rooms[index].type === RoomType.JitsiBroadcast
        ) {
          // enter to room jitsi
          await dispatch(
            enterRoomJitsi(index, rooms[index].id, id, standChannel!, user!, broadcasterEnabled)
          )
        } else if (rooms[index].type === RoomType.External) {
          // enter to room external
          await dispatch(enterRoomExternal(index, rooms[index].id, id, standChannel!, user!))
        } else {
          if (rooms[index].type === RoomType.Broadcast) {
            setCurrentlyBroadcaster(broadcasterEnabled!)
          }
          // instance of mediaStream opentok
          const opentokStream = new OpentokStream()
          opentokStream.preload(async () => {
            setMediaStream(opentokStream)
            // inactive all rooms
            rooms.forEach((room) => (room.active = false))
            setStreams([])
            // call api to get token to join video
            await dispatch(
              enterRoom(index, rooms[index].id, id, standChannel!, user!, broadcasterEnabled)
            )
          }, location)
        }
      })
    },
    [id, rooms, active, standChannel, user, location, onClickRoomCommon, sendDataToGTM, dispatch]
  )

  useEffect(() => {
    let timer: number
    if (active > -1 && !rooms[active].active) {
      timer = window.setTimeout(() => {
        magicStream.info(
          intl.formatMessage(
            { id: 'auto.enter.room.starting' },
            { title: `<b>${rooms[active].title || rooms[active].roomClassName}</b>` }
          )
        )
        rooms[active].active = true
        activeVideoAudio(false)
        setIsRoomReady(true)
        setError(undefined)
        onEnterRoom(active)
      }, 500)
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [intl, rooms, active, mediaPublisher, broadcaster, activeVideoAudio, onEnterRoom, magicStream])

  const onStreamsUpdated = useCallback(
    (sts: IStream[]) => {
      if (rooms[active].type === RoomType.VideoChat) {
        const connections: { [key: string]: number } = {}
        // keep only last stream camera
        sts.forEach((stream) => {
          if (
            stream.videoType === VideoSourceType.CAMERA &&
            (!connections[stream.connection.id] ||
              stream.creationTime > connections[stream.connection.id])
          ) {
            connections[stream.connection.id] = stream.creationTime
          }
        })
        // keep screen sharing and last stream by connection
        const uniqueStream = sts.filter((stream) => {
          return (
            stream.videoType !== VideoSourceType.CAMERA ||
            connections[stream.connection.id] === stream.creationTime
          )
        })
        setStreams(uniqueStream)
      } else {
        const streamAvailable = sts.filter(isBroadcastStream)
        setViewers(sts.length - streamAvailable.length)
        setStreams(streamAvailable)
      }
    },
    [active, rooms]
  )

  // start opentok session when has roomToken
  useEffect(() => {
    if (
      active > -1 &&
      (rooms[active].type === RoomType.VideoChat || rooms[active].type === RoomType.Broadcast) &&
      mediaStream &&
      !mediaStream.isInit() &&
      roomToken
    ) {
      mediaStream.init({
        apiKey: settings.opentok.apiKey,
        sessionId: rooms[active].sessionId,
        token: roomToken,
        options: {
          connectionEventsSuppressed: true,
          mode:
            rooms[active].type === RoomType.Broadcast && !currentlyBroadcaster
              ? 'viewer'
              : 'default'
        },
        onStreamsUpdated,
        onError: (err: MediaError) => {
          setError(err)
        }
      })
      if (sessionEventHandlers) {
        mediaStream.connect(sessionEventHandlers)
      }
      setStreams(mediaStream.getStreams())
    }
  }, [
    rooms,
    active,
    roomToken,
    mediaStream,
    sessionEventHandlers,
    currentlyBroadcaster,
    onStreamsUpdated
  ])

  // remove user from room
  useEffect(() => {
    return () => {
      if (roomActive && roomActive.active) {
        pusherUtils.unsubscribe(`presence-room-${id}-${roomActive.id}`)
      }
    }
  }, [id, roomActive])

  // remove user from chair
  useEffect(() => {
    return () => {
      if (roomActive && roomActive.active && chairUid && user) {
        pusherUtils.unsubscribe(`private-chair-${chairUid}`)
        pusherUtils.unsubscribe(`private-chair-${chairUid}-user-${user.id}`)
      }
    }
  }, [roomActive, chairUid, user])

  // auto enter in room
  useEffect(() => {
    if (
      autoEnterRoom.id === id &&
      autoEnterRoom.roomActive > -1 &&
      autoEnterRoom.roomActive !== active &&
      refAppPlayer.current
    ) {
      dispatch(setAutoEnterRoom({ id: '', roomActive: -1 }))
      magicStream.info(
        intl.formatMessage(
          { id: 'auto.enter.room' },
          {
            title: `<b>${
              rooms[autoEnterRoom.roomActive].title || rooms[autoEnterRoom.roomActive].roomClassName
            }</b>`
          }
        )
      )
      // simulate enter room
      window.setTimeout(() => {
        onClickRoom(autoEnterRoom.roomActive)
      }, 2500) // 2,5 s before launch
    }
  }, [intl, id, autoEnterRoom, active, refAppPlayer, rooms, onClickRoom, magicStream, dispatch])

  const handleResize = useCallback(() => {
    if (refAppPlayer && refAppPlayer.current) {
      setHeightPlayer(refAppPlayer.current.clientHeight)
    }
  }, [refAppPlayer])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (isChangeDetected) {
        setIsChangeDetected(false)
      }
    }, 1000)
    return () => window.clearTimeout(timer)
  }, [isChangeDetected])

  useEffect(() => {
    return () => {
      if (mediaStream) {
        // change page without hangup
        mediaStream.disconnect()
      }
    }
  }, [mediaStream])

  useEffect(() => {
    if (error && error.message) {
      magicStream.error(error.message)
      setError(undefined)
    }
  }, [error, magicStream])

  useEffect(() => {
    if (zoomError) {
      magicStream.warning(zoomError)
      dispatch(setZoomError())
    }
  }, [zoomError, magicStream, dispatch])

  useEffect(() => {
    if (jitsiError) {
      magicStream.warning(jitsiError)
      dispatch(setJitsiError())
    }
  }, [jitsiError, magicStream, dispatch])

  useEffect(() => {
    if (opentokError) {
      magicStream.warning(opentokError)
      dispatch(setOpentokError())
    }
  }, [opentokError, magicStream, dispatch])

  return (
    <Container>
      <SvgContainer>
        <SvgBackground
          id={id}
          height={height || 1920}
          width={width || 1920}
          svgString={svgString}
          rooms={rooms}
          chairs={chairs}
          onClickRoom={onClickRoom}
          active={active}
          isEditable={isEditable}
          isBroadcaster={isBroadcaster}
          totalUsers={totalUsers}
          handleRenameRoom={handleRenameRoom}
        />
      </SvgContainer>
      <FullScreen
        handle={handleFullScreen}
        onChange={(isFullscreen) => {
          if (!isFullscreen) {
            dispatch(setFullscreen(isFullscreen))
            dispatch(setFullscreenSubscriber(''))
          }
        }}
      >
        <RoomContainer
          fixed={trigger}
          opened={isRoomReady}
          isMenuMobile={isMobileLg}
          height={heightPlayer}
          fullscreen={fullscreen}
          light={window.lightTheme}
        >
          {rooms.map(
            (room, index) =>
              active === index &&
              isRoomReady && (
                <VideoRoom
                  key={index}
                  index={index}
                  active={active}
                  room={room}
                  rooms={rooms}
                  isEditable={isEditable}
                  panelChildren={panelChildren}
                  viewers={viewers}
                  mediaStream={mediaStream}
                  mediaPublisher={mediaPublisher}
                  streams={streams}
                  hasMicro={hasMicro}
                  isChangeDetected={isChangeDetected}
                  handleFullscreen={handleFullScreen}
                  onClickRoom={onClickRoom}
                  handleRenameRoom={handleRenameRoom}
                />
              )
          )}
        </RoomContainer>
        {fullscreen && call && call.url && <IncomingCall mode="small" />}
      </FullScreen>
    </Container>
  )
}

export default VideoStand
