import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { alpha } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useSelector } from 'react-redux'
import { addUserLogVideo as addUserLogVideoApi } from '../../api'
import ConferenceImage from '../../components/conference/ConferenceImage'
import AppPlayer from '../../components/globals/AppPlayer/AppPlayer'
import Questions from '../../components/globals/Questions'
import { ConferenceStatus, IConferenceFull, LogUserUrlTypes } from '../../entities'
import { useDateFns, useIsMobile, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { updateConference } from '../../store/conference'
import { setFullscreen, setHandleFullscreenExit } from '../../store/stand'
import { BLACK_BLUE, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import consoleUtils from '../../utils/consoleUtils'
import { breakpointsValues } from '../breakpoints'

interface IConferencePlayerProps {
  conferenceId: string | null
  conference: IConferenceFull
  localGroupId: number
  title?: string
  videoPlaying?: boolean
  videoMuted?: boolean
  videoVolume: number
  editMode?: boolean
  hidden?: boolean
  setVideoVolume?: (val: number) => void
  setVideoPlaying?: (val: boolean) => void
  handleLeave?: () => void
  handleRenameRoom?: (value: string) => void
}

const ConferencePlayer: React.FC<IConferencePlayerProps> = ({
  conferenceId,
  conference,
  localGroupId,
  title,
  videoPlaying,
  videoMuted,
  videoVolume,
  editMode,
  hidden,
  setVideoVolume,
  setVideoPlaying,
  handleLeave,
  handleRenameRoom
}) => {
  const isMobile = useIsMobile(breakpointsValues.lg)
  const handleFullScreen = useFullScreenHandle()
  const [userLogVimeo, setUserLogVimeo] = useState(0)
  const [playerHeight, setPlayerHeight] = useState(0)
  const fullscreen = useSelector((state: IRootState) => state.standState.fullscreen)
  const handleFullscreenExit = useSelector(
    (state: IRootState) => state.standState.handleFullscreenExit
  )
  const dateFns = useDateFns()
  const dispatch = useThunkDispatch()

  const refVideoPlayer = React.createRef<HTMLDivElement>()

  const updateConferenceImage = (base64: string) => {
    if (conference) {
      dispatch(updateConference(conference.id, { imageUrlS3: base64 }))
    }
  }

  const liveInProgress = useMemo(
    () =>
      !!conference &&
      !!conference.eventCoUrlVimeo &&
      conference.status === ConferenceStatus.IN_PROGRESS,
    [conference]
  )

  const vodActive = useMemo(
    () => !!conference && !!conference.vodUrl && conference.status === ConferenceStatus.ARCHIVE,
    [conference]
  )

  const addOverlayConditionMemo = useMemo(
    () =>
      conference && conference.start_date
        ? new Date(conference?.start_date).getTime() > new Date().getTime()
        : false,
    [conference]
  )

  const timeStart = useMemo(() => (liveInProgress ? Date.now() : null), [liveInProgress])

  useEffect(() => {
    const height = refVideoPlayer.current && refVideoPlayer.current.clientHeight
    if (height) {
      setPlayerHeight(height)
    }
  }, [refVideoPlayer])

  // log duration on live stream vimeo
  useEffect(() => {
    return () => {
      if (timeStart && conferenceId) {
        const timeStop = Date.now()
        consoleUtils.log(
          'USERLOG LIVE timeStart=',
          timeStart / 1000,
          ', DURATION=',
          (timeStop - timeStart) / 1000,
          's'
        )
        addUserLogVideoApi({
          url: `/conference/${conferenceId}`,
          urlType: LogUserUrlTypes.vimeo,
          objectType: 'event',
          objectId: parseInt(conferenceId, 10),
          timestamp: timeStart / 1000,
          actionDuration: Math.round((timeStop - timeStart) / 1000)
        })
      }
    }
  }, [userLogVimeo, liveInProgress, timeStart, conferenceId])

  // timer to refresh previous useEffect each 10 secondes
  useEffect(() => {
    const timer = window.setTimeout(() => {
      setUserLogVimeo(userLogVimeo + 1)
    }, 10000)

    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [userLogVimeo])

  useEffect(() => {
    if (handleFullscreenExit) {
      try {
        handleFullScreen.exit()
      } catch (e) {
        consoleUtils.error('handleFullScreen.exit() error=', e)
      }
      dispatch(setFullscreen(false))
      dispatch(setHandleFullscreenExit(false))
    }
  }, [handleFullScreen, handleFullscreenExit, dispatch])

  if (!conference) return <></>

  return (
    <FullScreen
      handle={handleFullScreen}
      onChange={(isFullscreen) => {
        dispatch(setFullscreen(isFullscreen))
      }}
    >
      <StyledConferencePlayer isMobile={isMobile}>
        <div>
          {/* div:first-of-type */}
          {liveInProgress && (
            <AppPlayer
              withRightSide={!hidden && !isMobile}
              customControls
              hidden={hidden}
              wrapperRef={refVideoPlayer}
              url={conference.eventCoUrlVimeo}
              controls
              pip
              playing={videoPlaying}
              muted={videoMuted}
              volume={videoVolume}
              handleFullScreen={handleFullScreen}
              setVideoVolume={setVideoVolume}
              setVideoPlaying={setVideoPlaying}
              config={conference.enableVideoControls ? {
                vimeo: {
                  playerOptions: {
                    controls: true
                  }
                }
              } : undefined}
            />
          )}
          {conference.vodUrl && (!liveInProgress || vodActive) && (
            <AppPlayer
              customControls
              hidden={hidden}
              wrapperRef={refVideoPlayer}
              url={conference.vodUrl}
              controls
              pip
              playing={videoPlaying}
              muted={videoMuted}
              volume={videoVolume}
              handleFullScreen={handleFullScreen}
              setVideoVolume={setVideoVolume}
              setVideoPlaying={setVideoPlaying}
              config={conference.enableVideoControls ? {
                vimeo: {
                  playerOptions: {
                    controls: true
                  }
                }
              } : undefined}
            />
          )}
          {!conference.vodUrl && !liveInProgress && !vodActive && !hidden && (
            <StyledConferenceImage ref={refVideoPlayer} isMobile={isMobile}>
              <ConferenceImage
                conference={conference}
                readonly={!editMode}
                onChangeImage={(base64: string) => updateConferenceImage(base64)}
              ></ConferenceImage>
              {addOverlayConditionMemo && (
                <StyledOverlay isMobile={isMobile}>
                  {dateFns.formatDistanceToNow(conference.start_date)}
                </StyledOverlay>
              )}
            </StyledConferenceImage>
          )}
        </div>
        {!hidden && (
          <QuestionsPanel
            fullscreen={fullscreen}
            isMobile={isMobile}
            isImage={!conference.vodUrl && !liveInProgress && !vodActive && !hidden}
            height={playerHeight}
          >
            <Questions
              groupId={localGroupId}
              editMode={conference.editable}
              title={title}
              handleLeave={handleLeave}
              handleRenameRoom={handleRenameRoom}
            />
          </QuestionsPanel>
        )}
      </StyledConferencePlayer>
    </FullScreen>
  )
}

const StyledConferencePlayer = styled.div<{ isMobile: boolean }>`
  width: 100%;
  position: relative;
  ${(props) =>
    !props.isMobile &&
    css`
      display: flex;
      flex-direction: row;
    `}
  & > div:first-of-type {
    ${(props) =>
      !props.isMobile &&
      css`
        flex: 1;
      `};
  }
`

const StyledOverlay = styled.div<{ isMobile: boolean }>`
  & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${alpha(BLACK_BLUE, 0.4)};
    color: ${WHITE};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    border-top-left-radius: ${BORDER_RADIUS_CARD};
    border-top-right-radius: ${(props) => (props.isMobile ? BORDER_RADIUS_CARD : 0)};
    border-bottom-left-radius: ${BORDER_RADIUS_CARD};
    border-bottom-right-radius: ${(props) => (props.isMobile ? BORDER_RADIUS_CARD : 0)};
  }
`

const StyledConferenceImage = styled.div<{ isMobile: boolean }>`
  & {
    position: relative;
    font-size: 10px;
    margin-bottom: ${(props) => (props.isMobile ? 0 : '20px')};

    img {
      width: 100%;
      height: 250px;
      object-fit: contain;
      border-top-left-radius: ${BORDER_RADIUS_CARD};
      border-top-right-radius: ${(props) => (props.isMobile ? BORDER_RADIUS_CARD : 0)};
      border-bottom-left-radius: ${BORDER_RADIUS_CARD};
      border-bottom-right-radius: ${(props) => (props.isMobile ? BORDER_RADIUS_CARD : 0)};
    }
  }
`

const QuestionsPanel = styled.div<{
  fullscreen: boolean
  isMobile: boolean
  isImage: boolean
  height?: number | null
}>`
  ${(props) =>
    props.isMobile
      ? css`
          margin-left: 15px;
        `
      : css`
          flex-basis: ${props.fullscreen ? `350px` : '200px'};
        `};
  ${(props) =>
    props.fullscreen
      ? css`
          height: 100vh;
        `
      : css`
          height: ${props.height && !props.isMobile
            ? `${props.height + (props.isImage ? 0 : 68)}px`
            : '350px'};
          margin-top: ${props.isImage ? 0 : '-20px'};
        `};
  width: calc(100% - 30px);
  background-color: ${WHITE};
  padding-bottom: 5px;
  border-top-right-radius: ${(props) => (props.isMobile ? 0 : BORDER_RADIUS_CARD)};
  border-bottom-left-radius: ${(props) => (props.isMobile ? BORDER_RADIUS_CARD : 0)};
  border-bottom-right-radius: ${BORDER_RADIUS_CARD};
`

export default ConferencePlayer
