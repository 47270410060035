import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IZoomState } from './model'

const initialState: IZoomState = {
  loadingZoom: false,
  zoomRoom: null
}

const actionHandlers: IReducerHandler<IZoomState> = {
  [ActionsTypes.SET_LOADING_ZOOM]: (
    state: IZoomState | undefined,
    { loadingZoom }: AnyAction
  ): IZoomState => ({
    ...state!,
    loadingZoom
  }),

  [ActionsTypes.SET_ZOOM_ROOM]: (
    state: IZoomState | undefined,
    { zoomRoom }: AnyAction
  ): IZoomState => ({
    ...state!,
    zoomRoom
  }),

  [ActionsTypes.SET_ERROR_ZOOM]: (
    state: IZoomState | undefined,
    { errorZoom }: AnyAction
  ): IZoomState => ({
    ...state!,
    errorZoom
  })
}

export const reducer = createReducer(initialState, actionHandlers)
