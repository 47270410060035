import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import React from 'react'
import { IAppLinkValues } from '../entities'
import { ButtonType } from './ui/AppButton/AppButton'

interface IAppLinks {
  values: IAppLinkValues[]
}

const AppLinks: React.FC<IAppLinks> = ({ values }) => {
  if (!values || values.length === 0) return <></>

  return (
    <AppLinksContainer>
      {values.map((value, index) => (
        <Button
          key={index}
          variant="contained"
          href={value.value}
          color={index % 2 === 0 ? ButtonType.Primary : ButtonType.Secondary}
          target={value.target}
        >
          {value.id}
        </Button>
      ))}
    </AppLinksContainer>
  )
}

const AppLinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  width: 100%;

  & > a {
    margin-right: 10px;
  }
`

export default AppLinks
