import styled from '@emotion/styled'
import { alpha, Chip, ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core'
import React, { FC, useEffect, useRef, useState } from 'react'
import { IKeywordUser } from '../../entities/keyword'
import { WHITE } from '../../theme/colors'
import { BOX_SHADOW } from '../../theme/sizes'

interface IContactKeywordsMoreProps {
  keywords: IKeywordUser[]
}

const ContactKeywordsMore: FC<IContactKeywordsMoreProps> = ({ keywords }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [mouseover, setMouseover] = useState(false)

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    let timer: number | null = null
    if (mouseover) {
      timer = window.setTimeout(() => {
        setOpen(true)
      }, 250)
    } else {
      timer = window.setTimeout(() => {
        setOpen(false)
      }, 250)
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [mouseover])

  return (
    <StyledContainer>
      <StyledChipContainer>
        {keywords.map((keyword, index) => (
          <Chip key={index} label={keyword.keyword_id.title} color="primary" />
        ))}
      </StyledChipContainer>
      <StyledContainerEffect
        ref={anchorRef}
        onMouseOver={() => {
          setMouseover(true)
        }}
        onMouseOut={() => {
          setMouseover(false)
        }}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 1401, width: '220px' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledChipContainer>
                  {keywords.map((keyword, index) => (
                    <Chip key={index} label={keyword.keyword_id.title} color="primary" />
                  ))}
                </StyledChipContainer>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  position: relative;
  height: 50px;
  overflow: hidden;
`

const StyledContainerEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, ${alpha(WHITE, 0)} 0%, ${alpha(WHITE, 1)} 100%);
`

const StyledChipContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
  box-shadow: ${BOX_SHADOW};

  & > * {
    margin: 3px;
    height: 20px;
    font-size: 8px;
  }
`

export default ContactKeywordsMore
