import { FormControl, ListSubheader, MenuItem, Select } from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { IFilters, ISubFilters } from '../../../entities/filters'
import { useMagicStream, useThunkDispatch } from '../../../hooks'
import { setErrorConferenceFilters } from '../../../store/conference'
import { setErrorExhibitorFilters } from '../../../store/exhibitor'
import { setErrorUserFilters } from '../../../store/participant'
import Loading from '../../Loading'
import { Label } from '../LabelWrapper/LabelWrapper'

interface ISearchFilters {
  selectFirst?: boolean
  filters: IFilters
  loading?: boolean
  error?: string
  filterCustom: { [key: string]: number | string | { id: number; name: string } }
  onChangeValue?: (value: { [key: string]: number | string | { id: number; name: string } }) => void
}

const SearchFilters: React.FC<ISearchFilters> = ({
  selectFirst,
  filters,
  loading,
  error,
  filterCustom,
  onChangeValue
}) => {
  const magicStream = useMagicStream()
  const dispatch = useThunkDispatch()

  const defaultValue = useMemo(() => {
    const values: { [key: string]: string } = {}
    Object.keys(filters).forEach((key) => {
      values[key] = selectFirst
        ? Array.isArray(filters[key])
          ? (filters[key] as string[])[0]
          : 'all'
        : 'all'
    })
    return values
  }, [filters, selectFirst])

  useEffect(() => {
    Object.keys(filters).forEach((key) => {
      if (
        defaultValue[key] !== 'all' &&
        filterCustom[key] !== 'all' &&
        filterCustom[key] !== defaultValue[key]
      ) {
        onChangeValue &&
          onChangeValue({
            ...filterCustom,
            [key]: defaultValue[key] as number | string | { id: number; name: string }
          })
      }
    })
  }, [filters, filterCustom, defaultValue, onChangeValue])

  useEffect(() => {
    if (error) {
      magicStream.error(error)
      dispatch(setErrorConferenceFilters(undefined))
      dispatch(setErrorExhibitorFilters(undefined))
      dispatch(setErrorUserFilters(undefined))
    }
  }, [error, magicStream, dispatch])

  return (
    <>
      {filters &&
        Object.keys(filters).map((key, index) => (
          <FormControl key={index}>
            <Label>
              <FormattedMessage id={key} />
            </Label>
            {loading ? (
              <Loading />
            ) : (
              <Select
                name={`filters-${key}`}
                onChange={(
                  event: React.ChangeEvent<{
                    name?: string | undefined
                    value: unknown
                  }>
                ) =>
                  onChangeValue &&
                  onChangeValue({
                    ...filterCustom,
                    [key]: event.target.value as number | string | { id: number; name: string }
                  })
                }
                value={filterCustom[key] || defaultValue[key] || 'all'}
              >
                <MenuItem value="all">
                  <FormattedMessage id="filter.all" />
                </MenuItem>
                {Array.isArray(filters[key])
                  ? (filters[key] as any[]).map((value: any, index2: number) => (
                      <MenuItem key={index2} value={value}>
                        {typeof value === 'string' ? value : value.name}
                      </MenuItem>
                    ))
                  : Object.keys(filters[key]).map((subKey, index3) => {
                      const childs = (filters[key] as ISubFilters)[subKey].map((value, index4) => (
                        <MenuItem key={`${subKey}-${index3}-${index4}`} value={value}>
                          {value}
                        </MenuItem>
                      ))
                      childs.unshift(
                        <ListSubheader key={index3}>
                          <FormattedMessage id={subKey} />
                        </ListSubheader>
                      )
                      return childs
                    })}
              </Select>
            )}
          </FormControl>
        ))}
    </>
  )
}

export default SearchFilters
