import styled from '@emotion/styled'
import React from 'react'

export interface ILabelWrapperProps {
  label?: string | React.ReactNode
  className?: string
}

const LabelWrapper: React.FC<ILabelWrapperProps> = ({ label, className, children }) => {
  return (
    <Wrapper className={className}>
      <Label>{label}</Label>
      {children}
    </Wrapper>
  )
}

// language=SCSS
const Wrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
  }
`

// language=SCSS
export const Label = styled.label`
  & {
    font-weight: bold;
    font-size: 18px;
    transform: none;
    line-height: 20px;
  }
`

export default LabelWrapper
