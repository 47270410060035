import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IGtmState } from './model'

const initialState: IGtmState = {
  pageReferral: 'Direct'
}

const actionHandlers: IReducerHandler<IGtmState> = {
  [ActionsTypes.SET_PAGE_REFERRAL]: (
    state: IGtmState | undefined,
    { pageReferral }: AnyAction
  ): IGtmState => ({
    ...state!,
    pageReferral
  })
}

export const reducer = createReducer(initialState, actionHandlers)
