import styled from '@emotion/styled'
import { MenuList } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React, { useEffect } from 'react'
import { IContact } from '../../entities'
import { useThunkDispatch } from '../../hooks'
import { refreshContactInfo } from '../../store/participant/thunks'
import ContactPopperActions from './ContactPopperActions'

type IProps = {
  open: boolean
  anchorRef: React.RefObject<HTMLDivElement> | React.RefObject<HTMLButtonElement>
  contact: IContact
  handleClose: (event: React.MouseEvent<EventTarget>) => void
  handleListKeyDown: ((event: React.KeyboardEvent<HTMLUListElement>) => void) | undefined
}

const ContactMenuPopper: React.FC<IProps> = ({
  open,
  anchorRef,
  contact,
  handleClose,
  handleListKeyDown
}) => {
  const dispatch = useThunkDispatch()

  useEffect(() => {
    if (open && contact.id) {
      dispatch(refreshContactInfo(contact.id))
    }
  }, [open, contact, dispatch])

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      style={{ zIndex: 1300 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <StyledMenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                <ContactPopperActions
                  contact={contact}
                  onClose={handleClose}
                  status={contact.userStatus}
                />
              </StyledMenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

const StyledMenuList = styled(MenuList)`
  padding: 0;
`

export default ContactMenuPopper
