import React from 'react'

const SvgWarning: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 166 142" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.fill || 'currentColor'}
        fillRule="nonzero"
        d="M95.072 12.27L163.9 126.85c2.844 4.735 1.311 10.878-3.423 13.722a10 10 0 01-5.15 1.428H17.673c-5.522 0-10-4.477-10-10a10 10 0 011.428-5.15L77.928 12.27c2.844-4.734 8.987-6.266 13.721-3.422a10 10 0 013.423 3.423z"
        opacity={0.1}
      />
      <path
        fill={props.fill || 'currentColor'}
        fillRule="nonzero"
        d="M88.358 8.3a2 2 0 113.429-2.06l68.827 114.58c3.413 5.682 1.574 13.054-4.107 16.467a12 12 0 01-6.18 1.713H12.673c-6.627 0-12-5.373-12-12a12 12 0 011.714-6.18l24.601-40.954a2 2 0 013.43 2.06L5.814 122.88A8 8 0 0012.672 135h137.656a8 8 0 006.857-12.12L88.358 8.3zm-11.48-2.413a2 2 0 01-2.309-3.266 12 12 0 0117.218 3.62 2 2 0 01-3.43 2.06 8.001 8.001 0 00-11.48-2.413z"
      />
      <path
        fill={props.fill || 'currentColor'}
        fillRule="nonzero"
        d="M88.358 8.3a2 2 0 113.429-2.06L99.51 19.1a2 2 0 11-3.43 2.059L88.359 8.301zm-32.71 31.621a2 2 0 01-3.429-2.06l7.724-12.858a2 2 0 113.429 2.06L55.648 39.92z"
      />
      <g transform="translate(78 60)">
        <path stroke="#FF3E40" strokeLinecap="round" strokeWidth={6} d="M3.5 0v33.5" />
        <circle
          cx={3.5}
          cy={46.5}
          r={3.5}
          fill="#F46580"
          fillRule="nonzero"
          transform="rotate(-180 3.5 46.5)"
        />
      </g>
      <path
        fill={props.fill || 'currentColor'}
        fillRule="nonzero"
        d="M78.5 60a3 3 0 016 0v33.5a3 3 0 01-6 0V60zm3 43a3.5 3.5 0 110 7 3.5 3.5 0 010-7z"
      />
    </g>
  </svg>
)

export default SvgWarning
