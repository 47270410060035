import styled from '@emotion/styled'
import { alpha, IconButton } from '@material-ui/core'
import CallEndIcon from '@material-ui/icons/CallEnd'
import CloseIcon from '@material-ui/icons/Close'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import ScreenShareIcon from '@material-ui/icons/ScreenShare'
import SettingsIcon from '@material-ui/icons/Settings'
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import React, { useState } from 'react'
import { FullScreenHandle } from 'react-full-screen'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgFullScreen } from '../../../assets/images/icons/expand.svg'
import { ReactComponent as SvgFullScreenExit } from '../../../assets/images/icons/reduce.svg'
import { VideoSourceType } from '../../../entities/mediaStream'
import { useThunkDispatch, useTooltip } from '../../../hooks'
import { IMediaStream } from '../../../media/IMediaStream'
import { IRootState } from '../../../redux'
import { setFullscreen } from '../../../store/stand'
import { PRIMARY_COLOR } from '../../../theme'
import { BLACK_BLUE, RED, WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD, CONTACTS_MENU_WIDTH, LEFT_MENU_WIDTH } from '../../../theme/sizes'
import { breakpoints } from '../../breakpoints'
import PublisherSettings from './PublisherSettings'

interface IProps {
  mediaStream?: IMediaStream
  viewer?: boolean
  broadcaster?: boolean
  handlePublishVideo: () => void
  publishVideo: boolean
  handlePublishAudio: () => void
  publishAudio: boolean
  handlePublishVideoSource: () => void
  videoSource: VideoSourceType
  handleLeave?: () => void
  handleFullscreen?: FullScreenHandle
}

const PublisherActions: React.FC<IProps> = ({
  mediaStream,
  viewer,
  broadcaster,
  handlePublishVideo,
  publishVideo,
  handlePublishAudio,
  publishAudio,
  handlePublishVideoSource,
  videoSource,
  handleLeave,
  handleFullscreen
}) => {
  const intl = useIntl()
  const [showSettings, setShowSettings] = useState(false)
  const fullscreen = useSelector((state: IRootState) => state.standState.fullscreen)
  const streamingActive = useSelector((state: IRootState) => state.roomState.streamingActive)
  const screenSharingAvailable = useSelector(
    (state: IRootState) => state.roomState.screenSharingAvailable
  )
  const { anchorRef: anchorRefVideo, setOpen: setOpenVideo, Tooltip: TooltipVideo } = useTooltip(
    intl.formatMessage({
      id: publishVideo ? 'actions.video.disable' : 'actions.video.enable'
    })
  )
  const { anchorRef: anchorRefAudio, setOpen: setOpenAudio, Tooltip: TooltipAudio } = useTooltip(
    intl.formatMessage({
      id: publishAudio ? 'actions.audio.disable' : 'actions.audio.enable'
    })
  )
  const { anchorRef: anchorRefShare, setOpen: setOpenShare, Tooltip: TooltipShare } = useTooltip(
    intl.formatMessage({
      id: videoSource === VideoSourceType.SCREEN ? 'actions.share.disable' : 'actions.share.enable'
    })
  )
  const {
    anchorRef: anchorRefSettings,
    setOpen: setOpenSettings,
    Tooltip: TooltipSettings
  } = useTooltip(
    intl.formatMessage({
      id: showSettings ? 'actions.settings.hide' : 'actions.settings.show'
    })
  )
  const {
    anchorRef: anchorRefFullscreen,
    setOpen: setOpenFullscreen,
    Tooltip: TooltipFullscreen
  } = useTooltip(
    intl.formatMessage({
      id: fullscreen ? 'actions.fullscreen.exit' : 'actions.fullscreen.enter'
    })
  )
  const { anchorRef: anchorRefHangup, setOpen: setOpenHangup, Tooltip: TooltipHangup } = useTooltip(
    intl.formatMessage({
      id: 'actions.hangup'
    })
  )
  const dispatch = useThunkDispatch()

  const toggleSettings = () => {
    setShowSettings(!showSettings)
  }

  const toggleFullscreen = () => {
    dispatch(setFullscreen(!fullscreen))
    try {
      if (handleFullscreen) {
        if (fullscreen) {
          handleFullscreen.exit()
        } else {
          handleFullscreen.enter()
        }
      }
    } catch (e) {
      console.error('e=', e)
    }
  }

  return (
    <StyledPublisherActions active={streamingActive}>
      {showSettings && (
        <StyledPublisherSettings>
          <PublisherSettings mediaStream={mediaStream} />
        </StyledPublisherSettings>
      )}
      <ToolbarActions>
        <ActionsContainer>
          {streamingActive ? (
            <Actions>
              {!broadcaster && !viewer && (
                <>
                  <IconButton
                    ref={anchorRefVideo}
                    onMouseOver={() => setOpenVideo(true)}
                    onMouseOut={() => setOpenVideo(false)}
                    onClick={handlePublishVideo}
                  >
                    {publishVideo ? (
                      <VideocamIcon
                        style={{ color: `${PRIMARY_COLOR}` }}
                        fontSize="large"
                        titleAccess={intl.formatMessage({
                          id: 'titleAccess.VideocamIcon'
                        })}
                      />
                    ) : (
                      <VideocamOffIcon
                        fontSize="large"
                        titleAccess={intl.formatMessage({
                          id: 'titleAccess.VideocamOffIcon'
                        })}
                      />
                    )}
                  </IconButton>
                  <TooltipVideo />
                  <IconButton
                    ref={anchorRefAudio}
                    onMouseOver={() => setOpenAudio(true)}
                    onMouseOut={() => setOpenAudio(false)}
                    onClick={handlePublishAudio}
                  >
                    {publishAudio ? (
                      <MicIcon
                        style={{ color: `${PRIMARY_COLOR}` }}
                        fontSize="large"
                        titleAccess={intl.formatMessage({
                          id: 'titleAccess.MicIcon'
                        })}
                      />
                    ) : (
                      <MicOffIcon
                        fontSize="large"
                        titleAccess={intl.formatMessage({
                          id: 'titleAccess.MicOffIcon'
                        })}
                      />
                    )}
                  </IconButton>
                  <TooltipAudio />
                </>
              )}
              {screenSharingAvailable && !viewer && (
                <>
                  <IconButton
                    ref={anchorRefShare}
                    onMouseOver={() => setOpenShare(true)}
                    onMouseOut={() => setOpenShare(false)}
                    onClick={handlePublishVideoSource}
                  >
                    {videoSource === VideoSourceType.SCREEN ? (
                      <ScreenShareIcon
                        style={{ color: `${PRIMARY_COLOR}` }}
                        fontSize="large"
                        titleAccess={intl.formatMessage({
                          id: 'titleAccess.ScreenShareIcon'
                        })}
                      />
                    ) : (
                      <StopScreenShareIcon
                        fontSize="large"
                        titleAccess={intl.formatMessage({
                          id: 'titleAccess.StopScreenShareIcon'
                        })}
                      />
                    )}
                  </IconButton>
                  <TooltipShare />
                </>
              )}
              {!viewer && (
                <>
                  <IconButton
                    ref={anchorRefSettings}
                    onMouseOver={() => setOpenSettings(true)}
                    onMouseOut={() => setOpenSettings(false)}
                    onClick={toggleSettings}
                  >
                    {showSettings ? (
                      <CloseIcon
                        fontSize="large"
                        titleAccess={intl.formatMessage({
                          id: 'titleAccess.CloseIcon'
                        })}
                      />
                    ) : (
                      <SettingsIcon
                        fontSize="large"
                        titleAccess={intl.formatMessage({
                          id: 'titleAccess.SettingsIcon'
                        })}
                      />
                    )}
                  </IconButton>
                  <TooltipSettings />
                </>
              )}
              <IconButton
                ref={anchorRefFullscreen}
                onMouseOver={() => setOpenFullscreen(true)}
                onMouseOut={() => setOpenFullscreen(false)}
                onClick={toggleFullscreen}
              >
                {fullscreen ? <SvgFullScreenExit /> : <SvgFullScreen />}
              </IconButton>
              <TooltipFullscreen />
            </Actions>
          ) : (
            <Actions>
              <FormattedMessage id="actions.inprogress" />
            </Actions>
          )}
        </ActionsContainer>
        {handleLeave && (
          <HangUpContainer>
            <IconButton
              ref={anchorRefHangup}
              onMouseOver={() => setOpenHangup(true)}
              onMouseOut={() => setOpenHangup(false)}
              onClick={handleLeave}
            >
              <CallEndIcon
                fontSize="large"
                titleAccess={intl.formatMessage({
                  id: 'titleAccess.CallEndIcon'
                })}
              />
            </IconButton>
            <TooltipHangup />
          </HangUpContainer>
        )}
      </ToolbarActions>
    </StyledPublisherActions>
  )
}

const StyledPublisherActions = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: 1500;
  bottom: 10px;
  ${(props) => (props.active ? '' : 'left: calc(50% - (140px + 75px) / 2)')};
  @media (min-width: ${breakpoints.sm}) {
    left: calc(50% - ((${CONTACTS_MENU_WIDTH} + ${LEFT_MENU_WIDTH}) / 2));
  }
  display: flex;
  flex-direction: column;
`

const StyledPublisherSettings = styled.div`
  max-width: 375px;
  background-color: ${WHITE};
  box-shadow: 0 0 30px 0 ${alpha(BLACK_BLUE, 0.14)};
  padding: 10px;
  border-radius: ${BORDER_RADIUS_CARD};
  margin: 0 auto 10px;
`

const ToolbarActions = styled.div`
  display: flex;
  flex-direction: row;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 300px;
  background-color: ${WHITE};
  box-shadow: 0 0 30px 0 ${alpha(BLACK_BLUE, 0.14)};
  padding: 10px;
  border-radius: ${BORDER_RADIUS_CARD};
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const HangUpContainer = styled.div`
  background-color: ${RED};
  box-shadow: 0 0 30px 0 ${alpha(BLACK_BLUE, 0.14)};
  padding: 10px;
  margin-left: 10px;
  border-radius: ${BORDER_RADIUS_CARD};

  & .MuiSvgIcon-root {
    fill: ${WHITE};
  }
`

export default PublisherActions
