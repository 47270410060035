import useGTM from '@elgorditosalsero/react-gtm-hook'
import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Card, IconButton, Tooltip } from '@material-ui/core'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as UserPlus } from '../../assets/images/icons/user-plus.svg'
import { IContact } from '../../entities'
import { useThunkDispatch } from '../../hooks'
import useIsProfilingEnabled from '../../hooks/useIsProfilingEnabled'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { setAgendaInitDate } from '../../store/app'
import { addFavoriteContact } from '../../store/participant/thunks'
import { BLUE_GREEN, GRAY_ICON, PRIMARY, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import ActionSendMessage from '../contacts/actions/ActionSendMessage'
import ActionTakeAppointment from '../contacts/actions/ActionTakeAppointment'
import ContactFullScreen from '../contacts/ContactFullScreen'
import ContactMenuPopper from '../contacts/ContactMenuPopper'
import ContactNoteTag from '../contacts/ContactNoteTag'
import ContactAvatar from '../globals/ContactAvatar/ContactAvatar'
import Loading from '../Loading'
import VisitorInformations from '../visitor/VisitorInformations'

interface IVisitorCardProps {
  visitor: IContact
  extendedNote?: boolean
}

const VisitorCard: React.FC<IVisitorCardProps> = ({ visitor, extendedNote }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const anchorRef = useRef<HTMLDivElement>(null)
  const anchorMenuRef = useRef<HTMLButtonElement>(null)
  const anchorNoteRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [openNote, setOpenNote] = useState(false)
  const user = useSelector((state: IRootState) => state.appState.user)
  const adding = useSelector((state: IRootState) => state.participantState.adding)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const dispatch = useThunkDispatch()

  const handleToggleNote = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault()
    event.stopPropagation()
    if (!openNote) {
      sendDataToGTM({ event: 'show-note', cardType: 'Card visitor' })
    }
    setOpenNote(!openNote)
  }

  const isMe = useMemo(() => user && user.id === visitor.id, [user, visitor])

  const isFavoriteContact = useMemo(() => !!visitor.isContact, [visitor])

  const hasNoteOrTag = useMemo(
    () => !!visitor.note?.id || !!visitor.contactKeywords?.length,
    [visitor]
  )

  const isProfilingEnabled = useIsProfilingEnabled()

  const handleToggleMenu = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault()
    event.stopPropagation()
    if (!openMenu) {
      sendDataToGTM({ event: 'toggle-menu', cardType: 'Card visitor' })
    }
    setOpenMenu(!openMenu)
  }

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
    // reset
    dispatch(setAgendaInitDate(null))
  }

  const handleCloseMenu = (event: React.MouseEvent<EventTarget>) => {
    if (anchorMenuRef.current && anchorMenuRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpenMenu(false)
  }

  const handleAddPerson = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault()
    event.stopPropagation()
    sendDataToGTM({ event: 'add-person', cardType: 'Card visitor' })
    visitor.id && dispatch(addFavoriteContact(visitor.id, false))
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenMenu(false)
    }
  }

  useEffect(() => {
    setOpenNote(!!visitor.note?.id || !!visitor.contactKeywords?.length)
  }, [visitor])

  return (
    <>
      <StyledCard ref={anchorRef} onClick={handleToggle}>
        {isProfilingEnabled && visitor.score && visitor.score > 0 ? (
          <StyledScore>
            <FormattedMessage id="visitor.recommended" values={{ score: visitor.score }} />
          </StyledScore>
        ) : null}
        <StyledContactAvatar>
          <ContactAvatar contact={visitor} />
        </StyledContactAvatar>
        <VisitorInformations isFullDesc={false} visitor={visitor} />
        {!isMe && (
          <StyledButtons withMenu={settings.disableFeatureContacts || !eventCo?.eventcoAppointment}>
            {!settings.disableFeatureContacts && !isFavoriteContact && (
              <StyledIconButton
                title={intl.formatMessage({ id: 'action.add.favorite.contact' })}
                onClick={handleAddPerson}
                color="inherit"
                aria-label={intl.formatMessage({ id: 'action.add.favorite.contact' })}
              >
                {adding === visitor.id ? <Loading /> : <UserPlus />}
              </StyledIconButton>
            )}
            {!settings.disableFeatureContacts && isFavoriteContact && (
              <StyledIconButton ref={anchorNoteRef} onClick={handleToggleNote}>
                <StyledNote hasNote={hasNoteOrTag}>
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'tooltip.ContactNote.LibraryBooksIcon'
                    })}
                    placement="bottom"
                  >
                    <LibraryBooksIcon
                      titleAccess={intl.formatMessage({
                        id: 'titleAccess.ContactNote.LibraryBooksIcon'
                      })}
                    />
                  </Tooltip>
                </StyledNote>
              </StyledIconButton>
            )}
            {eventCo?.eventcoAppointment ? (
              <>
                <ActionTakeAppointment contact={visitor} iconOnly />
                {!settings.disableFeatureChat && <ActionSendMessage contact={visitor} iconOnly />}
              </>
            ) : (
              <StyledIconButton
                ref={anchorMenuRef}
                aria-controls="fade-menu"
                aria-haspopup="true"
                onClick={handleToggleMenu}
                color="inherit"
                aria-label={intl.formatMessage({ id: 'ariaLabel.VisitorCard.MoreVertIcon' })}
              >
                <MoreVertIcon
                  fontSize="large"
                  titleAccess={intl.formatMessage({ id: 'titleAccess.VisitorCard.MoreVertIcon' })}
                />
              </StyledIconButton>
            )}
          </StyledButtons>
        )}
        <ContactMenuPopper
          open={openMenu}
          anchorRef={anchorMenuRef}
          contact={visitor}
          handleClose={handleCloseMenu}
          handleListKeyDown={handleListKeyDown}
        />
      </StyledCard>
      {!settings.disableFeatureContacts && extendedNote && (
        <StyledExtendedNote open={openNote}>
          <ContactNoteTag contact={visitor} showIconNote />
        </StyledExtendedNote>
      )}
      <ContactFullScreen open={open} contact={visitor} handleClose={handleClose} />
    </>
  )
}

const StyledCard = styled(Card)`
  & {
    position: relative;
    z-index: 10;
    margin-bottom: 1em;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
`

const StyledScore = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  color: ${BLUE_GREEN};
  font-weight: bold;
`

const StyledContactAvatar = styled.div`
  & {
    flex: 0 0 60px;
    width: 60px;
  }
`

const StyledButtons = styled.div<{ withMenu: boolean }>`
  & {
    ${(props) =>
      props.withMenu
        ? `
        flex: 0 0 85px;
        width: 85px;
    `
        : `
        flex: 0 0 150px;
        width: 150px;
    `}
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      color: ${GRAY_ICON};
    }
  }
`

const slideInDown = keyframes`
  0% {
    transform: translateY(-100px);
    visibility: visible;
    opacity: 0;
    padding: 15px;
  }
  100% {
    height: auto;
    opacity: 1;
    transform: translateY(-40px);
    padding: 40px 15px 15px;
  }
`
const slideOutDown = keyframes`
  0% {
    transform: translateY(-40px);
    opacity: 1;
    padding: 40px 15px 15px;
  }
  100% {
    visibility: hidden;
    height: 0;
    opacity: 0;
    transform: translateY(-100px);
    padding: 15px;
  }
`

const StyledExtendedNote = styled.div<{ open: boolean }>`
  & {
    position: relative;
    display: flex;
    z-index: 5;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
      0px 1px 3px 0px rgb(0 0 0 / 12%);
    background-color: ${WHITE};
    transform: translateY(-40px);
    margin-bottom: -30px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100% - 100px);
    border-bottom-left-radius: ${BORDER_RADIUS_CARD};
    border-bottom-right-radius: ${BORDER_RADIUS_CARD};
    ${(props) =>
      props.open
        ? css`
            animation: 250ms ease-in 0s ${slideInDown} forwards;
          `
        : props.open === undefined
        ? css`
            display: none;
          `
        : css`
            animation: 250ms ease-in 0s ${slideOutDown} forwards;
          `}

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    & > div > div:first-of-type {
      margin-right: 20px;
    }

    & > div > div:last-of-type {
      min-width: 150px;
    }
  }
`

const StyledNote = styled.span<{ hasNote: boolean }>`
  & {
    svg {
      ${(props) => (props.hasNote ? `color: ${PRIMARY} !important` : '')}
    }
  }
`

const StyledIconButton = styled(IconButton)`
  & {
    min-width: auto;
  }
`

export default VisitorCard
