import { MediaActivity } from '../entities'

const audioDetect = (
  audioLevel: number,
  activity: MediaActivity | null,
  setActivity: (a: MediaActivity | null) => void,
  setTalking: (t: boolean) => void
) => {
  const now = Date.now()
  if (!activity || (activity && now - activity.timestamp > 100)) {
    if (audioLevel > 0.1) {
      if (!activity) {
        setActivity({ timestamp: now, talking: false })
      } else if (activity.talking) {
        setActivity({ ...activity, timestamp: now })
      } else if (now - activity.timestamp > 100) {
        // detected audio activity for more than 1s for the first time.
        setActivity({ ...activity, talking: true })
        setTalking(true)
      }
    } else if (activity && now - activity.timestamp > 1000) {
      // detected low audio activity for more than 1s
      if (activity.talking) {
        setTalking(false)
      }
      setActivity(null)
    }
  }
}

export default audioDetect
