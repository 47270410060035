import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { IContactKeyword } from '../../entities/keyword'
import Tag from './ContactTag'

interface IProps {
  tags: IContactKeyword[]
  onClickTag?: (keyword: IContactKeyword) => void | undefined
  color?: 'default' | 'primary' | undefined
  icon?: ReactNode | undefined
}

const ContactTagsList: React.FC<IProps> = ({ tags, onClickTag, color, icon }) => {
  const handleRemoveTag = (keyword: IContactKeyword) => {
    if (onClickTag) {
      onClickTag(keyword)
    }
  }

  return (
    <Tags className="tags">
      {tags.map((tag, index) => (
        <Tag
          key={index}
          tag={tag}
          onClickTag={onClickTag ? () => handleRemoveTag(tag) : undefined}
          color={color ? color : 'primary'}
          icon={!!icon ? icon : undefined}
        />
      ))}
    </Tags>
  )
}

const Tags = styled.span`
  & {
    display: flex;
    flex-wrap: wrap;
  }
`

export default ContactTagsList
