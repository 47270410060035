import styled from '@emotion/styled'
import { Link } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgVisitor } from '../../assets/images/icons/participants.svg'
import { IContact } from '../../entities'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { formatName } from '../../utils'
import ContactDescriptionMore from '../contacts/ContactDescriptionMore'
import ContactPopper from '../contacts/ContactPopper'
import UserAvatar from '../globals/UserAvatar/UserAvatar'

interface IVisitorInformations {
  visitor: IContact
  isFullDesc?: boolean
  hasUserAvatar?: boolean
}

const VisitorInformations: React.FC<IVisitorInformations> = ({
  visitor,
  isFullDesc,
  hasUserAvatar
}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const descMaxLength = 150

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  return (
    <StyledInformations>
      {hasUserAvatar ? (
        <>
          <StyledContainerNameAvatar ref={anchorRef} onClick={handleToggle}>
            <UserAvatar user={visitor.userPresence.user} disabledAnimation />
            <StyledName>
              {formatName(visitor.userPresence.user).full}
            </StyledName>
          </StyledContainerNameAvatar>
          <ContactPopper
            open={open}
            anchorRef={anchorRef}
            contact={visitor}
            handleClose={handleClose}
          />
        </>
      ) : (
        <StyledName>
          {formatName(visitor.userPresence.user).full}
        </StyledName>
      )}
      {visitor.userPresence.user.description && (
        <StyledUserDescription>
          {!isFullDesc ? (
            <ContactDescriptionMore
              description={visitor.userPresence.user.description}
              maxLength={descMaxLength}
              maxWidth={450}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: visitor.userPresence.user.description }}></div>
          )}
        </StyledUserDescription>
      )}
      {visitor.userPresence.user.function && (
        <StyledUserFunction>{visitor.userPresence.user.function}</StyledUserFunction>
      )}
      {visitor.userPresence.user.partner_id && (
        <StyledEnterprise>{visitor.userPresence.user.partner_id.name}</StyledEnterprise>
      )}
      {!eventCo?.eventcoAppointment &&
        visitor.userStatus &&
        visitor.userStatus.appName === settings.theme.name &&
        visitor.userStatus.description && (
          <StyledLinkLocation href={visitor.userStatus.url}>
            <SvgVisitor />
            <span>{`${visitor.userStatus.description.length > 150
              ? `${visitor.userStatus.description.substring(0, 57)}...`
              : visitor.userStatus.description
              }`}</span>
          </StyledLinkLocation>
        )}
    </StyledInformations>
  )
}

const StyledInformations = styled.div`
  & {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
  }
`

const StyledContainerNameAvatar = styled.div`
  & {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    > div {
      margin-bottom: 0;

      &:nth-of-type(1) {
        flex: 0 0 60px;
        width: 60px;
      }
      &:nth-of-type(2) {
        flex-grow: 1;
      }
    }
  }
`

const StyledName = styled.div`
  & {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 0.5em;
  }
`

// const StyledJob = styled.div`
//   & {
//     font-size: 14px;
//     line-height: 16px;
//     margin-bottom: 0.5em;
//   }
// `

const StyledUserDescription = styled.div`
  & {
    line-height: 14px;
    font-size: 12px;
  }
`

const StyledUserFunction = styled.div`
  & {
    line-height: 14px;
    font-size: 12px;
  }
`

const StyledEnterprise = styled.div`
  & {
    display: block;
    max-height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
`

const StyledLinkLocation = styled(Link)`
  & {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      min-width: 30px;
    }
  }
`

export default VisitorInformations
