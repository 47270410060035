export interface IConsole {
  log: (message?: any, ...optionalParams: any[]) => void
  info: (message?: any, ...optionalParams: any[]) => void
  warn: (message?: any, ...optionalParams: any[]) => void
  error: (message?: any, ...optionalParams: any[]) => void
}

export const ConsoleLevel: { [key: string]: number } = {
  log: 0,
  info: 1,
  warn: 2,
  error: 3
}
