import { IConference, IConferenceFull, IConferenceTimeline, IRelatedContent } from '../../entities/conference'
import { IFilters } from '../../entities/filters'

export enum ActionsTypes {
  SET_CONFERENCES_TOTAL = 'SET_CONFERENCES_TOTAL',
  SET_CONFERENCE_SEARCH = 'SET_CONFERENCE_SEARCH',
  SET_CONFERENCE_LOADING = 'SET_CONFERENCE_LOADING',
  SET_CONFERENCE_EMBEDED_LOADING = 'SET_CONFERENCE_LOADING',
  SET_VODS_LOADING = 'SET_VODS_LOADING',
  SET_VODS_LOADED = 'SET_VODS_LOADED',
  SET_LIVES_LOADING = 'SET_LIVES_LOADING',
  SET_LIVES_LOADED = 'SET_LIVES_LOADED',
  SET_CONFERENCE_UPDATING = 'SET_CONFERENCE_UPDATING',
  SET_CONFERENCES = 'SET_CONFERENCES',
  SET_CONFERENCES_TIMELINE = 'SET_CONFERENCES_TIMELINE',
  SET_CONFERENCE_VIEW_DISPLAY_TYPE = 'SET_CONFERENCE_VIEW_DISPLAY_TYPE',
  SET_CONFERENCE_VIEW_DATE = 'SET_CONFERENCE_VIEW_DATE',
  SET_VODS = 'SET_VODS',
  SET_LIVES = 'SET_LIVES',
  SET_RECOMMENDED_CONFERENCE_LOADING = 'SET_RECOMMENDED_CONFERENCE_LOADING',
  SET_RECOMMENDED_CONFERENCES = 'SET_RECOMMENDED_CONFERENCES',
  SET_CONFERENCE = 'SET_CONFERENCE',
  SET_CONFERENCE_EMBEDED = 'SET_CONFERENCE_EMBEDED',
  SET_CONFERENCE_EDIT_MODE = 'SET_CONFERENCE_EDIT_MODE',
  SET_CONFERENCE_ERROR = 'SET_CONFERENCE_ERROR',
  SET_CONFERENCE_EMBEDED_ERROR = 'SET_CONFERENCE_EMBEDED_ERROR',
  SET_VODS_ERROR = 'SET_VODS_ERROR',
  SET_LIVES_ERROR = 'SET_LIVES_ERROR',
  RESET_CONFERENCE = 'RESET_CONFERENCE',
  MERGE_CONFERENCE = 'MERGE_CONFERENCE',
  MERGE_CONFERENCE_ROOM = 'MERGE_CONFERENCE_ROOM',
  SET_CONFERENCE_FILTERS = 'SET_CONFERENCE_FILTERS',
  SET_ERROR_CONFERENCE_FILTERS = 'SET_ERROR_CONFERENCE_FILTERS',
  SET_LOADING_CONFERENCE_FILTERS = 'SET_LOADING_CONFERENCE_FILTERS',
  UPDATE_CONFERENCES_FAVORITE = 'UPDATE_CONFERENCES_FAVORITE',
  SET_ADDING_CONFERENCE_TO_FAVORITES = 'SET_ADDING_CONFERENCE_TO_FAVORITES',
  SET_ERROR_ADDING_CONFERENCE_TO_FAVORITES = 'SET_ERROR_ADDING_CONFERENCE_TO_FAVORITES',
  SET_REMOVING_CONFERENCE_FROM_FAVORITES = 'SET_REMOVING_CONFERENCE_FROM_FAVORITES',
  SET_ERROR_REMOVING_CONFERENCE_FROM_FAVORITES = 'SET_ERROR_REMOVING_CONFERENCE_FROM_FAVORITES',
  UPDATE_CONFERENCE_ROOM_USERS_ONLINE = 'UPDATE_CONFERENCE_ROOM_USERS_ONLINE',
  SET_OPEN_EXHIBITORS_CHOICE = 'SET_OPEN_EXHIBITORS_CHOICE',
  SET_SENDING_NOTIFICATION_SHOW_RELATED = 'SET_SENDING_NOTIFICATION_SHOW_RELATED',
  SET_RELATED_CONTENT = 'SET_RELATED_CONTENT'
}

export interface IConferenceState {
  error?: string
  errorEmbeded?: string
  errorVods?: string
  errorLives?: string
  totalConferences: number
  vods: { total: number; items: IConference[] }
  lives: { total: number; items: IConference[] }
  searchConferences?: string
  conferences: { total: number; items: IConference[] }
  recommendedConferences: IConference[]
  conference: IConferenceFull | null
  conferenceEmbeded: IConferenceFull | null
  conferencesTimeline: { total: number; items: IConferenceTimeline[] }
  conferenceViewDisplayType: string | null
  conferenceViewDate: string | null
  editMode: boolean
  loading: boolean
  loadingEmbeded: boolean
  loadingVods: boolean
  loadedVods: boolean
  loadingLives: boolean
  loadedLives: boolean
  updating: boolean
  loadingRecommended: boolean
  filters: IFilters
  loadingFilters: boolean
  errorFilters?: string
  addingConferenceToFavorites: { [key: number]: boolean }
  errorAddingConferenceToFavorites?: string
  removingConferenceFromFavorites: { [key: number]: boolean }
  errorRemovingConferenceFromFavorites?: string
  openExhibitorsChoice: {
    open: boolean,
    autoRedirect: boolean
  }
  relatedContent: IRelatedContent
  sendingNotificationShowRelated: boolean
}

export interface IConferenceFilters {
  page?: number
  search?: string
  sortBy?: string
  keyword?: string
  type?: string
  location?: string
  followed?: boolean
  matching?: number
  keywordsX?: string
}
