import styled from '@emotion/styled'
import ChatIcon from '@material-ui/icons/Chat'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IMagicStream } from '../../entities'
import { useDateFns, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { setExtendedPanel } from '../../store/app'
import { setNewMessageClicked } from '../../store/chat'
import { GRAY, VERY_LIGHT_GRAY, WHITE } from '../../theme/colors'

type IStreamNewMessageProps = {
  stream: IMagicStream
}

const StreamNewMessage: React.FC<IStreamNewMessageProps> = ({ stream }) => {
  const dateFns = useDateFns()
  const newMessageClicked = useSelector((state: IRootState) => state.chatState.newMessageClicked)
  const dispatch = useThunkDispatch()

  const clicked = useMemo(() => newMessageClicked[stream.id], [stream, newMessageClicked])

  const onClick = () => {
    if (!clicked) {
      dispatch(setNewMessageClicked(stream.id, true))
      dispatch(setExtendedPanel('magic.streams', false))
      stream.clickAction && stream.clickAction()
    }
  }

  return (
    <StreamNewMessageContainer clicked={clicked} onClick={onClick}>
      <StyledIcon className="icon-hover">
        <ChatIcon fontSize="large" htmlColor="blue" />
      </StyledIcon>
      <StyledContent>
        <StyledText clicked={clicked}>{stream.content}</StyledText>
        <StyledDate>{dateFns.formatDistanceToNow(stream.writeDate || stream.createDate)}</StyledDate>
      </StyledContent>
    </StreamNewMessageContainer>
  )
}

const StreamNewMessageContainer = styled.div<{ clicked: boolean }>`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  font-size: 12px;
  transition: all 0.3s;
  padding: 7px 0;
  ${(props) => (!props.clicked ? 'cursor: pointer;' : '')}

  &:hover {
    span {
      opacity: 1;
    }
    .icon-hover {
      background-color: ${WHITE};
    }
  }
`

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`

const StyledIcon = styled.div`
  background-color: ${VERY_LIGHT_GRAY};
  border-radius: 50%;
  padding: 5px;
`

const StyledText = styled.div<{ clicked: boolean }>`
  ${(props) => props.clicked && 'text-decoration: line-through;'}
`

const StyledDate = styled.span`
  position: absolute;
  right: 3px;
  bottom: 1px;
  opacity: 0;
  font-size: 10px;
  color: ${GRAY};
`

export default StreamNewMessage
