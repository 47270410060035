import { useEffect } from 'react'

export const useScrollEvent = (scrollEvent: () => void) => {
  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', scrollEvent)
      window.addEventListener('resize', scrollEvent)
    }
    watchScroll()
    window.scrollTo(0, 0)
    return () => {
      window.removeEventListener('scroll', scrollEvent)
      window.removeEventListener('resize', scrollEvent)
    }
  }, [scrollEvent])
}
