import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Button, MenuItem } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import {
  setOpenExhibitorsChoice,
  setSendingNotificationShowRelated,
  triggerPopinOnConference
} from '../../store/conference'
import { WHITE } from '../../theme/colors'
import Loading from '../Loading'

interface IConferenceRelatedButtonsProps {
  menuMode?: boolean
}

const ConferenceRelatedButtons: FC<IConferenceRelatedButtonsProps> = ({ menuMode }) => {
  const { sendDataToGTM } = useGTM()
  const conference = useSelector((state: IRootState) => state.conferenceState.conference)
  const sendingNotificationShowRelated = useSelector(
    (state: IRootState) => state.conferenceState.sendingNotificationShowRelated
  )
  const dispatch = useThunkDispatch()

  const showButtons = useMemo(
    () =>
      (conference?.business_event_exhibitors &&
        conference?.business_event_exhibitors.length !== 0) ||
      (conference?.relatedContent &&
        (conference?.relatedContent.items.length !== 0 ||
          conference?.relatedContent.exhibitors.length !== 0)),
    [conference]
  )

  const handleClickRelatedExhibitors = () => {
    sendDataToGTM({ event: 'list-related-exhibitors', label: conference?.title })
    dispatch(setOpenExhibitorsChoice({ open: true, autoRedirect: false }))
  }

  const handleClickShowRelated = () => {
    if (conference) {
      sendDataToGTM({ event: 'list-related-exhibitors', label: conference.title })
      dispatch(setSendingNotificationShowRelated(true))
      dispatch(triggerPopinOnConference(conference.id, conference))
    }
  }

  return (
    <>
      {showButtons && (
        <>
          {menuMode ? (
            <>
              <MenuItem onClick={handleClickRelatedExhibitors}>
                <FormattedMessage id="see.next" />
              </MenuItem>
              <MenuItem onClick={handleClickShowRelated} disabled={sendingNotificationShowRelated}>
                <FormattedMessage id="show.next" />
              </MenuItem>
            </>
          ) : (
            <>
              <div>
                <RelatedButton
                  variant="contained"
                  color="secondary"
                  onClick={handleClickRelatedExhibitors}
                >
                  <FormattedMessage id="see.next" />
                </RelatedButton>
              </div>
              <div>
                <RelatedButton
                  variant="contained"
                  color="secondary"
                  onClick={handleClickShowRelated}
                  disabled={sendingNotificationShowRelated}
                >
                  <FormattedMessage id="show.next" />
                  {sendingNotificationShowRelated && <Loading />}
                </RelatedButton>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

const RelatedButton = styled(Button)`
  & {
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 10px;

    svg {
      height: 20px;
      width: 20px;
      color: ${WHITE};
    }
  }
`

export default ConferenceRelatedButtons
