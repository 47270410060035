import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IMagicStream, MagicStreamStatus } from '../../entities'
import { useDateFns } from '../../hooks'
import { IRootState } from '../../redux'
import { formatName } from '../../utils'
import UserAvatar from '../globals/UserAvatar/UserAvatar'
import StreamCallActions from './StreamCallActions'

type IStreamCallProps = {
  stream: IMagicStream
}

const StreamCall: React.FC<IStreamCallProps> = ({ stream }) => {
  const dateFns = useDateFns()
  const callEnded = useSelector((state: IRootState) => state.chatState.callEnded)

  const expirated = useMemo(
    () =>
      (stream.expirationDate ? new Date() > new Date(stream.expirationDate) : false) ||
      callEnded[stream.id] ||
      stream.status === MagicStreamStatus.ACCEPTED ||
      stream.status === MagicStreamStatus.DECLINED,
    [stream, callEnded]
  )

  return (
    <StreamCallContainer>
      <StreamCallContent>
        {stream.creatorUser && (
          <UserAvatar
            popper={{ placement: 'left' }}
            user={stream.creatorUser}
            showDetails
            disabledAnimation
          />
        )}
        {stream.creatorUser && (
          <StreamCallMessage expirated={expirated}>
            <FormattedMessage
              id="invitation.message"
              values={{
                user: formatName(stream.creatorUser).full,
                date: dateFns.formatDistanceToNow(stream.writeDate || stream.createDate)
              }}
            />
            {/* TODO: utiliser le stream.status 'accepted' ou 'declined' pour afficher qqch */}
          </StreamCallMessage>
        )}
      </StreamCallContent>
      <StreamCallActions stream={stream} expirated={expirated} />
    </StreamCallContainer>
  )
}

const StreamCallContainer = styled.div``

const StreamCallContent = styled.div`
  display: flex;
  font-size: 12px;
  width: 100%;

  & > span {
    white-space: pre-wrap;
    text-align: left;
    margin-left: 12px;
  }
`

const StreamCallMessage = styled.span<{ expirated: boolean }>`
  ${(props) => props.expirated && 'text-decoration: line-through;'}
`

export default StreamCall
