import { IContact, IContactNote } from '../entities'
import { IContactKeyword, IKeyword, IKeywordUser } from '../entities/keyword'
import settings from '../settings'
import { apiPatchRequest, apiPostRequest, apiRequest } from './requests'

export const getKeywords = async () => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/keywords?groupId=${settings.eventGroupId}`
  )
  return {
    items: response['hydra:member'] as IKeyword[],
    total: response['hydra:totalItems'] as number
  }
}

export const getUserKeywords = async (userId: number) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/keyword_users?groupId=${settings.eventGroupId}&userId=${userId}`
  )
  return {
    items: response['hydra:member'] as IKeywordUser[],
    total: response['hydra:totalItems'] as number
  }
}

export const postUserKeywords = async (keywordId: number) => {
  const response = await apiPostRequest(`${settings.url.api}/keyword_users`, {
    keyword_id: `/keywords/${keywordId}`
  })
  return response
}

export const deleteUserKeywords = async (keywordUserId: number) => {
  const response = await apiRequest<boolean>(
    'DELETE',
    `${settings.url.api}/keyword_users/${keywordUserId}`
  )
  return response
}

export const getKeywordsCreatedByUser = async (userId: number, title: string = '') => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/keywords?createdBy=${userId}&title=${title}`
  )
  return {
    items: response['hydra:member'] as IKeyword[],
    total: response['hydra:totalItems'] as number
  }
}

export const getKeywordsByType = async (type: number) => {
  const response = await apiRequest<any>('GET', `${settings.url.api}/keywords?type=${type}`)
  return {
    items: response['hydra:member'] as IKeyword[],
    total: response['hydra:totalItems'] as number
  }
}

export const createKeywordForUser = async (title: string, userId: number) => {
  const response = await apiPostRequest<IKeyword>(`${settings.url.api}/keywords`, {
    title,
    userId
  })
  return response
}

export const deleteKeyword = async (id: number) => {
  const response = await apiRequest<boolean>('DELETE', `${settings.url.api}/keywords/${id}`)
  return response
}

export const editKeyword = async (id: number, title: string) => {
  const response = await apiPatchRequest<IKeyword>(`${settings.url.api}/keywords/${id}`, {
    title
  })
  return response
}

export const addContactKeyword = async (contact: IContact, keyword: IKeyword) => {
  const response = await apiPostRequest<IContactKeyword>(`${settings.url.api}/user_user_keywords`, {
    keyword: `/keywords/${keyword.id}`,
    target: `/users/${contact.id}`
  })
  return response
}

export const removeContactKeyword = async (keyword: IContactKeyword) => {
  const response = await apiRequest<boolean>(
    'DELETE',
    `${settings.url.api}/user_user_keywords/${keyword.id}`
  )
  return response
}

export const addContactNote = async (contact: IContact, note: string) => {
  const response = await apiPostRequest<IContactNote>(`${settings.url.api}/user_user_notes`, {
    note,
    target: `/users/${contact.id}`
  })
  return response
}

export const updateContactNote = async (note: IContactNote, noteNewValue: string) => {
  const response = await apiPatchRequest<IContactNote>(
    `${settings.url.api}/user_user_notes/${note.id}`,
    {
      note: noteNewValue
    }
  )
  return response
}

export const removeContactNote = async (keyword: IContactNote) => {
  const response = await apiRequest<boolean>(
    'DELETE',
    `${settings.url.api}/user_user_notes/${keyword.id}`
  )
  return response
}
