import styled from '@emotion/styled'
import React from 'react'
import { IMagicStream } from '../../entities'

type IStreamAnnounceProps = {
  stream: IMagicStream
}

const StreamAnnounce: React.FC<IStreamAnnounceProps> = ({ stream }) => {
  return <StreamAnnounceContainer>{stream.content}</StreamAnnounceContainer>
}

const StreamAnnounceContainer = styled.div`
  & {
    position: relative;
    overflow: hidden;
    display: flex;
    white-space: pre-wrap;
    margin: 10px 0;

    > .MuiAvatar {
      flex: 0 0 50px;
      width: 50px;
    }
  }
`

export default StreamAnnounce
