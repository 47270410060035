import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { IconButton, Link, Tooltip } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgVisitor } from '../../assets/images/icons/participants.svg'
import { ReactComponent as SvgUserFriend } from '../../assets/images/icons/user-check.svg'
import { ReactComponent as SvgUserAdd } from '../../assets/images/icons/user-plus.svg'
import { IContact } from '../../entities'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { addFavoriteContact } from '../../store/participant/thunks'
import { PRIMARY_COLOR } from '../../theme'
import { GRAY, PRIMARY } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import { formatName } from '../../utils'
import { breakpoints } from '../breakpoints'
import CssAnimationInsert from '../CssAnimationInsert'
import ContactAvatar from '../globals/ContactAvatar/ContactAvatar'
import Loading from '../Loading'
import ContactMenuPopper from './ContactMenuPopper'
import ContactPopper from './ContactPopper'
import ContactPopperNoteTags from './ContactPopperNoteTags'
import ContactTagsList from './ContactTagsList'

type IProps = {
  contact: IContact
  index?: number
  add: boolean
  more: boolean
  note: boolean
  showModerator?: boolean | undefined
}

const ContactItem: React.FC<IProps> = ({ contact, index = -1, add, more, note, showModerator }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const anchorRef = useRef<HTMLDivElement>(null)
  const anchorMenuRef = useRef<HTMLDivElement>(null)
  const anchorNoteRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [openTags, setOpenTags] = useState(false)
  const userLocation = useSelector((state: IRootState) => state.appState.location)
  const adding = useSelector((state: IRootState) => state.participantState.adding)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const dispatch = useThunkDispatch()

  const added = useMemo(() => !!contact.isContact, [contact])

  const addToContact = (event: React.MouseEvent<EventTarget>, id: number, isOnline: boolean) => {
    event.preventDefault()
    event.stopPropagation()
    sendDataToGTM({ event: 'add-person', cardType: 'Card contact' })
    dispatch(addFavoriteContact(id, isOnline))
  }

  const handleToggle = () => {
    setOpenMenu(false)
    setOpenTags(false)
    setOpen(!open)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const handleToggleMenu = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault()
    event.stopPropagation()
    if (!openMenu) {
      sendDataToGTM({ event: 'toggle-menu', cardType: 'Card contact' })
    }
    setOpen(false)
    setOpenTags(false)
    setOpenMenu(!openMenu)
  }

  const handleCloseMenu = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpenMenu(false)
  }

  const hasNoteOrTag = useMemo(() => !!contact.note || !!contact.contactKeywords?.length, [contact])

  const handleToggleTags = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault()
    event.stopPropagation()
    if (!openTags) {
      sendDataToGTM({ event: 'show-note', cardType: 'Card contact' })
    }
    setOpenTags(!openTags)
    setOpen(false)
    setOpenMenu(false)
  }

  const handleCloseTags = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpenTags(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenMenu(false)
    }
  }

  const showLocation = useMemo(
    () =>
      contact.userStatus &&
      contact.userStatus.appName === settings.theme.name &&
      contact.userStatus.url &&
      contact.userStatus.url !== userLocation,
    [contact, userLocation]
  )

  const onlyRoomLocation = useMemo(
    () =>
      contact.userStatus &&
      contact.userStatus.appName === settings.theme.name &&
      contact.userStatus.url &&
      contact.userStatus.url !== userLocation &&
      contact.userStatus.url.indexOf(userLocation) > -1,
    [contact, userLocation]
  )

  return (
    <StyledContactItem className="card-contact" isContact={added} isAdd={index === 0 && !added}>
      <ListItem
        ref={anchorRef}
        aria-controls="fade-menu"
        aria-haspopup="true"
        button
        onClick={handleToggle}
      >
        <ListItemAvatar>
          <ContactAvatar contact={contact} showModerator={showModerator} />
        </ListItemAvatar>
        <StyledListItemText
          primary={formatName(contact.userPresence.user).full}
          secondary={
            <>
              {contact && contact.contactKeywords && contact.contactKeywords.length > 0 && (
                <ContactTagsList tags={contact.contactKeywords} />
              )}
              {contact.userPresence.user.function && (
                <StyledFunction
                  title={
                    contact.userPresence.user.function.length > 20
                      ? contact.userPresence.user.function
                      : undefined
                  }
                >
                  {contact.userPresence.user.function.substring(0, 20)}
                  {contact.userPresence.user.function.length > 20 ? '...' : ''}
                </StyledFunction>
              )}
              {contact.userPresence.user.partner_id && (
                <StyledEnterprise>{contact.userPresence.user.partner_id.name}</StyledEnterprise>
              )}
              {!eventCo?.eventcoAppointment &&
                contact.userStatus &&
                (showLocation || onlyRoomLocation) && (
                  <StyledLinkLocation>
                    <SvgVisitor />
                    <span>{`${contact.userStatus.description.length > 150
                      ? `${contact.userStatus.description.substring(0, 57)}...`
                      : contact.userStatus.description
                      }`}</span>
                  </StyledLinkLocation>
                )}
            </>
          }
        />
        {add && (
          <StyledListItemIcon
            className={added ? 'action-hover' : 'action-color'}
            onClick={(event: React.MouseEvent<EventTarget>) =>
              !added && contact.id && addToContact(event, contact.id, contact.userPresence.isOnline)
            }
          >
            {adding === contact.id ? <Loading /> : added ? <SvgUserFriend /> : <SvgUserAdd />}
          </StyledListItemIcon>
        )}
        {note && (
          <StyledIconButton
            ref={anchorNoteRef}
            className={hasNoteOrTag ? '' : 'action-hover'}
            onClick={handleToggleTags}
          >
            <StyledNote hasNote={hasNoteOrTag}>
              <Tooltip
                title={intl.formatMessage({
                  id: 'tooltip.ContactNote.LibraryBooksIcon'
                })}
                placement="bottom"
              >
                <LibraryBooksIcon
                  titleAccess={intl.formatMessage({
                    id: 'titleAccess.ContactNote.LibraryBooksIcon'
                  })}
                />
              </Tooltip>
            </StyledNote>
          </StyledIconButton>
        )}
        {more && (
          <StyledListItemIcon
            ref={anchorMenuRef}
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={handleToggleMenu}
          >
            <MoreVertIcon
              titleAccess={intl.formatMessage({ id: 'titleAccess.ContactItem.MoreVertIcon' })}
            />
          </StyledListItemIcon>
        )}
      </ListItem>

      <ContactPopper
        open={open}
        anchorRef={anchorRef}
        contact={contact}
        isOnline={contact.userPresence.isOnline}
        handleClose={handleClose}
      />
      <ContactMenuPopper
        open={openMenu}
        anchorRef={anchorMenuRef}
        contact={contact}
        handleClose={handleCloseMenu}
        handleListKeyDown={handleListKeyDown}
      />
      <ContactPopperNoteTags
        open={openTags}
        setOpen={setOpenTags}
        anchorRef={anchorRef}
        contact={contact}
        handleClose={handleCloseTags}
      />
    </StyledContactItem>
  )
}

const StyledContactItem = styled.div<{ isContact: boolean; isAdd: boolean }>`
  & {
    ${(props) => (props.isContact ? `background-color: transparent;` : '')}
    ${(props) => (props.isAdd ? CssAnimationInsert : '')}
    
    margin: 0 16px;
    .MuiListItem-gutters {
      padding-left: 10px !important;
      padding-right: 10px !important;
      border-radius: ${BORDER_RADIUS_CARD};
    }
    .MuiListItemAvatar-root {
      min-width: 50px;
    }

    @media (min-width: ${breakpoints.md}) {
      .MuiListItem-button {
        .action-color {
          color: ${GRAY};
        }
        .action-hover {
          opacity: 0;
        }
      }
      .MuiListItem-button:hover {
        .action-color {
          color: ${PRIMARY_COLOR};
        }
        .action-hover {
          opacity: 1;
          color: ${PRIMARY_COLOR};
        }
      }
    }
  }
`

const StyledListItemText = styled(ListItemText)`
  & {
    & > span {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 15px;
    }

    & > p {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
    }

    @media (min-width: ${breakpoints.md}) {
      white-space: normal;
    }
  }
`

const StyledFunction = styled.span`
  & {
    display: block;
    max-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const StyledEnterprise = styled.span`
  & {
    display: block;
    max-height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
  }
`

const StyledListItemIcon = styled(ListItemIcon)`
  & {
    min-width: 30px;
    justify-content: center;
  }
`

const StyledLinkLocation = styled(Link)`
  & {
    display: flex;
    align-items: center;

    svg {
      min-width: 30px;
      margin-right: 5px;
    }
  }
`

const StyledNote = styled.span<{ hasNote: boolean }>`
  & {
    svg {
      ${(props) =>
    props.hasNote
      ? `color: ${PRIMARY} !important; opacity: 1 !important;`
      : `color: ${GRAY} !important`}
    }
  }
`

const StyledIconButton = styled(IconButton)`
  & {
    min-width: auto;
  }
`

export default ContactItem
