import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { alpha, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MaximizeIcon from '@material-ui/icons/Maximize'
import MinimizeIcon from '@material-ui/icons/Minimize'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as ExpandIcon } from '../../../assets/images/icons/expand.svg'
import { useMagicStream, useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import {
  setCurrentConversation,
  setErrorConversation,
  setMinimizeChat,
  setOpenChat
} from '../../../store/chat'
import { PRIMARY_COLOR } from '../../../theme'
import { BLACK_BLUE, WHITE } from '../../../theme/colors'
import {
  BORDER_RADIUS_CARD,
  CONTACTS_MENU_WIDTH,
  HEADER_HEIGHT,
  MAGIC_STREAMS_HEIGHT
} from '../../../theme/sizes'
import { breakpoints } from '../../breakpoints'
import Loading from '../../Loading'
import FullScreenDialog from '../../modals/FullScreenDialog'
import Transition from '../../Transition'
import ChatTabs from './ChatTabs'

const ChatPopup: React.FC = () => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [isExtended, setExtended] = useState(false)
  const conversations = useSelector((state: IRootState) => state.chatState.conversations)
  const openChat = useSelector((state: IRootState) => state.chatState.openChat)
  const minimize = useSelector((state: IRootState) => state.chatState.minimizeChat)
  const unread = useSelector((state: IRootState) => state.chatState.unread)
  const channelsToBind = useSelector((state: IRootState) => state.chatState.channelsToBind)
  const loadingConversations = useSelector(
    (state: IRootState) => state.chatState.loadingConversations
  )
  const errorConversation = useSelector((state: IRootState) => state.chatState.errorConversation)
  const dispatch = useThunkDispatch()


  const toggleOpenMenuChat = () => {
    sendDataToGTM({ event: 'click-header-chat', label: 'Toggle chat' })
    dispatch(setOpenChat(false))
    dispatch(setMinimizeChat(false))
    if (unread.length) {
      dispatch(setCurrentConversation(unread[0].author.id))
    }
  }

  const memoConversationsLength = useMemo(
    () =>
      loadingConversations ? (
        <>
          {`(${conversations.length}/${channelsToBind.length})`}
          <Loading />
        </>
      ) : conversations.length > 0 ? (
        `(${conversations.length})`
      ) : (
        <></>
      ),
    [conversations, channelsToBind, loadingConversations]
  )

  const handleClickExtend = () => {
    setExtended(!isExtended)
    if (!isExtended) {
      dispatch(setMinimizeChat(false))
    }
  }

  useEffect(() => {
    if (openChat) {
      dispatch(setMinimizeChat(false))
    }
  }, [openChat, dispatch])

  useEffect(() => {
    if (errorConversation) {
      magicStream.error(errorConversation)
      dispatch(setErrorConversation(undefined))
    }
  }, [errorConversation, magicStream, dispatch])

  return (
    <StyledChatPopup open={openChat} minimize={minimize}>
      <ChatPopupHeader>
        <StyledLabel onClick={() => dispatch(setMinimizeChat(!minimize))}>
          <FormattedMessage id="conversations.title" /> {memoConversationsLength}
        </StyledLabel>
        <div>
          <StyledIconButton
            onClick={() => dispatch(setMinimizeChat(!minimize))}
            title={intl.formatMessage({
              id: minimize ? 'conversations.maximize' : 'conversations.minimize'
            })}
          >
            {minimize && (
              <MaximizeIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.Conversation.MaximizeIcon' })}
              />
            )}
            {!minimize && (
              <MinimizeIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.Conversation.MinimizeIcon' })}
              />
            )}
          </StyledIconButton>
          <StyledIconButton onClick={handleClickExtend}
            title={intl.formatMessage({
              id: isExtended ? 'conversations.extended' : 'conversations.extend'
            })}>
            {!isExtended && <ExpandIcon />}
            {isExtended && <ExpandIcon />}
          </StyledIconButton>
          <StyledIconButton
            onClick={toggleOpenMenuChat}
            title={intl.formatMessage({
              id: 'conversations.close'
            })}
          >
            <CloseIcon />
          </StyledIconButton>
        </div>
      </ChatPopupHeader>
      <ChatTabs />
      <FullScreenDialog
        title={`${intl.formatMessage({ id: 'conversations.title' })} ${memoConversationsLength}`}
        open={isExtended}
        fullScreen
        TransitionComponent={Transition}
        scroll={'body'}
        onClose={handleClickExtend}
      >
        <ChatTabs fullscreen />
      </FullScreenDialog>
    </StyledChatPopup>
  )
}

const StyledLabel = styled.label`
  & {
    cursor: pointer;
  }
`

const StyledChatPopup = styled.div<{ open: boolean; minimize: boolean }>`
  & {
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    top: ${HEADER_HEIGHT};
    right: ${(props) => (props.open ? '0' : '-100%')};
    height: ${(props) => (props.minimize ? '51px' : `auto`)};
    width: 100%;
    background-color: ${WHITE};
    box-shadow: 0 0px 9px 6px ${alpha(BLACK_BLUE, 0.24)};
    transition: all 0.3s;
    z-index: 1203;

    @media (min-width: ${breakpoints.lg}) {
      border-top-left-radius: ${BORDER_RADIUS_CARD};
      border-top-right-radius: ${BORDER_RADIUS_CARD};
      top: ${(props) =>
    props.minimize ? 'auto' : `calc(${HEADER_HEIGHT} + ${MAGIC_STREAMS_HEIGHT} + 20px)`};
      display: ${(props) => (props.open ? 'flex' : 'none')};
      width: calc(${CONTACTS_MENU_WIDTH} - 20px);
      right: 20px;
      overflow: hidden;
    }
  }
`

const ChatPopupHeader = styled.div`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 51px;
    width: 100%;
    padding: 5px 15px 5px 15px;
    text-align: center;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE};
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    .MuiIconButton-root {
      color: ${WHITE};
    }

    & > label {
      display: flex;
      flex-direction: row;
    }
  }
`

const StyledIconButton = styled(IconButton)`
  & {
    padding: 0;
    margin-left: 10px;

    @media (max-width: ${breakpoints.lg}) {
      display: none;
    }
  }
`

export default ChatPopup
