import { Dialog } from '@material-ui/core'
import React from 'react'

interface IOnboardingModalProps {
  isOpen: boolean
  ariaLabelledby: string
}

const OnboardingModal: React.FC<IOnboardingModalProps> = ({ isOpen, ariaLabelledby, children }) => {

  const onClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: 'backdropClick' | 'escapeKeyDown') => {
    // onClose prop with the `reason` argument to filter the `backdropClick` events.
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault()
      event.stopPropagation()
      return
    }
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      aria-labelledby={ariaLabelledby}
    >
      {children}
    </Dialog>
  )
}

export default OnboardingModal
