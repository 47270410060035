import { useEffect, useState } from 'react'
import { IAudioLevelUpdatedEvent, MediaActivity } from '../entities/mediaStream'
import audioDetect from '../utils/audioDetect'

export const useAudioDetection = () => {
  const [talking, setTalking] = useState(false)
  const [audioLevel, setAudioLevel] = useState(0)
  const [activity, setActivity] = useState<MediaActivity | null>(null)

  useEffect(() => {
    const timer = window.setTimeout(() => {
      audioDetect(audioLevel, activity, setActivity, setTalking)
    }, 1000)
    audioDetect(audioLevel, activity, setActivity, setTalking)

    return () => window.clearTimeout(timer)
  }, [audioLevel, activity, setActivity, setTalking])

  return {
    talking,
    audioLevel,
    audioLevelUpdated: (event: IAudioLevelUpdatedEvent) => {
      if (event.audioLevel > 0.015 && audioLevel !== event.audioLevel) {
        // security for prevent too lot of useless events
        setAudioLevel(event.audioLevel)
      }
    }
  }
}
