import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import InfoIcon from '@material-ui/icons/Info'
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContact, IContactUserStatus } from '../../../entities'
import { useThunkDispatch, useTooltip } from '../../../hooks'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { setOpenMenuContact } from '../../../store/app'
import { sendInvitation, setOpenChat } from '../../../store/chat'
import { LIGHT_GRAY } from '../../../theme/colors'

type IProps = {
  contact: IContact
  status?: IContactUserStatus | null
  isOnline?: boolean | undefined
  isOffice?: boolean | undefined
  onClose?: (event: React.MouseEvent<EventTarget>) => void
}

const ActionInvitToJoin: React.FC<IProps> = ({ contact, status, isOnline, isOffice, onClose }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const user = useSelector((state: IRootState) => state.appState.user)
  const currentLocation = useSelector((state: IRootState) => state.appState.location)
  const roomNumber = useSelector((state: IRootState) => state.roomState.roomNumber)
  const invitationInProgress = useSelector(
    (state: IRootState) => state.chatState.invitationInProgress
  )
  const {
    anchorRef: anchorRefInvitation,
    setOpen: setOpenInvitation,
    Tooltip: TooltipInvitation
  } = useTooltip(
    intl.formatMessage({
      id:
        roomNumber === -1
          ? 'contactpopper.invitation.noRoomNumber'
          : currentLocation.indexOf(`salon=${roomNumber}`) === -1
            ? 'contactpopper.invitation.sameLocation'
            : !contact.userPresence.isOnline && !isOnline
              ? 'contactpopper.invitation.notOnline'
              : invitationInProgress
                ? 'contactpopper.invitation.inProgress'
                : 'contactpopper.button_invite'
    })
  )
  const dispatch = useThunkDispatch()

  const invitePerson = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    if (user && contact.id) {
      dispatch(
        sendInvitation(contact.id, intl.formatMessage({ id: 'invitation.sended' }), currentLocation)
      )
      dispatch(setOpenChat(false))
      dispatch(setOpenMenuContact(false))
      sendDataToGTM({
        event: 'invite-contact',
        cardType: 'Contact Popper'
      })
    }
    onClose && onClose(event)
  }

  const memoCanInvitePerson = useMemo(
    () =>
      roomNumber > -1 &&
      currentLocation.indexOf(`salon=${roomNumber}`) > -1 &&
      (contact.userPresence.isOnline || isOnline) &&
      !invitationInProgress,
    [roomNumber, currentLocation, invitationInProgress, contact, isOnline]
  )

  const notSameLocation = useMemo(
    () => isOffice || !status || (status.url && status.url !== currentLocation),
    [isOffice, status, currentLocation]
  )

  const sameApp = useMemo(() => !status || status.appName === settings.theme.name, [status])

  return (
    <>
      {/* // invitation to join */}
      {sameApp && notSameLocation && memoCanInvitePerson ? (
        <MenuItem onClick={invitePerson}>
          <ListItemIcon>
            <ContactPhoneIcon
              fontSize="large"
              titleAccess={intl.formatMessage({
                id: 'titleAccess.ContactPopperAction.ContactPhoneIcon'
              })}
            />
          </ListItemIcon>
          <Typography variant="inherit">
            <FormattedMessage id="contactpopper.button_invite" />
          </Typography>
        </MenuItem>
      ) : (
        <MenuItemDisabled
          ref={anchorRefInvitation}
          onMouseOver={() => setOpenInvitation(true)}
          onMouseOut={() => setOpenInvitation(false)}
        >
          <ListItemIcon>
            <ContactPhoneIcon
              fontSize="large"
              titleAccess={intl.formatMessage({
                id: 'titleAccess.ContactPopperAction.ContactPhoneIcon'
              })}
            />
          </ListItemIcon>
          <Typography variant="inherit">
            <FormattedMessage id="contactpopper.button_invite" />
          </Typography>
          <StyledRightListItemIcon>
            <InfoIcon fontSize="small" />
          </StyledRightListItemIcon>
          <TooltipInvitation />
        </MenuItemDisabled>
      )}
    </>
  )
}

const MenuItemDisabled = styled(MenuItem)`
  color: ${LIGHT_GRAY};

  .MuiSvgIcon-root {
    fill: ${LIGHT_GRAY};
  }

  span {
    flex: auto;
  }
`

const StyledRightListItemIcon = styled(ListItemIcon)`
  justify-content: flex-end;
`

export default ActionInvitToJoin
