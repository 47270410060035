// Breakpoint

export const breakpointsValues = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1800
}

export const breakpoints = {
  sm: `${breakpointsValues.sm}px`,
  md: `${breakpointsValues.md}px`,
  lg: `${breakpointsValues.lg}px`,
  xl: `${breakpointsValues.xl}px`,
  xxl: `${breakpointsValues.xxl}px`
}

export const mobileWidth = 576
