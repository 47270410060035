import styled from '@emotion/styled'
import { Grid, Switch } from '@material-ui/core'
import React, { ChangeEvent, FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Label } from '../ui/LabelWrapper/LabelWrapper'
import SelectStreamType from './SelectStreamType'
import StreamsPanelContent from './StreamsPanelContent'

interface IStreamsPanelExtendedProps {
  onlyAppointment: boolean
  setOnlyAppointment: (value: boolean) => void
  priorityHigh: boolean
  setPriorityHigh: (value: boolean) => void
  streamType: string
  setStreamType: (value: string) => void
}

const StreamsPanelExtended: FC<IStreamsPanelExtendedProps> = ({
  onlyAppointment,
  setOnlyAppointment,
  priorityHigh,
  setPriorityHigh,
  streamType,
  setStreamType
}) => {
  const intl = useIntl()

  return (
    <StyledExtended>
      <Grid container spacing={5}>
        <Grid item xs={12} lg={4} xl={3}>
          <StyledFilters>
            <>
              <Label id="is-high-label">
                <FormattedMessage id="is.high.priority" />
              </Label>
              <Switch
                aria-labelledby="is-high-label"
                checked={priorityHigh}
                color="primary"
                title={intl.formatMessage({
                  id: priorityHigh ? 'is.high.priority' : 'not.high.priority'
                })}
                onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  setPriorityHigh(checked)
                }}
                name="isHighPriority"
              ></Switch>
            </>
            <>
              <Label id="is-appointment-label">
                <FormattedMessage id="is.appointment" />
              </Label>
              <Switch
                aria-labelledby="is-appointment-label"
                checked={onlyAppointment}
                color="primary"
                title={intl.formatMessage({
                  id: onlyAppointment ? 'is.appointment' : 'not.appointment'
                })}
                onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  setOnlyAppointment(checked)
                }}
                name="isOnlyAppointment"
              ></Switch>
            </>
            <SelectStreamType streamType={streamType} onChange={(type) => setStreamType(type)} />
          </StyledFilters>
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <StreamsPanelContent
            priorityHigh={priorityHigh}
            onlyAppointment={onlyAppointment}
            streamType={streamType}
          />
        </Grid>
      </Grid>
    </StyledExtended>
  )
}

const StyledExtended = styled.div``

const StyledFilters = styled.div`
  display: flex;
  flex-direction: column;
`

export default StreamsPanelExtended
