import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { setAgendaInitDate } from '../../store/app'
import { setContactAppointment } from '../../store/chat'
import ContactTakeAppointmentForm from '../contacts/ContactTakeAppointmentForm'
import SendDialog from '../modals/SendDialog'

interface IContactTakeAppointmentDialogProps {
  showDate?: boolean
  onlyMessage?: boolean
}

const ContactTakeAppointmentDialog: React.FC<IContactTakeAppointmentDialogProps> = ({
  showDate,
  onlyMessage
}) => {
  const intl = useIntl()
  const [disableSend, setDisableSend] = useState(true)
  const [sent, setSent] = useState(false)
  const contactAppointment = useSelector((state: IRootState) => state.chatState.contactAppointment)
  const sending = useSelector((state: IRootState) => state.chatState.sending)
  const dispatch = useThunkDispatch()

  return (
    <SendDialog
      title={intl.formatMessage({ id: 'button.appointment' })}
      open={!!contactAppointment}
      disableSend={disableSend}
      sending={sending}
      sendEvent={() => setSent(true)}
      cancelEvent={() => {
        // reset
        dispatch(setAgendaInitDate(null))
        dispatch(setContactAppointment(null))
      }}
      maxWidth="sm"
      fullWidth
    >
      {contactAppointment && (
        <ContactTakeAppointmentForm
          contact={contactAppointment}
          showDate={showDate}
          onlyMessage={onlyMessage}
          sent={sent}
          setSent={setSent}
          setDisableSend={setDisableSend}
        />
      )}
    </SendDialog>
  )
}

export default ContactTakeAppointmentDialog
