import styled from '@emotion/styled'
import { motion, useAnimation } from 'framer-motion'
import React, { useState } from 'react'

const withRippleEffect = (WrappedComponent: React.ReactType) => (props: any) => {
  const [ripple, setRipple] = useState({ x: 0, y: 0, size: 0 })
  const controls = useAnimation()

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.target) {
      const rect = e.currentTarget && e.currentTarget.getBoundingClientRect()

      const rippleSize = Math.max(rect.height, rect.width)

      const clientX = e.clientX
      const clientY = e.clientY
      const rippleX = Math.round(clientX - rect.left)
      const rippleY = Math.round(clientY - rect.top)

      setRipple({ x: rippleX, y: rippleY, size: rippleSize })
    }

    controls.start('touch')
  }

  const variants = {
    touch: { opacity: [0, 0.3, 0, 0], scale: [0, 1, 1, 0] }
  }

  const { children, className, ...restProps } = props

  return (
    <WrappedComponent
      onMouseDown={handleMouseDown}
      style={{ position: 'relative ' }}
      className={className}
      {...restProps}
    >
      <RippleWrapper>
        <Ripple
          animate={controls}
          variants={variants}
          style={{
            top: -(ripple.size / 2) + ripple.y,
            left: -(ripple.size / 2) + ripple.x,
            height: ripple.size,
            width: ripple.size
          }}
        />
      </RippleWrapper>
      {children}
    </WrappedComponent>
  )
}

const RippleWrapper = styled.div`
  display: block;
  position: absolute;
  overflow: hidden;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 0;
`

const Ripple = styled(motion.div)`
  opacity: 0;
  scale: 0;
  position: absolute;
  border-radius: 50%;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
`

export default withRippleEffect
