import styled from '@emotion/styled'
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import React, { FC, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import { updateConference } from '../../../store/conference'
import ConferenceLiveButton from '../../conference/ConferenceLiveButton'
import ConferenceRelatedButtons from '../../conference/ConferenceRelatedButtons'
import IconButtonBg from '../../ui/IconButtonBg/IconButtonBg'
import Announce from '../Announce'
import VimeoId from '../VimeoId'

interface IEditModeMenuProps {
  editMode: boolean
  setEditMode: (value: boolean) => void
  status?: string
  updateConferenceStatus?: (confStatus: string, notify: boolean) => void
  disabled?: boolean
}

const EditModeMenu: FC<IEditModeMenuProps> = ({ editMode, setEditMode, status, updateConferenceStatus, disabled }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const conference = useSelector((state: IRootState) => state.conferenceState.conference)
  const groupId = useSelector((state: IRootState) => state.appState.groupId)
  const dispatch = useThunkDispatch()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const togglaEditMode = () => {
    setEditMode(!editMode)
    setOpen(false)
  }

  const updateConferenceVimeoId = (value: string | undefined) => {
    if (conference) {
      dispatch(updateConference(conference.id, { live_vimeo_id: value }))
    }
  }

  return (
    <StyledEditModeMenu>
      <IconButtonBg
        iconButtonRef={anchorRef}
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={disabled}
      >
        <SettingsIcon />
      </IconButtonBg>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={togglaEditMode}>
                    <FormattedMessage
                      id={editMode ? 'editMode.menu.disable' : 'editMode.menu.enable'}
                    />
                  </MenuItem>
                  {conference && (
                    <VimeoId
                      menuMode
                      url={conference.live_vimeo_id}
                      onChange={updateConferenceVimeoId}
                    />
                  )}
                  {status && updateConferenceStatus && (
                    <ConferenceLiveButton
                      menuMode
                      setOpen={setOpen}
                      status={status}
                      updateConferenceStatus={updateConferenceStatus}
                    />
                  )}
                  <ConferenceRelatedButtons menuMode />
                  <Announce groupId={groupId} menuMode />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </StyledEditModeMenu>
  )
}

const StyledEditModeMenu = styled.div`
  & {
    z-index: 10;
  }
`

export default EditModeMenu
