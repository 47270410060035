import { MediaDevice, MediaError } from '../entities'
import settings from '../settings'

export class CommonStream {
  checkScreenSharingCapability(onScreenSharingAvailable: () => void): void {
    if (window.OT) {
      window.OT.checkScreenSharingCapability((response) => {
        if (!response.supported || response.extensionRegistered === false) {
          // This browser does not support screen sharing
        } else if (response.extensionInstalled === false) {
          // Prompt to install the extension
        } else {
          // Screen sharing is available.
          onScreenSharingAvailable()
          window.OT.registerScreenSharingExtension('chrome', settings.chromeExtensionId, 2)
        }
      })
    }
  }
  registerScreenSharingExtension(): void {
    if (window.OT) {
      window.OT.registerScreenSharingExtension('chrome', settings.chromeExtensionId, 2)
    }
  }
  getDevices(
    onSuccess: (devices: MediaDevice[]) => void,
    onError?: (err: MediaError) => void
  ): void {
    if (window.OT) {
      window.OT.getDevices((err: MediaError | undefined, devices?: MediaDevice[] | undefined) => {
        if (err) {
          onError && onError(err)
        } else if (devices) {
          onSuccess(devices)
        }
      })
    }
  }
}
