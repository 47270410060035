import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import consoleUtils from '../../utils/consoleUtils'
import { setOpenSyncCalendar, setUser } from '../../store/app'
import { useIntl } from 'react-intl'
import { setAlert } from '../../store/alerts'
import { AlertType } from '../../entities'

const SynchronizeCalendarDialog: React.FC = () => {
  const intl = useIntl()
  const dispatch = useThunkDispatch()
  const openSyncCalendar = useSelector((state: IRootState) => state.appState.openSyncCalendar)
  const user = useSelector((state: IRootState) => state.appState.user)
  const [openedWindow, setOpenedWindow] = useState<Window | null>(null)
  const src = useMemo(
    () =>
      openSyncCalendar ? settings.url.auth + '/users/sync-calendar/authorize/google' : undefined,
    [openSyncCalendar]
  )

  useEffect(() => {
    let timer: number | null = null
    const handler = (event: MessageEvent) => {
      consoleUtils.error('handler message error=', event)
      try {
        const data = JSON.parse(event.data)
        if (data.done) {
          timer = window.setTimeout(() => {
            if (user && user.id) {
              const syncUser = { ...user, isCalendarSynchronized: true }
              dispatch(setUser(syncUser))
              dispatch(
                setAlert({
                  id: new Date().getTime().toString(),
                  intlKey: 'success.syncCalendar',
                  type: AlertType.success
                })
              )
            }

            dispatch(setOpenSyncCalendar(false))
          }, 3000)
        }
      } catch (e) {
        consoleUtils.error('handler message error=', event.data)
        consoleUtils.error('handler message error=', e)
      }
    }
    if (openSyncCalendar && openedWindow) {
      window.addEventListener('message', handler)
    }

    return () => {
      window.removeEventListener('message', handler)
      if (timer) window.clearTimeout(timer)
    }
  }, [dispatch, openSyncCalendar, openedWindow, user])

  useEffect(() => {
    const h = window.screen.height
    const w = window.screen.width
    if (openSyncCalendar && !openedWindow) {
      const win = window.open(
        src,
        intl.formatMessage({
          id: 'titleAccess.Stream.SyncGoogleCalendar'
        }),
        'width=' + w + ',height=' + h + ',fullscreen'
      )
      setOpenedWindow(win)
    }
    if (!openSyncCalendar && openedWindow) {
      openedWindow.close()
      setOpenedWindow(null)
    }
  }, [openSyncCalendar, src, openedWindow, intl])

  return <></>
}
export default SynchronizeCalendarDialog
