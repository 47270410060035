import styled from '@emotion/styled'
import { FormControl, FormHelperText, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { ChangeEvent, default as React, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Loading from '../../components/Loading'
import AppButton from '../../components/ui/AppButton/AppButton'
import FormContainer from '../../components/ui/Form/FormContainer'
import FormPictureUploader from '../../components/ui/Form/FormPictureUploader'
import { Label } from '../../components/ui/LabelWrapper/LabelWrapper'
import SelectLanguage from '../../components/ui/SelectLanguage/SelectLanguage'
import { GROUP_ROLES } from '../../entities'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { setRefreshRequired, updateUser } from '../../store/app'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../theme'
import { WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import Editor from '../ui/Editor/Editor'

interface IProfileFormProps {
  loadData: boolean
  hintCapture?: boolean
  withSubmit?: boolean
  doSubmit?: boolean
  setDoSubmit?: (value: boolean) => void
  onSubmit?: () => void
}

const ProfileForm: React.FC<IProfileFormProps> = ({
  loadData,
  hintCapture,
  withSubmit,
  doSubmit,
  setDoSubmit,
  onSubmit
}) => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const [isDirty, setDirty] = useState(false)
  const [firstname, setFirstname] = useState<string | null>(null)
  const [lastname, setLastname] = useState<string | null>(null)
  // const [description, setDescription] = useState<string | null>(null)
  const [descriptionHtml, setDescriptionHtml] = useState<string | null>(null)
  const [functionName, setFunctionName] = useState<string | null>(null)
  const [avatar, setAvatar] = useState<string | null>(null)
  const user = useSelector((state: IRootState) => state.appState.user)
  const userUpdating = useSelector((state: IRootState) => state.appState.userUpdating)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const dispatch = useThunkDispatch()

  const companyLogo = useMemo(() => {
    if (user) {
      const userRoles = user.userGroupRoles
        ? user.userGroupRoles.map((userRole) => userRole.role).join(',')
        : ''
      return (
        userRoles.indexOf(GROUP_ROLES.ROLE_CONTRACTOR) > -1 ||
        userRoles.indexOf(GROUP_ROLES.ROLE_ORDERTAKER) > -1
      )
    } else {
      return false
    }
  }, [user])

  const handleChangeFirstname = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFirstname(event.target.value)
    setDirty(true)
  }

  const handleChangeLastname = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLastname(event.target.value)
    setDirty(true)
  }

  // const handleChangeDescription = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   setDescription(event.target.value)
  //   setDirty(true)
  // }

  const handleChangeDescriptionHtml = (content: string) => {
    setDescriptionHtml(content)
    setDirty(true)
  }

  const handleChangeFunction = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFunctionName(event.target.value)
    setDirty(true)
  }

  const handleChangeAvatarPath = (base64: string) => {
    setAvatar(base64)
    setDirty(true)
  }

  const handleSubmit = useCallback(() => {
    if (isDirty) {
      if (user && firstname && lastname && avatar) {
        // call api to save form
        dispatch(
          updateUser(
            user,
            {
              firstname,
              lastname,
              description: descriptionHtml || undefined,
              // descriptionHtml: descriptionHtml || undefined,
              function: functionName || undefined,
              avatar
            },
            [],
            // success
            () => {
              setDirty(false)
              onSubmit && onSubmit()
            },
            // error
            (error) => {
              magicStream.error(error.message)
            }
          )
        )
      }
    } else {
      onSubmit && onSubmit()
    }
  }, [
    user,
    magicStream,
    isDirty,
    firstname,
    lastname,
    // description,
    descriptionHtml,
    functionName,
    avatar,
    onSubmit,
    dispatch
  ])

  useEffect(() => {
    if (doSubmit) {
      handleSubmit()
      setDoSubmit && setDoSubmit(false)
    }
  }, [doSubmit, setDoSubmit, handleSubmit])

  useEffect(() => {
    if (user) {
      setFirstname(user.firstname)
      setLastname(user.lastname)
      // setDescription(user.description)
      setDescriptionHtml(user.description)
      setFunctionName(user.function)
      setAvatar(
        user.avatar && user.avatar.indexOf('data:') > -1
          ? user.avatar
          : user.avatarPath && user.avatarPath.indexOf('://') === -1
          ? `http${settings.prod ? 's' : ''}://${user.avatarPath}`
          : user.avatarPath
      )
    }
  }, [user])

  return (
    <FormContainer>
      <StyledPartner>
        <FormattedMessage id="profileForm.partner_id.title" />
        <b>{user?.partner_id.name}</b>
      </StyledPartner>
      {user && user.userGroupRoles && user.userGroupRoles.length > 0 && (
        <StyledRole>
          <FormattedMessage
            id={
              user.userGroupRoles.length === 1
                ? 'profileForm.role.title'
                : 'profileForm.roles.title'
            }
          />
          <b>
            {user.userGroupRoles
              .map((userGroupRole) =>
                intl.formatMessage({
                  id:
                    userGroupRole.role <= 5
                      ? `profileForm.role.${userGroupRole.role}`
                      : 'profileForm.role.unknow'
                })
              )
              .join(', ')}
          </b>
        </StyledRole>
      )}
      <FormControl>
        <TextField
          id="firstname"
          label={<FormattedMessage id="profileForm.firstname" />}
          value={firstname || ''}
          error={!firstname}
          onChange={handleChangeFirstname}
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{ shrink: false, disableAnimation: true }}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="lastname"
          label={<FormattedMessage id="profileForm.lastname" />}
          value={lastname || ''}
          error={!lastname}
          onChange={handleChangeLastname}
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{ shrink: false, disableAnimation: true }}
        />
      </FormControl>
      {/* <FormControl>
        <TextField
          id="description"
          label={<FormattedMessage id="profileForm.description" />}
          value={description || ''}
          onChange={handleChangeDescription}
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{ shrink: false, disableAnimation: true }}
        />
        <FormHelperText>
          <FormattedMessage id="profileForm.description.helper" />
        </FormHelperText>
      </FormControl> */}
      <FormControl>
        <Label>
          <FormattedMessage id="profileForm.descriptionHtml" />
        </Label>
        <Editor content={descriptionHtml || ''} onChange={handleChangeDescriptionHtml} />
        <FormHelperText>
          <FormattedMessage id="profileForm.descriptionHtml.helper" />
        </FormHelperText>
      </FormControl>
      <FormControl>
        <TextField
          id="functionName"
          label={<FormattedMessage id="profileForm.function" />}
          value={functionName || ''}
          onChange={handleChangeFunction}
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{ shrink: false, disableAnimation: true }}
        />
      </FormControl>
      <FormControl>
        <Label>
          <FormattedMessage
            id={companyLogo ? 'profileForm.avatar.company' : 'profileForm.avatar'}
          />
        </Label>
        <FormHelperText>
          <FormattedMessage id="profileForm.avatar.helper" />
        </FormHelperText>
        <FormPictureUploader image={avatar} onChangeImage={handleChangeAvatarPath}>
          <AddIcon titleAccess={intl.formatMessage({ id: 'titleAccess.ProfilForm.AddIcon' })} />
        </FormPictureUploader>
        {!eventCo?.eventcoAppointment && hintCapture && (
          <FormHelperText>
            <FormattedMessage id="helpertext.capture.image" />
          </FormHelperText>
        )}
      </FormControl>

      {settings.eventLanguages.split(',').length > 1 && (
        <SelectLanguage
          labelId="select.language"
          onValueChange={() => dispatch(setRefreshRequired(true))}
        />
      )}
      {withSubmit && (
        <StyledAppButton onClick={handleSubmit} disabled={!isDirty}>
          {userUpdating ? <Loading color="inherit" /> : <FormattedMessage id="button.update" />}
        </StyledAppButton>
      )}
    </FormContainer>
  )
}

const StyledAppButton = styled(AppButton)`
  & {
    max-width: 150px;
    margin: 0 auto;
  }
`

const StyledRole = styled.div`
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE};
  border-radius: ${BORDER_RADIUS_CARD};
`

const StyledPartner = styled.div`
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
  background-color: ${SECONDARY_COLOR};
  color: ${WHITE};
  border-radius: ${BORDER_RADIUS_CARD};
`

export default ProfileForm
