import {
  IContact,
  IDataUserCreate,
  IDataUserUpdate,
  IRegistration,
  IUser,
  IUserLog,
  IUserLogVideo,
  IUserStatus,
  IUserUpdate
} from '../entities/user'
import settings from '../settings'
import consoleUtils from '../utils/consoleUtils'
import { apiPatchRequest, apiPostRequest, apiRequest } from './requests'

export const getMe = async () => {
  const response = await apiRequest<IUser>('GET', `${settings.url.api}/me`)
  return response
}

export const getUser = async (id: number) => {
  const response = await apiRequest<IContact>('GET', `${settings.url.api}/users/${id}`)
  return response
}

export const updateUser = async (id: number, user: IUserUpdate) => {
  const response = await apiPatchRequest<IUserUpdate>(`${settings.url.api}/users/${id}`, user)
  return response
}

export const createDataUser = async (dataUser: IDataUserCreate) => {
  const response = await apiPostRequest<IDataUserCreate>(`${settings.url.api}/data_users`, dataUser)
  return response
}

export const updateDataUser = async (id: number, dataUser: IDataUserUpdate) => {
  const response = await apiPatchRequest<IDataUserUpdate>(
    `${settings.url.api}/data_users/${id}`,
    dataUser
  )
  return response
}

export const getTotalUsersConnected = async () => {
  const response = await apiRequest<number>(
    'GET',
    `${settings.url.api}/count_users?presenceIn=${settings.eventGroupId}`
  )
  return response
}

export const getParticipants = async (
  page: number = 1,
  search?: string,
  functionName?: string,
  partnerType?: { id: number; name: string },
  isOnline?: boolean,
  isContact?: boolean,
  tags?: string,
  keywordsX?: string,
  keywordsY?: string,
  sortBy?: string,
  roles?: string
) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/user_contacts?appName=${settings.theme.name}&isContact=${isContact}&${
      isOnline ? 'presenceIn' : 'memberOf'
    }=${settings.eventGroupId}&page=${page}&limit=${settings.list.itemsPerPage}${
      search ? `&search=${search}` : ''
    }${functionName && functionName !== 'all' ? `&function=${functionName}` : ''}${
      partnerType && partnerType.id ? `&partner_type=${partnerType.id}` : ''
    }${tags ? '&keyword=' + tags : ''}${keywordsX ? `&xKeyword=${keywordsX}` : ''}${
      keywordsY ? `&yKeyword=${keywordsY}` : ''
    }${sortBy === 'recommandation' ? `&orderByMatching=true` : ''}${roles ? `&roles=${roles}` : ''}`
  )
  return {
    items: response['hydra:member'] as IContact[],
    total: response['hydra:totalItems'] as number
  }
}

export const getParticipantsWithMe = async (page: number = 1, search?: string) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/user_contacts?appName=${settings.theme.name}&memberOf=${
      settings.eventGroupId
    }&page=${page}&limit=${settings.list.itemsPerPage}${
      search ? `&search=${search}` : ''
    }&showLoggedinUser=1`
  )
  return {
    items: response['hydra:member'] as IContact[],
    total: response['hydra:totalItems'] as number
  }
}

export const getPresenceUsers = async (groupId: number | string) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/user_contacts?appName=${settings.theme.name}&isContact=false&presenceIn=${groupId}&limit=${settings.list.contacts}`
  )
  return {
    items: response['hydra:member'] as IContact[],
    total: response['hydra:totalItems'] as number
  }
}

export const getContacts = async (
  search?: string,
  tags?: string,
  keywordsX?: string,
  keywordsY?: string
) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/user_contacts?appName=${settings.theme.name}&isContact=true${
      search ? `&search=${search}` : ''
    }&memberOf=${settings.eventGroupId}&limit=${settings.list.contacts}${
      tags ? `&keyword=${tags}` : ''
    }${keywordsX ? `&xKeyword=${keywordsX}` : ''}${keywordsY ? `&yKeyword=${keywordsY}` : ''}`
  )
  return response['hydra:member'] as IContact[]
}

export const getFollowers = async (type: string, id: number) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/user_contacts?appName=${settings.theme.name}&followsType=${type}&followsId=${id}&memberOf=${settings.eventGroupId}&limit=150`
  )
  return response['hydra:member'] as IContact[]
}

export const getContact = async (id: number) => {
  const response = await apiRequest<IContact>(
    'GET',
    `${settings.url.api}/user_contacts/${id}?groupId=${settings.eventGroupId}`
  )
  return response
}

export const addFavoriteContact = async (id: number) => {
  const response = await apiPostRequest<IContact>(`${settings.url.api}/add_contact`, { id })
  return response
}

export const deleteContact = async (id: number) => {
  const response = await apiRequest<IContact>('DELETE', `${settings.url.api}/user_contacts/${id}`)
  return response
}

export const addUserLog = async (userLog: IUserLog) => {
  consoleUtils.log(`[UserLog] status '${userLog.description}' (${userLog.url})`)
  try {
    const response = await apiPostRequest<IUserLog>(`${settings.url.api}/user_logs`, {
      ...userLog,
      appName: settings.theme.name
    })
    return response
  } catch (e) {
    consoleUtils.error(e)
  }
}

export const addUserLogVideo = async (userLogVideo: IUserLogVideo) => {
  consoleUtils.log(`[UserLog] on event '${userLogVideo.objectId}' (${userLogVideo.url})`)
  try {
    const response = await apiPostRequest<IUserLog>(`${settings.url.api}/user_logs`, {
      ...userLogVideo,
      appName: settings.theme.name
    })
    return response
  } catch (e) {
    consoleUtils.error(e)
  }
}

export const getUserStatus = async (userId: number) => {
  const response = await apiRequest<IUserStatus>(
    'GET',
    `${settings.url.api}/user_statuses/${userId}`
  )
  return response
}

export const registerEventGroup = async () => {
  try {
    const response = await apiPostRequest<IRegistration>(`${settings.url.api}/registration`, {
      group: `/groups/${settings.eventGroupId}`
    })
    return response
  } catch (e) {
    consoleUtils.error(e)
  }
}

export const unsyncCalendar = async (id: number) => {
  const response = await apiPatchRequest<IUser>(
    `${settings.url.api}/users/${id}/unsync_calendar`,
    {}
  )
  return response
}
