import { IEnterRoomJitsi } from '../entities'
import settings from '../settings'
import { apiRequest } from './requests'

export const enterRoomJitsi = async (roomId: number) => {
  const response = await apiRequest<IEnterRoomJitsi>(
    'GET',
    `${settings.url.api}/e_events/room/enter/${roomId}`
  )
  return response
}

export const enterRoomBroadcasterJitsi = async (roomId: number) => {
  const response = await apiRequest<IEnterRoomJitsi>(
    'GET',
    `${settings.url.api}/e_events/room/broadcast/${roomId}`
  )
  return response
}
