import styled from '@emotion/styled'
import { FormControl } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRootState } from '../../redux'
import SelectSlot from '../agenda/SelectSlot'
import { breakpoints } from '../breakpoints'
import Loading from '../Loading'
import DatePicker from '../ui/DatePicker/DatePicker'

interface IDateSlotPickerProps {
  userId: number
  date?: Date
  setDate: (value: Date) => void
  setDateTime: (value: Date) => void
}

const DateSlotPicker: React.FC<IDateSlotPickerProps> = ({
  userId,
  date,
  setDate,
  setDateTime
}) => {
  const intl = useIntl()
  const [slot, setSlot] = useState<string>('')
  const agendaInitDateLoading = useSelector((state: IRootState) => state.appState.agendaInitDateLoading)
  const sending = useSelector((state: IRootState) => state.chatState.sending)

  useEffect(() => {
    if (date && slot) {
      const slotHM = slot.split('h')
      const dt = new Date(date.setHours(parseInt(slotHM[0], 10), parseInt(slotHM[1], 10), 0, 0))
      setDateTime(dt)
    }
  }, [date, slot, setDateTime])

  return (
    <StyledDate>
      <FormControl fullWidth>
        <DatePicker
          id="date-picker-appointment"
          label={intl.formatMessage({ id: 'rdv.date.title' })}
          format="dd/MM/yyyy"
          minDate={new Date()}
          minDateMessage="La date ne peut pas être inférieur à aujourd'hui"
          showTodayButton
          fullWidth
          disabled={sending || agendaInitDateLoading}
          value={date}
          onChange={(d: MaterialUiPickersDate) => {
            if (d) setDate(d)
          }}
        />
      </FormControl>
      {agendaInitDateLoading && (
        <FormControl fullWidth>
          <StyledLoadingContainer><Loading /></StyledLoadingContainer>
        </FormControl>
      )}
      {date && (
        <FormControl fullWidth>
          <SelectSlot
            userId={userId}
            slotDate={date}
            onValueChange={(value: string) => {
              if (value) setSlot(value)
            }}
          />
        </FormControl>
      )}
    </StyledDate>
  )
}

const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  height: 50px;
`

const StyledDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`

export default DateSlotPicker
