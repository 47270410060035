import styled from '@emotion/styled'
import { Chip, FormControl, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Alert } from '@material-ui/lab'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IProduct } from '../../entities'
import { IKeywordProductDisplay } from '../../entities/keyword'
import { useEffectOnce, useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { addProduct, editProduct, loadProductKeywords } from '../../store/product'
import { RED, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import Loading from '../Loading'
import AppButton from '../ui/AppButton/AppButton'
import FormContainer from '../ui/Form/FormContainer'
import FormPictureUploader from '../ui/Form/FormPictureUploader'
import { Label } from '../ui/LabelWrapper/LabelWrapper'

interface IExhibitorProductFormProps {
  product?: IProduct
  onSubmit?: () => void
}

const ExhibitorProductForm: React.FC<IExhibitorProductFormProps> = ({ product, onSubmit }) => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const [error, setError] = useState<string | undefined>(undefined)
  const [name, setName] = useState('')
  const [nameDirty, setNameDirty] = useState(false)
  const [body, setBody] = useState<string | undefined>(undefined)
  const [picture, setPicture] = useState<string | undefined>(undefined)
  const [errorCategory, setErrorCategory] = useState(false)
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const [keywords, setKeywords] = useState<IKeywordProductDisplay[]>([])
  const loadingProductKeywords = useSelector(
    (state: IRootState) => state.productState.loadingProductKeywords
  )
  const productKeywords = useSelector((state: IRootState) => state.productState.productKeywords)
  const productUpdating = useSelector((state: IRootState) => state.productState.productUpdating)
  const productAdding = useSelector((state: IRootState) => state.productState.productAdding)
  const dispatch = useThunkDispatch()

  const handleChangeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value)
    setNameDirty(true)
  }

  const handleChangeBody = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBody(event.target.value)
  }

  const handleChangePicture = (base64: string) => {
    setPicture(base64)
  }

  const toggleKeyword = useCallback(
    (selected: number) => {
      setErrorCategory(false)
      setKeywords(
        keywords.map((keyword, index) =>
          index === selected
            ? { ...keyword, selected: !keyword.selected }
            : { ...keyword, selected: false }
        )
      )
    },
    [setErrorCategory, keywords, setKeywords]
  )

  const handleSubmitAdd = useCallback(() => {
    setErrorCategory(false)
    const keywordSelected = keywords.find((k) => k.selected)
    if (name) {
      // call api to save product
      dispatch(
        addProduct(
          {
            name,
            body,
            businessEventExhibitorId: exhibitor?.id,
            productCategoryId: keywordSelected?.id,
            imageData64: picture
          },
          // success
          () => {
            setError(undefined)
            setName('')
            setBody('')
            setNameDirty(false)
            keywords.map((k) => (k.selected = false))
            magicStream.success(
              intl.formatMessage(
                { id: 'product.form.messageSuccessProductAdd' },
                { productName: `<b>${name}</b>` }
              )
            )
          },
          // error
          (err) => {
            setError(err.message)
            magicStream.error(err.message)
          }
        )
      )
    } else if (name && body) {
      setErrorCategory(true)
    }
  }, [intl, magicStream, exhibitor, name, body, keywords, picture, setNameDirty, setErrorCategory, dispatch])

  const handleSubmitEdit = useCallback(() => {
    setErrorCategory(false)
    const keywordSelected = keywords.find((k) => k.selected)
    if (product && name) {
      // call api to save edit product
      dispatch(
        editProduct(
          {
            id: product?.id,
            name,
            body,
            businessEventExhibitorId: exhibitor?.id,
            productCategoryId: keywordSelected?.id,
            imageData64: picture
          },
          // success
          () => {
            onSubmit && onSubmit()
            magicStream.success(
              intl.formatMessage(
                { id: 'product.form.messageSuccessProductEdit' },
                { productName: `<b>${name}</b>` }
              )
            )
          },
          // error
          (err) => {
            setError(err.message)
            magicStream.error(err.message)
          }
        )
      )
    } else if (name && body) {
      setErrorCategory(true)
    }
  }, [intl, magicStream, product, exhibitor, name, body, keywords, picture, onSubmit, dispatch])

  useEffect(() => {
    if (productKeywords && productKeywords.length > 0 && keywords.length === 0) {
      if (product) {
        setKeywords(
          productKeywords.map((k) => ({
            id: k.id,
            name: k.name,
            selected: product.productCategory?.id === k.id
          }))
        )
      } else {
        setKeywords(
          productKeywords.map((k) => ({
            id: k.id,
            name: k.name,
            selected: false
          }))
        )
      }
    }
  }, [product, productKeywords, keywords, setKeywords])

  useEffectOnce(() => {
    if (product) {
      setName(product.name)
      setBody(product.body)
      setPicture(product.imageResource?.sizes.vignette)
    }
  })

  useEffect(() => {
    if (!productKeywords && keywords.length === 0) {
      dispatch(loadProductKeywords())
    }
  }, [productKeywords, keywords, dispatch])

  return (
    <FormContainer>
      {error &&
        <StyledError>
          {error}
        </StyledError>
      }
      <FormControl>
        <TextField
          id="name"
          label={<FormattedMessage id="product.form.name" />}
          value={name || ''}
          error={nameDirty && !name}
          onChange={handleChangeName}
          InputLabelProps={{ disableAnimation: true }}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="body"
          label={<FormattedMessage id="product.form.body" />}
          value={body || ''}
          onChange={handleChangeBody}
          InputLabelProps={{ shrink: false, disableAnimation: true }}
        />
      </FormControl>
      <FormControl>
        <Label>
          <FormattedMessage id="product.form.keywords.label" />
        </Label>
        <StyledChipContainer>
          {keywords &&
            keywords.map((keyword, index) => (
              <Chip
                key={index}
                label={keyword.name}
                color={keyword.selected ? 'primary' : 'default'}
                clickable
                onClick={() => toggleKeyword(index)}
              />
            ))}
          {!loadingProductKeywords && productKeywords && keywords.length === 0 && (
            <Alert severity="warning">
              <FormattedMessage id="keywords.list.empty" />
            </Alert>
          )}
          {(loadingProductKeywords || !productKeywords) && <Loading />}
        </StyledChipContainer>
        {errorCategory && (
          <Alert severity="warning">
            <FormattedMessage id="product.form.errorKeyword" />
          </Alert>
        )}
      </FormControl>
      <FormControl>
        <Label>
          <FormattedMessage id="product.form.image.label" />
        </Label>
        <FormPictureUploader image={picture} square onChangeImage={handleChangePicture}>
          <AddIcon titleAccess={intl.formatMessage({ id: 'titleAccess.ProfilForm.AddIcon' })} />
        </FormPictureUploader>
      </FormControl>
      <StyledAppButton onClick={product ? handleSubmitEdit : handleSubmitAdd} disabled={!name}>
        {product ? (
          productUpdating ? (
            <Loading color="inherit" />
          ) : (
            <FormattedMessage id="product.form.submitButtonEdit" />
          )
        ) : productAdding ? (
          <Loading color="inherit" />
        ) : (
          <FormattedMessage id="product.form.submitButton" />
        )}
      </StyledAppButton>
    </FormContainer>
  )
}

const StyledError = styled.div`
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
  background-color: ${RED};
  color: ${WHITE};
  border-radius: ${BORDER_RADIUS_CARD};
`

const StyledAppButton = styled(AppButton)`
  & {
    max-width: 190px;
    margin: 0 auto;
  }
`

const StyledChipContainer = styled.div`
  & {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    & > * {
      margin: 3px;
    }
  }
`

export default ExhibitorProductForm
