import styled from '@emotion/styled'
import React, { FC, useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { MagicStreamStatus } from '../../entities'
import { IAgenda } from '../../entities/agenda'
import { useDateFns, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { setAgenda, setPlanning, setPlanningLength, setPlanningOrder } from '../../store/app'
import { breakpoints } from '../breakpoints'
import AgendaConference from './AgendaConference'
import AgendaItem from './AgendaItem'

const Agenda: FC = () => {
  const dateFns = useDateFns()
  const agenda = useSelector((state: IRootState) => state.appState.agenda)
  const planning = useSelector((state: IRootState) => state.appState.planning)
  const planningOrder = useSelector((state: IRootState) => state.appState.planningOrder)
  const planningLength = useSelector((state: IRootState) => state.appState.planningLength)
  const dispatch = useThunkDispatch()

  const formatRelativeToday = dateFns.formatRelativeToday

  const formatPlanning = useCallback(() => {
    let currentPlanningLength = 0
    const currentPlanningOrder: string[] = []
    const currentPlanning: { [key: string]: IAgenda[] } = {}
    agenda.sort((item1, item2) => new Date(item1.dateStart).getTime() - new Date(item2.dateStart).getTime())
      .forEach((item) => {
        if (
          item.type === 'event' ||
          item.type === 'unavailability' ||
          (item.magicstream &&
            (item.magicstream.status === MagicStreamStatus.ACCEPTED ||
              (item.magicstream.expirationDate &&
                new Date() <= new Date(item.magicstream.expirationDate))))
        ) {
          // filter unavailability or accepted or not expirated
          const dateRelative = formatRelativeToday(item.dateStart)
          if (!currentPlanning[dateRelative]) {
            currentPlanningOrder.push(dateRelative)
            currentPlanning[dateRelative] = [] as IAgenda[]
          }
          currentPlanning[dateRelative].push(item)
          currentPlanningLength++
        }
      })
    return { currentPlanningLength, currentPlanning, currentPlanningOrder }
  }, [agenda, formatRelativeToday])

  useEffect(() => {
    if (agenda.length) {
      const { currentPlanningLength, currentPlanning, currentPlanningOrder } = formatPlanning()
      dispatch(setPlanningOrder(currentPlanningOrder))
      dispatch(setPlanning(currentPlanning))
      dispatch(setPlanningLength(currentPlanningLength))
      // reset agenda to avoid multiple call
      dispatch(setAgenda([]))
    }
  }, [agenda, formatPlanning, dispatch])

  return (
    <StyledAgenda>
      {planningOrder.map((order, orderIndex) => (
        <div key={orderIndex}>
          <StyledTitleDay>{order}</StyledTitleDay>
          {planning[order] &&
            planning[order].map((agendaItem, index) => (
              <>
                {agendaItem.type === 'event' ?
                  <AgendaConference key={index} item={agendaItem} />
                  :
                  <AgendaItem key={index} item={agendaItem} />
                }
              </>
            ))}
        </div>
      ))}
      {planningLength === 0 && <FormattedMessage id="agenda.empty" />}
    </StyledAgenda>
  )
}

const StyledAgenda = styled.div`
  @media (min-width: ${breakpoints.md}) {
    padding: 15px;
  }
`

const StyledTitleDay = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
`

export default Agenda
