import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import {
  alpha,
  Avatar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar
} from '@material-ui/core'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgConferences } from '../../assets/images/icons/conferences.svg'
import { ReactComponent as SvgExhibitors } from '../../assets/images/icons/exposants.svg'
import { ReactComponent as Help } from '../../assets/images/icons/help.svg'
import { ReactComponent as SvgHome } from '../../assets/images/icons/home.svg'
import { ReactComponent as SvgParticipants } from '../../assets/images/icons/participants.svg'
import { ReactComponent as SvgProducts } from '../../assets/images/icons/produits.svg'
import { useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setOpenMenu } from '../../store/app'
import { setPageReferral } from '../../store/gtm'
import { BLACK_BLUE, PRIMARY, WHITE } from '../../theme/colors'
import {
  BORDER_RADIUS_CARD,
  CONTACTS_MENU_WIDTH,
  HEADER_HEIGHT,
  LEFT_MENU_WIDTH
} from '../../theme/sizes'
import { breakpoints } from '../breakpoints'

const Menu: React.FC = () => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const path = window.location.pathname.replace(/\/$/, '')
  const openMenu = useSelector((state: IRootState) => state.appState.openMenu)
  const currentLocation = useSelector((state: IRootState) => state.appState.location)
  const totalConferences = useSelector(
    (state: IRootState) => state.conferenceState.totalConferences
  )
  const totalExhibitors = useSelector((state: IRootState) => state.exhibitorState.totalExhibitors)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const dispatch = useThunkDispatch()

  const defaultMenuItems = useMemo(() => {
    const menu = [
      {
        text: intl.formatMessage({ id: 'menu.home' }),
        icon: <StyledSvgHome />,
        to: '/accueil'
      }
    ]
    // conferences
    if (totalConferences > 0) {
      menu.push({
        text: intl.formatMessage({ id: 'menu.conferences' }),
        icon: <StyledSvgConferences />,
        to: '/conference'
      })
    }
    // exhibitors
    if (totalExhibitors > 0) {
      menu.push({
        text: intl.formatMessage({ id: 'menu.exhibitors' }),
        icon: <StyledSvgExhibitors />,
        to: '/exposant'
      })
    }
    // participants
    menu.push({
      text: intl.formatMessage({ id: 'menu.participants' }),
      icon: <StyledSvgParticipants />,
      to: '/participant'
    })
    // products
    if (eventCo?.displayProducts) {
      menu.push({
        text: intl.formatMessage({ id: 'menu.products' }),
        icon: <StyledSvgProducts />,
        to: '/produit'
      })
    }
    return menu
  }, [intl, eventCo, totalConferences, totalExhibitors])

  const onMenuChange = (menu: any) => {
    dispatch(setPageReferral(`${pageReferral}::main-menu`))
    sendDataToGTM({ event: 'click-menu-main', linkText: menu.text, linkUrl: menu.to })
    browserHistory.push(menu.to)
    dispatch(setOpenMenu(false))
  }

  return (
    <StyledDrawer variant="permanent" open={openMenu}>
      <Toolbar />
      <StyledMenu>
        <List disablePadding>
          {defaultMenuItems.map((menu, index) => (
            <React.Fragment key={index}>
              <StyledListItem
                button
                onClick={() => onMenuChange(menu)}
                className={(currentLocation || path).indexOf(menu.to) > -1 ? 'active' : ''}
              >
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText primary={menu.text} />
              </StyledListItem>
            </React.Fragment>
          ))}
        </List>
        <StyledBoxHelp>
          <a
            href="https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=idealco-help.zendesk.com&settings=JTdCJTIyd2ViV2lkZ2V0JTIyJTNBJTdCJTIyY2hhdCUyMiUzQSU3QiUyMnRpdGxlJTIyJTNBJTdCJTIyKiUyMiUzQSUyMkNvbnRhY3Rlei1ub3VzJTIyJTdEJTJDJTIybWVudU9wdGlvbnMlMjIlM0ElN0IlMjJlbWFpbFRyYW5zY3JpcHQlMjIlM0F0cnVlJTdEJTJDJTIyZGVwYXJ0bWVudHMlMjIlM0ElN0IlN0QlMkMlMjJwcmVjaGF0Rm9ybSUyMiUzQSU3QiUyMmRlcGFydG1lbnRMYWJlbCUyMiUzQW51bGwlMkMlMjJncmVldGluZyUyMiUzQW51bGwlN0QlMkMlMjJvZmZsaW5lRm9ybSUyMiUzQSU3QiUyMmdyZWV0aW5nJTIyJTNBbnVsbCU3RCUyQyUyMmNvbmNpZXJnZSUyMiUzQSU3QiUyMmF2YXRhclBhdGglMjIlM0FudWxsJTJDJTIybmFtZSUyMiUzQW51bGwlMkMlMjJ0aXRsZSUyMiUzQW51bGwlN0QlN0QlMkMlMjJjb2xvciUyMiUzQSU3QiUyMmFydGljbGVMaW5rcyUyMiUzQSUyMiUyMiUyQyUyMmJ1dHRvbiUyMiUzQSUyMiUyMiUyQyUyMmhlYWRlciUyMiUzQSUyMiUyMiUyQyUyMmxhdW5jaGVyJTIyJTNBJTIyJTIyJTJDJTIybGF1bmNoZXJUZXh0JTIyJTNBJTIyJTIyJTJDJTIycmVzdWx0TGlzdHMlMjIlM0ElMjIlMjIlMkMlMjJ0aGVtZSUyMiUzQW51bGwlN0QlN0QlN0Q=&&locale=fr-fr&title=Chat%20en%20direct%20du%20Web%20Widget"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledAvatarHelp>
              <Help />
            </StyledAvatarHelp>
          </a>
        </StyledBoxHelp>
      </StyledMenu>
    </StyledDrawer>
  )
}

const StyledSvgHome = styled(SvgHome)`
  & {
    height: 24px;
    width: 24px;
  }
`

const StyledSvgConferences = styled(SvgConferences)`
  & {
    height: 24px;
    width: 24px;
  }
`

const StyledSvgExhibitors = styled(SvgExhibitors)`
  & {
    height: 24px;
    width: 24px;
  }
`

const StyledSvgParticipants = styled(SvgParticipants)`
  & {
    height: 24px;
    width: 24px;
  }
`

const StyledSvgProducts = styled(SvgProducts)`
  & {
    height: 24px;
    width: 24px;
  }
`

const StyledListItem = styled(ListItem)`
  & {
    padding-top: 12px;
    padding-bottom: 12px;

    &:hover {
      background-color: transparent;
      .MuiTypography-root {
        color: ${PRIMARY};
      }
    }

    @media (min-width: ${breakpoints.lg}) {
      display: flex;
      flex-direction: column;
    }
    svg {
      color: ${PRIMARY};
    }
    .MuiTypography-root {
      font-size: 16px;
      font-weight: bold;
      color: ${alpha(BLACK_BLUE, 0.38)};

      @media (min-width: ${breakpoints.lg}) {
        font-size: 12px;
      }
    }
    .MuiListItemText-root {
      white-space: normal;
      text-align: center;
      @media (max-width: ${breakpoints.lg}) {
        margin-left: 10px;
      }
    }
    .MuiListItemIcon-root {
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f4f4f8;
      border-radius: ${BORDER_RADIUS_CARD};
    }
    &.active {
      position: relative;

      .MuiListItemIcon-root {
        background-color: ${PRIMARY};
      }
      svg {
        color: ${WHITE};
      }
      .MuiTypography-root {
        color: ${PRIMARY};
      }
    }
  }
`

const StyledDrawer = styled(Drawer)<{ open: boolean }>`
  & {
    flex-shrink: 0;

    > div {
      width: ${(props) => `${props.open ? CONTACTS_MENU_WIDTH : LEFT_MENU_WIDTH}`};
      overflow-x: ${(props) => `${props.open ? 'auto' : 'hidden'}`};
      white-space: nowrap;
      transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      border-right: none;
      z-index: 1201;
    }
    .MuiListItem-button {
      white-space: nowrap !important;
    }
    .MuiToolbar-regular {
      min-height: ${HEADER_HEIGHT};
    }
    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
    @media (max-width: ${breakpoints.lg}) {
      > div {
        transition: left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        left: ${(props) => `${props.open ? '0' : '-100%'}`};
        width: 100%;
      }
    }
  }
`

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

const StyledBoxHelp = styled(Box)`
  & {
    color: #fff;
    width: 47px;
    height: 47px;
    border-radius: 50%;
  }
`

const StyledAvatarHelp = styled(Avatar)`
  & {
    background-color: #3f3358;
  }
`

export default Menu
