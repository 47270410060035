import { Location } from 'history'
import { IStream } from '../entities'
import { CommonStream } from './CommonStream'
import { ConnectStream, CreateStream, IMediaStream } from './IMediaStream'

export class ZoomStream extends CommonStream implements IMediaStream {
  ready = false

  preload(callback: () => void, location?: Location<any>): void {
    this.ready = true
    callback()
  }

  init({
    apiKey,
    sessionId,
    token,
    options,
    onStreamsUpdated,
    onConnect,
    onError
  }: CreateStream): void {
    throw new Error('Method not implemented.')
  }
  isInit(): boolean {
    throw new Error('Method not implemented.')
  }

  connect(connectStream: ConnectStream): void {
    throw new Error('Method not implemented.')
  }

  // tslint:disable-next-line: no-empty
  disconnect(): void { }

  createPublisher(): void {
    throw new Error('Method not implemented.')
  }

  publish(): void {
    throw new Error('Method not implemented.')
  }

  destroyPublisher(): void {
    throw new Error('Method not implemented.')
  }

  createSubscriber(): void {
    throw new Error('Method not implemented.')
  }
  destroySubscriber(): void {
    throw new Error('Method not implemented.')
  }
  getStreams(): IStream[] {
    throw new Error('Method not implemented.')
  }

  publishVideo(publish: boolean): void {
    if (publish) {
      this.startVideo()
    } else {
      this.stopVideo()
    }
  }
  startVideo(): void {
    throw new Error('Method not implemented.')
  }
  stopVideo(): void {
    throw new Error('Method not implemented.')
  }

  publishAudio(publish: boolean): void {
    if (publish) {
      this.startAudio()
    } else {
      this.stopAudio()
    }
  }
  startAudio(): void {
    throw new Error('Method not implemented.')
  }
  stopAudio(): void {
    throw new Error('Method not implemented.')
  }

  subscribeToAudio(stream: IStream, muted: boolean): void {
    throw new Error('Method not implemented.')
  }
  mute(): void {
    throw new Error('Method not implemented.')
  }
  unmute(): void {
    throw new Error('Method not implemented.')
  }

  startScreenShare(): void {
    throw new Error('Method not implemented.')
  }
  stopScreenShare(): void {
    throw new Error('Method not implemented.')
  }
  takePhoto(): string {
    throw new Error('Method not implemented.')
  }
}
