import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import ReactPlayer from 'react-player'
import AppPlayer from '../../components/globals/AppPlayer/AppPlayer'
import UserAvatar from '../../components/globals/UserAvatar/UserAvatar'
import { INotification } from '../../entities'
import { useDateFns } from '../../hooks'
import settings from '../../settings'
import { GRAY } from '../../theme/colors'

type IAnnounceRenderProps = {
  notification: INotification
}

const AnnounceRender: FC<IAnnounceRenderProps> = ({ notification }) => {
  const dateFns = useDateFns()

  const isVideoUrl = useMemo(
    () =>
      notification.params && notification.params.url
        ? ReactPlayer.canPlay(notification.params.url)
        : false,
    [notification]
  )

  const isInternalUrl = useMemo(
    () =>
      notification.params && notification.params.url
        ? notification.params.url.indexOf(settings.theme.header.url) > -1 ||
        notification.params.url.indexOf('http') === -1
        : false,
    [notification]
  )

  if (!notification.message) return <></>

  return (
    <StyledAnnounceContainer>
      <StyledAnnounce>
        {notification.params && notification.params.user && (
          <UserAvatar
            popper={{ placement: 'left' }}
            user={notification.params.user}
            showDetails
            disabledAnimation
          />
        )}
        <StyledAnnounceMessage
          dangerouslySetInnerHTML={{ __html: notification.message }}
        ></StyledAnnounceMessage>
      </StyledAnnounce>
      {notification.params &&
        notification.params.url &&
        (isVideoUrl ? (
          <StyledAnnouncePlayer>
            <AppPlayer url={notification.params.url} controls pip muted volume={0} />
          </StyledAnnouncePlayer>
        ) : (
          <StyledAnnounceButton>
            <Button
              color="primary"
              href={notification.params.url}
              target={isInternalUrl ? '' : '_blank'}
            >
              <FormattedMessage id="click.here" />
            </Button>
          </StyledAnnounceButton>
        ))}
      {notification.params && notification.params.createDate && (
        <StyledDate>{dateFns.formatDistanceToNow(notification.params.createDate)}</StyledDate>
      )}
    </StyledAnnounceContainer>
  )
}

const StyledAnnounceContainer = styled.div`
  width: 100%;

  &:hover {
    span {
      opacity: 1;
    }
  }
`

const StyledAnnounce = styled.div`
  & {
    display: flex;
    flex-direction: row;
  }
`

const StyledAnnounceMessage = styled.div`
  & {
    margin-left: 12px;
  }
`

const StyledAnnouncePlayer = styled.div`
  & {
    height: auto;
    max-width: 500px;
    overflow: auto;
    margin-top: 10px;
    margin-left: 52px;
    margin-bottom: 20px;
  }
`

const StyledAnnounceButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  & > a {
    margin-bottom: 10px;
  }
`

const StyledDate = styled.span`
  position: absolute;
  right: 3px;
  bottom: 1px;
  opacity: 0;
  font-size: 10px;
  color: ${GRAY};
`

export default AnnounceRender
