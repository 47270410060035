import { LogUserUrlTypes } from './enums'
import { IContactKeyword, IKeywordUser } from './keyword'

export enum ROLES {
  ROLE_UTILISATEUR = 'ROLE_UTILISATEUR',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_ABONNE = 'ROLE_ABONNE',
  ROLE_ANIMATEUR = 'ROLE_ANIMATEUR',
  ROLE_ANIMATEUR_IDEAL = 'ROLE_ANIMATEUR_IDEAL',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_ANNOUNCEMENT_EXHIBITOR = 'announcement_exhibitor',
  ROLE_ANNOUNCEMENT_CONFERENCE = 'announcement_business_event',
  ROLE_ANNOUNCEMENT_CRM = 'enhanced_crm'
}

export enum GROUP_ROLES {
  ROLE_VISITOR = '1',
  ROLE_EXHIBITOR = '2',
  ROLE_SPEAKER = '3',
  ROLE_CONTRACTOR = '4',
  ROLE_ORDERTAKER = '5',
  ROLE_EXPERT = '6'
}

export interface IUser {
  id: number
  username?: string
  firstname: string
  lastname: string
  avatar: string
  avatarPath: string
  description: string
  descriptionHtml: string
  email: string
  function: string
  phone: string
  partner_id: IPartner
  keywords?: IKeywordUser[]
  roles?: (ROLES | string)[]
  note?: IContactNote
  isContact?: boolean
  userGroupRoles?: { role: GROUP_ROLES | number }[]
  isCalendarSynchronized?: boolean
  dataUser?: IDataUser
  isOnSite?: boolean
}

export type IUserUpdate = {
  firstname?: string
  lastname?: string
  avatar?: string
  description?: string
  descriptionHtml?: string
  function?: string
  email?: string
  phone?: string
}

export interface IPartner {
  id: number
  avatarPath: string
  description: string
  name: string
  street: string
  city: string
  zip: string
  website: string
}

export interface IContact {
  id: number
  relationship?: string
  contactId?: number
  userPresence: IContactUserPresence
  userStatus?: IContactUserStatus
  note?: IContactNote
  contactKeywords?: IContactKeyword[]
  isContact?: boolean
  score?: number
  isOnSite?: boolean
}

export interface IContactUserPresence {
  isOnline: boolean
  user: IContactUser
}

export interface IContactUser {
  id: number
  avatar?: string
  avatarPath?: string
  firstname: string
  lastname: string
  partner_id?: IContactPartnerId
  partnerName?: string
  function?: string
  description?: string
  note?: IContactNote
  isContact?: boolean
}
export interface INotificationUser {
  id: number
  firstname: string
  lastname: string
  avatar?: string
  avatarPath?: string
  note?: IContactNote
  isContact?: boolean
}

export interface IContactPartnerId {
  name: string
}

export interface IContactUserStatus {
  id: number
  appName: string
  createDate: string
  description: string
  uid: number
  url: string
}

export interface IContactNote {
  id: number
  note: string
}

export type IUserLog = {
  action: string
  description: string
  url: string
  urlType: LogUserUrlTypes
}

export type IUserLogVideo = {
  url: string
  urlType: 'vimeo'
  objectType: 'event'
  objectId: number // event_id
  timestamp: number // timestamp du début de la séance de visionnage
  actionDuration: number // durée totale de visualisation en seconde
}

export type IUserStatus = {
  description: string
  uid: string
  url: string
}

export interface IRegistration {
  id: number
  user: string
  group: string
  status: string
}

export interface IBindUser {
  count: number
  display: boolean
  users: {
    id: number
    avatar?: string
    avatarPath?: string
    firstname: string
    lastname: string
    partner_id?: IContactPartnerId
    partnerName?: string
    function?: string
    description?: string
    status?: string | IContactUserStatus
  }[]
}

export enum PROFILINGS {
  USER = 'USER',
  EXHIBITOR = 'EXHIBITOR',
  CONFERENCE = 'CONFERENCE'
}

export type IContactAutocomplete = {
  id: number | undefined
  contact: IContactUser | undefined
}

export interface IDataUser {
  id: number
  preferredLanguage: string
}

export type IDataUserCreate = {
  id?: number
  userId: string
  preferredLanguage?: string
}

export type IDataUserUpdate = {
  preferredLanguage?: string
}
