import useGTM from '@elgorditosalsero/react-gtm-hook'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { IconButton, Tooltip } from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IExhibitor, IExhibitorFull } from '../../entities'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { addExhibitorToFavorite, removeExhibitorFromFavorite } from '../../store/exhibitor'
import { GRAY, SECONDARY, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import Loading from '../Loading'

type IExhibitorFavoriteButtonProps = {
  exhibitor: IExhibitor | IExhibitorFull
  decal?: boolean
}

const ExhibitorFavoriteButton: React.FC<IExhibitorFavoriteButtonProps> = ({ exhibitor, decal }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [favorite, setFavorite] = useState(false)
  const addingExhibitorToFavorites = useSelector(
    (state: IRootState) => state.exhibitorState.addingExhibitorToFavorites
  )
  const removingExhibitorFromFavorites = useSelector(
    (state: IRootState) => state.exhibitorState.removingExhibitorFromFavorites
  )
  const dispatch = useThunkDispatch()

  const isLoading = useMemo(
    () => addingExhibitorToFavorites[exhibitor.id] || removingExhibitorFromFavorites[exhibitor.id],
    [addingExhibitorToFavorites, removingExhibitorFromFavorites, exhibitor]
  )

  const updateFavorite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setFavorite(!favorite)
    if (exhibitor) {
      if (favorite) {
        dispatch(removeExhibitorFromFavorite(exhibitor.id))
      } else {
        sendDataToGTM({ event: 'exhibitor-favorite', exhibitorName: exhibitor.name })
        magicStream.success(intl.formatMessage({ id: 'favorite.exhibitor.success' }))
        dispatch(addExhibitorToFavorite(exhibitor.id))
      }
    }
  }

  useEffect(() => {
    setFavorite(exhibitor && exhibitor.isFollowed)
  }, [exhibitor])

  return (
    <StyledButtonFavorite isFavorite={favorite}>
      <Tooltip
        title={intl.formatMessage({
          id: isLoading
            ? 'favorite.saving'
            : favorite
              ? 'favorite.exhibitor.true'
              : 'favorite.exhibitor.false'
        })}
      >
        <IconButton
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={updateFavorite}
          color={favorite ? 'primary' : 'default'}
        >
          {!isLoading && !favorite && (
            <FavoriteBorderIcon
              titleAccess={intl.formatMessage({
                id: 'titleAccess.FavoriteBorder'
              })}
            />
          )}
          {!isLoading && favorite && (
            <FavoriteIcon
              titleAccess={intl.formatMessage({
                id: 'titleAccess.Favorite'
              })}
            />
          )}
          {isLoading && <Loading size="1rem" />}
        </IconButton>
      </Tooltip>
    </StyledButtonFavorite>
  )
}

const StyledButtonFavorite = styled.div<{ isFavorite: boolean }>`
  & {
    cursor: pointer;
    margin-right: 5px;

    &:hover {
      svg {
        color: ${SECONDARY};
      }
    }
    .MuiIconButton-root {
      border-radius: ${BORDER_RADIUS_CARD};
    }
    svg {
      color: ${GRAY};
    }
    ${(props) =>
    props.isFavorite
      ? css`
            & {
              .MuiIconButton-root {
                background-color: ${SECONDARY};
              }
              svg {
                color: ${WHITE} !important;
              }
            }
          `
      : ''};
  }
`

export default ExhibitorFavoriteButton
