import React from 'react'

const SvgFavoriteFull: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.fill || 'currentColor'}
        d="M37.0907467,43.8529723 L25.5582821,38.1667564 C25.0547187,37.9193917 24.4653122,37.9263445 23.9675946,38.1855208 L12.8896644,43.8334932 L14.6507499,30.9677786 C14.728581,30.4136142 14.5465736,29.8544274 14.1584544,29.4552847 L5.42861238,20.5335988 L18.059352,18.2552524 C18.6034953,18.1588723 19.0723835,17.8121824 19.3281421,17.3171255 L25.028047,6.15536351 L31.0688818,17.604309 C31.3285229,18.1041644 31.8054181,18.4515971 32.3565791,18.5424359 L44.6086109,20.5718423 L35.7083432,29.9147426 C35.3144987,30.3182071 35.1320314,30.8858942 35.2160477,31.4463582 L37.0907467,43.8529723 L37.0907467,43.8529723 Z"
      />
      <path
        fill={props.fill || 'currentColor'}
        d="M39.4578395,48.7542139 C39.9599769,48.7283506 40.4277912,48.4886932 40.7453853,48.0946147 C41.0629794,47.7005363 41.2006402,47.1889055 41.1242634,46.6864747 L38.8708021,31.7913537 L49.5132176,20.5913215 C49.9632597,20.1107475 50.1156278,19.4189856 49.9098129,18.7907502 C49.7039981,18.1625147 49.1733118,17.6994866 48.5285016,17.5855447 L33.795767,15.1540456 L26.5809199,1.44595584 C26.2696634,0.858604904 25.6625078,0.493592276 25.0034436,0.497568574 C24.3443794,0.501610248 23.741612,0.873982652 23.4373683,1.46507758 L16.5823495,14.8668621 L1.4708142,17.5855447 C0.821505206,17.7005474 0.288469504,18.1694066 0.085980051,18.8036475 C-0.116509402,19.4378884 0.044804942,20.1333411 0.505076957,20.6104432 L11.0149029,31.3510176 L8.89399894,46.7248075 C8.8100124,47.3831052 9.09176998,48.0346054 9.62680631,48.4192575 C10.1618426,48.8039096 10.8630268,48.8590729 11.4504765,48.5627285 L24.800811,41.8044442 L38.5867459,48.5818502 C38.8580208,48.7128581 39.1577298,48.7721616 39.4578395,48.7542139 L39.4578395,48.7542139 Z M37.0907467,43.8529723 L25.5582821,38.1667564 C25.0547187,37.9193917 24.4653122,37.9263445 23.9675946,38.1855208 L12.8896644,43.8334932 L14.6507499,30.9677786 C14.728581,30.4136142 14.5465736,29.8544274 14.1584544,29.4552847 L5.42861238,20.5335988 L18.059352,18.2552524 C18.6034953,18.1588723 19.0723835,17.8121824 19.3281421,17.3171255 L25.028047,6.15536351 L31.0688818,17.604309 C31.3285229,18.1041644 31.8054181,18.4515971 32.3565791,18.5424359 L44.6086109,20.5718423 L35.7083432,29.9147426 C35.3144987,30.3182071 35.1320314,30.8858942 35.2160477,31.4463582 L37.0907467,43.8529723 L37.0907467,43.8529723 Z"
      />
    </g>
  </svg>
)

export default SvgFavoriteFull
