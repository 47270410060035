import styled from '@emotion/styled'
import { Button, MenuItem, Switch } from '@material-ui/core'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ConferenceStatus } from '../../entities'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { setSendedLive } from '../../store/chat'
import ConfirmDialog from '../modals/ConfirmDialog'
import { Label } from '../ui/LabelWrapper/LabelWrapper'

interface IConferenceLiveButton {
  menuMode?: boolean
  status: string
  updateConferenceStatus: (confStatus: string, notify: boolean) => void
  setOpen?: (open: boolean) => void
}

const ConferenceLiveButton: React.FC<IConferenceLiveButton> = ({
  menuMode,
  status,
  updateConferenceStatus,
  setOpen
}) => {
  const intl = useIntl()
  const [confirm, setConfirm] = useState(false)
  const [goLive, setGoLive] = useState(false)
  const [notify, setNotify] = useState(false)
  const [confStatus, setConfStatus] = useState(status)
  const updating = useSelector((state: IRootState) => state.conferenceState.updating)
  const sendedLive = useSelector((state: IRootState) => state.chatState.sendedLive)
  const dispatch = useThunkDispatch()

  const handleLiveAction = (newStatus: string) => {
    setConfStatus(newStatus)
    setConfirm(true)
  }

  const handleClick = () => {
    handleLiveAction(status === ConferenceStatus.OPEN ? ConferenceStatus.IN_PROGRESS : ConferenceStatus.OPEN)
  }

  const onConfirm = () => {
    if (confStatus === ConferenceStatus.IN_PROGRESS && goLive) {
      updateConferenceStatus(confStatus, notify)
    } else if (confStatus === ConferenceStatus.OPEN) {
      updateConferenceStatus(confStatus, true)
    }
    setConfirm(false)
    setGoLive(false)
    setNotify(false)
    setOpen && setOpen(false)
  }

  useEffect(() => {
    if (sendedLive) {
      dispatch(setSendedLive(false))
    }
  }, [sendedLive, dispatch])

  return (
    <StyledDiv menumode={menuMode}>
      {menuMode && (
        <MenuItem onClick={handleClick}>
          <FormattedMessage id={status === ConferenceStatus.OPEN ? 'go.live' : 'stop.live'} />
        </MenuItem>
      )}
      {!menuMode && (
        <StyledButton
          disabled={updating}
          variant="contained"
          color="secondary"
          onClick={handleClick}
        >
          <FormattedMessage id={status === ConferenceStatus.OPEN ? 'go.live' : 'stop.live'} />
        </StyledButton>
      )}
      <ConfirmDialog
        open={confirm}
        message={intl.formatMessage({
          id: status === ConferenceStatus.OPEN ? 'confirm.go.live' : 'confirm.stop.live'
        })}
        disableOk={status === ConferenceStatus.OPEN && !goLive}
        okEvent={onConfirm}
        cancelEvent={() => setConfirm(false)}
      >
        {status === ConferenceStatus.OPEN && (
          <>
            <Label id="go-live-confirm-label">
              <FormattedMessage id="go.live.confirm" />
            </Label>
            <Switch
              aria-labelledby="go-live-confirm-label"
              checked={goLive}
              disabled={updating}
              color="primary"
              title={intl.formatMessage({ id: 'go.live.confirm' })}
              onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                setGoLive(checked)
              }
              name="goLive"
            ></Switch>
            <Label id="go-live-notify-label">
              <FormattedMessage id="go.live.notify" />
            </Label>
            <Switch
              aria-labelledby="go-live-notify-label"
              checked={notify}
              disabled={updating}
              color="primary"
              title={intl.formatMessage({ id: 'go.live.notify' })}
              onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                setNotify(checked)
              }
              name="goLive"
            ></Switch>
          </>
        )}
      </ConfirmDialog>
    </StyledDiv>
  )
}

const StyledDiv = styled.div<{ menumode?: boolean }>`
  margin-bottom: ${props => props.menumode ? '0' : '10px'};
`

const StyledButton = styled(Button)`
  text-transform: uppercase;
`

export default ConferenceLiveButton
