import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import useSound from 'use-sound'
import { addUserLogVideo as addUserLogVideoApi } from '../../api'
import connectedSfx from '../../assets/sounds/alarm_presence.mp3'
// import disconnectedSfx from '../../assets/sounds/disconnected.mp3'
import ConferenceContent from '../../components/conference/ConferenceContent'
import ConferenceHeader from '../../components/conference/ConferenceHeader'
import ConferenceListBusinessConferences from '../../components/conference/ConferenceListBusinessConferences'
import AppLoading from '../../components/ui/AppLoading/AppLoading'
import {
  ConferenceStatus,
  IBindUser,
  IContactUserStatus,
  IRelatedContent,
  IRoom,
  IUser,
  LogUserUrlTypes,
  NotificationType
} from '../../entities'
import { useBeforeRouteChange, useMagicStream, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import settings from '../../settings'
import { resetGroupId } from '../../store/app'
import { notificationLiveStart, notificationLiveStop, setNotification } from '../../store/chat'
import {
  getConference,
  mergeConference,
  resetConference,
  setConferenceError,
  setOpenExhibitorsChoice,
  setRelatedContent,
  setSendingNotificationShowRelated,
  updateConference
} from '../../store/conference'
import { setPageReferral } from '../../store/gtm'
import { setAroundUsers, setAroundUsersCount } from '../../store/participant'
import { loadAroundUsers } from '../../store/participant/thunks'
import { resetRoomToken, setOfficeRoomIndex } from '../../store/room'
import {
  resetChairId,
  setAutoEnterRoom,
  setHandleFullscreenExit,
  setLocations,
  setStandChannel
} from '../../store/stand'
import { WHITE } from '../../theme/colors'
import { formatName } from '../../utils'
import consoleUtils from '../../utils/consoleUtils'
import pusherUtils from '../../utils/pusherUtils'

interface IRouterParams {
  id: string
}

type Props = RouteComponentProps<IRouterParams>

const ConferencePage: React.FC<Props> = ({ match, location }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [playEnter] = useSound(connectedSfx)
  // const [playLeave] = useSound(disconnectedSfx)
  const [status, setStatus] = useState('')
  const [calledGtm, setCalledGtm] = useState(false)
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [lastVideoPlaying, setLastVideoPlaying] = useState(false)
  const [conferenceId, setConferenceId] = useState<string | null>(null)
  const [userLogVimeo, setUserLogVimeo] = useState(0)
  const [localGroupId, setLocalGroupId] = useState(0)
  const [redirectUrl, setRedirectUrl] = useState('')
  const hasPassedTunnel = useSelector((state: IRootState) => state.appState.hasPassedTunnel)
  const user = useSelector((state: IRootState) => state.appState.user)
  const groupId = useSelector((state: IRootState) => state.appState.groupId)
  const notification = useSelector((state: IRootState) => state.chatState.notification)
  const errorSending = useSelector((state: IRootState) => state.chatState.errorSending)
  const conference = useSelector((state: IRootState) => state.conferenceState.conference)
  const loading = useSelector((state: IRootState) => state.conferenceState.loading)
  const error = useSelector((state: IRootState) => state.conferenceState.error)
  const openExhibitorsChoice = useSelector(
    (state: IRootState) => state.conferenceState.openExhibitorsChoice
  )
  const officeRoomIndex = useSelector((state: IRootState) => state.roomState.officeRoomIndex)
  const alarm = useSelector((state: IRootState) => state.standState.alarm)
  const autoEnterRoom = useSelector((state: IRootState) => state.standState.autoEnterRoom)
  const standChannel = useSelector((state: IRootState) => state.standState.standChannel)
  const locations = useSelector((state: IRootState) => state.standState.locations)
  const chairId = useSelector((state: IRootState) => state.standState.chairId)
  const aroundUsers = useSelector((state: IRootState) => state.participantState.aroundUsers)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const { Prompt, setDirty, setPristine } = useBeforeRouteChange()
  const dispatch = useThunkDispatch()

  const hasAlarm = useMemo(
    () => conference && alarm === `conference-${conference.id}`,
    [alarm, conference]
  )

  const findIndexRoomById = (rooms: IRoom[], id: number) => {
    // let try to find index of room by id
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i] && rooms[i].id === id) {
        return `${i}`
      }
    }
    // or if id can be an index
    if (id < rooms.length) return `${id}`
    return '-1'
  }

  const findRoomOfficeIndex = (rooms: IRoom[], officeUser: IUser) => {
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i] && rooms[i].userPresence && rooms[i].userPresence!.user.id === officeUser.id) {
        return i
      }
    }
    return -1
  }

  const liveInProgress = useMemo(
    () =>
      !!conference &&
      !!conference.eventCoUrlVimeo &&
      conference.status === ConferenceStatus.IN_PROGRESS,
    [conference]
  )

  const timeStart = useMemo(() => (liveInProgress ? Date.now() : null), [liveInProgress])

  const updateConferenceStatus = (confStatus: string, notify: boolean) => {
    if (conference) {
      dispatch(updateConference(conference.id, { status: confStatus }))
      if (notify) {
        if (confStatus === ConferenceStatus.IN_PROGRESS) {
          dispatch(
            notificationLiveStart(
              conference.group.id,
              intl.formatMessage(
                { id: 'notification.start.live' },
                { title: `<b>${conference.title}</b>` }
              ),
              conference.eventCoUrlVimeo
            )
          )
        } else if (confStatus === ConferenceStatus.OPEN) {
          dispatch(
            notificationLiveStop(
              conference.group.id,
              intl.formatMessage(
                { id: 'notification.stop.live' },
                { title: `<b>${conference.title}</b>` }
              )
            )
          )
        }
      }
    }
  }

  const enterBind = useCallback(
    (usersEnter: IBindUser) => {
      if (
        usersEnter &&
        conference &&
        conference.group.id &&
        groupId === conference.group.id &&
        user
      ) {
        const filterUsersEnter = usersEnter.users.filter((userEnter) => userEnter.id !== user.id)
        if (filterUsersEnter.length > 0) {
          const filterUsersEnterIds = filterUsersEnter.map((filterUserLeave) => filterUserLeave.id)
          consoleUtils.log(
            '[EnterBind] > ids=',
            filterUsersEnterIds,
            ', groupId=',
            groupId,
            'usersEnter.display=',
            usersEnter.display
          )
          // add all enter users into around users and remove those users from aroundUsers
          if (usersEnter.display) {
            // set number of visitor
            dispatch(setAroundUsersCount(usersEnter.count))
            dispatch(
              setAroundUsers(
                [
                  ...filterUsersEnter.map((filterUserEnter) => ({
                    id: filterUserEnter.id,
                    userPresence: {
                      isOnline: true,
                      user: {
                        ...filterUserEnter,
                        partner_id: {
                          name: filterUserEnter.partnerName || ''
                        }
                      }
                    },
                    userStatus:
                      filterUserEnter.status && (filterUserEnter.status as IContactUserStatus).id
                        ? filterUserEnter.status
                        : filterUserEnter.status
                        ? JSON.parse(filterUserEnter.status as string)
                        : null
                  })),
                  ...aroundUsers.filter(
                    (aroundUser) =>
                      !aroundUser.id || filterUsersEnterIds.indexOf(aroundUser.id) === -1
                  )
                ].slice(0, settings.list.contacts)
              )
            )
          }
          // sound when enter
          if (hasAlarm) playEnter()
        }
      }
    },
    [conference, groupId, aroundUsers, user, hasAlarm, playEnter, dispatch]
  )

  const leaveBind = useCallback(
    (usersLeave: IBindUser) => {
      if (
        usersLeave &&
        conference &&
        conference.group.id &&
        groupId === conference.group.id &&
        user
      ) {
        const filterUsersLeaveIds = usersLeave.users
          .filter((userLeave) => userLeave.id !== user.id)
          .map((filterUserLeave) => filterUserLeave.id)
        if (filterUsersLeaveIds.length > 0) {
          consoleUtils.log('[LeaveBind] > ids=', filterUsersLeaveIds, ', groupId=', groupId)
          // set number of visitor
          dispatch(setAroundUsersCount(usersLeave.count))
          // remove all leaving users from list
          dispatch(
            setAroundUsers(
              aroundUsers.filter(
                (aroundUser) => !aroundUser.id || filterUsersLeaveIds.indexOf(aroundUser.id) === -1
              )
            )
          )
          // remove users from locations
          dispatch(
            setLocations(locations.filter((loc) => filterUsersLeaveIds.indexOf(loc.user.id) === -1))
          )
          // sound when leave
          // if (hasAlarm) playLeave()
        }
      }
    },
    [conference, groupId, aroundUsers, locations, user, /* hasAlarm, playLeave,*/ dispatch]
  )

  // delay match to conferenceId to avoid multiple call
  useEffect(() => {
    if (match) {
      setConferenceId(match.params.id)
    }
  }, [match])

  // trigger location on svg for other users
  useEffect(() => {
    // need timeout to be triggered correctly, see if we can do otherwise
    if (user && standChannel) {
      pusherUtils.triggerClientLocation(
        standChannel,
        `client-location-conference-${conferenceId}`,
        {
          user: {
            id: user.id,
            username: formatName(user).full,
            firstname: user.firstname,
            lastname: user.lastname,
            avatarPath: user.avatarPath,
            isOnline: true
          },
          roomId: '-1',
          chairId: '-1',
          broadcaster: false
        }
      )

      if (hasPassedTunnel) {
        // Active autoplay video
        setVideoPlaying(settings.video.autoStart)
        setLastVideoPlaying(settings.video.autoStart)
      }
    }

    return () => {
      if (user) {
        dispatch(resetRoomToken())
        dispatch(resetChairId())
      }
    }
  }, [conferenceId, standChannel, user, hasPassedTunnel, dispatch])

  // load data for page and clean
  useEffect(() => {
    if (user && window.pusher && conferenceId) {
      dispatch(getConference(conferenceId, user.id))
      // subscribe to conference
      pusherUtils.subscribe(`private-conference-${conferenceId}`).then((channel) => {
        dispatch(setStandChannel(channel))
      })
      // subscribe to conference popin
      pusherUtils.subscribe(`conference-popin-${conferenceId}`).then((channel) => {
        if (channel) {
          channel.bind('popinOpen', (data: IRelatedContent) => {
            consoleUtils.log(`[popinOpen] Message reçu sur group ${conferenceId}=`, data)
            dispatch(setRelatedContent(data))
            // close fullscreen
            dispatch(setHandleFullscreenExit(true))
            dispatch(setOpenExhibitorsChoice({ open: true, autoRedirect: true }))
            dispatch(setSendingNotificationShowRelated(false))
          })
        }
      })
    }
    return () => {
      if (user && window.pusher) {
        dispatch(resetConference())
        dispatch(setStandChannel(null))
        pusherUtils.triggerLeaveLocation(
          window.pusher.channel(`private-conference-${conferenceId}`),
          `client-leave-conference-${conferenceId}`,
          user
        )
        pusherUtils.unsubscribe(`private-conference-${conferenceId}`)
        pusherUtils.unsubscribe(`conference-popin-${conferenceId}`)
      }
    }
  }, [dispatch, conferenceId, user])

  // set conference infos for use
  useEffect(() => {
    if (conference) {
      setLocalGroupId(conference.group.id)
      setRedirectUrl(conference.redirect_url)
      setStatus(conference.status)
      dispatch(setRelatedContent(conference.relatedContent))
    }
  }, [conference, dispatch])

  // load around users at init of page and clean
  useEffect(() => {
    if (localGroupId && user) {
      dispatch(loadAroundUsers(localGroupId))
    }
    return () => {
      if (localGroupId && user) {
        dispatch(resetGroupId())
        dispatch(setAroundUsers([]))
        dispatch(setAroundUsersCount(0))
        pusherUtils.unsubscribe(`group-${localGroupId}`)
        pusherUtils.unsubscribe(`private-group-${localGroupId}-user-${user.id}`)
      }
    }
  }, [localGroupId, user, dispatch])

  // bind of events for enter / leave page and unbind
  useEffect(() => {
    if (window.pusher && localGroupId && settings.debug.events) {
      const channel = window.pusher.channel(`group-${localGroupId}`)
      if (channel) {
        channel.bind('enter', enterBind)
        channel.bind('leave', leaveBind)
      }
    }
    return () => {
      if (window.pusher && localGroupId && settings.debug.events) {
        const channel = window.pusher.channel(`group-${localGroupId}`)
        if (channel) {
          channel.unbind('enter')
          channel.unbind('leave')
        }
      }
    }
  }, [localGroupId, standChannel, enterBind, leaveBind])

  // system to prevent change page
  useEffect(() => {
    if (chairId) {
      setDirty()
    } else {
      setPristine()
    }
  }, [chairId, setDirty, setPristine])

  // notification live start / stop / announce
  useEffect(() => {
    if (notification) {
      consoleUtils.log('[conference notification]=', notification)
      if (notification.type === NotificationType.START_LIVE) {
        dispatch(
          mergeConference({
            status: ConferenceStatus.IN_PROGRESS,
            urlVimeo: notification.params?.url
          })
        )
        if (notification.message) magicStream.global(notification.message)
      } else if (notification.type === NotificationType.STOP_LIVE) {
        // close fullscreen
        dispatch(setHandleFullscreenExit(true))
        dispatch(mergeConference({ status: ConferenceStatus.OPEN }))
        if (notification.message) magicStream.global(notification.message)
      }
      dispatch(setNotification(null))
    }
  }, [notification, intl, magicStream, dispatch])

  // auto enter into a room if param in query url
  useEffect(() => {
    // auto enter room with redirect_url
    if (redirectUrl && redirectUrl.indexOf('conference') === -1) {
      // if different page, redirect url
      browserHistory.push(redirectUrl.indexOf('/') === 0 ? redirectUrl : `/${redirectUrl}`)
    } else if (conference) {
      // else, autoEnterRoom
      const search = redirectUrl ? redirectUrl.split('?')[1] : location.search
      const salon = new URLSearchParams(search).get('salon')
      const table = new URLSearchParams(search).get('table')
      const salonIndex = table ? findIndexRoomById(conference.rooms, parseInt(table, 10)) : '-1'
      const index = parseInt(salon || salonIndex || '-1', 10)
      dispatch(
        setAutoEnterRoom({
          id: `conference-${match.params.id}`,
          roomActive: index
        })
      )
    }
  }, [match, location, redirectUrl, conference, dispatch])

  // auto enter in office room
  useEffect(() => {
    const search = new URLSearchParams(location.search).get('salon')
    if (
      user &&
      conference &&
      search === 'bureau' &&
      autoEnterRoom.roomActive === -1 &&
      officeRoomIndex === -1
    ) {
      // find room office index
      const index = findRoomOfficeIndex(conference.rooms, user)
      if (index > -1) {
        consoleUtils.log('AUTO enter in office, index=', index)
        dispatch(setOfficeRoomIndex(index))
        dispatch(
          setAutoEnterRoom({
            id: `conference-${conference.id}`,
            roomActive: index
          })
        )
      }
    }
  }, [location, conference, autoEnterRoom, user, officeRoomIndex, dispatch])

  // log duration on live stream vimeo
  useEffect(() => {
    return () => {
      if (timeStart && conferenceId) {
        const timeStop = Date.now()
        consoleUtils.log(
          'USERLOG LIVE timeStart=',
          timeStart / 1000,
          ', DURATION=',
          (timeStop - timeStart) / 1000,
          's'
        )
        addUserLogVideoApi({
          url: `/conference/${conferenceId}`,
          urlType: LogUserUrlTypes.vimeo,
          objectType: 'event',
          objectId: parseInt(conferenceId, 10),
          timestamp: timeStart / 1000,
          actionDuration: Math.round((timeStop - timeStart) / 1000)
        })
      }
    }
  }, [userLogVimeo, liveInProgress, timeStart, conferenceId])

  // timer to refresh previous useEffect each 10 secondes
  useEffect(() => {
    const timer = window.setTimeout(() => {
      setUserLogVimeo(userLogVimeo + 1)
    }, 10000)

    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [userLogVimeo])

  // gtm DATA
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (!calledGtm && conference) {
        setCalledGtm(true)
        sendDataToGTM({
          event: 'page-view',
          pageTitle: conference?.title,
          pageType: intl.formatMessage({ id: 'gtm.conference.type' }),
          pageReferral
        })
        dispatch(setPageReferral(conference?.title))
      }
    })
    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [conference, pageReferral, calledGtm, setCalledGtm, sendDataToGTM, intl, dispatch])

  useEffect(() => {
    if (error) {
      magicStream.error(error)
      dispatch(setConferenceError(undefined))
    }
  }, [error, magicStream, dispatch])

  useEffect(() => {
    if (errorSending) {
      magicStream.error(errorSending)
      dispatch(setConferenceError(undefined))
    }
  }, [errorSending, magicStream, dispatch])

  if (loading || !conference) return <AppLoading />

  return (
    <StyledConference>
      <ConferenceHeader
        goBack={location && location.state && (location?.state as any).goBack}
        status={status}
        updateConferenceStatus={updateConferenceStatus}
      />
      <ConferenceContent
        conferenceId={conferenceId}
        localGroupId={localGroupId}
        videoPlaying={videoPlaying}
        setVideoPlaying={setVideoPlaying}
        lastVideoPlaying={lastVideoPlaying}
        setLastVideoPlaying={setLastVideoPlaying}
        status={status}
        updateConferenceStatus={updateConferenceStatus}
      />
      <Prompt />
      {((conference.business_event_exhibitors &&
        conference.business_event_exhibitors.length !== 0) ||
        (conference.relatedContent &&
          (conference.relatedContent.items.length !== 0 ||
            conference.relatedContent.exhibitors.length !== 0))) && (
        <SeeNext>
          <ConferenceListBusinessConferences
            open={openExhibitorsChoice.open}
            autoRedirect={openExhibitorsChoice.autoRedirect}
            onHandle={() => dispatch(setOpenExhibitorsChoice({ open: false, autoRedirect: false }))}
          />
        </SeeNext>
      )}
      <Helmet>
        <title>{`${conference.title} - ${settings.theme.header.title}`}</title>
      </Helmet>
    </StyledConference>
  )
}

const StyledConference = styled.div`
  & {
    position: relative;
    width: 100%;
  }

  .MuiBreadcrumbs-ol {
    padding: 10px;
  }

  .MuiBreadcrumbs-li {
    display: flex;
    color: ${WHITE};

    & > p {
      color: ${WHITE};
    }
  }
  .MuiBreadcrumbs-separator {
    color: ${WHITE};
  }
`

const SeeNext = styled.div`
  margin-top: 50px;
`

export default ConferencePage
