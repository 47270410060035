import React from 'react'

const SvgArrowDown: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      // tslint:disable-next-line: max-line-length
      d="M24.726 35.15a2.414 2.414 0 0 0 1.603-.66l15.085-14.481a2.42 2.42 0 0 0-3.356-3.489L24.65 29.4 11.244 16.52a2.42 2.42 0 0 0-3.356 3.489L22.973 34.49a2.414 2.414 0 0 0 1.753.66z"
    />
  </svg>
)

export default SvgArrowDown
