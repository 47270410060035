import styled from '@emotion/styled'
import React from 'react'
import SelectSortBy from '../globals/SelectSortBy/SelectSortBy'

interface IConferenceFiltersTri {
  setSortBy: (value: string) => void
  sortBy: string
  visible: boolean
}
const ConferenceFiltersTri: React.FC<IConferenceFiltersTri> = ({ setSortBy, sortBy, visible }) => {
  return (
    <StyledFilters>
      {visible && <SelectSortBy date recommandation value={sortBy} onChange={setSortBy} />}
    </StyledFilters>
  )
}

const StyledFilters = styled.div`
  & {
    padding: 1em;
  }
`

export default ConferenceFiltersTri
