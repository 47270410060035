import { IConference, IConferenceFull } from '../../entities'

export const getProgressBarNumber = (currentLive: IConference) => {
  const timestampCurrent = Date.now()
  const timestampStartLive = new Date(currentLive.start_date).getTime()
  const timestampEndLive = new Date(currentLive.end_date).getTime()

  if (timestampStartLive > 0 && timestampEndLive > 0) {
    const pourcent =
      ((timestampCurrent - timestampStartLive) / (timestampEndLive - timestampStartLive)) * 100

    if (pourcent >= 10 && pourcent <= 100) {
      return pourcent
    } else if (pourcent < 10) {
      return pourcent + 4
    }
  }
  return 0
}

export const getDurationConference = (conference: IConference | IConferenceFull) => {
  let totalMinutes = 0

  if (conference.duration) {
    totalMinutes = conference.duration
  } else if (conference && conference.start_date && conference.end_date) {
    const dateStart = new Date(conference.start_date).getTime()
    const dateEnd = new Date(conference.end_date).getTime()
    totalMinutes = (dateEnd - dateStart) / 1000 / 60
  }
  if (totalMinutes > 0) {
    const hour = Math.trunc(totalMinutes / 60)
    const minutes = Math.round(totalMinutes % 60)

    if (hour === 0) {
      return `${minutes}min`
    } else {
      if (minutes > 0) {
        return `${hour}h${minutes}`
      } else {
        return `${hour}h`
      }
    }
  }
  return ''
}
