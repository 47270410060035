import { useScrollTrigger } from '@material-ui/core'
import React, { ReactElement } from 'react'

interface IElevationScrollProps {
  children: ReactElement
}

const ElevationScroll: React.FC<IElevationScrollProps> = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  })
}

export default ElevationScroll
