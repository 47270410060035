import { Grid, IconButton, List, ListItem } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRootState } from '../../redux'
import ProductCard from '../cards/ProductCard'
import FullScreenDialog from '../modals/FullScreenDialog'
import Transition from '../Transition'
import ExhibitorProductForm from './ExhibitorProductForm'

const ExhibitorProductsEdit: React.FC = () => {
  const intl = useIntl()
  const [showProductsModal, setShowProductModal] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)

  const handleToggleProductsModal = () => {
    setShowProductModal(!showProductsModal)
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleToggleProductsModal}
      >
        <EditIcon
          titleAccess={intl.formatMessage({ id: 'titleAccess.EditProductsButton.EditIcon' })}
        />
      </IconButton>
      <FullScreenDialog
        title={intl.formatMessage({ id: 'exhibitor.productsModalTitle' })}
        open={showProductsModal}
        fullScreen
        TransitionComponent={Transition}
        scroll={'body'}
        onClose={handleToggleProductsModal}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} lg={4} xl={3}>
            <ExhibitorProductForm />
          </Grid>
          <Grid item xs={12} lg={8} xl={9}>
            <List dense disablePadding>
              {exhibitor &&
                exhibitor.products.map((product) => (
                  <ListItem key={product.id} disableGutters>
                    <ProductCard
                      product={product}
                      moderators={exhibitor.moderators}
                      showTags
                      showImage
                      editable={exhibitor.editable}
                      lightMode
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
      </FullScreenDialog>
    </>
  )
}

export default ExhibitorProductsEdit
