import throttle from 'lodash.throttle'
import { useEffect, useState } from 'react'
import { mobileWidth } from '../components/breakpoints'

export const useIsMobile = (breakpoint: number = mobileWidth) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint)

  useEffect(() => {
    const onWindowResize = throttle(() => {
      setIsMobile(window.innerWidth < breakpoint)
    }, 200)

    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [breakpoint])

  return isMobile
}
