import { IQuestion } from '../../entities/questions'
import { ActionsTypes } from './model'

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_LOADING_QUESTIONS,
  loading
})

export const setSending = (sending: boolean) => ({
  type: ActionsTypes.SET_SENDING_QUESTION,
  sending
})

export const setUpdating = (questionId: number, updating: boolean) => ({
  type: ActionsTypes.SET_UPDATING_QUESTION,
  questionId,
  updating
})

export const setDeleting = (deleting: boolean) => ({
  type: ActionsTypes.SET_DELETING_QUESTION,
  deleting
})

export const setQuestionError = (error?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_QUESTIONS,
  error
})

export const setQuestions = (questions: IQuestion[]) => ({
  type: ActionsTypes.SET_QUESTIONS,
  questions
})

export const addQuestion = (question: IQuestion) => ({
  type: ActionsTypes.ADD_QUESTION,
  question
})

export const deleteQuestion = (questionId: number) => ({
  type: ActionsTypes.DELETE_QUESTION,
  questionId
})

export const setCountUp = (questionId: number, countUp: number) => ({
  type: ActionsTypes.SET_COUNT_UP,
  questionId,
  countUp
})

export const incrementCountUp = (questionId: number) => ({
  type: ActionsTypes.INCREMENT_COUNT_UP,
  questionId
})

export const decrementCountUp = (questionId: number) => ({
  type: ActionsTypes.DECREMENT_COUNT_UP,
  questionId
})
