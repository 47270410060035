import styled from '@emotion/styled'
import { DARKEN_WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'

const AgendaItemMessage = styled.p<{ center?: boolean; right?: boolean }>`
  position: relative;
  font-size: 10px;
  font-style: italic;
  padding: 5px;
  background-color: ${DARKEN_WHITE};
  width: 100%;
  text-align: center;
  margin-top: 10px;
  border-radius: ${BORDER_RADIUS_CARD};

  // triangle
  &:before {
    content: '';
    position: absolute;
    top: -8px;
    ${(props) => (props.center ? `left: calc(50% - 7px);` : props.right ? 'right: 15px;' : 'left: 15px;')}
    width: 5px;
    height: 5px;
    border-style: solid;
    border-width: 0 7px 12px 7px;
    border-color: transparent transparent ${DARKEN_WHITE} transparent;
  }
`

export default AgendaItemMessage
