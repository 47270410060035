import styled from '@emotion/styled'
import { alpha } from '@material-ui/core'
import React, { FC, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IQuestion } from '../../../entities/questions'
import { IRootState } from '../../../redux'
import { BACKGROUND_SECONDARY_LIGHT, BLACK_BLUE, VERY_LIGHT_GRAY } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import Loading from '../../Loading'
import Question from './Question'

interface IQuestionListProps {
  questions: IQuestion[]
  editMode?: boolean
  scrollBottom: boolean
  setScrollBottom: (scroll: boolean) => void
}

const QuestionList: FC<IQuestionListProps> = ({
  questions,
  editMode,
  scrollBottom,
  setScrollBottom
}) => {
  const refBottom = React.createRef<HTMLDivElement>()
  const loading = useSelector((state: IRootState) => state.questionsState.loading)
  const sending = useSelector((state: IRootState) => state.questionsState.sending)
  const questionsSorted = useMemo(
    () =>
      questions.sort((q1, q2) => {
        const date1 = q1.createDate ? new Date(q1.createDate) : new Date('1970-01-01 00:00:00')
        const date2 = q2.createDate ? new Date(q2.createDate) : new Date('1970-01-01 00:00:00')
        return (date1 > date2 ? 1 : 0) - (date1 < date2 ? 1 : 0)
      }),
    [questions]
  )

  useEffect(() => {
    if (refBottom && refBottom.current && scrollBottom) {
      refBottom.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      setScrollBottom(false)
    }
  }, [refBottom, scrollBottom, setScrollBottom])

  return (
    <StyledQuestionList>
      {loading && <Loading />}
      {questions.length === 0 && (
        <EmptyQuestion>
          <FormattedMessage id="no.questions" />
        </EmptyQuestion>
      )}
      {questionsSorted.map((question, index) => (
        <StyledQuestion key={index}>
          <Question question={question} editMode={editMode} />
        </StyledQuestion>
      ))}
      {sending && <Loading />}
      <div style={{ float: 'left', clear: 'both' }} ref={refBottom}></div>
    </StyledQuestionList>
  )
}

const StyledQuestionList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: calc(100% - 75px);
  overflow-y: auto;
  padding-top: 0;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px ${alpha(BLACK_BLUE, 0.14)};
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${BACKGROUND_SECONDARY_LIGHT};
  }
`

const StyledQuestion = styled.li`
  position: relative;
  width: calc(100% - 10px);
  margin: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  background-color: ${VERY_LIGHT_GRAY};
  border-radius: ${BORDER_RADIUS_CARD};
`

const EmptyQuestion = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 20px);
  text-align: center;
  padding: 5px;
`

export default QuestionList
