import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils/createReducer'
import { ActionsTypes, IQuestionState } from './model'

const initialState: IQuestionState = {
  loading: false,
  sending: false,
  updating: {},
  deleting: false,
  questions: []
}

const actionHandlers: IReducerHandler<IQuestionState> = {
  [ActionsTypes.SET_LOADING_QUESTIONS]: (
    state: IQuestionState | undefined,
    { loading }: AnyAction
  ): IQuestionState => ({
    ...state!,
    loading
  }),

  [ActionsTypes.SET_SENDING_QUESTION]: (
    state: IQuestionState | undefined,
    { sending }: AnyAction
  ): IQuestionState => ({
    ...state!,
    sending
  }),

  [ActionsTypes.SET_UPDATING_QUESTION]: (
    state: IQuestionState | undefined,
    { questionId, updating }: AnyAction
  ): IQuestionState => ({
    ...state!,
    updating: state && state.updating ? { ...state.updating, [questionId]: updating } : {}
  }),

  [ActionsTypes.SET_DELETING_QUESTION]: (
    state: IQuestionState | undefined,
    { deleting }: AnyAction
  ): IQuestionState => ({
    ...state!,
    deleting
  }),

  [ActionsTypes.SET_ERROR_QUESTIONS]: (
    state: IQuestionState | undefined,
    { error }: AnyAction
  ): IQuestionState => ({
    ...state!,
    error
  }),

  [ActionsTypes.SET_QUESTIONS]: (
    state: IQuestionState | undefined,
    { questions }: AnyAction
  ): IQuestionState => ({
    ...state!,
    questions
  }),

  [ActionsTypes.ADD_QUESTION]: (
    state: IQuestionState | undefined,
    { question }: AnyAction
  ): IQuestionState => ({
    ...state!,
    questions: state && state.questions ? [...state.questions, question] : []
  }),

  [ActionsTypes.DELETE_QUESTION]: (
    state: IQuestionState | undefined,
    { questionId }: AnyAction
  ): IQuestionState => ({
    ...state!,
    questions: state && state.questions ? state.questions.filter((q) => q.id !== questionId) : []
  }),

  [ActionsTypes.SET_COUNT_UP]: (
    state: IQuestionState | undefined,
    { questionId, countUp }: AnyAction
  ): IQuestionState => ({
    ...state!,
    questions:
      state && state.questions
        ? state.questions.map((q) => {
            if (q.id === questionId) {
              q.countUpVotes = countUp
              return q
            } else {
              return q
            }
          })
        : []
  }),

  [ActionsTypes.INCREMENT_COUNT_UP]: (
    state: IQuestionState | undefined,
    { questionId }: AnyAction
  ): IQuestionState => ({
    ...state!,
    questions:
      state && state.questions
        ? state.questions.map((q) => {
            if (q.id === questionId) {
              // q.countUpVotes++
              q.haveVoted = true
              return q
            } else {
              return q
            }
          })
        : []
  }),

  [ActionsTypes.DECREMENT_COUNT_UP]: (
    state: IQuestionState | undefined,
    { questionId }: AnyAction
  ): IQuestionState => ({
    ...state!,
    questions:
      state && state.questions
        ? state.questions.map((q) => {
            if (q.id === questionId) {
              // q.countUpVotes--
              q.haveVoted = false
              return q
            } else {
              return q
            }
          })
        : []
  })
}

export const reducer = createReducer(initialState, actionHandlers)
