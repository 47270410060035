import { IEnterRoomJitsi } from '../../entities'

export enum ActionsTypes {
  SET_LOADING_JITSI = 'SET_LOADING_JITSI',
  SET_JITSI_ROOM = 'SET_JITSI_ROOM',
  SET_ERROR_JITSI = 'SET_ERROR_JITSI'
}

export interface IJitsiState {
  loadingJitsi: boolean
  jitsiRoom?: IEnterRoomJitsi | null
  errorJitsi?: string
}
