export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null
}

export const getPlainText = (strSrc: string) => {
  let resultStr = ''
  // Ignore the <p> tag if it is in very start of the text
  resultStr = strSrc && strSrc.indexOf('<p>') === 0 ? strSrc.substring(3) : strSrc
  // Replace <p> with two newlines
  resultStr = resultStr.replace(/<p>/gi, '\r\n\r\n')
  // Replace <br /> with one newline
  resultStr = resultStr.replace(/<br \/>/gi, '\r\n')
  resultStr = resultStr.replace(/<br>/gi, '\r\n')
  // -+-+-+-+-+-+-+-+-+-+-+
  // Strip off other HTML tags.
  // -+-+-+-+-+-+-+-+-+-+-+
  return resultStr.replace(/<[^<|>]+?>/gi, '')
}

export const convertQueryParamToObject = (param: string | null) => {
  if (param) {
    const ids = param.split(',')
    const obj: { [key: string]: boolean } = {}
    ids.forEach((id) => {
      obj[id] = true
    })
    return obj
  } else {
    return {}
  }
}

export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi

export const formatName = (user?: { firstname: string, lastname: string }) => {
  const formatSmall = (name: string) => {
    if (name.indexOf('-') > -1) {
      const tab = name.split('-')
      return `${tab[0].substring(0, 1).toUpperCase()}-${tab[1].substring(0, 1).toUpperCase()}`
    } else {
      return name.substring(0, 1).toUpperCase()
    }
  }
  const firstname = user && user.firstname ? user.firstname : ''
  const lastname = user && user.lastname ? user.lastname : ''
  const lengthFull = firstname.length + lastname.length + 1
  const full = `${firstname} ${lastname.toUpperCase()}`
  const lengthNormal = lastname.length + 2
  const normal = `${formatSmall(firstname)}.${lastname.toUpperCase()}`
  const separator = firstname.indexOf('-') > -1 || lastname.indexOf('-') > -1 ? '.' : ''
  const short = `${formatSmall(firstname)}${separator}${formatSmall(lastname)}`
  return {
    first: `${firstname}`,
    full,
    normal,
    short,
    limited: lengthFull < 12 ? full : lengthNormal < 12 ? normal : short
  }
}