import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IChat, IConversation, IMessage } from '../../../entities/chat'
import { useMagicStream, useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import {
  addMessageToConversation,
  loadMessages,
  sendMessage,
  setErrorMessages
} from '../../../store/chat'
import consoleUtils from '../../../utils/consoleUtils'
import ChatBox from './ChatBox'
import ChatHeader from './ChatHeader'
import ChatList from './ChatList'

type IChatProps = {
  conversation: IConversation
  hidden?: boolean
}

const Chat: React.FC<IChatProps> = ({ conversation, hidden }) => {
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [firstLoad, setFirstLoad] = useState(false)
  const [lastMessageId, setLastMessageId] = useState<number | undefined>()
  const user = useSelector((state: IRootState) => state.appState.user)
  const loading = useSelector((state: IRootState) => state.chatState.loading)
  const errorMessages = useSelector((state: IRootState) => state.chatState.errorMessages)
  const open = useSelector((state: IRootState) => state.chatState.openChat)
  const minimize = useSelector((state: IRootState) => state.chatState.minimizeChat)
  const currentConversation = useSelector(
    (state: IRootState) => state.chatState.currentConversation
  )
  const conversations = useSelector((state: IRootState) => state.chatState.conversations)
  const [chats, setChats] = useState<IChat[]>([])
  const [newMessage, setNewMessage] = useState(false)
  const [sendings, setSendings] = useState<IChat[]>([])
  const dispatch = useThunkDispatch()

  const handleTextChange = useCallback(
    (text: string) => {
      if (user) {
        sendDataToGTM({ event: 'chat-send-message' })
        const payload: IChat = {
          id: user.id,
          user: {
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            isOnline: true, // sender is online
            avatarPath: user.avatarPath
          },
          message: text,
          createDate: new Date().toUTCString()
        }
        setSendings([payload, ...sendings])
        dispatch(sendMessage(conversation.contact.id!, text))
        window.setTimeout(() => {
          setNewMessage(true)
        }, 250)
      }
    },
    [conversation, user, sendings, dispatch, sendDataToGTM]
  )

  const loadPreviousMessage = useCallback(() => {
    if (conversation.contact.id && !loading && lastMessageId) {
      dispatch(loadMessages(conversation.contact.id, lastMessageId))
    }
  }, [lastMessageId, conversation, loading, dispatch])

  useEffect(() => {
    if (!loading) {
      setChats(
        user
          ? conversation.messages.map((message) => ({
            id: message.author.id === user.id ? user.id : conversation.contact.id!,
            user: {
              id: message.author.id === user.id ? user.id : conversation.contact.id!,
              firstname:
                message.author.id === user.id
                  ? user.firstname
                  : conversation.contact.userPresence.user.firstname,
              lastname:
                message.author.id === user.id
                  ? user.lastname
                  : conversation.contact.userPresence.user.lastname,
              isOnline:
                message.author.id === user.id ? true : conversation.contact.userPresence.isOnline,
              avatarPath:
                message.author.id === user.id
                  ? user.avatarPath
                  : conversation.contact.userPresence.user.avatarPath
            },
            message: message.message,
            createDate: message.createDate
          }))
          : []
      )
      if (conversation.messages.length > 0) {
        setLastMessageId(conversation.messages[conversation.messages.length - 1].id)
      }
      if (!firstLoad) {
        setFirstLoad(true)
        // first load, scroll to bottom
        window.setTimeout(() => {
          setNewMessage(true)
        }, 250)
      }
    }
  }, [conversation, loading, user, firstLoad])

  useEffect(() => {
    if (window.pusher && user && conversation.contact && conversation.contact.id) {
      const channel = window.pusher.channel(`private-user-${user.id}`)
      if (channel) {
        channel.bind('message', (data: IMessage) => {
          consoleUtils.log(`[${conversation.contact.id}] Message reçu de ${data.author.id}=`, data)
          if (data.channel.indexOf(`${conversation.contact.id}`) > -1) {
            if (data.author.id === user.id) {
              sendings.shift()
              setSendings(sendings)
            }
            const payload: IChat = {
              id: data.author.id === user.id ? user.id : conversation.contact.id!,
              user: {
                id: data.author.id === user.id ? user.id : conversation.contact.id!,
                firstname:
                  data.author.id === user.id
                    ? user.firstname
                    : conversation.contact.userPresence.user.firstname,
                lastname:
                  data.author.id === user.id
                    ? user.lastname
                    : conversation.contact.userPresence.user.lastname,
                isOnline:
                  data.author.id === user.id ? true : conversation.contact.userPresence.isOnline,
                avatarPath:
                  data.author.id === user.id
                    ? user.avatarPath
                    : conversation.contact.userPresence.user.avatarPath
              },
              message: data.message,
              createDate: data.createDate
            }
            setChats([payload, ...chats])
            setTimeout(() => setNewMessage(true), 500)
          }
          if (data.author.id !== user.id && open && !minimize) {
            if (
              currentConversation === data.author.id &&
              conversations.find((conv) => conv.contact.id === data.author.id)
            ) {
              dispatch(addMessageToConversation(data.author.id, data))
            }
          }
        })
      }
    }
    return () => {
      if (window.pusher && user && conversation.contact && conversation.contact.id) {
        const channel = window.pusher.channel(`private-user-${user.id}`)
        if (channel) {
          channel.unbind('message')
        }
      }
    }
  }, [
    user,
    chats,
    sendings,
    currentConversation,
    conversation,
    conversations,
    open,
    minimize,
    dispatch
  ])

  useEffect(() => {
    if (open && !minimize) {
      setTimeout(() => setNewMessage(true), 250)
    }
  }, [open, minimize])

  useEffect(() => {
    let timer: number | null = null
    if (sendings.length > 0) {
      timer = window.setTimeout(() => {
        setSendings([])
      }, 5000) // autoclean loading when 30 secondes
    }
    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [sendings])

  useEffect(() => {
    if (errorMessages) {
      magicStream.error(errorMessages)
      dispatch(setErrorMessages(undefined))
    }
  }, [errorMessages, magicStream, dispatch])

  return (
    <StyledChat hidden={hidden}>
      <ChatHeader conversation={conversation} />
      <ChatList
        loading={loading}
        fixed
        chats={chats}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        sendings={sendings}
        onScrollTop={loadPreviousMessage}
      />
      <ChatBox handleTextChange={handleTextChange} />
    </StyledChat>
  )
}

const StyledChat = styled.div<{ hidden?: boolean }>`
  flex: 1;
  display: ${props => props.hidden ? 'none' : 'flex'};
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export default Chat
