import React from 'react'

const SvgProfile: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M25 0C11.215 0 0 11.215 0 25s11.215 25 25 25 25-11.215 25-25S38.785 0 25 0zm0 48.12C12.252 48.12 1.88 37.748 1.88 25 1.88 12.252 12.252 1.88 25 1.88c12.748 0 23.12 10.372 23.12 23.12 0 12.748-10.372 23.12-23.12 23.12zM17.162 20.1c0 5.198 3.516 9.428 7.838 9.428s7.838-4.23 7.838-9.429c0-4.927-3.224-8.369-7.838-8.369-.763 0-1.508.1-2.218.295a.94.94 0 1 0 .5 1.813A6.478 6.478 0 0 1 25 13.61c3.563 0 5.957 2.608 5.957 6.489 0 4.162-2.672 7.548-5.957 7.548-3.285 0-5.957-3.386-5.957-7.548 0-1.86.538-3.453 1.557-4.607a1.07 1.07 0 0 0 .04-.049c.588-.765 1.268-2.627-.367-4.204a.94.94 0 1 0-1.305 1.353c.756.73.352 1.463.194 1.688-1.308 1.498-2 3.509-2 5.818zM25 31.3c-6.53 0-11.71 3.561-14.207 9.771a.94.94 0 1 0 1.744.702C14.767 36.23 19.192 33.18 25 33.18c5.809 0 10.235 3.051 12.464 8.593a.94.94 0 0 0 1.745-.702C36.71 34.861 31.532 31.3 25 31.3z"
    />
  </svg>
)

export default SvgProfile
