import { IHighlight } from '../../entities/highlight'
import { ActionsTypes } from './model'

export const setHighlight = (highlight: { total: number; items: IHighlight[] }) => ({
  type: ActionsTypes.SET_HIGHLIGHT,
  highlight
})

export const setHighlightLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_HIGHLIGHT_LOADING,
  loading
})

export const setHighlightLoaded = (loaded: boolean) => ({
  type: ActionsTypes.SET_HIGHLIGHT_LOADED,
  loaded
})

export const setHighlightError = (error?: string | undefined) => ({
  type: ActionsTypes.SET_HIGHLIGHT_ERROR,
  error
})
