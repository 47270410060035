import styled from '@emotion/styled'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ReactComponent as SvgAlarm } from '../../../assets/images/icons/alarm.svg'
import { useEffectOnce } from '../../../hooks'
import { PRIMARY_COLOR } from '../../../theme'
import { WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import { breakpoints } from '../../breakpoints'

interface ICountDown {
  date: Date
}

const CountDown: React.FC<ICountDown> = ({ date = new Date() }) => {
  const intl = useIntl()
  const [countDown, setCountDown] = useState({
    years: -1,
    days: 0,
    hours: 0,
    min: 0,
    sec: 0
  })
  const [intervalTimer, setintervalTimer] = useState<NodeJS.Timeout>()

  const stop = () => {
    if (intervalTimer) clearInterval(intervalTimer)
    setCountDown({
      years: -1,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    })
  }

  useEffectOnce(() => {
    // update every second
    const interval = setInterval(() => {
      const newDate = calculateCountdown(date)
      newDate ? setCountDown(newDate) : stop()
    }, 1000)
    setintervalTimer(interval)
    return () => {
      stop()
    }
  })

  const calculateCountdown = (endDate: Date) => {
    let diff = (endDate.getTime() - new Date().getTime()) / 1000

    // clear countdown when date is reached
    if (diff <= 0) return false

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    }

    // calculate time difference between now and expected date
    if (diff > 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400))
      diff -= timeLeft.years * 365.25 * 86400
    }
    if (diff > 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400)
      diff -= timeLeft.days * 86400
    }
    if (diff > 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600)
      diff -= timeLeft.hours * 3600
    }
    if (diff > 60) {
      timeLeft.min = Math.floor(diff / 60)
      diff -= timeLeft.min * 60
    }
    timeLeft.sec = Math.round(diff)

    return timeLeft
  }

  const addLeadingZeros = (value: number) => {
    let sValue = String(value)
    while (sValue.length < 2) {
      sValue = '0' + value
    }
    return sValue
  }

  return countDown.years > -1 ? (
    <CountdownContainer>
      <Countdown>
        <SvgAlarm />
        <StyledStart>
          <FormattedMessage id="start.count.down.before" />
        </StyledStart>
        {countDown.days > 0 && (
          <StyledDaysRemaining>
            <FormattedMessage
              id="start.count.down.days"
              values={{
                days: intl.formatMessage({ id: 'days' }, { days: countDown.days })
              }}
            />
          </StyledDaysRemaining>
        )}
        <StyledClock>
          <FormattedMessage
            id="start.count.down.time"
            values={{
              hours: addLeadingZeros(countDown.hours),
              min: addLeadingZeros(countDown.min),
              sec: addLeadingZeros(countDown.sec)
            }}
          />
        </StyledClock>
      </Countdown>
    </CountdownContainer>
  ) : (
    <></>
  )
}

const CountdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Countdown = styled.div`
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  font-size: 30px;
  color: ${WHITE};
  background-color: ${PRIMARY_COLOR};
  border-radius: ${BORDER_RADIUS_CARD};
  margin-top: 10px;
  padding: 10px;

  @media (max-width: ${breakpoints.lg}) {
    display: none;
  }
`

const StyledStart = styled.div`
  font-size: 12.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  margin-left: 5px;
`

const StyledDaysRemaining = styled.div`
  font-size: 25.2px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  margin-left: 10px;
`

const StyledClock = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  font-family: Orbitron;
  font-size: 16.8px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  width: 100px;
`

export default CountDown
