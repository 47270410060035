import styled from '@emotion/styled'
import React from 'react'
import { GRAY, PRIMARY } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'

interface IProps {
  progress: number
  bottom?: 'big' | 'small'
}

const ProgressBar: React.FC<IProps> = ({ progress, bottom }) => {
  if (!progress) return null
  return <StyledProgressBar progress={progress} bottom={bottom} />
}

const StyledProgressBar = styled.div<{ progress: number; bottom?: 'big' | 'small' }>`
  & {
    display: ${(props) => (props.progress && props.progress > 0 ? 'block' : 'none')};
    position: absolute;
    bottom: ${(props) => (props.bottom === 'small' ? '13px' : '20px')};
    left: 16px;
    right: 16px;
    height: 6px;
    background-color: ${GRAY};
    border-radius: ${BORDER_RADIUS_CARD};
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: ${(props) => (props.progress ? props.progress : 0)}%;
      background-color: ${PRIMARY};
    }
  }
`

export default ProgressBar
