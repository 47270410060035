import styled from '@emotion/styled'
import { IconButton, InputLabel } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as EnFlagIcon } from '../../../assets/images/flags/en.svg'
import { ReactComponent as FrFlagIcon } from '../../../assets/images/flags/fr.svg'
import { LanguageKeys } from '../../../entities'
import { useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import { changeLocale } from '../../../store/app'
import { BLACK_BLUE } from '../../../theme/colors'
import asyncLocalStorage from '../../../utils/asyncLocalstorage'

interface SelectLanguageProps {
  labelId?: string
  onValueChange?: (value: LanguageKeys) => void
}

const SelectLanguage: React.FC<SelectLanguageProps> = ({ labelId, onValueChange }) => {
  const locale = useSelector((state: IRootState) => state.appState.locale)
  const user = useSelector((state: IRootState) => state.appState.user)
  const dispatch = useThunkDispatch()

  const languageChange = (value: LanguageKeys) => {
    dispatch(changeLocale(asyncLocalStorage, value, user))
    if (onValueChange) onValueChange(value)
  }
  return (
    <StyledSelectLanguage>
      <StyledInputLabel id="select-language">
        <FormattedMessage id={labelId} />
      </StyledInputLabel>
      <StyledIconButton
        color={locale === LanguageKeys.fr ? 'primary' : 'default'}
        onClick={() => languageChange(LanguageKeys.fr)}
      >
        <FrFlagIcon />
        <FormattedMessage id="language.fr" />
      </StyledIconButton>
      <StyledIconButton
        color={locale === LanguageKeys.en ? 'primary' : 'default'}
        onClick={() => languageChange(LanguageKeys.en)}
      >
        <EnFlagIcon />
        <FormattedMessage id="language.en" />
      </StyledIconButton>
    </StyledSelectLanguage>
  )
}

const StyledSelectLanguage = styled.div`
  display: flex;
  align-items: center;
`

const StyledInputLabel = styled(InputLabel)`
  margin-right: 15px;
  color: ${BLACK_BLUE};
`

const StyledIconButton = styled(IconButton)`
  & svg {
    height: 30px;
    margin-right: 20px;
  }
`

export default SelectLanguage
