import useGTM from '@elgorditosalsero/react-gtm-hook'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { IconButton, Tooltip } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IConference, IConferenceFull } from '../../entities'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { addConferenceToFavorite, removeConferenceFromFavorite } from '../../store/conference'
import { GRAY, SECONDARY, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import Loading from '../Loading'

type IConferenceFavoriteButtonProps = {
  conference: IConference | IConferenceFull
  hasHelpToolTip?: boolean
}

const ConferenceFavoriteButton: React.FC<IConferenceFavoriteButtonProps> = ({
  conference,
  hasHelpToolTip
}) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [favorite, setFavorite] = useState(false)
  const [openTooltipFavorite, setOpenTooltipFavorite] = useState(false)
  const addingConferenceToFavorites = useSelector(
    (state: IRootState) => state.conferenceState.addingConferenceToFavorites
  )
  const removingConferenceFromFavorites = useSelector(
    (state: IRootState) => state.conferenceState.removingConferenceFromFavorites
  )
  const dispatch = useThunkDispatch()

  const isLoading = useMemo(
    () =>
      addingConferenceToFavorites[conference.id] || removingConferenceFromFavorites[conference.id],
    [addingConferenceToFavorites, removingConferenceFromFavorites, conference]
  )

  const updateFavorite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setFavorite(!favorite)
    if (conference) {
      if (favorite) {
        dispatch(removeConferenceFromFavorite(conference.id))
      } else {
        sendDataToGTM({ event: 'conference-favorite', conferenceTitle: conference.title })
        dispatch(addConferenceToFavorite(conference.id))
        magicStream.success(intl.formatMessage({ id: 'favorite.conference.success' }))
      }
    }
  }

  useEffect(() => {
    setFavorite(conference && conference.isFollowed)
  }, [conference])

  return (
    <StyledButtonFavorite isfavorite={favorite}>
      <Tooltip
        open={!favorite && hasHelpToolTip ? openTooltipFavorite && hasHelpToolTip : undefined}
        placement="left"
        title={intl.formatMessage({
          id: isLoading
            ? 'favorite.saving'
            : !favorite && hasHelpToolTip
              ? 'favorite.explain'
              : favorite
                ? 'favorite.conf.true'
                : 'favorite.conf.false'
        })}
        arrow
      >
        <IconButton
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={updateFavorite}
          color={favorite ? 'primary' : 'default'}
          onMouseOver={() => setOpenTooltipFavorite(true)}
          onMouseOut={() => setOpenTooltipFavorite(false)}
        >
          {!isLoading && <CalendarTodayIcon />}
          {!isLoading && !favorite && (
            <FavoriteBorderIcon
              titleAccess={intl.formatMessage({
                id: 'titleAccess.FavoriteBorder'
              })}
              className="favorite"
            />
          )}
          {!isLoading && favorite && (
            <FavoriteIcon
              titleAccess={intl.formatMessage({
                id: 'titleAccess.Favorite'
              })}
              className="favorite"
            />
          )}
          {isLoading && <Loading size="1rem" />}
        </IconButton>
      </Tooltip>
    </StyledButtonFavorite>
  )
}

const StyledButtonFavorite = styled.div<{ isfavorite: boolean }>`
  & {
    cursor: pointer;
    margin-right: 5px;

    &:hover {
      .favorite {
        color: ${SECONDARY};
      }
    }
    .MuiIconButton-root {
      border-radius: ${BORDER_RADIUS_CARD};
    }
    svg {
      color: ${GRAY};

      &.favorite {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translateX(-50%);
        height: 10px;
        width: 10px;
      }
    }
    ${(props) =>
    props.isfavorite
      ? css`
            & {
              .MuiIconButton-root {
                background-color: ${SECONDARY};
              }
              svg {
                color: ${WHITE} !important;

                &.favorite {
                  color: ${SECONDARY};
                }
              }
            }
          `
      : ''};
  }
`

export default ConferenceFavoriteButton
