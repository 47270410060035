import { IProduct, IRoom } from '../../entities'
import {
  IExhibitor,
  IExhibitorDemo,
  IExhibitorFull,
  IExhibitorUpdate
} from '../../entities/exhibitor'
import { IFilters } from '../../entities/filters'
import { ActionsTypes } from './model'

export const setExhibitorsTotal = (totalExhibitors?: number) => ({
  type: ActionsTypes.SET_EXHIBITORS_TOTAL,
  totalExhibitors
})

export const setExhibitorsSearch = (searchExhibitors?: string | undefined) => ({
  type: ActionsTypes.SET_EXHIBITOR_SEARCH,
  searchExhibitors
})

export const setExhibitorUpdating = (updating: boolean) => ({
  type: ActionsTypes.SET_EXHIBITOR_UPDATING,
  updating
})

export const setExhibitorError = (error?: string | undefined) => ({
  type: ActionsTypes.SET_EXHIBITOR_ERROR,
  error
})

export const setExhibitorLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_EXHIBITOR_LOADING,
  loading
})

export const setExhibitors = (exhibitors: { total: number; items: IExhibitor[] }) => ({
  type: ActionsTypes.SET_EXHIBITORS,
  exhibitors
})

export const setExhibitorsError = (errorExhibitors?: string | undefined) => ({
  type: ActionsTypes.SET_EXHIBITORS_ERROR,
  errorExhibitors
})

export const setExhibitorsLoading = (loadingExhibitors: boolean) => ({
  type: ActionsTypes.SET_EXHIBITORS_LOADING,
  loadingExhibitors
})

export const setEditMode = (editMode: boolean) => ({
  type: ActionsTypes.SET_EXHIBITOR_EDIT_MODE,
  editMode
})

export const setScoredExhibitorError = (errorScored?: string | undefined) => ({
  type: ActionsTypes.SET_SCORED_EXHIBITOR_ERROR,
  errorScored
})

export const setScoredExhibitorLoading = (loadingScored: boolean) => ({
  type: ActionsTypes.SET_SCORED_EXHIBITOR_LOADING,
  loadingScored
})

export const setScoredExhibitors = (scoredExhibitors: IExhibitor[]) => ({
  type: ActionsTypes.SET_SCORED_EXHIBITORS,
  scoredExhibitors
})

export const setRecommendedExhibitorError = (errorRecommended?: string | undefined) => ({
  type: ActionsTypes.SET_RECOMMENDED_EXHIBITOR_ERROR,
  errorRecommended
})

export const setRecommendedExhibitorLoading = (loadingRecommended: boolean) => ({
  type: ActionsTypes.SET_RECOMMENDED_EXHIBITOR_LOADING,
  loadingRecommended
})

export const setRecommendedExhibitors = (recommendedExhibitors: IExhibitor[]) => ({
  type: ActionsTypes.SET_RECOMMENDED_EXHIBITORS,
  recommendedExhibitors
})

export const setPremiumExhibitorError = (errorPremium?: string | undefined) => ({
  type: ActionsTypes.SET_PREMIUM_EXHIBITOR_ERROR,
  errorPremium
})

export const setPremiumExhibitorLoading = (loadingPremium: boolean) => ({
  type: ActionsTypes.SET_PREMIUM_EXHIBITOR_LOADING,
  loadingPremium
})

export const setPremiumExhibitors = (premiumExhibitors: IExhibitor[]) => ({
  type: ActionsTypes.SET_PREMIUM_EXHIBITORS,
  premiumExhibitors
})

export const resetExhibitor = () => ({
  type: ActionsTypes.RESET_EXHIBITOR
})

export const setExhibitor = (exhibitor: IExhibitorFull) => ({
  type: ActionsTypes.SET_EXHIBITOR,
  exhibitor
})

export const mergeExhibitor = (exhibitorUpdate: IExhibitorUpdate) => ({
  type: ActionsTypes.MERGE_EXHIBITOR,
  exhibitorUpdate
})

export const mergeExhibitorRoom = (roomUpdated: IRoom) => ({
  type: ActionsTypes.MERGE_EXHIBITOR_ROOM,
  roomUpdated
})

export const setExhibitorFilters = (filters: IFilters) => ({
  type: ActionsTypes.SET_EXHIBITOR_FILTERS,
  filters
})

export const setErrorExhibitorFilters = (errorFilters?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_EXHIBITOR_FILTERS,
  errorFilters
})

export const addProduct = (product: IProduct) => ({
  type: ActionsTypes.ADD_PRODUCT,
  product
})

export const removeProduct = (productId: number) => ({
  type: ActionsTypes.REMOVE_PRODUCT,
  productId
})

export const updateProduct = (product: IProduct) => ({
  type: ActionsTypes.UPDATE_PRODUCT,
  product
})

export const setLoadingExhibitorFilters = (loadingFilters: boolean) => ({
  type: ActionsTypes.SET_LOADING_EXHIBITOR_FILTERS,
  loadingFilters
})

export const updateExhibitorsFavorite = (exhibitorId: number, isFollowed: boolean) => ({
  type: ActionsTypes.UPDATE_EXHIBITORS_FAVORITE,
  exhibitorId,
  isFollowed
})

export const setAddingExhibitorToFavorites = (
  exhibitorId: number,
  addingExhibitorToFavorites: boolean
) => ({
  type: ActionsTypes.SET_ADDING_EXHIBITOR_TO_FAVORITES,
  exhibitorId,
  addingExhibitorToFavorites
})

export const setErrorAddingExhibitorToFavorites = (
  errorAddingExhibitorToFavorites?: string | undefined
) => ({
  type: ActionsTypes.SET_ERROR_ADDING_EXHIBITOR_TO_FAVORITES,
  errorAddingExhibitorToFavorites
})

export const setRemovingExhibitorFromFavorites = (
  exhibitorId: number,
  removingExhibitorFromFavorites: boolean
) => ({
  type: ActionsTypes.SET_REMOVING_EXHIBITOR_FROM_FAVORITES,
  exhibitorId,
  removingExhibitorFromFavorites
})

export const setErrorRemovingExhibitorFromFavorites = (
  errorRemovingExhibitorFromFavorites?: string | undefined
) => ({
  type: ActionsTypes.SET_ERROR_REMOVING_EXHIBITOR_FROM_FAVORITES,
  errorRemovingExhibitorFromFavorites
})

export const setShowroomsError = (errorShowrooms?: string | undefined) => ({
  type: ActionsTypes.SET_SHOWROOMS_ERROR,
  errorShowrooms
})

export const setShowroomsLoading = (loadingShowrooms: boolean) => ({
  type: ActionsTypes.SET_SHOWROOMS_LOADING,
  loadingShowrooms
})

export const setShowrooms = (showrooms: IExhibitorDemo[]) => ({
  type: ActionsTypes.SET_SHOWROOMS,
  showrooms
})

export const updateExhibitorRoomUsersOnline = (userIds: number[], isOnline: boolean) => ({
  type: ActionsTypes.UPDATE_EXHIBITOR_ROOM_USERS_ONLINE,
  userIds,
  isOnline
})
