import { css } from '@emotion/react'
import { alpha } from '@material-ui/core'
import { BLACK_BLUE } from '../../theme/colors'
import { breakpoints } from '../breakpoints'

export const videoBackgroundStyle = css`
  & {
    margin-top: 30px;
    margin-bottom: 30px;

    @media (min-width: ${breakpoints.lg}) {
      width: 800px;
      max-width: calc(100% - 30px);
      /*max-width: 80%;*/
    }
    :before {
      content: '';
      position: absolute;
      bottom: 20px;
      left: 25px;
      right: 25px;
      width: calc(100% - 50px);
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-bottom: 60px solid ${alpha(BLACK_BLUE, 0.9)};
      filter: blur(15px);
    }
  }
`
