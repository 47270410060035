import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IStandState } from './model'

const initialState: IStandState = {
  standChannel: null,
  standRoomChannel: null,
  autoEnterRoom: { id: '', roomActive: -1 },
  chairId: null,
  chairUid: null,
  locations: [],
  fullscreen: false,
  handleFullscreenExit: false
}

const actionHandlers: IReducerHandler<IStandState> = {
  [ActionsTypes.SET_STAND_CHANNEL]: (
    state: IStandState | undefined,
    { standChannel }: AnyAction
  ): IStandState => ({
    ...state!,
    standChannel
  }),
  [ActionsTypes.SET_STAND_ROOM_CHANNEL]: (
    state: IStandState | undefined,
    { standRoomChannel }: AnyAction
  ): IStandState => ({
    ...state!,
    standRoomChannel
  }),
  [ActionsTypes.SET_AUTO_ENTER_ROOM]: (
    state: IStandState | undefined,
    { autoEnterRoom }: AnyAction
  ): IStandState => ({
    ...state!,
    autoEnterRoom
  }),
  [ActionsTypes.SET_CHAIR_ID]: (
    state: IStandState | undefined,
    { chairId }: AnyAction
  ): IStandState => ({
    ...state!,
    chairId
  }),
  [ActionsTypes.RESET_CHAIR_ID]: (state: IStandState | undefined): IStandState => ({
    ...state!,
    chairId: null
  }),
  [ActionsTypes.SET_CHAIR_UID]: (
    state: IStandState | undefined,
    { chairUid }: AnyAction
  ): IStandState => ({
    ...state!,
    chairUid
  }),
  [ActionsTypes.RESET_CHAIR_UID]: (state: IStandState | undefined): IStandState => ({
    ...state!,
    chairUid: null
  }),
  [ActionsTypes.SET_USERS_LOCATIONS]: (
    state: IStandState | undefined,
    { locations }: AnyAction
  ): IStandState => ({
    ...state!,
    locations
  }),
  [ActionsTypes.ADD_USERS_LOCATIONS]: (
    state: IStandState | undefined,
    { location }: AnyAction
  ): IStandState => ({
    ...state!,
    locations:
      state && state.locations
        ? [...state.locations.filter((loc) => loc.user.id !== location.user.id), location]
        : []
  }),
  [ActionsTypes.REMOVE_USERS_LOCATIONS]: (
    state: IStandState | undefined,
    { location }: AnyAction
  ): IStandState => ({
    ...state!,
    locations:
      state && state.locations
        ? [...state.locations.filter((loc) => loc.user.id !== location.user.id)]
        : []
  }),
  [ActionsTypes.SET_FULLSCREEN]: (
    state: IStandState | undefined,
    { fullscreen }: AnyAction
  ): IStandState => ({
    ...state!,
    fullscreen
  }),
  [ActionsTypes.SET_HANDLE_FULLSCREEN_EXIT]: (
    state: IStandState | undefined,
    { handleFullscreenExit }: AnyAction
  ): IStandState => ({
    ...state!,
    handleFullscreenExit
  }),
  [ActionsTypes.SET_ALARM]: (
    state: IStandState | undefined,
    { alarm }: AnyAction
  ): IStandState => ({
    ...state!,
    alarm
  })
}

export const reducer = createReducer(initialState, actionHandlers)
