import { CircularProgress } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  createKeywordForUser as createKeywordForUserApi,
  getKeywordsCreatedByUser as getKeywordsCreatedByUserApi
} from '../../api'
import { IContactKeyword, IKeyword, IKeywordUserAutocomplete } from '../../entities/keyword'
import { IRootState } from '../../redux'
import ContactTagsList from './ContactTagsList'

const filter = createFilterOptions<IKeywordUserAutocomplete>()

type AutocompleteTagsProps = {
  contactId: number | undefined
  tags: IContactKeyword[]
  onAddTag: (keyword: IKeyword) => void
  onRemoveTag: (contactKeyword: IContactKeyword) => void
  showLabel?: boolean
}

export default function AutocompleteTags({
  contactId,
  tags,
  onAddTag,
  onRemoveTag,
  showLabel
}: AutocompleteTagsProps) {
  const intl = useIntl()
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<IKeywordUserAutocomplete[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector((state: IRootState) => state.appState.user)
  const loadingUpdateTags = useSelector(
    (state: IRootState) => state.participantState.loadingUpdateTags
  )
  const keywordsCreatedByUser = useSelector(
    (state: IRootState) => state.participantState.keywordsCreatedByUser
  )

  const loading = useMemo(() => isLoading && open && options.length === 0 && inputValue !== '', [
    isLoading,
    open,
    options,
    inputValue
  ])

  const isLoadingTags = useMemo(() => (contactId ? loadingUpdateTags[contactId] : undefined), [
    loadingUpdateTags,
    contactId
  ])

  const create = useCallback(
    async (keyword: IKeywordUserAutocomplete): Promise<IKeyword> => {
      setIsLoading(true)
      const keywordSaved = await createKeywordForUserApi(keyword.inputValue!, user?.id!)
      setIsLoading(false)
      return keywordSaved
    },
    [user]
  )

  useEffect(() => {
    if (inputValue === '') {
      setOptions(
        keywordsCreatedByUser.map((keywordCreatedByUser) => ({
          id: keywordCreatedByUser.id,
          keyword: keywordCreatedByUser
        }))
      )
      return undefined
    }

    if (!open || !user) {
      return undefined
    }

    const timer = window.setTimeout(async () => {
      setIsLoading(true)
      const keywords = await getKeywordsCreatedByUserApi(user.id, inputValue)
      setIsLoading(false)
      let newOptions = [] as IKeywordUserAutocomplete[]

      if (tags) {
        newOptions = tags
      }

      if (keywords.items) {
        const res = keywords.items.map((result) => ({
          keyword: result
        }))
        newOptions = [...newOptions, ...res]
      }

      setOptions(newOptions)
    }, 250)

    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [keywordsCreatedByUser, inputValue, open, user, tags])

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <div>
      <Autocomplete
        value={tags}
        onChange={async (event, newValue, reason, details) => {
          if (reason === 'remove-option') {
            onRemoveTag(details?.option as IContactKeyword)
          } else if (reason === 'select-option') {
            const element = details?.option
            if (element && element.inputValue) {
              // TODO create keyword and add it
              const keyword = await create(element)
              onAddTag(keyword)
            } else if (element && element.keyword?.id) {
              onAddTag(element.keyword)
            }
          } else if (reason === 'create-option') {
            const element = details?.option as string
            const keyword = await create({ inputValue: element })
            onAddTag(keyword)
          }
        }}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        filterOptions={(opts, params) => {
          const filtered = filter(opts, params)
          // Suggest the creation of a new value
          if (params.inputValue !== '' && !isLoading) {
            filtered.push({
              inputValue: params.inputValue,
              keyword: { id: 0, title: `Ajouter "${params.inputValue}"` }
            })
          }

          return filtered
        }}
        clearOnBlur
        handleHomeEndKeys
        multiple
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue
          }
          // Regular option
          return option.keyword?.title!
        }}
        renderOption={(option) => option.keyword?.title}
        style={{ width: '100%' }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={showLabel ? intl.formatMessage({ id: 'contacts.tags.label' }) : false}
            placeholder={showLabel ? '' : intl.formatMessage({ id: 'contacts.tags.label' })}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
            helperText={
              isLoadingTags !== undefined &&
              (isLoadingTags
                ? `${intl.formatMessage({ id: 'contacts.tags.save.in.progress' })}`
                : `${intl.formatMessage({ id: 'contacts.tags.save' })}`)
            }
          />
        )}
        renderTags={() => false}
        options={options}
        loading={loading}
      />
      <ContactTagsList
        onClickTag={onRemoveTag}
        tags={tags}
        icon={<DeleteForeverIcon fontSize="small" />}
      />
    </div>
  )
}
