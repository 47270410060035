import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'

const Shake = keyframes`
${`10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }`}
`

const AnimatedIconButton = styled(IconButton)`
  animation: ${Shake} 1.5s infinite;
`

export default AnimatedIconButton
