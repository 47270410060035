import { IEnterRoomZoom } from '../entities'
import settings from '../settings'
import { apiRequest } from './requests'

export const enterRoomZoom = async (roomId: number) => {
  const response = await apiRequest<IEnterRoomZoom>(
    'GET',
    `${settings.url.api}/e_events/room/enter/${roomId}`
  )
  return response
}
