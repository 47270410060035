import { IUser, IUserUpdate, LanguageKeys } from '../../entities'
import { IAgenda, IUserSlot } from '../../entities/agenda'
import { IKeyword, IKeywordUser } from '../../entities/keyword'
import {
  FrameRateType,
  IDevice,
  VideoResolution,
  VideoSourceType
} from '../../entities/mediaStream'
import { ActionsTypes } from './model'

export const setLocale = (locale: LanguageKeys) => ({ type: ActionsTypes.SET_LOCALE, locale })

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_LOADING,
  loading
})

export const setError = (error?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR,
  error
})

export const setLocation = (location: string) => ({
  type: ActionsTypes.SET_LOCATION,
  location
})

export const setUser = (user: IUser) => ({
  type: ActionsTypes.SET_USER,
  user
})

export const mergeUser = (userUpdate: IUserUpdate, keywords?: IKeywordUser[]) => ({
  type: ActionsTypes.MERGE_USER,
  userUpdate,
  keywords
})

export const setUserUpdating = (userUpdating: boolean) => ({
  type: ActionsTypes.SET_USER_UPDATING,
  userUpdating
})

export const setHasPassedTunnel = (hasPassedTunnel: boolean) => ({
  type: ActionsTypes.SET_HAS_PASSED_TUNNEL,
  hasPassedTunnel
})

export const setVideoSource = (videoSource: VideoSourceType) => ({
  type: ActionsTypes.SET_VIDEO_SOURCE,
  videoSource
})

export const setVideoInputDevices = (devices: IDevice[]) => ({
  type: ActionsTypes.SET_VIDEO_INPUT_DEVICES,
  devices
})

export const setAudioInputDevices = (devices: IDevice[]) => ({
  type: ActionsTypes.SET_AUDIO_INPUT_DEVICES,
  devices
})

export const setDefaultVideoSource = (deviceId: string) => ({
  type: ActionsTypes.SET_DEFAULT_VIDEO_SOURCE,
  deviceId
})

export const setPreferedVideoDisplay = (preferedVideoDisplay: boolean) => ({
  type: ActionsTypes.SET_PREFERED_VIDEO_DISPLAY,
  preferedVideoDisplay
})

export const resetDefaultVideoSource = () => ({ type: ActionsTypes.RESET_DEFAULT_VIDEO_SOURCE })

export const setDefaultAudioSource = (deviceId: string) => ({
  type: ActionsTypes.SET_DEFAULT_AUDIO_SOURCE,
  deviceId
})

export const resetDefaultAudioSource = () => ({ type: ActionsTypes.RESET_DEFAULT_AUDIO_SOURCE })

export const setDefaultResolution = (resolution: VideoResolution) => ({
  type: ActionsTypes.SET_DEFAULT_RESOLUTION,
  resolution
})

export const setRecommendedFrameRate = (recommendedFrameRate: FrameRateType) => ({
  type: ActionsTypes.SET_RECOMMENDED_FRAMERATE,
  recommendedFrameRate
})

export const setRecommendedResolution = (recommendedResolution: VideoResolution) => ({
  type: ActionsTypes.SET_RECOMMENDED_RESOLUTION,
  recommendedResolution
})

export const setKeywords = (keywords: { total: number; items: IKeyword[] }) => ({
  type: ActionsTypes.SET_KEYWORDS,
  keywords
})

export const setKeywordsLoading = (loadingKeywords: boolean) => ({
  type: ActionsTypes.SET_KEYWORDS_LOADING,
  loadingKeywords
})

export const setKeywordsError = (errorKeywords?: string | undefined) => ({
  type: ActionsTypes.SET_KEYWORDS_ERROR,
  errorKeywords
})

export const setKeywordsByUser = (keywordsByUser: { total: number; items: IKeywordUser[] }) => ({
  type: ActionsTypes.SET_KEYWORDS_BY_USER,
  keywordsByUser
})

export const setKeywordsByUserLoading = (loadingKeywordsByUser: boolean) => ({
  type: ActionsTypes.SET_KEYWORDS_BY_USER_LOADING,
  loadingKeywordsByUser
})

export const setKeywordsByUserError = (errorKeywordsByUser?: string | undefined) => ({
  type: ActionsTypes.SET_KEYWORDS_BY_USER_ERROR,
  errorKeywordsByUser
})

export const setSearch = (search: string | null) => ({
  type: ActionsTypes.SET_SEARCH,
  search
})

export const setGroupId = (groupId: number | string) => ({
  type: ActionsTypes.SET_GROUP_ID,
  groupId
})

export const resetGroupId = () => ({
  type: ActionsTypes.RESET_GROUP_ID
})

export const setOpenMenu = (openMenu: boolean) => ({
  type: ActionsTypes.SET_OPEN_MENU,
  openMenu
})

export const setOpenMenuContact = (openMenuContact: boolean) => ({
  type: ActionsTypes.SET_OPEN_MENU_CONTACT,
  openMenuContact
})

export const setOpenAgenda = (openAgenda: boolean) => ({
  type: ActionsTypes.SET_OPEN_AGENDA,
  openAgenda
})

export const setOpenSyncCalendar = (openSyncCalendar: boolean) => ({
  type: ActionsTypes.SET_OPEN_SYNC_CALENDAR,
  openSyncCalendar
})

export const setOpenAvailabilities = (openAvailabilities: boolean) => ({
  type: ActionsTypes.SET_OPEN_AVAILABILITIES,
  openAvailabilities
})

export const setAgenda = (agenda: IAgenda[]) => ({
  type: ActionsTypes.SET_AGENDA,
  agenda
})

export const setAgendaToRespond = (agendaToRespond: number) => ({
  type: ActionsTypes.SET_AGENDA_TO_RESPOND,
  agendaToRespond
})

export const setPlanning = (planning: { [key: string]: IAgenda[] }) => ({
  type: ActionsTypes.SET_PLANNING,
  planning
})

export const setPlanningLength = (planningLength: number) => ({
  type: ActionsTypes.SET_PLANNING_LENGTH,
  planningLength
})

export const setPlanningOrder = (planningOrder: string[]) => ({
  type: ActionsTypes.SET_PLANNING_ORDER,
  planningOrder
})

export const setAgendaLoading = (loadingAgenda: boolean) => ({
  type: ActionsTypes.SET_LOADING_AGENDA,
  loadingAgenda
})

export const setAgendaError = (errorAgenda?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_AGENDA,
  errorAgenda
})

export const setAgendaSlots = (agendaSlots: IUserSlot) => ({
  type: ActionsTypes.SET_AGENDA_SLOTS,
  agendaSlots
})

export const setAgendaInitDate = (agendaInitDate: Date | null) => ({
  type: ActionsTypes.SET_AGENDA_INIT_DATE,
  agendaInitDate
})

export const setAgendaInitDateLoading = (agendaInitDateLoading: boolean) => ({
  type: ActionsTypes.SET_AGENDA_INIT_DATE_LOADING,
  agendaInitDateLoading
})

export const setAgendaSlotsLoading = (loadingAgendaSlots: boolean) => ({
  type: ActionsTypes.SET_LOADING_AGENDA_SLOTS,
  loadingAgendaSlots
})

export const setAgendaSlotsError = (errorAgendaSlots?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_AGENDA_SLOTS,
  errorAgendaSlots
})

export const setExtendedPanel = (key: string, extendedPanel: boolean) => ({
  type: ActionsTypes.SET_EXTENDED_PANEL,
  key,
  extendedPanel
})

export const setKeywordsX = (keywordsX: IKeyword[]) => ({
  type: ActionsTypes.SET_KEYWORDS_X,
  keywordsX
})

export const setKeywordsXError = (errorKeywordsX: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_KEYWORDS_X,
  errorKeywordsX
})

export const setKeywordsXLoading = (loadingKeywordsX: boolean) => ({
  type: ActionsTypes.SET_LOADING_KEYWORDS_X,
  loadingKeywordsX
})

export const setKeywordsXLoaded = (loadedKeywordsX: boolean) => ({
  type: ActionsTypes.SET_LOADED_KEYWORDS_X,
  loadedKeywordsX
})

export const setKeywordsXLabel = (labelKeywordsX: string) => ({
  type: ActionsTypes.SET_LABEL_KEYWORDS_X,
  labelKeywordsX
})

export const setKeywordsY = (keywordsY: IKeyword[]) => ({
  type: ActionsTypes.SET_KEYWORDS_Y,
  keywordsY
})

export const setKeywordsYError = (errorKeywordsY: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_KEYWORDS_Y,
  errorKeywordsY
})

export const setKeywordsYLoading = (loadingKeywordsY: boolean) => ({
  type: ActionsTypes.SET_LOADING_KEYWORDS_Y,
  loadingKeywordsY
})

export const setKeywordsYLoaded = (loadedKeywordsY: boolean) => ({
  type: ActionsTypes.SET_LOADED_KEYWORDS_Y,
  loadedKeywordsY
})

export const setKeywordsYLabel = (labelKeywordsY: string) => ({
  type: ActionsTypes.SET_LABEL_KEYWORDS_Y,
  labelKeywordsY
})

export const setEventCoCompatibility = (eventCoCompatibility: string) => ({
  type: ActionsTypes.SET_EVENTCO_COMPATIBILITY,
  eventCoCompatibility
})

export const setRefreshRequired = (refreshRequired: boolean) => ({
  type: ActionsTypes.SET_REFRESH_REQUIRED,
  refreshRequired
})
