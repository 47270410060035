import styled from '@emotion/styled'
import {
  alpha,
  ClickAwayListener, Grow,
  Paper,
  Popper,
  PopperPlacementType,
  Tab,
  Tabs
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React, { FC, useMemo, useRef, useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import { FormattedMessage, useIntl } from 'react-intl'
import placeholder from '../../../assets/images/placeholder.png'
import { PRIMARY_COLOR } from '../../../theme'
import { BLACK_BLUE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import AppButton from '../../ui/AppButton/AppButton'
import FormPictureUploader from '../../ui/Form/FormPictureUploader'
import IconButtonBg from '../../ui/IconButtonBg/IconButtonBg'

interface IEditBackgroundButtonProps {
  value?: string
  type?: string
  disabled?: boolean
  position?: PopperPlacementType
  onChange?: (type: string, value: string) => void
  onUpdate?: (type: string, value: string) => void
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <StyledPanelBox
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </StyledPanelBox>
  )
}

const EditBackgroundHeader: FC<IEditBackgroundButtonProps> = ({
  value,
  type,
  disabled,
  position,
  onChange,
  onUpdate
}) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [currentType, setCurrentType] = useState(type)
  const [currentValue, setCurrentValue] = useState(value)

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const updateImage = () => {
    if (currentType && currentValue && onUpdate) {
      setCurrentType('image')
      onUpdate(currentType, currentValue)
    }
    setOpen(false)
  }

  const updateColor = () => {
    if (currentType && currentValue && onUpdate) {
      setCurrentType('color')
      onUpdate(currentType, currentValue)
    }
    setOpen(false)
  }

  const a11yProps = (index: any) => {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`
    }
  }

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const changeImage = (base64: string) => {
    const newType = 'image'
    setCurrentType(newType)
    setCurrentValue(base64)
    if (onChange) {
      onChange(newType, base64)
    }
  }

  const changeColor = (newColor: ColorResult) => {
    const newType = 'color'
    setCurrentType(newType)
    setCurrentValue(newColor.hex)
    if (onChange) {
      onChange(newType, newColor.hex)
    }
  }

  const memoImage = useMemo(
    () => (currentValue && !/^#(?:[0-9a-fA-F]{3}){1,2}$/g.test(currentValue) ? currentValue : null),
    [currentValue]
  )

  return (
    <>
      <IconButtonBg
        disabled={disabled}
        iconButtonRef={anchorRef}
        onClick={handleToggle}
        title={intl.formatMessage({ id: 'actions.edit.color' })}
      >
        <EditIcon
          titleAccess={intl.formatMessage({ id: 'titleAccess.EditBackgroundButton.EditIcon' })}
        />
      </IconButtonBg>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={position}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledPopper>
                  <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTab}
                    aria-label="Choix du fond pour le header"
                  >
                    <Tab label="Couleur" {...a11yProps(0)} />
                    <Tab label="Image" {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <ChromePicker
                      color={
                        currentType === 'color' ? currentValue || PRIMARY_COLOR : PRIMARY_COLOR
                      }
                      onChange={changeColor}
                    />
                    <AppButton onClick={updateColor}>
                      {/* disabled={currentType !== 'color'}*/}
                      <FormattedMessage id="action.save" />
                    </AppButton>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <FormPictureUploader
                      image={currentType === 'image' ? memoImage : null}
                      onChangeImage={changeImage}
                    >
                      <StyledImg src={placeholder} />
                    </FormPictureUploader>
                    <AppButton onClick={updateImage}>
                      {/* disabled={currentType !== 'image'}*/}
                      <FormattedMessage id="action.save" />
                    </AppButton>
                  </TabPanel>
                </StyledPopper>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

const StyledPanelBox = styled.div`
  & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 100%;

    button {
      margin-top: 15px;
    }
    .chrome-picker {
      box-shadow: none !important;
      width: 290px !important;
    }
  }
`

const StyledPopper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
    border-radius: ${BORDER_RADIUS_CARD};
    box-shadow: ${alpha(BLACK_BLUE, 0.3)} 0 0 2px, ${alpha(BLACK_BLUE, 0.3)} 0 4px 8px;

    > .chrome-picker {
      border-radius: 0 !important;
      box-shadow: none !important;
    }
  }
`

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
`

export default EditBackgroundHeader
