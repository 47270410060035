import styled from '@emotion/styled'
import React from 'react'
import SelectSortBy from '../globals/SelectSortBy/SelectSortBy'

interface IVisitorFiltersTri {
  setSortBy: (value: string) => void
  sortBy: string
}

const VisitorFiltersTri: React.FC<IVisitorFiltersTri> = ({ setSortBy, sortBy }) => {
  return (
    <StyledFilters>
      <SelectSortBy value={sortBy} onChange={setSortBy} alphabetic recommandation />
    </StyledFilters>
  )
}

// language=SCSS
const StyledFilters = styled.div`
  & {
    padding: 1em;
  }
`

export default VisitorFiltersTri
