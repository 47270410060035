import { Device } from '@opentok/client'
import { IUser, LanguageKeys } from '../../entities'
import { IAgenda, IUserSlot } from '../../entities/agenda'
import { IKeyword, IKeywordUser } from '../../entities/keyword'
import { VideoResolution, VideoSourceType } from '../../entities/mediaStream'

export enum ActionsTypes {
  SET_LOCALE = 'SET_LOCALE',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_LOCATION = 'SET_LOCATION',
  SET_USER_UPDATING = 'SET_USER_UPDATING',
  SET_USER = 'SET_USER',
  MERGE_USER = 'MERGE_USER',
  SET_HAS_PASSED_TUNNEL = 'SET_HAS_PASSED_TUNNEL',
  SET_VIDEO_SOURCE = 'SET_VIDEO_SOURCE',
  SET_VIDEO_INPUT_DEVICES = 'SET_VIDEO_INPUT_DEVICES',
  SET_AUDIO_INPUT_DEVICES = 'SET_AUDIO_INPUT_DEVICES',
  SET_DEFAULT_AUDIO_SOURCE = 'SET_DEFAULT_AUDIO_SOURCE',
  RESET_DEFAULT_AUDIO_SOURCE = 'RESET_DEFAULT_AUDIO_SOURCE',
  SET_DEFAULT_VIDEO_SOURCE = 'SET_DEFAULT_VIDEO_SOURCE',
  RESET_DEFAULT_VIDEO_SOURCE = 'RESET_DEFAULT_VIDEO_SOURCE',
  SET_DEFAULT_RESOLUTION = 'SET_DEFAULT_RESOLUTION',
  SET_RECOMMENDED_FRAMERATE = 'SET_RECOMMENDED_FRAMERATE',
  SET_RECOMMENDED_RESOLUTION = 'SET_RECOMMENDED_RESOLUTION',
  SET_PREFERED_VIDEO_DISPLAY = 'SET_PREFERED_VIDEO_DISPLAY',
  SET_KEYWORDS = 'SET_KEYWORDS',
  SET_KEYWORDS_LOADING = 'SET_KEYWORDS_LOADING',
  SET_KEYWORDS_ERROR = 'SET_KEYWORDS_ERROR',
  SET_KEYWORDS_BY_USER = 'SET_KEYWORDS_BY_USER',
  SET_KEYWORDS_BY_USER_LOADING = 'SET_KEYWORDS_BY_USER_LOADING',
  SET_KEYWORDS_BY_USER_ERROR = 'SET_KEYWORDS_BY_USER_ERROR',
  SET_SEARCH = 'SET_SEARCH',
  SET_GROUP_ID = 'SET_GROUP_ID',
  RESET_GROUP_ID = 'RESET_GROUP_ID',
  SET_OPEN_MENU = 'SET_OPEN_MENU',
  SET_OPEN_MENU_CONTACT = 'SET_OPEN_MENU_CONTACT',
  SET_OPEN_AGENDA = 'SET_OPEN_AGENDA',
  SET_OPEN_AVAILABILITIES = 'SET_OPEN_AVAILABILITIES',
  SET_AGENDA = 'SET_AGENDA',
  SET_AGENDA_TO_RESPOND = 'SET_AGENDA_TO_RESPOND',
  SET_PLANNING = 'SET_PLANNING',
  SET_PLANNING_LENGTH = 'SET_PLANNING_LENGTH',
  SET_PLANNING_ORDER = 'SET_PLANNING_ORDER',
  SET_LOADING_AGENDA = 'SET_LOADING_AGENDA',
  SET_ERROR_AGENDA = 'SET_ERROR_AGENDA',
  SET_AGENDA_SLOTS = 'SET_AGENDA_SLOTS',
  SET_LOADING_AGENDA_SLOTS = 'SET_LOADING_AGENDA_SLOTS',
  SET_ERROR_AGENDA_SLOTS = 'SET_ERROR_AGENDA_SLOTS',
  SET_EXTENDED_PANEL = 'SET_EXTENDED_PANEL',
  SET_KEYWORDS_X = 'SET_KEYWORDS_X',
  SET_ERROR_KEYWORDS_X = 'SET_ERROR_KEYWORDS_X',
  SET_LOADING_KEYWORDS_X = 'SET_LOADING_KEYWORDS_X',
  SET_LOADED_KEYWORDS_X = 'SET_LOADED_KEYWORDS_X',
  SET_LABEL_KEYWORDS_X = 'SET_LABEL_KEYWORDS_X',
  SET_KEYWORDS_Y = 'SET_KEYWORDS_Y',
  SET_ERROR_KEYWORDS_Y = 'SET_ERROR_KEYWORDS_Y',
  SET_LOADING_KEYWORDS_Y = 'SET_LOADING_KEYWORDS_Y',
  SET_LOADED_KEYWORDS_Y = 'SET_LOADED_KEYWORDS_Y',
  SET_LABEL_KEYWORDS_Y = 'SET_LABEL_KEYWORDS_Y',
  SET_EVENTCO_COMPATIBILITY = 'SET_EVENTCO_COMPATIBILITY',
  SET_OPEN_SYNC_CALENDAR = 'SET_OPEN_SYNC_CALENDAR',
  SET_REFRESH_REQUIRED = 'SET_REFRESH_REQUIRED',
  SET_AGENDA_INIT_DATE = 'SET_AGENDA_INIT_DATE',
  SET_AGENDA_INIT_DATE_LOADING = 'SET_AGENDA_INIT_DATE_LOADING'
}

export interface IAppState {
  user?: IUser | null
  userUpdating?: boolean
  locale: LanguageKeys
  location: string
  loading?: boolean
  error?: string
  hasPassedTunnel?: boolean
  videoSource: VideoSourceType
  videoInputDevices?: Device[] | null
  audioInputDevices?: Device[] | null
  defaultVideoSource?: string | null
  defaultAudioSource?: string | null
  defaultResolution?: VideoResolution
  recommendedFrameRate?: 1 | 15 | 30 | 7 | undefined
  recommendedResolution?: VideoResolution
  preferedVideoDisplay: boolean
  keywords: { total: number; items: IKeyword[] }
  loadingKeywords?: boolean
  errorKeywords?: string
  keywordsByUser: { total: number; items: IKeywordUser[] }
  loadingKeywordsByUser?: boolean
  errorKeywordsByUser?: string
  search?: string | null
  groupId: number | string
  openMenu: boolean
  openMenuContact: boolean
  openAgenda: boolean
  openAvailabilities: boolean
  openSyncCalendar: boolean
  agenda: IAgenda[]
  agendaToRespond: number
  planningOrder: string[]
  planning: { [key: string]: IAgenda[] }
  planningLength: number
  loadingAgenda?: boolean
  errorAgenda?: string
  agendaSlots?: IUserSlot
  loadingAgendaSlots?: boolean
  errorAgendaSlots?: string
  extendedPanel: { [key: string]: boolean }
  keywordsX: IKeyword[]
  loadingKeywordsX?: boolean
  errorKeywordsX?: string
  loadedKeywordsX: boolean
  labelKeywordsX?: string
  keywordsY: IKeyword[]
  loadingKeywordsY?: boolean
  errorKeywordsY?: string
  loadedKeywordsY: boolean
  labelKeywordsY?: string
  eventCoCompatibility?: string
  refreshRequired?: boolean
  agendaInitDate?: Date | null
  agendaInitDateLoading: boolean
}

export enum StorageKeys {
  HAS_PASSED_TUNNEL = 'idealco_has_passed_tunnel',
  DEFAULT_AUDIO_SOURCE = 'opentok_default_audio_source',
  DEFAULT_VIDEO_SOURCE = 'opentok_default_video_source',
  DEFAULT_RESOLUTION = 'opentok_default_resolution',
  PREFERED_VIDEO_DISPLAY = 'opentok_prefered_video_display',
  RECOMMENDED_FRAMERATE = 'opentok_recommended_framerate',
  RECOMMENDED_RESOLUTION = 'opentok_recommended_resolution',
  QUALITY_TEST_RESULTS = 'opentok_quality_test_results',
  PREFERED_LANGUAGE = 'idealco_prefered_language'
}
