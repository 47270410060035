import { AnyAction, Reducer } from 'redux'

export interface IReducerHandler<TState> {
  [type: string]: Reducer<TState>
}

export const createReducer =
  <TState, TAction extends AnyAction>(
    initialState: TState,
    handlers: IReducerHandler<TState>
  ): Reducer<TState, TAction> =>
  (state = initialState, action: TAction): TState => {
    try {
      return action.type && handlers[action.type] ? handlers[action.type](state, action) : state
    } catch (e) {
      throw e
    }
  }
