import styled from '@emotion/styled'
import { alpha, Chip, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { FC, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { deleteUnavailability } from '../../api/agenda'
import { MagicStreamStatus } from '../../entities'
import { IAgenda } from '../../entities/agenda'
import { useDateFns, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { loadAgenda } from '../../store/app'
import { sendResponse } from '../../store/chat'
import { PRIMARY_COLOR } from '../../theme'
import { BLACK_BLUE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import { breakpoints } from '../breakpoints'
import UserAvatar from '../globals/UserAvatar/UserAvatar'
import StreamAppointmentActions from '../magicstream/StreamAppointmentActions'
import ConfirmDialog from '../modals/ConfirmDialog'
import AgendaItemMessage from './AgendaItemMessage'

interface IAgendaItemProps {
  item: IAgenda
}

const AgendaItem: FC<IAgendaItemProps> = ({ item }) => {
  const intl = useIntl()
  const dateFns = useDateFns()
  const [deleting, setDeleting] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const user = useSelector((state: IRootState) => state.appState.user)
  const callEnded = useSelector((state: IRootState) => state.chatState.callEnded)
  const dispatch = useThunkDispatch()

  const expirated = useMemo(
    () =>
      item.magicstream &&
      ((item.magicstream.expirationDate
        ? new Date() > new Date(item.magicstream.expirationDate)
        : false) ||
        callEnded[item.magicstream.id] ||
        item.magicstream.status === MagicStreamStatus.ACCEPTED ||
        item.magicstream.status === MagicStreamStatus.DECLINED),
    [item, callEnded]
  )

  const unavailability = useMemo(() => item.type === 'unavailability', [item])

  const sameDay = useMemo(() => {
    const startDate = new Date(item.dateStart)
    const endDate = new Date(item.dateEnd)
    return (
      startDate.getFullYear() === endDate.getFullYear() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getDate() === endDate.getDate()
    )
  }, [item])

  const handleConfirmDelete = () => {
    setConfirmDelete(true)
  }

  const handleDelete = async () => {
    setConfirmDelete(false)
    setDeleting(true)
    if (unavailability) {
      await deleteUnavailability(item.id)
    } else if (item.magicstream) {
      await dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'appointment.refuse'
          }),
          0,
          undefined,
          item.magicstream.id
        )
      )
    }
    await dispatch(loadAgenda())
    setDeleting(false)
  }

  return (
    <StyledAgendaItem>
      {deleting && <FormattedMessage id="agenda.deleting" />}
      {!deleting && (
        <>
          {unavailability && (
            <UnavailabilityItem>
              <StyledChip
                label={intl.formatMessage(
                  { id: sameDay ? 'date.format.unavailability.sameDay' : 'date.format.unavailability' },
                  {
                    startDate: dateFns.format(
                      item.dateStart,
                      intl.formatMessage({ id: sameDay ? 'date.format.duration' : 'date.format.short' })
                    ),
                    endDate: dateFns.format(
                      item.dateEnd,
                      intl.formatMessage({ id: sameDay ? 'date.format.duration' : 'date.format.short' })
                    )
                  }
                )}
                color="default"
              />
            </UnavailabilityItem>
          )}
          {!unavailability && (
            <>
              <div>
                <StyledChipHours
                  label={`${dateFns.format(
                    item.dateStart,
                    intl.formatMessage({ id: 'date.format.duration' })
                  )} - ${dateFns.format(
                    item.dateEnd,
                    intl.formatMessage({ id: 'date.format.duration' })
                  )}`}
                  color="secondary"
                />
                <UserAvatar
                  popper={{ placement: 'left' }}
                  user={item.subjectUser}
                  showDetails
                  disabledAnimation
                />
              </div>
              <div>
                <StyledSummary>
                  <FormattedMessage id="agenda.message" values={{ message: item.summary }} />
                </StyledSummary>
                {item.description && (
                  <AgendaItemMessage center>{item.description}</AgendaItemMessage>
                )}
                <StyledStatus>
                  <FormattedMessage
                    id={
                      item.status
                        ? 'appointment.status.accepted'
                        : expirated
                          ? 'appointment.status.waiting.expired'
                          : 'appointment.status.waiting'
                    }
                  />
                </StyledStatus>
                {item.magicstream && item.magicstream.creatorUser?.id !== user?.id && !expirated && (
                  <StreamAppointmentActions stream={item.magicstream} />
                )}
              </div>
            </>
          )}
          <IconButton
            className="delete-icon"
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={handleConfirmDelete}
            color="inherit"
            aria-label={intl.formatMessage({ id: 'ariaLabel.Agenda.DeleteIcon' })}
          >
            <DeleteIcon
              fontSize="small"
              titleAccess={intl.formatMessage({ id: 'titleAccess.Agenda.DeleteIcon' })}
            />
          </IconButton>
        </>
      )}
      <ConfirmDialog
        open={confirmDelete}
        message={intl.formatMessage({ id: 'confirm.delete.agenda' })}
        okEvent={handleDelete}
        cancelEvent={() => {
          setConfirmDelete(false)
        }}
      />
    </StyledAgendaItem>
  )
}

const StyledAgendaItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 5px 3px 10px;
  border-radius: ${BORDER_RADIUS_CARD};
  margin-bottom: 10px;

  & > div:first-of-type {
    margin-right: 10px;
  }

  &:hover {
    transition: all 0.3s;
    background-color: ${alpha(BLACK_BLUE, 0.05)};

    &:after {
      background-color: ${PRIMARY_COLOR};
    }
  }

  @media (min-width: ${breakpoints.md}) {
    .delete-icon {
      opacity: 0;
    }
    &:hover {
      .delete-icon {
        opacity: 1;
      }
    }
  }
`

const UnavailabilityItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledChip = styled(Chip)`
  max-width: 250px;

  & > span {
    white-space: break-spaces;
  }
`

const StyledChipHours = styled(Chip)`
  max-width: 250px;
  margin-bottom: 15px;
`

const StyledSummary = styled.p``

const StyledStatus = styled.p`
  font-style: italic;
  text-align: center;
  font-size: 10px;
`

export default AgendaItem
