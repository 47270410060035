import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IHighlightState } from './model'

const initialState: IHighlightState = {
  highlight: { total: 0, items: [] },
  loading: false,
  loaded: false
}

const actionHandlers: IReducerHandler<IHighlightState> = {
  [ActionsTypes.SET_HIGHLIGHT]: (
    state: IHighlightState | undefined,
    { highlight }: AnyAction
  ): IHighlightState => ({
    ...state!,
    highlight
  }),

  [ActionsTypes.SET_HIGHLIGHT_ERROR]: (
    state: IHighlightState | undefined,
    { error }: AnyAction
  ): IHighlightState => ({
    ...state!,
    error
  }),

  [ActionsTypes.SET_HIGHLIGHT_LOADING]: (
    state: IHighlightState | undefined,
    { loading }: AnyAction
  ): IHighlightState => ({
    ...state!,
    loading
  }),

  [ActionsTypes.SET_HIGHLIGHT_LOADED]: (
    state: IHighlightState | undefined,
    { loaded }: AnyAction
  ): IHighlightState => ({
    ...state!,
    loaded
  })
}

export const reducer = createReducer(initialState, actionHandlers)
