import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Grid } from '@material-ui/core'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { addUserLog as addUserLogApi } from '../../api'
import placeholderVideo from '../../assets/images/placeholder-video.png'
import { breakpoints } from '../../components/breakpoints'
import ExhibitorInfos from '../../components/exhibitor/ExhibitorInfos'
import { videoBackgroundStyle } from '../../components/exhibitor/VideoBackgroundStyle'
import AppPlayer from '../../components/globals/AppPlayer/AppPlayer'
import VideoChat from '../../components/globals/Room/VideoChat'
import VideoStand from '../../components/globals/Room/VideoStand'
import { IRoom, LogUserActions, LogUserUrlTypes, RoomType } from '../../entities'
import { useScrollEvent, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import settings from '../../settings'
import { setLocation } from '../../store/app'
import { renameExhibitorRoom, setOnOfficeRoom } from '../../store/room'
import { PRIMARY, SECONDARY, WHITE } from '../../theme/colors'
import { LEFT_MENU_WIDTH } from '../../theme/sizes'
import EditableStand from '../globals/Room/EditableStand'

interface IExhibitorContentProps {
  videoPlaying: boolean
  setVideoPlaying: (val: boolean) => void
  lastVideoPlaying: boolean
  setLastVideoPlaying: (val: boolean) => void
}

const ExhibitorContent: React.FC<IExhibitorContentProps> = ({
  videoPlaying,
  setVideoPlaying,
  lastVideoPlaying,
  setLastVideoPlaying
}) => {
  const intl = useIntl()
  const location = useLocation()
  const [trigger, setTrigger] = useState(false)
  const [videoMuted, setVideoMuted] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [videoVolume, setVideoVolume] = useState(1)
  const user = useSelector((state: IRootState) => state.appState.user)
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const editMode = useSelector((state: IRootState) => state.exhibitorState.editMode)
  const roomNumber = useSelector((state: IRootState) => state.roomState.roomNumber)
  const fullscreenSubscriber = useSelector(
    (state: IRootState) => state.roomState.fullscreenSubscriber
  )
  const dispatch = useThunkDispatch()
  const scrollEvent = useCallback(() => {
    setTrigger(window.scrollY > 350)
  }, [])
  useScrollEvent(scrollEvent)

  const refAppPlayer = React.createRef<HTMLDivElement>()

  const isEventRoom = useMemo(
    () =>
      roomNumber > -1 &&
      exhibitor &&
      exhibitor.rooms[roomNumber] &&
      exhibitor.rooms[roomNumber].type === RoomType.PslEvent,
    [roomNumber, exhibitor]
  )

  const canEditSvg = useMemo(
    () =>
      settings.video.svgEditable &&
      editMode &&
      exhibitor &&
      user &&
      user.roles &&
      (user.roles.indexOf(`edit_group_${exhibitor.group.id}`) > -1 ||
        user.roles.indexOf(`edit_group_svg_${exhibitor.group.id}`) > -1 ||
        user.roles.indexOf('edit_group_svg_*') > -1),
    [editMode, user, exhibitor]
  )

  const activeVideoAudio = useCallback(
    (activeAudio: boolean) => {
      setVideoMuted(!activeAudio)
      setVideoVolume(activeAudio ? 1 : 0)
      setVideoPlaying(activeAudio ? lastVideoPlaying : false)
      if (!activeAudio) {
        setLastVideoPlaying(videoPlaying)
      }
    },
    [videoPlaying, lastVideoPlaying, setVideoPlaying, setLastVideoPlaying]
  )

  const onEnterRoom = useCallback(
    (num: number) => {
      if (exhibitor) {
        addUserLogApi({
          action: LogUserActions.enter_table,
          description: `${intl.formatMessage({ id: 'pathname.description.exhibitor' })} ${exhibitor.name
            }, ${intl.formatMessage({ id: 'pathname.description.exhibitor.room' })} '${exhibitor.rooms[num].title || exhibitor.rooms[num].roomClassName
            }'`,
          url: `/exposant/${exhibitor.id}?salon=${num}`,
          urlType: LogUserUrlTypes.exhibitor
        })
        if (
          user &&
          exhibitor.rooms[num].userPresence &&
          exhibitor.rooms[num].userPresence!.user.id === user.id
        ) {
          // enter at my office
          dispatch(setOnOfficeRoom(true))
        }
        dispatch(setLocation(`/exposant/${exhibitor.id}?salon=${num}`))
      }
    },
    [intl, exhibitor, user, dispatch]
  )

  const onLeaveRoom = useCallback(
    (num: number) => {
      if (exhibitor) {
        addUserLogApi({
          action: LogUserActions.view,
          description: `${intl.formatMessage({ id: 'pathname.description.exhibitor' })} ${exhibitor.name
            }`,
          url: `/exposant/${exhibitor.id}`,
          urlType: LogUserUrlTypes.exhibitor
        })
        dispatch(setLocation(`/exposant/${exhibitor.id}`))
        browserHistory.replace(
          `/exposant/${exhibitor.id}${location.search.indexOf('magicstream=true') > -1 ? '?magicstream=true' : ''
          }${location.search.indexOf('appointment=true') > -1 ? '&appointment=true' : ''}`
        )
      }
    },
    [intl, exhibitor, location, dispatch]
  )

  const handleRenameRoom = async (room: IRoom, value: string) => {
    if (exhibitor) {
      dispatch(renameExhibitorRoom(room, value, exhibitor))
    }
  }

  if (!exhibitor) return <></>

  return (
    <StyledExhibitorContent isCollapsed={collapse}>
      <StyledGridContainer
        container
        justifyContent={window.lightTheme || !exhibitor.enableFeatureRooms ? 'center' : 'space-between'}
        spacing={2}
      >
        {!window.lightTheme && exhibitor.enableFeatureRooms && (
          <StyledButtonCollapse isCollapsed={collapse}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DoubleArrowIcon />}
              onClick={() => setCollapse(!collapse)}
            >
              <FormattedMessage
                id={collapse ? 'collapse.sidebar.true' : 'collapse.sidebar.false'}
              />
            </Button>
          </StyledButtonCollapse>
        )}
        <StyledGridRelative item xs={12} lg={window.lightTheme || !exhibitor.enableFeatureRooms ? 10 : collapse ? 12 : 8}>
          <StyledAppPlayer
            ref={refAppPlayer}
            withVideo={!!exhibitor.vodUrl}
            isEventRoom={isEventRoom}
          >
            {exhibitor.vodUrl && (
              <AppPlayer
                hidden={roomNumber > -1}
                url={exhibitor.vodUrl}
                controls
                pip
                playing={videoPlaying}
                muted={videoMuted}
                volume={videoVolume}
                setVideoPlaying={setVideoPlaying}
              />
            )}
            {!exhibitor.vodUrl && <StyledImage src={placeholderVideo} alt="No video" />}
          </StyledAppPlayer>
          {exhibitor.enableFeatureRooms && (
            <>
              {canEditSvg ? (
                <EditableStand
                  width={exhibitor.width}
                  height={exhibitor.height}
                  svgString={exhibitor.svgString}
                  rooms={exhibitor.rooms}
                />
              ) : (
                <VideoStand
                  id={`exhibitor-${exhibitor.id}`}
                  width={exhibitor.width}
                  height={exhibitor.height}
                  trigger={trigger && !isEventRoom}
                  svgString={exhibitor.svgString}
                  isBroadcaster={exhibitor.isBroadcaster}
                  isEditable={exhibitor.editable}
                  rooms={exhibitor.rooms}
                  chairs={exhibitor.chairs}
                  totalUsers={exhibitor.nbUsers}
                  refAppPlayer={refAppPlayer}
                  activeVideoAudio={activeVideoAudio}
                  panelChildren={<VideoChat />}
                  onEnterRoom={onEnterRoom}
                  onLeaveRoom={onLeaveRoom}
                  handleRenameRoom={handleRenameRoom}
                />
              )}
            </>
          )}
        </StyledGridRelative>
        {(!window.lightTheme || !exhibitor.enableFeatureRooms) && !fullscreenSubscriber && (
          <Grid className="sidebar-info" item xs={12} lg={!exhibitor.enableFeatureRooms ? 12 : 4}>
            <ExhibitorInfos />
          </Grid>
        )}
      </StyledGridContainer>
    </StyledExhibitorContent>
  )
}

const StyledButtonCollapse = styled.div<{ isCollapsed: boolean }>`
  & {
    position: fixed;
    bottom: 20px;
    left: ${LEFT_MENU_WIDTH};
    z-index: 10;
    padding-left: 20px;
    display: none;

    @media (min-width: ${breakpoints.xl}) {
      display: block;
    }
    ${(props) =>
    props.isCollapsed
      ? css`
            .MuiButtonBase-root {
              background-color: ${SECONDARY};
            }
            svg {
              color: ${WHITE};
            }
          `
      : css`
            .MuiButtonBase-root {
              background-color: ${WHITE};
              color: ${PRIMARY};
            }
            svg {
              color: ${PRIMARY};
              transform: rotate(180deg);
            }
          `}
  }
`

const StyledExhibitorContent = styled.div<{ isCollapsed: boolean }>`
  & {
    width: 100%;

    ${(props) =>
    props.isCollapsed
      ? css`
            & .sidebar-info {
              display: none;
            }
          `
      : ''}
  }
`

const StyledGridContainer = styled(Grid)`
  & {
    @media (min-width: ${breakpoints.lg}) {
      flex-direction: row-reverse;
    }
  }
`

const StyledGridRelative = styled(Grid)`
  & {
    position: relative;
  }
`

const StyledAppPlayer = styled.div<{ withVideo: boolean; isEventRoom: boolean | null }>`
  & {
    position: relative;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 30px;
    text-align: center;

    ${(props) =>
    props.isEventRoom ? `min-height: 350px;` : props.withVideo ? videoBackgroundStyle : ''};
  }
`

const StyledImage = styled.img`
  & {
    margin: 0 auto;
    width: 100%;
    height: 350px;
    object-fit: contain;
  }
`

export default ExhibitorContent
