import { IHighlight } from '../../entities/highlight'

export enum ActionsTypes {
  SET_HIGHLIGHT = 'SET_HIGHLIGHT',
  SET_HIGHLIGHT_ERROR = 'SET_HIGHLIGHT_ERROR',
  SET_HIGHLIGHT_LOADING = 'SET_HIGHLIGHT_LOADING',
  SET_HIGHLIGHT_LOADED = 'SET_HIGHLIGHT_LOADED'
}

export interface IHighlightState {
  highlight: { total: number; items: IHighlight[] }
  loading: boolean
  loaded: boolean
  error?: string
}
