import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import {
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import React, { FC, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgConferences } from '../../../assets/images/icons/conferences.svg'
import { ReactComponent as SvgExhibitors } from '../../../assets/images/icons/exposants.svg'
import { ConferenceViewDisplayType, PROFILINGS } from '../../../entities'
import { useThunkDispatch } from '../../../hooks'
import useIsProfilingEnabled from '../../../hooks/useIsProfilingEnabled'
import { browserHistory, IRootState } from '../../../redux'
import { setOpenMenu } from '../../../store/app'
import { logout } from '../../../store/auth'
import { setConferenceViewDisplayType } from '../../../store/conference'
import { setPageReferral } from '../../../store/gtm'
import { RED } from '../../../theme/colors'
import asyncLocalStorage from '../../../utils/asyncLocalstorage'
import ProfilingFullscreen from '../../profile/ProfilingFullscreen'
import MyAvatar from '../MyAvatar/MyAvatar'

// Component
const UserMenu: FC = () => {
  const { sendDataToGTM } = useGTM()
  const [open, setOpen] = useState(false)
  const [openProfiling, setOpenProfiling] = useState(false)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const anchorRef = useRef<HTMLDivElement>(null)
  const isProfilingEnabled = useIsProfilingEnabled()
  const dispatch = useThunkDispatch()

  const handleToggle = () => {
    sendDataToGTM({
      event: 'click-header-user-menu',
      label: 'Toggle user menu'
    })
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const seeProfile = () => {
    dispatch(setPageReferral(`${pageReferral}::user-menu`))
    browserHistory.push('/mes-infos')
    setOpen(false)
    dispatch(setOpenMenu(false))
  }

  const seeMyConferences = () => {
    dispatch(setPageReferral(`${pageReferral}::user-menu`))
    dispatch(setConferenceViewDisplayType(ConferenceViewDisplayType.LIST))
    browserHistory.push('/conference?page=1&name=&keyword=&sortBy=date&type=all&favorite=true')
    setOpen(false)
    dispatch(setOpenMenu(false))
  }

  const seeMyExhibitors = () => {
    dispatch(setPageReferral(`${pageReferral}::user-menu`))
    browserHistory.push('/exposant?page=1&search=&keyword=&sortBy=alphabetic&favorite=true')
    setOpen(false)
    dispatch(setOpenMenu(false))
  }

  const handleLogout = () => {
    if (window.pusher) window.pusher.disconnect()
    dispatch(logout(asyncLocalStorage))
  }

  return (
    <>
      <StyledUserMenu>
        <MyAvatar
          avatarRef={anchorRef}
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleToggle}
          disabledAnimation
        ></MyAvatar>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={seeProfile}>
                      <ListItemIcon>
                        <AccountBoxIcon />
                      </ListItemIcon>
                      <FormattedMessage id="usermenu.profile" />
                    </MenuItem>
                    {isProfilingEnabled && (
                      <MenuItem onClick={() => setOpenProfiling(true)}>
                        <ListItemIcon>
                          <AssessmentIcon />
                        </ListItemIcon>
                        <FormattedMessage id="usermenu.profiling" />
                      </MenuItem>
                    )}
                    {!window.lightTheme && (
                      <>
                        <MenuItem onClick={seeMyConferences}>
                          <ListItemIcon>
                            <SvgConferences />
                          </ListItemIcon>
                          <FormattedMessage id="usermenu.my.conferences" />
                        </MenuItem>
                        <MenuItem onClick={seeMyExhibitors}>
                          <ListItemIcon>
                            <SvgExhibitors />
                          </ListItemIcon>
                          <FormattedMessage id="usermenu.my.exhibitors" />
                        </MenuItem>
                      </>
                    )}
                    <MenuItem className="color-red" onClick={handleLogout}>
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      <FormattedMessage id="usermenu.logout" />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </StyledUserMenu>
      <ProfilingFullscreen
        opened={openProfiling}
        type={PROFILINGS.USER}
        onClose={() => setOpenProfiling(false)}
      />
    </>
  )
}

const StyledUserMenu = styled.div`
  & {
    margin: 1em;

    svg {
      width: 21px;
      height: 21px;
    }
    .color-red {
      color: ${RED};

      svg {
        color: ${RED};
      }
    }
    .MuiListItemIcon-root {
      min-width: 40px;
    }
  }
`

export default UserMenu
