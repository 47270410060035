import '@ckeditor/ckeditor5-build-inline/build/translations/fr'
import DateFnsUtils from '@date-io/date-fns'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import enLocale from 'date-fns/locale/en-US'
import frLocale from 'date-fns/locale/fr'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../redux'

function getLocale(localeStr: string) {
  switch (localeStr || 'fr') {
    case 'fr':
      return frLocale
    case 'en':
    default:
      return enLocale
  }
}

interface IDatePickerProps extends KeyboardDatePickerProps {
  iconMode?: boolean
}

const DatePicker: React.FC<IDatePickerProps> = ({ iconMode, ...props }) => {
  const locale = useSelector((state: IRootState) => state.appState.locale)

  return (
    <StyledDatePicker iconMode={iconMode}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
        <KeyboardDatePicker
          margin="normal"
          clearable
          KeyboardButtonProps={{
            'aria-label': 'change date/time'
          }}
          clearLabel={<FormattedMessage id="dialog.clear" />}
          cancelLabel={<FormattedMessage id="dialog.cancel" />}
          todayLabel={<FormattedMessage id="dialog.today" />}
          okLabel={<FormattedMessage id="dialog.validate" />}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </StyledDatePicker>
  )
}

const CssIconMode = css`
  & > .MuiFormControl-root {
    margin-top: 5px;
  }
  & .MuiInputBase-input,
  & .MuiInput-underline:after,
  & .MuiInput-underline::before,
  & .MuiFormHelperText-root {
    display: none;
  }
`

const StyledDatePicker = styled.div<{ iconMode?: boolean }>`
  & > .MuiFormControl-root {
    margin-top: 0;
  }
  ${(props) => (props.iconMode ? CssIconMode : '')}
`

export default DatePicker
