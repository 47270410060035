import { IOpentokRecommended, IRoomQueue } from '../../entities'

export enum ActionsTypes {
  SET_OPENTOK_ERROR = 'SET_OPENTOK_ERROR',
  SET_OPENTOK_STREAMING = 'SET_OPENTOK_STREAMING',
  SET_OPENTOK_BROADCASTER = 'SET_OPENTOK_BROADCASTER',
  SET_OPENTOK_RECOMMENDED = 'SET_OPENTOK_RECOMMENDED',
  SET_ROOM_TOKEN = 'SET_ROOM_TOKEN',
  SET_ROOM_TOKEN_LOADING = 'SET_ROOM_TOKEN_LOADING',
  RESET_ROOM_TOKEN = 'RESET_ROOM_TOKEN',
  SET_ROOM_NUMBER = 'SET_ROOM_NUMBER',
  SET_FULLSCREEN_SUBSCRIBER = 'SET_FULLSCREEN_SUBSCRIBER',
  SET_SCREEN_SHARING_AVAILABLE = 'SET_SCREEN_SHARING_AVAILABLE',
  UPDATE_ROOM_LOCKED = 'UPDATE_ROOM_LOCKED',
  SET_ROOM_UPDATING = 'SET_ROOM_UPDATING',
  SET_ROOM_ERROR = 'SET_ROOM_ERROR',
  SET_ROOM_QUEUE_LOADING = 'SET_ROOM_QUEUE_LOADING',
  SET_ROOM_QUEUE_ERROR = 'SET_ROOM_QUEUE_ERROR',
  REMOVE_ROOM_QUEUE_LOADING = 'REMOVE_ROOM_QUEUE_LOADING',
  REMOVE_ROOM_QUEUE_ERROR = 'REMOVE_ROOM_QUEUE_ERROR',
  SET_ROOM_QUEUE = 'SET_ROOM_QUEUE',
  ADD_ROOM_QUEUE = 'ADD_ROOM_QUEUE',
  REMOVE_ROOM_QUEUE = 'REMOVE_ROOM_QUEUE',
  SET_ROOM_QUEUED = 'SET_ROOM_QUEUED',
  SET_EXTERNAL_ERROR = 'SET_OPENTOK_ERROR',
  SET_EXTERNAL_LOADING = 'SET_EXTERNAL_LOADING',
  SET_START_OFFICE_CALL = 'SET_START_OFFICE_CALL',
  SET_ON_OFFICE_ROOM = 'SET_ON_OFFICE_ROOM',
  SET_OFFICE_ROOM_INDEX = 'SET_OFFICE_ROOM_INDEX',
  SET_ROOM_ACCESS_DENIED = 'SET_ROOM_ACCESS_DENIED'
}

export interface IRoomState {
  error?: string
  errorExternal?: string
  opentokRecommended: IOpentokRecommended | null
  streamingActive: boolean
  broadcaster: boolean
  loadingRoomToken: boolean
  loadingExternal: boolean
  roomNumber: number
  roomToken: string | null
  fullscreenSubscriber: string
  screenSharingAvailable: boolean
  roomsLocked: { [key: number]: boolean }
  roomUpdating: boolean
  roomError?: string
  loadingRoomQueue: boolean
  errorRoomQueue?: string
  loadingRemoveRoomQueue: boolean
  errorRemoveRoomQueue?: string
  roomQueue?: IRoomQueue
  roomQueued: boolean
  startOfficeCall: boolean
  onOfficeRoom: boolean
  officeRoomIndex: number
  roomAccessDenied: boolean
}
