import styled from '@emotion/styled'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { FullScreenHandle } from 'react-full-screen'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import useSound from 'use-sound'
import connectedSfx from '../../../assets/sounds/connected.mp3'
import disconnectedSfx from '../../../assets/sounds/disconnected.mp3'
import { IRoom, RoomType } from '../../../entities'
import { IStream, MediaDevice, MediaError } from '../../../entities/mediaStream'
import { useMagicStream, useThunkDispatch } from '../../../hooks'
import { IMediaStream } from '../../../media/IMediaStream'
import { IRootState } from '../../../redux'
import { updateDefaultSources } from '../../../store/app'
import { setJitsiError } from '../../../store/jitsi'
import { setOpentokError } from '../../../store/room'
import { setZoomError } from '../../../store/zoom'
import { WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import asyncLocalStorage from '../../../utils/asyncLocalstorage'
import { breakpoints } from '../../breakpoints'
import Loading from '../../Loading'
import LiveTag from '../LiveTag/LiveTag'
import JitsiIFrame from '../Media/Jitsi/JitsiIFrame'
import Publisher from '../Media/Publisher'
import Subscriber from '../Media/Subscriber'
import ZoomIFrame from '../Media/Zoom/ZoomIFrame'
import EventRoom from './EventRoom'
import ExternalRoom from './ExternalRoom'
import VideoRoomPanel from './VideoRoomPanel'

export interface IVideoRoomProps {
  index: number
  active: number
  room: IRoom
  rooms: IRoom[]
  viewers: number
  isEditable: boolean
  panelChildren: ReactNode
  mediaStream: IMediaStream | null
  mediaPublisher: React.RefObject<any>[]
  streams: IStream[]
  hasMicro: { [key: string]: boolean }
  isChangeDetected: boolean
  handleFullscreen: FullScreenHandle
  onClickRoom: (num: number, broadcasterEnabled?: boolean | undefined) => Promise<boolean>
  handleRenameRoom: (room: IRoom, value: string) => void
}

const VideoRoom: React.FC<IVideoRoomProps> = ({
  index,
  active,
  room,
  rooms,
  viewers,
  isEditable,
  panelChildren,
  mediaStream,
  mediaPublisher,
  streams,
  hasMicro,
  isChangeDetected,
  handleFullscreen,
  onClickRoom,
  handleRenameRoom
}) => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const [playConnected] = useSound(connectedSfx)
  const [playDisconnected] = useSound(disconnectedSfx)
  const [error, setError] = useState<MediaError>()
  const [videoWidth, setVideoWidth] = useState(0)
  const [videoHeight, setVideoHeight] = useState(0)
  const [autoClose, setAutoClose] = useState(false)
  const user = useSelector((state: IRootState) => state.appState.user)
  const opentokError = useSelector((state: IRootState) => state.roomState.error)
  const broadcaster = useSelector((state: IRootState) => state.roomState.broadcaster)
  const roomQueued = useSelector((state: IRootState) => state.roomState.roomQueued)
  const roomsLocked = useSelector((state: IRootState) => state.roomState.roomsLocked)
  const loadingRoomToken = useSelector((state: IRootState) => state.roomState.loadingRoomToken)
  const jitsiError = useSelector((state: IRootState) => state.jitsiState.errorJitsi)
  const zoomError = useSelector((state: IRootState) => state.zoomState.errorZoom)
  const dispatch = useThunkDispatch()

  const refVideosContainer = React.createRef<HTMLDivElement>()

  const handleResize = useCallback(() => {
    if (
      refVideosContainer &&
      refVideosContainer.current &&
      refVideosContainer.current.clientWidth
    ) {
      const currentWidth = refVideosContainer.current?.clientWidth!
      const currentHeight = refVideosContainer.current?.clientHeight!
      const videosLength =
        streams.length +
        (active > -1 && rooms[active] && rooms[active].type === RoomType.Broadcast && !broadcaster
          ? 0
          : 1)
      const countRow = Math.ceil(videosLength / 2)
      setVideoWidth(streams.length === 0 ? currentWidth : currentWidth / 2)
      setVideoHeight(currentHeight / countRow)
    }
  }, [refVideosContainer, streams, rooms, active, broadcaster])

  const length = useMemo(
    () =>
      streams.length +
      (broadcaster || (active > -1 && rooms[active] && rooms[active].type === RoomType.VideoChat)
        ? 1
        : 0),
    [rooms, broadcaster, active, streams]
  )

  useEffect(() => {
    if (mediaStream) {
      mediaStream.getDevices(
        (devices: MediaDevice[]) => {
          if (devices && devices.length > 0) {
            dispatch(updateDefaultSources(asyncLocalStorage, devices))
            setError(undefined)
          } else {
            setError({ name: 'No media devices', message: 'No media devices' })
          }
        },
        (err: MediaError) => {
          setError(err)
        }
      )
    }
  }, [mediaStream, dispatch])

  // auto close room when queued
  useEffect(() => {
    if (!loadingRoomToken && roomQueued && !autoClose) {
      setAutoClose(true)
      magicStream.warning(
        intl.formatMessage({
          id:
            roomsLocked[room.id] || (roomsLocked[room.id] === undefined && room.locked) || false
              ? room.userPresence && user && room.userPresence.user.id !== user.id
                ? 'room.personal'
                : 'room.queued'
              : 'room.fully'
        })
      )
      setTimeout(() => {
        onClickRoom(index)
        setAutoClose(false)
      }, 7500)
    }
  }, [
    index,
    room,
    intl,
    magicStream,
    loadingRoomToken,
    roomQueued,
    roomsLocked,
    autoClose,
    user,
    onClickRoom
  ])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    if (error && error.message) {
      magicStream.error(error.message)
      setError(undefined)
    }
  }, [error, magicStream])

  useEffect(() => {
    if (opentokError) {
      magicStream.warning(opentokError)
      dispatch(setOpentokError())
    }
  }, [opentokError, magicStream, dispatch])

  useEffect(() => {
    if (jitsiError) {
      magicStream.warning(jitsiError)
      dispatch(setJitsiError())
    }
  }, [jitsiError, magicStream, dispatch])

  useEffect(() => {
    if (zoomError) {
      magicStream.warning(zoomError)
      dispatch(setZoomError())
    }
  }, [zoomError, magicStream, dispatch])

  return (
    <StyledVideoRoom>
      <StyledVideosContainer
        ref={refVideosContainer}
        length={length}
        withBackgroundColor={room.type !== RoomType.PslEvent}
      >
        {/* Loading room */}
        {loadingRoomToken && <Loading position="center" size="3em" color="inherit" />}
        {/* Room message */}
        {!loadingRoomToken && roomQueued && (
          <StyledMessage>
            <FormattedMessage
              id={
                roomsLocked[room.id] || (roomsLocked[room.id] === undefined && room.locked) || false
                  ? room.userPresence && user && room.userPresence.user.id !== user.id
                    ? 'room.personal'
                    : 'room.queued'
                  : 'room.fully'
              }
            />
          </StyledMessage>
        )}
        {/* Opentok - videochat */}
        {room.type === RoomType.VideoChat && mediaStream && mediaStream.ready && (
          <>
            <Publisher
              publisherRef={mediaPublisher[index]!}
              mediaStream={mediaStream}
              width={videoWidth}
              height={videoHeight}
              handleLeave={() => onClickRoom(index)}
              handleFullscreen={handleFullscreen}
            />
            {streams.map((stream: IStream) => (
              <Subscriber
                key={stream.id}
                stream={stream}
                mediaStream={mediaStream}
                width={videoWidth}
                height={videoHeight}
                hasMicro={hasMicro}
                isChangeDetected={isChangeDetected}
                handleFullscreen={handleFullscreen}
                onConnected={playConnected}
                onDestroyed={playDisconnected}
              />
            ))}
          </>
        )}
        {/* Opentok - broadcast */}
        {room.type === RoomType.Broadcast && mediaStream && mediaStream.ready && (
          <>
            {!!streams.length && <LiveTag />}
            {broadcaster && (
              <>
                <Publisher
                  publisherRef={mediaPublisher[index]!}
                  mediaStream={mediaStream}
                  broadcaster
                  width={videoWidth}
                  height={videoHeight}
                  handleLeave={() => onClickRoom(index)}
                  handleFullscreen={handleFullscreen}
                />
                <>
                  {streams.map((stream: IStream) => (
                    <Subscriber
                      key={stream.id}
                      stream={stream}
                      mediaStream={mediaStream}
                      width={videoWidth}
                      height={videoHeight}
                      hasMicro={hasMicro}
                      isChangeDetected={isChangeDetected}
                      handleFullscreen={handleFullscreen}
                      onConnected={playConnected}
                      onDestroyed={playDisconnected}
                    />
                  ))}
                </>
              </>
            )}
            {!broadcaster && (
              <>
                {!streams.length && (
                  <StyledMessage>
                    <FormattedMessage id="broadcast.not.started" />
                  </StyledMessage>
                )}
                {streams.map((stream: IStream) => (
                  <Subscriber
                    key={stream.id}
                    stream={stream}
                    mediaStream={mediaStream}
                    width={videoWidth}
                    height={videoHeight}
                    hasMicro={hasMicro}
                    isChangeDetected={isChangeDetected}
                    handleFullscreen={handleFullscreen}
                    onConnected={playConnected}
                    onDestroyed={playDisconnected}
                  />
                ))}
                <HiddenPublisher
                  publisherRef={mediaPublisher[index]!}
                  mediaStream={mediaStream}
                  viewer
                />
              </>
            )}
          </>
        )}
        {/* Zoom */}
        {(room.type === RoomType.Zoom ||
          room.type === RoomType.ZoomMeeting ||
          room.type === RoomType.ZoomWebinar) && (
            <ZoomIFrame
              webinar={room.type === RoomType.ZoomWebinar}
              handleFullscreen={handleFullscreen}
              handleLeave={() => onClickRoom(index)}
            />
          )}
        {/* Jitsi videochat */}
        {(room.type === RoomType.JitsiVideoChat || room.type === RoomType.JitsiIdealChat) && (
          <JitsiIFrame
            room={room}
            handleFullscreen={handleFullscreen}
            handleLeave={() => onClickRoom(index)}
          />
        )}
        {/* Jitsi broadcast */}
        {(room.type === RoomType.JitsiBroadcast || room.type === RoomType.JitsiBroadcastIdeal) &&
          (broadcaster ? (
            <JitsiIFrame
              room={room}
              broadcaster
              handleFullscreen={handleFullscreen}
              handleLeave={() => onClickRoom(index)}
            />
          ) : (
            <JitsiIFrame
              room={room}
              viewer
              handleFullscreen={handleFullscreen}
              handleLeave={() => onClickRoom(index)}
            />
          ))}
        {/* Psl_Event */}
        {room.type === RoomType.PslEvent && (
          <EventRoom
            room={room}
            handleLeave={() => onClickRoom(index)}
            handleRenameRoom={(value: string) => handleRenameRoom(room, value)}
          />
        )}
        {/* External */}
        {room.type === RoomType.External && (
          <ExternalRoom
            room={room}
            handleLeave={() => onClickRoom(index)}
          />
        )}
      </StyledVideosContainer>

      {room.type !== RoomType.External && room.type !== RoomType.PslEvent && (
        <VideoRoomPanel
          index={index}
          room={room}
          viewers={viewers}
          isEditable={isEditable}
          panelChildren={panelChildren}
          streams={streams}
          onClickRoom={onClickRoom}
          handleRenameRoom={handleRenameRoom}
        />
      )}
    </StyledVideoRoom>
  )
}

const StyledVideoRoom = styled.div`
  display: flex;
  height: 100%;
  border-radius: ${BORDER_RADIUS_CARD};
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`

const HiddenPublisher = styled(Publisher)`
  position: absolute;
`

const StyledVideosContainer = styled.div<{ length: number; withBackgroundColor: boolean }>`
  flex: 1;
  display: grid;
  position: relative;
  justify-content: center;
  ${(props) => props.withBackgroundColor && `background-color: black;`}
  color: ${WHITE};
  min-height: 200px;
  grid-template-columns: ${(props) => (props.length > 1 ? 'repeat(4, 1fr)' : 'repeat(1, 1fr)')};

  & > div {
    grid-column: ${(props) => (props.length > 1 ? 'span 2' : '1')};
  }

  & > div:nth-last-of-type(1):nth-of-type(odd) {
    grid-column: ${(props) => (props.length > 1 ? '2 / span 2' : '1')};
  }
`

const StyledMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
`

export default VideoRoom
