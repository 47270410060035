import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { WHITE } from '../../../theme/colors'
import { BOX_SHADOW } from '../../../theme/sizes'

interface FormContainerProps {
  error?: Error | undefined
  box?: boolean
  children: ReactNode
}

const FormContainer: React.FC<FormContainerProps> = ({ error, box, children }) => {
  const intl = useIntl()
  const unhandledErrorMessage = intl.formatMessage({ id: 'form.global.error' })

  return (
    <Container box={box}>
      {error ? (
        <div>
          {unhandledErrorMessage} {error!.message}
        </div>
      ) : null}
      {children}
    </Container>
  )
}

// language=SCSS
const Container = styled.div<{ box?: boolean }>`
  & {
    display: flex;
    flex-direction: column;
    background-color: ${WHITE};
    padding: 15px;
    ${(props) => (props.box ? `box-shadow: ${BOX_SHADOW}; margin: 5px;` : '')}
  }
`

export default FormContainer
