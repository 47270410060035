import { FormControl, MenuItem, Select } from '@material-ui/core'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useMagicStream, useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import { loadKeywords, setKeywordsError } from '../../../store/app'
import Loading from '../../Loading'
import { Label } from '../LabelWrapper/LabelWrapper'

interface ISearchKeywords {
  onChangeValue?: (value: string) => void
}

const SearchKeywords: React.FC<ISearchKeywords> = ({ onChangeValue }) => {
  const magicStream = useMagicStream()
  const keywords = useSelector((state: IRootState) => state.appState.keywords)
  const loading = useSelector((state: IRootState) => state.appState.loadingKeywords)
  const error = useSelector((state: IRootState) => state.appState.errorKeywords)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    if (keywords.total === 0) {
      dispatch(loadKeywords())
    }
  }, [keywords, dispatch])

  useEffect(() => {
    if (error) {
      magicStream.error(error)
      dispatch(setKeywordsError(undefined))
    }
  }, [error, magicStream, dispatch])

  if (loading) return <Loading />

  return (
    <>
      {keywords.total > 0 && (
        <FormControl>
          <Label>
            <FormattedMessage id="label.themes" />
          </Label>
          <Select
            name="keyword"
            onChange={(event: any) => {
              const value = event.target.value as string
              onChangeValue && onChangeValue(value)
            }}
            defaultValue="all"
          >
            <MenuItem value="all">
              <FormattedMessage id="all.themes" />
            </MenuItem>
            {keywords.items &&
              keywords.items.map((keyword, index) => (
                <MenuItem key={index} value={keyword.id}>
                  {keyword.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </>
  )
}

export default SearchKeywords
