import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { WHITE } from '../theme/colors'
import { BORDER_RADIUS_CARD, BOX_SHADOW } from '../theme/sizes'

interface ILoadingCardProps {
  cardType: 'visitor' | 'contact' | 'product' | 'exhibitor' | 'conference' | 'live' | 'VideoPlayer'
}

const LoadingCard: React.FC<ILoadingCardProps> = ({ cardType }) => {
  return (
    <>
      {cardType === 'visitor' && (
        <StyledCardVisitorLoading>
          <div className="container-image">
            <StyledImage rounded width={40} />
          </div>
          <div className="container-text">
            <StyledTextLine />
            <StyledTextLine />
            <StyledTextLine />
          </div>
          <div className="container-icon">
            <StyledIcon />
            <StyledIcon />
          </div>
        </StyledCardVisitorLoading>
      )}
      {cardType === 'contact' && (
        <StyledCardContactLoading>
          <div className="container-image">
            <StyledImage rounded width={40} />
          </div>
          <div className="container-text">
            <StyledTextLine />
            <StyledTextLine />
            <StyledTextLine />
          </div>
          <div className="container-icon">
            <StyledIcon />
            <StyledIcon />
          </div>
        </StyledCardContactLoading>
      )}
      {cardType === 'product' && (
        <StyledCardProductLoading>
          <div className="container-image">
            <StyledImage rounded={false} width={100} />
          </div>
          <div className="container-text">
            <StyledTextLine />
            <StyledTextLine />
            <StyledTextLine />
          </div>
          <div className="container-icon">
            <StyledIcon />
            <StyledIcon />
          </div>
        </StyledCardProductLoading>
      )}
      {cardType === 'exhibitor' && (
        <StyledCardExhibitorLoading>
          <div className="container-image">
            <StyledImage rounded={false} width={142} />
          </div>
          <div className="container-top">
            <StyledTextLine className="text" />
            <StyledIcon />
          </div>
          <div className="container-text">
            <StyledTextLine />
            <StyledTextLine />
            <StyledTextLine />
          </div>
          <div className="container-bottom">
            <StyledTextLine className="text" />
            <StyledIcon />
          </div>
        </StyledCardExhibitorLoading>
      )}
      {cardType === 'conference' && (
        <StyledCardConferenceLoading>
          <div className="container-image">
            <StyledImage rounded={false} width={142} />
          </div>
          <div className="container-top">
            <StyledTextLine className="text" />
            <StyledIcon />
          </div>
          <div className="container-text">
            <StyledTextLine />
            <StyledTextLine />
            <StyledTextLine />
          </div>
          <div className="container-bottom">
            <StyledTextLine className="text" />
            <StyledIcon />
          </div>
        </StyledCardConferenceLoading>
      )}
      {cardType === 'live' && (
        <StyledCardLiveLoading>
          <div className="container-visitors">
            <StyledTextLine />
          </div>
          <div className="container-icon">
            <StyledIcon />
          </div>
          <div className="container-text">
            <StyledTextLine />
            <StyledTextLine />
          </div>
        </StyledCardLiveLoading>
      )}
      {cardType === 'VideoPlayer' && (
        <StyledCardVideoPlayer>
          <div className="container-top-text">
            <StyledTextLine />
          </div>
          <div className="container-middle-icon">
            <StyledIcon />
          </div>
          <div className="container-bottom-bar">
            <div className="container-icon">
              <StyledIcon />
            </div>
            <div className="container-text">
              <StyledTextLine />
            </div>
          </div>
        </StyledCardVideoPlayer>
      )}
    </>
  )
}

const placeHolderShimmer = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
`

const CssAnimatedBackground = css`
  & {
    position: relative;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f6f6;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 800px 104px;
  }
`

const StyledCardLoading = css`
  & {
    background-color: ${WHITE};
    margin-bottom: 12px;
  }
`

const StyledTextLine = styled.div`
  & {
    height: 18px;
    width: calc(100% - 30px);
    margin-bottom: 10px;
    ${CssAnimatedBackground}
  }
`

const StyledImage = styled.div<{ width?: number; rounded?: boolean }>`
  & {
    height: ${(props) => props.width}px;
    width: ${(props) => props.width}px;
    ${(props) => (props.rounded ? `border-radius: 50%;` : '')};
    ${CssAnimatedBackground}
  }
`

const StyledIcon = styled.div`
  & {
    height: 30px;
    width: 30px;
    ${CssAnimatedBackground}
  }
`

const StyledCardVisitorLoading = styled.div`
  & {
    display: flex;
    border-radius: ${BORDER_RADIUS_CARD};
    padding: 12px;
    box-shadow: ${BOX_SHADOW};

    .container {
      &-image {
        flex: 0 0 60px;
        width: 60px;
      }
      &-text {
        flex: 0 0 calc(100% - 150px);
        width: calc(100% - 150px);
      }
      &-icon {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex: 0 0 90px;
        width: 90px;
      }
    }
    ${StyledCardLoading}
  }
`

const StyledCardContactLoading = styled.div`
  & {
    display: flex;
    padding: 6px 12px;
    margin-bottom: 0 !important;

    .container {
      &-image {
        flex: 0 0 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-text {
        flex: 0 0 calc(100% - 86px);
        width: calc(100% - 86px);
      }
      &-icon {
        flex: 0 0 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    ${StyledCardLoading}
  }
`

const StyledCardProductLoading = styled.div`
  & {
    display: flex;
    border-radius: ${BORDER_RADIUS_CARD};
    padding: 12px;
    box-shadow: ${BOX_SHADOW};

    .container {
      &-image {
        flex: 0 0 130px;
        width: 130px;
      }
      &-text {
        flex: 0 0 calc(100% - 220px);
        width: calc(100% - 220px);
      }
      &-icon {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex: 0 0 90px;
        width: 90px;
      }
    }
    ${StyledCardLoading}
  }
`

const StyledCardExhibitorLoading = styled.div`
  & {
    display: flex;
    flex-direction: column;
    border-radius: ${BORDER_RADIUS_CARD};
    box-shadow: ${BOX_SHADOW};
    margin: 8px;

    .container {
      &-image {
        > div {
          width: 100% !important;
        }
      }
      &-top {
        display: flex;
        justify-content: space-between;
        padding: 12px;

        .text {
          flex: 0 0 60%;
          width: 60%;
        }
      }
      &-text {
        padding: 12px;
      }
      &-bottom {
        display: flex;
        justify-content: space-between;
        padding: 12px;

        .text {
          flex: 0 0 60%;
          width: 60%;
        }
      }
    }
    ${StyledCardLoading}
  }
`

const StyledCardConferenceLoading = styled.div`
  & {
    display: flex;
    flex-direction: column;
    border-radius: ${BORDER_RADIUS_CARD};
    box-shadow: ${BOX_SHADOW};
    margin: 8px;

    .container {
      &-image {
        > div {
          width: 100% !important;
        }
      }
      &-top {
        display: flex;
        justify-content: space-between;
        padding: 12px;

        .text {
          flex: 0 0 60%;
          width: 60%;
        }
      }
      &-text {
        padding: 12px;
      }
      &-bottom {
        display: flex;
        justify-content: space-between;
        padding: 12px;

        .text {
          flex: 0 0 60%;
          width: 60%;
        }
      }
    }
    ${StyledCardLoading}
  }
`

const StyledCardLiveLoading = styled.div`
  & {
    position: relative;
    border-radius: ${BORDER_RADIUS_CARD};
    box-shadow: ${BOX_SHADOW};
    height: 300px;

    .container {
      &-icon {
        > div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          width: 80px;
          height: 80px;
        }
      }
      &-text {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 12px;
        width: 100%;

        > div {
          width: 40%;

          + div {
            width: 60%;
          }
        }
      }
      &-visitors {
        position: absolute;
        top: 5px;
        right: 5px;

        > div {
          width: 100px;
        }
      }
    }
    ${StyledCardLoading}
  }
`

const StyledCardVideoPlayer = styled.div`
  & {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #fff;
    border-radius: ${BORDER_RADIUS_CARD};
    box-shadow: ${BOX_SHADOW};
    height: 420px;
    max-height: 100%;

    .container {
      &-top-text {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        width: 100%;

        > div {
          height: 50px;
        }
      }
      &-middle-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        > div {
          height: 50px;
          width: 50px;
        }
      }
      &-bottom-bar {
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 10px;
        display: flex;
        justify-content: space-between;
      }
      &-icon {
        flex: 0 0 60px;
        width: 60px;

        > div {
          height: 50px;
          width: 50px;
        }
      }
      &-text {
        flex: 0 0 calc(100% - 80px);
        width: calc(100% - 80px);

        > div {
          height: 50px;
        }
      }
    }
    ${StyledCardLoading}
  }
`

export default LoadingCard
