import styled from '@emotion/styled'
import { Grid, LinearProgress } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IConferenceExhibitors, IRelatedEvent } from '../../entities'
import { browserHistory, IRootState } from '../../redux'
import ExhibitorSmallCard from '../cards/ExhibitorSmallCard'
import RelatedSmallCard from '../cards/RelatedSmallCard'
import Loading from '../Loading'
import ViewDialog from '../modals/ViewDialog'

const secondsInitial = 30

interface IConferenceListBusinessConferencesProps {
  open: boolean
  autoRedirect: boolean
  onHandle: () => void
}

const ConferenceListBusinessConferences: React.FC<IConferenceListBusinessConferencesProps> = ({
  open,
  autoRedirect,
  onHandle
}) => {
  const intl = useIntl()
  const [seconds, setSeconds] = useState(secondsInitial)
  const currentConference = useSelector((state: IRootState) => state.conferenceState.conference)
  const loading = useSelector((state: IRootState) => state.conferenceState.loadingEmbeded)
  const conferenceRelatedContent = useSelector(
    (state: IRootState) => state.conferenceState.relatedContent
  )

  const handleOk = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    onHandle()
  }

  const memoContents = useMemo(() => {
    const relatedContent: (IConferenceExhibitors | IRelatedEvent)[] = []
    const exhibitorKey = 'businessEventExhibitor'

    return (
      currentConference &&
      conferenceRelatedContent &&
      (currentConference.business_event_exhibitors &&
      currentConference.business_event_exhibitors.length > 0
        ? currentConference.business_event_exhibitors
        : conferenceRelatedContent
        ? relatedContent
            .concat(conferenceRelatedContent.items)
            .concat(conferenceRelatedContent.exhibitors)
        : []
      )
        .sort(
          (a: IConferenceExhibitors | IRelatedEvent, b: IConferenceExhibitors | IRelatedEvent) => {
            // IConferenceExhibitors first
            if (
              !(a as IConferenceExhibitors)[exhibitorKey] &&
              (b as IConferenceExhibitors)[exhibitorKey]
            ) {
              return -1
            } else if (
              (a as IConferenceExhibitors)[exhibitorKey] &&
              !(b as IConferenceExhibitors)[exhibitorKey]
            ) {
              return 1
            }
            // then sort by position
            const key = 'position'
            if (a[key] < b[key]) {
              return -1
            } else if (a[key] > b[key]) {
              return 1
            }
            return 0
          }
        )
        .map((item: IConferenceExhibitors | IRelatedEvent) => {
          return {
            ...item,
            isExhibitor: !!(item as IConferenceExhibitors)[exhibitorKey]
          }
        })
    )
  }, [currentConference, conferenceRelatedContent])

  const hasAutoRedirect = useMemo(
    () => memoContents && memoContents.filter((content) => content.is_auto_redirected).length > 0,
    [memoContents]
  )

  useEffect(() => {
    let timer = 0
    if (autoRedirect && open && memoContents?.length) {
      if (seconds > 0) {
        timer = window.setTimeout(() => setSeconds(seconds - 1), 1000)
      } else if (seconds === 0) {
        setSeconds(-1)
      } else if (seconds < 0) {
        memoContents.map((content) => {
          if (content.is_auto_redirected) {
            onHandle()
            if (content.isExhibitor) {
              browserHistory.push({
                pathname: `/exposant/${content.businessEventExhibitor.id}`,
                state: { goBack: false }
              })
            } else {
              browserHistory.replace((content as any).url)
            }
            return false
          }
          return true
        })
      }
    } else if (memoContents) {
      setSeconds(secondsInitial)
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [autoRedirect, open, seconds, memoContents, onHandle])

  return (
    <StyledViewDialog
      title={intl.formatMessage({ id: 'conference.list.businessConference.title' })}
      open={open}
      maxWidth="md"
      fullWidth
      hasCloseButton
      disableButtonOk
      closeEvent={handleOk}
      okEvent={handleOk}
    >
      {!loading && hasAutoRedirect && (
        <Grid container spacing={2} style={{ paddingBottom: `20px` }}>
          <Grid item md={3}>
            &nbsp;
          </Grid>
          <Grid item xs={12} md={6}>
            {seconds > 0 && seconds < 30 && (
              <StyledCounterDown>
                <p>
                  <FormattedMessage
                    id="conference.counterDown"
                    values={{ seconds: `${seconds}` }}
                  />
                </p>
                <LinearProgress
                  variant="determinate"
                  value={((secondsInitial - seconds) / secondsInitial) * 100}
                />
              </StyledCounterDown>
            )}
          </Grid>
        </Grid>
      )}
      {!loading && memoContents && (
        <Grid container justifyContent="center" spacing={2}>
          {memoContents.map((content, index) => (
            <Grid key={index} item xs={12} md={6} lg={4}>
              {content.isExhibitor ? (
                <ExhibitorSmallCard exhibitor={content as IConferenceExhibitors} />
              ) : (
                <RelatedSmallCard relatedEvent={content as IRelatedEvent} onClick={onHandle} />
              )}
            </Grid>
          ))}
        </Grid>
      )}
      {loading && <Loading />}
    </StyledViewDialog>
  )
}

const StyledViewDialog = styled(ViewDialog)`
  & {
    .MuiDialogTitle-root {
      text-align: center;
    }
  }
`

const StyledCounterDown = styled.div`
  & {
    margin-top: 15px;
    font-weight: bold;
    text-align: center;
  }
`

export default ConferenceListBusinessConferences
