import React from 'react'

const SvgArrowRight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M34.301 25.425a2.414 2.414 0 0 0-.66-1.603L19.16 8.737a2.42 2.42 0 0 0-3.489 3.356L28.55 25.5 15.67 38.907a2.42 2.42 0 0 0 3.489 3.356L33.64 27.178a2.414 2.414 0 0 0 .66-1.753z"
    />
  </svg>
)

export default SvgArrowRight
