import { AnyAction, Dispatch } from 'redux'
import {
  addProduct as addProductApi,
  deleteProduct as deleteProductApi,
  getFiltersProduct as getApiFiltersProduct,
  getProduct as getApiProduct,
  getProducts as getApiProducts,
  updateProduct as updateProductApi
} from '../../api'
import { IProductAdd } from '../../entities'
import { IKeywordProductDisplay } from '../../entities/keyword'
import consoleUtils from '../../utils/consoleUtils'
import {
  addProduct as addProductExhibitor,
  removeProduct as removeProductExhibitor,
  updateProduct
} from '../exhibitor'
import {
  deleteProduct,
  mergeProduct,
  setErrorProductFilters,
  setErrorProductKeywords,
  setLoadingProductFilters,
  setLoadingProductKeywords,
  setProduct,
  setProductAdding,
  setProductDeleting,
  setProductError,
  setProductFilters,
  setProductKeywords,
  setProductLoading,
  setProducts,
  setProductsSearch,
  setProductUpdating
} from './actions'
import { IProductFilters } from './model'

export const getProducts = (filters: IProductFilters) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setProductLoading(true))
  if (filters.location) {
    dispatch(setProducts({ total: 0, items: [] }))
    dispatch(setProductsSearch(filters.location))
  }
  try {
    // get product data
    const products = await getApiProducts(
      filters.page,
      filters.search,
      filters.productCategoryId,
      filters.exhibitorId
    )
    dispatch(setProducts(products))
  } catch (e) {
    dispatch(setProductError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setProductLoading(false))
}

export const getProduct = (productId: string, userId: number) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setProductLoading(true))
  try {
    // get product data
    const product = await getApiProduct(productId)
    dispatch(setProduct(product))
  } catch (e) {
    dispatch(setProductError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setProductLoading(false))
}

export const removeProduct = (
  productId: number,
  success?: () => void,
  error?: (error: Error) => void
) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setProductDeleting(productId, true))
  try {
    // delete product
    await deleteProductApi(productId)
    dispatch(deleteProduct(productId))
    dispatch(removeProductExhibitor(productId))
  } catch (e) {
    consoleUtils.error(e)
    error && error(e)
  }
  success && success()
  dispatch(setProductDeleting(productId, false))
}

export const addProduct = (
  product: IProductAdd,
  success?: () => void,
  error?: (error: Error) => void
) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setProductAdding(true))
  try {
    // add product
    const newProduct = await addProductApi(product)
    dispatch(mergeProduct(newProduct))
    dispatch(addProductExhibitor(newProduct))
  } catch (e) {
    consoleUtils.error(e)
    error && error(e)
  }
  success && success()
  dispatch(setProductAdding(false))
}

export const editProduct = (
  product: IProductAdd,
  success?: () => void,
  error?: (error: Error) => void
) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setProductUpdating(true))
  try {
    // edit product data
    const newProduct = await updateProductApi(product)
    // refresh product
    dispatch(updateProduct(newProduct))
    success && success()
  } catch (e) {
    consoleUtils.error(e)
    error && error(e)
  }
  dispatch(setProductUpdating(false))
}

export const loadFiltersProducts = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoadingProductFilters(true))
  try {
    // load filters for product
    const filtersAPIResult = await getApiFiltersProduct()
    dispatch(setProductFilters(filtersAPIResult))
  } catch (e) {
    dispatch(setErrorProductFilters((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingProductFilters(false))
}

export const loadProductKeywords = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setLoadingProductKeywords(true))
  try {
    const filtersAPIResult = await getApiFiltersProduct()
    if (filtersAPIResult.categories.length > 0) {
      dispatch(setProductKeywords(filtersAPIResult.categories as IKeywordProductDisplay[]))
    }
  } catch (e) {
    dispatch(setErrorProductKeywords((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setLoadingProductKeywords(false))
}
