import { useState } from 'react'
import settings from '../settings'

export const usePagination = (lastPage?: number) => {
  const [count, setCount] = useState(parseInt(`${settings.list.itemsPerPage}`, 10))
  const [page, setPage] = useState(lastPage || 1)

  return {
    count,
    page,
    setCount,
    setPage
  }
}
