import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface ConfirmDialogProps extends DialogProps {
  message: string
  disableOk?: boolean
  cancelEvent?: () => void
  okEvent: () => void
}
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  message,
  disableOk,
  cancelEvent,
  okEvent,
  children,
  ...props
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const handleCancel = () => {
    if(cancelEvent){
      cancelEvent()
    }
  }

  const handleOk = () => {
    okEvent()
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="dialog.confirm" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        {children && <DialogChildren>{children}</DialogChildren>}
      </DialogContent>
      <DialogActions>
        {cancelEvent && (
          <Button onClick={handleCancel} color="primary">
            <FormattedMessage id="dialog.cancel" />
          </Button>
        )}
        <Button
          onClick={handleOk}
          disabled={disableOk}
          color="primary"
          variant="contained"
          autoFocus
        >
          <FormattedMessage id="dialog.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DialogChildren = styled.div`
  width: 100%;
`

export default ConfirmDialog
