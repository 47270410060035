import { FormControl, MenuItem, Select } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRootState } from '../../redux'
import settings from '../../settings'
import InputText from '../ui/InputText/InputText'
import { Label } from '../ui/LabelWrapper/LabelWrapper'

interface IContactAppointmentPlaceProps {
  setConferenceId: (value: number | undefined) => void
  setExhibitorId: (value: number | undefined) => void
  setRoomId: (value: number | undefined) => void
  customMessage?: string
  setCustomMessage: (value: string | undefined) => void
  isOnsite?: boolean
}

const ContactAppointmentPlace: React.FC<IContactAppointmentPlaceProps> = ({
  setConferenceId,
  setExhibitorId,
  setRoomId,
  customMessage,
  setCustomMessage,
  isOnsite
}) => {
  const intl = useIntl()
  const [selected, setSelected] = useState('')
  const [other, setOther] = useState(false)
  const agendaSlots = useSelector((state: IRootState) => state.appState.agendaSlots)
  const meetingLocation = settings.meetingLocation

  useEffect(() => {
    if (meetingLocation && isOnsite) setCustomMessage(meetingLocation)
  }, [meetingLocation, isOnsite, setCustomMessage])
  if (!agendaSlots) return <></>

  return (
    <>
      {agendaSlots.choices && Object.keys(agendaSlots.choices).length && (
        <FormControl>
          <Label id="label-select-room">
            <FormattedMessage id="rdv.room" />
          </Label>
          {isOnsite && meetingLocation ? (
            <p>{meetingLocation}</p>
          ) : (
            <Select
              labelId="label-select-room"
              id="select-room"
              value={selected}
              onChange={(
                event: React.ChangeEvent<{
                  name?: string | undefined
                  value: unknown
                }>
              ) => {
                const newSelected = event.target.value as string
                setSelected(newSelected)
                const choice = agendaSlots.choices[newSelected]
                if (choice.event_id) {
                  //  exhibitor
                  setConferenceId(choice.event_id)
                  setOther(false)
                } else if (choice.exhibitor_id && choice.room_id) {
                  // room
                  setExhibitorId(choice.exhibitor_id)
                  setRoomId(choice.room_id)
                  setOther(false)
                } else if (choice.exhibitor_id) {
                  //  exhibitor
                  setExhibitorId(choice.exhibitor_id)
                  setOther(false)
                } else {
                  setOther(true)
                }
              }}
            >
              {Object.keys(agendaSlots.choices).map((title, index) => (
                <MenuItem key={index} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      )}

      {other && (
        <InputText
          label={intl.formatMessage({
            id: 'custom.appointment.place'
          })}
          placeholder={intl.formatMessage({
            id: 'custom.appointment.placeholder'
          })}
          value={customMessage}
          onChangeValue={setCustomMessage}
        />
      )}
    </>
  )
}

export default ContactAppointmentPlace
