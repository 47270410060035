import React from 'react'

const SvgPin: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M11.525 23.663h.978A24.936 24.936 0 0 1 14.84 36.65a5.965 5.965 0 0 0 5.27 6.483c.42.045.841.068 1.262.068 3.638 0 8.136-1.515 12.576-4.234l7.03 10.063a2.435 2.435 0 0 0 3.99-2.787l-7.05-10.102c4.567-3.618 7.745-7.823 8.674-11.558a5.965 5.965 0 0 0-4.273-7.178 24.936 24.936 0 0 1-11.393-6.67c1.408-3.011 1.35-5.788-.107-7.88-3.002-4.294-10.796-3.687-18.12 1.437a23.47 23.47 0 0 0-7.295 7.823c-1.77 3.324-1.868 6.415-.284 8.683 1.34 1.927 3.628 2.865 6.405 2.865zm29.61-1.554c.567.148.913.72.783 1.29-.753 3.042-4.166 7.041-8.694 10.2-4.527 3.158-9.505 4.997-12.624 4.664a1.085 1.085 0 0 1-.978-1.183 29.62 29.62 0 0 0-2.337-14.56 26.403 26.403 0 0 0 5.867-3.14 26.403 26.403 0 0 0 4.978-4.478 29.62 29.62 0 0 0 13.005 7.207zM9.697 14.393a18.863 18.863 0 0 1 5.789-6.131 16.956 16.956 0 0 1 9.025-3.393 2.855 2.855 0 0 1 2.347.772c.47.665.235 2.054-.596 3.608a18.853 18.853 0 0 1-5.809 6.122c-5.33 3.716-10.356 4.048-11.343 2.63-.47-.674-.245-2.034.587-3.608z"
    />
  </svg>
)

export default SvgPin
