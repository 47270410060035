import { AnyAction, Dispatch } from 'redux'
import { getHighlight as getApiHighlight } from '../../api'
import consoleUtils from '../../utils/consoleUtils'
import { setHighlight, setHighlightError, setHighlightLoaded, setHighlightLoading } from './actions'

export const getHighlight = (type: string) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setHighlightLoading(true))
  try {
    // get highlight data
    const highlight = await getApiHighlight(type)
    dispatch(setHighlight(highlight))
    dispatch(setHighlightLoaded(true))
  } catch (e) {
    dispatch(setHighlightError((e as any).message))
    consoleUtils.error(e)
  }
  dispatch(setHighlightLoading(false))
}
