import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IContact, IContactUserStatus } from '../../entities'
import { IRootState } from '../../redux'
import settings from '../../settings'
import ActionAddContact from './actions/ActionAddContact'
import ActionInvitToJoin from './actions/ActionInvitToJoin'
import ActionJoin from './actions/ActionJoin'
import ActionRemoveContact from './actions/ActionRemoveContact'
import ActionSendMessage from './actions/ActionSendMessage'
import ActionTakeAppointment from './actions/ActionTakeAppointment'

type IProps = {
  contact: IContact
  status?: IContactUserStatus | null
  disabledSendMessage?: boolean | null
  isOnline?: boolean | undefined
  isOffice?: boolean | undefined
  onClose?: (event: React.MouseEvent<EventTarget>) => void
  onEnterOffice?: () => void
}

const ContactPopperActions: React.FC<IProps> = ({
  contact,
  status,
  disabledSendMessage,
  isOnline,
  isOffice,
  onClose,
  onEnterOffice
}) => {
  const user = useSelector((state: IRootState) => state.appState.user)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)

  const memoMe = useMemo(() => user && user.id === contact.id, [user, contact])

  if (memoMe) return <></>

  return (
    <>
      {/* // invitation to join */}
      {!eventCo?.eventcoAppointment && (
        <ActionInvitToJoin
          contact={contact}
          status={status}
          isOnline={isOnline}
          isOffice={isOffice}
          onClose={onClose}
        />
      )}
      {/* // join */}
      {!eventCo?.eventcoAppointment && (
        <ActionJoin
          contact={contact}
          status={status}
          isOnline={isOnline}
          isOffice={isOffice}
          onClose={onClose}
          onEnterOffice={onEnterOffice}
        />
      )}
      {/* // send message */}
      {!settings.disableFeatureChat && (
        <ActionSendMessage
          contact={contact}
          disabledSendMessage={disabledSendMessage}
          onClose={onClose}
        />
      )}
      {!settings.disableFeatureContacts && (
        <>
          {/* // add as favorite contact */}
          <ActionAddContact contact={contact} onClose={onClose} />
          {/* // remove favorite */}
          <ActionRemoveContact contact={contact} onClose={onClose} />
        </>
      )}
      {/* // take appointment */}
      <ActionTakeAppointment contact={contact} onClose={onClose} />
    </>
  )
}

export default ContactPopperActions
