import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Loading from '../Loading'
import Transition from '../Transition'

interface SendDialogProps extends DialogProps {
  sending?: boolean
  disableSend?: boolean
  cancelEvent: () => void
  sendEvent: () => void
}

const SendDialog: React.FC<SendDialogProps> = ({
  open,
  title,
  children,
  sending,
  disableSend,
  cancelEvent,
  sendEvent,
  ...props
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    cancelEvent && cancelEvent()
  }

  const handleSend = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    sendEvent && sendEvent()
  }

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      {title && (
        <DialogTitle id="alert-dialog-title">
          <span
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          <FormattedMessage id="dialog.cancel" />
        </Button>
        <Button
          disabled={disableSend}
          onClick={handleSend}
          color="primary"
          variant="contained"
          autoFocus
        >
          {sending ? <Loading color="inherit" /> : <FormattedMessage id="dialog.send" />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendDialog
