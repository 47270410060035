import styled from '@emotion/styled'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import { useIntl } from 'react-intl'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'

export interface IEditableProps {
  show: boolean
  onClick: () => void
}

const Editable: React.FC<IEditableProps> = ({ show, onClick }) => {
  const intl = useIntl()

  return show ? (
    <StyledContentEditableMessage
      title={intl.formatMessage({ id: 'action.edit.body' })}
      onClick={onClick}
    >
      <div>
        <EditIcon titleAccess={intl.formatMessage({ id: 'titleAccess.Editor.EditIcon' })} />
      </div>
    </StyledContentEditableMessage>
  ) : (
    <></>
  )
}

const StyledContentEditableMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  cursor: pointer;

  & > div {
    border-radius: ${BORDER_RADIUS_CARD};
    padding: 10px;
    text-align: center;
  }
`

export default Editable
