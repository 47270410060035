import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Avatar, Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IDocument, IExhibitorFull } from '../../entities'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { deleteExhibitorDocument } from '../../store/exhibitor/thunks'
interface IExhibitorDocumentsListProps {
  columns?: number
  summary?: boolean
  showDelete?: boolean
}

interface ListProps {
  documents: IDocument[]
  width: number
  showDelete?: boolean
  exhibitor: IExhibitorFull
}

const download = (file: IDocument) => {
  const link = document.createElement('a')
  link.download = file.title!
  link.href = file.url!
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const SimpleList: React.FC<ListProps> = ({ documents, width, showDelete, exhibitor }) => {
  const dispatch = useThunkDispatch()
  const { sendDataToGTM } = useGTM()

  const handleDownload = (document: IDocument) => {
    sendDataToGTM({ event: 'download-document', documentTitle: document.title })
    download(document)
  }

  const handleDelete = (document: IDocument, exhibitorId: number) => {
    dispatch(deleteExhibitorDocument(document, exhibitorId))
  }

  return (
    <StyledListDiv itemWidth={width.toString()}>
      <List dense>
        {documents.map((document) => (
          <ListItem key={document.id} onClick={() => handleDownload(document)} button>
            <ListItemAvatar>
              <Avatar>
                <InsertDriveFileIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={document.title} />
            {showDelete && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(document, exhibitor?.id!)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    </StyledListDiv>
  )
}

const AccordionList: React.FC<ListProps> = ({ documents, width, showDelete, exhibitor }) => {
  const [expanded, setExpanded] = useState(false)
  const summary = documents.slice(0, 5)
  const rest = documents.slice(5)

  const handleExpand = () => {
    if (rest.length > 0) {
      setExpanded(!expanded)
    }
  }

  return (
    <>
      <Accordion expanded={expanded} elevation={0}>
        <AccordionSummary
          expandIcon={rest.length > 0 ? <ExpandMoreIcon /> : undefined}
          aria-controls="panel1c-content"
          id="panel1c-header"
          onClick={handleExpand}
        >
          <Typography variant="h6">
            <FormattedMessage id="exhibitor.documentList" />
          </Typography>
        </AccordionSummary>
        <StyledAccordionDetails>
          <SimpleList
            showDelete={showDelete}
            width={width}
            exhibitor={exhibitor}
            documents={documents}
          />
        </StyledAccordionDetails>
      </Accordion>
      {(!expanded || rest.length === 0) && (
        <SimpleList
          showDelete={showDelete}
          width={width}
          exhibitor={exhibitor}
          documents={summary}
        />
      )}
    </>
  )
}

const ExhibitorDocumentsList: React.FC<IExhibitorDocumentsListProps> = ({
  columns,
  summary,
  showDelete
}) => {
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const documents = exhibitor?.documents
  const width = 100 / (columns || 1)

  const ListComponent = summary ? AccordionList : SimpleList

  return (
    <StyledDiv>
      <Grid item xs={12}>
        {exhibitor && documents && documents.length > 0 && (
          <ListComponent
            showDelete={showDelete}
            width={width}
            exhibitor={exhibitor}
            documents={documents}
          />
        )}
      </Grid>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  & {
    margin-bottom: 10px;
  }
`

const StyledListDiv = styled.div<{ itemWidth: string }>`
  & ul {
    max-height: 100%;
  }
  li {
    float: left;
    width: ${({ itemWidth }) => itemWidth}%;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  & {
    padding: 0;
  }
`

export default ExhibitorDocumentsList
