import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { breakpoints } from '../../components/breakpoints'
import ExhibitorCard from '../../components/cards/ExhibitorCard'
import ExhibitorFiltersSearch from '../../components/exhibitor/ExhibitorFiltersSearch'
import ExhibitorFiltersTri from '../../components/exhibitor/ExhibitorFiltersTri'
import AppPagination from '../../components/globals/AppPagination/AppPagination'
import Highlight from '../../components/globals/Highlight/Highlight'
import LoadingCard from '../../components/LoadingCard'
import Slider from '../../components/ui/Slider/Slider'
import Title from '../../components/ui/Title/Title'
import { useEffectOnce, useMagicStream, usePagination, useThunkDispatch } from '../../hooks'
import useIsProfilingEnabled from '../../hooks/useIsProfilingEnabled'
import { browserHistory, IRootState } from '../../redux'
import settings from '../../settings'
import {
  getExhibitors,
  loadRecommendedExhibitors,
  setExhibitorsError,
  setExhibitorsSearch
} from '../../store/exhibitor'
import { setPageReferral } from '../../store/gtm'
import { convertQueryParamToObject } from '../../utils'

const ExhibitorListPage = () => {
  const intl = useIntl()
  const location = useLocation()
  const magicStream = useMagicStream()
  const { sendDataToGTM } = useGTM()
  const { count, page, setPage } = usePagination(
    parseInt(new URLSearchParams(location.search).get('page') || '1', 10)
  )
  const [filterSearch, setFilterSearch] = useState(
    new URLSearchParams(location.search).get('search') || ''
  )
  const [filterKeyword, setFilterKeyword] = useState(
    new URLSearchParams(location.search).get('keyword') || ''
  )
  const [sortBy, setSortBy] = useState(
    new URLSearchParams(location.search).get('sortBy') || 'alphabetic'
  )
  const [displayOrder, setDisplayOrder] = useState(filterSearch.length <= 0)
  const [filterCustom, setFilterCustom] = useState<{
    [key: string]: number | string | { id: number; name: string }
  }>({})
  const [filterIsFavorite, setFilterIsFavorite] = useState(
    new URLSearchParams(location.search).get('favorite') === 'true' || false
  )
  const recommendedExhibitors = useSelector(
    (state: IRootState) => state.exhibitorState.recommendedExhibitors
  )
  const exhibitors = useSelector((state: IRootState) => state.exhibitorState.exhibitors)
  const searchExhibitors = useSelector((state: IRootState) => state.exhibitorState.searchExhibitors)
  const loadingExhibitors = useSelector(
    (state: IRootState) => state.exhibitorState.loadingExhibitors
  )
  const loadingRecommended = useSelector(
    (state: IRootState) => state.exhibitorState.loadingRecommended
  )
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const errorExhibitors = useSelector((state: IRootState) => state.exhibitorState.errorExhibitors)
  const [filterKeywordsX, setFilterKeywordsX] = useState<{ [key: string]: boolean }>(
    convertQueryParamToObject(new URLSearchParams(location.search).get('keywordsX')) || {}
  )
  const isProfilingEnabled = useIsProfilingEnabled()

  const dispatch = useThunkDispatch()

  useEffect(() => {
    const search = `?page=${page}&search=${filterSearch}&keyword=${filterKeyword}&sortBy=${sortBy}&favorite=${filterIsFavorite}${
      filterKeywordsX
        ? `&keywordsX=${Object.keys(filterKeywordsX)
            .filter((id) => filterKeywordsX[id])
            .join(',')}`
        : ''
    }`
    // refresh url only if search as changed and is different of location
    if (
      searchExhibitors !== search &&
      ((location.search.indexOf('magicstream=true') > -1 &&
        !location.search.endsWith(`${search}&magicstream=true`)) ||
        (location.search.indexOf('magicstream=true') === -1 && !location.search.endsWith(search)))
    ) {
      setDisplayOrder(filterSearch.length <= 0)
      browserHistory.replace(
        `/exposant${search}${
          location.search.indexOf('magicstream=true') > -1 ? '&magicstream=true' : ''
        }${location.search.indexOf('appointment=true') > -1 ? '&appointment=true' : ''}`
      )
    }
  }, [
    filterSearch,
    filterKeyword,
    filterIsFavorite,
    page,
    sortBy,
    location,
    searchExhibitors,
    filterKeywordsX
  ])

  // read url location to load data
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if ((location.search || searchExhibitors) && searchExhibitors !== location.search) {
        const locationSearch = new URLSearchParams(location.search)
        const currentPage = parseInt(locationSearch.get('page') || '1', 10)
        setPage(currentPage)
        const currentSearch = locationSearch.get('search') || ''
        setFilterSearch(currentSearch)
        const currentKeyword = locationSearch.get('keyword') || ''
        setFilterKeyword(currentKeyword)
        const currentSortBy = locationSearch.get('sortBy') || 'alphabetic'
        setSortBy(currentSortBy)
        const currentFavorite = locationSearch.get('favorite') === 'true'
        setFilterIsFavorite(currentFavorite)
        const currentKeywordsX = locationSearch.get('keywordsX') || ''
        setFilterKeywordsX(convertQueryParamToObject(currentKeywordsX) || {})
        dispatch(
          getExhibitors({
            search: currentSearch,
            keyword: currentKeyword,
            page: currentPage,
            sortBy: currentSortBy,
            location: location.search,
            followed: currentFavorite,
            keywordsX: currentKeywordsX
          })
        )
      }
    }, 1000)

    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [location, searchExhibitors, setPage, dispatch])

  useEffect(() => {
    dispatch(loadRecommendedExhibitors())
  }, [dispatch])

  // clean location
  useEffect(() => {
    return () => {
      dispatch(setExhibitorsSearch(undefined))
    }
  }, [dispatch])

  // gtm DATA
  useEffectOnce(() => {
    const title = intl.formatMessage({ id: 'gtm.listExhibitors.title' })
    dispatch(setPageReferral(title))
    sendDataToGTM({
      event: 'page-view',
      pageTitle: title,
      pageType: intl.formatMessage({ id: 'gtm.listExhibitors.type' }),
      pageReferral
    })
  })

  useEffect(() => {
    if (errorExhibitors) {
      magicStream.error(errorExhibitors)
      dispatch(setExhibitorsError(undefined))
    }
  }, [errorExhibitors, magicStream, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  return (
    <StyledListPage>
      {isProfilingEnabled && (loadingRecommended || recommendedExhibitors.length !== 0) && (
        <>
          <Title variant="h2" titleId="exhibitor.title_recommended" />
          <StyledContainerRecommended>
            {loadingRecommended && recommendedExhibitors.length === 0 && (
              <Slider>
                {Array(9)
                  .fill(0)
                  .map((value, index) => (
                    <div key={index}>
                      <LoadingCard cardType="exhibitor" />
                    </div>
                  ))}
              </Slider>
            )}
            {!loadingRecommended && (
              <Slider>
                {recommendedExhibitors.map((exhibitor, index) => (
                  <div key={index}>
                    <ExhibitorCard exhibitor={exhibitor} />
                  </div>
                ))}
              </Slider>
            )}
            {!loadingRecommended && recommendedExhibitors.length === 0 && (
              <Alert severity="warning">
                <FormattedMessage id="exhibitor.recommended.empty" />
              </Alert>
            )}
          </StyledContainerRecommended>
        </>
      )}
      <Highlight type="exhibitors" />
      <StyledExhibitorHeader>
        <Title
          titleId={filterIsFavorite ? 'exhibitor.title_list.favorite' : 'exhibitor.title_list'}
          variant="h2"
          suffix={exhibitors.total > 0 ? ` (${exhibitors.total})` : ''}
        />
        <ExhibitorFiltersTri sortBy={sortBy} setSortBy={setSortBy} visible={displayOrder} />
      </StyledExhibitorHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4} xl={3}>
          <ExhibitorFiltersSearch
            setPage={setPage}
            setFilterSearch={setFilterSearch}
            filterSearch={filterSearch}
            setFilterCustom={(value: any) => {
              dispatch(setExhibitorsSearch(undefined))
              setFilterCustom(value)
            }}
            filterCustom={filterCustom}
            setFilterKeyword={setFilterKeyword}
            filterKeyword={filterKeyword}
            setFilterIsFavorite={setFilterIsFavorite}
            filterIsFavorite={filterIsFavorite}
            filterKeywordsX={filterKeywordsX}
            setFilterKeywordsX={setFilterKeywordsX}
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <StyledGridContainerList container spacing={1}>
            {loadingExhibitors &&
              exhibitors.total === 0 &&
              Array(9)
                .fill(0)
                .map((value, index) => (
                  <StyledGrid key={index} item xs={12}>
                    <LoadingCard cardType="exhibitor" />
                  </StyledGrid>
                ))}
            {exhibitors.total > 0 &&
              exhibitors.items.map((exhibitor, index) => (
                <StyledGrid key={index} item xs={12}>
                  <ExhibitorCard key={index} exhibitor={exhibitor} goBackList />
                </StyledGrid>
              ))}
            {!loadingExhibitors && exhibitors.total === 0 && (
              <StyledGridEmptyList item xs={12}>
                <Alert severity="warning">
                  <FormattedMessage id="exhibitor.list.empty" />
                </Alert>
              </StyledGridEmptyList>
            )}
          </StyledGridContainerList>
          {exhibitors.total > 0 && (
            <AppPagination total={exhibitors.total} count={count} page={page} setPage={setPage} />
          )}
        </Grid>
      </Grid>
      <Helmet>
        <title>
          {`${intl.formatMessage({ id: 'gtm.listExhibitors.title' })} - ${
            settings.theme.header.title
          }`}
        </title>
      </Helmet>
    </StyledListPage>
  )
}

const StyledListPage = styled.div`
  & {
    max-width: 1280px;
    margin: 0 auto;
  }
`

const StyledContainerRecommended = styled.div`
  & {
    margin-bottom: 1em;
  }
`

const StyledGrid = styled(Grid)`
  & {
    @media (min-width: 640px) {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }
    @media (min-width: ${breakpoints.lg}) {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
    @media (min-width: 1180px) {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }
    @media (min-width: 1400px) {
      flex-grow: 0;
      max-width: 33%;
      flex-basis: 33%;
    }
  }
`

const StyledExhibitorHeader = styled.div`
  & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;

    @media (min-width: ${breakpoints.lg}) {
      flex-direction: row;
    }
    label {
      display: none;
    }
  }
`

const StyledGridContainerList = styled(Grid)`
  & {
    margin: 0 0 1em;
    width: 100%;
  }
`

const StyledGridEmptyList = styled(Grid)`
  & {
    margin-top: 1em;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
`

export default ExhibitorListPage
