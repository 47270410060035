import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Loading from '../Loading'

interface EditDialogProps extends DialogProps {
  saveLoading?: boolean
  cancelEvent: () => void
  saveEvent: () => void
}

const EditDialog: React.FC<EditDialogProps> = ({
  open,
  title,
  children,
  saveLoading,
  cancelEvent,
  saveEvent,
  ...props
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const handleCancel = () => {
    cancelEvent()
  }

  const handleSave = () => {
    saveEvent()
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" disabled={saveLoading}>
          <FormattedMessage id="dialog.cancel" />
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          autoFocus
          disabled={saveLoading}
        >
          {saveLoading && <Loading size="1em" />}
          <FormattedMessage id="dialog.save" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDialog
