import styled from '@emotion/styled'
import { Button, FormControl, TextField } from '@material-ui/core'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import EditDialog from '../modals/EditDialog'
import FormContainer from '../ui/Form/FormContainer'
import { Label } from '../ui/LabelWrapper/LabelWrapper'

interface IVideoUrlProps {
  url?: string
  onChange: (value: string | undefined) => void
}

const VideoUrl: React.FC<IVideoUrlProps> = ({ url, onChange }) => {
  const intl = useIntl()
  const [videoUrl, setVideoUrl] = useState<string>()
  const [openVideoUrl, setOpenVideoUrl] = useState(false)

  const handleUpdateVideoUrl = () => {
    setOpenVideoUrl(true)
  }

  const updateVideoUrl = () => {
    setOpenVideoUrl(false)
    onChange && onChange(videoUrl)
  }

  useEffect(() => {
    if (url) setVideoUrl(url)
  }, [url])

  return (
    <StyledDiv>
      <StyledButton
        variant="contained"
        color="secondary"
        startIcon={<OndemandVideoIcon fontSize="small" />}
        onClick={handleUpdateVideoUrl}
      >
        <FormattedMessage id={url ? 'button.videoUrl.edit' : 'button.videoUrl.add'} />
      </StyledButton>
      <EditDialog
        title={intl.formatMessage({ id: url ? 'button.videoUrl.edit' : 'button.videoUrl.add' })}
        open={openVideoUrl}
        saveEvent={updateVideoUrl}
        cancelEvent={() => setOpenVideoUrl(false)}
        maxWidth="sm"
        fullWidth
      >
        <FormContainer>
          <FormControl>
            <Label htmlFor="video_url" id="video_url_id_label">
              <FormattedMessage id="videoUrl" />
            </Label>
            <TextField
              id="video_url"
              aria-labelledby="video_url_id_label"
              value={videoUrl || ''}
              placeholder={intl.formatMessage({ id: 'videoUrl.placeholder' })}
              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setVideoUrl(event.target.value)
              }}
            />
          </FormControl>
        </FormContainer>
      </EditDialog>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  margin-bottom: 10px;
`

const StyledButton = styled(Button)`
  text-transform: uppercase;
  text-align: left;
`

export default VideoUrl
