import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import { PRIMARY_COLOR } from '../../../theme'
import InvisibleButton from '../InvisibleButton/InvisibleButton'

type HTMLInputFileProps = Exclude<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type'
>

export interface IFormPictureUploaderProps {
  image?: string | null
  square?: boolean
  onChangeImage?: (base64: string) => void
}

const FormPictureUploader: React.FC<IFormPictureUploaderProps & HTMLInputFileProps> = ({
  children,
  image,
  square,
  onChangeImage,
  ...props
}) => {
  const inputFile = useRef<HTMLInputElement>(null)
  const [picture, setPicture] = useState<string | undefined>(undefined)

  useEffect(() => {
    // test if not a color
    if (image && image.indexOf('rgb') === -1) setPicture(image)
  }, [image])

  const onButtonClick = () => {
    if (inputFile && inputFile.current) {
      inputFile.current.click()
    }
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0])
      setPicture(url)
      if (onChangeImage) toDataUrl(url, onChangeImage)
    } else {
      setPicture(undefined)
    }
  }

  const toDataUrl = (url: string, callback: (base64: string) => void) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => {
        callback(reader.result as string)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  return (
    <StyledContainer>
      <StyledInvisibleButton type="button" onClick={onButtonClick} square={square}>
        {picture && <Image src={picture} alt="preview" />}
        {!picture && children}
      </StyledInvisibleButton>
      <input
        type="file"
        hidden
        ref={inputFile}
        {...(props as HTMLInputFileProps)}
        onChange={onFileChange}
      />
    </StyledContainer>
  )
}

// language=SCSS
const StyledContainer = styled.div`
  & {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }
`

// language=SCSS
const StyledInvisibleButton = styled(InvisibleButton) <{ square?: boolean }>`
  & {
    border: 2px dashed ${PRIMARY_COLOR};
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
    height: ${props => props.square ? '100px' : '70px'};;
    width: ${props => props.square ? '125px' : '70px'};
    border-radius: ${props => props.square ? '0' : '50%'};
  }
`

// language=SCSS
const Image = styled.img`
  & {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
  }
`

export default FormPictureUploader
