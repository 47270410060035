import styled from '@emotion/styled'
import EditIcon from '@material-ui/icons/Edit'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useDetectImageExist } from '../../hooks'
import { IRootState } from '../../redux'
import { WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import InvisibleButton from '../InvisibleButton'
import Loading from '../Loading'

type HTMLInputFileProps = Exclude<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type'
>

interface IExhibitorImageProps {
  readonly: boolean
  children?: ReactNode
  onChangeImage?: (base64: string) => void
}

const ExhibitorImage: React.FC<IExhibitorImageProps & HTMLInputFileProps> = ({
  readonly,
  children,
  onChangeImage,
  ...props
}) => {
  const intl = useIntl()
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const inputFile = useRef<HTMLInputElement>(null)
  const image = useDetectImageExist(exhibitor?.imageUrl, false, '&size=large')
  const [picture, setPicture] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (exhibitor) {
      setPicture(image)
    }
  }, [exhibitor, image])

  const onButtonClick = () => {
    if (inputFile && inputFile.current) {
      inputFile.current.click()
    }
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0])
      setPicture(url)
      if (onChangeImage) toDataUrl(url, onChangeImage)
    } else {
      setPicture(undefined)
    }
  }

  const toDataUrl = (url: string, callback: (base64: string) => void) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => {
        callback(reader.result as string)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }
  return exhibitor ? (
    <StyledImage>
      <img src={picture} alt={exhibitor.name} />
      <input
        type="file"
        hidden
        ref={inputFile}
        {...(props as HTMLInputFileProps)}
        onChange={onFileChange}
      />
      {!readonly && (
        <StyledInvisibleButton
          type="button"
          onClick={onButtonClick}
          title="Cliquer pour changer d'image"
        >
          <EditIcon
            titleAccess={intl.formatMessage({ id: 'titleAccess.ExhibitorImage.EditIcon' })}
          />
        </StyledInvisibleButton>
      )}
      {children}
    </StyledImage>
  ) : (
    <Loading />
  )
}

const StyledImage = styled.div`
  border-radius: ${BORDER_RADIUS_CARD};
  background-color: ${WHITE};
`

const StyledInvisibleButton = styled(InvisibleButton)`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ${WHITE};
`

export default ExhibitorImage
