import styled from '@emotion/styled'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { AlertType } from '../../entities/alert'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { setAlert } from '../../store/alerts'
import Icon, { IconList } from '../ui/Icon/Icon'

const ConnectedSnackbar: React.FC = () => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const alert = useSelector((state: IRootState) => state.alertState.alert)
  const authError = useSelector((state: IRootState) => state.authState.error)
  const dispatch = useThunkDispatch()

  function handleClose(event?: SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    if (alert) setOpen(true)
  }, [alert])

  useEffect(() => {
    if (authError) {
      dispatch(
        setAlert({
          id: new Date().getTime().toString(),
          content: authError,
          type: AlertType.error
        })
      )
    }
  }, [authError, dispatch])

  return (
    <Snackbar open={open} onClose={handleClose} key={(alert && alert.id) || undefined}>
      <Alert
        severity={(alert && alert.type) || AlertType.info}
        aria-describedby="client-snackbar"
        elevation={6}
        action={[
          <StyledIcon
            icon={IconList.Cross}
            key="close"
            aria-label={intl.formatMessage({ id: 'ariaLabel.ConnectedSnackBar.Icon' })}
            color="inherit"
            onClick={handleClose}
          />
        ]}
      >
        {(alert && alert.content) ||
          (alert && alert.intlKey && intl.formatMessage({ id: alert.intlKey })) ||
          ''}
      </Alert>
    </Snackbar>
  )
}

const StyledIcon = styled(Icon)`
  height: 25px;
  cursor: pointer;
`

export default ConnectedSnackbar
