import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Breadcrumbs, Grid, Link, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import EditBackgroundHeader from '../../components/globals/EditBackgroundHeader/EditBackgroundHeader'
import Loading from '../../components/Loading'
import { useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setEditMode, updateConference } from '../../store/conference'
import { setPageReferral } from '../../store/gtm'
import { setAlarm } from '../../store/stand'
import { PRIMARY_COLOR } from '../../theme'
import { WHITE } from '../../theme/colors'
import { CONTACTS_MENU_WIDTH, LEFT_MENU_WIDTH } from '../../theme/sizes'
import EditModeMenu from '../globals/EditModeMenu/EditModeMenu'
import IconButtonBg from '../ui/IconButtonBg/IconButtonBg'

interface IConferenceHeaderProps {
  goBack: boolean
  status: string
  updateConferenceStatus: (confStatus: string, notify: boolean) => void
}

const ConferenceHeader: React.FC<IConferenceHeaderProps> = ({ goBack, status, updateConferenceStatus }) => {
  const intl = useIntl()
  const [headerType, setHeaderType] = useState<string | null>(null)
  const [headerValue, setHeaderValue] = useState<string | null>(null)
  const conference = useSelector((state: IRootState) => state.conferenceState.conference)
  const updating = useSelector((state: IRootState) => state.conferenceState.updating)
  const editMode = useSelector((state: IRootState) => state.conferenceState.editMode)
  const alarm = useSelector((state: IRootState) => state.standState.alarm)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const dispatch = useThunkDispatch()

  const hasAlarm = useMemo(() => conference && alarm === `conference-${conference.id}`, [
    alarm,
    conference
  ])

  const setHeader = useCallback(
    (newType: string, newValue: string) => {
      setHeaderType(newType)
      setHeaderValue(newValue)
    },
    [setHeaderType, setHeaderValue]
  )

  const updateHeader = useCallback(
    (newType: string, newValue: string) => {
      setHeader(newType, newValue)
      const updateValue =
        newType === 'image'
          ? { bannerUrlS3: newValue, bannerColor: null }
          : { bannerColor: newValue, bannerUrlS3: null }
      if (conference) {
        dispatch(updateConference(conference.id, updateValue))
      }
    },
    [setHeader, dispatch, conference]
  )

  const updateEditMode = (em: boolean) => dispatch(setEditMode(em))

  // set exhibitor infos for use
  useEffect(() => {
    if (conference) {
      const type =
        conference.bannerUrl && conference.bannerUrl.startsWith('http') ? 'image' : 'color'
      const color = conference.bannerColor || PRIMARY_COLOR
      const image = conference.bannerUrl
      const value = type === 'image' ? image : color
      setHeader(type, value)
    }
  }, [conference, setHeaderType, setHeader])

  if (!conference) return <></>

  return (
    <StyledConferenceHeader type={headerType || 'color'} value={headerValue || PRIMARY_COLOR}>
      <Grid container spacing={2}>
        <StyledGridFlex item xs={12}>
          <Breadcrumbs
            separator={
              <NavigateNextIcon
                fontSize="small"
                color="inherit"
                titleAccess={intl.formatMessage({
                  id: 'titleAccess.ConferencePage.NavigateNextIcon'
                })}
              />
            }
            aria-label={intl.formatMessage({ id: 'ariaLabel.ConferencePage.Breadcrumb' })}
          >
            {!window.lightTheme && (
              <Link
                color="inherit"
                onClick={() => {
                  dispatch(setPageReferral(`${pageReferral}::conference-go-back`))
                  if (goBack) {
                    browserHistory.goBack()
                  } else {
                    browserHistory.push('/conference')
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                <FormattedMessage id="navigation.conference" />
              </Link>
            )}
            <StyledConferenceName>
              <Typography>{conference.title}</Typography>
            </StyledConferenceName>
          </Breadcrumbs>
          <Icons>
            {conference.editable && (
              <IconButtonBg
                onClick={() =>
                  dispatch(setAlarm(hasAlarm ? undefined : `conference-${conference.id}`))
                }
              >
                {hasAlarm && <NotificationsActiveIcon />}
                {!hasAlarm && <NotificationsOffIcon />}
              </IconButtonBg>
            )}
            {conference.editable && (
              <EditBackgroundHeader
                type={headerType || 'image'}
                value={headerValue || PRIMARY_COLOR}
                position="bottom-end"
                disabled={updating}
                onUpdate={updateHeader}
                onChange={setHeader}
              />
            )}
            {conference.editable && (
              <EditModeMenu
                editMode={editMode}
                setEditMode={updateEditMode}
                status={status}
                updateConferenceStatus={updateConferenceStatus}
              />
            )}
            {updating && <WhiteLoading />}
          </Icons>
        </StyledGridFlex>
      </Grid>
    </StyledConferenceHeader>
  )
}

const headerImageCss = (image?: string) => css`
  & {
    background-image: url("${image}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const StyledConferenceHeader = styled.div<{ type?: string; value?: string }>`
  & {
    position: relative;
    padding: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -${LEFT_MENU_WIDTH};
      right: calc(-${CONTACTS_MENU_WIDTH} - 12px);
      height: 350px;
      background-color: ${(props) => (props.type === 'color' ? props.value || 'white' : 'white')};
      ${(props) => (props.type === 'image' ? headerImageCss(props.value) : '')};
    }

    > * {
      position: relative;
    }
  }
`

const StyledGridFlex = styled(Grid)`
  & {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const WhiteLoading = styled(Loading)`
  & {
    color: ${WHITE};
    margin: 12px;
  }
`

const Icons = styled.div`
  display: flex;
  height: 45px;

  & > button,
  & > div {
    margin-left: 5px;
  }
`

const StyledConferenceName = styled.div`
  display: flex;
  flex-direction: row;
`

export default ConferenceHeader
