import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IBaseRootState } from '..'
import {
  getMagicStreams,
  getMessages,
  getUserChannels,
  patchReadChannel,
  postAnnouncement,
  postInvitation,
  postMagicStream,
  postMessage,
  postNotification,
  postResponse,
  postTakeAppointment
} from '../../api'
import {
  IContact,
  IConversation,
  IMessage,
  INotificationUser,
  IPresenceUser,
  IUser,
  NotificationType
} from '../../entities'
import { IPromiseReject, IPromiseResolve } from '../../entities/promise'
import consoleUtils from '../../utils/consoleUtils'
import promiseUtils from '../../utils/promiseUtils'
import { loadAgenda } from '../app'
import {
  addConversation,
  addMessagesToConversation,
  addMessageToConversation,
  decrementUnread,
  incrementUnread,
  setChannelsToBind,
  setCurrentConversation,
  setErrorChannels,
  setErrorConversation,
  setErrorConversations,
  setErrorMagicStreams,
  setErrorMagicStreamsUser,
  setErrorMessages,
  setErrorSending,
  setInvitationInProgress,
  setLoadedConversations,
  setLoadingChannels,
  setLoadingConversation,
  setLoadingConversations,
  setLoadingMagicStreams,
  setLoadingMagicStreamsUser,
  setLoadingMessages,
  setMagicStreams,
  setMagicStreamsUser,
  setMinimizeChat,
  setNotification,
  setOpenChat,
  setSended,
  setSendedLive,
  setSending
} from './actions'

export const loadChannels =
  () => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setLoadingChannels(true))
    try {
      const channels = await getUserChannels()
      dispatch(setChannelsToBind(channels))
    } catch (e) {
      consoleUtils.error(e)
      dispatch(setErrorChannels('Erreur lors de la récupération des channels'))
    }
    dispatch(setLoadingChannels(false))
  }

export const initConversations =
  (contacts: IContact[]) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setLoadingConversations(true))
    try {
      consoleUtils.log('[initConversations] conversation with contacts=', contacts)
      dispatch(setLoadedConversations(true))
      promiseUtils
        .waterfall(
          contacts.map((contact) => {
            return (resolve: IPromiseResolve, reject: IPromiseReject) => {
              if (contact.id) {
                getMessages(contact.id)
                  .then((messages) => {
                    dispatch(addConversation({ contact, messages }))
                    resolve()
                  })
                  .catch((raison) => reject(raison))
              }
            }
          })
        )
        .then(() => {
          dispatch(setLoadingConversations(false))
        })
    } catch (e) {
      consoleUtils.error(e)
      dispatch(setErrorConversations('Erreur lors du chargement du contexte des conversations'))
    }
  }

export const startConversation =
  (contact: IContact) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setLoadingConversation(true))
    try {
      dispatch(setOpenChat(true))
      dispatch(setMinimizeChat(false))
      if (contact.id) {
        const messages = await getMessages(contact.id)
        dispatch(addConversation({ contact, messages }, true))
        dispatch(setCurrentConversation(contact.id))
      }
    } catch (e) {
      consoleUtils.error(e)
      dispatch(setErrorConversation('Erreur lors du chargement de la conversation'))
    }
    dispatch(setLoadingConversation(false))
  }

export const loadMessages =
  (contactId: number, lastMessageId: number) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setLoadingMessages(true))
      try {
        const messages = await getMessages(contactId, lastMessageId)
        dispatch(addMessagesToConversation(contactId, messages))
      } catch (e) {
        consoleUtils.error(e)
        dispatch(setErrorMessages('Erreur lors de la récupération des messages'))
      }
      dispatch(setLoadingMessages(false))
    }

export const sendMessage =
  (contactId: number, message: string) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setSending(true))
      try {
        consoleUtils.log('Send message to contactId=', contactId, ', message=', message)
        const messageSended = await postMessage(message, [contactId])
        // add message to conversation
        dispatch(addMessageToConversation(contactId, messageSended))
      } catch (e) {
        consoleUtils.error(e)
        dispatch(setErrorSending((e as any).message))
      }
      dispatch(setSending(false))
    }

export const sendInvitation =
  (contactId: number, message: string, currentLocation: string, roomNumber?: number) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setSending(true))
      try {
        consoleUtils.log(
          'Send invitation to contactId=',
          contactId,
          ', message=',
          message,
          ', currentLocation=',
          currentLocation,
          ', roomNumber=',
          roomNumber
        )
        const messageSended = await postInvitation(message, currentLocation, [contactId], roomNumber)
        // add message to conversation
        dispatch(addMessageToConversation(contactId, messageSended))
        dispatch(setInvitationInProgress(true))
      } catch (e) {
        consoleUtils.error(e)
        dispatch(setErrorSending((e as any).message))
      }
      dispatch(setSending(false))
    }

export const sendResponse =
  (message: string, answer: 0 | 1 | 2, contactId?: number, streamId?: number, dueDate?: string) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setSending(true))
      try {
        consoleUtils.log(
          'Send response to contactId=',
          contactId,
          ', message=',
          message,
          ', answer=',
          answer
        )
        if (streamId) {
          await postMagicStream(streamId, answer, dueDate)
        }
        if (contactId) {
          const messageSended = await postResponse(message, answer, [contactId])
          // add message to conversation
          dispatch(addMessageToConversation(contactId, messageSended))
        }
      } catch (e) {
        consoleUtils.error(e)
        dispatch(setErrorSending((e as any).message))
      }
      dispatch(setSending(false))
    }

export const newMessageReceived =
  (contact: IContact, conversations: IConversation[], data: IMessage) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      try {
        // detect if conversation is already loaded
        if (contact.id) {
          consoleUtils.log(`[newMessageReceived] contact.id=${contact.id}, data=${data.message}`)
          dispatch(incrementUnread(data))
          if (conversations.find((conversation) => conversation.contact.id === contact.id)) {
            dispatch(addMessageToConversation(contact.id, data))
          } else {
            const messages = await getMessages(contact.id, 0)
            dispatch(addConversation({ contact, messages }, true))
          }
        }
      } catch (e) {
        consoleUtils.error(e)
      }
    }

export const parseMessage =
  (
    user: IUser,
    author: IPresenceUser,
    message: string,
    embed: string,
    onResponseYes?: () => void,
    onResponseNo?: () => void
  ) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      if (embed) {
        const embedData = JSON.parse(embed)
        if (embedData.response && user.id !== author.id) {
          consoleUtils.log(`>>> Response received with '${embedData.response.value}'`)
          if (embedData.response.value === 0 || embedData.response.value === 1) {
            dispatch(setInvitationInProgress(false))
          }
          if (embedData.response.value === 1) {
            onResponseYes && onResponseYes()
          } else if (embedData.response.value === 0) {
            onResponseNo && onResponseNo()
          }
        } else if (embedData.toast) {
          consoleUtils.log('>>> Notification received with ', embedData.toast)
          dispatch(
            setNotification({
              type: embedData.toast.type,
              params: embedData.toast.params,
              message
            })
          )
        }
      }
    }

export const readMessage =
  (currentConversation: number) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      try {
        consoleUtils.log(`Decrement unread message from user ${currentConversation}`)
        dispatch(decrementUnread(currentConversation))
        // PATCH api to mark as read
        await patchReadChannel(currentConversation)
      } catch (e) {
        consoleUtils.error(e)
      }
    }

export const takeAppointment =
  (
    contactIds: number[],
    message: string,
    date: Date,
    url?: string,
    messageOnly?: boolean,
    endDate?: Date
  ) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setSending(true))
      try {
        consoleUtils.log('Take appointment=', contactIds, ', message=', message, ', date=', date)
        await postTakeAppointment(message, contactIds, date, url, messageOnly, endDate)
        dispatch(setSended(true))
        // reload agenda directly
        dispatch(loadAgenda())
      } catch (e) {
        dispatch(setErrorSending((e as any).message))
        consoleUtils.error(e)
      }
      dispatch(setSending(false))
    }

export const notificationLiveStart =
  (groupId: number, message: string, url: string) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setSending(true))
      try {
        consoleUtils.log('Notification live start groupId=', groupId, ', url=', url)
        await postNotification(groupId, message, NotificationType.START_LIVE, { url })
        dispatch(setSendedLive(true))
      } catch (e) {
        dispatch(setErrorSending((e as any).message))
        consoleUtils.error(e)
      }
      dispatch(setSending(false))
    }

export const notificationLiveStop =
  (groupId: number, message: string) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setSending(true))
      try {
        consoleUtils.log('Notification live stop groupId=', groupId)
        await postNotification(groupId, message, NotificationType.STOP_LIVE)
        dispatch(setSendedLive(true))
      } catch (e) {
        dispatch(setErrorSending((e as any).message))
        consoleUtils.error(e)
      }
      dispatch(setSending(false))
    }

export const doAnnounce =
  (
    groupId: string | number,
    message: string,
    type: NotificationType,
    user: INotificationUser,
    url?: string,
    priority?: 'high' | 'low'
  ) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setSending(true))
      try {
        consoleUtils.log('Announce -> ', groupId, ' from user=', user)
        await postNotification(groupId, message, type, { user, url, priority })
        dispatch(setSended(true))
      } catch (e) {
        dispatch(setErrorSending((e as any).message))
        consoleUtils.error(e)
      }
      dispatch(setSending(false))
    }

export const doPremiumAnnounce =
  (
    groupId: string | number,
    message: string,
    user: INotificationUser,
    url?: string,
    segmentAll?: boolean,
    isHighPriority?: boolean
  ) =>
    async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
      dispatch(setSending(true))
      try {
        consoleUtils.log('Announce Premium -> ', groupId, ' from user=', user)
        await postAnnouncement(groupId, message, { user, url }, segmentAll, isHighPriority)
        dispatch(setSended(true))
      } catch (e) {
        dispatch(setErrorSending((e as any).message))
        consoleUtils.error(e)
      }
      dispatch(setSending(false))
    }

export const loadMagicStreams =
  () => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setLoadingMagicStreams(true))
    try {
      const magicStreams = await getMagicStreams()
      dispatch(setMagicStreams(magicStreams))
    } catch (e) {
      dispatch(setErrorMagicStreams((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setLoadingMagicStreams(false))
  }

export const loadMagicStreamsUser =
  (userId: number) => async (dispatch: ThunkDispatch<IBaseRootState, {}, AnyAction>) => {
    dispatch(setLoadingMagicStreamsUser(true))
    try {
      const magicStreams = await getMagicStreams(userId)
      dispatch(setMagicStreamsUser(magicStreams))
    } catch (e) {
      dispatch(setErrorMagicStreamsUser((e as any).message))
      consoleUtils.error(e)
    }
    dispatch(setLoadingMagicStreamsUser(false))
  }
