import styled from '@emotion/styled'
import EventIcon from '@material-ui/icons/Event'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IMagicStream, MagicStreamStatus } from '../../entities'
import { useDateFns, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setExtendedPanel } from '../../store/app'
import { PRIMARY_COLOR } from '../../theme'
import { VERY_LIGHT_GRAY, WHITE } from '../../theme/colors'
import { formatName } from '../../utils'

type IAgendaHistoryItemProps = {
  stream: IMagicStream
}

const AgendaHistoryItem: React.FC<IAgendaHistoryItemProps> = ({ stream }) => {
  const intl = useIntl()
  const dateFns = useDateFns()
  const user = useSelector((state: IRootState) => state.appState.user)
  const location = useSelector((state: IRootState) => state.appState.location)
  const dispatch = useThunkDispatch()

  const isMe = useMemo(() => stream.creatorUser && user && stream.creatorUser.id === user.id, [
    stream,
    user
  ])

  const statusColor = useMemo(
    () =>
      stream.status === MagicStreamStatus.WAITING
        ? 'blue'
        : stream.status === MagicStreamStatus.INVITED
          ? 'orange'
          : stream.status === MagicStreamStatus.ACCEPTED
            ? 'green'
            : stream.status === MagicStreamStatus.RUNNING
              ? PRIMARY_COLOR
              : 'red',
    [stream]
  )

  const onClick = () => {
    if (stream) {
      if (stream.ctas && stream.ctas.go) {
        dispatch(setExtendedPanel('magic.streams', false))
        if (location && location.split('?')[0] === stream.ctas.go.split('?')[0]) {
          browserHistory.replace(stream.ctas.go)
        } else {
          browserHistory.push(stream.ctas.go)
        }
      }
    }
  }

  return (
    <AgendaHistoryItemContainer
      clickable={stream.status === MagicStreamStatus.RUNNING && !!(stream.ctas && stream.ctas.go)}
      onClick={onClick}
    >
      <StyledIcon className="icon-hover">
        <EventIcon fontSize="large" htmlColor={statusColor} />
      </StyledIcon>
      <StyledContent>
        {stream.dueDate && (
          <StyledText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id:
                    stream.status === MagicStreamStatus.ACCEPTED
                      ? 'rdv.commercial.accepted'
                      : stream.status === MagicStreamStatus.DECLINED
                        ? 'rdv.commercial.declined'
                        : stream.status === MagicStreamStatus.INVITED
                          ? (isMe ? 'rdv.commercial.rechedule' : 'rdv.commercial.invited')
                          : (isMe ? 'rdv.commercial.small' : 'rdv.commercial.waiting')
                },
                {
                  user: `<b>${formatName(isMe ? stream.subjectUser : stream.creatorUser).full}</b>`,
                  date: `<b>${dateFns.format(
                    new Date(stream.dueDate).toString(),
                    intl.formatMessage({ id: 'date.format' })
                  )}</b>`
                }
              )
            }}
          />
        )}
      </StyledContent>
    </AgendaHistoryItemContainer>
  )
}

const AgendaHistoryItemContainer = styled.div<{ clickable: boolean }>`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  font-size: 12px;
  transition: all 0.3s;
  padding: 7px 0;
  ${(props) => (props.clickable ? 'cursor: pointer;' : '')}

  &:hover {
    span {
      opacity: 1;
    }
    .icon-hover {
      background-color: ${WHITE};
    }
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 12px;
`

const StyledIcon = styled.div`
  background-color: ${VERY_LIGHT_GRAY};
  border-radius: 50%;
  padding: 5px;
`

const StyledText = styled.div``

export default AgendaHistoryItem
