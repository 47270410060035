import styled from '@emotion/styled'
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import { PRIMARY_COLOR } from '../../../theme'

type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const InvisibleButton: React.FC<ButtonProps> = ({ children, className, ...restProps }) => {
  return (
    <Button className={className} {...restProps}>
      {children}
    </Button>
  )
}
const Button = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  outline-color: ${PRIMARY_COLOR};

  &:disabled {
    cursor: initial;
  }
`

export default InvisibleButton
