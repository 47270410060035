import { IEventCo } from '../entities'
import { IOpentokSettings } from '../entities/mediaStream'
import settings from '../settings'
import { apiRequest } from './requests'

export const getOpentokSettings = async () => {
  const response = await apiRequest<IOpentokSettings>(
    'GET',
    `${settings.url.api}/e_events/settings/video/${settings.eventGroupId}`
  )
  return response
}

export const getEventCo = async () => {
  const response = await apiRequest<IEventCo>(
    'GET',
    `${settings.url.api}/e_events/list?eventGroupId=${settings.eventGroupId}`
  )
  return response
}
