import settings from '../settings'

export const PRIMARY =
  `rgb(${settings.theme.colors.primaryR},${settings.theme.colors.primaryG},${settings.theme.colors.primaryB})`
export const PRIMARY_LIGHT =
  `rgba(${settings.theme.colors.primaryR},${settings.theme.colors.primaryG},${settings.theme.colors.primaryB}, 0.1)`
export const BACKGROUND_PRIMARY_OPACITY_8 =
  `rgba(${settings.theme.colors.primaryR},${settings.theme.colors.primaryG},${settings.theme.colors.primaryB}, 0.8)`
export const BACKGROUND_PRIMARY_OPACITY_9 =
  `rgba(${settings.theme.colors.primaryR},${settings.theme.colors.primaryG},${settings.theme.colors.primaryB}, 0.95)`
export const SECONDARY =
  `rgb(${settings.theme.colors.secondaryR},${settings.theme.colors.secondaryG},${settings.theme.colors.secondaryB})`
export const SECONDARY_LIGHT =
  `rgba(${settings.theme.colors.secondaryR},${settings.theme.colors.secondaryG},${settings.theme.colors.secondaryB}, 0.1)`
export const BACKGROUND_SECONDARY_OPACITY_8 =
  `rgba(${settings.theme.colors.secondaryR},${settings.theme.colors.secondaryG},${settings.theme.colors.secondaryB}, 0.8)`
export const BACKGROUND_SECONDARY_OPACITY_9 =
  `rgba(${settings.theme.colors.secondaryR},${settings.theme.colors.secondaryG},${settings.theme.colors.secondaryB}, 0.95)`
export const BACKGROUND_PRIMARY_LIGHT = '#f4f7fc'
export const BACKGROUND_SECONDARY_LIGHT = 'rgba(55, 55, 89, 0.5)'
export const CORNFLOWER = '#6788ff'

export const ROSY_PINK = '#f46580'
export const RED = '#c8102e'
export const PASTEL_RED = '#eb4848'
export const BRIGHT_RED = '#f45332'

export const PALE_BLUE = '#dcf2f3'
export const LIGHT_BLUE_GREY = '#aed6e1'
export const TIFFANY_BLUE = '#7fd4d9'
export const CORNFLOWER_BLUE = '#5874d2'
export const AZURE = '#0a90f7'
export const SKY_BLUE = '#76b9ec'
export const PERRYWINKLE = '#839be9'

export const GOLD = '#ffd700'
export const MARIGOLD = '#f7cb0a'
export const DARK_CREAM = '#ffd898'
export const BEIGE = '#f8cc92'
export const BUTTERSCOTCH = '#ffaa3c'
export const ORANGEISH = '#fd9743'
export const PINKISH_ORANGE = '#f37552'
export const ORANGE_RED = '#FF6565'

export const WHITE = '#ffffff'
export const DARKEN_WHITE = '#fafafa'
export const VERY_LIGHT_GRAY = '#f3f3f3'
export const PALE = '#f8edda'
export const PALE_GREY = '#eaedfb'

export const LIGHT_GRAY = '#bababa'
export const GREYISH_BROWN = '#444444'
export const LIGHT_BLACK = '#494949'
export const TUNDRA = '#414141'
export const BLACK_BLUE = '#17173e'

export const VERY_LIGHT_GREEN = '#f1fbf8'
export const SEAFOAM = '#70d694'
export const SEAFOAM_BLUE = '#7addc0'
export const BLUE_GREEN = '#127155'
export const FRESH_GREEN = '#6fd25c'
export const GRAY = '#a7a7a7'
export const GRAY_200 = '#EAEAF2'

export const GRAY_ICON = '#A5ACC6'
export const ORANGE = '#FFB967'
export const GRAY_BORDER = '#e4e7ee'

export const AVATAR_ME_COLOR = '#6fd779'
export const AVATAR_COLORS: { [key: string]: string } = {
  a: '#f44336',
  b: '#8d6e63',
  c: '#f06292',
  d: '#4a148c',
  e: '#9575cd',
  f: '#5c6bc0',
  g: '#42a5f5',
  h: '#2196f3',
  i: '#81d4fa',
  j: '#00bfa5',
  k: '#81c784',
  l: '#fbc02d',
  m: '#2e7d32',
  n: '#8bc34a',
  o: '#4e342e',
  p: '#f57f17',
  q: '#ff6f00',
  r: '#00C851',
  s: '#90a4ae',
  t: '#37474F',
  u: '#b0bec5',
  v: '#607d8b',
  w: '#263238',
  x: '#7283a7',
  y: '#45526e',
  z: '#1c2a48'
}

export const TEST_COLORS: { [key: number]: string } = {
  1: '#E74C3C',
  2: '#f39c12',
  3: '#f1c40f',
  4: '#9bd715',
  5: '#2ecc71'
}
