import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IContact } from '../../entities'
import { usePagination, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { loadParticipantsFiltered } from '../../store/participant/thunks'
import ExtensiblePanel from '../globals/ExtensiblePanel/ExtensiblePanel'
import ContactItem from './ContactItem'
import ContactsList from './ContactsList'

interface IPersonsPanelProps {
  half: boolean
  titleKey: string
  persons: IContact[]
  listMode?: boolean
}

const PersonsPanel: FC<IPersonsPanelProps> = ({ half, titleKey, persons, listMode }) => {
  const { count, page, setPage } = usePagination(1)
  const [extended, setExtended] = useState(false)
  const [filterSearch, setFilterSearch] = useState('')
  const [filterIsOnline, setFilterIsOnline] = useState(true)
  const [filterIsContact, setFilterIsContact] = useState(false)
  const [filterCustom, setFilterCustom] = useState<{
    [key: string]: number | string | { id: number; name: string }
  }>({})
  const [filterTags, setFilterTags] = useState<{ [key: string]: boolean }>({})
  const [filterKeywordsX, setFilterKeywordsX] = useState<{ [key: string]: boolean }>({})
  const [filterKeywordsY, setFilterKeywordsY] = useState<{ [key: string]: boolean }>({})
  const [filterUserRoles, setFilterUserRoles] = useState('')
  const participantsFiltered = useSelector(
    (state: IRootState) => state.participantState.participantsFiltered
  )
  const loadingParticipantsFiltered = useSelector(
    (state: IRootState) => state.participantState.loadingParticipantsFiltered
  )
  const dispatch = useThunkDispatch()

  const handleExtend = (extend: boolean) => {
    setExtended(extend)
    if (extend) {
      dispatch(
        loadParticipantsFiltered({
          page,
          search: filterSearch,
          sortBy: 'recommandation',
          functionName: filterCustom.functions as string,
          partnerType: filterCustom.partner_types
            ? (filterCustom.partner_types as { id: number; name: string })
            : undefined,
          isOnline: filterIsOnline,
          isContact: filterIsContact,
          tags: Object.keys(filterTags)
            .filter((id) => filterTags[id])
            .join(','),
          keywordsX: Object.keys(filterKeywordsX)
            .filter((id) => filterKeywordsX[id])
            .join(','),
          keywordsY: Object.keys(filterKeywordsY)
            .filter((id) => filterKeywordsY[id])
            .join(','),
          roles: filterUserRoles
        })
      )
    }
  }

  useEffect(() => {
    if (extended) {
      dispatch(
        loadParticipantsFiltered({
          page,
          search: filterSearch,
          sortBy: 'recommandation',
          functionName: filterCustom.functions as string,
          partnerType: filterCustom.partner_types
            ? (filterCustom.partner_types as { id: number; name: string })
            : undefined,
          isOnline: filterIsOnline,
          isContact: filterIsContact,
          tags: Object.keys(filterTags)
            .filter((id) => filterTags[id])
            .join(','),
          keywordsX: Object.keys(filterKeywordsX)
            .filter((id) => filterKeywordsX[id])
            .join(','),
          keywordsY: Object.keys(filterKeywordsY)
            .filter((id) => filterKeywordsY[id])
            .join(','),
          roles: filterUserRoles
        })
      )
    }
  }, [
    extended,
    filterSearch,
    filterIsOnline,
    filterIsContact,
    filterCustom,
    page,
    filterTags,
    filterKeywordsX,
    filterKeywordsY,
    filterUserRoles,
    dispatch
  ])

  return (
    <ExtensiblePanel
      half={half}
      titleKey={titleKey}
      // subheaderNode={
      //   !settings.theme.light && (
      //     <Button component={Link} to="/participant?online=true" color="primary">
      //       <FormattedMessage id="persons.on.app.link.list" />
      //     </Button>
      //   )
      // }
      contentExtended={
        listMode && (
          <ContactsList
            contacts={participantsFiltered}
            loading={loadingParticipantsFiltered}
            count={count}
            page={page}
            setPage={setPage}
            setFilterSearch={setFilterSearch}
            filterSearch={filterSearch}
            setFilterCustom={setFilterCustom}
            filterCustom={filterCustom}
            setFilterIsOnline={setFilterIsOnline}
            filterIsOnline={filterIsOnline}
            setFilterIsContact={setFilterIsContact}
            filterIsContact={filterIsContact}
            filterTags={filterTags}
            setFilterTags={setFilterTags}
            filterKeywordsX={filterKeywordsX}
            setFilterKeywordsX={setFilterKeywordsX}
            filterKeywordsY={filterKeywordsY}
            setFilterKeywordsY={setFilterKeywordsY}
            filterUserRoles={filterUserRoles}
            setFilterUserRoles={setFilterUserRoles}
          />
        )
      }
      onExtend={handleExtend}
    >
      {/* // FIXME : problème d'affichage blanc, surement un contact qui enter et leave trop vite */}
      {/* <AnimatedList> */}
      {persons.map((person, index) => (
        <ContactItem
          key={person.id}
          contact={person}
          index={index}
          add
          more={false}
          note={false}
          showModerator
        />
      ))}
      {/* </AnimatedList> */}
    </ExtensiblePanel>
  )
}

export default PersonsPanel
