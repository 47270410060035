import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import jwtDecode from 'jwt-decode'
import { IToken } from '../entities'
import store, { browserHistory } from '../redux'
import settings from '../settings'
import { setEventCoCompatibility } from '../store/app'
import { resetToken, setToken, TOKEN_KEY } from '../store/auth'
import asyncLocalStorage from '../utils/asyncLocalstorage'
import consoleUtils from '../utils/consoleUtils'
import { getToken } from './auth'

export async function checkVersionInterceptor(response: AxiosResponse) {
  const { appState } = store.getState()
  if (appState.eventCoCompatibility !== response.headers['eventco-compatibility']) {
    store.dispatch(setEventCoCompatibility(response.headers['eventco-compatibility']))
  }
  return response
}

export const errorInterceptor = (error: AxiosError) => {
  if (
    error.response &&
    /*(error.response.status === 403 || */ error.response.status === 401 /*)*/
  ) {
    browserHistory.push('/login')
  }
  return Promise.reject(error)
}

export async function handleToken(config: AxiosRequestConfig) {
  const { authState } = store.getState()
  let token = authState.token
  if (token) {
    const tokenData: IToken = jwtDecode(token)
    const now = Date.now()
    if (now > tokenData.exp * 1000) {
      try {
        consoleUtils.info('REFRESH TOKEN')
        const response = await getToken()
        store.dispatch(setToken(response))
        await asyncLocalStorage.setItem(TOKEN_KEY, JSON.stringify(response))
        // refresh pusher headers
        if (window.pusher && window.pusher.config && window.pusher.config.auth) {
          window.pusher.config.auth.headers.Authorization = 'Bearer ' + response.token
        }
        token = response.token
      } catch (e) {
        await store.dispatch(resetToken())
        await asyncLocalStorage.removeItem(TOKEN_KEY)
        window.location.href = settings.url.login_page.replace(
          '{target_path}',
          window._env_.REACT_APP_ENDPOINT
        )
        // cancel the API call
        throw new Axios.Cancel('user disconnected')
      }
    }
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
}

export async function handleIdentity(config: AxiosRequestConfig) {
  config.headers['X-Context-App-Name'] = settings.theme.name
  config.headers['X-Context-Group'] = settings.eventGroupId

  const { appState } = store.getState()
  const eventLanguages = (settings.eventLanguages as string).split(',')
  if (
    appState.user?.dataUser?.preferredLanguage &&
    eventLanguages.length > 0 &&
    eventLanguages.includes(appState.user?.dataUser?.preferredLanguage)
  ) {
    config.headers['X-Context-Language'] = appState.user?.dataUser?.preferredLanguage
  }

  return config
}
