import { IPusherUtils } from '../entities/pusher'
import consoleUtils from './consoleUtils'

const pusherUtils: IPusherUtils = {
  subscribe(channelName) {
    return new Promise((resolve, reject) => {
      if (!window.pusher) {
        return reject(`No pusher (subscribe to channel )`)
      }
      const channelExist = window.pusher.channel(channelName)
      if (channelExist) {
        return resolve(channelExist)
      }
      consoleUtils.log(`Subscribe to channel '${channelName}'`)
      const channel = window.pusher.subscribe(channelName)
      channel.bind('pusher:subscription_succeeded', () => {
        // success
        consoleUtils.log('pusher:subscription_succeeded')
        resolve(channel)
      })
      channel.bind('pusher:subscription_error', (err: any) => {
        if (!window.pusher) {
          return reject(`No pusher (subscribe to channel )`)
        }
        consoleUtils.error('pusher:subscription_error=', err)
        const status = err ? err.status : null
        const code = err && err.data ? err.data.code : null
        if (status === 408 || status === 503 || (code >= 4200 && code < 4300)) {
          consoleUtils.error(`RETRY Subscribe to channel '${channelName}'`)
          const channelRetry = window.pusher.subscribe(channelName)
          channelRetry.bind('pusher:subscription_succeeded', () => {
            // success
            resolve(channelRetry)
          })
          channel.bind('pusher:subscription_error', (errRetry: any) => {
            const errorString = JSON.stringify(errRetry, null, 2)
            consoleUtils.error(
              `ERROR with subscribe to channel '${channelName}' (error=${errorString})`
            )
            reject()
          })
        }
      })
    })
  },
  unsubscribe(channelName) {
    if (!window.pusher) return consoleUtils.error('No pusher')
    const existingChannel = window.pusher.channel(channelName)
    if (existingChannel) {
      consoleUtils.log(`Unsubscribe from channel '${channelName}'`)
      window.pusher.unsubscribe(channelName)
    } else {
      consoleUtils.log(`Try unsubscribe from channel '${channelName}' not exist, command cancel`)
    }
  },
  triggerClientLocation(channel, eventName, data) {
    if (!channel) return consoleUtils.log(`Trigger event '${eventName}' no channel`)
    const triggered = channel.trigger(eventName, data)
    consoleUtils.log(`Triggered event '${eventName}' > triggered=`, triggered)
    if (!triggered) {
      const triggeredRetry = channel.trigger(eventName, data)
      consoleUtils.log(`RETRY trigger event '${eventName}' > triggered=`, triggeredRetry)
    }
  },
  triggerLeaveLocation(channel, eventName, user) {
    if (!channel) return consoleUtils.log(`Trigger event '${eventName}' no channel`)
    const triggered = channel.trigger(eventName, { user })
    consoleUtils.log(`Triggered event '${eventName}' > triggered=`, triggered)
    if (!triggered) {
      const triggeredRetry = channel.trigger(eventName, { user })
      consoleUtils.log(`RETRY trigger event '${eventName}' > triggered=`, triggeredRetry)
    }
  },
  triggerClientMessage(channel, eventName, data) {
    if (!channel) return consoleUtils.log(`Trigger event '${eventName}' no channel`)
    const triggered = channel.trigger(eventName, data)
    consoleUtils.log(`Triggered event '${eventName}' > triggered=`, triggered)
    if (!triggered) {
      const triggeredRetry = channel.trigger(eventName, data)
      consoleUtils.log(`RETRY trigger event '${eventName}' > triggered=`, triggeredRetry)
    }
  }
}

export default pusherUtils
