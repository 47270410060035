import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import React from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import ErrorDisplay from '../../components/globals/ErrorDisplay/ErrorDisplay'
import { useEffectOnce, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { setPageReferral } from '../../store/gtm'

const NotFoundPage: React.FC = () => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const dispatch = useThunkDispatch()

  // gtm DATA
  useEffectOnce(() => {
    const title = intl.formatMessage({ id: 'gtm.404.title' })
    dispatch(setPageReferral(title))
    sendDataToGTM({
      event: 'page-view',
      pageTitle: title,
      pageType: intl.formatMessage({ id: 'gtm.404.type' }),
      pageReferral
    })
  })

  return (
    <StyledPageContainer>
      <ErrorDisplay
        title={<FormattedMessage id="error.404.title" />}
        content={<FormattedMessage id="error.404.text" />}
      />
      <Helmet>
        <title>
          {`${intl.formatMessage({ id: 'error.404.title' })} - ${settings.theme.header.title}`}
        </title>
      </Helmet>
    </StyledPageContainer>
  )
}

const StyledPageContainer = styled.div`
  & {
    height: 50vh;
    width: 100%;
  }
`

export default NotFoundPage
