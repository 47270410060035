import React from 'react'
import { IContact } from '../../entities'
import { formatName } from '../../utils'
import FullScreenDialog from '../modals/FullScreenDialog'
import Transition from '../Transition'
import ContactFullScreenContent from './ContactFullScreenContent'

interface IContactFullScreenProps {
  open: boolean
  contact: IContact
  disabledSendMessage?: boolean | null
  isOnline?: boolean | undefined
  isOffice?: boolean | undefined
  handleClose: (event: React.MouseEvent<EventTarget>) => void
  onEnterOffice?: () => void
}

const ContactFullScreen: React.FC<IContactFullScreenProps> = ({
  open,
  contact,
  disabledSendMessage,
  isOnline,
  isOffice,
  handleClose,
  onEnterOffice
}) => {
  return (
    <FullScreenDialog
      title={formatName(contact.userPresence.user).full}
      open={open}
      fullScreen
      TransitionComponent={Transition}
      scroll={'body'}
      onClose={handleClose}
    >
      <ContactFullScreenContent
        contact={contact}
        disabledSendMessage={disabledSendMessage}
        isOnline={isOnline}
        isOffice={isOffice}
        onEnterOffice={onEnterOffice}
        onClose={handleClose}
      />
    </FullScreenDialog>
  )
}

export default ContactFullScreen
