import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import InfoIcon from '@material-ui/icons/Info'
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContact, IContactUserStatus } from '../../../entities'
import { useThunkDispatch, useTooltip } from '../../../hooks'
import { browserHistory, IRootState } from '../../../redux'
import settings from '../../../settings'
import { setExtendedPanel } from '../../../store/app'
import { setPageReferral } from '../../../store/gtm'
import { LIGHT_GRAY } from '../../../theme/colors'

type IProps = {
  contact: IContact
  status?: IContactUserStatus | null
  isOnline?: boolean | undefined
  isOffice?: boolean | undefined
  onClose?: (event: React.MouseEvent<EventTarget>) => void
  onEnterOffice?: () => void
}

const ActionJoin: React.FC<IProps> = ({
  contact,
  status,
  isOnline,
  isOffice,
  onClose,
  onEnterOffice
}) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const currentLocation = useSelector((state: IRootState) => state.appState.location)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const { anchorRef: anchorRefJoin, setOpen: setOpenJoin, Tooltip: TooltipJoin } = useTooltip(
    intl.formatMessage({
      id:
        !status || status.appName !== settings.theme.name
          ? 'contactpopper.join.noStatus'
          : !contact.userPresence.isOnline && !isOnline
            ? 'contactpopper.join.notHere'
            : (status.url && status.url === currentLocation) || isOffice
              ? 'contactpopper.join.samePlace'
              : 'contactpopper.button_join'
    })
  )
  const dispatch = useThunkDispatch()

  const handleJoin = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    if (isOffice) {
      onEnterOffice && onEnterOffice()
    } else if (status && status.appName === settings.theme.name) {
      dispatch(setPageReferral(`${pageReferral}::join`))
      sendDataToGTM({ event: 'join-contact', cardType: 'Contact Popper' })
      dispatch(setExtendedPanel('magic.streams', false))
      browserHistory.push(status.url)
    }
    onClose && onClose(event)
  }

  const notSameLocation = useMemo(
    () => isOffice || !status || (status.url && status.url !== currentLocation),
    [isOffice, status, currentLocation]
  )

  const sameApp = useMemo(() => !status || status.appName === settings.theme.name, [status])

  return (
    <>
      {/* // join */}
      {sameApp && notSameLocation ? (
        <MenuItem onClick={handleJoin}>
          <ListItemIcon>
            <AccountCircleIcon
              fontSize="large"
              titleAccess={intl.formatMessage({
                id: 'titleAccess.ContactPopperAction.AccountCircleIcon'
              })}
            />
          </ListItemIcon>
          <Typography variant="inherit">
            <FormattedMessage id="contactpopper.button_join" />
          </Typography>
        </MenuItem>
      ) : (
        <MenuItemDisabled
          ref={anchorRefJoin}
          onMouseOver={() => setOpenJoin(true)}
          onMouseOut={() => setOpenJoin(false)}
        >
          <ListItemIcon>
            <AccountCircleIcon
              fontSize="large"
              titleAccess={intl.formatMessage({
                id: 'titleAccess.ContactPopperAction.AccountCircleIcon'
              })}
            />
          </ListItemIcon>
          <Typography variant="inherit">
            <FormattedMessage id="contactpopper.button_join" />
          </Typography>
          <StyledRightListItemIcon>
            <InfoIcon fontSize="small" />
          </StyledRightListItemIcon>
          <TooltipJoin />
        </MenuItemDisabled>
      )}
    </>
  )
}

const MenuItemDisabled = styled(MenuItem)`
  color: ${LIGHT_GRAY};

  .MuiSvgIcon-root {
    fill: ${LIGHT_GRAY};
  }

  span {
    flex: auto;
  }
`

const StyledRightListItemIcon = styled(ListItemIcon)`
  justify-content: flex-end;
`

export default ActionJoin
