import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IMagicStream, MagicStreamStatus } from '../../entities'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setExtendedPanel } from '../../store/app'
import {
  sendResponse,
  setCallEnded,
  setCurrentConversation,
  setErrorSending,
  setMinimizeChat,
  setOpenChat,
  startConversation
} from '../../store/chat'
import { setPageReferral } from '../../store/gtm'
import { setStartOfficeCall } from '../../store/room'
import { PRIMARY_COLOR } from '../../theme'
import consoleUtils from '../../utils/consoleUtils'
import AnimatedIconButton from '../ui/AnimatedIconButton'
import DateTimePicker from '../ui/DateTimePicker/DateTimePicker'

type IStreamCallActionsProps = {
  stream: IMagicStream
  expirated: boolean
}

const StreamCallActions: React.FC<IStreamCallActionsProps> = ({ stream, expirated }) => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const location = useSelector((state: IRootState) => state.appState.location)
  const conversations = useSelector((state: IRootState) => state.chatState.conversations)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const sending = useSelector((state: IRootState) => state.chatState.sending)
  const errorSending = useSelector((state: IRootState) => state.chatState.errorSending)
  const dispatch = useThunkDispatch()

  // auto hide actions
  useEffect(() => {
    const remains = stream.expirationDate
      ? new Date(stream.expirationDate).getTime() - new Date().getTime()
      : 0
    const timer =
      remains > 0
        ? window.setTimeout(() => {
          dispatch(setCallEnded(stream.id, true))
        }, remains)
        : null
    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [stream, dispatch])

  const onStartCall = () => {
    if (stream) {
      if (stream.ctas && stream.ctas.accept) {
        dispatch(setPageReferral(`${pageReferral}::invitation-accept`))
        if (location && location.split('?')[0] === stream.ctas.accept.split('?')[0]) {
          browserHistory.replace(stream.ctas.accept)
        } else {
          browserHistory.push(stream.ctas.accept)
        }
      } else if (stream.subjectVideoRoom && stream.subjectVideoRoom.group.typeId) {
        // case office room
        consoleUtils.log('AUTO redirect to office')
        dispatch(setStartOfficeCall(true))
        browserHistory.replace(
          `/${stream.subjectVideoRoom.group.typeLabel === 'EXHIBITOR' ? 'exposant' : 'conference'
          }/${stream.subjectVideoRoom.group.typeId}?salon=bureau${location.indexOf('magicstream=true') > -1 ? '&magicstream=true' : ''
          }${location.indexOf('appointment=true') > -1 ? '&appointment=true' : ''}`
        )
      }
      dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'invitation.accepted'
          }),
          1,
          stream.creatorUser && stream.creatorUser.id,
          stream.id
        )
      )
      dispatch(setCallEnded(stream.id, true))
      dispatch(setExtendedPanel('magic.streams', false))
    }
  }

  const onEndCall = () => {
    if (stream) {
      dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'invitation.refused'
          }),
          0,
          stream.creatorUser && stream.creatorUser.id,
          stream.id
        )
      )
      dispatch(setCallEnded(stream.id, true))
    }
  }

  const onReschedule = (newDate: string) => {
    if (stream) {
      dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'appointment.reschedule'
          }),
          2,
          undefined, // stream.creatorUser && stream.creatorUser.id,
          stream.id,
          newDate
        )
      )
    }
  }

  const onChatCall = () => {
    if (stream) {
      dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'invitation.refused'
          }),
          0,
          stream.creatorUser && stream.creatorUser.id,
          stream.id
        )
      )
      if (stream.creatorUser) {
        if (
          conversations.find(
            (conversation) =>
              stream.creatorUser && conversation.contact.id === stream.creatorUser.id
          )
        ) {
          // already started
          dispatch(setOpenChat(true))
          dispatch(setMinimizeChat(false))
          dispatch(setCurrentConversation(stream.creatorUser.id))
        } else {
          // start
          dispatch(
            startConversation({
              id: stream.creatorUser.id,
              userPresence: {
                isOnline: true,
                user: stream.creatorUser
              }
            })
          )
        }
        dispatch(setExtendedPanel('magic.streams', false))
      }
    }
  }

  useEffect(() => {
    if (errorSending) {
      magicStream.error(errorSending)
      dispatch(setErrorSending(undefined))
    }
  }, [errorSending, magicStream, dispatch])

  return (
    <StreamActions>
      {!expirated && (
        <>
          <AnimatedIconButton
            onClick={onStartCall}
            title={intl.formatMessage({ id: 'invitation.accept' })}
          >
            <DoneIcon
              fontSize="large"
              htmlColor="green"
              titleAccess={intl.formatMessage({ id: 'titleAccess.IncommingCall.PhoneInTalkIcon' })}
            />
          </AnimatedIconButton>
          <IconButton onClick={onEndCall} title={intl.formatMessage({ id: 'invitation.refuse' })}>
            <CloseIcon
              fontSize="large"
              htmlColor="red"
              titleAccess={intl.formatMessage({ id: 'titleAccess.IncommingCall.CallEndIcon' })}
            />
          </IconButton>
          {stream.status === MagicStreamStatus.RUNNING && (
            <DateTimePicker
              id="date-time-picker-appointment"
              iconMode
              disabled={sending}
              title={intl.formatMessage({ id: 'appointment.reschedule' })}
              fullWidth
              format="dd/MM/yyyy HH:mm"
              minDate={new Date()}
              minDateMessage={intl.formatMessage({ id: 'appointment.minDate' })}
              showTodayButton
              value={stream.dueDate}
              onChange={(dateTime: MaterialUiPickersDate) => {
                if (dateTime) {
                  onReschedule(dateTime.toISOString())
                }
              }}
            />
          )}
        </>
      )}
      {stream.creatorUser && (
        <IconButton onClick={onChatCall} title={intl.formatMessage({ id: 'invitation.chat' })}>
          <ChatIcon
            fontSize="large"
            htmlColor={PRIMARY_COLOR}
            titleAccess={intl.formatMessage({ id: 'titleAccess.IncommingCall.ChatIcon' })}
          />
        </IconButton>
      )}
    </StreamActions>
  )
}

const StreamActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & svg {
    height: 30px;
    width: 30px;
  }
`

export default StreamCallActions
