export enum LanguageKeys {
  en = 'en',
  fr = 'fr'
}

export enum LogUserUrlTypes {
  home = 'homepage',
  exhibitorList = 'liste_exposants',
  visitorList = 'liste_participants',
  conferenceList = 'liste_conferences',
  conference = 'conference',
  exhibitor = 'exposant',
  vimeo = 'vimeo'
}

export enum LogUserActions {
  view = 'view',
  enter_table = 'enter_table',
  time_live = 'time_live'
}
