import styled from '@emotion/styled'
import { Avatar } from '@material-ui/core'
import React, { Children, FC, useMemo } from 'react'
import { PRIMARY, WHITE } from '../../../theme/colors'

const SPACINGS: { [key: string]: number } = {
  small: -16,
  medium: 0
}

interface IUserAvatarGroupProps {
  max?: number
  spacing?: number | 'small' | 'medium'
  total?: number
}

const UserAvatarGroup: FC<IUserAvatarGroupProps> = ({
  max,
  spacing = 'medium',
  total,
  children,
  ...props
}) => {
  const marginLeft = useMemo(
    () => (spacing && SPACINGS[spacing] !== undefined ? SPACINGS[spacing] : -spacing),
    [spacing]
  )
  const extra = useMemo(() => (total && max && total > max ? total - max : 0), [total, max])
  const childrenLength = useMemo(() => Children.toArray(children).length, [children])
  const maxDisplay = useMemo(() => max || childrenLength, [max, childrenLength])

  return (
    <StyledAvatarGroup {...props}>
      {(Children.toArray(children).slice(0, maxDisplay) as React.ReactElement[]).map(
        (child, index) => {
          return React.cloneElement(child, {
            style: {
              zIndex: childrenLength - index,
              marginLeft: index === 0 ? undefined : marginLeft,
              ...child.props.style
            }
          })
        }
      )}
      {extra > 0 && (
        <StyledAvatar
          style={{
            zIndex: 0,
            marginLeft: 0,
            scale: 1.75
          }}
        >
          +{extra}
        </StyledAvatar>
      )}
    </StyledAvatarGroup>
  )
}

const StyledAvatarGroup = styled.div`
  & {
    z-index: 2 !important;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
`

const StyledAvatar = styled(Avatar)`
  & {
    background-color: ${PRIMARY};
    border: '2px solid ${WHITE}';
    margin-left: -8;
    white-space: nowrap;

    &:first-of-type {
      margin-left: 0,
    }
  }
`

export default UserAvatarGroup
