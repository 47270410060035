import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as SvgExit } from '../../../assets/images/icons/exit.svg'
import { IRoom } from '../../../entities'
import { BLACK_BLUE, LIGHT_GRAY, WHITE } from '../../../theme/colors'

export interface IExternalRoomProps {
  room: IRoom
  handleLeave?: () => void
}

const ExternalRoom: React.FC<IExternalRoomProps> = ({ room, handleLeave }) => {
  const handleClick = () => window.open(room.sessionId, '_blank')


  return (
    <StyledExternal>
      <StyledOkButton size="medium" variant="contained" color="primary" onClick={handleClick}>
        <FormattedMessage id="external.button" />
      </StyledOkButton>
      <StyledButton
        startIcon={<SvgExit />}
        size="small"
        variant="contained"
        onClick={handleLeave}>
        <FormattedMessage id="external.leave" />
      </StyledButton>
    </StyledExternal>
  )
}

const StyledExternal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: ${BLACK_BLUE};
`

const StyledOkButton = styled(Button)`
  margin-top: 10px;
  padding: 20px;
  color: white;
  svg {
    color: ${WHITE};
    width: 20px;
    height: 20px;
  }
`

const StyledButton = styled(Button)`
  margin-top: 10px;
  background-color: ${LIGHT_GRAY};
  color: white;
  
  svg {
    color: ${WHITE};
    width: 20px;
    height: 20px;
  }
`

export default ExternalRoom
