import styled from '@emotion/styled'
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { LanguageKeys, PROFILINGS } from '../../../entities'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import Loading from '../../Loading'
import ProfilingIFrame from '../../profile/ProfilingIFrame'
import OnboardingModal from './OnboardingModal'
import OnboardingStepper from './OnboardingStepper'

interface IOnboardingProfilingProps {
  step: number
  total: number
  title: string
  opened?: boolean
  finishStep?: boolean
  onNext?: () => void
}

const OnboardingProfiling: React.FC<IOnboardingProfilingProps> = ({
  step,
  total,
  title,
  opened,
  finishStep,
  onNext
}) => {
  const [isOpen, setOpen] = useState(false)
  const userUpdating = useSelector((state: IRootState) => state.appState.userUpdating)
  const [showFullscreenAlertMessage, setShowFullscreenAlertMessage] = useState(true)
  const locale = useSelector((state: IRootState) => state.appState.locale)

  const localizedImage = useMemo(() => {
    let image = settings.theme.header.image
    if (locale === LanguageKeys.en && settings.theme.header.image_en) {
      image = settings.theme.header.image_en
    }
    return image
  }, [locale])
  useEffect(() => {
    if (opened) setOpen(opened)
  }, [opened])

  useEffect(() => {
    const timer =
      isOpen &&
      window.setTimeout(() => {
        setShowFullscreenAlertMessage(false)
      }, 5000)
    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [isOpen])

  return (
    <OnboardingModal isOpen={isOpen} ariaLabelledby="onboardingmodal-title">
      <StyledAppBar>
        <Toolbar>
          <StyledLogo>
            <img alt={settings.theme.header.title} src={localizedImage} />
          </StyledLogo>
          <StyledTitle id="onboardingmodal-title">
            <FormattedMessage id={title} />
          </StyledTitle>
          <Button
            id="next-step"
            variant="contained"
            color="secondary"
            onClick={() => onNext && onNext()}
          >
            {userUpdating ? (
              <Loading color="inherit" />
            ) : (
              <FormattedMessage
                id={finishStep ? 'onboarding.step.finish' : 'onboarding.step2.next'}
              />
            )}
          </Button>
        </Toolbar>
      </StyledAppBar>
      <OnboardingStepper step={step} total={total} />
      <StyledContent>
        {showFullscreenAlertMessage && (
          <StyledMessageContainer>
            <StyledMessage>
              <FormattedMessage id={'onboarding.profiling.showProfilingFullscreenMessage'} />
            </StyledMessage>
            <Loading color="inherit" size="3rem" position="center" />
          </StyledMessageContainer>
        )}
        {!showFullscreenAlertMessage && (
          <ProfilingIFrame
            type={PROFILINGS.USER}
            onComplete={() => onNext && onNext()}
            fullscreen
            autostart
          />
        )}
      </StyledContent>
    </OnboardingModal>
  )
}

const StyledAppBar = styled(AppBar)`
  position: relative;
`

const StyledLogo = styled.div`
  img {
    height: 50px;
    display: block;
    margin-left: 1em;
  }
`

const StyledTitle = styled(Typography)`
  margin-left: 1em;
  flex: 1;
  font-size: 20px;
`

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;
  height: 100%;
  & > div {
    max-width: 600px;
    width: 100%;
  }
`
const StyledMessageContainer = styled.div`
  & {
    display: block;
  }
`

const StyledMessage = styled.h3`
  & {
    margin-top: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }
`

export default OnboardingProfiling
