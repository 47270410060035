import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import AppLoading from '../components/ui/AppLoading/AppLoading'
import { IRootState } from '../redux'

/**
 * Route that redirect to login page if user is not connected
 */
const PrivateRoute: React.FC<RouteProps> = ({ ...rest }) => {
  const token = useSelector((state: IRootState) => state.authState.token)
  const loading = useSelector((state: IRootState) => state.authState.loading)
  if (loading) return <AppLoading />
  return token ? <Route {...rest} /> : <Redirect to={{ pathname: '/login' }} />
}

export default PrivateRoute
