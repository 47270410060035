import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Button, IconButton, MenuItem, Switch, TextField } from '@material-ui/core'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgAnnouncement } from '../../assets/images/icons/announcement.svg'
import { NotificationType } from '../../entities'
import { useMagicStream, useThunkDispatch, useTooltip } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { doAnnounce, doPremiumAnnounce, setErrorSending, setSended } from '../../store/chat'
import { PRIMARY_COLOR } from '../../theme'
import { WHITE } from '../../theme/colors'
import { getPlainText } from '../../utils'
import { breakpoints } from '../breakpoints'
import SendDialog from '../modals/SendDialog'
import Editor from '../ui/Editor/Editor'
import FormContainer from '../ui/Form/FormContainer'
import { Label } from '../ui/LabelWrapper/LabelWrapper'

type IAnnounceProps = {
  groupId: number | string
  displayIcon?: boolean
  menuMode?: boolean
  premium?: boolean
  quota?: number
  onEnd?: () => void
}

const Announce: React.FC<IAnnounceProps> = ({ groupId, displayIcon, menuMode, premium, quota, onEnd }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [message, setMessage] = useState('')
  const [localGroupId, setLocalGroupId] = useState<string>(groupId || settings.eventGroupId)
  const [urlVideo, setUrlVideo] = useState<string | undefined>()
  const [showAnnounce, setShowAnnounce] = useState(false)
  const [isAnnounceStored, setIsAnnounceStored] = useState(false)
  const [isHighDisplay, setIsHighDisplay] = useState(false)
  const [currentQuota, setCurrentQuota] = useState(quota)
  const user = useSelector((state: IRootState) => state.appState.user)
  const sending = useSelector((state: IRootState) => state.chatState.sending)
  const sended = useSelector((state: IRootState) => state.chatState.sended)
  const errorSending = useSelector((state: IRootState) => state.chatState.errorSending)
  const { anchorRef, setOpen, Tooltip } = useTooltip(
    intl.formatMessage({
      id: premium ? 'button.premium.announce' : 'button.global.announce'
    })
  )
  const dispatch = useThunkDispatch()

  const handleDoAnnounce = () => {
    sendDataToGTM({ event: 'click-header-announce', label: 'Toggle announce' })
    setShowAnnounce(true)
  }

  const send = () => {
    if (user && groupId) {
      sendDataToGTM({ event: 'send-announce', announce: getPlainText(message) })
      if (premium || isAnnounceStored) {
        dispatch(
          doPremiumAnnounce(
            localGroupId || groupId,
            message,
            {
              // user
              id: user.id,
              firstname: user.firstname,
              lastname: user.lastname,
              avatarPath: user.avatarPath
            },
            urlVideo,
            isAnnounceStored,
            isHighDisplay
          )
        )
      } else {
        dispatch(
          doAnnounce(
            localGroupId || groupId,
            message,
            groupId === settings.eventGroupId
              ? NotificationType.GLOBAL_ANNOUNCE
              : NotificationType.PAGE_ANNOUNCE,
            {
              // user
              id: user.id,
              firstname: user.firstname,
              lastname: user.lastname,
              avatarPath: user.avatarPath
            },
            urlVideo,
            isHighDisplay ? 'high' : 'low'
          )
        )
      }
    }
  }

  const onClose = useCallback(() => {
    setShowAnnounce(false)
    onEnd && onEnd()
  }, [onEnd])

  useEffect(() => {
    if (sended && showAnnounce) {
      setMessage('')
      setUrlVideo(undefined)
      dispatch(setSended(false))
      onClose()
      // magicStream.success(
      //   intl.formatMessage({ id: premium ? 'success.premium.announce' : 'success.announce' })
      // )
      if (currentQuota !== undefined && currentQuota > 0) {
        setCurrentQuota(currentQuota - 1)
      }
    }
  }, [intl, magicStream, sended, showAnnounce, currentQuota, premium, onClose, dispatch])

  useEffect(() => {
    if (errorSending && showAnnounce) {
      magicStream.error(errorSending)
      dispatch(setErrorSending(undefined))
    }
  }, [errorSending, showAnnounce, magicStream, dispatch])

  return (
    <StyledDiv displayIcon={displayIcon}>
      {menuMode && !displayIcon && (
        <MenuItem onClick={handleDoAnnounce}>
          <FormattedMessage id={premium ? 'button.premium.announce' : 'button.announce'} />
        </MenuItem>
      )}
      {displayIcon && !menuMode && (
        <StyledIconButton
          ref={anchorRef}
          color="primary"
          onClick={handleDoAnnounce}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
          edge="end"
        >
          <SvgAnnouncement />
          <Tooltip />
        </StyledIconButton>
      )}
      {!displayIcon && !menuMode && (
        <StyledButton
          variant="contained"
          startIcon={<SvgAnnouncement />}
          color="secondary"
          onClick={handleDoAnnounce}
        >
          <FormattedMessage id={premium ? 'button.premium.announce' : 'button.announce'} />
        </StyledButton>
      )}
      <SendDialog
        title={intl.formatMessage({ id: premium ? 'button.premium.announce' : 'button.announce' })}
        open={showAnnounce}
        disableSend={!message || sending || currentQuota === 0}
        sending={sending}
        sendEvent={send}
        cancelEvent={onClose}
        maxWidth="sm"
        fullWidth
      >
        <FormContainer>
          <StyledContent>
            {currentQuota !== undefined && (
              <StyledQuota>
                <FormattedMessage id="announce.quota" values={{ quota: currentQuota }} />
              </StyledQuota>
            )}
            <Label>
              <FormattedMessage id="announce.title" />
            </Label>
            <Editor
              content={message}
              readonly={sending}
              placeholder={intl.formatMessage({ id: 'announce.placeholder' })}
              onContentChange={setMessage}
              startupFocus
            />
            <TextField
              id="urlVideo"
              type="url"
              label={<FormattedMessage id="announce.url" />}
              value={urlVideo || ''}
              helperText={intl.formatMessage({
                id:
                  premium || groupId === settings.eventGroupId
                    ? 'announce.placeholder.url'
                    : 'announce.placeholder.urlVideo'
              })}
              fullWidth
              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setUrlVideo(event.target.value)
              }}
              InputLabelProps={{ shrink: false, disableAnimation: true }}
            />
            {!premium && groupId === settings.eventGroupId && (
              <SyledAdminContainer>
                <TextField
                  id="localGroupId"
                  label={<FormattedMessage id="announce.groupId" />}
                  value={localGroupId || settings.eventGroupId}
                  helperText={intl.formatMessage(
                    { id: 'announce.groupId.helperText' },
                    { default: settings.eventGroupId }
                  )}
                  fullWidth
                  onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setLocalGroupId(event.target.value)
                  }}
                  InputLabelProps={{ shrink: false, disableAnimation: true }}
                />
                <SyledToggleContainer>
                  <>
                    <Label id="is-announce-stored-label">
                      <FormattedMessage id="is.announce.stored" />
                    </Label>
                    <Switch
                      aria-labelledby="is-announce-stored-label"
                      checked={isAnnounceStored}
                      color="primary"
                      title={intl.formatMessage({
                        id: isAnnounceStored ? 'is.announce.stored' : 'not.announce.stored'
                      })}
                      onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        setIsAnnounceStored(checked)
                      }}
                      name="isAnnounceStored"
                    ></Switch>
                  </>
                  <>
                    <Label id="is-high-priority-label">
                      <FormattedMessage id="is.high.display" />
                    </Label>
                    <Switch
                      aria-labelledby="is-high-priority-label"
                      checked={isHighDisplay}
                      color="primary"
                      title={intl.formatMessage({
                        id: isAnnounceStored ? 'is.high.display' : 'not.high.display'
                      })}
                      onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        setIsHighDisplay(checked)
                      }}
                      name="isHighDisplay"
                    ></Switch>
                  </>
                </SyledToggleContainer>
              </SyledAdminContainer>
            )}
          </StyledContent>
        </FormContainer>
      </SendDialog>
    </StyledDiv>
  )
}

const StyledDiv = styled.div<{ displayIcon?: boolean }>`
  & {
    margin-bottom: ${(props) => (props.displayIcon ? '0' : '10px')};
  }
`

const StyledIconButton = styled(IconButton)`
  svg {
    height: 35px;
    width: 35px;
    color: ${PRIMARY_COLOR};
  }
`

const StyledButton = styled(Button)`
  & {
    text-align: left;
    text-transform: uppercase;

    svg {
      height: 20px;
      width: 20px;
      color: ${WHITE};
    }
  }
`

const StyledContent = styled.div`
  position: relative;
`

const StyledQuota = styled.div`
  position: absolute;
  top: -25px;
  right: 10px;
`

const SyledAdminContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SyledToggleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`

export default Announce
