import { IAuthResponse } from '../../entities/auth'
import { ActionsTypes } from './model'

export const setToken = (auth: IAuthResponse) => ({ type: ActionsTypes.SET_TOKEN, auth })

export const resetToken = () => ({ type: ActionsTypes.RESET_TOKEN })

export const setLoading = (loading: boolean) => ({ type: ActionsTypes.SET_AUTH_LOADING, loading })

export const setAuthError = (error: string = 'Auth API error') => ({
  type: ActionsTypes.SET_AUTH_ERROR,
  error
})
