import IStorageAccess from '../entities/storageAccess'

const asyncLocalStorage: IStorageAccess = {
  getItem(key) {
    return Promise.resolve(localStorage.getItem(key))
  },
  setItem(key, value) {
    return Promise.resolve(localStorage.setItem(key, value))
  },
  removeItem(key) {
    return Promise.resolve(localStorage.removeItem(key))
  },
  clear() {
    return Promise.resolve(localStorage.clear())
  }
}

export default asyncLocalStorage
