import useGTM from '@elgorditosalsero/react-gtm-hook'
import { createTheme, CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { ConnectedRouter } from 'connected-react-router'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { IntlProvider } from 'react-intl'
import { Provider, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ConnectedSnackBar from './components/globals/ConnectedSnackBar'
import { IEventCo, LanguageKeys } from './entities'
import WithLoggedInUserData from './HOC/WithLoggedInUserData'
import ConferenceListPage from './pages/ConferenceListPage/ConferenceListPage'
import ConferencePage from './pages/ConferencePage/ConferencePage'
import ErrorPage from './pages/ErrorPage/ErrorPage'
import ExhibitorListPage from './pages/ExhibitorListPage/ExhibitorListPage'
import ExhibitorPage from './pages/ExhibitorPage/ExhibitorPage'
import HomePage from './pages/HomePage/HomePage'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import ProductListPage from './pages/ProductListPage/ProductListPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import VisitorListPage from './pages/VisitorListPage/VisitorListPage'
import store, { browserHistory, IRootState } from './redux'
import settings from './settings'
import { setLocale, StorageKeys } from './store/app'
import { readTokenFromStorage, simulateLogin } from './store/auth'
import { resolveEvent } from './store/settings/thunks'
import './styles/global.scss'
import Layout from './templates/Layout/Layout'
import PrivateRoute from './templates/PrivateRoute'
import { Theme } from './theme'
import { IMessages } from './translations'
import asyncLocalStorage from './utils/asyncLocalstorage'

window.version = '1.2.2'
window.images = {}

const customTheme = createTheme(Theme)

const ConnectedIntlProvider: FC = (props) => {
  const locale = useSelector((state: IRootState) => state.appState.locale)
  const eventLanguages = (settings.eventLanguages as string).split(',')
  if (eventLanguages.length > 0 && !eventLanguages.includes(locale)) {
    store.dispatch(setLocale(eventLanguages[0] as LanguageKeys))
  }
  const locales = settings.theme.locales || 'locales'
  const messages: IMessages = {
    en: require(`./translations/${locales}/en.json`),
    fr: require(`./translations/${locales}/fr.json`)
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {props.children}
    </IntlProvider>
  )
}

const App: React.FC = () => {
  const { init, UseGTMHookProvider } = useGTM()
  const [redirectHref, setRedirectHref] = useState(window._env_.REACT_APP_ENDPOINT)
  const [tokenReady, setTokenReady] = useState(false)
  const [currentEventCo, setCurrentEventCo] = useState<IEventCo | null>(null)
  const [exhibitorId, setExhibitorId] = useState(0)
  const [eventId, setEventId] = useState(0)
  const [selectedLocale, setSelectedLocale] = useState<LanguageKeys | null>(null)

  const localizedImage = useMemo(() => {
    let image = settings.theme.header.image
    if (selectedLocale === LanguageKeys.en && settings.theme.header.image_en) {
      image = settings.theme.header.image_en
    }
    return image
  }, [selectedLocale])

  useEffect(() => {
    setRedirectHref(window.location.href)
    simulateLogin(asyncLocalStorage, (userId) => {
      const gtmParams = {
        id: settings.gtmId,
        dataLayer: {
          env: `${settings.prod ? 'prod' : 'dev'}`,
          userId: `${userId}`,
          pageReferral: `direct`,
          project: `${settings.theme.name}`
        }
      }
      init(gtmParams)
    })(store.dispatch)
  }, [init])

  useEffect(() => {
    readTokenFromStorage(asyncLocalStorage)(store.dispatch).then(() => {
      setTokenReady(true)
    })
  }, [])

  useEffect(() => {
    // load event infos
    resolveEvent()(store.dispatch).then((eventCo: IEventCo) => {
      setCurrentEventCo(eventCo)
      // console.log('eventCo=', eventCo)
      if (eventCo && eventCo.eventcoAppointment) {
        // ?magicstream=true
        browserHistory.replace(`/participant${window.location.search}`)
      } else if (eventCo && eventCo.eventcoRemote && eventCo.eventcoRemoteExhibitorId) {
        setExhibitorId(eventCo.eventcoRemoteExhibitorId.id)
        browserHistory.replace(
          `/exposant/${eventCo.eventcoRemoteExhibitorId.id}${window.location.search}`
        )
      } else if (eventCo && eventCo.eventcoRemote && eventCo.eventcoRemoteEventId) {
        setEventId(eventCo.eventcoRemoteEventId.id)
        browserHistory.replace(
          `/conference/${eventCo.eventcoRemoteEventId.id}${window.location.search}`
        )
      }
    })
  }, [])

  useEffect(() => {
    const locale = localStorage.getItem('locale')

    if (locale) {
      store.dispatch(setLocale(locale as LanguageKeys))
      setSelectedLocale(locale as LanguageKeys)
    } else {
      const { appState } = store.getState()
      const eventLanguages = (settings.eventLanguages as string).split(',')
      if (
        appState.user?.dataUser?.preferredLanguage &&
        eventLanguages.length > 0 &&
        eventLanguages.includes(appState.user.dataUser.preferredLanguage)
      ) {
        // set locale from preferredLanguage only if language is configurated
        store.dispatch(
          setLocale(
            (localStorage.getItem(StorageKeys.PREFERED_LANGUAGE) as LanguageKeys) || LanguageKeys.fr
          )
        )
        setSelectedLocale(
          (localStorage.getItem(StorageKeys.PREFERED_LANGUAGE) as LanguageKeys) || LanguageKeys.fr
        )
        /*navigator.language.split(/[-_]/)[0] as LanguageKeys*/
      }
    }
  }, [])

  return (
    <Provider store={store}>
      <ConnectedIntlProvider>
        <UseGTMHookProvider>
          <ThemeProvider theme={customTheme}>
            <CssBaseline />
            <ConnectedRouter history={browserHistory}>
              {tokenReady && (
                <Switch>
                  <Route
                    path="/login"
                    exact
                    //  component={LoginPage}
                    render={() =>
                    (window.location.href = settings.url.login_page.replace(
                      '{target_path}',
                      redirectHref
                    ))
                    }
                  />
                  <Route path="/erreur" component={ErrorPage} />
                  <PrivateRoute path="/">
                    <WithLoggedInUserData>
                      <Layout>
                        {window.lightTheme ? (
                          <>
                            {currentEventCo?.eventcoAppointment ? (
                              <Switch>
                                <Redirect from="/" to="/participant" exact push />
                                <Route path="/participant" component={VisitorListPage} />
                                <Route path="/mes-infos" component={ProfilePage} />
                                <Route component={NotFoundPage} />
                              </Switch>
                            ) : currentEventCo?.eventcoRemoteExhibitorId ? (
                              <Switch>
                                <Redirect from="/" to={`/exposant/${exhibitorId}`} exact push />
                                <Route path="/exposant/:id" component={ExhibitorPage} />
                                <Route path="/mes-infos" component={ProfilePage} />
                                <Route path="/produit" component={ProductListPage} />
                                <Route component={NotFoundPage} />
                              </Switch>
                            ) : (
                              <Switch>
                                <Redirect from="/" to={`/conference/${eventId}`} exact push />
                                <Route path="/conference/:id" component={ConferencePage} />
                                <Route path="/mes-infos" component={ProfilePage} />
                                <Route component={NotFoundPage} />
                              </Switch>
                            )}
                          </>
                        ) : (
                          <Switch>
                            <Redirect from="/" to="/accueil" exact push />
                            <Route path="/accueil" component={HomePage} />
                            <Route path="/participant" component={VisitorListPage} />
                            <Route path="/conference/:id" component={ConferencePage} />
                            <Route path="/conference" component={ConferenceListPage} />
                            <Route path="/exposant/:id" component={ExhibitorPage} />
                            <Route path="/exposant" component={ExhibitorListPage} />
                            <Route path="/produit" component={ProductListPage} />
                            <Route path="/mes-infos" component={ProfilePage} />
                            <Route component={NotFoundPage} />
                          </Switch>
                        )}
                      </Layout>
                    </WithLoggedInUserData>
                  </PrivateRoute>
                </Switch>
              )}
            </ConnectedRouter>
            <ConnectedSnackBar />
            <ToastContainer
              position={toast.POSITION.TOP_RIGHT}
              autoClose={settings.toastify.duration}
            />
            <Helmet>
              <title>{settings.theme.header.title}</title>
              <meta name="description" content={settings.theme.header.description} />
              <meta property="og:title" content={settings.theme.header.title} />
              <meta property="og:description" content={settings.theme.header.description} />
              <meta property="og:image" content={localizedImage} />
              <meta property="og:url" content={settings.theme.header.url} />
            </Helmet>
          </ThemeProvider>
        </UseGTMHookProvider>
      </ConnectedIntlProvider>
    </Provider>
  )
}

export default App
