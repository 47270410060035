import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import {
  alpha,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRoom } from '../../../entities'
import { useMagicStream, useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import { sendInvitation } from '../../../store/chat'
import { loadRoomQueue, refuseRoomQueue, setErrorRoomQueue } from '../../../store/room'
import { BACKGROUND_SECONDARY_LIGHT, BLACK_BLUE } from '../../../theme/colors'
import { breakpoints } from '../../breakpoints'
import Loading from '../../Loading'
import UserAvatar from '../UserAvatar/UserAvatar'

export interface IVideoRoomWaitingListProps {
  room: IRoom
}

const VideoRoomWaitingList: React.FC<IVideoRoomWaitingListProps> = ({ room }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [sendedId, setSendedId] = useState<{ [key: string]: boolean }>({})
  const currentLocation = useSelector((state: IRootState) => state.appState.location)
  const sending = useSelector((state: IRootState) => state.chatState.sending)
  const roomQueue = useSelector((state: IRootState) => state.roomState.roomQueue)
  const loadingRoomQueue = useSelector((state: IRootState) => state.roomState.loadingRoomQueue)
  const errorRoomQueue = useSelector((state: IRootState) => state.roomState.errorRoomQueue)
  const dispatch = useThunkDispatch()

  const handleNextPerson = (userId: number, roomQueueId: string) => {
    if (userId) {
      dispatch(
        sendInvitation(
          userId,
          intl.formatMessage({ id: 'invitation.sended' }),
          currentLocation,
          room.id
        )
      )
      setSendedId({ ...sendedId, [roomQueueId]: true })
      sendDataToGTM({
        event: 'invite-contact-table-locked',
        roomTitle: room.title
      })
    }
  }

  const handleRefusePerson = (roomQueueId: string) => {
    dispatch(refuseRoomQueue(roomQueueId))
    setSendedId({ ...sendedId, [roomQueueId]: true })
  }

  useEffect(() => {
    if (room) {
      dispatch(loadRoomQueue(room.id))
    }
  }, [room, dispatch])

  useEffect(() => {
    if (errorRoomQueue) {
      magicStream.error(errorRoomQueue)
      dispatch(setErrorRoomQueue(undefined))
    }
  }, [errorRoomQueue, magicStream, dispatch])

  return (
    <RoomWaiting>
      <h4>
        <FormattedMessage id="room.waiting.title" />
      </h4>
      <StyledList>
        {roomQueue &&
          roomQueue.items.map((item, itemIndex) => (
            <ListItem key={itemIndex}>
              <ListItemAvatar>
                <UserAvatar user={item.user} popper={{ placement: 'left' }} showDetails />
              </ListItemAvatar>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="next-person"
                  disabled={sending || sendedId[item.id]}
                  onClick={() => handleNextPerson(item.user.id, item.id)}
                >
                  <QueuePlayNextIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="refuse-person"
                  disabled={sending || sendedId[item.id]}
                  onClick={() => handleRefusePerson(item.id)}
                >
                  <MeetingRoomIcon />
                </IconButton>
              </ListItemSecondaryAction>
              {sendedId[item.id] && <ListItemText secondary={intl.formatMessage({ id: 'sended' })} />}
            </ListItem>
          ))}
        {!loadingRoomQueue && (!roomQueue || roomQueue.items.length === 0) && (
          <EmptyMessage>
            <FormattedMessage id="room.waiting.empty" />
          </EmptyMessage>
        )}
        {loadingRoomQueue && <Loading position="center" />}
      </StyledList>
    </RoomWaiting>
  )
}

const StyledList = styled(List)`
  position: relative;
  padding: 2px;

  & .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const RoomWaiting = styled.div`
  flex-basis: 150px;
  @media (min-width: ${breakpoints.md}) {
    flex-basis: 100px;
  }
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px ${alpha(BLACK_BLUE, 0.14)};
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${BACKGROUND_SECONDARY_LIGHT};
  }
`

const EmptyMessage = styled.div`
  text-align: center;
`

export default VideoRoomWaitingList
