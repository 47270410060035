import styled from '@emotion/styled'
import { alpha, IconButton } from '@material-ui/core'
import CallEndIcon from '@material-ui/icons/CallEnd'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import React from 'react'
import { FullScreenHandle } from 'react-full-screen'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgFullScreen } from '../../../../assets/images/icons/expand.svg'
import { ReactComponent as SvgFullScreenExit } from '../../../../assets/images/icons/reduce.svg'
import { useThunkDispatch, useTooltip } from '../../../../hooks'
import { IRootState } from '../../../../redux'
import { setFullscreen } from '../../../../store/stand'
import { PRIMARY_COLOR } from '../../../../theme'
import { BLACK_BLUE, RED, WHITE } from '../../../../theme/colors'
import { BORDER_RADIUS_CARD, CONTACTS_MENU_WIDTH } from '../../../../theme/sizes'

interface IJitsiActionsProps {
  viewer?: boolean
  handlePublishVideo: () => void
  publishVideo: boolean
  handlePublishAudio: () => void
  publishAudio: boolean
  handleLeave?: () => void
  handleFullscreen?: FullScreenHandle
}

const JitsiActions: React.FC<IJitsiActionsProps> = ({
  viewer,
  handlePublishVideo,
  publishVideo,
  handlePublishAudio,
  publishAudio,
  handleLeave,
  handleFullscreen
}) => {
  const intl = useIntl()
  const fullscreen = useSelector((state: IRootState) => state.standState.fullscreen)
  const {
    anchorRef: anchorRefFullscreen,
    setOpen: setOpenFullscreen,
    Tooltip: TooltipFullscreen
  } = useTooltip(
    intl.formatMessage({
      id: fullscreen ? 'actions.fullscreen.exit' : 'actions.fullscreen.enter'
    })
  )
  const { anchorRef: anchorRefVideo, setOpen: setOpenVideo, Tooltip: TooltipVideo } = useTooltip(
    intl.formatMessage({
      id: publishVideo ? 'actions.video.disable' : 'actions.video.enable'
    })
  )
  const { anchorRef: anchorRefAudio, setOpen: setOpenAudio, Tooltip: TooltipAudio } = useTooltip(
    intl.formatMessage({
      id: publishAudio ? 'actions.audio.disable' : 'actions.audio.enable'
    })
  )
  const { anchorRef: anchorRefHangup, setOpen: setOpenHangup, Tooltip: TooltipHangup } = useTooltip(
    intl.formatMessage({
      id: 'actions.hangup'
    })
  )
  const dispatch = useThunkDispatch()

  const toggleFullscreen = () => {
    dispatch(setFullscreen(!fullscreen))
    if (handleFullscreen) {
      if (fullscreen) {
        handleFullscreen.exit()
      } else {
        handleFullscreen.enter()
      }
    }
  }

  return (
    <StyledJitsiActions fullscreen={fullscreen}>
      <ToolbarActions>
        <ActionsContainer>
          <Actions>
            {!viewer && (
              <>
                <IconButton
                  ref={anchorRefVideo}
                  onMouseOver={() => setOpenVideo(true)}
                  onMouseOut={() => setOpenVideo(false)}
                  onClick={handlePublishVideo}
                >
                  {publishVideo ? (
                    <VideocamIcon
                      style={{ color: `${PRIMARY_COLOR}` }}
                      fontSize="large"
                      titleAccess={intl.formatMessage({
                        id: 'titleAccess.VideocamIcon'
                      })}
                    />
                  ) : (
                    <VideocamOffIcon
                      fontSize="large"
                      titleAccess={intl.formatMessage({
                        id: 'titleAccess.VideocamOffIcon'
                      })}
                    />
                  )}
                </IconButton>
                <IconButton
                  ref={anchorRefAudio}
                  onMouseOver={() => setOpenAudio(true)}
                  onMouseOut={() => setOpenAudio(false)}
                  onClick={handlePublishAudio}
                >
                  {publishAudio ? (
                    <MicIcon
                      style={{ color: `${PRIMARY_COLOR}` }}
                      fontSize="large"
                      titleAccess={intl.formatMessage({
                        id: 'titleAccess.MicIcon'
                      })}
                    />
                  ) : (
                    <MicOffIcon
                      fontSize="large"
                      titleAccess={intl.formatMessage({
                        id: 'titleAccess.MicOffIcon'
                      })}
                    />
                  )}
                </IconButton>
              </>
            )}
            <IconButton
              ref={anchorRefFullscreen}
              onMouseOver={() => setOpenFullscreen(true)}
              onMouseOut={() => setOpenFullscreen(false)}
              onClick={toggleFullscreen}
            >
              {fullscreen ? <SvgFullScreenExit /> : <SvgFullScreen />}
              <TooltipFullscreen />
            </IconButton>
          </Actions>
        </ActionsContainer>
        {handleLeave && (
          <HangUpContainer>
            <IconButton
              ref={anchorRefHangup}
              onMouseOver={() => setOpenHangup(true)}
              onMouseOut={() => setOpenHangup(false)}
              onClick={handleLeave}
            >
              <CallEndIcon
                fontSize="large"
                titleAccess={intl.formatMessage({
                  id: 'titleAccess.CallEndIcon'
                })}
              />
              <TooltipHangup />
            </IconButton>
          </HangUpContainer>
        )}
        <TooltipAudio />
        <TooltipVideo />
      </ToolbarActions>
    </StyledJitsiActions>
  )
}

const StyledJitsiActions = styled.div<{ fullscreen: boolean }>`
  position: fixed;
  z-index: 1500;
  bottom: 10px;
  ${(props) => (props.fullscreen ? 'right: 215px;' : `right: calc(${CONTACTS_MENU_WIDTH} + 35px)`)};
  @media (max-width: 675px) {
    right: 120px;
  }
  @media (max-width: 375px) {
    right: 70px;
  }
  display: flex;
  flex-direction: column;
`

const ToolbarActions = styled.div`
  display: flex;
  flex-direction: row;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 300px;
  background-color: ${WHITE};
  box-shadow: 0 0 30px 0 ${alpha(BLACK_BLUE, 0.14)};
  padding: 10px;
  border-radius: ${BORDER_RADIUS_CARD};
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const HangUpContainer = styled.div`
  background-color: ${RED};
  box-shadow: 0 0 30px 0 ${alpha(BLACK_BLUE, 0.14)};
  padding: 10px;
  margin-left: 10px;
  border-radius: ${BORDER_RADIUS_CARD};

  & .MuiSvgIcon-root {
    fill: ${WHITE};
  }
`

export default JitsiActions
