import React from 'react'

const CheckCircle: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 148 148" {...props}>
    <g fill={props.color || 'currentColor'} fillRule="evenodd" transform="translate(-19 -19)">
      <circle
        fill={props.color || 'currentColor'}
        cx="97"
        cy="97"
        r="70"
        transform="rotate(-159 97 97)"
        style={{ fillOpacity: 0.1 }}
      />
      <path
        stroke={props.color || 'currentColor'}
        strokeDasharray="351"
        strokeLinecap="round"
        strokeWidth="4"
        fill="transparent"
        d="M116.086 25.65c-36.092-13.855-76.582 4.172-90.437 40.264-13.854 36.092 4.173 76.582 40.265 90.437 36.092 13.854 76.582-4.173 90.437-40.265 13.854-36.092-4.173-76.582-40.265-90.437z"
      />
      <path
        stroke={props.color || 'currentColor'}
        fill="transparent"
        strokeDasharray="10,400"
        strokeLinecap="round"
        strokeWidth="4"
        d="M116.086 25.65c-36.092-13.855-76.582 4.172-90.437 40.264-13.854 36.092 4.173 76.582 40.265 90.437 36.092 13.854 76.582-4.173 90.437-40.265 13.854-36.092-4.173-76.582-40.265-90.437z"
      />
      <path
        fill={props.color || 'currentColor'}
        d="M116.902 76.228a3.774 3.774 0 0 0-5.335-.273L84.192 100.71 72.604 87.898a3.774 3.774 0 0 0-5.593 5.066L81.13 108.58a3.774 3.774 0 0 0 5.31.25l30.208-27.34a3.774 3.774 0 0 0 .254-5.262z"
      />
    </g>
  </svg>
)

export default CheckCircle
