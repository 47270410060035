import styled from '@emotion/styled'
import { Button, FormControl, MenuItem, TextField } from '@material-ui/core'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import EditDialog from '../modals/EditDialog'
import FormContainer from '../ui/Form/FormContainer'
import { Label } from '../ui/LabelWrapper/LabelWrapper'

interface IVimeoIdProps {
  menuMode?: boolean
  url?: string
  onChange: (value: string | undefined) => void
}

const VimeoId: React.FC<IVimeoIdProps> = ({ menuMode, url, onChange }) => {
  const intl = useIntl()
  const [vimeoId, setVimeoId] = useState<string>()
  const [openVimeoId, setOpenVimeoId] = useState(false)

  const handleUpdateVimeoId = () => {
    setOpenVimeoId(true)
  }

  const updateVimeoId = () => {
    setOpenVimeoId(false)
    onChange && onChange(vimeoId)
  }

  useEffect(() => {
    if (url) setVimeoId(url)
  }, [url])

  return (
    <StyledDiv menumode={menuMode}>
      {menuMode && (
        <MenuItem onClick={handleUpdateVimeoId}>
          <FormattedMessage id={url ? 'button.vimeoId.edit' : 'button.vimeoId.add'} />
        </MenuItem>
      )}
      {!menuMode && (
        <StyledButton variant="contained" color="secondary" onClick={handleUpdateVimeoId}>
          <FormattedMessage id={url ? 'button.vimeoId.edit' : 'button.vimeoId.add'} />
        </StyledButton>
      )}
      <EditDialog
        title={intl.formatMessage({ id: url ? 'button.vimeoId.edit' : 'button.vimeoId.add' })}
        open={openVimeoId}
        saveEvent={updateVimeoId}
        cancelEvent={() => setOpenVimeoId(false)}
        maxWidth="sm"
        fullWidth
      >
        <FormContainer>
          <FormControl>
            <Label htmlFor="video_url" id="video_url_id_label">
              <FormattedMessage id="vimeoId" />
            </Label>
            <TextField
              id="video_url"
              aria-labelledby="video_url_id_label"
              value={vimeoId || ''}
              placeholder={intl.formatMessage({ id: 'vimeoId.placeholder' })}
              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setVimeoId(event.target.value)
              }}
            />
          </FormControl>
        </FormContainer>
      </EditDialog>
    </StyledDiv>
  )
}

const StyledDiv = styled.div<{ menumode?: boolean }>`
margin-bottom: ${props => props.menumode ? '0' : '10px'};
`

const StyledButton = styled(Button)`
  text-transform: uppercase;
`

export default VimeoId
