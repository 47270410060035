import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { FullScreenHandle } from 'react-full-screen'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { LanguageKeys } from '../../../../entities'
import { IRootState } from '../../../../redux'
import settings from '../../../../settings'
import { formatName } from '../../../../utils'
import ZoomActions from './ZoomActions'

interface IZoomIFrame {
  webinar?: boolean
  handleFullscreen?: FullScreenHandle
  handleLeave?: () => void
}

const ZoomIFrame: React.FC<IZoomIFrame> = ({ webinar, handleFullscreen, handleLeave }) => {
  const locale = useSelector((state: IRootState) => state.appState.locale)
  const user = useSelector((state: IRootState) => state.appState.user)
  const loadingZoom = useSelector((state: IRootState) => state.zoomState.loadingZoom)
  const zoomRoom = useSelector((state: IRootState) => state.zoomState.zoomRoom)

  const zoomPath = useMemo(
    () =>
      user && zoomRoom
        ? `/zoom.html?webinar=${webinar}&key=${settings.zoom.apiKey}&name=${formatName(user).full
        }&email=${user?.email}&number=${zoomRoom.externalMeetingId}&pass=${zoomRoom.password
        }&lang=${locale === LanguageKeys.fr ? 'fr-FR' : 'en-US'}&signature=${zoomRoom.token
        }&url=${window.location.protocol}//${window.location.host}/erreur`
        : null,
    [user, zoomRoom, locale, webinar]
  )

  if (loadingZoom || !zoomPath) {
    return (
      <Center>
        <FormattedMessage id="zoom.loading" />
      </Center>
    )
  }

  return (
    <>
      <StyledIFrame
        src={zoomPath}
        frameBorder="0"
        allow="camera; microphone; autoplay; fullscreen; display-capture; autoplay"
        allowFullScreen
      ></StyledIFrame>
      <ZoomActions
        zoomRoom={zoomRoom}
        handleFullscreen={handleFullscreen}
        handleLeave={handleLeave}
      />
    </>
  )
}

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`

export default ZoomIFrame
