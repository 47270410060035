import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { breakpoints } from '../../components/breakpoints'
import ProductCard from '../../components/cards/ProductCard'
import AppPagination from '../../components/globals/AppPagination/AppPagination'
import LoadingCard from '../../components/LoadingCard'
import ProductFiltersSearch from '../../components/product/ProductFiltersSearch'
import Title from '../../components/ui/Title/Title'
import { useEffectOnce, useMagicStream, usePagination, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setPageReferral } from '../../store/gtm'
import { getProducts, setProductError, setProductsSearch } from '../../store/product'

const ProductListPage = () => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const { sendDataToGTM } = useGTM()
  const location = useLocation()
  const { count, page, setPage } = usePagination(
    parseInt(new URLSearchParams(location.search).get('page') || '1', 10)
  )
  const [filterSearch, setFilterSearch] = useState(
    new URLSearchParams(location.search).get('search') || ''
  )
  const [exhibitorId] = useState(new URLSearchParams(location.search).get('exhibitorId') || '')
  const [filterCustom, setFilterCustom] = useState<{
    [key: string]: number | string | { id: number; name: string }
  }>({})
  const products = useSelector((state: IRootState) => state.productState.products)
  const searchProducts = useSelector((state: IRootState) => state.productState.searchProducts)
  const errorProducts = useSelector((state: IRootState) => state.productState.error)
  const loading = useSelector((state: IRootState) => state.productState.loading)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const dispatch = useThunkDispatch()

  // gtm DATA
  useEffectOnce(() => {
    const title = intl.formatMessage({ id: 'gtm.listProducts.title' })
    dispatch(setPageReferral(title))
    sendDataToGTM({
      event: 'page-view',
      pageTitle: title,
      pageType: intl.formatMessage({ id: 'gtm.listProducts.type' }),
      pageReferral
    })
  })

  useEffect(() => {
    const search = `?page=${page}&search=${filterSearch}&exhibitorId=${exhibitorId}`
    // refresh url only if search as changed and is different of location
    if (searchProducts !== search && search !== location.search) {
      browserHistory.replace(`/produit${search}`)
    }
  }, [filterSearch, page, exhibitorId, searchProducts, location])

  // read url location to load data
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if ((location.search || searchProducts) && searchProducts !== location.search) {
        const locationSearch = new URLSearchParams(location.search)
        const currentPage = parseInt(locationSearch.get('page') || '1', 10)
        setPage(currentPage)
        const currentSearch = locationSearch.get('search') || ''
        setFilterSearch(currentSearch)
        dispatch(
          getProducts({
            search: currentSearch,
            page: currentPage,
            productCategoryId: filterCustom.categories
              ? (filterCustom.categories as { id: number; name: string })
              : undefined,
            exhibitorId,
            location: searchProducts !== location.search ? location.search : undefined
          })
        )
      }
    }, 1000)

    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [location, exhibitorId, filterCustom, searchProducts, setPage, dispatch])

  // clean location
  useEffect(() => {
    return () => {
      dispatch(setProductsSearch(undefined))
    }
  }, [dispatch])

  // error
  useEffect(() => {
    if (errorProducts) {
      magicStream.error(errorProducts)
      dispatch(setProductError(undefined))
    }
  }, [errorProducts, magicStream, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  return (
    <StyledProductListPage>
      <StyledProductHeader>
        <Title
          titleId="product.title.main"
          variant="h1"
          suffix={products.total > 0 ? ` (${products.total})` : ''}
        />
      </StyledProductHeader>
      <StyledFilters>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={4} xl={3}>
            <ProductFiltersSearch
              setPage={setPage}
              setFilterSearch={setFilterSearch}
              filterSearch={filterSearch}
              setFilterCustom={(value: any) => {
                dispatch(setProductsSearch(undefined))
                setFilterCustom(value)
              }}
              filterCustom={filterCustom}
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={9}>
            <StyledGridContainerList container spacing={1}>
              {loading &&
                products.total === 0 &&
                Array(10)
                  .fill(0)
                  .map((value, index) => (
                    <Grid key={index} item xs={12}>
                      <LoadingCard cardType="product" />
                    </Grid>
                  ))}
              {products.total > 0 &&
                products.items.map((product, index) => (
                  <Grid key={index} item xs={12}>
                    <ProductCard
                      product={product}
                      moderators={product.businessEventExhibitor?.moderators}
                      showTags
                      showImage
                      hasLinkToExhibitor
                    />
                  </Grid>
                ))}
              {!loading && products.total === 0 && (
                <StyledGridEmptyList item xs={12}>
                  <Alert severity="warning">
                    <FormattedMessage id="product.list.empty" />
                  </Alert>
                </StyledGridEmptyList>
              )}
            </StyledGridContainerList>
            {products.total > 0 && (
              <AppPagination total={products.total} count={count} page={page} setPage={setPage} />
            )}
          </Grid>
        </Grid>
      </StyledFilters>
    </StyledProductListPage>
  )
}

// language=SCSS
const StyledProductListPage = styled.div`
  & {
    max-width: 1280px;
    margin: 0 auto;
  }
`

// language=SCSS
const StyledProductHeader = styled.div`
  & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;

    @media (min-width: ${breakpoints.lg}) {
      flex-direction: row;
    }
    label {
      display: none;
    }
  }
`

// language=SCSS
const StyledFilters = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }
`

// language=SCSS
const StyledGridContainerList = styled(Grid)`
  & {
    position: relative;
    margin-bottom: 1em;
  }
`

// language=SCSS
const StyledGridEmptyList = styled(Grid)`
  & {
    margin-top: 1em;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
`

export default ProductListPage
