import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils/createReducer'
import { ActionsTypes, IChatState } from './model'

const initialState: IChatState = {
  loadedConversations: false,
  loadingConversations: false,
  loadingConversation: false,
  loading: false,
  openChat: false,
  minimizeChat: false,
  sending: false,
  sended: false,
  sendedLive: false,
  invitationInProgress: false,
  currentConversation: -1,
  conversations: [],
  channelsToBind: [],
  loadingChannels: false,
  unread: [],
  call: {},
  notification: null,
  magicStreams: [],
  loadingMagicStreams: false,
  magicStreamsUser: [],
  loadingMagicStreamsUser: false,
  newMessageClicked: {},
  callEnded: {},
  contactAppointment: null,
  openNotifications: false
}

const actionHandlers: IReducerHandler<IChatState> = {
  [ActionsTypes.SET_LOADED_CONVERSATIONS]: (
    state: IChatState | undefined,
    { loadedConversations }: AnyAction
  ): IChatState => ({
    ...state!,
    loadedConversations
  }),

  [ActionsTypes.SET_LOADING_CONVERSATIONS]: (
    state: IChatState | undefined,
    { loadingConversations }: AnyAction
  ): IChatState => ({
    ...state!,
    loadingConversations
  }),

  [ActionsTypes.SET_ERROR_CONVERSATIONS]: (
    state: IChatState | undefined,
    { errorConversations }: AnyAction
  ): IChatState => ({
    ...state!,
    errorConversations
  }),

  [ActionsTypes.SET_LOADING_CONVERSATION]: (
    state: IChatState | undefined,
    { loadingConversation }: AnyAction
  ): IChatState => ({
    ...state!,
    loadingConversation
  }),

  [ActionsTypes.SET_ERROR_CONVERSATION]: (
    state: IChatState | undefined,
    { errorConversation }: AnyAction
  ): IChatState => ({
    ...state!,
    errorConversation
  }),

  [ActionsTypes.SET_LOADING_MESSAGES]: (
    state: IChatState | undefined,
    { loading }: AnyAction
  ): IChatState => ({
    ...state!,
    loading
  }),

  [ActionsTypes.SET_ERROR_MESSAGES]: (
    state: IChatState | undefined,
    { errorMessages }: AnyAction
  ): IChatState => ({
    ...state!,
    errorMessages
  }),

  [ActionsTypes.SET_OPEN_CHAT]: (
    state: IChatState | undefined,
    { openChat }: AnyAction
  ): IChatState => ({
    ...state!,
    openChat
  }),

  [ActionsTypes.SET_MINIMIZE_CHAT]: (
    state: IChatState | undefined,
    { minimizeChat }: AnyAction
  ): IChatState => ({
    ...state!,
    minimizeChat
  }),

  [ActionsTypes.SET_ERROR_SENDING]: (
    state: IChatState | undefined,
    { errorSending }: AnyAction
  ): IChatState => ({
    ...state!,
    errorSending
  }),

  [ActionsTypes.SET_SENDING]: (
    state: IChatState | undefined,
    { sending }: AnyAction
  ): IChatState => ({
    ...state!,
    sending
  }),

  [ActionsTypes.SET_SENDED]: (
    state: IChatState | undefined,
    { sended }: AnyAction
  ): IChatState => ({
    ...state!,
    sended
  }),

  [ActionsTypes.SET_SENDED_LIVE]: (
    state: IChatState | undefined,
    { sendedLive }: AnyAction
  ): IChatState => ({
    ...state!,
    sendedLive
  }),

  [ActionsTypes.SET_INVITATION_IN_PROGRESS]: (
    state: IChatState | undefined,
    { invitationInProgress }: AnyAction
  ): IChatState => ({
    ...state!,
    invitationInProgress
  }),

  [ActionsTypes.SET_CURRENT_CONVERSATION]: (
    state: IChatState | undefined,
    { currentConversation }: AnyAction
  ): IChatState => ({
    ...state!,
    currentConversation
  }),

  [ActionsTypes.ADD_CONVERSATION]: (
    state: IChatState | undefined,
    { conversation, firstPosition }: AnyAction
  ): IChatState => ({
    ...state!,
    conversations:
      state && state.conversations
        ? firstPosition
          ? [
            conversation,
            ...state.conversations.filter((c) => c.contact.id !== conversation.contact.id)
          ]
          : [
            ...state.conversations.filter((c) => c.contact.id !== conversation.contact.id),
            conversation
          ]
        : []
  }),

  [ActionsTypes.ADD_MESSAGE_TO_CONVERSATION]: (
    state: IChatState | undefined,
    { contactId, messageSended }: AnyAction
  ): IChatState => {
    const existingConversation =
      state && state.conversations.find((conversation) => conversation.contact.id === contactId)
    return {
      ...state!,
      conversations:
        state && state.conversations
          ? existingConversation
            ? [
              {
                ...existingConversation,
                messages: [messageSended, ...existingConversation.messages]
              },
              ...state.conversations.filter(
                (conversation) => conversation.contact.id !== contactId
              )
            ]
            : state.conversations
          : []
    }
  },

  [ActionsTypes.ADD_MESSAGES_TO_CONVERSATION]: (
    state: IChatState | undefined,
    { contactId, messages }: AnyAction
  ): IChatState => ({
    ...state!,
    conversations:
      state && state.conversations
        ? state.conversations.map((conversation) => {
          if (conversation.contact.id === contactId) {
            return {
              ...conversation,
              messages: [...conversation.messages, ...messages]
            }
          } else {
            return conversation
          }
        })
        : []
  }),

  [ActionsTypes.SET_CHANNELS_TO_BIND]: (
    state: IChatState | undefined,
    { channelsToBind }: AnyAction
  ): IChatState => ({
    ...state!,
    channelsToBind
  }),

  [ActionsTypes.SET_LOADING_CHANNELS]: (
    state: IChatState | undefined,
    { loadingChannels }: AnyAction
  ): IChatState => ({
    ...state!,
    loadingChannels
  }),

  [ActionsTypes.SET_ERROR_CHANNELS]: (
    state: IChatState | undefined,
    { errorChannels }: AnyAction
  ): IChatState => ({
    ...state!,
    errorChannels
  }),

  [ActionsTypes.INCREMENT_UNREAD]: (
    state: IChatState | undefined,
    { unread }: AnyAction
  ): IChatState => ({
    ...state!,
    unread: state ? [...state.unread, unread] : [unread]
  }),

  [ActionsTypes.DECREMENT_UNREAD]: (
    state: IChatState | undefined,
    { contactId }: AnyAction
  ): IChatState => ({
    ...state!,
    unread:
      state && state.unread ? state.unread.filter((unread) => unread.author.id !== contactId) : []
  }),

  [ActionsTypes.SET_CALL]: (state: IChatState | undefined, { call }: AnyAction): IChatState => ({
    ...state!,
    call
  }),

  [ActionsTypes.SET_NOTIFICATION]: (
    state: IChatState | undefined,
    { notification }: AnyAction
  ): IChatState => ({
    ...state!,
    notification
  }),

  [ActionsTypes.SET_MAGIC_STREAMS]: (
    state: IChatState | undefined,
    { streams }: AnyAction
  ): IChatState => ({
    ...state!,
    magicStreams: streams
  }),

  [ActionsTypes.ADD_MAGIC_STREAM]: (
    state: IChatState | undefined,
    { stream }: AnyAction
  ): IChatState => ({
    ...state!,
    magicStreams: state
      ? [stream, ...state.magicStreams.filter((magicStream) => magicStream.id !== stream.id)]
      : [stream]
  }),

  [ActionsTypes.SET_LOADING_MAGIC_STREAMS]: (
    state: IChatState | undefined,
    { loadingMagicStreams }: AnyAction
  ): IChatState => ({
    ...state!,
    loadingMagicStreams
  }),

  [ActionsTypes.SET_ERROR_MAGIC_STREAMS]: (
    state: IChatState | undefined,
    { errorMagicStreams }: AnyAction
  ): IChatState => ({
    ...state!,
    errorMagicStreams
  }),

  [ActionsTypes.SET_MAGIC_STREAMS_USER]: (
    state: IChatState | undefined,
    { streamsUser }: AnyAction
  ): IChatState => ({
    ...state!,
    magicStreamsUser: streamsUser
  }),

  [ActionsTypes.SET_LOADING_MAGIC_STREAMS_USER]: (
    state: IChatState | undefined,
    { loadingMagicStreamsUser }: AnyAction
  ): IChatState => ({
    ...state!,
    loadingMagicStreamsUser
  }),

  [ActionsTypes.SET_ERROR_MAGIC_STREAMS_USER]: (
    state: IChatState | undefined,
    { errorMagicStreamsUser }: AnyAction
  ): IChatState => ({
    ...state!,
    errorMagicStreamsUser
  }),

  [ActionsTypes.SET_NEW_MESSAGE_CLICKED]: (
    state: IChatState | undefined,
    { key, newMessageClicked }: AnyAction
  ): IChatState => ({
    ...state!,
    newMessageClicked:
      state && state.newMessageClicked
        ? { ...state.newMessageClicked, [key]: newMessageClicked }
        : {}
  }),

  [ActionsTypes.SET_CALL_ENDED]: (
    state: IChatState | undefined,
    { key, callEnded }: AnyAction
  ): IChatState => ({
    ...state!,
    callEnded: state && state.callEnded ? { ...state.callEnded, [key]: callEnded } : {}
  }),

  [ActionsTypes.SET_CONTACT_APPOINTMENT]: (
    state: IChatState | undefined,
    { contactAppointment }: AnyAction
  ): IChatState => ({
    ...state!,
    contactAppointment
  }),

  [ActionsTypes.SET_OPEN_NOTIFICATIONS]: (
    state: IChatState | undefined,
    { openNotifications }: AnyAction
  ): IChatState => ({
    ...state!,
    openNotifications
  })
}

export const reducer = createReducer(initialState, actionHandlers)
