import useGTM from '@elgorditosalsero/react-gtm-hook'
import { ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IContact } from '../../../entities'
import { useThunkDispatch } from '../../../hooks'
import { deleteContact } from '../../../store/participant/thunks'

type IProps = {
  contact: IContact
  onClose?: (event: React.MouseEvent<EventTarget>) => void
}

const ActionRemoveContact: React.FC<IProps> = ({ contact, onClose }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const dispatch = useThunkDispatch()

  const handleRemoveFavoriteContact = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    if (contact.id) {
      sendDataToGTM({ event: 'delete-contact', cardType: 'Contact Popper' })
      dispatch(deleteContact(contact.id))
    }
    onClose && onClose(event)
  }

  const memoFriends = useMemo(() => !!contact.isContact, [contact])

  return (
    <>
      {/* // remove favorite contact */}
      {memoFriends && (
        <MenuItem onClick={handleRemoveFavoriteContact}>
          <ListItemIcon>
            <PersonAddDisabledIcon
              fontSize="large"
              titleAccess={intl.formatMessage({
                id: 'titleAccess.ContactPopperAction.PersonAddDisabledIcon'
              })}
            />
          </ListItemIcon>
          <Typography variant="inherit">
            <FormattedMessage id="contactpopper.button_unfollow" />
          </Typography>
        </MenuItem>
      )}
    </>
  )
}

export default ActionRemoveContact
