import { IPresenceUser } from './pusher'
import { IContactUser, IContactUserPresence } from './user'

export type IRoom = {
  id: number
  roomClassName: string
  sessionId: string
  type: RoomType
  title?: string
  active?: boolean
  locked?: boolean
  userPresence?: IContactUserPresence
  color?: string
  hasAccessControl?: string
}

export type IRoomUpdate = {
  title: string
  type: RoomType
  sessionId?: string
  color?: string
  userId?: number
}

export type IChair = {
  id: string
  uid: string
  dataChairId: string
  dataRoom: string
  dataChairType: string
}

export type IChairs = {
  [key: string]: IChair[]
}

export type IChairLocation = {
  id: string
  uid: string
  class: string
  y: string
  x: string
  width: string
  height: string
  dataChairId: string
  dataRoom: string
  dataChairType: 'user' | 'queued'
  queuePosition: number
}

export type IEnterRoom = {
  token: string
  room: { locked: boolean }
  isQueued: boolean
  chair: IChairLocation
}

export type IAutoEnterRoom = {
  id: string
  roomActive: number
}

export type IRoomOccupation = {
  id: string
  chairOccupations: {
    id: string
    isFree: boolean
    broadcasters: IPresenceUser[]
    users: IPresenceUser[]
  }[]
}

export type IRoomQueue = {
  id: string
  items: {
    id: string
    date: string
    user: IContactUser
  }[]
}

export enum RoomType {
  Lobby = 'lobby',
  VideoChat = 'videochat',
  Broadcast = 'broadcast',
  External = 'external',
  PslEvent = 'psl_event',
  JitsiVideoChat = 'jitsi',
  JitsiIdealChat = 'jitsi_ideal',
  JitsiBroadcast = 'jitsi_webinar',
  JitsiBroadcastIdeal = 'jitsi_webinar_ideal',
  Zoom = 'zoom',
  ZoomMeeting = 'zoom_meeting', // like zoom
  ZoomWebinar = 'zoom_webinar', // TODO
  ZoomInstant = 'zoom_instant' // WIP
}

export interface IQueue {
  user: IContactUser
  room_id: number
}
