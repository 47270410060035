import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Breadcrumbs, Grid, Link, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import EditBackgroundHeader from '../../components/globals/EditBackgroundHeader/EditBackgroundHeader'
import Loading from '../../components/Loading'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setEditMode, updateExhibitor } from '../../store/exhibitor'
import { setPageReferral } from '../../store/gtm'
import { setAlarm } from '../../store/stand'
import { PRIMARY_COLOR } from '../../theme'
import { WHITE } from '../../theme/colors'
import { CONTACTS_MENU_WIDTH, LEFT_MENU_WIDTH } from '../../theme/sizes'
import EditModeMenu from '../globals/EditModeMenu/EditModeMenu'
import LiveCounterSvg from '../globals/LiveCounterSvg/LiveCounterSvg'
import IconButtonBg from '../ui/IconButtonBg/IconButtonBg'

const ExhibitorHeader: React.FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const magicStream = useMagicStream()
  const [headerType, setHeaderType] = useState<string | null>(null)
  const [headerValue, setHeaderValue] = useState<string | null>(null)
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const updating = useSelector((state: IRootState) => state.exhibitorState.updating)
  const editMode = useSelector((state: IRootState) => state.exhibitorState.editMode)
  const aroundUsersCount = useSelector(
    (state: IRootState) => state.participantState.aroundUsersCount
  )
  const alarm = useSelector((state: IRootState) => state.standState.alarm)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)

  const dispatch = useThunkDispatch()

  const hasAlarm = useMemo(() => exhibitor && alarm === `exhibitor-${exhibitor.id}`, [
    alarm,
    exhibitor
  ])

  const toggleAlarm = () => {
    if (exhibitor) {
      dispatch(setAlarm(hasAlarm ? undefined : `exhibitor-${exhibitor.id}`))
      magicStream.info(intl.formatMessage({ id: hasAlarm ? 'stop.alarm' : 'start.alarm' }))
    }
  }

  const setHeader = useCallback(
    (newType: string, newValue: string) => {
      setHeaderType(newType)
      setHeaderValue(newValue)
    },
    [setHeaderType, setHeaderValue]
  )

  const updateHeader = useCallback(
    (newType: string, newValue: string) => {
      setHeader(newType, newValue)
      const updateValue =
        newType === 'image'
          ? { bannerUrlS3: newValue, color: null }
          : { color: newValue, bannerUrlS3: null }
      if (exhibitor) {
        dispatch(updateExhibitor(exhibitor.id, updateValue))
      }
    },
    [setHeader, dispatch, exhibitor]
  )

  const updateEditMode = (em: boolean) => dispatch(setEditMode(em))

  // set exhibitor infos for use
  useEffect(() => {
    if (exhibitor) {
      const type = exhibitor.bannerUrl && exhibitor.bannerUrl.startsWith('http') ? 'image' : 'color'
      const color = exhibitor.color || PRIMARY_COLOR
      const image = exhibitor.bannerUrl
      const value = type === 'image' ? image : color
      setHeader(type, value)
    }
  }, [exhibitor, setHeaderType, setHeader])

  if (!exhibitor) return <></>

  return (
    <StyledExhibitorHeader type={headerType || 'color'} value={headerValue || PRIMARY_COLOR}>
      <Grid container spacing={2}>
        <StyledGridFlex item xs={12}>
          <Breadcrumbs
            separator={
              <NavigateNextIcon
                fontSize="small"
                color="inherit"
                titleAccess={intl.formatMessage({
                  id: 'titleAccess.ExhibitorPage.NavigateNextIcon'
                })}
              />
            }
            aria-label={intl.formatMessage({ id: 'ariaLabel.ExhibitorPage.Breadcrumb' })}
          >
            {!window.lightTheme && (
              <Link
                color="inherit"
                onClick={() => {
                  dispatch(setPageReferral(`${pageReferral}::exhibitor-go-back`))
                  if (location && location.state && (location.state as any).goBack) {
                    browserHistory.goBack()
                  } else {
                    browserHistory.push('/exposant')
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                <FormattedMessage id="navigation.exhibitor" />
              </Link>
            )}
            <StyledExhibitorName>
              <StyledTypography title={exhibitor.name.length > 30 ? exhibitor.name : undefined}>
                {exhibitor.name.length > 30
                  ? `${exhibitor.name.substring(0, 25)}...`
                  : exhibitor.name}
              </StyledTypography>
              {!window.lightTheme && aroundUsersCount ? (
                <LiveCounterSvg
                  display={aroundUsersCount > 0}
                  contentText={intl.formatMessage(
                    { id: 'total.visitor.max' },
                    { visitors: aroundUsersCount }
                  )}
                  relative
                />
              ) : null}
            </StyledExhibitorName>
          </Breadcrumbs>
          <Icons>
            <IconButtonBg
              title={intl.formatMessage({
                id: hasAlarm ? 'actions.alarm.off' : 'actions.alarm.on'
              })}
              onClick={() => toggleAlarm()}
              disabled={updating}
            >
              {hasAlarm && <NotificationsActiveIcon />}
              {!hasAlarm && <NotificationsOffIcon />}
            </IconButtonBg>
            {exhibitor.editable && (
              <EditBackgroundHeader
                type={headerType || 'image'}
                value={headerValue || PRIMARY_COLOR}
                position="bottom-end"
                disabled={updating}
                onUpdate={updateHeader}
                onChange={setHeader}
              />
            )}
            {exhibitor.editable && (
              <EditModeMenu
                editMode={editMode}
                setEditMode={updateEditMode}
                disabled={updating}
              />
            )}
            {updating && <WhiteLoading />}
          </Icons>
        </StyledGridFlex>
      </Grid>
    </StyledExhibitorHeader>
  )
}

const headerImageCss = (image?: string) => css`
  & {
		background-image: url("${image}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const StyledExhibitorHeader = styled.div<{ type?: string; value?: string }>`
  & {
    position: relative;
    padding: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -${LEFT_MENU_WIDTH};
      right: calc(-${CONTACTS_MENU_WIDTH} - 12px);
      height: 350px;
      background-color: ${(props) => (props.type === 'color' ? props.value || 'white' : 'white')};
      ${(props) => (props.type === 'image' ? headerImageCss(props.value) : '')};
    }

    > * {
      position: relative;
    }
  }
`

const StyledGridFlex = styled(Grid)`
  & {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const WhiteLoading = styled(Loading)`
  & {
    color: ${WHITE};
    margin: 12px;
  }
`

const Icons = styled.div`
  display: flex;
  height: 45px;

  & > button,
  & > div {
    margin-left: 5px;
  }
`

const StyledExhibitorName = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledTypography = styled(Typography)`
  word-break: normal;
`

export default ExhibitorHeader
