import { IExhibitor, IExhibitorFull, IExhibitorUpdate } from '../entities/exhibitor'
import settings from '../settings'
import { apiPatchRequest, apiRequest } from './requests'

export const getExhibitors = async (
  page: number = 1,
  search?: string,
  sortBy?: string,
  keyword?: string,
  followed?: boolean,
  matching?: number,
  keywordsX?: string,
  isHighlight?: boolean
) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/e_events/exhibitors?eventGroupId=${settings.eventGroupId}${sortBy === 'recommandation' // orderByReco (bool) : mots clés en commun avec l'utilisateur
      ? `&orderByMatching=true&minimumMatching=${matching}`
      : sortBy === 'popularity' // scoring (bool) : tri par score
        ? '&scoring=true'
        : ''
    }${matching && matching > 0 ? `&orderByMatching=true&minimumMatching=${matching}` : ''}${keyword && keyword !== 'all' ? `&keywordId=${keyword}` : ''
    }${keywordsX ? `&xKeyword=${keywordsX}` : ''}${search ? `&search=${search}` : ''}${page ? `&page=${page}` : ''
    }${followed ? `&followed=${followed}` : ''}${isHighlight ? '&isHighlight=1' : ''}&limit=${settings.list.itemsPerPage
    }`
  )
  return { items: response['hydra:member'] as IExhibitor[], total: response['hydra:totalItems'] }
}

export const getScoredExhibitors = async () => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/e_events/exhibitors?eventGroupId=${settings.eventGroupId}&limit=10&scoring=true`
  )
  return response['hydra:member'] as IExhibitor[]
}

export const getPremiumExhibitors = async () => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/e_events/exhibitors?eventGroupId=${settings.eventGroupId}&limit=20&sponsorshipLevel=1`
  )
  return response['hydra:member'] as IExhibitor[]
}

export const getExhibitor = async (id: string) => {
  const response = await apiRequest<IExhibitorFull>(
    'GET',
    `${settings.url.api}/e_events/exhibitor/${id}`
  )
  return response
}

export const updateExhibitor = async (exhibitorId: number, exhibitor: IExhibitorUpdate) => {
  const response = await apiPatchRequest<IExhibitorUpdate>(
    `${settings.url.api}/e_events/exhibitor/${exhibitorId}`,
    exhibitor
  )
  return response
}
