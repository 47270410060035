import styled from '@emotion/styled'
import { IconButton, ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'
import InfoIcon from '@material-ui/icons/Info'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContact } from '../../../entities'
import { useThunkDispatch, useTooltip } from '../../../hooks'
import { IRootState } from '../../../redux'
import { setExtendedPanel, setOpenMenuContact } from '../../../store/app'
import {
  setCurrentConversation,
  setMinimizeChat,
  setOpenChat,
  startConversation
} from '../../../store/chat'
import { LIGHT_GRAY } from '../../../theme/colors'

type IProps = {
  contact: IContact
  iconOnly?: boolean
  disabledSendMessage?: boolean | null
  onClose?: (event: React.MouseEvent<EventTarget>) => void
}

const ActionSendMessage: React.FC<IProps> = ({ contact, iconOnly, disabledSendMessage, onClose }) => {
  const intl = useIntl()
  const conversations = useSelector((state: IRootState) => state.chatState.conversations)
  const {
    anchorRef: anchorRefMessage,
    setOpen: setOpenMessage,
    Tooltip: TooltipMessage
  } = useTooltip(
    intl.formatMessage({
      id: 'contactpopper.message.notFriends'
    })
  )
  const dispatch = useThunkDispatch()

  const startMessage = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(setOpenMenuContact(false))
    if (
      contact.id &&
      conversations.find((conversation) => conversation.contact.id === contact.id)
    ) {
      // already started
      dispatch(setOpenChat(true))
      dispatch(setMinimizeChat(false))
      dispatch(setCurrentConversation(contact.id))
    } else {
      // start
      dispatch(startConversation(contact))
    }
    dispatch(setExtendedPanel('magic.streams', false))
    dispatch(setExtendedPanel('my.contacts', false))
    dispatch(setExtendedPanel('persons.around', false))
    dispatch(setExtendedPanel('persons.on.app', false))
    onClose && onClose(event)
  }


  return (
    <>
      {/* // send message */}
      {iconOnly ? (
        <IconButton onClick={startMessage}>
          <ChatIcon
            fontSize="large"
            titleAccess={intl.formatMessage({ id: 'titleAccess.ContactPopperAction.ChatIcon' })}
          />
        </IconButton>
      ) : !disabledSendMessage ? (
        <MenuItem onClick={startMessage}>
          <ListItemIcon>
            <ChatIcon
              fontSize="large"
              titleAccess={intl.formatMessage({ id: 'titleAccess.ContactPopperAction.ChatIcon' })}
            />
          </ListItemIcon>
          <Typography variant="inherit">
            <FormattedMessage id="contactpopper.button_message" />
          </Typography>
        </MenuItem>
      ) : (
        <MenuItemDisabled
          ref={anchorRefMessage}
          onMouseOver={() => setOpenMessage(true)}
          onMouseOut={() => setOpenMessage(false)}
        >
          <ListItemIcon>
            <ChatIcon
              fontSize="large"
              titleAccess={intl.formatMessage({ id: 'titleAccess.ContactPopperAction.ChatIcon' })}
            />
          </ListItemIcon>
          <Typography variant="inherit">
            <FormattedMessage id="contactpopper.button_message" />
          </Typography>
          <StyledRightListItemIcon>
            <InfoIcon fontSize="small" />
          </StyledRightListItemIcon>
          <TooltipMessage />
        </MenuItemDisabled>
      )}
    </>
  )
}

const MenuItemDisabled = styled(MenuItem)`
  color: ${LIGHT_GRAY};

  .MuiSvgIcon-root {
    fill: ${LIGHT_GRAY};
  }

  span {
    flex: auto;
  }
`

const StyledRightListItemIcon = styled(ListItemIcon)`
  justify-content: flex-end;
`

export default ActionSendMessage
