import styled from '@emotion/styled'
import { Grid, IconButton } from '@material-ui/core'
import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import MicIcon from '@material-ui/icons/Mic'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { MediaDevice, VideoResolution } from '../../../entities/mediaStream'
import { useThunkDispatch } from '../../../hooks'
import { IMediaStream } from '../../../media/IMediaStream'
import { IRootState } from '../../../redux'
import {
  saveDefaultAudioSource,
  saveDefaultResolution,
  saveDefaultVideoSource,
  savePreferedVideoDisplay,
  updateDefaultSources
} from '../../../store/app'
import { PRIMARY_COLOR } from '../../../theme'
import asyncLocalStorage from '../../../utils/asyncLocalstorage'
import OpenTokModal from '../../modals/OpenTokModal'
import AppButton from '../../ui/AppButton/AppButton'
import AppSelect from '../../ui/AppSelect/AppSelect'

interface IPublisherSettingsProps {
  mediaStream?: IMediaStream
  disableTest?: boolean
}

const PublisherSettings: React.FC<IPublisherSettingsProps> = ({ mediaStream, disableTest }) => {
  const intl = useIntl()
  const [opentokTestOpen, setOpentokTestOpen] = useState(false)
  const videoInputDevices = useSelector((state: IRootState) => state.appState.videoInputDevices)
  const audioInputDevices = useSelector((state: IRootState) => state.appState.audioInputDevices)
  const defaultVideoSource = useSelector((state: IRootState) => state.appState.defaultVideoSource)
  const defaultAudioSource = useSelector((state: IRootState) => state.appState.defaultAudioSource)
  const defaultResolution = useSelector((state: IRootState) => state.appState.defaultResolution)
  const preferedVideoDisplay = useSelector(
    (state: IRootState) => state.appState.preferedVideoDisplay
  )
  const dispatch = useThunkDispatch()

  const handleChangeInputVideo = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    dispatch(saveDefaultVideoSource(asyncLocalStorage, event.target.value as string))
  }

  const handleChangeInputAudio = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    dispatch(saveDefaultAudioSource(asyncLocalStorage, event.target.value as string))
  }

  const handleChangeResolution = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    dispatch(saveDefaultResolution(asyncLocalStorage, event.target.value as VideoResolution))
  }

  const togglePreferedVideoDisplay = () => {
    dispatch(savePreferedVideoDisplay(asyncLocalStorage, !preferedVideoDisplay))
  }

  useEffect(() => {
    if (mediaStream) {
      mediaStream.getDevices((devices: MediaDevice[]) => {
        if (devices) {
          dispatch(updateDefaultSources(asyncLocalStorage, devices))
        }
      })
    }
  }, [mediaStream, dispatch])

  return (
    <Settings>
      <Grid container spacing={0}>
        <Grid container item xs={12} spacing={1} alignItems="center">
          <StyledCenterGrid item xs={12}>
            <IconButton onClick={togglePreferedVideoDisplay}>
              {preferedVideoDisplay && (
                <VideocamIcon
                  fontSize="large"
                  titleAccess={intl.formatMessage({ id: 'prefered.video.on' })}
                />
              )}
              {!preferedVideoDisplay && (
                <VideocamOffIcon
                  fontSize="large"
                  titleAccess={intl.formatMessage({ id: 'prefered.video.off' })}
                />
              )}
            </IconButton>
            {!disableTest && (
              <AppButton onClick={() => setOpentokTestOpen(true)}>Test de qualité</AppButton>
            )}
          </StyledCenterGrid>
          <Grid item xs={2}>
            <VideocamIcon
              fontSize="large"
              titleAccess={intl.formatMessage({ id: 'default.video.source' })}
            />
          </Grid>
          <Grid item xs={10} zeroMinWidth>
            {videoInputDevices && videoInputDevices.length > 0 && (
              <StyledAppSelect
                list={videoInputDevices}
                value={defaultVideoSource || videoInputDevices[0].deviceId}
                select="deviceId"
                label="label"
                onChange={handleChangeInputVideo}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1} alignItems="center">
          <Grid item xs={2}>
            <MicIcon
              fontSize="large"
              titleAccess={intl.formatMessage({ id: 'default.audio.source' })}
            />
          </Grid>
          <Grid item xs={10} zeroMinWidth>
            {audioInputDevices && audioInputDevices.length > 0 && (
              <StyledAppSelect
                list={audioInputDevices}
                value={defaultAudioSource || audioInputDevices[0].deviceId}
                select="deviceId"
                label="label"
                onChange={handleChangeInputAudio}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1} alignItems="center">
          <Grid item xs={2}>
            <AspectRatioIcon
              fontSize="large"
              titleAccess={intl.formatMessage({ id: 'default.video.resolution' })}
            />
          </Grid>
          <Grid item xs={10} zeroMinWidth>
            <StyledAppSelect
              list={Object.values(VideoResolution).map((res) => ({ resolution: res }))}
              value={defaultResolution}
              select="resolution"
              onChange={handleChangeResolution}
            />
          </Grid>
        </Grid>
      </Grid>
      <OpenTokModal opened={opentokTestOpen} setOpened={setOpentokTestOpen} />
    </Settings>
  )
}

const Settings = styled.div`
  display: flex;
  flex-direction: column;

  & svg {
    color: ${PRIMARY_COLOR};
  }
`

const StyledAppSelect = styled(AppSelect)`
  max-width: 100%;
`

const StyledCenterGrid = styled(Grid)`
  text-align: center;
`

export default PublisherSettings
