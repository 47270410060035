import { QualityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testQuality'
import { IEventCo } from '../../entities'
import { IOpentokSettings } from '../../entities/mediaStream'

export enum ActionsTypes {
  SET_LOADING_EVENT = 'SET_LOADING_EVENT',
  SET_LOADING_SETTINGS = 'SET_LOADING_SETTINGS',
  SET_LOADING_TESTS = 'SET_LOADING_TESTS',
  SET_SETTINGS_OPENTOK = 'SET_SETTINGS_OPENTOK',
  SET_QUALITY_TEST_RESULTS_OPENTOK = 'SET_QUALITY_TEST_RESULTS_OPENTOK',
  SET_TESTS_OPENTOK = 'SET_TESTS_OPENTOK',
  SET_EVENT_CO = 'SET_EVENT_CO',
  SET_EVENT_CO_DATES = 'SET_EVENT_CO_DATES',
  SET_EVENT_CO_DATES_LOADED = 'SET_EVENT_CO_DATES_LOADED',
  SET_EVENT_ERROR = 'SET_EVENT_ERROR',
  SET_SETTINGS_ERROR = 'SET_SETTINGS_ERROR',
  SET_TESTS_ERROR = 'SET_TESTS_ERROR'
}

export interface ISettingsState {
  loadingEvent: boolean
  loadingSettings: boolean
  loadingOpentokTests: boolean
  opentokSettings?: IOpentokSettings | null
  opentokQualityTestResults?: QualityTestResults | null
  opentokTests?: IOpentokSettings | null
  eventCo?: IEventCo | null
  eventCoDates?: string[]
  eventCoDatesLoaded: boolean
  errorEvent?: string
  errorSettings?: string
  errorOpentokTests?: string
}
