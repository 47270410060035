import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { PROFILINGS } from '../../entities'
import { IRootState } from '../../redux'
import settings from '../../settings'
import consoleUtils from '../../utils/consoleUtils'

interface IProfilingIFrameProps {
  exhibitorId?: number
  conferenceId?: number
  type: PROFILINGS
  fullscreen?: boolean
  autostart?: boolean
  onComplete?: () => void
}

const ProfilingIFrame: React.FC<IProfilingIFrameProps> = ({
  type,
  exhibitorId,
  conferenceId,
  fullscreen,
  autostart,
  onComplete
}) => {
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)

  const profilingSrc = useMemo(
    () =>
      settings.profilingUrl +
      (type === PROFILINGS.USER
        ? `/profiling/user?businessEventId=${eventCo?.id}`
        : type === PROFILINGS.EXHIBITOR
          ? `/profiling/exhibitor?businessEventId=${eventCo?.id}&businessEventExhibitorId=${exhibitorId}`
          : `/profiling/event?businessEventId=${eventCo?.id}&eventId=${conferenceId}`) + (autostart ? `&autostart=1` : ''),
    [eventCo, type, exhibitorId, conferenceId, autostart]
  )

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data)
        if (data.done && onComplete) {
          onComplete()
        }
      } catch (e) {
        consoleUtils.error('handler message error=', e)
      }
    }

    window.addEventListener('message', handler)

    return () => window.removeEventListener('message', handler)
  }, [onComplete])

  return <StyledIFrame src={profilingSrc} frameBorder="0" fullscreen={fullscreen}></StyledIFrame>
}

const fullscreenIframeStyle = css`
  & {
    position: fixed;
    top: 0;
    z-index: 10000;
  }
`

const StyledIFrame = styled.iframe<{ fullscreen?: boolean }>`
  width: 100%;
  height: 100%;
  ${(props) => props.fullscreen && fullscreenIframeStyle};
`

export default ProfilingIFrame
