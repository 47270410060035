import styled from '@emotion/styled'
import { alpha, Chip, Grid, MenuList } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContact } from '../../entities'
import { useEffectOnce, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { getKeywordsByUser } from '../../store/app'
import { loadMagicStreamsUser } from '../../store/chat'
import { refreshContactInfo } from '../../store/participant/thunks'
import { BLACK_BLUE } from '../../theme/colors'
import { formatName } from '../../utils'
import ContactAvatar from '../globals/ContactAvatar/ContactAvatar'
import Loading from '../Loading'
import ContactFullScreenActions from './ContactFullScreenActions'
import ContactTakeAppointmentForm from './ContactTakeAppointmentForm'

type IContactFullScreenContentProps = {
  contact: IContact
  disabledSendMessage?: boolean | null
  isOnline?: boolean | undefined
  isOffice?: boolean | undefined
  onClose?: (event: React.MouseEvent<EventTarget>) => void
  onEnterOffice?: () => void
}

const ContactFullScreenContent: FC<IContactFullScreenContentProps> = ({
  contact,
  disabledSendMessage,
  isOnline,
  isOffice,
  onEnterOffice,
  onClose
}) => {
  const dispatch = useThunkDispatch()
  const user = useSelector((state: IRootState) => state.appState.user)
  const keywordsByUser = useSelector((state: IRootState) => state.appState.keywordsByUser)
  const loadingKeywordsByUser = useSelector(
    (state: IRootState) => state.appState.loadingKeywordsByUser
  )
  const userLocation = useSelector((state: IRootState) => state.appState.location)
  const refreshingContact = useSelector(
    (state: IRootState) => state.participantState.refreshingContact
  )
  const contactsRefreshed = useSelector(
    (state: IRootState) => state.participantState.contactsRefreshed
  )
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)

  const memoNotMe = useMemo(() => user && user.id !== contact.id, [user, contact])

  const memoContact = useMemo(() => (contact.id && contactsRefreshed[contact.id]) || contact, [
    contactsRefreshed,
    contact
  ])

  const showLocation = useMemo(
    () =>
      memoContact &&
      memoContact.userStatus &&
      memoContact.userStatus.appName === settings.theme.name &&
      memoContact.userStatus.url &&
      memoContact.userStatus.url !== userLocation,
    [memoContact, userLocation]
  )

  const sameLocation = useMemo(
    () =>
      memoContact &&
      memoContact.userStatus &&
      memoContact.userStatus.appName === settings.theme.name &&
      memoContact.userStatus.url &&
      memoContact.userStatus.url === userLocation,
    [memoContact, userLocation]
  )

  const onAppointmentSended = () => {
    dispatch(loadMagicStreamsUser(contact.id))
  }

  useEffectOnce(() => {
    if (contact.id) {
      dispatch(refreshContactInfo(contact.id))
      dispatch(getKeywordsByUser(contact.id))
    }
  })

  return (
    <StyledContactFullScreenContent>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <StyledContactFullScreenContentTop>
            <ContactAvatar contact={memoContact} />
          </StyledContactFullScreenContentTop>
          <StyledUserInfo>
            <StyledUserName>
              {formatName(memoContact.userPresence.user).full}
            </StyledUserName>
            {memoContact.userPresence.user.function && (
              <StyledUserFunction>{memoContact.userPresence.user.function}</StyledUserFunction>
            )}
            {memoContact.userPresence.user.partner_id && (
              <StyledEnterprise>{memoContact.userPresence.user.partner_id.name}</StyledEnterprise>
            )}
            {memoContact.userPresence.user.description && (
              <StyledUserDescription
                dangerouslySetInnerHTML={{ __html: memoContact.userPresence.user.description }}
              />
            )}
            {!loadingKeywordsByUser && keywordsByUser && keywordsByUser.total > 0 && (
              <StyledChipContainer>
                {keywordsByUser.items.map((keyword, index) => (
                  <Chip key={index} label={keyword.keyword_id.title} color="primary" />
                ))}
              </StyledChipContainer>
            )}
            {!eventCo?.eventcoAppointment && memoNotMe && refreshingContact && <Loading />}
            {!eventCo?.eventcoAppointment &&
              memoNotMe &&
              showLocation &&
              memoContact.userStatus && (
                <StyledUserLocation>{memoContact.userStatus.description}</StyledUserLocation>
              )}
            {!eventCo?.eventcoAppointment && memoNotMe && sameLocation && (
              <StyledUserLocation>
                <FormattedMessage id="pathname.description.same" />
              </StyledUserLocation>
            )}
          </StyledUserInfo>
          <StyledMenuList>
            <ContactFullScreenActions
              contact={memoContact}
              status={memoContact.userStatus}
              disabledSendMessage={disabledSendMessage}
              isOnline={isOnline}
              isOffice={isOffice}
              onEnterOffice={onEnterOffice}
              onClose={onClose}
            />
          </StyledMenuList>
        </Grid>
        <Grid item xs={12} md={6}>
          <ContactTakeAppointmentForm
            contact={memoContact}
            showDate
            withSubmit
            onAppointmentSended={onAppointmentSended}
          />
        </Grid>
      </Grid>
    </StyledContactFullScreenContent>
  )
}

const StyledContactFullScreenContent = styled.div`
  & {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
`

const StyledContactFullScreenContentTop = styled.div`
  & {
    position: relative;
    height: 55px;
    margin-bottom: 30px;

    & > div,
    & > span {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 60px;
      width: 60px;
    }
    & > div .MuiAvatar-root,
    & > span .MuiAvatar-root {
      height: 60px;
      width: 60px;
    }
  }
`

const StyledUserInfo = styled.div`
  & {
    padding: 15px;
  }
`

const StyledUserName = styled.div`
  & {
    text-align: center;
    line-height: 19px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`

const StyledUserDescription = styled.div`
  & {
    text-align: center;
    line-height: 14px;
    font-size: 12px;
    margin-bottom: 5px;
  }
`

const StyledUserFunction = styled.div`
  & {
    text-align: center;
    line-height: 14px;
    font-size: 12px;
    margin-bottom: 5px;
  }
`

const StyledChipContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;

  & > * {
    margin: 3px;
    height: 20px;
    font-size: 8px;
  }
`

const StyledEnterprise = styled.div`
  & {
    display: block;
    max-height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
  }
`

const StyledUserLocation = styled.div`
  & {
    white-space: normal;
    line-height: 14px;
    font-size: 12px;
    color: ${alpha(BLACK_BLUE, 0.38)};
    margin-top: 10px;
  }
`

const StyledMenuList = styled(MenuList)`
  & {
    padding: 0;
    .MuiButtonBase-root {
      width: 100%;
    }
    .MuiListItemIcon-root {
      min-width: 46px;
    }
    svg {
      color: ${alpha(BLACK_BLUE, 0.38)};
    }
  }
`

export default ContactFullScreenContent
