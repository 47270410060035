import styled from '@emotion/styled'
import { alpha, IconButton, IconButtonProps } from '@material-ui/core'
import React from 'react'
import { PRIMARY_COLOR } from '../../../theme'
import { BLACK_BLUE, WHITE } from '../../../theme/colors'

interface IIconButtonBgProps extends IconButtonProps {
  iconButtonRef?: React.RefObject<HTMLButtonElement>
}

const IconButtonBg: React.FC<IIconButtonBgProps> = ({ iconButtonRef, children, ...props }) => {
  return (
    <StyledIconButton ref={iconButtonRef} {...props}>
      {children}
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton)`
  & {
    background-color: ${WHITE};
    transition: all 0.4s;
    box-shadow: ${alpha(BLACK_BLUE, 0.3)} 0 0 2px, ${alpha(BLACK_BLUE, 0.3)} 0 4px 8px;

    svg {
      color: ${alpha(BLACK_BLUE, 0.6)};
    }
    &:hover {
      background-color: ${PRIMARY_COLOR};

      svg {
        color: ${WHITE};
      }
    }
  }
`
export default IconButtonBg
