import { IAgenda, IUserSlot } from '../entities/agenda'
import settings from '../settings'
import { apiRequest } from './requests'

export const getAgenda = async () => {
  const response = await apiRequest<any>('GET', `${settings.url.api}/calendar/items/`)
  return response['hydra:member'] as IAgenda[]
}

export const getAgendaToRespond = async () => {
  const response = await apiRequest<any>('GET', `${settings.url.api}/calendar/items/?status=2`)
  return response['hydra:totalItems'] as number
}

export const deleteUnavailability = async (id: string) => {
  const response = await apiRequest<boolean>('DELETE', `${settings.url.api}/calendar/items/${id}`)
  return response
}

export const getAgendaSlots = async (userId: number, date?: Date) => {
  const response = await apiRequest<IUserSlot>(
    'GET',
    `${settings.url.api}/calendar/userslots/${userId}${date ? `?date=${date.toISOString()}` : ''
    }`
  )
  return response
}
