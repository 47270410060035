import styled from '@emotion/styled'
import React from 'react'

interface ISvgContainerProps {
  className?: string
}

const SvgContainer: React.FC<ISvgContainerProps> = ({ children, className, ...props }) => {
  return (
    <StyledSvgContainer className={className} {...props}>
      {children}
    </StyledSvgContainer>
  )
}

const StyledSvgContainer = styled.div`
  margin-bottom: 20px;
`

export default SvgContainer
