import styled from '@emotion/styled'
import { InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { FC, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RoomType } from '../../../entities'
import { BLACK_BLUE } from '../../../theme/colors'

interface ISelectRoomTypeProps {
  roomType: string
  disabled?: boolean
  onChange: (value: RoomType) => void
}

const SelectRoomType: FC<ISelectRoomTypeProps> = ({ roomType, disabled, onChange }) => {
  const [type, setType] = useState<string>(roomType || RoomType.JitsiVideoChat)

  const roomTypes = useMemo(() => Object.values(RoomType), [])

  return (
    <StyledSelectRoomType>
      <InputLabel id="room-type">
        <FormattedMessage id="select.room.type" />
      </InputLabel>
      <Select
        labelId="room-type"
        value={type}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          const newType = event.target.value as RoomType
          setType(newType)
          onChange(newType)
        }}
        disabled={disabled}
      >
        {roomTypes.map((t, index) => (
          <MenuItem key={index} value={t}>
            <FormattedMessage id={`room.type.${t}`} />
          </MenuItem>
        ))}
      </Select>
    </StyledSelectRoomType>
  )
}

const StyledSelectRoomType = styled.div`
  display: flex;
  flex-direction: column;

  #room-type {
    color: ${BLACK_BLUE};
    margin-top: -15px;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
  }
`

export default SelectRoomType
