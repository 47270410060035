import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PROFILINGS } from '../../entities'
import FullScreenDialog from '../modals/FullScreenDialog'
import Transition from '../Transition'
import ProfilingIFrame from './ProfilingIFrame'

interface IProfilingFullscreenProps {
  opened: boolean
  type: PROFILINGS
  exhibitorId?: number
  conferenceId?: number
  onClose: () => void

}

const ProfilingFullscreen: FC<IProfilingFullscreenProps> = ({
  opened,
  type,
  exhibitorId,
  conferenceId,
  onClose
}) => {
  const intl = useIntl()

  return (
    <FullScreenDialog
      title={intl.formatMessage({ id: 'profiling.title' })}
      open={opened}
      fullScreen
      TransitionComponent={Transition}
      scroll={'body'}
      onClose={onClose}
    >
      <ProfilingIFrame type={type} exhibitorId={exhibitorId} conferenceId={conferenceId} onComplete={onClose}/>
    </FullScreenDialog>
  )
}

export default ProfilingFullscreen
