import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import useSound from 'use-sound'
import connectedSfx from '../../assets/sounds/alarm_presence.mp3'
// import disconnectedSfx from '../../assets/sounds/disconnected.mp3'
import ExhibitorContent from '../../components/exhibitor/ExhibitorContent'
import ExhibitorHeader from '../../components/exhibitor/ExhibitorHeader'
import AppLoading from '../../components/ui/AppLoading/AppLoading'
import { IBindUser, IContactUserStatus, IRoom, IUser, RoomType } from '../../entities'
import { useBeforeRouteChange, useMagicStream, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import settings from '../../settings'
import { resetGroupId } from '../../store/app'
import { resetConference } from '../../store/conference'
import { getExhibitor, resetExhibitor, setExhibitorError } from '../../store/exhibitor'
import { setPageReferral } from '../../store/gtm'
import { setAroundUsers, setAroundUsersCount } from '../../store/participant'
import { loadAroundUsers } from '../../store/participant/thunks'
import { resetRoomToken, setOfficeRoomIndex, setOpentokStreaming } from '../../store/room'
import { resetChairId, setAutoEnterRoom, setLocations, setStandChannel } from '../../store/stand'
import { WHITE } from '../../theme/colors'
import { formatName } from '../../utils'
import consoleUtils from '../../utils/consoleUtils'
import pusherUtils from '../../utils/pusherUtils'

interface IRouterParams {
  id: string
}

type Props = RouteComponentProps<IRouterParams>

const ExhibitorPage: React.FC<Props> = ({ match, location }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [playEnter] = useSound(connectedSfx)
  // const [playLeave] = useSound(disconnectedSfx)
  const [calledGtm, setCalledGtm] = useState(false)
  const [exhibitorId, setExhibitorId] = useState<string | null>(null)
  const [localGroupId, setLocalGroupId] = useState(0)
  const [liveRoomIndex, setLiveRoomIndex] = useState(-1)
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [lastVideoPlaying, setLastVideoPlaying] = useState(false)
  const hasPassedTunnel = useSelector((state: IRootState) => state.appState.hasPassedTunnel)
  const user = useSelector((state: IRootState) => state.appState.user)
  const groupId = useSelector((state: IRootState) => state.appState.groupId)
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const loading = useSelector((state: IRootState) => state.exhibitorState.loading)
  const error = useSelector((state: IRootState) => state.exhibitorState.error)
  const officeRoomIndex = useSelector((state: IRootState) => state.roomState.officeRoomIndex)
  const alarm = useSelector((state: IRootState) => state.standState.alarm)
  const standChannel = useSelector((state: IRootState) => state.standState.standChannel)
  const locations = useSelector((state: IRootState) => state.standState.locations)
  const chairId = useSelector((state: IRootState) => state.standState.chairId)
  const autoEnterRoom = useSelector((state: IRootState) => state.standState.autoEnterRoom)
  const aroundUsers = useSelector((state: IRootState) => state.participantState.aroundUsers)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const { Prompt, setDirty, setPristine } = useBeforeRouteChange()
  const dispatch = useThunkDispatch()

  const hasAlarm = useMemo(() => exhibitor && alarm === `exhibitor-${exhibitor.id}`, [
    alarm,
    exhibitor
  ])

  const enterBind = useCallback(
    (usersEnter: IBindUser) => {
      if (usersEnter && exhibitor && exhibitor.group.id && groupId === exhibitor.group.id && user) {
        const filterUsersEnter = usersEnter.users.filter((userEnter) => userEnter.id !== user.id)
        if (filterUsersEnter.length > 0) {
          const filterUsersEnterIds = filterUsersEnter.map((filterUserLeave) => filterUserLeave.id)
          consoleUtils.log(
            '[EnterBind] > ids=',
            filterUsersEnterIds,
            ', groupId=',
            groupId,
            'usersEnter.display=',
            usersEnter.display
          )
          // add all enter users into around users and remove those users from aroundUsers
          if (usersEnter.display) {
            // set number of visitor
            dispatch(setAroundUsersCount(usersEnter.count))
            dispatch(
              setAroundUsers(
                [
                  ...filterUsersEnter.map((filterUserEnter) => ({
                    id: filterUserEnter.id,
                    userPresence: {
                      isOnline: true,
                      user: {
                        ...filterUserEnter,
                        partner_id: {
                          name: filterUserEnter.partnerName || ''
                        }
                      }
                    },
                    userStatus:
                      filterUserEnter.status && (filterUserEnter.status as IContactUserStatus).id
                        ? filterUserEnter.status
                        : filterUserEnter.status
                          ? JSON.parse(filterUserEnter.status as string)
                          : null
                  })),
                  ...aroundUsers.filter(
                    (aroundUser) =>
                      !aroundUser.id || filterUsersEnterIds.indexOf(aroundUser.id) === -1
                  )
                ].slice(0, settings.list.contacts)
              )
            )
            // sound when enter
            if (hasAlarm) playEnter()
          }
        }
      }
    },
    [exhibitor, groupId, aroundUsers, user, hasAlarm, playEnter, dispatch]
  )

  const leaveBind = useCallback(
    (usersLeave: IBindUser) => {
      if (usersLeave && exhibitor && exhibitor.group.id && groupId === exhibitor.group.id && user) {
        const filterUsersLeaveIds = usersLeave.users
          .filter((userLeave) => userLeave.id !== user.id)
          .map((filterUserLeave) => filterUserLeave.id)
        if (filterUsersLeaveIds.length > 0) {
          consoleUtils.log('[LeaveBind] > ids=', filterUsersLeaveIds, ', groupId=', groupId)
          // set number of visitor
          dispatch(setAroundUsersCount(usersLeave.count))
          // remove all leaving users from list
          dispatch(
            setAroundUsers(
              aroundUsers.filter(
                (aroundUser) => !aroundUser.id || filterUsersLeaveIds.indexOf(aroundUser.id) === -1
              )
            )
          )
          // remove users from locations
          dispatch(
            setLocations(locations.filter((loc) => filterUsersLeaveIds.indexOf(loc.user.id) === -1))
          )
          // sound when leave
          // if (hasAlarm) playLeave()
        }
      }
    },
    [exhibitor, groupId, aroundUsers, locations, user, /*hasAlarm, playLeave,*/ dispatch]
  )

  const findIndexRoomById = (rooms: IRoom[], id: number) => {
    // let try to find index of room by id
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i] && rooms[i].id === id) {
        return `${i}`
      }
    }
    // or if id can be an index
    if (id < rooms.length) return `${id}`
    return '-1'
  }

  const findRoomBroadcastIndex = (rooms: IRoom[]) => {
    for (let i = 0; i < rooms.length; i++) {
      if (
        rooms[i] &&
        (rooms[i].type === RoomType.Broadcast || rooms[i].type === RoomType.JitsiBroadcast)
      ) {
        return i
      }
    }
    return -1
  }

  const findRoomOfficeIndex = (rooms: IRoom[], officeUser: IUser) => {
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i] && rooms[i].userPresence && rooms[i].userPresence!.user.id === officeUser.id) {
        return i
      }
    }
    return -1
  }

  // redirect
  useEffect(() => {
    if (exhibitor && exhibitor.redirectBusinessEventExhibitorId) {
      browserHistory.push({
        pathname: `/exposant/${exhibitor.redirectBusinessEventExhibitorId}`,
        state: { goBack: false }
      })
    }
  }, [exhibitor])

  // delay match to exhibitorId to avoid multiple call
  useEffect(() => {
    if (match) {
      if (eventCo && eventCo.eventcoRemote && eventCo.eventcoRemoteExhibitorId) {
        setExhibitorId(`${eventCo.eventcoRemoteExhibitorId.id}`)
      } else {
        setExhibitorId(match.params.id)
      }
    }
  }, [match, eventCo])

  // trigger location on svg for other users
  useEffect(() => {
    // need timeout to be triggered correctly, see if we can do otherwise
    if (user && standChannel && exhibitorId) {
      pusherUtils.triggerClientLocation(standChannel, `client-location-exhibitor-${exhibitorId}`, {
        user: {
          id: user.id,
          username: formatName(user).full,
          firstname: user.firstname,
          lastname: user.lastname,
          avatarPath: user.avatarPath,
          isOnline: true
        },
        roomId: '-1',
        chairId: '-1',
        broadcaster: false
      })

      if (hasPassedTunnel) {
        // Active autoplay video
        setVideoPlaying(settings.video.autoStart)
        setLastVideoPlaying(settings.video.autoStart)
      }
    }

    return () => {
      if (user && standChannel && exhibitorId) {
        dispatch(resetRoomToken())
        dispatch(resetChairId())
      }
    }
  }, [exhibitorId, standChannel, user, hasPassedTunnel, dispatch])

  // load data for page and clean
  useEffect(() => {
    if (user && user.id && exhibitorId) {
      dispatch(getExhibitor(exhibitorId, user.id))
      // subscribe to exhibitor
      pusherUtils.subscribe(`private-exhibitor-${exhibitorId}`).then((channel) => {
        dispatch(setStandChannel(channel))
      })
    }
    return () => {
      if (user && user.id && exhibitorId && window.pusher) {
        dispatch(resetExhibitor())
        dispatch(resetConference())
        dispatch(setStandChannel(null))
        dispatch(setOpentokStreaming(false))
        pusherUtils.triggerLeaveLocation(
          window.pusher.channel(`private-exhibitor-${exhibitorId}`),
          `client-leave-exhibitor-${exhibitorId}`,
          user
        )
        pusherUtils.unsubscribe(`private-exhibitor-${exhibitorId}`)
      }
    }
  }, [exhibitorId, user, dispatch])

  // set exhibitor infos for use
  useEffect(() => {
    if (exhibitor) {
      setLocalGroupId(exhibitor.group.id)
    }
  }, [exhibitor])

  // load around users at init of page and clean
  useEffect(() => {
    if (localGroupId && user) {
      dispatch(loadAroundUsers(localGroupId))
    }
    return () => {
      if (localGroupId && user) {
        dispatch(resetGroupId())
        dispatch(setAroundUsers([]))
        dispatch(setAroundUsersCount(0))
        pusherUtils.unsubscribe(`group-${localGroupId}`)
        pusherUtils.unsubscribe(`private-group-${localGroupId}-user-${user.id}`)
      }
    }
  }, [localGroupId, user, dispatch])

  // bind of events for enter / leave page and unbind
  useEffect(() => {
    if (window.pusher && localGroupId && settings.debug.events) {
      const channel = window.pusher.channel(`group-${localGroupId}`)
      if (channel) {
        channel.bind('enter', enterBind)
        channel.bind('leave', leaveBind)
      }
    }
    return () => {
      if (window.pusher && localGroupId && settings.debug.events) {
        const channel = window.pusher.channel(`group-${localGroupId}`)
        if (channel) {
          channel.unbind('enter')
          channel.unbind('leave')
        }
      }
    }
  }, [localGroupId, standChannel, enterBind, leaveBind])

  // gtm DATA
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (!calledGtm && exhibitor) {
        setCalledGtm(true)
        sendDataToGTM({
          event: 'page-view',
          pageTitle: `${exhibitor?.name}`,
          pageType: intl.formatMessage({ id: 'gtm.exhibitor.type' }),
          pageReferral
        })
        dispatch(setPageReferral(exhibitor?.name))
      }
    })
    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [exhibitor, pageReferral, calledGtm, setCalledGtm, sendDataToGTM, intl, dispatch])

  // system to prevent change page
  useEffect(() => {
    if (chairId) {
      setDirty()
    } else {
      setPristine()
    }
  }, [chairId, setDirty, setPristine])

  // auto enter into a room if param in query url
  useEffect(() => {
    if (exhibitor) {
      const search = location.search
      const salon = new URLSearchParams(search).get('salon')
      const table = new URLSearchParams(search).get('table')
      const salonIndex = table ? findIndexRoomById(exhibitor.rooms, parseInt(table, 10)) : '-1'
      const index = parseInt(salon || salonIndex || '-1', 10)
      if (index > -1) {
        setLiveRoomIndex(index)
        dispatch(
          setAutoEnterRoom({
            id: `exhibitor-${match.params.id}`,
            roomActive: index
          })
        )
      }
    }
  }, [match, location, exhibitor, dispatch])

  // auto enter in office room
  useEffect(() => {
    const search = new URLSearchParams(location.search).get('salon')
    if (
      user &&
      exhibitor &&
      search === 'bureau' &&
      autoEnterRoom.roomActive === -1 &&
      officeRoomIndex === -1
    ) {
      // find room office index
      const index = findRoomOfficeIndex(exhibitor.rooms, user)
      if (index > -1) {
        consoleUtils.log('AUTO enter in office, index=', index)
        dispatch(setOfficeRoomIndex(index))
        dispatch(
          setAutoEnterRoom({
            id: `exhibitor-${exhibitor.id}`,
            roomActive: index
          })
        )
      }
    }
  }, [location, exhibitor, autoEnterRoom, user, officeRoomIndex, dispatch])

  // auto enter in live room
  useEffect(() => {
    if (
      settings.prod &&
      exhibitor &&
      exhibitor.live &&
      autoEnterRoom.roomActive === -1 &&
      liveRoomIndex === -1
    ) {
      // find room broadcast index
      const index = findRoomBroadcastIndex(exhibitor.rooms)
      if (index > -1) {
        consoleUtils.log('AUTO enter in live')
        setLiveRoomIndex(index)
        dispatch(
          setAutoEnterRoom({
            id: `exhibitor-${exhibitor.id}`,
            roomActive: index
          })
        )
      }
    }
  }, [exhibitor, autoEnterRoom, liveRoomIndex, dispatch])

  useEffect(() => {
    if (error) {
      magicStream.error(error)
      dispatch(setExhibitorError(undefined))
    }
  }, [error, magicStream, dispatch])

  if (loading || !exhibitor) return <AppLoading />

  return (
    <StyledExhibitor>
      <ExhibitorHeader />
      <ExhibitorContent
        videoPlaying={videoPlaying}
        setVideoPlaying={setVideoPlaying}
        lastVideoPlaying={lastVideoPlaying}
        setLastVideoPlaying={setLastVideoPlaying}
      />
      <Prompt />
      <Helmet>
        <title>{`${exhibitor.name} - ${settings.theme.header.title}`}</title>
      </Helmet>
    </StyledExhibitor>
  )
}

const StyledExhibitor = styled.div`
  & {
    position: relative;
    width: 100%;
  }

  .MuiBreadcrumbs-ol {
    padding: 10px;
  }

  .MuiBreadcrumbs-li {
    display: flex;
    color: ${WHITE};

    & > p {
      color: ${WHITE};
    }
  }
  .MuiBreadcrumbs-separator {
    color: ${WHITE};
  }
`

export default ExhibitorPage
