import { MenuItem, Select, SelectProps } from '@material-ui/core'
import React from 'react'

interface ISelectProps extends SelectProps {
  list: any[]
  value?: any
  select: string
  label?: string
}

const AppSelect: React.FC<ISelectProps> = ({ list, value, select, label, ...props }) => {
  return (
    <Select value={value || ''} {...props}>
      {list.map((item, index) => (
        <MenuItem key={index} value={item[select]}>
          {item[label || select]}
        </MenuItem>
      ))}
    </Select>
  )
}

export default AppSelect
