import styled from '@emotion/styled'
import Pagination, { PaginationProps } from '@material-ui/lab/Pagination'
import React from 'react'

interface IAppPaginationProps extends PaginationProps {
  total: number
  count: number
  setPage?: (value: number) => void
}

const AppPagination: React.FC<IAppPaginationProps> = ({ total, count, setPage, ...props }) => {
  return (
    <StyledAppPagination
      count={Math.ceil(total / count)}
      page={props.page}
      onChange={(event: React.ChangeEvent<unknown>, value: number) => {
        setPage && setPage(value)
      }}
      color="primary"
      showFirstButton={false}
      showLastButton={false}
      {...props}
    />
  )
}

const StyledAppPagination = styled(Pagination)`
  & {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }
`

export default AppPagination
