import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { checkVersionInterceptor, errorInterceptor, handleIdentity, handleToken } from '../api/interceptors'
import { addRequestInterceptor, addResponseInterceptor } from '../api/requests'
import {
  alertRedux,
  appRedux,
  authRedux,
  chatRedux,
  conferenceRedux,
  exhibitorRedux,
  gtmRedux,
  highlightRedux,
  IBaseRootState,
  jitsiRedux,
  opentokRedux,
  participantRedux,
  productRedux,
  questionsRedux,
  settingsRedux,
  standRedux,
  zoomRedux
} from '../store'
import { IAlertState } from '../store/alerts'
import { IAppState } from '../store/app'
import { IAuthState } from '../store/auth'
import { IChatState } from '../store/chat'
import { IConferenceState } from '../store/conference'
import { IExhibitorState } from '../store/exhibitor'
import { IGtmState } from '../store/gtm'
import { IHighlightState } from '../store/hightlight'
import { IJitsiState } from '../store/jitsi'
import { IParticipantState } from '../store/participant'
import { IProductState } from '../store/product'
import { IQuestionState } from '../store/questions'
import { IRoomState } from '../store/room'
import { ISettingsState } from '../store/settings'
import { IStandState } from '../store/stand'
import { IZoomState } from '../store/zoom'

const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    authState: authRedux.reducer,
    alertState: alertRedux.reducer,
    appState: appRedux.reducer,
    chatState: chatRedux.reducer,
    exhibitorState: exhibitorRedux.reducer,
    conferenceState: conferenceRedux.reducer,
    highlightState: highlightRedux.reducer,
    jitsiState: jitsiRedux.reducer,
    gtmState: gtmRedux.reducer,
    participantState: participantRedux.reducer,
    productState: productRedux.reducer,
    roomState: opentokRedux.reducer,
    questionsState: questionsRedux.reducer,
    settingsState: settingsRedux.reducer,
    standState: standRedux.reducer,
    zoomState: zoomRedux.reducer
  })

export interface IRootState {
  router: RouterState
  authState: IAuthState
  alertState: IAlertState
  appState: IAppState
  chatState: IChatState
  exhibitorState: IExhibitorState
  conferenceState: IConferenceState
  highlightState: IHighlightState
  jitsiState: IJitsiState
  gtmState: IGtmState
  participantState: IParticipantState
  productState: IProductState
  roomState: IRoomState
  questionsState: IQuestionState
  settingsState: ISettingsState
  standState: IStandState
  zoomState: IZoomState
}

export type RootState = IBaseRootState & {
  router: RouterState
}

export const browserHistory = createBrowserHistory()

addRequestInterceptor(handleIdentity, undefined)
addRequestInterceptor(handleToken, undefined)
addResponseInterceptor(checkVersionInterceptor, errorInterceptor)

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducers(browserHistory),
  composeEnhancers(applyMiddleware(routerMiddleware(browserHistory), thunk))
)

export default store
