import { Container } from '@material-ui/core'
import React from 'react'
import { IRoom } from '../../../entities'
import SvgBackgroundEditMode from '../SvgBackground/SvgBackgroundEditMode'
import SvgContainer from '../SvgContainer/SvgContainer'

interface IProps {
  width: number
  height: number
  svgString: string
  rooms: IRoom[]
}

const EditableStand: React.FC<IProps> = ({ width, height, svgString, rooms }) => {
  return (
    <Container>
      <SvgContainer>
        <SvgBackgroundEditMode
          height={height || 1920}
          width={width || 1920}
          svgString={svgString}
          rooms={rooms}
        />
      </SvgContainer>
    </Container>
  )
}

export default EditableStand
