import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useMagicStream, usePagination, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { getConferences, setConferenceError, setConferencesSearch } from '../../store/conference'
import { convertQueryParamToObject } from '../../utils'
import { breakpoints } from '../breakpoints'
import ConferenceCard from '../cards/ConferenceCard'
import ConferenceFiltersSearch from '../conference/ConferenceFiltersSearch'
import ConferenceFiltersTri from '../conference/ConferenceFiltersTri'
import AppPagination from '../globals/AppPagination/AppPagination'
import LoadingCard from '../LoadingCard'
import Title from '../ui/Title/Title'

const ConferenceList = () => {
  const location = useLocation()
  const magicStream = useMagicStream()
  const { count, page, setPage } = usePagination(
    parseInt(new URLSearchParams(location.search).get('page') || '1', 10)
  )
  const [filterName, setFilterName] = useState(
    new URLSearchParams(location.search).get('name') || ''
  )
  const [filterKeyword, setFilterKeyword] = useState(
    new URLSearchParams(location.search).get('keyword') || ''
  )
  const [sortBy, setSortBy] = useState(new URLSearchParams(location.search).get('sortBy') || 'date')
  const [displayOrder, setDisplayOrder] = useState(filterName.length <= 0)
  const [filterCustom, setFilterCustom] = useState<{ [key: string]: number | string }>({
    type: new URLSearchParams(location.search).get('type') || 'Conférences à venir'
  })
  const [filterIsFavorite, setFilterIsFavorite] = useState(
    new URLSearchParams(location.search).get('favorite') === 'true' || false
  )
  const [filterKeywordsX, setFilterKeywordsX] = useState<{ [key: string]: boolean }>(
    convertQueryParamToObject(new URLSearchParams(location.search).get('keywordsX')) || {}
  )
  const conferences = useSelector((state: IRootState) => state.conferenceState.conferences)
  // const recommendedConferences = useSelector(
  //   (state: IRootState) => state.conferenceState.recommendedConferences
  // )
  const searchConferences = useSelector(
    (state: IRootState) => state.conferenceState.searchConferences
  )
  const error = useSelector((state: IRootState) => state.conferenceState.error)
  const loading = useSelector((state: IRootState) => state.conferenceState.loading)
  // const loadingRecommended = useSelector(
  //   (state: IRootState) => state.conferenceState.loadingRecommended
  // )
  const dispatch = useThunkDispatch()

  useEffect(() => {
    const search = `?page=${page}&name=${filterName}&keyword=${filterKeyword}&sortBy=${sortBy}&type=${
      filterCustom.type as string
    }&favorite=${filterIsFavorite}${
      filterKeywordsX
        ? `&keywordsX=${Object.keys(filterKeywordsX)
            .filter((id) => filterKeywordsX[id])
            .join(',')}`
        : ''
    }`
    // refresh url only if search as changed and is different of location
    if (
      searchConferences !== search &&
      ((location.search.indexOf('magicstream=true') > -1 &&
        !location.search.endsWith(`${search}&magicstream=true`)) ||
        (location.search.indexOf('magicstream=true') === -1 && !location.search.endsWith(search)))
    ) {
      setDisplayOrder(filterName.length <= 0)
      browserHistory.replace(
        `/conference${search}${
          location.search.indexOf('magicstream=true') > -1 ? '&magicstream=true' : ''
        }${location.search.indexOf('appointment=true') > -1 ? '&appointment=true' : ''}`
      )
    }
  }, [
    filterName,
    filterKeyword,
    filterIsFavorite,
    page,
    sortBy,
    filterCustom,
    location,
    searchConferences,
    filterKeywordsX
  ])

  // read url location to load data
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if ((location.search || searchConferences) && searchConferences !== location.search) {
        const locationSearch = new URLSearchParams(location.search)
        const currentPage = parseInt(locationSearch.get('page') || '1', 10)
        setPage(currentPage)
        const currentName = locationSearch.get('name') || ''
        setFilterName(currentName)
        const currentKeyword = locationSearch.get('keyword') || ''
        setFilterKeyword(currentKeyword)
        const currentSortBy = locationSearch.get('sortBy') || 'alphabetic'
        setSortBy(currentSortBy)
        const currentType = locationSearch.get('type') || 'Conférences à venir'
        setFilterCustom({ type: currentType })
        const currentFavorite = locationSearch.get('favorite') === 'true'
        setFilterIsFavorite(currentFavorite)
        const currentKeywordsX = locationSearch.get('keywordsX') || ''
        setFilterKeywordsX(convertQueryParamToObject(currentKeywordsX) || {})
        dispatch(
          getConferences({
            search: currentName,
            page: currentPage,
            sortBy: currentSortBy,
            keyword: currentKeyword,
            type: currentType,
            location: location.search,
            followed: currentFavorite,
            keywordsX: currentKeywordsX
          })
        )
      }
    }, 1000)

    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [location, searchConferences, setPage, dispatch])

  // useEffect(() => {
  //   dispatch(getRecommendedConferences())
  // }, [dispatch])

  // clean location
  useEffect(() => {
    return () => {
      dispatch(setConferencesSearch(undefined))
    }
  }, [dispatch])

  useEffect(() => {
    if (error) {
      magicStream.error(error)
      dispatch(setConferenceError(undefined))
    }
  }, [error, magicStream, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  return (
    <StyledListPage>
      {/* {(loadingRecommended || recommendedConferences.length !== 0) && (
        <>
          <Title variant="h2" titleId="conference.title_recommended" />
          <StyledContainerRecommended>
            {loadingRecommended && recommendedConferences.length === 0 && conferences.total === 0 && (
              <Slider>
                {' '}
                {Array(8)
                  .fill(0)
                  .map((value, index) => (
                    <div key={index}>
                      <LoadingCard cardType="conference" />
                    </div>
                  ))}
              </Slider>
            )}
            {!loadingRecommended && (
              <Slider>
                {recommendedConferences.map((conference, index) => (
                  <div key={index}>
                    <ConferenceCard conference={conference} />
                  </div>
                ))}
              </Slider>
            )}
          </StyledContainerRecommended>
        </>
      )} */}
      <StyledConferenceHeader>
        <Title
          variant="h2"
          titleId={filterIsFavorite ? 'conference.title_list.favorite' : 'conference.title_list'}
          suffix={conferences.total > 0 ? ` (${conferences.total})` : ''}
        />
        <ConferenceFiltersTri sortBy={sortBy} setSortBy={setSortBy} visible={displayOrder} />
      </StyledConferenceHeader>
      <Grid container spacing={5}>
        <Grid item xs={12} lg={4} xl={3}>
          <ConferenceFiltersSearch
            setPage={setPage}
            setFilterName={setFilterName}
            filterName={filterName}
            setFilterCustom={setFilterCustom}
            filterCustom={filterCustom}
            setFilterKeyword={setFilterKeyword}
            filterKeyword={filterKeyword}
            setFilterIsFavorite={setFilterIsFavorite}
            filterIsFavorite={filterIsFavorite}
            filterKeywordsX={filterKeywordsX}
            setFilterKeywordsX={setFilterKeywordsX}
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <StyledGridContainerList container spacing={1}>
            {loading &&
              conferences.total === 0 &&
              Array(9)
                .fill(0)
                .map((value, index) => (
                  <StyledGrid key={index} item xs={12}>
                    <LoadingCard cardType="conference" />
                  </StyledGrid>
                ))}
            {conferences.total > 0 &&
              conferences.items.map((conference, index) => (
                <StyledGrid key={index} item xs={12}>
                  <ConferenceCard conference={conference} goBackList />
                </StyledGrid>
              ))}
            {!loading && conferences.total === 0 && (
              <StyledGridEmptyList item xs={12}>
                <Alert severity="warning">
                  <FormattedMessage id="conference.list.empty" />
                </Alert>
              </StyledGridEmptyList>
            )}
          </StyledGridContainerList>
          {conferences.total > 0 && (
            <AppPagination total={conferences.total} count={count} page={page} setPage={setPage} />
          )}
        </Grid>
      </Grid>
    </StyledListPage>
  )
}

const StyledListPage = styled.div`
  & {
    max-width: 1280px;
    margin: 0 auto;
  }
`

// const StyledContainerRecommended = styled.div`
//   & {
//     margin-bottom: 1em;
//   }
// `

const StyledGrid = styled(Grid)`
  & {
    @media (min-width: 640px) {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }
    @media (min-width: ${breakpoints.lg}) {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
    @media (min-width: 1180px) {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }
    @media (min-width: 1400px) {
      flex-grow: 0;
      max-width: 33%;
      flex-basis: 33%;
    }
  }
`

const StyledConferenceHeader = styled.div`
  & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;

    @media (min-width: ${breakpoints.lg}) {
      flex-direction: row;
    }
    label {
      display: none;
    }
  }
`

const StyledGridContainerList = styled(Grid)`
  & {
    margin-bottom: 1em;
  }
`

const StyledGridEmptyList = styled(Grid)`
  & {
    margin-top: 1em;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
`

export default ConferenceList
