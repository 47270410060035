import { Location } from 'history'
import { IStream } from '../entities'
import settings from '../settings'
import consoleUtils from '../utils/consoleUtils'
import { CommonStream } from './CommonStream'
import createSession from './createSession'
import {
  ConnectStream,
  CreatePublisherOptions,
  CreateStream,
  CreateSubscriberOptions,
  IMediaStream
} from './IMediaStream'

export interface OpentokSession {
  session: OT.Session
  streams: IStream[]
  disconnect: () => void
}

export class OpentokStream extends CommonStream implements IMediaStream {
  // change false to true to activate console.log
  isDebug = false
  // variables opentok
  ready = false
  mode = 'default'
  sessionHelper?: OpentokSession
  streams?: IStream[]
  session?: OT.Session
  publisherPublished?: boolean
  publisherId?: string
  publisher?: OT.Publisher
  publisherPipId?: string
  publisherPip?: OT.Publisher
  publishPip?: boolean
  subscribersId: { [key: string]: string | undefined } = {}
  subscribers: { [key: string]: OT.Subscriber | undefined } = {}
  sessionEventHandlers?: {
    [key: string]: (event: any) => void
  }
  publisherEventHandlers?: {
    [key: string]: (event: any) => void
  }
  subscribersEventHandlers: {
    [key: string]: {
      [key: string]: (event: any) => void
    }
  } = {}
  onPublishSuccess?: () => void
  onPublishError?: (err: Error) => void
  onSubscribeSuccess?: () => void
  onSubscribeError?: (err: Error) => void
  // implementation of methods
  /**
   * Inspired by scriptjs
   *
   * @param loaded function called when script is add to dom and loaded
   */
  script(path: string, onLoaded: () => void): void {
    const isExist = window.document.getElementById('opentok')
    if (!isExist) {
      const doc = window.document
      const head = doc.head
      const el = doc.createElement('script')
      el.onload = el.onerror = () => {
        el.onload = null
        onLoaded()
      }
      el.id = 'opentok'
      el.async = true
      el.src = path
      head.insertBefore(el, head.lastChild)
    } else {
      onLoaded()
    }
  }
  preload(callback: () => void, location?: Location<any>): void {
    this.debug('OpentokStream > preload')
    this.script(settings.opentok.minJs, callback)
  }
  init({
    apiKey,
    sessionId,
    token,
    options,
    onStreamsUpdated,
    onConnect,
    onError
  }: CreateStream): void {
    this.debug('OpentokStream > init')
    if (apiKey && sessionId) {
      this.sessionHelper = createSession({
        apiKey,
        sessionId,
        token,
        options,
        onStreamsUpdated,
        onConnect,
        onError
      })
      this.session = this.sessionHelper.session
      this.streams = this.sessionHelper.streams
      this.mode = options?.mode || 'default'
    } else {
      consoleUtils.error('Opentok apiKey && sessionId are required !')
    }
  }
  isInit(): boolean {
    return this.sessionHelper !== undefined
  }
  connect(connectStream: ConnectStream): void {
    this.debug('OpentokStream > connect')
    if (this.session) {
      this.sessionEventHandlers = connectStream
      this.session.on(this.sessionEventHandlers)
    }
    this.ready = true
  }
  disconnect(): void {
    this.debug('OpentokStream > disconnect')
    if (this.sessionHelper) {
      if (this.session && this.sessionEventHandlers) this.session.off(this.sessionEventHandlers)
      this.sessionHelper.disconnect()
    }
    this.sessionEventHandlers = undefined
    this.sessionHelper = undefined
    this.session = undefined
    this.streams = undefined
  }
  createPublisher(
    publisherRef: React.RefObject<any>,
    options?: CreatePublisherOptions,
    publisherEventHandlers?: {
      [key: string]: (event: any) => void
    },
    pip?: boolean,
    onInit?: () => void,
    onError?: (err: Error) => void
  ): void {
    if (this.mode === 'viewer') {
      return
    }
    this.debug('OpentokStream > createPublisher')
    const container = document.createElement('div')
    container.setAttribute('class', 'OTPublisherContainer')
    if (publisherRef.current) {
      publisherRef.current.appendChild(container)
    }
    const newPublisherId = `${Date.now()}`

    const errorHandler = (err: Error) => {
      if (
        (pip && newPublisherId !== this.publisherPipId) ||
        (!pip && newPublisherId !== this.publisherId)
      ) {
        // Either this publisher has been recreated or the
        // component unmounted so don't invoke any callbacks
        return
      }
      if (onError) {
        onError(err)
      }
    }

    const properties: OT.PublisherProperties = {
      mirror: false,
      showControls: false,
      fitMode: 'contain',
      frameRate: 15,
      ...options
    }

    const pub = OT.initPublisher(container, properties, (err) => {
      if (
        (pip && newPublisherId !== this.publisherPipId) ||
        (!pip && newPublisherId !== this.publisherId)
      ) {
        // Either this publisher has been recreated or the
        // component unmounted so don't invoke any callbacks
        return
      }
      if (err) {
        errorHandler(err)
      } else if (onInit) {
        onInit()
      }
    })

    if (publisherEventHandlers) {
      this.publisherEventHandlers = publisherEventHandlers
      pub.on(publisherEventHandlers)
    }

    if (pip) {
      this.publisherPip = pub
      this.publisherPipId = newPublisherId
    } else {
      this.publisher = pub
      this.publisherId = newPublisherId
    }
  }
  publishToSession = () => {
    this.debug('OpentokStream > publishToSession')
    const publisher = this.publishPip ? this.publisherPip : this.publisher
    if (publisher && this.session) {
      this.session.publish(publisher, (err) => {
        if (this.publisherPublished) {
          // Either this publisher has been recreated or the
          // component unmounted so don't invoke any callbacks
          return
        }
        if (err) {
          this.onPublishError && this.onPublishError(err)
        } else {
          this.publisherPublished = true
          this.onPublishSuccess && this.onPublishSuccess()
        }
      })
    }
    this.publishPip = undefined
  }
  publish(pip?: boolean, onPublish?: () => void, onError?: (err: Error) => void): void {
    this.debug('OpentokStream > publish')
    if (this.session) {
      this.onPublishSuccess = onPublish
      this.onPublishError = onError
      this.publishPip = pip
      if (this.session.connection) {
        this.publishToSession()
      } else {
        this.session.once('sessionConnected', this.publishToSession)
      }
    }
  }
  destroyPublisher(pip?: boolean): void {
    this.debug('OpentokStream > destroyPublisher')
    const publisher = pip ? this.publisherPip : this.publisher
    if (publisher) {
      // this.publisher.off('streamCreated', streamCreatedHandler)
      if (this.publisherEventHandlers) {
        publisher.once('destroyed', () => {
          publisher && this.publisherEventHandlers && publisher.off(this.publisherEventHandlers)
        })
        this.publisherEventHandlers = undefined
      }
      if (this.session) {
        this.session.off('sessionConnected', this.publishToSession)
        this.session.unpublish(publisher)
      }
      // destroy
      publisher.destroy()
      // clean
      if (pip) {
        this.publisherPip = undefined
        this.publisherPipId = undefined
      } else {
        this.publisher = undefined
        this.publisherId = undefined
      }
      this.onPublishSuccess = undefined
      this.onPublishError = undefined
      this.publisherPublished = false
    }
  }
  createSubscriber(
    subscriberRef: React.RefObject<any>,
    stream: IStream,
    retry: boolean,
    maxRetryAttempts: number,
    retryAttemptTimeout: number,
    options?: CreateSubscriberOptions,
    subscriberEventHandlers?: {
      [key: string]: (event: any) => void
    },
    onSubscribe?: () => void,
    onError?: (err: Error) => void
  ): void {
    this.debug('OpentokStream > createSubscriber')
    if (!this.session) {
      return
    }
    const container = document.createElement('div')
    container.setAttribute('class', 'OTSubscriberContainer')
    if (subscriberRef.current) {
      subscriberRef.current.appendChild(container)
    }
    const newSubscriberId = `${Date.now()}`

    const properties: OT.SubscriberProperties = {
      showControls: false,
      fitMode: 'contain',
      style: {
        audioBlockedDisplayMode: 'auto'
        // https://tokbox.com/developer/guides/customize-ui/js/#audio-blocking-ui
      },
      ...options
    }

    let currentRetryAttempt = 0

    const sub = this.session.subscribe(stream, container, properties, (err) => {
      if (newSubscriberId !== this.subscribersId[stream.id]) {
        // Either this subscriber has been recreated or the
        // component unmounted so don't invoke any callbacks
        return
      }
      if (err && retry && currentRetryAttempt < maxRetryAttempts - 1) {
        // Error during subscribe function
        setTimeout(() => {
          currentRetryAttempt++
          this.createSubscriber(
            subscriberRef,
            stream,
            retry,
            maxRetryAttempts,
            retryAttemptTimeout,
            options,
            subscriberEventHandlers,
            onSubscribe,
            onError
          )
        }, retryAttemptTimeout)
        // If there is a retry action, do we want to execute the onError props function?
        // return;
      }
      if (err && onError) {
        onError(err)
      } else if (!err && onSubscribe) {
        onSubscribe()
      }
    })

    if (subscriberEventHandlers) {
      this.subscribersEventHandlers[stream.id] = subscriberEventHandlers
      sub.on(subscriberEventHandlers)
    }

    this.subscribers[stream.id] = sub
    this.subscribersId[stream.id] = newSubscriberId
  }
  destroySubscriber(stream: IStream): void {
    this.debug('OpentokStream > destroySubscriber')
    const subscriber = this.subscribers[stream.id]
    if (subscriber) {
      const subscriberEventHandlers = this.subscribersEventHandlers[stream.id]
      if (subscriberEventHandlers) {
        subscriber.once('destroyed', () => {
          subscriber && subscriberEventHandlers && subscriber.off(subscriberEventHandlers)
        })
      }

      if (this.session) {
        this.session.unsubscribe(subscriber)
      }
    }
    this.subscribers[stream.id] = undefined
    this.subscribersId[stream.id] = undefined
  }
  getStreams(): IStream[] {
    return this.streams || []
  }
  publishVideo(publish: boolean): void {
    if (publish) {
      this.startVideo()
    } else {
      this.stopVideo()
    }
  }
  startVideo(): void {
    if (this.publisher) {
      this.publisher.publishVideo(true)
    }
  }
  stopVideo(): void {
    if (this.publisher) {
      this.publisher.publishVideo(false)
    }
  }
  publishAudio(publish: boolean): void {
    if (publish) {
      this.startAudio()
    } else {
      this.stopAudio()
    }
  }
  startAudio(): void {
    if (this.publisher) {
      this.publisher.publishAudio(true)
    }
  }
  stopAudio(): void {
    if (this.publisher) {
      this.publisher.publishAudio(false)
    }
  }
  subscribeToAudio(stream: IStream, muted: boolean): void {
    const subscriber = this.subscribers[stream.id]
    if (subscriber) {
      subscriber.subscribeToAudio(muted)
    }
  }
  mute(stream: IStream): void {
    const subscriber = this.subscribers[stream.id]
    if (subscriber) {
      subscriber.subscribeToAudio(false)
    }
  }
  unmute(stream: IStream): void {
    const subscriber = this.subscribers[stream.id]
    if (subscriber) {
      subscriber.subscribeToAudio(true)
    }
  }
  startScreenShare(): void {
    throw new Error('Method not implemented.')
  }
  stopScreenShare(): void {
    throw new Error('Method not implemented.')
  }
  takePhoto(): string {
    if (this.publisher) {
      return `data:image/png;base64,${this.publisher.getImgData()}`
    }
    return ''
  }
  debug(message?: any, ...optionalParams: any[]): void {
    if (this.isDebug) {
      console.log(message, ...optionalParams)
    }
  }
}
