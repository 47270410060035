import React from 'react'

const SvgCheck: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M47.374 12.905a3.199 3.199 0 0 0-4.52-.231l-23.2 20.978-9.82-10.857a3.199 3.199 0 0 0-4.74 4.293l11.965 13.234a3.199 3.199 0 0 0 4.5.212l25.6-23.17a3.199 3.199 0 0 0 .215-4.459z"
    />
  </svg>
)

export default SvgCheck
