import { IQuestion } from '../entities/questions'
import settings from '../settings'
import { apiPostRequest, apiRequest } from './requests'

export const getQuestions = async (groupId: number | string) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/e_events/questions?group=${groupId}`
  )
  return response['hydra:member'] as IQuestion[]
}

export const getQuestion = async (id: number) => {
  const response = await apiRequest<IQuestion>('GET', `${settings.url.api}/e_events/question/${id}`)
  return response
}

export const postQuestion = async (question: string, groupId: number | string) => {
  const response = await apiPostRequest<IQuestion>(`${settings.url.api}/e_events/question`, {
    question,
    groupId
  })
  return response
}

export const upVoteQuestion = async (id: number) => {
  const response = await apiRequest<boolean>(
    'GET',
    `${settings.url.api}/e_events/question/${id}/upvote`
  )
  return response
}

export const removeUpVoteQuestion = async (id: number) => {
  const response = await apiRequest<boolean>(
    'GET',
    `${settings.url.api}/e_events/question/${id}/removeupvote`
  )
  return response
}

export const deleteQuestion = async (id: number) => {
  const response = await apiRequest<boolean>(
    'DELETE',
    `${settings.url.api}/e_events/question/${id}/delete`
  )
  return response
}

export const deleteAllQuestion = async (groupId: number) => {
  const response = await apiRequest<boolean>(
    'GET',
    `${settings.url.api}/e_events/question/delete?group=${groupId}`
  )
  return response
}
