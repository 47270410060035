import { IProduct, IProductAdd } from '../entities/product'
import settings from '../settings'
import { apiPatchRequest, apiRequest } from './requests'

export const getProducts = async (
  page: number = 1,
  search?: string,
  productCategoryId?: { id: number; name: string },
  exhibitorId?: string
) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/product?eventGroupId=${settings.eventGroupId}${
      productCategoryId
        ? `&productCategoryId=${productCategoryId.id ? productCategoryId.id : ''}`
        : ''
    }${exhibitorId ? `&businessEventExhibitorId=${exhibitorId ? exhibitorId : ''}` : ''}${
      search ? `&search=${search}` : ''
    }${page ? `&page=${page}` : ''}&limit=${settings.list.itemsPerPage}`
  )
  return { items: response['hydra:member'] as IProduct[], total: response['hydra:totalItems'] }
}

export const getProduct = async (id: string) => {
  const response = await apiRequest<IProduct>('GET', `${settings.url.api}/e_events/product/${id}`)
  return response
}

export const addProduct = async (product: IProductAdd) => {
  const response = await apiRequest<IProduct>('POST', `${settings.url.api}/product`, product, true)
  return response
}

export const updateProduct = async (product: IProductAdd) => {
  const response = await apiPatchRequest<IProduct>(
    `${settings.url.api}/product/${product.id}?eventGroupId=${settings.eventGroupId}`,
    product,
    true
  )
  return response
}

export const deleteProduct = async (id: number) => {
  const response = await apiRequest<IProduct>('DELETE', `${settings.url.api}/product/${id}`)
  return response
}
