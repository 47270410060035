import React from 'react'

const SvgCross: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M42.269 8.731a2.496 2.496 0 0 0-3.53 0L25.5 21.97 12.261 8.73a2.496 2.496 0 1 0-3.53 3.53L21.97 25.5 8.73 38.738a2.496 2.496 0 0 0 3.53 3.53L25.5 29.03 38.739 42.27a2.496 2.496 0 1 0 3.53-3.53L29.03 25.5 42.27 12.261a2.496 2.496 0 0 0 0-3.53z"
    />
  </svg>
)

export default SvgCross
