import styled from '@emotion/styled'
import { IconButton, List, ListSubheader } from '@material-ui/core'
import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { ReactComponent as ExpandIcon } from '../../../assets/images/icons/expand.svg'
import { useIsMobile, useThunkDispatch } from '../../../hooks'
import { browserHistory, IRootState } from '../../../redux'
import { setExtendedPanel } from '../../../store/app'
import { BACKGROUND_SECONDARY_LIGHT, WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD, HEADER_HEIGHT, MAGIC_STREAMS_HEIGHT } from '../../../theme/sizes'
import { breakpoints, breakpointsValues } from '../../breakpoints'
import FullScreenDialog from '../../modals/FullScreenDialog'
import Transition from '../../Transition'

interface IExtensiblePanelProps {
  full?: boolean
  half?: boolean
  height?: string
  titleKey: string
  subheaderNode?: ReactNode
  contentExtended?: ReactNode
  scrollTop?: boolean
  closeExtend?: boolean
  onExtend?: (extend: boolean) => void
}

const ExtensiblePanel: FC<IExtensiblePanelProps> = ({
  full,
  half,
  height,
  titleKey,
  subheaderNode,
  contentExtended,
  scrollTop,
  children,
  onExtend
}) => {
  const intl = useIntl()
  const location = useLocation()
  const isMobile = useIsMobile(breakpointsValues.lg)
  const anchorRef = useRef<HTMLUListElement>(null)
  const extendedPanel = useSelector((state: IRootState) => state.appState.extendedPanel)
  const dispatch = useThunkDispatch()

  const isExtended = useMemo(() => !!extendedPanel[titleKey], [titleKey, extendedPanel])

  const handleClickExtend = () => {
    // FIX
    browserHistory.replace(
      `${location.pathname}${location.search
        .replace('&magicstream=true', '')
        .replace('?magicstream=true', '')}`
    )
    dispatch(setExtendedPanel(titleKey, !extendedPanel[titleKey]))
    if (onExtend) {
      onExtend(!extendedPanel[titleKey])
    }
  }

  // auto scroll to top of content
  useEffect(() => {
    if (scrollTop) {
      if (anchorRef && anchorRef.current) {
        anchorRef.current.scrollTo(0, 0)
      }
    }
  }, [scrollTop])

  return (
    <>
      <StyledOverflow isMobile={isMobile} full={full} half={half} height={height}>
        <ListSubheader disableSticky component="div" id="list-subheader">
          <FormattedMessage id={titleKey} />
          <div>
            {subheaderNode}
            <IconButton onClick={handleClickExtend}>
              {!isExtended && <ExpandIcon />}
              {isExtended && <ExpandIcon />}
            </IconButton>
          </div>
        </ListSubheader>
        <StyledList>
          <List ref={anchorRef} aria-labelledby="list-subheader">
            {children}
          </List>
        </StyledList>
      </StyledOverflow>
      <FullScreenDialog
        title={intl.formatMessage({ id: titleKey })}
        open={isExtended}
        fullScreen
        TransitionComponent={Transition}
        scroll={'body'}
        onClose={handleClickExtend}
      >
        {contentExtended || children}
      </FullScreenDialog>
    </>
  )
}

const StyledOverflow = styled.div<{
  isMobile: boolean
  full?: boolean
  half?: boolean
  height?: string
}>`
  & {
    position: relative;
    background-color: ${WHITE};
    margin-top: 10px;
    border-radius: ${BORDER_RADIUS_CARD};
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.lg}) {
      height: ${(props) =>
    `${props.height
      ? props.height
      : props.full
        ? `calc(100vh - ${HEADER_HEIGHT} - 30px)`
        : props.half
          ? `calc(50vh - (${HEADER_HEIGHT} + ${MAGIC_STREAMS_HEIGHT} + 40px) / 2)`
          : `calc(100vh - ${HEADER_HEIGHT} - ${MAGIC_STREAMS_HEIGHT} - 30px)`
    }`};
      max-height: ${(props) =>
    `${props.height
      ? props.height
      : props.full
        ? `calc(100vh - ${HEADER_HEIGHT} - 30px)`
        : props.half
          ? `calc(50vh - (${HEADER_HEIGHT} + ${MAGIC_STREAMS_HEIGHT} + 40px) / 2)`
          : `calc(100vh - ${HEADER_HEIGHT} - ${MAGIC_STREAMS_HEIGHT} - 30px)`
    }`};
    }
    .MuiList-root {
      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 0;
      padding-bottom: 0;

      &::-webkit-scrollbar-track {
        background-color: ${WHITE};
      }
      &::-webkit-scrollbar {
        width: 12px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${BACKGROUND_SECONDARY_LIGHT};
        border-color: transparent;
        border-style: solid;
        border-right-width: 4px;
        border-left-width: 4px;
        border-top-width: 4px;
        border-bottom-width: 12px;
        background-clip: padding-box;
      }
    }
    #presence-list-subheader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .MuiButton-text {
        padding: 0 4px;
      }
    }
  }
`

const StyledList = styled.div`
  & {
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: 0 0 ${BORDER_RADIUS_CARD} ${BORDER_RADIUS_CARD};
  }
`

export default ExtensiblePanel
