import styled from '@emotion/styled'
import { StandardTextFieldProps, TextField } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { PRIMARY_COLOR } from '../../../theme'
import { BLACK_BLUE } from '../../../theme/colors'
import Editable from '../Editable'

export interface IInputTextProps {
  inputRef?: React.RefObject<HTMLDivElement>
  label?: string
  value?: string
  disabled?: boolean
  hideEditable?: boolean
  placeholder?: string
  onChangeValue: (value: string) => void
}

const InputText: React.FC<IInputTextProps & StandardTextFieldProps> = ({
  inputRef,
  label,
  value,
  disabled,
  placeholder,
  onChangeValue,
  hideEditable,
  ...props
}) => {
  const [currentInputRef, setCurrentInputRef] = useState(useRef<HTMLDivElement>(null))
  const [editing, setEditing] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)

  const onBlur = () => {
    if (currentValue && value !== currentValue && onChangeValue) {
      onChangeValue(currentValue)
    }
    setEditing(false)
  }

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  useEffect(() => {
    if (inputRef) {
      setCurrentInputRef(inputRef)
    }
  }, [inputRef])

  return (
    <StyledInputText hideEditable={hideEditable}>
      <TextField
        label={label}
        inputRef={currentInputRef}
        value={currentValue}
        disabled={disabled}
        onChange={(event: any) => setCurrentValue(event.target.value)}
        onFocus={() => setEditing(true)}
        onBlur={onBlur}
        placeholder={placeholder}
        multiline
        {...props}
      />
      {!hideEditable && (
        <Editable show={!disabled && !editing} onClick={() => currentInputRef.current?.focus()} />
      )}
    </StyledInputText>
  )
}

// language=SCSS
const StyledInputText = styled.div<{ hideEditable?: boolean }>`
  & {
    position: relative;

    .MuiInputBase-root {
      &:before,
      &:after {
        display: none;
      }
    }
    .MuiInput-root {
      margin-bottom: 5px;
      border: 1px solid transparent;

      &:focus,
      &:focus-within {
        border: 1px solid ${PRIMARY_COLOR};
      }
    }
    .MuiFormControl-root {
      width: 100%;
      ${(props) => (props.hideEditable ? 'margin-bottom: 0px;' : '')}
    }
    input {
      cursor: default;
      color: ${BLACK_BLUE};
    }
  }
`

export default InputText
