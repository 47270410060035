import styled from '@emotion/styled'
import { Drawer } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { loadAgenda, setAgendaError } from '../../store/app'
import { WHITE } from '../../theme/colors'
import { CONTACTS_MENU_WIDTH, HEADER_HEIGHT } from '../../theme/sizes'
import { breakpoints } from '../breakpoints'
import Loading from '../Loading'
import Agenda from './Agenda'

const AgendaDrawer: FC = () => {
  const magicStream = useMagicStream()
  const [ready, setReady] = useState(false)
  const openAgenda = useSelector((state: IRootState) => state.appState.openAgenda)
  const agenda = useSelector((state: IRootState) => state.appState.agenda)
  const loadingAgenda = useSelector((state: IRootState) => state.appState.loadingAgenda)
  const errorAgenda = useSelector((state: IRootState) => state.appState.errorAgenda)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    if (openAgenda) {
      dispatch(loadAgenda())
    }
  }, [openAgenda, dispatch])

  useEffect(() => {
    if (agenda && agenda.length) {
      setReady(true)
    }
  }, [agenda])

  useEffect(() => {
    if (errorAgenda) {
      magicStream.error(errorAgenda)
      dispatch(setAgendaError(undefined))
    }
  }, [errorAgenda, magicStream, dispatch])

  return (
    <StyledDrawer anchor="right" variant="persistent" open={openAgenda}>
      {loadingAgenda && <Loading />}
      {ready && <Agenda />}
      {!loadingAgenda && !ready && <FormattedMessage id="agenda.empty" />}
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer) <{ open: boolean }>`
  flex-shrink: 0;

  & > div {
    z-index: 1202;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(${HEADER_HEIGHT} + 50px);
    width: 100%;
    height: ${(props) => `calc(100vh - ${HEADER_HEIGHT})`};
    background-color: ${WHITE};

    @media (min-width: ${breakpoints.lg}) {
      width: calc(${CONTACTS_MENU_WIDTH} - 20px);
    height: ${(props) => `calc(100vh - ${HEADER_HEIGHT} - 30px - 30px)`};
      margin-right: 20px;
    }
  }
`

export default AgendaDrawer
