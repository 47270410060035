import styled from '@emotion/styled'
import { Chip, ClickAwayListener, Paper, Popper, TextField } from '@material-ui/core'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IKeyword } from '../../entities/keyword'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import {
  editKeywordForUser,
  loadKeywordsCreatedByUser,
  removeKeywordForUser
} from '../../store/participant/thunks'
import { WHITE } from '../../theme/colors'
import Loading from '../Loading'
import ConfirmDialog from '../modals/ConfirmDialog'
import AppButton from '../ui/AppButton/AppButton'
import { Label } from '../ui/LabelWrapper/LabelWrapper'

interface ISearchByTagContactProps {
  filterTags: { [key: string]: boolean }
  setFilterTags: (value: { [key: string]: boolean }) => void
  filterKeywordsX: { [key: string]: boolean }
  setFilterKeywordsX: (value: { [key: string]: boolean }) => void
  filterKeywordsY: { [key: string]: boolean }
  setFilterKeywordsY: (value: { [key: string]: boolean }) => void
}

const SearchByTagContact: React.FC<ISearchByTagContactProps> = ({
  filterTags,
  setFilterTags,
  filterKeywordsX,
  setFilterKeywordsX,
  filterKeywordsY,
  setFilterKeywordsY
}) => {
  const intl = useIntl()
  const [tagToDelete, setTagToDelete] = useState<IKeyword | null>(null)
  const [tagToEdit, setTagToEdit] = useState<IKeyword | null>(null)
  const [tagToEditNewTitle, setTagToEditNewTitle] = useState('')

  const anchorRefs = useRef<HTMLElement[]>([])

  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const keywordsCreatedByUser = useSelector(
    (state: IRootState) => state.participantState.keywordsCreatedByUser
  )
  const loadingKeywordsCreatedByUser = useSelector(
    (state: IRootState) => state.participantState.loadingKeywordsCreatedByUser
  )
  const keywordsX = useSelector((state: IRootState) => state.appState.keywordsX)
  const keywordsXLabel = useSelector((state: IRootState) => state.appState.labelKeywordsX)
  const loadingKeywordsX = useSelector((state: IRootState) => state.appState.loadingKeywordsX)
  const keywordsY = useSelector((state: IRootState) => state.appState.keywordsY)
  const keywordsYLabel = useSelector((state: IRootState) => state.appState.labelKeywordsY)
  const loadingKeywordsY = useSelector((state: IRootState) => state.appState.loadingKeywordsY)
  const user = useSelector((state: IRootState) => state.appState.user)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    if (user?.id) {
      dispatch(loadKeywordsCreatedByUser(user.id))
    }
  }, [user, dispatch])

  const toggleTagSearch = (keyword: IKeyword) => {
    setFilterTags({ ...filterTags, [keyword.id]: !filterTags[keyword.id] })
  }

  const toggleKeywordXSearch = (keyword: IKeyword) => {
    setFilterKeywordsX({ ...filterKeywordsX, [keyword.id]: !filterKeywordsX[keyword.id] })
  }

  const toggleKeywordYSearch = (keyword: IKeyword) => {
    setFilterKeywordsY({ ...filterKeywordsY, [keyword.id]: !filterKeywordsY[keyword.id] })
  }

  const handleDeleteKeyword = async (keyword: IKeyword) => {
    dispatch(removeKeywordForUser(user?.id!, keyword))
    setFilterTags({ ...filterTags, [keyword.id]: false })
  }
  const handleEditKeyword = async (keyword: IKeyword, title: string) => {
    dispatch(editKeywordForUser(user?.id!, keyword, title))
    setTagToEdit(null)
  }

  return (
    <>
      {!eventCo?.eventcoAppointment && (
        <>
          <Label id="is-contact-label">
            <FormattedMessage id="contacts.tags.label" />
          </Label>
          <StyledSearchByTagContact>
            {!loadingKeywordsCreatedByUser && keywordsCreatedByUser.length ? (
              <>
                {keywordsCreatedByUser.map((keyword, index) => (
                  <Chip
                    key={index}
                    label={keyword.title}
                    title={intl.formatMessage(
                      { id: 'contacts.tags.edit.title' },
                      { keyword: keyword.title }
                    )}
                    ref={(el) => (anchorRefs.current[keyword.id] = el!)}
                    size="small"
                    onClick={() => toggleTagSearch(keyword)}
                    color={filterTags[keyword.id] ? 'primary' :
                      (keyword.childrenCount !== undefined && keyword.childrenCount > 0 ? 'secondary' :
                        'default')}
                    onDelete={() => setTagToDelete(keyword)}
                    onDoubleClick={() => setTagToEdit(keyword)}
                  />
                ))}
                <ConfirmDialog
                  open={!!tagToDelete}
                  message={intl.formatMessage({ id: 'confirm.delete.keyword' })}
                  okEvent={() => {
                    if (tagToDelete) {
                      handleDeleteKeyword(tagToDelete)
                    }
                    setTagToDelete(null)
                  }}
                  cancelEvent={() => {
                    setTagToDelete(null)
                  }}
                />
                {tagToEdit && anchorRefs.current[tagToEdit?.id!] && (
                  <Popper
                    modifiers={{
                      flip: {
                        enabled: true
                      }
                    }}
                    open={!!tagToEdit}
                    anchorEl={anchorRefs.current[tagToEdit?.id!]}
                    style={{ zIndex: 1400 }}
                  >
                    <ClickAwayListener onClickAway={() => setTagToEdit(null)}>
                      <StyledPaper>
                        <TextField
                          value={tagToEditNewTitle || tagToEdit?.title!}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setTagToEditNewTitle(event.target.value)
                          }}
                          placeholder={intl.formatMessage({ id: 'keyword.title.placeholder' })}
                          label={intl.formatMessage({ id: 'keyword.input.label' })}
                          autoFocus
                        />
                        <StyledAppButton
                          onClick={() => {
                            if (
                              tagToEdit &&
                              tagToEdit.title !== tagToEditNewTitle &&
                              tagToEditNewTitle.length > 0
                            ) {
                              handleEditKeyword(tagToEdit, tagToEditNewTitle)
                            }
                            setTagToEdit(null)
                            setTagToEditNewTitle('')
                          }}
                        >
                          <FormattedMessage id="keyword.editPopper.button.save" />
                        </StyledAppButton>
                      </StyledPaper>
                    </ClickAwayListener>
                  </Popper>
                )}
              </>
            ) : loadingKeywordsCreatedByUser ? (
              <Loading />
            ) : (
              <FormattedMessage id="contacts.tags.no.tag.created" />
            )}
          </StyledSearchByTagContact>
        </>
      )}

      {keywordsX.length > 0 && (
        <>
          <Label id="x-keywords">
            {keywordsXLabel ? keywordsXLabel : <FormattedMessage id="contacts.xKeywords.label" />}
          </Label>
          <StyledSearchByKeywordsContact>
            {!loadingKeywordsX ? (
              keywordsX.map((keyword, index) => (
                <Chip
                  key={index}
                  label={keyword.title}
                  title={intl.formatMessage(
                    { id: 'contacts.tags.edit.title' },
                    { keyword: keyword.title }
                  )}
                  size="small"
                  onClick={() => toggleKeywordXSearch(keyword)}
                  color={filterKeywordsX[keyword.id] ? 'primary' : 'default'}
                />
              ))
            ) : loadingKeywordsX ? (
              <Loading />
            ) : (
              <FormattedMessage id="contacts.tags.no.tag.created" />
            )}
          </StyledSearchByKeywordsContact>
        </>
      )}
      {keywordsY.length > 0 && (
        <>
          <Label id="y-keywords">
            {keywordsYLabel ? keywordsYLabel : <FormattedMessage id="contacts.yKeywords.label" />}
          </Label>
          <StyledSearchByKeywordsContact>
            {!loadingKeywordsY ? (
              keywordsY.map((keyword, index) => (
                <Chip
                  key={index}
                  label={keyword.title}
                  title={intl.formatMessage(
                    { id: 'contacts.tags.edit.title' },
                    { keyword: keyword.title }
                  )}
                  size="small"
                  onClick={() => toggleKeywordYSearch(keyword)}
                  color={filterKeywordsY[keyword.id] ? 'primary' : 'default'}
                />
              ))
            ) : loadingKeywordsY ? (
              <Loading />
            ) : (
              <FormattedMessage id="contacts.tags.no.tag.created" />
            )}
          </StyledSearchByKeywordsContact>
        </>
      )}
    </>
  )
}

const StyledSearchByTagContact = styled.div`
  & {
    width: 100%;
    background-color: ${WHITE};
    margin-top: 15px;

    & > div {
      margin-right: 5px;
      margin-bottom: 5px;
      max-width: 100%;
    }
  }
`

const StyledSearchByKeywordsContact = styled.div`
  & {
    width: 100%;
    background-color: ${WHITE};
    margin-top: 15px;

    & > div {
      margin-right: 5px;
      margin-bottom: 5px;
      max-width: 100%;
    }
  }
`

const StyledPaper = styled(Paper)`
  & {
    padding: 25px;
  }
`

const StyledAppButton = styled(AppButton)`
  & {
    margin-top: 15px;
  }
`

export default SearchByTagContact
