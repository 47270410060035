import { IContact } from '../../entities'
import {
  ICall,
  IChatChannel,
  IConversation,
  IMagicStream,
  IMessage,
  INotification
} from '../../entities/chat'
import { ActionsTypes } from './model'

export const setLoadedConversations = (loadedConversations: boolean) => ({
  type: ActionsTypes.SET_LOADED_CONVERSATIONS,
  loadedConversations
})

export const setLoadingConversations = (loadingConversations: boolean) => ({
  type: ActionsTypes.SET_LOADING_CONVERSATIONS,
  loadingConversations
})

export const setErrorConversations = (errorConversations?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_CONVERSATIONS,
  errorConversations
})

export const setLoadingConversation = (loadingConversation: boolean) => ({
  type: ActionsTypes.SET_LOADING_CONVERSATION,
  loadingConversation
})

export const setErrorConversation = (errorConversation?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_CONVERSATION,
  errorConversation
})

export const setLoadingMessages = (loading: boolean) => ({
  type: ActionsTypes.SET_LOADING_MESSAGES,
  loading
})

export const setErrorMessages = (errorMessages?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_MESSAGES,
  errorMessages
})

export const setOpenChat = (openChat: boolean) => ({
  type: ActionsTypes.SET_OPEN_CHAT,
  openChat
})

export const setMinimizeChat = (minimizeChat: boolean) => ({
  type: ActionsTypes.SET_MINIMIZE_CHAT,
  minimizeChat
})

export const setErrorSending = (errorSending?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_SENDING,
  errorSending
})

export const setSending = (sending: boolean) => ({
  type: ActionsTypes.SET_SENDING,
  sending
})

export const setSended = (sended: boolean) => ({
  type: ActionsTypes.SET_SENDED,
  sended
})

export const setSendedLive = (sendedLive: boolean) => ({
  type: ActionsTypes.SET_SENDED_LIVE,
  sendedLive
})

export const setInvitationInProgress = (invitationInProgress: boolean) => ({
  type: ActionsTypes.SET_INVITATION_IN_PROGRESS,
  invitationInProgress
})

export const setCurrentConversation = (currentConversation: number) => ({
  type: ActionsTypes.SET_CURRENT_CONVERSATION,
  currentConversation
})

export const addConversation = (conversation: IConversation, firstPosition?: boolean) => ({
  type: ActionsTypes.ADD_CONVERSATION,
  conversation,
  firstPosition
})

export const addMessageToConversation = (contactId: number, messageSended: IMessage) => ({
  type: ActionsTypes.ADD_MESSAGE_TO_CONVERSATION,
  contactId,
  messageSended
})

export const addMessagesToConversation = (contactId: number, messages: IMessage[]) => ({
  type: ActionsTypes.ADD_MESSAGES_TO_CONVERSATION,
  contactId,
  messages
})

export const setChannelsToBind = (channelsToBind: IChatChannel[]) => ({
  type: ActionsTypes.SET_CHANNELS_TO_BIND,
  channelsToBind
})

export const setLoadingChannels = (loadingChannels: boolean) => ({
  type: ActionsTypes.SET_LOADING_CHANNELS,
  loadingChannels
})

export const setErrorChannels = (errorChannels?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_CHANNELS,
  errorChannels
})

export const incrementUnread = (unread: IMessage) => ({
  type: ActionsTypes.INCREMENT_UNREAD,
  unread
})

export const decrementUnread = (contactId: number) => ({
  type: ActionsTypes.DECREMENT_UNREAD,
  contactId
})

export const setCall = (call: ICall) => ({
  type: ActionsTypes.SET_CALL,
  call
})

export const setNotification = (notification: INotification | null) => ({
  type: ActionsTypes.SET_NOTIFICATION,
  notification
})

export const setMagicStreams = (streams: IMagicStream[]) => ({
  type: ActionsTypes.SET_MAGIC_STREAMS,
  streams
})

export const addMagicStream = (stream: IMagicStream) => ({
  type: ActionsTypes.ADD_MAGIC_STREAM,
  stream
})

export const setLoadingMagicStreams = (loadingMagicStreams: boolean) => ({
  type: ActionsTypes.SET_LOADING_MAGIC_STREAMS,
  loadingMagicStreams
})

export const setErrorMagicStreams = (errorMagicStreams?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_MAGIC_STREAMS,
  errorMagicStreams
})

export const setMagicStreamsUser = (streamsUser: IMagicStream[]) => ({
  type: ActionsTypes.SET_MAGIC_STREAMS_USER,
  streamsUser
})

export const setLoadingMagicStreamsUser = (loadingMagicStreamsUser: boolean) => ({
  type: ActionsTypes.SET_LOADING_MAGIC_STREAMS_USER,
  loadingMagicStreamsUser
})

export const setErrorMagicStreamsUser = (errorMagicStreamsUser?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_MAGIC_STREAMS_USER,
  errorMagicStreamsUser
})

export const setNewMessageClicked = (key: number, newMessageClicked: boolean) => ({
  type: ActionsTypes.SET_NEW_MESSAGE_CLICKED,
  key,
  newMessageClicked
})

export const setCallEnded = (key: number, callEnded: boolean) => ({
  type: ActionsTypes.SET_CALL_ENDED,
  key,
  callEnded
})

export const setContactAppointment = (contactAppointment: IContact | null) => ({
  type: ActionsTypes.SET_CONTACT_APPOINTMENT,
  contactAppointment
})

export const setOpenNotifications = (openNotifications: boolean) => ({
  type: ActionsTypes.SET_OPEN_NOTIFICATIONS,
  openNotifications
})
