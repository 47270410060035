import { IStream, MediaError } from '../entities'

export default function createSession({
  apiKey,
  sessionId,
  token,
  onStreamsUpdated,
  onConnect,
  onError,
  options
}: {
  apiKey: string
  sessionId: string
  token: string
  onStreamsUpdated?: (sts: IStream[]) => void
  onConnect?: () => void
  onError?: (err: MediaError) => void
  options?: { connectionEventsSuppressed: boolean }
}) {
  if (!apiKey) {
    throw new Error('Missing apiKey')
  }

  if (!sessionId) {
    throw new Error('Missing sessionId')
  }

  if (!token) {
    throw new Error('Missing token')
  }

  let streams: IStream[] = []

  let onStreamCreated: ((event: { stream: IStream }) => void) | null = (event) => {
    const index = streams.findIndex((stream) => stream.id === event.stream.id)
    if (index < 0) {
      streams.push(event.stream)
      onStreamsUpdated && onStreamsUpdated(streams)
    }
  }

  let onStreamDestroyed: ((event: { stream: IStream }) => void) | null = (event) => {
    const index = streams.findIndex((stream) => stream.id === event.stream.id)
    if (index >= 0) {
      streams.splice(index, 1)
      onStreamsUpdated && onStreamsUpdated(streams)
    }
  }

  let eventHandlers: {
    streamCreated?: (event: { stream: IStream }) => void
    streamDestroyed?: (event: { stream: IStream }) => void
  } = {
    streamCreated: onStreamCreated,
    streamDestroyed: onStreamDestroyed
  }

  let session: OT.Session | null = OT.initSession(apiKey, sessionId, options)
  session.on(eventHandlers)
  session.connect(token, (err) => {
    if (!session) {
      // Either this session has been disconnected or OTSession
      // has been unmounted so don't invoke any callbacks
      return
    }
    if (err && onError) {
      onError(err)
    } else if (!err && onConnect) {
      onConnect()
    }
  })

  return {
    session,
    streams,
    disconnect() {
      if (session) {
        session.off(eventHandlers)
        session.disconnect()
      }

      streams = []
      onStreamCreated = null
      onStreamDestroyed = null
      eventHandlers = {}
      session = null
    }
  }
}
