import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { ParsedUrlQueryInput, stringify } from 'querystring'

export const apiInstance = axios.create({ withCredentials: true })
export const openApiInstance = axios.create({ withCredentials: true })

export const apiRequest = async <T = void>(
  method: Method,
  url: string,
  payload?: object,
  isProtected: boolean = true
) => {
  const instance = isProtected ? apiInstance : openApiInstance
  const response = await instance.request<T>({
    method,
    url,
    data: payload
  })

  return response.data
}

export const apiFormRequest = async <T = void>(
  method: Method,
  url: string,
  payload?: ParsedUrlQueryInput,
  isProtected: boolean = true,
  contentType: string = 'application/x-www-form-urlencoded'
) => {
  const data = stringify(payload)
  const instance = isProtected ? apiInstance : openApiInstance
  const response = await instance.request<T>({
    method,
    url,
    data,
    headers: {
      'Content-Type': contentType
    }
  })

  return response.data
}

export const apiFormRawRequest = async <T = void>(
  method: Method,
  url: string,
  payload?: FormData,
  isProtected: boolean = true,
  contentType: string = 'multipart/form-data'
) => {
  const data = payload
  const instance = isProtected ? apiInstance : openApiInstance
  const response = await instance.request<T>({
    method,
    url,
    data,
    headers: {
      'Content-Type': contentType
    }
  })

  return response.data
}

export const apiPostRequest = async <T = void>(
  url: string,
  payload?: ParsedUrlQueryInput,
  isProtected: boolean = true,
  contentType: string = 'application/ld+json'
) => {
  const instance = isProtected ? apiInstance : openApiInstance
  const response = await instance.request<T>({
    method: 'POST',
    url,
    data: payload,
    headers: {
      'Content-Type': contentType
    }
  })

  return response.data
}

export const apiPatchRequest = async <T = void>(
  url: string,
  payload?: ParsedUrlQueryInput,
  isProtected: boolean = true,
  contentType: string = 'application/merge-patch+json'
) => {
  const instance = isProtected ? apiInstance : openApiInstance
  const response = await instance.request<T>({
    method: 'PATCH',
    url,
    data: payload,
    headers: {
      'Content-Type': contentType
    }
  })

  return response.data
}

export const apiRequestAxios = async <T = void>(
  config: AxiosRequestConfig,
  isProtected: boolean = true
) => {
  const instance = isProtected ? apiInstance : openApiInstance
  const response = await instance.request<T>(config)

  return response.data
}

type RequestInterceptorFullFilled = (
  value: AxiosRequestConfig
) => AxiosRequestConfig | Promise<AxiosRequestConfig>
type RequestInterceptorFullRejected = (error: any) => any
type ResponseInterceptorFullFilled = (
  value: AxiosResponse
) => AxiosResponse | Promise<AxiosResponse>
type ResponseInterceptorFullRejected = (error: any) => any

export const addRequestInterceptor = (
  onFulfilled: RequestInterceptorFullFilled | undefined,
  onRejected: RequestInterceptorFullRejected | undefined
) => {
  apiInstance.interceptors.request.use(onFulfilled, onRejected)
}

export const addResponseInterceptor = (
  onFulfilled: ResponseInterceptorFullFilled | undefined,
  onRejected: ResponseInterceptorFullRejected | undefined
) => {
  apiInstance.interceptors.response.use(onFulfilled, onRejected)
}
