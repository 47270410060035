import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRelatedEvent } from '../../entities'
import { useDetectImageExist, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setPageReferral } from '../../store/gtm'
import AppButton from '../ui/AppButton/AppButton'

interface IRelatedSmallCardProps {
  relatedEvent: IRelatedEvent
  onClick?: () => void
}

const RelatedSmallCard: React.FC<IRelatedSmallCardProps> = ({ relatedEvent, onClick }) => {
  const { sendDataToGTM } = useGTM()
  const location = useSelector((state: IRootState) => state.appState.location)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const image = useDetectImageExist(relatedEvent.image)
  const dispatch = useThunkDispatch()

  const onClickCard = () => {
    dispatch(setPageReferral(`${pageReferral}::card-relatedEvent-small`))
    sendDataToGTM({
      event: 'click-popin-after-conference',
      cardType: 'RelatedEvent',
      cardName: `${relatedEvent.title}`
    })
    const urls = relatedEvent.url.split('?')
    const pathname = urls[0]
    const search = `?${urls[1]}`
    if (location && location.split('?')[0] === pathname) {
      browserHistory.replace({
        pathname,
        search,
        state: { goBack: false }
      })
    } else {
      browserHistory.push({
        pathname,
        search,
        state: { goBack: false }
      })
    }
    onClick && onClick()
  }

  return (
    <>
      <StyledCard elevation={1}>
        <StyledCardActionArea>
          <StyledCardMedia image={image} title={relatedEvent.title} />
        </StyledCardActionArea>
        <StyledCardContent>
          <StyledText>
            <StyledTitle>{relatedEvent.title}</StyledTitle>
            <StyledDescription>{relatedEvent.description}</StyledDescription>
          </StyledText>
          <StyledAppButton onClick={onClickCard}>
            <FormattedMessage id="conference.RelatedSmallCard.items.link" />
          </StyledAppButton>
        </StyledCardContent>
      </StyledCard>
    </>
  )
}

const StyledCard = styled(Card)`
  & {
    width: calc(100% - 16px);
    margin: 8px;
    height: calc(100% - 16px);
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-flow: column;
  }
`

const StyledCardActionArea = styled(CardActionArea)`
  & {
    position: relative;
  }
`

const StyledAppButton = styled(AppButton)`
  & {
    margin-top: 15px;
  }
`

const StyledCardMedia = styled(CardMedia)`
  & {
    width: 100%;
    height: 142px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
`

const StyledCardContent = styled(CardContent)`
  & {
    padding: 12px;
    display: flex;
    flex-direction: column;
    &:hover {
      cursor: pointer;
    }
    padding-bottom: 12px !important;
    flex: 1 1 auto;
  }
`

const StyledText = styled.div`
  & {
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 8px;
    max-height: 90px;
  }
`

const StyledTitle = styled.h3`
  & {
    margin-top: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
  }
`

const StyledDescription = styled.div`
  & {
    font-size: 14px;
    line-height: 17px;
  }
`

export default RelatedSmallCard
