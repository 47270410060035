import styled from '@emotion/styled'
import { InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { MagicStreamType } from '../../entities'

const listType = [
  'all',
  MagicStreamType.NEW_MESSAGE,
  MagicStreamType.INVITATION_SENT,
  MagicStreamType.INVITATION_RECEIVED,
  MagicStreamType.APPOINTMENT,
  MagicStreamType.ANNOUNCE,
  MagicStreamType.REMINDER_CONFERENCE,
  MagicStreamType.SUGGEST_CONFERENCE,
  MagicStreamType.TABLE_QUEUE,
  'toast'
]
interface ISelectStreamTypeProps {
  streamType: string
  onChange: (value: string) => void
}
const SelectStreamType: FC<ISelectStreamTypeProps> = ({ streamType, onChange }) => {
  const [type, setType] = useState<string>(streamType || 'all')
  return (
    <StyledSelectStreamType>
      <InputLabel id="stream-type">
        <FormattedMessage id="select.stream.type" />
      </InputLabel>
      <Select
        labelId="stream-type"
        value={type}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          const newType = event.target.value as string
          setType(newType)
          onChange(newType)
        }}
      >
        {listType.map((t, index) => (
          <MenuItem key={index} value={t}>
            <FormattedMessage id={`stream.type.${t}`} />
          </MenuItem>
        ))}
      </Select>
    </StyledSelectStreamType>
  )
}

const StyledSelectStreamType = styled.div`
  display: flex;
  flex-direction: column;
`

export default SelectStreamType
