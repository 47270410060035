import styled from '@emotion/styled'
import { Button, FormControl, TextField } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import EditDialog from '../modals/EditDialog'
import FormContainer from '../ui/Form/FormContainer'
import { Label } from '../ui/LabelWrapper/LabelWrapper'

interface IExhibitorShortBodyEditProps {
  shortBody?: string
  onChange: (value: string) => void
}

const ExhibitorShortBodyEdit: React.FC<IExhibitorShortBodyEditProps> = ({
  shortBody,
  onChange
}) => {
  const intl = useIntl()
  const [shortBodyValue, setShortBodyValue] = useState<string>()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const updateShortBody = () => {
    setOpen(false)
    onChange && onChange(shortBodyValue!)
  }

  useEffect(() => {
    if (shortBody) setShortBodyValue(shortBody)
  }, [shortBody])

  return (
    <StyledDiv>
      <StyledButton
        variant="contained"
        color="secondary"
        startIcon={<CreateIcon fontSize="small" />}
        onClick={handleClick}
      >
        <FormattedMessage id="button.shortBody.edit" />
      </StyledButton>
      <EditDialog
        title={intl.formatMessage({
          id: 'button.shortBody.edit'
        })}
        open={open}
        saveEvent={updateShortBody}
        cancelEvent={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <FormContainer>
          <FormControl>
            <Label htmlFor="short_body" id="short_body_id_label">
              <FormattedMessage id="shortBody.label" />
            </Label>
            <TextField
              id="short_body"
              aria-labelledby="short_body_id_label"
              value={shortBodyValue || ''}
              placeholder={intl.formatMessage({ id: 'shortBody.placeholder' })}
              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setShortBodyValue(event.target.value)
              }}
            />
          </FormControl>
        </FormContainer>
      </EditDialog>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  margin-bottom: 10px;
`

const StyledButton = styled(Button)`
  text-transform: uppercase;
  text-align: left;
`

export default ExhibitorShortBodyEdit
