import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { TextField } from '@material-ui/core'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContact } from '../../entities'
import { IContactKeyword, IKeyword } from '../../entities/keyword'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { addKeywordCreatedByUser, setErrorUpdateContact } from '../../store/participant'
import {
  addContactKeyword,
  addContactNote,
  removeContactKeyword,
  removeContactNote,
  updateContactNote
} from '../../store/participant/thunks'
import { GRAY_ICON } from '../../theme/colors'
import AutocompleteTags from './AutocompleteTags'

interface IContactExtendedNote {
  contact: IContact
  showIconNote?: boolean
  showLabel?: boolean
}

const ContactNoteTag: React.FC<IContactExtendedNote> = ({ contact, showIconNote, showLabel }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const magicStream = useMagicStream()
  const [lastNoteValue, setLastNoteValue] = useState(contact.note?.note || undefined)
  const [currentNote, setCurrentNote] = useState(contact.note || undefined)
  const [currentNoteValue, setCurrentNoteValue] = useState(contact.note?.note || undefined)
  const [currentTags, setCurrentTags] = useState<IContactKeyword[]>(
    contact.contactKeywords ? contact.contactKeywords : []
  )
  const loadingUpdateNote = useSelector(
    (state: IRootState) => state.participantState.loadingUpdateNote
  )
  const errorUpdateContact = useSelector(
    (state: IRootState) => state.participantState.errorUpdateContact
  )
  const dispatch = useThunkDispatch()

  const isLoadingNote = useMemo(() => (contact.id ? loadingUpdateNote[contact.id] : undefined), [
    loadingUpdateNote,
    contact
  ])

  const handleAddTag = (keyword: IKeyword) => {
    if (contact.contactId) {
      // call api to save Tags
      dispatch(addContactKeyword(contact, keyword))
      dispatch(addKeywordCreatedByUser(keyword))
      sendDataToGTM({ event: 'add-tag-user' })
    }
  }

  const handleRemoveTag = (contactKeyword: IContactKeyword) => {
    dispatch(removeContactKeyword(contact, contactKeyword))
    sendDataToGTM({ event: 'delete-tag-user' })
  }

  const handleChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const currentValue = event.target.value as string
    setCurrentNoteValue(currentValue)
  }

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (contact.id && currentNoteValue !== lastNoteValue) {
        sendDataToGTM({ event: 'save-note' })
        if (!currentNote?.id && currentNoteValue?.length! > 0) {
          dispatch(addContactNote(contact, currentNoteValue!))
        } else if (currentNote?.id && currentNoteValue?.length! > 0) {
          dispatch(updateContactNote(contact, currentNote, currentNoteValue!))
        } else if (currentNote?.id && currentNoteValue?.length! === 0) {
          dispatch(removeContactNote(contact, currentNote))
        }
        setLastNoteValue(currentNoteValue)
      }
    }, 1000)

    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [currentNote, lastNoteValue, currentNoteValue, contact, sendDataToGTM, dispatch])

  useEffect(() => {
    setCurrentTags(contact.contactKeywords || [])
    setCurrentNote(contact.note || undefined)
    setCurrentNoteValue(contact.note?.note || undefined)
  }, [contact])

  useEffect(() => {
    if (errorUpdateContact) {
      magicStream.error(errorUpdateContact)
      dispatch(setErrorUpdateContact(undefined))
    }
  }, [errorUpdateContact, dispatch, magicStream])

  return (
    <>
      {showIconNote && (
        <NoteIcon>
          <LibraryBooksIcon
            titleAccess={intl.formatMessage({
              id: 'titleAccess.ContactNote.LibraryBooksIcon'
            })}
          />
        </NoteIcon>
      )}
      <NoteContent>
        <TextField
          id="noteContact"
          className="note"
          value={currentNoteValue}
          onChange={handleChangeNote}
          placeholder={intl.formatMessage({ id: 'contacts.note.placeholder' })}
          helperText={
            isLoadingNote !== undefined &&
            (isLoadingNote
              ? `${intl.formatMessage({ id: 'contacts.note.save.in.progress' })}`
              : `${intl.formatMessage({ id: 'contacts.note.save' })}`)
          }
          multiline
          label={showLabel ? intl.formatMessage({ id: 'contacts.note.label' }) : false}
          maxRows={4}
        />
        <AutocompleteTags
          contactId={contact.id}
          tags={currentTags}
          onAddTag={handleAddTag}
          onRemoveTag={handleRemoveTag}
          showLabel={showLabel}
        />
      </NoteContent>
    </>
  )
}

const NoteIcon = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 130px;
    width: 130px;
    padding: 10px;

    > svg {
      color: ${GRAY_ICON};
      height: 100%;
      width: 45%;
    }
  }
`

const NoteContent = styled.div`
  & {
    display: flex;
    flex-direction: column;
  }
`

export default ContactNoteTag
