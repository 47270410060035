import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IRoom } from '../../entities'
import { useThunkDispatch } from '../../hooks'
import { setOpentokBroadcaster } from '../../store/room'
import InputText from '../ui/InputText/InputText'

interface BroadcasterDialogProps extends DialogProps {
  room?: IRoom | null
  onClose: (broadcasterEnabled: boolean) => void
  handleRenameRoom?: (room: IRoom, value: string) => void
}

const BroadcasterDialog: React.FC<BroadcasterDialogProps> = ({
  open,
  room,
  onClose,
  handleRenameRoom,
  ...props
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [askForRoomName, setAskForRoomName] = useState(false)
  const [roomName, setRoomName] = useState(room?.title!)
  const dispatch = useThunkDispatch()
  const intl = useIntl()

  useEffect(() => {
    setRoomName(room?.title!)
  }, [room])

  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const handleAccept = () => {
    if (handleRenameRoom && room) {
      setAskForRoomName(true)
    } else {
      setAskForRoomName(false)
      dispatch(setOpentokBroadcaster(true))
      onClose(true)
    }
  }

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: 'backdropClick' | 'escapeKeyDown') => {
    // onClose prop with the `reason` argument to filter the `backdropClick` events.
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    setAskForRoomName(false)
    dispatch(setOpentokBroadcaster(false))
    onClose(false)
  }

  const handleReject = () => {
    setAskForRoomName(false)
    dispatch(setOpentokBroadcaster(false))
    onClose(false)
  }

  const handleRename = () => {
    if (handleRenameRoom && room) {
      handleRenameRoom(room, roomName)
    }
    setAskForRoomName(false)
    dispatch(setOpentokBroadcaster(true))
    onClose(true)
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage
          id={askForRoomName ? 'dialog.broadcaster.chooseLiveName' : 'dialog.broadcaster.title'}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage
            id={
              askForRoomName
                ? 'dialog.broadcaster.chooseLiveNameMessage'
                : 'dialog.broadcaster.message'
            }
          />
        </DialogContentText>
        {askForRoomName && (
          <InputText
            onChangeValue={(value: string) => setRoomName(value)}
            placeholder={intl.formatMessage({
              id: 'room.name.placeholder'
            })}
            value={roomName}
            multiline={false}
            hideEditable
          />
        )}
      </DialogContent>
      <DialogActions>
        {!askForRoomName && (
          <>
            <Button onClick={handleAccept} color="primary" variant="contained" autoFocus>
              <FormattedMessage id="dialog.broadcaster.accept" />
            </Button>
            <Button onClick={handleReject} color="secondary">
              <FormattedMessage id="dialog.broadcaster.reject" />
            </Button>
          </>
        )}
        {askForRoomName && (
          <Button onClick={handleRename} color="primary" variant="contained">
            <FormattedMessage id="dialog.broadcaster.chooseLiveNameMessageAccept" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default BroadcasterDialog
