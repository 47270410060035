import styled from '@emotion/styled'
import { alpha, IconButton } from '@material-ui/core'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import React, { useState } from 'react'
import { FullScreenHandle } from 'react-full-screen'
import { useIntl } from 'react-intl'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgFullScreen } from '../../../assets/images/icons/expand.svg'
import { ReactComponent as SvgFullScreenExit } from '../../../assets/images/icons/reduce.svg'
import { useThunkDispatch, useTooltip } from '../../../hooks'
import { IRootState } from '../../../redux'
import { setFullscreen } from '../../../store/stand'
import { BLACK_BLUE, WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import consoleUtils from '../../../utils/consoleUtils'

interface IAppPlayerActionsProps {
  playerRef: React.RefObject<ReactPlayer>
  setSeeking?: (value: boolean) => void
  played?: number
  setPlayed?: (value: number) => void
  volume?: number
  setVolume?: (value: number) => void
  playing?: boolean
  setPlaying?: (value: boolean) => void
  handleFullscreen?: FullScreenHandle
  withRightSide?: boolean
}

const AppPlayerActions: React.FC<IAppPlayerActionsProps> = ({
  playerRef,
  setSeeking,
  played,
  setPlayed,
  volume,
  setVolume,
  playing,
  setPlaying,
  handleFullscreen,
  withRightSide
}) => {
  const intl = useIntl()
  const [lastVolume, setLastVolume] = useState(1)
  const fullscreen = useSelector((state: IRootState) => state.standState.fullscreen)
  const {
    anchorRef: anchorRefFullscreen,
    setOpen: setOpenFullscreen,
    Tooltip: TooltipFullscreen
  } = useTooltip(
    intl.formatMessage({
      id: fullscreen ? 'actions.fullscreen.exit' : 'actions.fullscreen.enter'
    })
  )
  const {
    anchorRef: anchorRefPlayPause,
    setOpen: setOpenPlayPause,
    Tooltip: TooltipPlayPause
  } = useTooltip(
    intl.formatMessage({
      id: playing ? 'actions.playing.pause' : 'actions.playing.play'
    })
  )
  const dispatch = useThunkDispatch()

  const handleVolumeReset = () => {
    setVolume && setVolume(lastVolume)
  }

  const handleVolumeMuted = () => {
    setLastVolume(volume || 1)
    setVolume && setVolume(0)
  }

  const handleVolumeChange = (e: any) => {
    setVolume && setVolume(parseFloat(e.target.value))
  }

  const togglePlayPause = () => {
    setPlaying && setPlaying(!playing)
  }

  const handleSeekMouseDown = (e: any) => {
    setSeeking && setSeeking(true)
  }

  const handleSeekChange = (e: any) => {
    setPlayed && setPlayed(parseFloat(e.target.value))
  }

  const handleSeekMouseUp = (e: any) => {
    setSeeking && setSeeking(false)
    setPlayed && setPlayed(parseFloat(e.target.value))
    playerRef && playerRef.current && playerRef.current.seekTo(parseFloat(e.target.value))
  }

  const toggleFullscreen = () => {
    dispatch(setFullscreen(!fullscreen))
    try {
      if (handleFullscreen) {
        if (fullscreen) {
          handleFullscreen.exit()
        } else {
          handleFullscreen.enter()
        }
      }
    } catch (e) {
      consoleUtils.warn('Not in fullscreen')
    }
  }

  return (
    <StyledAppPlayerActions withRightSide={withRightSide}>
      <Actions>
        {setPlaying && (
          <IconButton
            ref={anchorRefPlayPause}
            onMouseOver={() => setOpenPlayPause(true)}
            onMouseOut={() => setOpenPlayPause(false)}
            onClick={togglePlayPause}
          >
            {playing ? <PauseIcon /> : <PlayArrowIcon />}
            <TooltipPlayPause />
          </IconButton>
        )}
        <StyledSeek
          type="range" min={0} max={0.999999} step="any"
          value={played}
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
        />
        <StyledVolume>
          {volume === 0 ? <VolumeOffIcon onClick={handleVolumeReset} /> : <VolumeUpIcon onClick={handleVolumeMuted} />}
          <input type="range" min={0} max={1} step="any" value={volume} onChange={handleVolumeChange} />
        </StyledVolume>
        <IconButton
          ref={anchorRefFullscreen}
          onMouseOver={() => setOpenFullscreen(true)}
          onMouseOut={() => setOpenFullscreen(false)}
          onClick={toggleFullscreen}
        >
          {fullscreen ? <SvgFullScreenExit /> : <SvgFullScreen />}
          <TooltipFullscreen />
        </IconButton>
      </Actions>
    </StyledAppPlayerActions>
  )
}

const StyledAppPlayerActions = styled.div<{ withRightSide?: boolean }>`
  width: 100%;
  background-color: ${WHITE};
  box-shadow: 0 0 30px 0 ${alpha(BLACK_BLUE, 0.14)};
  padding: 10px;
  margin-top: 40px;
  border-top-left-radius: 0;
  border-bottom-left-radius: ${BORDER_RADIUS_CARD};
  border-top-right-radius: 0;
  border-bottom-right-radius: ${(props) => props.withRightSide ? 0 : BORDER_RADIUS_CARD};
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledVolume = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`

const StyledSeek = styled.input`
  position: relative;
  width: 100%;
`

export default AppPlayerActions
