import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper, { PopperProps } from '@material-ui/core/Popper'
import React from 'react'
import { IContact } from '../../entities'
import ContactPopperContent from './ContactPopperContent'

interface IContactPopperProps extends Omit<PopperProps, 'children'> {
  contact: IContact
  anchorRef: React.RefObject<HTMLDivElement>
  disabledSendMessage?: boolean | null
  isOnline?: boolean | undefined
  isOffice?: boolean | undefined
  handleClose: (event: React.MouseEvent<EventTarget>) => void
  onEnterOffice?: () => void
}

const ContactPopper: React.FC<IContactPopperProps> = ({
  contact,
  anchorRef,
  disabledSendMessage,
  isOnline,
  isOffice,
  handleClose,
  onEnterOffice,
  ...props
}) => {
  return (
    <Popper
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      style={{ zIndex: 1400 }}
      {...props}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <ContactPopperContent
                contact={contact}
                disabledSendMessage={disabledSendMessage}
                isOnline={isOnline}
                isOffice={isOffice}
                onEnterOffice={onEnterOffice}
                onClose={handleClose}
              />
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default ContactPopper
