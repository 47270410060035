import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactPlayerProps } from 'react-player'
import { BLACK_BLUE } from '../../../theme/colors'

interface ITitleProps extends ReactPlayerProps {
  titleId: string
  variant?:
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'srOnly'
  | 'inherit'
  prefix?: string
  suffix?: string
}

const Title: React.FC<ITitleProps> = ({ titleId, variant, prefix, suffix }) => {
  return (
    <StyledTypography variant={variant}>
      {prefix && <>{prefix}</>}
      <FormattedMessage id={titleId} />
      {suffix && <>{suffix}</>}
    </StyledTypography>
  )
}

const StyledTypography = styled(Typography)`
  & {
    color: ${BLACK_BLUE};
  }
`

export default Title
