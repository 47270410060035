import styled from '@emotion/styled'
import { alpha, Chip, Switch, Typography } from '@material-ui/core'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import SearchInput from '../../components/ui/SearchInput/SearchInput'
import { IKeyword } from '../../entities/keyword'
import { useEffectOnce, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { loadFiltersConference } from '../../store/conference'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../theme'
import { BLACK_BLUE, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD, FILTERS_HEIGHT_BUTTON_MOBILE } from '../../theme/sizes'
import { breakpoints } from '../breakpoints'
import Loading from '../Loading'
import { Label } from '../ui/LabelWrapper/LabelWrapper'
import SearchFilters from '../ui/SearchFilters/SearchFilters'
import SearchKeywords from '../ui/SearchKeywords/SearchKeywords'

interface IConferenceFiltersSearch {
  setPage: (page: number) => void
  setFilterName: (value: string) => void
  filterName: any
  setFilterCustom: (value: any) => void
  filterCustom: { [key: string]: number | string | { id: number; name: string } }
  setFilterKeyword: (value: string) => void
  filterKeyword: any
  setFilterIsFavorite: (value: boolean) => void
  filterIsFavorite: boolean
  filterKeywordsX: { [key: string]: boolean }
  setFilterKeywordsX: (value: { [key: string]: boolean }) => void
}
const ConferenceFiltersSearch: React.FC<IConferenceFiltersSearch> = ({
  setPage,
  setFilterName,
  filterName,
  setFilterCustom,
  filterCustom,
  setFilterKeyword,
  filterKeyword,
  setFilterIsFavorite,
  filterIsFavorite,
  filterKeywordsX,
  setFilterKeywordsX
}) => {
  const intl = useIntl()
  const [openMenu, setOpenMenu] = useState(false)
  const [currentFilterName, setCurrentFilterName] = useState(filterName)
  const [currentKeyword, setCurrentKeyword] = useState(filterKeyword)
  const conferences = useSelector((state: IRootState) => state.conferenceState.conferences)
  const filters = useSelector((state: IRootState) => state.conferenceState.filters)
  const loading = useSelector((state: IRootState) => state.conferenceState.loadingFilters)
  const error = useSelector((state: IRootState) => state.conferenceState.errorFilters)
  const keywordsX = useSelector((state: IRootState) => state.appState.keywordsX)
  const loadingKeywordsX = useSelector((state: IRootState) => state.appState.loadingKeywordsX)
  const dispatch = useThunkDispatch()

  const handleToggleMenu = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault()
    event.stopPropagation()
    setOpenMenu(!openMenu)
  }
  const toggleKeywordXSearch = (keyword: IKeyword) => {
    setFilterKeywordsX({ ...filterKeywordsX, [keyword.id]: !filterKeywordsX[keyword.id] })
  }

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setFilterName(currentFilterName)
      if (currentFilterName) {
        setPage(1)
      }
    }, 200)

    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [setFilterName, setPage, currentFilterName])

  useEffectOnce(() => {
    if (Object.keys(filters).length === 0) {
      dispatch(loadFiltersConference())
    }
  })

  useEffect(() => {
    setFilterKeyword(currentKeyword)
  }, [setFilterKeyword, currentKeyword])

  return (
    <StyledFilters>
      <StyledTitleMobile>
        <StyledTitleSearch variant="h2">
          <FormattedMessage id="conference.title.search" />
        </StyledTitleSearch>
      </StyledTitleMobile>
      {openMenu && <StyledOverlayOpenMobile onClick={handleToggleMenu} />}
      <StyledButtonOpenMobile open={openMenu} onClick={handleToggleMenu}>
        {openMenu && (
          <>
            <FormattedMessage id="filters.close" /> ({conferences.total})
          </>
        )}
        {!openMenu && (
          <>
            <FormattedMessage id="filters.filter" />
          </>
        )}
      </StyledButtonOpenMobile>
      <StyledContentOpenMobile open={openMenu}>
        <>
          <Label id="is-favorite-label">
            <FormattedMessage id="is.favorite" />
          </Label>
          <StyledSwitch
            aria-labelledby="is-favorite-label"
            checked={filterIsFavorite}
            color="primary"
            title={intl.formatMessage({ id: filterIsFavorite ? 'is.favorite' : 'not.favorite' })}
            onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
              setFilterIsFavorite(checked)
              setPage(1)
            }}
            name="isFavorite"
          ></StyledSwitch>
        </>
        <SearchInput
          name="conferenceName"
          defaultValue={currentFilterName}
          placeholder={intl.formatMessage({ id: 'conference.search' })}
          handleChange={setCurrentFilterName}
          resetSearch={() => setCurrentFilterName('')}
        />
        <SearchKeywords onChangeValue={setCurrentKeyword} />
        <SearchFilters
          selectFirst
          filters={filters}
          loading={loading}
          error={error}
          filterCustom={filterCustom}
          onChangeValue={(value) => {
            setFilterCustom(value)
            setPage(1)
          }}
        />
        {keywordsX.length > 0 && (
          <>
            <Label id="x-keywords">
              <FormattedMessage id="contacts.xKeywords.label" />
            </Label>
            <StyledSearchByKeywordsXContact>
              {!loadingKeywordsX ? (
                keywordsX.map((keyword, index) => (
                  <Chip
                    key={index}
                    label={keyword.title}
                    title={intl.formatMessage(
                      { id: 'contacts.tags.edit.title' },
                      { keyword: keyword.title }
                    )}
                    size="small"
                    onClick={() => toggleKeywordXSearch(keyword)}
                    color={filterKeywordsX[keyword.id] ? 'primary' : 'default'}
                  />
                ))
              ) : loadingKeywordsX ? (
                <Loading />
              ) : (
                <FormattedMessage id="contacts.tags.no.tag.created" />
              )}
            </StyledSearchByKeywordsXContact>
          </>
        )}
      </StyledContentOpenMobile>
    </StyledFilters>
  )
}

const StyledFilters = styled.div`
  & {
    @media (min-width: ${breakpoints.lg}) {
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;
      padding: 1em;
      border-radius: ${BORDER_RADIUS_CARD};
      background-color: ${WHITE};
    }
    .MuiInputBase-root {
      width: 100%;
    }
  }
`

const StyledTitleSearch = styled(Typography)`
  & {
    margin-top: 0;
    font-size: 18px;
    line-height: 21px;
  }
`

const StyledOverlayOpenMobile = styled.div`
  & {
    @media (max-width: ${breakpoints.lg}) {
      z-index: 1000;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${alpha(BLACK_BLUE, 0.38)};
    }
  }
`

const StyledTitleMobile = styled.div`
  & {
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.lg}) {
      display: none;
    }
  }
`

const StyledButtonOpenMobile = styled.div<{ open: boolean }>`
  & {
    display: none;

    @media (max-width: ${breakpoints.lg}) {
      z-index: ${(props) => (props.open ? `1050` : '950')};
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      height: ${FILTERS_HEIGHT_BUTTON_MOBILE};
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${WHITE};
      border-top: 1px solid ${SECONDARY_COLOR};
      background-color: ${PRIMARY_COLOR};
      font-size: 18px;
    }
  }
`

const StyledContentOpenMobile = styled.div<{ open: boolean }>`
  & {
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.lg}) {
      z-index: ${(props) => (props.open ? `1049` : '949')};
      position: fixed;
      left: 0;
      bottom: ${(props) => (props.open ? `${FILTERS_HEIGHT_BUTTON_MOBILE}` : '-100vh')};
      right: 0;
      padding: 15px;
      background-color: ${WHITE};
      transition: bottom 0.3s ease-in;
      max-height: calc(100vh - ${FILTERS_HEIGHT_BUTTON_MOBILE});
    }
  }
`

const StyledSwitch = styled(Switch)`
  & {
    margin-bottom: 10px;
  }
`

const StyledSearchByKeywordsXContact = styled.div`
  & {
    width: 100%;
    background-color: ${WHITE};
    margin-top: 15px;

    & > div {
      margin-right: 5px;
      margin-bottom: 5px;
      max-width: 100%;
    }
  }
`

export default ConferenceFiltersSearch
