import React from 'react'

const SvgMenu: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M6.333 10.667a2.333 2.333 0 0 1 0-4.667h37.334a2.333 2.333 0 0 1 0 4.667H6.333zm37.334 12a2.333 2.333 0 1 1 0 4.666H6.333a2.333 2.333 0 1 1 0-4.666h37.334zm0 16.666a2.333 2.333 0 0 1 0 4.667H6.333a2.333 2.333 0 1 1 0-4.667h37.334z"
    />
  </svg>
)

export default SvgMenu
