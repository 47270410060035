import styled from '@emotion/styled'
import { DropzoneArea } from 'material-ui-dropzone'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { addExhibitorDocument } from '../../store/exhibitor'
import Loading from '../Loading'
import AppButton from '../ui/AppButton/AppButton'
import FormContainer from '../ui/Form/FormContainer'
import ExhibitorDocumentsList from './ExhibitorDocumentsList'

const ExhibitorDocumentsForm: React.FC = () => {
  const intl = useIntl()
  const [isDirty, setDirty] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const exhibitorUpdating = useSelector((state: IRootState) => state.exhibitorState.updating)
  const exhibitorId = exhibitor?.id
  const dispatch = useThunkDispatch()

  const onChange = (loadedFiles: File[]) => {
    if (loadedFiles.length > 0) {
      setDirty(true)
    }
    setFiles(loadedFiles)
  }

  const handleSubmit = useCallback(() => {
    if (isDirty && exhibitorId) {
      // call api to save form
      files.forEach((file) => {
        const document = {
          documentFile: file,
          businessEventExhibitor: exhibitorId
        }
        dispatch(addExhibitorDocument(document))
      })
      setFiles([])
    }
  }, [isDirty, dispatch, files, exhibitorId, setFiles])

  useEffect(() => {
    if (files.length === 0) {
      setDirty(false)
    }
  }, [setDirty, files])

  if (!exhibitor) return <></>

  return (
    <FormContainer>
      {!exhibitorUpdating && (
        <>
          <DropzoneArea
            filesLimit={10}
            maxFileSize={18000000}
            onChange={onChange}
            showPreviews
            useChipsForPreview
            showPreviewsInDropzone={false}
            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
            dropzoneText={intl.formatMessage({ id: 'exhibitor.dropzone.text' })}
            previewText={intl.formatMessage({ id: 'exhibitor.preview.text' })}
            clearOnUnmount
          />

          {isDirty && (
            <StyledAppButton onClick={handleSubmit} disabled={!isDirty}>
              <FormattedMessage id="exhibitor.button.upload" />
            </StyledAppButton>
          )}
        </>
      )}
      {exhibitorUpdating ? (
        <StyledLoading />
      ) : (
        <StyledExhibitorDocumentsList columns={3} showDelete />
      )}
    </FormContainer>
  )
}
const StyledAppButton = styled(AppButton)`
  & {
    max-width: 150px;
    margin: 20px auto;
  }
`

const StyledLoading = styled(Loading)`
  & {
    z-index: 10000;
  }
`

const StyledExhibitorDocumentsList = styled(ExhibitorDocumentsList)`
  & {
    margin-top: 50px;
  }
`
export default ExhibitorDocumentsForm
