import { IPromiseUtils } from '../entities/promise'

const promiseUtils: IPromiseUtils = {
  waterfall(promises) {
    return new Promise<any>(async (resolve) => {
      const results = []
      for (const promise of promises) {
        try {
          results.push(await new Promise(promise))
        } catch (e) {
          results.push(e.message)
        }
        if (results.length === promises.length) {
          resolve(results)
        }
      }
    })
  }
}

export default promiseUtils
