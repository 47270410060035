import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { WHITE } from '../../theme/colors'
import FullScreenDialog from '../modals/FullScreenDialog'
import Transition from '../Transition'
import ExhibitorDocumentsForm from './ExhibitorDocumentsForm'

const ExhibitorDocuments: React.FC = () => {
  const intl = useIntl()
  const [showDocumentModal, setShowDocumentModal] = useState(false)
  const handleClose = () => {
    setShowDocumentModal(false)
  }
  return (
    <StyledDiv>
      <StyledButton
        variant="contained"
        startIcon={<AttachFileIcon />}
        color="secondary"
        onClick={() => setShowDocumentModal(true)}
      >
        <FormattedMessage id="button.exhibitorDocuments" />
      </StyledButton>
      <FullScreenDialog
        title={intl.formatMessage({ id: 'exhibitor.documentModalTitle' })}
        open={showDocumentModal}
        fullScreen
        TransitionComponent={Transition}
        scroll={'body'}
        onClose={handleClose}
      >
        <ExhibitorDocumentsForm />
      </FullScreenDialog>
    </StyledDiv>
  )
}

const StyledButton = styled(Button)`
  & {
    text-transform: uppercase;
    text-align: left;

    svg {
      height: 20px;
      width: 20px;
      color: ${WHITE};
    }
  }
`

const StyledDiv = styled.div`
  & {
    margin-bottom: 10px;
  }
`

export default ExhibitorDocuments
