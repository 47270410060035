import { AnyAction } from 'redux'
import { StorageKeys } from '.'
import { LanguageKeys } from '../../entities'
import { VideoResolution, VideoSourceType } from '../../entities/mediaStream'
import settings from '../../settings'
import { createReducer, IReducerHandler } from '../../store/utils/createReducer'
import { ActionsTypes, IAppState } from './model'

const initialState: IAppState = {
  user: null,
  userUpdating: false,
  locale: (localStorage.getItem(StorageKeys.PREFERED_LANGUAGE) as LanguageKeys) || LanguageKeys.fr,
  loading: false,
  location: '/',
  hasPassedTunnel: true,
  videoSource: VideoSourceType.CAMERA,
  videoInputDevices: [],
  audioInputDevices: [],
  defaultAudioSource: null,
  defaultVideoSource: null,
  defaultResolution: VideoResolution._360_P,
  recommendedFrameRate: 15,
  recommendedResolution: VideoResolution._360_P,
  keywords: { total: 0, items: [] },
  keywordsByUser: { total: 0, items: [] },
  loadingKeywords: false,
  loadingKeywordsByUser: false,
  search: null,
  groupId: settings.eventGroupId,
  openMenu: false,
  openMenuContact: false,
  openAgenda: false,
  openAvailabilities: false,
  openSyncCalendar: false,
  agenda: [],
  agendaToRespond: 0,
  planning: {},
  planningLength: 0,
  planningOrder: [],
  preferedVideoDisplay: settings.opentok.autoStart,
  extendedPanel: {},
  keywordsX: [],
  loadingKeywordsX: false,
  loadedKeywordsX: false,
  keywordsY: [],
  loadingKeywordsY: false,
  loadedKeywordsY: false,
  eventCoCompatibility: '',
  refreshRequired: false,
  agendaInitDate: null,
  agendaInitDateLoading: false
}

const actionHandlers: IReducerHandler<IAppState> = {
  [ActionsTypes.SET_LOCALE]: (state: IAppState | undefined, { locale }: AnyAction): IAppState => ({
    ...state!,
    locale
  }),

  [ActionsTypes.SET_LOADING]: (
    state: IAppState | undefined,
    { loading }: AnyAction
  ): IAppState => ({
    ...state!,
    loading
  }),

  [ActionsTypes.SET_ERROR]: (state: IAppState | undefined, { error }: AnyAction): IAppState => ({
    ...state!,
    error
  }),

  [ActionsTypes.SET_LOCATION]: (
    state: IAppState | undefined,
    { location }: AnyAction
  ): IAppState => ({
    ...state!,
    location
  }),

  [ActionsTypes.SET_HAS_PASSED_TUNNEL]: (
    state: IAppState | undefined,
    { hasPassedTunnel }: AnyAction
  ): IAppState => ({
    ...state!,
    hasPassedTunnel
  }),

  [ActionsTypes.SET_USER_UPDATING]: (
    state: IAppState | undefined,
    { userUpdating }: AnyAction
  ): IAppState => ({
    ...state!,
    userUpdating
  }),

  [ActionsTypes.SET_USER]: (state: IAppState | undefined, { user }: AnyAction): IAppState => ({
    ...state!,
    user
  }),

  [ActionsTypes.MERGE_USER]: (
    state: IAppState | undefined,
    { userUpdate, keywords }: AnyAction
  ): IAppState => ({
    ...state!,
    user: state && state.user ? { ...state.user, ...userUpdate, ...{ keywords } } : null
  }),

  [ActionsTypes.SET_VIDEO_SOURCE]: (
    state: IAppState | undefined,
    { videoSource }: AnyAction
  ): IAppState => ({
    ...state!,
    videoSource
  }),

  [ActionsTypes.SET_VIDEO_INPUT_DEVICES]: (
    state: IAppState | undefined,
    { devices }: AnyAction
  ): IAppState => ({
    ...state!,
    videoInputDevices: devices
  }),

  [ActionsTypes.SET_AUDIO_INPUT_DEVICES]: (
    state: IAppState | undefined,
    { devices }: AnyAction
  ): IAppState => ({
    ...state!,
    audioInputDevices: devices
  }),

  [ActionsTypes.SET_DEFAULT_VIDEO_SOURCE]: (
    state: IAppState | undefined,
    { deviceId }: AnyAction
  ): IAppState => ({
    ...state!,
    defaultVideoSource: deviceId
  }),

  [ActionsTypes.RESET_DEFAULT_VIDEO_SOURCE]: (state: IAppState | undefined): IAppState => ({
    ...state!,
    defaultVideoSource: null
  }),

  [ActionsTypes.SET_DEFAULT_AUDIO_SOURCE]: (
    state: IAppState | undefined,
    { deviceId }: AnyAction
  ): IAppState => ({
    ...state!,
    defaultAudioSource: deviceId
  }),

  [ActionsTypes.RESET_DEFAULT_AUDIO_SOURCE]: (state: IAppState | undefined): IAppState => ({
    ...state!,
    defaultAudioSource: null
  }),

  [ActionsTypes.SET_DEFAULT_RESOLUTION]: (
    state: IAppState | undefined,
    { resolution }: AnyAction
  ): IAppState => ({
    ...state!,
    defaultResolution: resolution
  }),

  [ActionsTypes.SET_RECOMMENDED_FRAMERATE]: (
    state: IAppState | undefined,
    { recommendedFrameRate }: AnyAction
  ): IAppState => ({
    ...state!,
    recommendedFrameRate
  }),

  [ActionsTypes.SET_RECOMMENDED_RESOLUTION]: (
    state: IAppState | undefined,
    { recommendedResolution }: AnyAction
  ): IAppState => ({
    ...state!,
    recommendedResolution
  }),

  [ActionsTypes.SET_PREFERED_VIDEO_DISPLAY]: (
    state: IAppState | undefined,
    { preferedVideoDisplay }: AnyAction
  ): IAppState => ({
    ...state!,
    preferedVideoDisplay
  }),

  [ActionsTypes.SET_KEYWORDS]: (
    state: IAppState | undefined,
    { keywords }: AnyAction
  ): IAppState => ({
    ...state!,
    keywords
  }),

  [ActionsTypes.SET_KEYWORDS_LOADING]: (
    state: IAppState | undefined,
    { loadingKeywords }: AnyAction
  ): IAppState => ({
    ...state!,
    loadingKeywords
  }),

  [ActionsTypes.SET_KEYWORDS_ERROR]: (
    state: IAppState | undefined,
    { errorKeywords }: AnyAction
  ): IAppState => ({
    ...state!,
    errorKeywords
  }),

  [ActionsTypes.SET_KEYWORDS_BY_USER]: (
    state: IAppState | undefined,
    { keywordsByUser }: AnyAction
  ): IAppState => ({
    ...state!,
    keywordsByUser
  }),

  [ActionsTypes.SET_KEYWORDS_BY_USER_LOADING]: (
    state: IAppState | undefined,
    { loadingKeywordsByUser }: AnyAction
  ): IAppState => ({
    ...state!,
    loadingKeywordsByUser
  }),

  [ActionsTypes.SET_KEYWORDS_BY_USER_ERROR]: (
    state: IAppState | undefined,
    { errorKeywordsByUser }: AnyAction
  ): IAppState => ({
    ...state!,
    errorKeywordsByUser
  }),

  [ActionsTypes.SET_SEARCH]: (state: IAppState | undefined, { search }: AnyAction): IAppState => ({
    ...state!,
    search
  }),

  [ActionsTypes.SET_GROUP_ID]: (
    state: IAppState | undefined,
    { groupId }: AnyAction
  ): IAppState => ({
    ...state!,
    groupId
  }),

  [ActionsTypes.RESET_GROUP_ID]: (state: IAppState | undefined): IAppState => ({
    ...state!,
    groupId: settings.eventGroupId
  }),

  [ActionsTypes.SET_OPEN_MENU]: (
    state: IAppState | undefined,
    { openMenu }: AnyAction
  ): IAppState => ({
    ...state!,
    openMenu
  }),

  [ActionsTypes.SET_OPEN_MENU_CONTACT]: (
    state: IAppState | undefined,
    { openMenuContact }: AnyAction
  ): IAppState => ({
    ...state!,
    openMenuContact
  }),

  [ActionsTypes.SET_OPEN_AGENDA]: (
    state: IAppState | undefined,
    { openAgenda }: AnyAction
  ): IAppState => ({
    ...state!,
    openAgenda
  }),

  [ActionsTypes.SET_OPEN_SYNC_CALENDAR]: (
    state: IAppState | undefined,
    { openSyncCalendar }: AnyAction
  ): IAppState => ({
    ...state!,
    openSyncCalendar
  }),

  [ActionsTypes.SET_OPEN_AVAILABILITIES]: (
    state: IAppState | undefined,
    { openAvailabilities }: AnyAction
  ): IAppState => ({
    ...state!,
    openAvailabilities
  }),

  [ActionsTypes.SET_AGENDA]: (state: IAppState | undefined, { agenda }: AnyAction): IAppState => ({
    ...state!,
    agenda
  }),

  [ActionsTypes.SET_AGENDA_TO_RESPOND]: (
    state: IAppState | undefined,
    { agendaToRespond }: AnyAction
  ): IAppState => ({
    ...state!,
    agendaToRespond
  }),

  [ActionsTypes.SET_PLANNING]: (
    state: IAppState | undefined,
    { planning }: AnyAction
  ): IAppState => ({
    ...state!,
    planning
  }),

  [ActionsTypes.SET_PLANNING_LENGTH]: (
    state: IAppState | undefined,
    { planningLength }: AnyAction
  ): IAppState => ({
    ...state!,
    planningLength
  }),

  [ActionsTypes.SET_PLANNING_ORDER]: (
    state: IAppState | undefined,
    { planningOrder }: AnyAction
  ): IAppState => ({
    ...state!,
    planningOrder
  }),

  [ActionsTypes.SET_LOADING_AGENDA]: (
    state: IAppState | undefined,
    { loadingAgenda }: AnyAction
  ): IAppState => ({
    ...state!,
    loadingAgenda
  }),

  [ActionsTypes.SET_ERROR_AGENDA]: (
    state: IAppState | undefined,
    { errorAgenda }: AnyAction
  ): IAppState => ({
    ...state!,
    errorAgenda
  }),

  [ActionsTypes.SET_AGENDA_SLOTS]: (
    state: IAppState | undefined,
    { agendaSlots }: AnyAction
  ): IAppState => ({
    ...state!,
    agendaSlots
  }),

  [ActionsTypes.SET_LOADING_AGENDA_SLOTS]: (
    state: IAppState | undefined,
    { loadingAgendaSlots }: AnyAction
  ): IAppState => ({
    ...state!,
    loadingAgendaSlots
  }),

  [ActionsTypes.SET_ERROR_AGENDA_SLOTS]: (
    state: IAppState | undefined,
    { errorAgendaSlots }: AnyAction
  ): IAppState => ({
    ...state!,
    errorAgendaSlots
  }),

  [ActionsTypes.SET_EXTENDED_PANEL]: (
    state: IAppState | undefined,
    { key, extendedPanel }: AnyAction
  ): IAppState => ({
    ...state!,
    extendedPanel:
      state && state.extendedPanel ? { ...state.extendedPanel, [key]: extendedPanel } : {}
  }),

  [ActionsTypes.SET_KEYWORDS_X]: (
    state: IAppState | undefined,
    { keywordsX }: AnyAction
  ): IAppState => ({
    ...state!,
    keywordsX
  }),

  [ActionsTypes.SET_ERROR_KEYWORDS_X]: (
    state: IAppState | undefined,
    { errorKeywordsX }: AnyAction
  ): IAppState => ({
    ...state!,
    errorKeywordsX
  }),

  [ActionsTypes.SET_LOADING_KEYWORDS_X]: (
    state: IAppState | undefined,
    { loadingKeywordsX }: AnyAction
  ): IAppState => ({
    ...state!,
    loadingKeywordsX
  }),

  [ActionsTypes.SET_LOADED_KEYWORDS_X]: (
    state: IAppState | undefined,
    { loadedKeywordsX }: AnyAction
  ): IAppState => ({
    ...state!,
    loadedKeywordsX
  }),

  [ActionsTypes.SET_LABEL_KEYWORDS_X]: (
    state: IAppState | undefined,
    { labelKeywordsX }: AnyAction
  ): IAppState => ({
    ...state!,
    labelKeywordsX
  }),

  [ActionsTypes.SET_KEYWORDS_Y]: (
    state: IAppState | undefined,
    { keywordsY }: AnyAction
  ): IAppState => ({
    ...state!,
    keywordsY
  }),

  [ActionsTypes.SET_ERROR_KEYWORDS_Y]: (
    state: IAppState | undefined,
    { errorKeywordsY }: AnyAction
  ): IAppState => ({
    ...state!,
    errorKeywordsY
  }),

  [ActionsTypes.SET_LOADING_KEYWORDS_Y]: (
    state: IAppState | undefined,
    { loadingKeywordsY }: AnyAction
  ): IAppState => ({
    ...state!,
    loadingKeywordsY
  }),

  [ActionsTypes.SET_LOADED_KEYWORDS_Y]: (
    state: IAppState | undefined,
    { loadedKeywordsY }: AnyAction
  ): IAppState => ({
    ...state!,
    loadedKeywordsY
  }),

  [ActionsTypes.SET_LABEL_KEYWORDS_Y]: (
    state: IAppState | undefined,
    { labelKeywordsY }: AnyAction
  ): IAppState => ({
    ...state!,
    labelKeywordsY
  }),

  [ActionsTypes.SET_EVENTCO_COMPATIBILITY]: (
    state: IAppState | undefined,
    { eventCoCompatibility }: AnyAction
  ): IAppState => ({
    ...state!,
    eventCoCompatibility
  }),

  [ActionsTypes.SET_REFRESH_REQUIRED]: (
    state: IAppState | undefined,
    { refreshRequired }: AnyAction
  ): IAppState => ({
    ...state!,
    refreshRequired
  }),

  [ActionsTypes.SET_AGENDA_INIT_DATE]: (
    state: IAppState | undefined,
    { agendaInitDate }: AnyAction
  ): IAppState => ({
    ...state!,
    agendaInitDate
  }),

  [ActionsTypes.SET_AGENDA_INIT_DATE_LOADING]: (
    state: IAppState | undefined,
    { agendaInitDateLoading }: AnyAction
  ): IAppState => ({
    ...state!,
    agendaInitDateLoading
  })
}

export const reducer = createReducer(initialState, actionHandlers)
