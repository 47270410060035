import React, { ReactNode, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { MagicStreamType, NotificationType } from '../entities'
import settings from '../settings'
import { addMagicStream } from '../store/chat'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../theme'
import { useThunkDispatch } from './useThunkDispatch'

export const useMagicStream = () => {
  const [asked, setAsked] = useState(false)
  const dispatch = useThunkDispatch()

  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then()
    } catch (e) {
      return false
    }
    return true
  }

  useEffect(() => {
    if (!asked) {
      if (!('Notification' in window)) {
        console.log('This browser does not support desktop notification')
      } else if (Notification.permission !== 'granted') {
        if (checkNotificationPromise()) {
          Notification.requestPermission().then(() => {
            setAsked(true)
          })
        } else {
          Notification.requestPermission(() => {
            setAsked(true)
          })
        }
      }
    }
  })

  const showNotification = (title: string, options: NotificationOptions) => {
    if (Notification.permission === 'granted') {
      const notification = new Notification(title, options)
      notification.onclick = () => {
        window.focus()
      }
      setTimeout(() => {
        if (notification) notification.close()
      }, 15000)
    }
  }

  const announce = (content: ReactNode, type: NotificationType, withToast?: boolean) => {
    if (settings.debug.toast || withToast) {
      toast(content, {
        position: 'top-center',
        autoClose: 150000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        bodyStyle: {
          color: type === NotificationType.GLOBAL_ANNOUNCE ? PRIMARY_COLOR : SECONDARY_COLOR
        }
      })
    }
    if (settings.debug.notification) {
      showNotification('Annonce', { body: 'Nouvelle annonce, cliquer ici pour la voir' })
    }
    dispatch(
      addMagicStream({
        id: Date.now(),
        createDate: new Date().toUTCString(),
        writeDate: new Date().toUTCString(),
        streamType: MagicStreamType.ANNOUNCE,
        content,
        priority: 'low'
      })
    )
  }

  const global = (globalMsg: string) => {
    if (settings.debug.toast) {
      toast(globalMsg)
    }
    if (settings.debug.magicstream) {
      dispatch(
        addMagicStream({
          id: Date.now(),
          createDate: new Date().toUTCString(),
          writeDate: new Date().toUTCString(),
          streamType: MagicStreamType.TOAST_GLOBAL,
          content: <div dangerouslySetInnerHTML={{ __html: globalMsg }} />,
          priority: 'low'
        })
      )
    }
  }

  const newMessage = (msg: string, clickAction: () => void) => {
    if (settings.debug.toast) {
      toast.info(msg, { autoClose: 2000 })
    }
    if (settings.debug.notification) {
      showNotification('Nouveau message reçu', { body: msg })
    }
    if (settings.debug.magicstream) {
      dispatch(
        addMagicStream({
          id: Date.now(),
          createDate: new Date().toUTCString(),
          writeDate: new Date().toUTCString(),
          streamType: MagicStreamType.NEW_MESSAGE,
          content: <div dangerouslySetInnerHTML={{ __html: msg }} />,
          priority: 'low',
          clickAction
        })
      )
    }
  }

  const info = (infoMsg: string) => {
    if (settings.debug.toast) {
      toast.info(infoMsg, { autoClose: 2000 })
    }
    if (settings.debug.magicstream) {
      dispatch(
        addMagicStream({
          id: Date.now(),
          createDate: new Date().toUTCString(),
          writeDate: new Date().toUTCString(),
          streamType: MagicStreamType.TOAST_INFO,
          content: <div dangerouslySetInnerHTML={{ __html: infoMsg }} />,
          priority: 'low'
        })
      )
    }
  }

  const success = (successMsg: string) => {
    if (settings.debug.toast) {
      toast.success(successMsg)
    }
    if (settings.debug.magicstream) {
      dispatch(
        addMagicStream({
          id: Date.now(),
          createDate: new Date().toUTCString(),
          writeDate: new Date().toUTCString(),
          streamType: MagicStreamType.TOAST_SUCCESS,
          content: <div dangerouslySetInnerHTML={{ __html: successMsg }} />,
          priority: 'low'
        })
      )
    }
  }

  const warning = (warningMsg: string) => {
    if (settings.debug.toast) {
      toast.warning(warningMsg)
    }
    if (settings.debug.magicstream) {
      dispatch(
        addMagicStream({
          id: Date.now(),
          createDate: new Date().toUTCString(),
          writeDate: new Date().toUTCString(),
          streamType: MagicStreamType.TOAST_WARNING,
          content: <div dangerouslySetInnerHTML={{ __html: warningMsg }} />,
          priority: 'low'
        })
      )
    }
  }

  const error = (errorMsg: string) => {
    if (settings.debug.toast) {
      toast.error(errorMsg)
    }
    if (settings.debug.magicstream) {
      dispatch(
        addMagicStream({
          id: Date.now(),
          createDate: new Date().toUTCString(),
          writeDate: new Date().toUTCString(),
          streamType: MagicStreamType.TOAST_ERROR,
          content: <div dangerouslySetInnerHTML={{ __html: errorMsg }} />,
          priority: 'low'
        })
      )
    }
  }

  return {
    announce,
    global,
    newMessage,
    info,
    success,
    warning,
    error
  }
}
