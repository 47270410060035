import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import DateSlotPicker from '../contacts/DateSlotPicker'
import SendDialog from '../modals/SendDialog'
import FormContainer from '../ui/Form/FormContainer'

interface IRescheduleDialogProps {
  userId: number
  initDate?: Date
  open: boolean
  setOpen: (value: boolean) => void
  onReschedule: (newDate: string) => void
}

const RescheduleDialog: React.FC<IRescheduleDialogProps> = ({ userId, initDate, open, setOpen, onReschedule }) => {
  const intl = useIntl()
  const [date, setDate] = useState<Date>()
  const [dateTime, setDateTime] = useState<Date>()

  const reschedule = () => {
    if (dateTime) {
      onReschedule(dateTime.toISOString())
      setOpen(false)
    }
  }

  useEffect(() => {
    if (initDate) {
      setDate(initDate)
    }
  }, [initDate])

  return (
    <SendDialog
      title={intl.formatMessage({ id: 'button.reschedule' })}
      open={open}
      sendEvent={() => reschedule()}
      cancelEvent={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      disableSend={!dateTime}
    >
      <FormContainer>
        <DateSlotPicker userId={userId} date={date} setDate={setDate} setDateTime={setDateTime} />
      </FormContainer>
    </SendDialog>
  )
}

export default RescheduleDialog
