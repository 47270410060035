import { IMagicStream, MagicStreamActions } from '../entities/chat'
import settings from '../settings'
import { apiPostRequest, apiRequest } from './requests'

export const getMagicStreams = async (userId?: number) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/magicstreams/${userId ? `?userId=${userId}` : ''}`
  )
  return response['hydra:member'] as IMagicStream[]
}

export const postMagicStream = async (id: number, action: 0 | 1 | 2, dueDate?: string) => {
  const response = await apiPostRequest(`${settings.url.api}/magicstream/action/`, {
    id,
    action:
      action === 0
        ? MagicStreamActions.DECLINE
        : action === 1
        ? MagicStreamActions.ACCEPT
        : action === 2
        ? MagicStreamActions.RESCHEDULE
        : 'unknown',
    dueDate
  })
  return response
}
