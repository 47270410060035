import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import { ShoppingCart } from '@material-ui/icons'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRootState } from '../../redux'
import ExhibitorProductsEdit from './ExhibitorProductsEdit'
import ExhibitorProductsList from './ExhibitorProductsList'

const ExhibitorProductsInfos: React.FC = () => {
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)

  if (!exhibitor || (!exhibitor.editable && exhibitor.products.length === 0)) return <></>

  return (
    <div>
      <StyledTitle>
        <Typography variant="h6">
          <ShoppingCart />
          <FormattedMessage id="exhibitor.productsList.title" />
        </Typography>
        {exhibitor.editable && <ExhibitorProductsEdit />}
      </StyledTitle>
      <ExhibitorProductsList showButtonMore showImage />
    </div>
  )
}

const StyledTitle = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h6 {
    padding: 0;
    margin-bottom: 10px;
  }
`

export default ExhibitorProductsInfos
