import { EffectCallback, useEffect, useState } from 'react'

export const useEffectOnce = (effect: EffectCallback) => {
  const [called, setCalled] = useState(false)

  useEffect(() => {
    let clearEffect: any = null
    const timer = window.setTimeout(() => {
      if (!called) {
        setCalled(true)
        clearEffect = effect()
      }
    })
    return () => {
      if (timer) window.clearTimeout(timer)
      if (clearEffect) clearEffect()
    }
  }, [effect, called])
}
