import React from 'react'

const SvgTrash: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      d="M6 15.44c0-2.72 4.312-4.047 7.761-4.71-.597-.266-1.127-.598-1.658-.93-3.715-2.255-5.24-4.178-4.644-5.97.796-2.321 4.843-2.786 12.007-1.326.73.199 1.194.862 1.061 1.592-.199.73-.862 1.194-1.592 1.061-5.505-1.061-8.225-.796-8.889-.398.531 1.26 5.573 4.776 14.66 7.828 9.155 2.985 15.59 3.184 16.85 2.454-.332-.597-2.256-2.454-7.96-5.041-.664-.266-.995-1.062-.664-1.725.266-.663 1.062-.929 1.725-.663 6.899 3.051 10.083 5.77 9.552 8.093 0 .199-.066.398-.132.597L37.974 46.02C37.576 49.8 27.16 50 25.104 50c-2.056 0-12.47-.199-12.868-3.98L6.133 16.302c-.067-.266-.133-.597-.133-.863zm8.889 30.116c.597.597 4.18 1.724 10.216 1.724s9.618-1.127 10.282-1.724v-.067l5.44-26.534c-1.593.663-3.716 1.194-6.435 1.592-2.787.464-6.037.663-9.287.663-3.317 0-6.568-.265-9.42-.663-2.653-.398-4.71-.929-6.302-1.592l5.506 26.6c0-.066 0-.066 0 0 0-.066 0-.066 0 0zm3.184-32.571c-4.51.132-8.823 1.592-9.353 2.52v.067c.265.332 2.056 1.592 7.43 2.388 2.72.398 5.837.597 9.021.597 3.118 0 6.235-.199 8.889-.597a37.85 37.85 0 0 0 1.128-.199c-3.317-.398-7.231-1.327-11.211-2.653a93.855 93.855 0 0 1-5.705-2.123h-.199zm3.98-5.373c-.663-.266-1.061-.995-.862-1.791l1.327-4.047c.199-.663.73-1.194 1.326-1.526a2.922 2.922 0 0 1 1.99-.132l5.24 1.724c1.328.465 2.124 1.924 1.66 3.317l-1.328 4.047c-.199.597-.73.928-1.26.928-.133 0-.265 0-.398-.066-.73-.199-1.061-.995-.862-1.658l1.326-4.047-5.174-1.658-1.326 4.046c-.2.73-.93 1.062-1.659.863z"
    />
  </svg>
)

export default SvgTrash
