import styled from '@emotion/styled'
import { Avatar, AvatarProps, Tooltip } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContact } from '../../../entities'
import { useDetectImageExist } from '../../../hooks'
import { IRootState } from '../../../redux'
import { AVATAR_COLORS, WHITE } from '../../../theme/colors'
import { formatName } from '../../../utils'
import BadgeOnline from '../../ui/BadgeOnline/BadgeOnline'
import { CssModeratorContainer } from '../AvatarStyles'

interface IContactAvatarProps extends AvatarProps {
  contact: IContact
  showModerator?: boolean | undefined
}

const AvatarCustom: FC<IContactAvatarProps> = ({ contact, showModerator, ...props }) => {
  const intl = useIntl()
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const image = useDetectImageExist(contact.userPresence.user.avatarPath, true)

  const moderator = useMemo(() => {
    return (
      !!showModerator &&
      exhibitor &&
      exhibitor.moderators &&
      exhibitor.moderators.find((mod) => mod.id === contact.id)
    )
  }, [showModerator, contact, exhibitor])

  const firstnameFirstLetter = useMemo(
    () =>
      contact.userPresence.user.firstname
        ? contact.userPresence.user.firstname.substring(0, 1)
        : '',
    [contact]
  )

  const CustomAvatar = () => <Avatar
    alt={formatName(contact.userPresence.user).full}
    src={image}
    style={
      image
        ? {}
        : {
          color: WHITE,
          backgroundColor: AVATAR_COLORS[firstnameFirstLetter.toLowerCase()]
        }
    }
    {...props}
  >
    {formatName(contact.userPresence.user).short}
  </Avatar>

  return (
    <>
      {moderator ? (
        <StyledExhibitorContainer>
          <Tooltip
            title={intl.formatMessage({ id: 'ContactAvatar.Tooltip.title' })}
            placement="top"
          >
            <CustomAvatar />
          </Tooltip>
        </StyledExhibitorContainer>
      ) : (
        <CustomAvatar />
      )}
    </>
  )
}

const ContactAvatar: FC<IContactAvatarProps> = ({ contact, ...props }) => {
  return contact.userPresence.isOnline ? (
    <BadgeOnline
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      variant="dot"
    >
      <AvatarCustom contact={contact} {...props} />
    </BadgeOnline>
  ) : (
    <AvatarCustom contact={contact} {...props} />
  )
}

const StyledExhibitorContainer = styled.div`
  & {
    ${CssModeratorContainer}
  }
`

export default ContactAvatar
