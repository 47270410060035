import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Button, Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import AppLinks from '../../components/AppLinks'
import { breakpoints } from '../../components/breakpoints'
import ConferenceCard from '../../components/cards/ConferenceCard'
import ExhibitorCard from '../../components/cards/ExhibitorCard'
import LiveCard from '../../components/cards/LiveCard'
import ShowroomCard from '../../components/cards/ShowroomCard'
import Highlight from '../../components/globals/Highlight/Highlight'
import LoadingCard from '../../components/LoadingCard'
import CountDown from '../../components/ui/CountDown/CountDown'
import Slider from '../../components/ui/Slider/Slider'
import Title from '../../components/ui/Title/Title'
import { ConferenceStatus, LanguageKeys } from '../../entities'
import { useEffectOnce, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { getLives, getVods } from '../../store/conference'
import { loadScoredExhibitors, loadShowrooms } from '../../store/exhibitor'
import { setPageReferral } from '../../store/gtm'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'

const HomePage = () => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const [firstLiveIndex, setFirstLiveIndex] = useState(-1)
  const lives = useSelector((state: IRootState) => state.conferenceState.lives)
  const vods = useSelector((state: IRootState) => state.conferenceState.vods)
  const loadingLives = useSelector((state: IRootState) => state.conferenceState.loadingLives)
  const loadingVods = useSelector((state: IRootState) => state.conferenceState.loadingVods)
  const loadedLives = useSelector((state: IRootState) => state.conferenceState.loadedLives)
  const loadedVods = useSelector((state: IRootState) => state.conferenceState.loadedVods)
  const totalExhibitors = useSelector((state: IRootState) => state.exhibitorState.totalExhibitors)
  const exhibitors = useSelector((state: IRootState) => state.exhibitorState.scoredExhibitors)
  const loadingExhibitors = useSelector((state: IRootState) => state.exhibitorState.loadingScored)
  const showrooms = useSelector((state: IRootState) => state.exhibitorState.showrooms)
  const loadingShowrooms = useSelector((state: IRootState) => state.exhibitorState.loadingShowrooms)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const locale = useSelector((state: IRootState) => state.appState.locale)
  const dispatch = useThunkDispatch()

  const memoLive1 = useMemo(() => {
    if (lives) {
      for (let i = 0; i < lives.items.length; i++) {
        if (
          lives.items[i].status === ConferenceStatus.IN_PROGRESS ||
          (lives.items[i].start_date &&
            lives.items[i].end_date &&
            new Date(lives.items[i].start_date).getTime() < new Date().getTime() &&
            new Date(lives.items[i].end_date).getTime() > new Date().getTime())
        ) {
          setFirstLiveIndex(i)
          return lives.items[i]
        }
      }
    } else {
      return null
    }
  }, [lives])

  const memoLive2 = useMemo(() => {
    if (lives && firstLiveIndex > -1 && firstLiveIndex + 1 < lives.total) {
      for (let i = firstLiveIndex + 1; i < lives.items.length; i++) {
        if (
          lives.items[i].status === ConferenceStatus.IN_PROGRESS ||
          (lives.items[i].start_date &&
            lives.items[i].end_date &&
            new Date(lives.items[i].start_date).getTime() < new Date().getTime() &&
            new Date(lives.items[i].end_date).getTime() > new Date().getTime())
        ) {
          return lives.items[i]
        }
      }
    } else {
      return null
    }
  }, [lives, firstLiveIndex])

  const memoConferenceNext = useMemo(
    () =>
      lives && lives.items.length
        ? lives.items.filter(
            (item) =>
              (!memoLive1 || item.id !== memoLive1.id) && (!memoLive2 || item.id !== memoLive2.id)
          )
        : [],
    [memoLive1, memoLive2, lives]
  )

  const localizedBanner = useMemo(() => {
    let banner = settings.theme.header.banner
    if (locale === LanguageKeys.en && settings.theme.header.banner_en) {
      banner = settings.theme.header.banner_en
    }
    return banner
  }, [locale])

  const localizedAppLinks = useMemo(() => {
    let appLinks = eventCo?.appLinkValues
    if (locale === LanguageKeys.en && eventCo?.appLinkValuesEn) {
      appLinks = eventCo?.appLinkValuesEn
    }
    return appLinks
  }, [locale, eventCo])

  useEffectOnce(() => {
    dispatch(getLives(loadedLives))
    dispatch(getVods(loadedVods))
    dispatch(loadScoredExhibitors())
    dispatch(loadShowrooms())
  })

  // gtm DATA
  useEffectOnce(() => {
    const title = intl.formatMessage({ id: 'gtm.home.title' })
    dispatch(setPageReferral(title))
    sendDataToGTM({
      event: 'page-view',
      pageTitle: title,
      pageType: intl.formatMessage({ id: 'gtm.home.type' }),
      pageReferral
    })
  })

  useEffectOnce(() => {
    window.scrollTo(0, 0)
  })

  return (
    <StyledHome>
      {localizedBanner && <StyledBanner src={localizedBanner} alt={settings.theme.header.title} />}
      {eventCo && eventCo.startDate && <CountDown date={new Date(eventCo.startDate)} />}
      {localizedAppLinks && <AppLinks values={localizedAppLinks} />}
      {(loadingLives || memoConferenceNext.length > 0 || memoLive1 || memoLive2) && (
        <>
          <StyledLives>
            <StyledTopContainer>
              <Typography variant="h2">
                <FormattedMessage id="home.lives" />
              </Typography>
              <Button component={Link} to="/conference" color="primary">
                <FormattedMessage id="home.conferences.more" />
              </Button>
            </StyledTopContainer>
            <Grid container spacing={3}>
              {loadingLives &&
                !memoLive1 &&
                !memoLive2 &&
                Array(2)
                  .fill(0)
                  .map((value, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <LoadingCard cardType="live" />
                    </Grid>
                  ))}
              {memoLive1 && (
                <Grid item xs={12} md={6}>
                  <LiveCard live={memoLive1} size="large" />
                </Grid>
              )}
              {memoLive2 && (
                <Grid item xs={12} md={6}>
                  <LiveCard live={memoLive2} size="large" />
                </Grid>
              )}
            </Grid>
          </StyledLives>
          <StyledConferences>
            {loadingLives && memoConferenceNext.length === 0 && (
              <Slider slidesToShow={4}>
                {Array(5)
                  .fill(0)
                  .map((value, index) => (
                    <StyledLoadingCardConferenceContainer key={index}>
                      <LoadingCard cardType="conference" />
                    </StyledLoadingCardConferenceContainer>
                  ))}
              </Slider>
            )}
            {memoConferenceNext.length > 0 && (
              <>
                {eventCo && eventCo?.nbExhibitors === 0 ? (
                  <Grid container spacing={2}>
                    {memoConferenceNext.map((conference, index) => (
                      <StyledGrid key={index} item xs={12}>
                        <ConferenceCard conference={conference} />
                      </StyledGrid>
                    ))}
                  </Grid>
                ) : (
                  <Slider slidesToShow={4}>
                    {memoConferenceNext.map((conference, index) => (
                      <div key={index}>
                        <ConferenceCard conference={conference} />
                      </div>
                    ))}
                  </Slider>
                )}
              </>
            )}
          </StyledConferences>
        </>
      )}
      <Highlight type="homepage" />
      {!loadingVods && vods.total > 0 && (
        <StyledVod>
          <StyledTopContainer>
            <Title titleId="conference.vod.title" variant="h2" />
            <Button component={Link} to="/conference?type=all" color="primary">
              <FormattedMessage id="home.conferences.more" />
            </Button>
          </StyledTopContainer>
          <Slider slidesToShow={4}>
            {vods.items.map((vod, index) => (
              <div key={index}>
                <ConferenceCard conference={vod} />
              </div>
            ))}
          </Slider>
        </StyledVod>
      )}
      {totalExhibitors > 0 && (
        <StyledExhibitors>
          <StyledTopContainer>
            <Title titleId="home.exhibitors" variant="h2" />
            <Button component={Link} to="/exposant" color="primary">
              <FormattedMessage id="home.exhibitors.more" />
            </Button>
          </StyledTopContainer>
          <Grid container spacing={2}>
            {loadingExhibitors &&
              exhibitors.length === 0 &&
              Array(4)
                .fill(0)
                .map((value, index) => (
                  <StyledGrid key={index} item xs={12}>
                    <LoadingCard cardType="exhibitor" />
                  </StyledGrid>
                ))}
            {exhibitors.length > 0 &&
              exhibitors.map((exhibitor, index) => (
                <StyledGrid key={index} item xs={12}>
                  <ExhibitorCard exhibitor={exhibitor} />
                </StyledGrid>
              ))}
            {!loadingExhibitors && exhibitors.length === 0 && (
              <StyledGridEmptyList item xs={12}>
                <Alert severity="warning">
                  <FormattedMessage id="exhibitor.list.empty" />
                </Alert>
              </StyledGridEmptyList>
            )}
          </Grid>
        </StyledExhibitors>
      )}
      {!loadingShowrooms && showrooms.length > 0 && (
        <StyledExhibitorDemos>
          <StyledTopContainer>
            <Title titleId="home.demos" variant="h2" />
          </StyledTopContainer>
          {loadingShowrooms &&
            showrooms.length === 0 &&
            Array(4)
              .fill(0)
              .map((value, index) => (
                <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                  <LoadingCard cardType="exhibitor" />
                </Grid>
              ))}
          {showrooms.length > 0 && (
            <Slider slidesToShow={4}>
              {showrooms.map((showroom, index) => (
                <div key={index}>
                  <ShowroomCard showroom={showroom} />
                </div>
              ))}
            </Slider>
          )}
        </StyledExhibitorDemos>
      )}
      <Helmet>
        <title>
          {`${intl.formatMessage({ id: 'gtm.home.title' })} - ${settings.theme.header.title}`}
        </title>
      </Helmet>
    </StyledHome>
  )
}

const StyledHome = styled.div`
  & {
    max-width: 1280px;
    margin: 0 auto;
  }
`

const StyledLives = styled.div``

const StyledConferences = styled.div`
  & {
    margin-top: 30px;

    .MuiPaper-root {
      margin-right: 15px;
    }
  }
`

const StyledVod = styled.div`
  & {
    margin-top: 30px;
  }
`
const StyledGrid = styled(Grid)`
  & {
    @media (min-width: 640px) {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }
    @media (min-width: ${breakpoints.lg}) {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
    @media (min-width: 1180px) {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }
    @media (min-width: 1400px) {
      flex-grow: 0;
      max-width: 33%;
      flex-basis: 33%;
    }
    @media (min-width: ${breakpoints.xxl}) {
      flex-grow: 0;
      max-width: 25%;
      flex-basis: 25%;
    }
  }
`

const StyledExhibitors = styled.div`
  & {
    .MuiGrid-grid-lg-auto,
    .MuiGrid-grid-xl-auto {
      width: 20%;
    }
  }
`

const StyledExhibitorDemos = styled.div`
  & {
    .MuiGrid-grid-lg-auto,
    .MuiGrid-grid-xl-auto {
      width: 20%;
    }
  }
`

const StyledTopContainer = styled.div`
  & {
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.md}) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
`

const StyledLoadingCardConferenceContainer = styled.div`
  & {
    padding: 4px;
  }
`

const StyledGridEmptyList = styled(Grid)`
  & {
    margin-top: 1em;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
`

const StyledBanner = styled.img`
  & {
    display: block;
    object-fit: contain;
    width: 100%;
    margin: 10px auto 0;
    border-radius: ${BORDER_RADIUS_CARD};
  }
`

export default HomePage
