import styled from '@emotion/styled'
import React, { ReactNodeArray } from 'react'
import { useIntl } from 'react-intl'
import Slick from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { ReactComponent as ChevronIcon } from '../../../assets/images/icons/chevron.svg'
import { PRIMARY, SECONDARY, WHITE } from '../../../theme/colors'
import { breakpoints, breakpointsValues } from '../../breakpoints'

interface ISlider {
  children: ReactNodeArray
  slidesToShow?: number
}

interface IArrowProps {
  className?: string
  style?: React.CSSProperties
  onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined
}

const defaultStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '48px',
  width: '48px',
  zIndex: 1
}

const SampleNextArrow = (props: IArrowProps) => {
  const intl = useIntl()
  const { className, style, onClick } = props

  return (
    <div
      className={className}
      style={{
        ...style,
        ...defaultStyle,
        right: 0
      }}
      onClick={onClick}
      aria-label={intl.formatMessage({ id: 'titleAccess.Slider.ChevronRightIcon' })}
    >
      <ChevronIcon />
    </div>
  )
}

const SamplePrevArrow = (props: IArrowProps) => {
  const intl = useIntl()
  const { className, style, onClick } = props

  return (
    <div
      className={className}
      style={{
        ...style,
        ...defaultStyle,
        left: 0
      }}
      onClick={onClick}
      aria-label={intl.formatMessage({ id: 'titleAccess.Slider.ChevronLeftIcon' })}
    >
      <ChevronIcon />
    </div>
  )
}

const Slider: React.FC<ISlider> = ({ children, slidesToShow = 4 }) => {
  const settings = {
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow,
    slidesToScroll: slidesToShow,
    swipeToSlide: true,
    rows: 1,
    variableWidth: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    useTransform: false,
    responsive: [
      {
        breakpoint: breakpointsValues.xxl,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: breakpointsValues.lg,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: breakpointsValues.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: breakpointsValues.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return <StyledSlick {...settings}>{children}</StyledSlick>
}

const StyledSlick = styled(Slick)`
  & {
    .slick {
      &-track {
        display: flex !important;
      }
      &-slide {
        height: inherit !important;

        > div,
        > div > div {
          height: 100%;
        }
      }
      &-track {
        margin-left: 0 !important;
      }
      &-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 16px 24px 2px #e9e9f1, 0 6px 30px 5px #e9e9f1, 0 8px 10px -5px #e9e9f1;
        background-color: ${PRIMARY} !important;

        &:before {
          display: none;
        }
        &.slick-disabled {
          display: none !important;
        }
        svg {
          height: 24px;
          width: 24px;
          color: ${WHITE};
          transition: all 0.3s;
        }
        &:hover {
          background-color: ${SECONDARY} !important;
        }
      }
      &-prev {
        display: none !important;

        svg {
          transform: rotate(180deg);
        }
        @media (min-width: ${breakpoints.lg}) {
          display: flex !important;
        }
      }
      &-next {
        @media (max-width: ${breakpoints.lg}) {
          right: 20px !important;
        }
      }
    }
  }
`

export default Slider
