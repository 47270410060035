import { IOpentokRecommended, IQueue, IRoomQueue } from '../../entities'
import { ActionsTypes } from './model'

export const setOpentokError = (error?: string | undefined) => ({
  type: ActionsTypes.SET_OPENTOK_ERROR,
  error
})

export const setOpentokStreaming = (streamingActive: boolean) => ({
  type: ActionsTypes.SET_OPENTOK_STREAMING,
  streamingActive
})

export const setOpentokBroadcaster = (broadcaster: boolean) => ({
  type: ActionsTypes.SET_OPENTOK_BROADCASTER,
  broadcaster
})

export const setOpentokRecommended = (opentokRecommended: IOpentokRecommended) => ({
  type: ActionsTypes.SET_OPENTOK_RECOMMENDED,
  opentokRecommended
})

export const setRoomNumber = (roomNumber: number) => ({
  type: ActionsTypes.SET_ROOM_NUMBER,
  roomNumber
})

export const setRoomToken = (roomToken: string) => ({
  type: ActionsTypes.SET_ROOM_TOKEN,
  roomToken
})

export const resetRoomToken = () => ({
  type: ActionsTypes.RESET_ROOM_TOKEN
})

export const setRoomTokenLoading = (loadingRoomToken: boolean) => ({
  type: ActionsTypes.SET_ROOM_TOKEN_LOADING,
  loadingRoomToken
})

export const setFullscreenSubscriber = (fullscreenSubscriber: string) => ({
  type: ActionsTypes.SET_FULLSCREEN_SUBSCRIBER,
  fullscreenSubscriber
})

export const setScreenSharingAvailable = (screenSharingAvailable: boolean) => ({
  type: ActionsTypes.SET_SCREEN_SHARING_AVAILABLE,
  screenSharingAvailable
})

export const updateRoomLocked = (roomId: number, roomLocked: boolean) => ({
  type: ActionsTypes.UPDATE_ROOM_LOCKED,
  roomId,
  roomLocked
})

export const setRoomUpdating = (roomUpdating: boolean) => ({
  type: ActionsTypes.SET_ROOM_UPDATING,
  roomUpdating
})

export const setRoomError = (roomError?: string | undefined) => ({
  type: ActionsTypes.SET_ROOM_ERROR,
  roomError
})

export const setLoadingRoomQueue = (loadingRoomQueue: boolean) => ({
  type: ActionsTypes.SET_ROOM_QUEUE_LOADING,
  loadingRoomQueue
})

export const setErrorRoomQueue = (errorRoomQueue?: string | undefined) => ({
  type: ActionsTypes.SET_ROOM_QUEUE_ERROR,
  errorRoomQueue
})

export const setLoadingRemoveRoomQueue = (loadingRemoveRoomQueue: boolean) => ({
  type: ActionsTypes.REMOVE_ROOM_QUEUE_LOADING,
  loadingRemoveRoomQueue
})

export const setErrorRemoveRoomQueue = (errorRemoveRoomQueue?: string | undefined) => ({
  type: ActionsTypes.REMOVE_ROOM_QUEUE_ERROR,
  errorRemoveRoomQueue
})

export const setRoomQueue = (roomQueue: IRoomQueue) => ({
  type: ActionsTypes.SET_ROOM_QUEUE,
  roomQueue
})

export const addRoomQueue = (roomQueueId: number, queue: IQueue) => ({
  type: ActionsTypes.ADD_ROOM_QUEUE,
  roomQueueId,
  queue
})

export const removeRoomQueue = (roomQueueId: string | number) => ({
  type: ActionsTypes.REMOVE_ROOM_QUEUE,
  roomQueueId
})

export const setRoomQueued = (roomQueued: boolean) => ({
  type: ActionsTypes.SET_ROOM_QUEUED,
  roomQueued
})

export const setExternalError = (errorExternal?: string | undefined) => ({
  type: ActionsTypes.SET_EXTERNAL_ERROR,
  errorExternal
})

export const setExternalLoading = (loadingExternal: boolean) => ({
  type: ActionsTypes.SET_EXTERNAL_LOADING,
  loadingExternal
})

export const setStartOfficeCall = (startOfficeCall: boolean) => ({
  type: ActionsTypes.SET_START_OFFICE_CALL,
  startOfficeCall
})

export const setOnOfficeRoom = (onOfficeRoom: boolean) => ({
  type: ActionsTypes.SET_ON_OFFICE_ROOM,
  onOfficeRoom
})

export const setOfficeRoomIndex = (officeRoomIndex: number) => ({
  type: ActionsTypes.SET_OFFICE_ROOM_INDEX,
  officeRoomIndex
})

export const setRoomAccessDenied = (roomAccessDenied: boolean) => ({
  type: ActionsTypes.SET_ROOM_ACCESS_DENIED,
  roomAccessDenied
})
