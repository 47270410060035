import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import CallEndIcon from '@material-ui/icons/CallEnd'
import ChatIcon from '@material-ui/icons/Chat'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import {
  sendResponse,
  setCall,
  setCurrentConversation,
  setMinimizeChat,
  setOpenChat,
  startConversation
} from '../../store/chat'
import { setPageReferral } from '../../store/gtm'
import { WHITE } from '../../theme/colors'
import { formatName } from '../../utils'
import ContactPopperContent from '../contacts/ContactPopperContent'

type IIncomingCallProps = {
  mode?: 'small' | 'fullscreen'
}

const IncomingCall: React.FC<IIncomingCallProps> = ({ mode = 'fullscreen' }) => {
  const intl = useIntl()
  const location = useSelector((state: IRootState) => state.appState.location)
  const call = useSelector((state: IRootState) => state.chatState.call)
  const conversations = useSelector((state: IRootState) => state.chatState.conversations)
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const dispatch = useThunkDispatch()

  const onStartCall = () => {
    if (call && call.url && call.author) {
      dispatch(setPageReferral(`${pageReferral}::incoming-call`))
      if (location && location.split('?')[0] === call.url.split('?')[0]) {
        browserHistory.replace(call.url)
      } else {
        browserHistory.push(call.url)
      }
      dispatch(setCall({}))
      dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'invitation.accepted'
          }),
          1,
          call.author.id
        )
      )
    }
  }

  const onEndCall = () => {
    if (call && call.url && call.author) {
      dispatch(setCall({}))
      dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'invitation.refused'
          }),
          0,
          call.author.id
        )
      )
    }
  }

  const onChatCall = () => {
    if (call && call.url && call.author) {
      dispatch(setCall({}))
      dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'invitation.refused'
          }),
          0,
          call.author.id
        )
      )
      if (
        call.author &&
        conversations.find(
          (conversation) => call.author && conversation.contact.id === call.author.id
        )
      ) {
        // already started
        dispatch(setOpenChat(true))
        dispatch(setMinimizeChat(false))
        dispatch(setCurrentConversation(call.author.id))
      } else {
        // start
        dispatch(
          startConversation({
            id: call.author.id,
            userPresence: {
              isOnline: true,
              user: call.author
            }
          })
        )
      }
    }
  }

  return (
    <IncomingCallContainer small={mode === 'small'}>
      {call.author && (
        <ContactPopperContent
          contact={{
            id: call.author.id,
            userPresence: {
              isOnline: true,
              user: call.author
            }
          }}
          disabledActions
        />
      )}
      {call.author && (
        <IncomingCallMessage>
          <FormattedMessage
            id="invitation.message"
            values={{ user: formatName(call.author).full }}
          />
        </IncomingCallMessage>
      )}
      <IncomingCallActions>
        <IconButton onClick={onStartCall} title={intl.formatMessage({ id: 'invitation.accept' })}>
          <PhoneInTalkIcon
            fontSize="large"
            htmlColor="green"
            titleAccess={intl.formatMessage({ id: 'titleAccess.IncommingCall.PhoneInTalkIcon' })}
          />
        </IconButton>
        <IconButton onClick={onEndCall} title={intl.formatMessage({ id: 'invitation.refuse' })}>
          <CallEndIcon
            fontSize="large"
            htmlColor="red"
            titleAccess={intl.formatMessage({ id: 'titleAccess.IncommingCall.CallEndIcon' })}
          />
        </IconButton>
        <IconButton onClick={onChatCall} title={intl.formatMessage({ id: 'invitation.chat' })}>
          <ChatIcon
            fontSize="large"
            htmlColor="white"
            titleAccess={intl.formatMessage({ id: 'titleAccess.IncommingCall.ChatIcon' })}
          />
        </IconButton>
      </IncomingCallActions>
    </IncomingCallContainer>
  )
}

const IncomingCallContainer = styled.div<{ small: boolean }>`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(55, 55, 55, 0.75);

  ${(props) =>
    props.small &&
    `
    height: 100vh;
    width: 350px;
    right: 0;
    left: auto;
  `}
`

const IncomingCallMessage = styled.div`
  font-size: 30px;
  color: ${WHITE};
  text-align: center;
  margin-top: 15px;
`

const IncomingCallActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & svg {
    height: 100px;
    width: 100px;
  }
`

export default IncomingCall
