import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { alpha, Avatar, AvatarProps, Tooltip } from '@material-ui/core'
import { PopperProps } from '@material-ui/core/Popper'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ScheduleIcon from '@material-ui/icons/Schedule'
import SmsIcon from '@material-ui/icons/Sms'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContactUser, INotificationUser, IUser } from '../../../entities'
import { useDetectImageExist } from '../../../hooks'
import { IRootState } from '../../../redux'
import { PRIMARY_COLOR } from '../../../theme'
import {
  AVATAR_COLORS,
  AVATAR_ME_COLOR,
  GRAY,
  PRIMARY,
  SECONDARY_LIGHT,
  WHITE
} from '../../../theme/colors'
import { formatName } from '../../../utils'
import ContactPopper from '../../contacts/ContactPopper'
import ContactPopperNoteTags from '../../contacts/ContactPopperNoteTags'
import Loading from '../../Loading'
import { CssModeratorContainer, CssMoveAvatar, CssPresenterContainer } from '../AvatarStyles'

interface IUserAvatarProps extends AvatarProps {
  avatarRef?: React.RefObject<HTMLDivElement>
  user: IUser | IContactUser | INotificationUser
  isOnline?: boolean | undefined
  isSpeak?: boolean | undefined
  isModerator?: boolean
  isNotHere?: boolean
  isOffice?: boolean | undefined
  isSmall?: boolean
  showDetails?: boolean | undefined
  showModerator?: boolean | undefined
  showMe?: boolean | undefined
  showNote?: boolean | undefined
  hasRollIn?: boolean | undefined
  disabledSendMessage?: boolean | undefined
  disabledAnimation?: boolean | undefined
  popper?: Omit<PopperProps, 'children' | 'open'>
  onEnterOffice?: () => void
}

const UserAvatar: FC<IUserAvatarProps> = ({
  avatarRef,
  user,
  isOnline,
  isSpeak,
  isModerator,
  isNotHere,
  isSmall,
  isOffice,
  showDetails,
  showModerator,
  showMe,
  showNote,
  hasRollIn,
  disabledSendMessage,
  disabledAnimation,
  onEnterOffice,
  popper,
  ...props
}) => {
  const intl = useIntl()
  const anchorNoteRef = useRef<HTMLDivElement>(null)
  const anchorRef = useRef<HTMLDivElement>(null)
  const popperRef = useRef<any>(null)
  const userCurrent = useSelector((state: IRootState) => state.appState.user)
  const [mouseover, setMouseover] = useState(false)
  const [openNote, setOpenNote] = useState(false)
  const [open, setOpen] = useState(false)
  const exhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const conference = useSelector((state: IRootState) => state.conferenceState.conference)
  const contacts = useSelector((state: IRootState) => state.participantState.contacts)
  const image = useDetectImageExist(user.avatarPath, true)

  const handleToggleNote = (event: React.MouseEvent<EventTarget>) => {
    event.preventDefault()
    event.stopPropagation()
    setOpenNote(!openNote)
  }

  const handleCloseNote = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpenNote(false)
  }

  const isFavoriteContact = useMemo(
    () =>
      user &&
      (!!user.isContact || (contacts && !!contacts.find((contact) => contact.id === user.id))),
    [user, contacts]
  )

  const moderator = useMemo(() => {
    return (
      !!showModerator &&
      exhibitor &&
      exhibitor.moderators &&
      exhibitor.moderators.find((mod) => mod.id === user.id)
    )
  }, [showModerator, user, exhibitor])

  const presenter = useMemo(() => {
    return (
      !!showModerator &&
      conference &&
      conference.presenters &&
      conference.presenters.find((pres) => pres.id === user.id)
    )
  }, [showModerator, user, conference])

  const me = useMemo(() => {
    return !!showMe && user.id === userCurrent?.id
  }, [showMe, user, userCurrent])

  // cannot use isContact here
  const memoContact = useMemo(() => contacts.find((contact) => contact.id === user.id), [
    contacts,
    user
  ])

  const firstnameFirstLetter = useMemo(
    () => (user.firstname ? user.firstname.substring(0, 1) : ''),
    [user]
  )

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    let timer: number | null = null
    if (mouseover) {
      timer = window.setTimeout(() => {
        setOpen(true)
      }, 500)
    } else {
      timer = window.setTimeout(() => {
        setOpen(false)
      }, 250)
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [mouseover])

  return user ? (
    <>
      <StyledUserAvatar
        disabledAnimation={disabledAnimation}
        isModerator={!!moderator || isModerator}
        isPresenter={!!presenter}
        isMe={!!me}
        isFavoriteContact={isFavoriteContact}
        isNotHere={isNotHere}
        isSmall={isSmall}
        hasRollIn={hasRollIn}
        onMouseOver={() => {
          if (showDetails) {
            setMouseover(true)
          }
        }}
        onMouseOut={() => {
          if (showDetails) {
            setMouseover(false)
          }
        }}
      >
        {isSpeak && !me && (
          <StyledSmsIcon duration={Math.floor(Math.random() * 12) + 10}>
            <SmsIcon />
          </StyledSmsIcon>
        )}
        {isNotHere && isOnline && (
          <StyledNotHereIcon>
            <ScheduleIcon />
          </StyledNotHereIcon>
        )}
        {showNote && !me && memoContact?.note?.id && (
          <StyledNote ref={anchorNoteRef} onClick={handleToggleNote}>
            <Tooltip
              title={intl.formatMessage({
                id: 'tooltip.UserAvatar.LibraryBooksIcon'
              })}
              placement="bottom"
            >
              <LibraryBooksIcon
                fontSize="small"
                titleAccess={intl.formatMessage({ id: 'titleAccess.UserAvatar.LibraryBooksIcon' })}
              />
            </Tooltip>
          </StyledNote>
        )}
        <Avatar
          ref={avatarRef || anchorRef}
          alt={formatName(user).full}
          src={image}
          style={
            image
              ? {
                backgroundColor: WHITE
              }
              : {
                color: WHITE,
                backgroundColor: AVATAR_COLORS[firstnameFirstLetter.toLowerCase()]
              }
          }
          {...props}
        >{formatName(user).short}</Avatar>
        {/* {moderator && (
        <StyledModerator
          src={moderator.avatarPath}
          alt={formatName(moderator).full}
        />
      )} */}
        {showDetails && (
          <ContactPopper
            popperRef={popperRef}
            open={open}
            anchorRef={avatarRef || anchorRef}
            contact={{
              id: user.id,
              userPresence: {
                isOnline: !!isOnline,
                user
              }
            }}
            disabledSendMessage={disabledSendMessage}
            isOnline={isOnline}
            isOffice={isOffice}
            onEnterOffice={onEnterOffice}
            handleClose={handleClose}
            {...popper}
          />
        )}
      </StyledUserAvatar>
      {showNote && !me && memoContact?.note?.id && (
        <ContactPopperNoteTags
          open={openNote}
          setOpen={setOpenNote}
          anchorRef={anchorNoteRef}
          contact={memoContact}
          handleClose={handleCloseNote}
          placement="bottom"
        />
      )}
    </>
  ) : (
    <Loading />
  )
}

const rollIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-80px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const speakUser = keyframes`
  0%{font-size: 1.25rem;}
  33%{font-size: 2rem;}
  45%{font-size: 1.6rem;}
  75%{font-size: 2.2rem;}
  100%{font-size: 1.25rem;}
`

const hideSpeak = keyframes`
  0%{opacity: 0;}
  1%{opacity: 1;}
  10%{opacity: 1;}
  11%{opacity: 0;}
  55%{opacity: 0;}
  56%{opacity: 1;}
  76%{opacity: 1;}
  77%{opacity: 0;}
  100%{opacity: 0;}
`

const StyledUserAvatar = styled.div<{
  disabledAnimation?: boolean | null
  isModerator?: boolean | null
  isPresenter?: boolean | null
  isMe?: boolean | null
  isFavoriteContact?: boolean
  isSpeak?: boolean | null
  isNotHere?: boolean | null
  isSmall?: boolean | null
  hasRollIn?: boolean | null
}>`
  & {
    position: relative;
    z-index: 1;
    ${(props) =>
    props.disabledAnimation
      ? ''
      : props.isModerator
        ? CssModeratorContainer
        : props.isPresenter
          ? CssPresenterContainer
          : props.isMe
            ? CssMoveAvatar(2, AVATAR_ME_COLOR, '8px')
            : props.isFavoriteContact
              ? CssMoveAvatar(0.9, SECONDARY_LIGHT, '1px')
              : CssMoveAvatar(0.9, WHITE, '1px')}
    ${(props) =>
    props.hasRollIn
      ? css`
            animation: ${rollIn} 2s forwards;
          `
      : ''}
    ${(props) =>
    props.isSmall
      ? css`
            transform: scale(0.75);
          `
      : ''}
    
    & img, & .MuiAvatar-circle {
      border-radius: 50%;
      white-space: nowrap;
      ${(props) =>
    props.isNotHere
      ? css`
              filter: grayscale(1);
            `
      : ''}
    }
  }
`

const StyledSmsIcon = styled.div<{ duration: number }>`
  & {
    position: absolute;
    bottom: 78%;
    left: 78%;
    color: ${PRIMARY_COLOR};
    opacity: 0;
    animation-name: ${hideSpeak};
    animation-duration: ${(props) => (props.duration ? `${props.duration}` : `15`)}s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    z-index: 50;

    svg {
      filter: drop-shadow(3px 3px 2px ${alpha(WHITE, 0.7)});
      animation-name: ${speakUser};
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
`

const StyledNotHereIcon = styled.div`
  & {
    position: absolute;
    right: 70%;
    top: 70%;
    z-index: 10;
    border-radius: 50%;
    background-color: ${WHITE};
    padding: 3px;

    svg {
      color: ${GRAY};
      height: 15px;
      width: 15px;
    }
  }
`

const StyledNote = styled.div`
  & {
    position: absolute;
    right: 70%;
    top: 70%;
    z-index: 10;
    border-radius: 50%;
    background-color: ${WHITE};
    padding: 3px;
    cursor: pointer;

    svg {
      color: ${PRIMARY};
    }
  }
`

export default UserAvatar
