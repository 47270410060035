import { IconButton } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import SyncIcon from '@material-ui/icons/Sync'
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import {
  loadAgenda,
  setExtendedPanel,
  setOpenAvailabilities,
  setOpenSyncCalendar,
  unsync
} from '../../store/app'
import { loadMagicStreams, setErrorMagicStreams } from '../../store/chat'
import { MAGIC_STREAMS_HEIGHT } from '../../theme/sizes'
import AgendaExtended from '../agenda/AgendaExtended'
import ExtensiblePanel from '../globals/ExtensiblePanel/ExtensiblePanel'
import StreamsPanelContent from '../magicstream/StreamsPanelContent'
import StreamsPanelExtended from '../magicstream/StreamsPanelExtended'
import AvailabilitiesDialog from '../modals/AvailabilitiesDialog'
import ConfirmDialog from '../modals/ConfirmDialog'
import SynchronizeCalendarDialog from '../modals/SynchronizeCalendarDialog'

const StreamsPanel: FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const magicStream = useMagicStream()
  const [scrollTop, setScrollTop] = useState(false)
  const [priorityHigh, setPriorityHigh] = useState(false)
  const [onlyAppointment, setOnlyAppointment] = useState(false)
  const [unsyncInProgress, setUnsyncInProgress] = useState(false)
  const [confirmUnsync, setConfirmUnsync] = useState(false)
  const [streamType, setStreamType] = useState('all')
  const planningOrder = useSelector((state: IRootState) => state.appState.planningOrder)
  const openAgenda = useSelector((state: IRootState) => state.appState.openAgenda)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const magicStreams = useSelector((state: IRootState) => state.chatState.magicStreams)
  const errorMagicStreams = useSelector((state: IRootState) => state.chatState.errorMagicStreams)
  const user = useSelector((state: IRootState) => state.appState.user)

  const handleConfirmUnsync = () => {
    setConfirmUnsync(true)
  }

  const handleUnsync = async () => {
    setConfirmUnsync(false)
    setUnsyncInProgress(true)
    await dispatch(unsync(user?.id!))
    setUnsyncInProgress(false)
  }

  const dispatch = useThunkDispatch()
  useEffect(() => {
    dispatch(loadMagicStreams())
  }, [dispatch])

  // show auto magicstream / appointment
  useEffect(() => {
    const magicstream = new URLSearchParams(location.search).get('magicstream') === 'true' || false
    const appointment = new URLSearchParams(location.search).get('appointment') === 'true' || false
    if (magicstream) dispatch(setExtendedPanel('magic.streams', true))
    if (appointment) setOnlyAppointment(true)
  }, [location, magicStreams, dispatch])

  useEffect(() => {
    if (magicStreams) {
      setScrollTop(true)
      process.nextTick(() => {
        setScrollTop(false)
      })
    }
  }, [magicStreams])

  useEffect(() => {
    if (errorMagicStreams) {
      magicStream.error(errorMagicStreams)
      dispatch(setErrorMagicStreams(undefined))
    }
  }, [errorMagicStreams, magicStream, dispatch])

  return (
    <ExtensiblePanel
      full={!!eventCo && eventCo.eventcoAppointment}
      height={eventCo && eventCo.eventcoAppointment ? undefined : MAGIC_STREAMS_HEIGHT}
      titleKey={openAgenda ? 'agenda.title' : 'magic.streams'}
      scrollTop={scrollTop}
      subheaderNode={
        <>
          {eventCo && !eventCo.eventcoAppointment && !openAgenda && (
            <IconButton onClick={() => setOnlyAppointment(!onlyAppointment)}>
              <CalendarTodayIcon
                titleAccess={intl.formatMessage({
                  id: onlyAppointment
                    ? 'titleAccess.Stream.CalendarTodayIcon.disabled'
                    : 'titleAccess.Stream.CalendarTodayIcon'
                })}
                color={onlyAppointment ? 'primary' : 'disabled'}
              />
            </IconButton>
          )}
          {openAgenda && (
            <IconButton onClick={() => dispatch(setOpenAvailabilities(true))}>
              <EventBusyIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.Stream.EventBusyIcon' })}
                color="primary"
              />
            </IconButton>
          )}
          {eventCo?.isBroadcaster && (
            <>
              {user?.isCalendarSynchronized || unsyncInProgress ? (
                <IconButton onClick={() => handleConfirmUnsync()}>
                  <SyncIcon
                    titleAccess={intl.formatMessage({
                      id: 'titleAccess.Stream.UnsyncGoogleCalendar'
                    })}
                    color="primary"
                  />
                </IconButton>
              ) : (
                <IconButton onClick={() => dispatch(setOpenSyncCalendar(true))}>
                  <SyncDisabledIcon
                    titleAccess={intl.formatMessage({
                      id: 'titleAccess.Stream.SyncGoogleCalendar'
                    })}
                    color="primary"
                  />
                </IconButton>
              )}
            </>
          )}
          {/*<IconButton onClick={() => setPriorityHigh(!priorityHigh)}>
             {priorityHigh && (
                <PriorityHighIcon
                 titleAccess={intl.formatMessage({ id: 'titleAccess.Stream.PriorityHighIcon' })}
                 color="primary"
               />
             )}
             {!priorityHigh && (
               <PriorityHighIcon
                 titleAccess={intl.formatMessage({
                   id: 'titleAccess.Stream.PriorityHighIcon.disabled'
                 })}
               />
             )}
           </IconButton> */}
        </>
      }
      contentExtended={
        openAgenda ? (
          <>
            {planningOrder.length > 0 && <AgendaExtended />}
            {planningOrder.length === 0 && <FormattedMessage id="agenda.empty" />}
          </>
        ) : (
          <StreamsPanelExtended
            priorityHigh={priorityHigh}
            setPriorityHigh={setPriorityHigh}
            onlyAppointment={onlyAppointment}
            setOnlyAppointment={setOnlyAppointment}
            streamType={streamType}
            setStreamType={setStreamType}
          />
        )
      }
      onExtend={(extended: boolean) => {
        if (extended && openAgenda) {
          dispatch(loadAgenda())
        }
        if (!extended) {
          // reset stream type on close fullscreen
          setStreamType('all')
        }
      }}
    >
      <StreamsPanelContent
        priorityHigh={priorityHigh}
        onlyAppointment={onlyAppointment}
        streamType={streamType}
      />
      <AvailabilitiesDialog />
      <SynchronizeCalendarDialog />
      <ConfirmDialog
        open={confirmUnsync}
        message={intl.formatMessage({ id: 'confirm.unsyncCalndar' })}
        okEvent={handleUnsync}
        cancelEvent={() => {
          setConfirmUnsync(false)
        }}
      />
    </ExtensiblePanel>
  )
}

export default StreamsPanel
