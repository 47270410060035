import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IRootState } from '../redux'

const useIsProfilingEnabled = () => {
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const [isProfilingEnabled, setIsProfilingEnabled] = useState(false)

  useEffect(() => {
    setIsProfilingEnabled(!!eventCo?.type_x_id?.id || !!eventCo?.type_y_id?.id)
  }, [eventCo])
  return isProfilingEnabled
}

export default useIsProfilingEnabled
