import styled from '@emotion/styled'
import React from 'react'
import SelectSortBy from '../globals/SelectSortBy/SelectSortBy'

interface IExhibitorFiltersTri {
  setSortBy: (value: string) => void
  sortBy: string
  visible: boolean
}

const ExhibitorFiltersTri: React.FC<IExhibitorFiltersTri> = ({ setSortBy, sortBy, visible }) => {
  return (
    <StyledFilters>
      {visible && (
        <SelectSortBy value={sortBy} onChange={setSortBy} recommandation alphabetic popularity />
      )}
    </StyledFilters>
  )
}

const StyledFilters = styled.div`
  & {
    padding: 1em;
  }
`

export default ExhibitorFiltersTri
