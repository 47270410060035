import { IEnterRoom, IRoom, IRoomOccupation, IRoomQueue, RoomType } from '../entities'
import settings from '../settings'
import { apiPatchRequest, apiRequest } from './requests'

export const enterRoom = async (roomId: number) => {
  const response = await apiRequest<IEnterRoom>(
    'GET',
    `${settings.url.api}/e_events/room/enter/${roomId}`
  )
  return response
}

export const enterRoomBroadcaster = async (roomId: number) => {
  const response = await apiRequest<IEnterRoom>(
    'GET',
    `${settings.url.api}/e_events/room/broadcast/${roomId}`
  )
  return response
}

export const enterRoomExternal = async (roomId: number) => {
  const response = await apiRequest<IEnterRoom>(
    'GET',
    `${settings.url.api}/e_events/room/enter/${roomId}`
  )
  return response
}

export const getRoomsOccupationExhibitor = async (exhibitorGroupId: number) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/e_events/rooms/?group=${exhibitorGroupId}&limit=${settings.list.locations}`
  )
  return response['hydra:member'] as IRoomOccupation[]
}

export const getRoomsOccupationConference = async (eventGroupId: number) => {
  const response = await apiRequest<any>(
    'GET',
    `${settings.url.api}/e_events/rooms/?group=${eventGroupId}&limit=${settings.list.locations}`
  )
  return response['hydra:member'] as IRoomOccupation[]
}

export const lockUnlockRoom = async (roomId: number, locked: boolean) => {
  const response = await apiPatchRequest(`${settings.url.api}/e_events/rooms/${roomId}`, {
    locked
  })
  return response
}

export const renameRoom = async (roomId: number, title: string) => {
  const response = await apiPatchRequest<IRoom>(`${settings.url.api}/e_events/rooms/${roomId}`, {
    title
  })
  return response
}

export const updateRoom = async (
  roomId: number,
  title: string,
  type: RoomType,
  sessionId?: string,
  userId?: number,
  color?: string
) => {
  const response = await apiPatchRequest<IRoom>(
    `${settings.url.api}/e_events/rooms/edit/${roomId}`,
    {
      title,
      type,
      sessionId,
      user: userId ? `/users/${userId}` : null,
      color: color || null
    }
  )
  return response
}

export const getRoomQueue = async (roomId: number) => {
  const response = await apiRequest<IRoomQueue>(
    'GET',
    `${settings.url.api}/e_events/rooms/${roomId}/queue`
  )
  return response
}

export const deleteRoomQueue = async (roomQueueId: string) => {
  const response = await apiRequest<boolean>(
    'DELETE',
    `${settings.url.api}/e_events/queueitem/${roomQueueId}`
  )
  return response
}


export const canAccessRoom = async (roomId: number) => {
  const response = await apiRequest<boolean>(
    'GET',
    `${settings.url.api}/e_events/room/check_access/${roomId}`
  )
  return response
}
