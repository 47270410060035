import { FormControl } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useDateFns, useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { setOpenAvailabilities } from '../../store/app'
import { setErrorSending, setSended, takeAppointment } from '../../store/chat'
import { formatName } from '../../utils'
import SendDialog from '../modals/SendDialog'
import DateTimePicker from '../ui/DateTimePicker/DateTimePicker'
import FormContainer from '../ui/Form/FormContainer'

const AvailabilitiesDialog: React.FC = () => {
  const intl = useIntl()
  const dateFns = useDateFns()
  const magicStream = useMagicStream()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const openAvailabilities = useSelector((state: IRootState) => state.appState.openAvailabilities)
  const user = useSelector((state: IRootState) => state.appState.user)
  const sended = useSelector((state: IRootState) => state.chatState.sended)
  const sending = useSelector((state: IRootState) => state.chatState.sending)
  const errorSending = useSelector((state: IRootState) => state.chatState.errorSending)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const dispatch = useThunkDispatch()

  const initDate = useMemo(
    () =>
      eventCo?.startDate &&
        new Date().setHours(0, 0, 0, 0) <= new Date(eventCo?.startDate).setHours(0, 0, 0, 0)
        ? new Date(eventCo?.startDate)
        : new Date(),
    [eventCo]
  )
  const changeAvailabilities = () => {
    if (user && startDate && endDate) {
      const chatMessage = intl.formatMessage(
        { id: 'rdv.availabilities' },
        {
          user: `<b>${formatName(user).full}</b>`,
          message: `<i>Indisponibilités</i>`,
          date: dateFns.format(startDate.toString(), intl.formatMessage({ id: 'date.format' }))
        }
      )
      dispatch(takeAppointment([user.id], chatMessage, startDate, undefined, undefined, endDate))
    }
  }

  useEffect(() => {
    if (initDate) {
      setStartDate(initDate)
      setEndDate(initDate)
    }
  }, [initDate])

  useEffect(() => {
    if (sended) {
      setStartDate(initDate)
      setEndDate(initDate)
      dispatch(setSended(false))
      dispatch(setOpenAvailabilities(false))
    }
  }, [intl, sended, initDate, dispatch])

  useEffect(() => {
    if (errorSending) {
      magicStream.error(errorSending)
      dispatch(setErrorSending(undefined))
    }
  }, [errorSending, magicStream, dispatch])

  return (
    <SendDialog
      title={intl.formatMessage({ id: 'button.availabilities' })}
      open={openAvailabilities}
      disableSend={sending}
      sending={sending}
      sendEvent={() => changeAvailabilities()}
      cancelEvent={() => dispatch(setOpenAvailabilities(false))}
      maxWidth="sm"
      fullWidth
    >
      <FormContainer>
        <FormControl>
          <DateTimePicker
            id="date-picker-availabilities-start"
            label={intl.formatMessage({ id: 'rdv.date.start.title' })}
            format={intl.formatMessage({ id: 'date.format' })}
            fullWidth
            minDate={eventCo?.startDate}
            minDateMessage="La date ne peut pas être inférieur à aujourd'hui"
            showTodayButton
            disabled={sending}
            value={startDate}
            onChange={(d: MaterialUiPickersDate) => {
              if (d) {
                setStartDate(d)
                if (endDate && d.getTime() > endDate.getTime()) {
                  setEndDate(d)
                }
              }
            }}
          />
        </FormControl>
        <FormControl>
          <DateTimePicker
            id="date-picker-appointment"
            label={intl.formatMessage({ id: 'rdv.date.end.title' })}
            format={intl.formatMessage({ id: 'date.format' })}
            fullWidth
            minDate={startDate}
            minDateMessage="La date ne peut pas être inférieur à aujourd'hui"
            showTodayButton
            disabled={sending}
            value={endDate}
            onChange={(d: MaterialUiPickersDate) => {
              if (d) setEndDate(d)
            }}
          />
        </FormControl>
      </FormContainer>
    </SendDialog>
  )
}

export default AvailabilitiesDialog
