import React from 'react'

const SvgMinus: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M23.004 23.004H9.282a2.496 2.496 0 1 0 0 4.992h32.436a2.496 2.496 0 1 0 0-4.992H23.004z"
    />
  </svg>
)

export default SvgMinus
