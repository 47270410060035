import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import { FullScreenHandle } from 'react-full-screen'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import { useSelector } from 'react-redux'
import { useIsMobile } from '../../../hooks'
import { IRootState } from '../../../redux'
import { WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import { breakpointsValues } from '../../breakpoints'
import LoadingCard from '../../LoadingCard'
import AppPlayerActions from './AppPlayerActions'

export enum PlayerMode {
  Default = 'default',
  Custom = 'custom'
}

interface IAppPlayerProps extends ReactPlayerProps {
  wrapperRef?: React.RefObject<HTMLDivElement>
  hidden?: boolean
  mode?: PlayerMode
  customControls?: boolean
  withRightSide?: boolean
  handleFullScreen?: FullScreenHandle
  setVideoVolume?: (volume: number) => void
  setVideoPlaying?: (playing: boolean) => void
}

const AppPlayer: React.FC<IAppPlayerProps> = ({
  wrapperRef,
  hidden,
  mode,
  customControls,
  withRightSide,
  handleFullScreen,
  volume,
  setVideoVolume,
  playing,
  setVideoPlaying,
  ...props
}) => {
  const isMobile = useIsMobile(breakpointsValues.lg)
  const playerRef = useRef<ReactPlayer>(null)
  const [isReady, setIsReady] = useState(false)
  const [seeking, setSeeking] = useState(false)
  const [played, setPlayed] = useState(0)
  const fullscreen = useSelector((state: IRootState) => state.standState.fullscreen)

  const onReady = () => setIsReady(true)

  const onPlay = () => {
    setIsReady(true)
    setVideoPlaying && setVideoPlaying(true)
  }

  const onPause = () => setVideoPlaying && setVideoPlaying(false)

  const onProgress = (state: any) => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played)
    }
  }

  useEffect(() => {
    const autoReady = window.setTimeout(() => {
      setIsReady(true)
    }, 3000)
    return () => {
      if (autoReady) {
        window.clearTimeout(autoReady)
      }
    }
  }, [])

  return (
    <>
      <StyledReactPlayerWrapper
        ref={wrapperRef}
        className="app-player"
        isReady={isReady}
        isMobile={isMobile}
        fullscreen={fullscreen}
        withRightSide={withRightSide}
        customControls={customControls}
      >
        {!isReady && <LoadingCard cardType="VideoPlayer" />}
        <StyledReactPlayer
          ref={playerRef}
          width={isReady ? (hidden ? 0 : getWidth(mode || PlayerMode.Default)) : 0}
          height={isReady ? (hidden ? 0 : getHeight(mode || PlayerMode.Default)) : 0}
          playing={playing}
          volume={volume}
          onReady={onReady}
          onError={onReady}
          onPlay={onPlay}
          onPause={onPause}
          onProgress={onProgress}
          {...props}
          config={
            customControls
              ? props.config 
                ? props.config
                : {
                    vimeo: {
                      playerOptions: {
                        controls: false
                      }
                    }
                  }
              : undefined
          }
        />
      </StyledReactPlayerWrapper>
      {isReady && !hidden && customControls && (
        <AppPlayerActions
          playerRef={playerRef}
          setSeeking={setSeeking}
          played={played}
          setPlayed={setPlayed}
          volume={volume}
          setVolume={setVideoVolume}
          playing={playing}
          setPlaying={setVideoPlaying}
          handleFullscreen={handleFullScreen}
          withRightSide={withRightSide}
        />
      )}
    </>
  )
}

const getWidth = (mode: PlayerMode) => {
  switch (mode) {
    default:
    case PlayerMode.Custom:
      return 'auto'
    case PlayerMode.Default:
      return '100%'
  }
}

const getHeight = (mode: PlayerMode) => {
  switch (mode) {
    default:
    case PlayerMode.Custom:
      return '250px'
    case PlayerMode.Default:
      return '100%'
  }
}

const StyledReactPlayerWrapper = styled.div<{
  isReady: boolean
  isMobile: boolean
  fullscreen: boolean
  withRightSide?: boolean
  customControls?: boolean
}>`
  & {
    position: relative;
    border-top-left-radius: ${BORDER_RADIUS_CARD};
    border-bottom-left-radius: ${BORDER_RADIUS_CARD};
    border-top-right-radius: ${(props) => (props.withRightSide ? 0 : BORDER_RADIUS_CARD)};
    border-bottom-right-radius: ${(props) => (props.withRightSide ? 0 : BORDER_RADIUS_CARD)};
    overflow: hidden;
    padding-top: ${(props) =>
      props.fullscreen
        ? props.isMobile
          ? 'calc(100vh - 50px - 350px)'
          : 'calc(100vh - 50px)'
        : '56.25%'};
    ${(props) =>
      props.isReady
        ? css`
            margin-top: -20px;
            margin-bottom: -40px;
          `
        : css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & svg {
              color: ${WHITE};
            }
          `};
    & > div:first-of-type {
      height: ${(props) => (props.fullscreen ? 'calc(100% - 30px) !important' : '100%')};
    }

    iframe {
      border-top-left-radius: ${BORDER_RADIUS_CARD};
      border-bottom-left-radius: ${(props) => (props.customControls ? 0 : BORDER_RADIUS_CARD)};
      border-top-right-radius: ${(props) => (props.withRightSide ? 0 : BORDER_RADIUS_CARD)};
      border-bottom-right-radius: ${(props) =>
        props.customControls || props.withRightSide ? 0 : BORDER_RADIUS_CARD};
    }
  }
`

const StyledReactPlayer = styled(ReactPlayer)`
  & {
    position: absolute;
    top: 0;
    left: 0;
  }
`
export default AppPlayer
