import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IAuthState } from './model'

const initialState: IAuthState = {
  token: '',
  loading: false
}

const actionHandlers: IReducerHandler<IAuthState> = {
  [ActionsTypes.SET_TOKEN]: (state: IAuthState | undefined, { auth }: AnyAction): IAuthState => ({
    ...state!,
    token: auth.token
  }),
  [ActionsTypes.RESET_TOKEN]: (state: IAuthState | undefined): IAuthState => ({
    ...state!,
    token: null
  }),
  [ActionsTypes.SET_AUTH_LOADING]: (
    state: IAuthState | undefined,
    { loading }: AnyAction
  ): IAuthState => ({
    ...state!,
    loading
  }),
  [ActionsTypes.SET_AUTH_ERROR]: (
    state: IAuthState | undefined,
    { error }: AnyAction
  ): IAuthState => ({
    ...state!,
    error
  })
}

export const reducer = createReducer(initialState, actionHandlers)
