import { IEnterRoomJitsi } from '../../entities'
import { ActionsTypes } from './model'

export const setJitsiLoading = (loadingJitsi: boolean) => ({
  type: ActionsTypes.SET_LOADING_JITSI,
  loadingJitsi
})

export const setJitsiRoom = (jitsiRoom?: IEnterRoomJitsi) => ({
  type: ActionsTypes.SET_JITSI_ROOM,
  jitsiRoom
})

export const setJitsiError = (errorJitsi?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_JITSI,
  errorJitsi
})
