import styled from '@emotion/styled'
import { Badge } from '@material-ui/core'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { LanguageKeys } from '../../../entities'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { breakpoints } from '../../breakpoints'
import BadgeOnline from '../../ui/BadgeOnline/BadgeOnline'

const HeaderLogo: FC = () => {
  const intl = useIntl()
  const presenceUsersCount = useSelector(
    (state: IRootState) => state.participantState.presenceUsersCount
  )
  const locale = useSelector((state: IRootState) => state.appState.locale)
  const [currentCount, setCurrentCount] = useState(presenceUsersCount)
  const [prevCount, setPrevCount] = useState(0)
  const [isNewTimeFirstTick, setIsNewTimeFirstTick] = useState(false)

  const localizedImage = useMemo(() => {
    let image = settings.theme.header.image
    if (locale === LanguageKeys.en && settings.theme.header.image_en) {
      image = settings.theme.header.image_en
    }
    return image
  }, [locale])

  useEffect(() => {
    if (presenceUsersCount !== currentCount) {
      setIsNewTimeFirstTick(true)
      process.nextTick(() => {
        setIsNewTimeFirstTick(false)
      })
      setPrevCount(currentCount)
      setCurrentCount(presenceUsersCount)
    }
  }, [presenceUsersCount, currentCount])

  return (
    <StyledLinkLogo to="/" title={settings.theme.header.title}>
      <Badge
        color="primary"
        badgeContent={
          presenceUsersCount > 0 ? (
            <StyledBadge width={`${presenceUsersCount}`.length}>
              <TimeWrapper width={`${presenceUsersCount}`.length}>
                <span
                  key={`current-${presenceUsersCount}`}
                  className={`time ${isNewTimeFirstTick ? 'up' : ''}`}
                >
                  {presenceUsersCount}
                </span>
                <span
                  key={`previous-${prevCount}`}
                  className={`time ${!isNewTimeFirstTick ? 'down' : ''}`}
                >
                  {prevCount}
                </span>
              </TimeWrapper>
              <StyledMessage>
                <BadgeOnline
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  variant="dot"
                >
                  {intl.formatMessage(
                    { id: 'total.visitor.animated' },
                    { visitors: presenceUsersCount }
                  )}
                </BadgeOnline>
              </StyledMessage>
            </StyledBadge>
          ) : undefined
        }
      >
        <StyledLogo src={localizedImage} alt={settings.theme.header.title} />
      </Badge>
    </StyledLinkLogo>
  )
}

const StyledLinkLogo = styled(Link)`
  & {
    display: block;
    padding: 5px;

    > .MuiBadge-root .MuiBadge-badge {
      padding-right: 20px;
    }
  }
`

const StyledLogo = styled.img`
  & {
    display: block;
    height: 50px;
    object-fit: contain;
    max-width: 115px;

    @media (min-width: ${breakpoints.sm}) {
      max-width: 200px;
    }
    @media (min-width: ${breakpoints.md}) {
      max-width: 330px;
    }
    @media (min-width: ${breakpoints.lg}) {
      max-width: 330px;
    }
  }
`

const StyledBadge = styled.div<{ width: number }>`
  text-align: center;
  ${(props) => (props.width ? `width: ${5 * props.width + 80}px;` : 'width: auto;')}
`

const TimeWrapper = styled.span<{ width: number }>`
  position: relative;
  ${(props) => (props.width ? `padding-right: ${5 * props.width + 10}px;` : 'padding-right: 10px;')}
  height: 15px;

  & .time {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.5s;
  }

  & .time.up {
    opacity: 0;
    transform: translateY(-100%);
  }

  & .time.down {
    opacity: 0;
    transform: translateY(100%);
  }
`

const StyledMessage = styled.span`
  & {
    white-space: nowrap;
    padding-left: 3px;

    .MuiBadge-badge {
      top: 50%;
      right: -10px;
      padding-right: 0 !important;
    }
  }
`

export default HeaderLogo
