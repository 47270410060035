import { IExhibitor, IExhibitorDemo, IExhibitorFull } from '../../entities/exhibitor'
import { IFilters } from '../../entities/filters'

export enum ActionsTypes {
  SET_EXHIBITORS_TOTAL = 'SET_EXHIBITORS_TOTAL',
  SET_EXHIBITOR_SEARCH = 'SET_EXHIBITOR_SEARCH',
  SET_EXHIBITOR_UPDATING = 'SET_EXHIBITOR_UPDATING',
  SET_EXHIBITOR_ERROR = 'SET_EXHIBITOR_ERROR',
  SET_EXHIBITOR_LOADING = 'SET_EXHIBITOR_LOADING',
  SET_EXHIBITORS = 'SET_EXHIBITORS',
  SET_EXHIBITORS_ERROR = 'SET_EXHIBITORS_ERROR',
  SET_EXHIBITORS_LOADING = 'SET_EXHIBITORS_LOADING',
  SET_EXHIBITOR_EDIT_MODE = 'SET_EXHIBITOR_EDIT_MODE',
  SET_SCORED_EXHIBITOR_ERROR = 'SET_SCORED_EXHIBITOR_ERROR',
  SET_SCORED_EXHIBITOR_LOADING = 'SET_SCORED_EXHIBITOR_LOADING',
  SET_SCORED_EXHIBITORS = 'SET_SCORED_EXHIBITORS',
  SET_RECOMMENDED_EXHIBITOR_ERROR = 'SET_RECOMMENDED_EXHIBITOR_ERROR',
  SET_RECOMMENDED_EXHIBITOR_LOADING = 'SET_RECOMMENDED_EXHIBITOR_LOADING',
  SET_RECOMMENDED_EXHIBITORS = 'SET_RECOMMENDED_EXHIBITORS',
  SET_PREMIUM_EXHIBITOR_ERROR = 'SET_PREMIUM_EXHIBITOR_ERROR',
  SET_PREMIUM_EXHIBITOR_LOADING = 'SET_PREMIUM_EXHIBITOR_LOADING',
  SET_PREMIUM_EXHIBITORS = 'SET_PREMIUM_EXHIBITORS',
  RESET_EXHIBITOR = 'RESET_EXHIBITOR',
  SET_EXHIBITOR = 'SET_EXHIBITOR',
  MERGE_EXHIBITOR = 'MERGE_EXHIBITOR',
  MERGE_EXHIBITOR_ROOM = 'MERGE_EXHIBITOR_ROOM',
  SET_EXHIBITOR_FILTERS = 'SET_EXHIBITOR_FILTERS',
  SET_ERROR_EXHIBITOR_FILTERS = 'SET_ERROR_EXHIBITOR_FILTERS',
  ADD_PRODUCT = 'ADD_PRODUCT ',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT ',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT ',
  SET_LOADING_EXHIBITOR_FILTERS = 'SET_LOADING_EXHIBITOR_FILTERS',
  UPDATE_EXHIBITORS_FAVORITE = 'UPDATE_EXHIBITORS_FAVORITE',
  SET_ADDING_EXHIBITOR_TO_FAVORITES = 'SET_ADDING_EXHIBITOR_TO_FAVORITES',
  SET_ERROR_ADDING_EXHIBITOR_TO_FAVORITES = 'SET_ERROR_ADDING_EXHIBITOR_TO_FAVORITES',
  SET_REMOVING_EXHIBITOR_FROM_FAVORITES = 'SET_REMOVING_EXHIBITOR_FROM_FAVORITES',
  SET_ERROR_REMOVING_EXHIBITOR_FROM_FAVORITES = 'SET_ERROR_REMOVING_EXHIBITOR_FROM_FAVORITES',
  SET_SHOWROOMS_LOADING = 'SET_SHOWROOMS_LOADING',
  SET_SHOWROOMS = 'SET_SHOWROOMS',
  SET_SHOWROOMS_ERROR = 'SET_SHOWROOMS_ERROR',
  UPDATE_EXHIBITOR_ROOM_USERS_ONLINE = 'UPDATE_EXHIBITOR_ROOM_USERS_ONLINE'
}

export interface IExhibitorState {
  error?: string
  loading: boolean
  totalExhibitors: number
  searchExhibitors?: string
  exhibitors: { total: number; items: IExhibitor[] }
  errorExhibitors?: string
  loadingExhibitors: boolean
  editMode: boolean
  errorScored?: string
  scoredExhibitors: IExhibitor[]
  loadingScored: boolean
  errorRecommended?: string
  recommendedExhibitors: IExhibitor[]
  loadingRecommended: boolean
  errorPremium?: string
  premiumExhibitors: IExhibitor[]
  loadingPremium: boolean
  exhibitor: IExhibitorFull | null
  updating: boolean
  filters: IFilters
  loadingFilters: boolean
  errorFilters?: string
  addingExhibitorToFavorites: { [key: number]: boolean }
  errorAddingExhibitorToFavorites?: string
  removingExhibitorFromFavorites: { [key: number]: boolean }
  errorRemovingExhibitorFromFavorites?: string
  showrooms: IExhibitorDemo[]
  loadingShowrooms: boolean
  errorShowrooms?: string
}

export interface IExhibitorFilters {
  search?: string
  page?: number
  sortBy?: string
  keyword?: string
  location?: string
  followed?: boolean
  matching?: number
  keywordsX?: string
}
