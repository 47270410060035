import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ISlot } from '../../entities/agenda'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { loadAgendaSlots, setAgendaSlotsError } from '../../store/app'
import Loading from '../Loading'
import { Label } from '../ui/LabelWrapper/LabelWrapper'

interface SelectSlotProps {
  userId: number
  slotDate?: Date
  onValueChange?: (value: string) => void
}

const SelectSlot: React.FC<SelectSlotProps> = ({ userId, slotDate, onValueChange }) => {
  // const dateFns = useDateFns()
  const magicStream = useMagicStream()
  const [slot, setSlot] = useState('')
  const [showAll, setShowAll] = useState(false)
  const [agendaSlotsFormatted, setAgendaSlotsFormatted] = useState<ISlot[]>([])
  const agendaSlots = useSelector((state: IRootState) => state.appState.agendaSlots)
  const loadingSlots = useSelector((state: IRootState) => state.appState.loadingAgendaSlots)
  const errorSlots = useSelector((state: IRootState) => state.appState.errorAgendaSlots)
  const dispatch = useThunkDispatch()

  // const day = useMemo(
  //   () =>
  //     agendaSlots &&
  //     agendaSlots.length > 0 &&
  //     dateFns.format(agendaSlots[0].dateStart, 'eee d LLL'),
  //   [agendaSlots, dateFns]
  // )

  const formatHoursMinutes = (dateS: string) => {
    const date = new Date(dateS)
    const minutes = date.getMinutes()
    return `${date.getHours()}h${minutes < 10 ? `0${minutes}` : minutes}`
  }

  const slotChange = (value: string) => {
    setSlot(value)
    if (onValueChange) onValueChange(value)
  }

  useEffect(() => {
    if (userId) {
      dispatch(loadAgendaSlots(userId, slotDate))
    }
  }, [userId, slotDate, dispatch])

  useEffect(() => {
    if (agendaSlots) {
      setAgendaSlotsFormatted(
        agendaSlots.calendarSlots.filter(agendaSlot => {
          return (new Date(agendaSlot.dateStart)).getTime() > (new Date()).getTime()
        }).map((agendaSlot) => ({
          dateStart: formatHoursMinutes(agendaSlot.dateStart),
          dateEnd: formatHoursMinutes(agendaSlot.dateEnd)
        }))
      )
    }
  }, [agendaSlots])

  useEffect(() => {
    if (errorSlots) {
      magicStream.error(errorSlots)
      dispatch(setAgendaSlotsError(undefined))
    }
  }, [errorSlots, magicStream, dispatch])

  if (loadingSlots) return <StyledLoadingContainer><Loading /></StyledLoadingContainer>

  return (
    <>
      <StyledLabel>
        <FormattedMessage id="slots.picker" />
      </StyledLabel>
      <StyledSelectSlot>
        {/* {day && <StyledDay>{day}</StyledDay>} */}
        {agendaSlotsFormatted.length === 0 && <FormattedMessage id="slots.empty" />}
        <StyledSlotButtons>
          {agendaSlotsFormatted.slice(0, 9).map((agendaSlot) => (
            <Button
              key={agendaSlot.dateStart}
              color={slot === agendaSlot.dateStart ? 'secondary' : 'primary'}
              onClick={() => slotChange(agendaSlot.dateStart)}
            >
              {agendaSlot.dateStart}
            </Button>
          ))}
        </StyledSlotButtons>
        {agendaSlotsFormatted.length > 9 && !showAll && (
          <Button onClick={() => setShowAll(true)}>
            <FormattedMessage id="slots.view.more" />
          </Button>
        )}
        {showAll && (
          <StyledSlotButtons>
            {agendaSlotsFormatted.slice(9).map((agendaSlot) => (
              <Button
                key={agendaSlot.dateStart}
                color={slot === agendaSlot.dateStart ? 'secondary' : 'primary'}
                onClick={() => slotChange(agendaSlot.dateStart)}
              >
                {agendaSlot.dateStart}
              </Button>
            ))}
          </StyledSlotButtons>
        )}
      </StyledSelectSlot>
    </>
  )
}

const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  height: 50px;
`

const StyledLabel = styled(Label)`
  text-align: center;
`

const StyledSelectSlot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSlotButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 250px;
`

// const StyledDay = styled.div`
//   margin-bottom: 10px;
// `

export default SelectSlot
