import jwtDecode from 'jwt-decode'
import Pusher from 'pusher-js'
import { AnyAction, Dispatch } from 'redux'
import { getToken } from '../../api'
import { IToken } from '../../entities'
import IStorageAccess from '../../entities/storageAccess'
import settings from '../../settings'
import consoleUtils from '../../utils/consoleUtils'
import pusherUtils from '../../utils/pusherUtils'
import { resetToken, setLoading, setToken } from './actions'
import { TOKEN_KEY } from './model'

export const simulateLogin =
  (storage: IStorageAccess, onSuccess?: (userId: string) => void) =>
  async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setLoading(true))
    try {
      let auth = null
      const storeAuth = await storage.getItem(TOKEN_KEY)
      if (storeAuth && storeAuth !== '') {
        auth = JSON.parse(storeAuth)
        dispatch(setToken(auth))
      } else {
        // get token
        auth = await getToken()
        await storage.setItem(TOKEN_KEY, JSON.stringify(auth))
        dispatch(setToken(auth))
      }

      const userId = jwtDecode<IToken>(auth.token).userId

      // connect to pusher
      const pusher = new Pusher(settings.pusher.id, {
        cluster: 'eu',
        authEndpoint: `${settings.url.api}/pusher/auth`,
        auth: {
          headers: {
            Authorization: 'Bearer ' + auth.token
          }
        }
      })
      pusher.connection.bind('error', (err: any) => {
        consoleUtils.error('[Pusher connection error] err=', err)
        if (err && err.error && err.error.data && err.error.data.code === 4004) {
          consoleUtils.error('OVER LIMIT of number of member')
        }
      })
      // subscribe to business event
      window.pusher = pusher
      pusherUtils.subscribe(`group-${settings.eventGroupId}`)
      pusherUtils.subscribe(`private-group-${settings.eventGroupId}-user-${userId}`)

      if (onSuccess) onSuccess(userId)
    } catch (e) {
      dispatch(setLoading(false))
      throw e
    }
    dispatch(setLoading(false))
  }

export const logout = (storage: IStorageAccess) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(resetToken())
  await storage.removeItem(TOKEN_KEY)
  consoleUtils.log(`Redirect to "${settings.url.logout_page}"`)
  window.location.href = settings.url.logout_page
}

export const readTokenFromStorage =
  (storage: IStorageAccess) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const storeAuth = await storage.getItem(TOKEN_KEY)
      if (storeAuth && storeAuth !== '') {
        const authToken = JSON.parse(storeAuth)
        dispatch(setToken(authToken))
      }
      return storeAuth
    } catch (e) {
      consoleUtils.error(e)
    }
  }
