import React from 'react'

const SvgUnlock: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill, stroke, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
      <path d="M24.332 0a8.754 8.754 0 0 0-8.754 8.753v4.789h-1.986A6.592 6.592 0 0 0 7 20.133v23.275a6.594 6.594 0 0 0 6.592 6.593h23.274a6.593 6.593 0 0 0 6.592-6.593V20.133a6.598 6.598 0 0 0-6.592-6.591H17.96V8.753a6.371 6.371 0 0 1 6.372-6.371h1.795a6.382 6.382 0 0 1 6.292 5.356 1.19 1.19 0 0 0 1.172 1.015c.716 0 1.296-.63 1.187-1.339C34.131 3.221 30.498 0 26.126 0h-1.794zM9.381 43.408V20.133a4.217 4.217 0 0 1 4.212-4.211h23.273a4.217 4.217 0 0 1 4.212 4.211v23.275a4.217 4.217 0 0 1-4.212 4.211H13.593a4.217 4.217 0 0 1-4.212-4.211zm11.173-12.363a4.68 4.68 0 0 0 3.485 4.516v4.064h2.382v-4.064a4.678 4.678 0 0 0 3.484-4.516 4.681 4.681 0 0 0-4.675-4.675 4.68 4.68 0 0 0-4.676 4.675zm2.382 0a2.296 2.296 0 0 1 2.294-2.294 2.296 2.296 0 0 1 2.293 2.294 2.297 2.297 0 0 1-2.293 2.295 2.297 2.297 0 0 1-2.294-2.295z" />
      <use fill={fill || 'currentColor'} fillRule="evenodd" />
    </svg>
  )
}

export default SvgUnlock
