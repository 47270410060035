import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Grid } from '@material-ui/core'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { addUserLog as addUserLogApi } from '../../api'
import { breakpoints } from '../../components/breakpoints'
import ConferenceInfos from '../../components/conference/ConferenceInfos'
import VideoChat from '../../components/globals/Room/VideoChat'
import VideoStand from '../../components/globals/Room/VideoStand'
import { ConferenceStatus, IRoom, LogUserActions, LogUserUrlTypes } from '../../entities'
import { useScrollEvent, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import settings from '../../settings'
import { setLocation } from '../../store/app'
import { renameConferenceRoom, setOnOfficeRoom } from '../../store/room'
import { PRIMARY, SECONDARY, WHITE } from '../../theme/colors'
import { LEFT_MENU_WIDTH } from '../../theme/sizes'
import EditableStand from '../globals/Room/EditableStand'
import ConferencePlayer from './ConferencePlayer'

interface IConferenceContentProps {
  conferenceId: string | null
  localGroupId: number
  videoPlaying: boolean
  setVideoPlaying: (val: boolean) => void
  lastVideoPlaying: boolean
  setLastVideoPlaying: (val: boolean) => void
  status: string
  updateConferenceStatus: (confStatus: string, notify: boolean) => void
}

const ConferenceContent: React.FC<IConferenceContentProps> = ({
  conferenceId,
  localGroupId,
  videoPlaying,
  setVideoPlaying,
  lastVideoPlaying,
  setLastVideoPlaying,
  status,
  updateConferenceStatus
}) => {
  const intl = useIntl()
  const location = useLocation()
  const [trigger, setTrigger] = useState(false)
  const [videoMuted, setVideoMuted] = useState(false)
  const [videoVolume, setVideoVolume] = useState(1)
  const [collapse, setCollapse] = useState(false)
  const user = useSelector((state: IRootState) => state.appState.user)
  const conference = useSelector((state: IRootState) => state.conferenceState.conference)
  const editMode = useSelector((state: IRootState) => state.conferenceState.editMode)
  const roomNumber = useSelector((state: IRootState) => state.roomState.roomNumber)
  const fullscreenSubscriber = useSelector(
    (state: IRootState) => state.roomState.fullscreenSubscriber
  )
  const dispatch = useThunkDispatch()
  const scrollEvent = useCallback(() => {
    setTrigger(window.scrollY > 350)
  }, [])
  useScrollEvent(scrollEvent)

  const refAppPlayer = React.createRef<HTMLDivElement>()

  const onAlternativeLinkClick = () => {
    window.open(conference?.videoAlternativeLink, '_blank')
  }

  const activeVideoAudio = useCallback(
    (activeAudio: boolean) => {
      setVideoMuted(!activeAudio)
      setVideoVolume(activeAudio ? 1 : 0)
      setVideoPlaying(activeAudio ? lastVideoPlaying : false)
      if (!activeAudio) {
        setLastVideoPlaying(videoPlaying)
      }
    },
    [videoPlaying, lastVideoPlaying, setVideoPlaying, setLastVideoPlaying]
  )

  const canEditSvg = useMemo(
    () =>
      settings.video.svgEditable &&
      editMode &&
      conference &&
      user &&
      user.roles &&
      (user.roles.indexOf(`edit_group_${conference.group.id}`) > -1 ||
        user.roles.indexOf(`edit_group_svg_${conference.group.id}`) > -1 ||
        user.roles.indexOf('edit_group_svg_*') > -1),
    [editMode, user, conference]
  )

  const liveInProgress = useMemo(
    () =>
      !!conference &&
      !!conference.eventCoUrlVimeo &&
      conference.status === ConferenceStatus.IN_PROGRESS,
    [conference]
  )

  const vodActive = useMemo(
    () =>
      !!conference &&
      !!conference.eventCoUrlVimeo &&
      conference.status === ConferenceStatus.ARCHIVE,
    [conference]
  )

  const onEnterRoom = useCallback(
    (num: number) => {
      if (conference) {
        addUserLogApi({
          action: LogUserActions.enter_table,
          description: `${intl.formatMessage({ id: 'pathname.description.conference' })} ${conference.title
            }, ${intl.formatMessage({ id: 'pathname.description.conference.room' })} '${conference.rooms[num].title || conference.rooms[num].roomClassName
            }'`,
          url: `/conference/${conference.id}?salon=${num}`,
          urlType: LogUserUrlTypes.conference
        })
        if (
          user &&
          conference.rooms[num].userPresence &&
          conference.rooms[num].userPresence!.user.id === user.id
        ) {
          // enter at my office
          dispatch(setOnOfficeRoom(true))
        }
        dispatch(setLocation(`/conference/${conference.id}?salon=${num}`))
      }
    },
    [intl, user, conference, dispatch]
  )

  const onLeaveRoom = useCallback(
    (num: number) => {
      if (conference) {
        addUserLogApi({
          action: LogUserActions.view,
          description: `${intl.formatMessage({ id: 'pathname.description.conference' })} ${conference.name
            }`,
          url: `/conference/${conference.id}`,
          urlType: LogUserUrlTypes.conference
        })
        dispatch(setLocation(`/conference/${conference.id}`))
        browserHistory.replace(
          `/conference/${conference.id}${location.search.indexOf('magicstream=true') > -1 ? '?magicstream=true' : ''
          }${location.search.indexOf('appointment=true') > -1 ? '&appointment=true' : ''}`
        )
      }
    },
    [intl, conference, location, dispatch]
  )

  const handleRenameRoom = async (room: IRoom, value: string) => {
    if (conference) {
      dispatch(renameConferenceRoom(room, value, conference))
    }
  }

  if (!conference) return <></>

  return (
    <StyledConferenceContent isCollapsed={collapse}>
      <Grid container>
        <Grid item xs={12}>
          <StyledAppPlayer
            ref={refAppPlayer}
            withVideo={liveInProgress || vodActive || !!conference.vodUrl}
            isHidden={roomNumber > -1}
          >
            <ConferencePlayer
              conferenceId={conferenceId}
              conference={conference}
              localGroupId={localGroupId}
              videoPlaying={videoPlaying}
              videoMuted={videoMuted}
              videoVolume={videoVolume}
              editMode={editMode}
              hidden={roomNumber > -1}
              setVideoVolume={setVideoVolume}
              setVideoPlaying={setVideoPlaying}
            />
          </StyledAppPlayer>
        </Grid>
        {roomNumber === -1 && liveInProgress && conference.videoAlternativeLink && (
          <Grid
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{ display: 'flex' }}
          >
            <StyledVideoAlternativeLink>
              <FormattedMessage id="video.alternative.link.message" />
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={onAlternativeLinkClick}
              >
                <FormattedMessage id="video.alternative.link.button" />
              </Button>
            </StyledVideoAlternativeLink>
          </Grid>
        )}
      </Grid>
      <StyledGridContainer container spacing={2}>
        {!window.lightTheme && (
          <StyledButtonCollapse isCollapsed={collapse}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DoubleArrowIcon />}
              onClick={() => setCollapse(!collapse)}
            >
              <FormattedMessage
                id={collapse ? 'collapse.sidebar.true' : 'collapse.sidebar.false'}
              />
            </Button>
          </StyledButtonCollapse>
        )}
        <Grid item xs={12} lg={window.lightTheme ? 10 : collapse ? 12 : 8}>
          {canEditSvg ? (
            <EditableStand
              width={conference.width}
              height={conference.height}
              svgString={conference.svgString}
              rooms={conference.rooms}
            />
          ) : (
            <VideoStand
              id={`conference-${conference.id}`}
              width={conference.width}
              height={conference.height}
              trigger={trigger}
              svgString={conference.svgString}
              isBroadcaster={conference.isBroadcaster}
              isEditable={conference.editable}
              rooms={conference.rooms}
              chairs={conference.chairs}
              totalUsers={conference.nbUsers}
              refAppPlayer={refAppPlayer}
              activeVideoAudio={activeVideoAudio}
              panelChildren={<VideoChat />}
              onEnterRoom={onEnterRoom}
              onLeaveRoom={onLeaveRoom}
              handleRenameRoom={handleRenameRoom}
            />
          )}
        </Grid>
        {!window.lightTheme && !fullscreenSubscriber && (
          <Grid className="sidebar-info" item xs={12} lg={4}>
            <ConferenceInfos status={status} updateConferenceStatus={updateConferenceStatus} />
          </Grid>
        )}
      </StyledGridContainer>
    </StyledConferenceContent>
  )
}

const StyledButtonCollapse = styled.div<{ isCollapsed: boolean }>`
  & {
    position: fixed;
    bottom: 20px;
    left: ${LEFT_MENU_WIDTH};
    z-index: 10;
    padding-left: 20px;
    display: none;

    @media (min-width: ${breakpoints.xl}) {
      display: block;
    }
    ${(props) =>
    props.isCollapsed
      ? css`
            .MuiButtonBase-root {
              background-color: ${SECONDARY};
            }
            svg {
              color: ${WHITE};
            }
          `
      : css`
            .MuiButtonBase-root {
              background-color: ${WHITE};
              color: ${PRIMARY};
            }
            svg {
              color: ${PRIMARY};
              transform: rotate(180deg);
            }
          `}
  }
`

const StyledConferenceContent = styled.div<{ isCollapsed: boolean }>`
  & {
    width: 100%;
    position: relative;

    ${(props) =>
    props.isCollapsed
      ? css`
            & .sidebar-info {
              display: none;
            }
          `
      : ''}

    & > div:first-of-type {
      min-height: 325px;
    }
  }
`

const StyledGridContainer = styled(Grid)`
  & {
    @media (min-width: ${breakpoints.lg}) {
      flex-direction: row-reverse;
    }
  }
`

const videoBackgroundStyle = css`
  & {
    margin-top: 30px;

    @media (min-width: ${breakpoints.lg}) {
      width: 800px;
      max-width: calc(100% - 30px);
    }
  }
`

const imageHeaderStyle = css`
  & {
    margin: 30px 20px 0 20px;

    & > div {
      padding: 0;

      > div {
        padding: 0;
      }
    }
  }
`

const StyledAppPlayer = styled.div<{ withVideo: boolean; isHidden: boolean }>`
  & {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    ${(props) =>
    props.isHidden &&
    css`
        min-height: 350px;
      `};

    & > * {
      flex: auto;
    }
    .app-player {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    ${(props) => props.withVideo && videoBackgroundStyle};
    ${(props) => !props.withVideo && imageHeaderStyle};
  }
`

const StyledVideoAlternativeLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;

  & > button {
    margin-left: 5px;
  }
`

export default ConferenceContent
