import { IFilters } from '../../entities/filters'
import { IKeywordProductDisplay } from '../../entities/keyword'
import { IProduct } from '../../entities/product'
import { ActionsTypes } from './model'

export const setProductsSearch = (searchProducts?: string | undefined) => ({
  type: ActionsTypes.SET_PRODUCT_SEARCH,
  searchProducts
})

export const setProductError = (error?: string | undefined) => ({
  type: ActionsTypes.SET_PRODUCT_ERROR,
  error
})

export const setProductLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_PRODUCT_LOADING,
  loading
})

export const setProductDeleting = (key: number, deleting: boolean) => ({
  type: ActionsTypes.SET_PRODUCT_DELETING,
  key,
  deleting
})

export const setProducts = (products: { total: number; items: IProduct[] }) => ({
  type: ActionsTypes.SET_PRODUCTS,
  products
})

export const deleteProduct = (productId: number) => ({
  type: ActionsTypes.DELETE_PRODUCT,
  productId
})

export const resetProduct = () => ({
  type: ActionsTypes.RESET_PRODUCT
})

export const setProduct = (product: IProduct) => ({
  type: ActionsTypes.SET_PRODUCT,
  product
})

export const setProductFilters = (filters: IFilters) => ({
  type: ActionsTypes.SET_PRODUCT_FILTERS,
  filters
})

export const setErrorProductFilters = (errorFilters?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_PRODUCT_FILTERS,
  errorFilters
})

export const setLoadingProductFilters = (loadingFilters: boolean) => ({
  type: ActionsTypes.SET_LOADING_PRODUCT_FILTERS,
  loadingFilters
})

export const setProductUpdating = (productUpdating: boolean) => ({
  type: ActionsTypes.SET_PRODUCT_UPDATING,
  productUpdating
})

export const setProductAdding = (productAdding: boolean) => ({
  type: ActionsTypes.SET_PRODUCT_ADDING,
  productAdding
})

export const setProductKeywords = (productKeywords: IKeywordProductDisplay[]) => ({
  type: ActionsTypes.SET_PRODUCT_KEYWORDS,
  productKeywords
})

export const setLoadingProductKeywords = (loadingProductKeywords: boolean) => ({
  type: ActionsTypes.SET_LOADING_PRODUCT_KEYWORDS,
  loadingProductKeywords
})

export const setErrorProductKeywords = (errorProductKeywords: string) => ({
  type: ActionsTypes.SET_ERROR_PRODUCT_KEYWORDS,
  errorProductKeywords
})

export const mergeProduct = (product: IProduct) => ({
  type: ActionsTypes.MERGE_PRODUCT,
  product
})

export const updateProduct = (product: IProduct) => ({
  type: ActionsTypes.UPDATE_PRODUCT,
  product
})
