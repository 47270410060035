import { css } from '@emotion/react'
import { breakpoints } from '../../breakpoints'

export const fullscreenStyle = css`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  padding: 0 10px;
  z-index: 1200;

  @media (max-width: ${breakpoints.lg}) {
    width: 100vw;
    left: 0;
    padding: 0;
  }
`
