import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { IconButton, Slide } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React, { ReactNode, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PRIMARY_COLOR } from '../../theme'
import { BACKGROUND_PRIMARY_OPACITY_9, VERY_LIGHT_GRAY, WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD, BOX_SHADOW } from '../../theme/sizes'

interface CustomDialogProps {
  open: boolean
  icon?: ReactNode
  title?: string
  customCss?: string
  footer?: ReactNode
  backdropClickDisabled?: boolean
  slideDirection?: 'left' | 'right' | 'up' | 'down' | undefined
  onClose?: (event: React.MouseEvent<any, MouseEvent>) => void
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  icon,
  title,
  customCss,
  footer,
  backdropClickDisabled,
  slideDirection,
  children,
  onClose
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const handleClose = (event: React.MouseEvent<any, MouseEvent>) => {
    if (onClose) {
      onClose(event)
    }
  }

  const stopPropagation = (event: React.MouseEvent<any, MouseEvent>) => {
    event.stopPropagation()
  }

  return (
    <StyledCustomBackgroundDialog
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        !backdropClickDisabled && handleClose(event)
      }
      backdropClickDisabled={backdropClickDisabled}
    >
      <Slide direction={slideDirection} in={openDialog} mountOnEnter unmountOnExit>
        <StyledCustomDialog customCss={customCss} onClick={stopPropagation}>
          <StyledHeader>
            <StyledHeaderLeft>
              {icon && <div>{icon}</div>}
              <StyledTitle>{title || <FormattedMessage id="custom.dialog.title" />}</StyledTitle>
            </StyledHeaderLeft>
            <StyledHeaderRight>
              <StyledIconButton onClick={handleClose}>
                <HighlightOffIcon />
              </StyledIconButton>
            </StyledHeaderRight>
          </StyledHeader>
          <StyledContent withFooter={!!footer}>{children}</StyledContent>
          {footer && <StyledFooter>{footer}</StyledFooter>}
        </StyledCustomDialog>
      </Slide>
    </StyledCustomBackgroundDialog>
  )
}

const StyledCustomBackgroundDialog = styled.div<{ backdropClickDisabled?: boolean }>`
  position: absolute;
  z-index: 1201;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    !props.backdropClickDisabled &&
    `
    height: 100vh;
    width: 100vw;
  `}
`

const StyledCustomDialog = styled.div<{ customCss?: string }>`
  background-color: ${BACKGROUND_PRIMARY_OPACITY_9};
  border: 1px solid ${VERY_LIGHT_GRAY};
  border-radius: ${BORDER_RADIUS_CARD};
  box-shadow: ${BOX_SHADOW};
  color: ${WHITE};
  padding: 0 15px;
  ${(props) =>
    props.customCss
      ? css`
          ${props.customCss}
        `
      : ''}
`

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${VERY_LIGHT_GRAY};
  justify-content: space-between;
  align-items: center;
`

const StyledHeaderLeft = styled.div`
  flex: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledTitle = styled.div`
  margin-left: 10px;
  font-weight: bold;
`

const StyledHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledIconButton = styled(IconButton)`
  margin-right: -15px;

  & svg {
    fill: ${WHITE};
  }
`

const StyledContent = styled.div<{ withFooter: boolean }>`
  ${(props) => (props.withFooter ? 'height: calc(100% - 80px);' : 'height: calc(100% - 60px);')}
  overflow-y: auto;
  & button,
  & button:hover,
  & button:focus,
  & a.MuiButtonBase-root,
  & a.MuiButtonBase-root:hover,
  & a.MuiButtonBase-root:focus {
    background-color: ${WHITE};
    margin-left: 5px;
  }
  & span {
    color: ${WHITE};
  }
  & span.MuiButton-label {
    color: ${PRIMARY_COLOR};
  }
`

const StyledFooter = styled.div`
  border-top: 1px solid ${VERY_LIGHT_GRAY};
`

export default CustomDialog
