import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { alpha } from '@material-ui/core'
import { darken } from 'polished'
import React from 'react'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../theme'
import { BLACK_BLUE, BRIGHT_RED, LIGHT_GRAY, RED, WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import withRippleEffect from '../withRippleEffect/withRippleEffect'

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  White = 'white',
  Danger = 'danger',
  Gray = 'gray',
  Black = 'black'
}

export enum ButtonSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  Normal = 'normal',
  Large = 'large'
}

type HTMLButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export interface IButtonProps extends HTMLButtonProps {
  btnType?: ButtonType
  outline?: boolean
  size?: ButtonSize
  className?: string
  phantom?: boolean
}

const AppButton: React.FC<IButtonProps> = ({ children, className, btnType, outline, ...props }) => {
  return (
    <StyledButton className={className} btnType={btnType} outline={outline} {...props}>
      <span className="content">{children}</span>
    </StyledButton>
  )
}

export const getBgColor = (btnType?: ButtonType, phantom?: boolean) => {
  if (phantom) return 'transparent'
  switch (btnType) {
    default:
    case ButtonType.Primary:
      return PRIMARY_COLOR
    case ButtonType.Secondary:
      return SECONDARY_COLOR
    case ButtonType.Danger:
      return RED
    case ButtonType.White:
      return WHITE
    case ButtonType.Black:
      return BLACK_BLUE
    case ButtonType.Gray:
      return LIGHT_GRAY
  }
}

const getColor = (btnType?: ButtonType, outline?: boolean, phantom?: boolean) => {
  switch (btnType) {
    case ButtonType.Danger:
      return !outline && !phantom ? WHITE : BRIGHT_RED
    default:
    case ButtonType.Primary:
      return !outline && !phantom ? WHITE : PRIMARY_COLOR
    case ButtonType.White:
      return !outline && !phantom ? PRIMARY_COLOR : WHITE
    case ButtonType.Black:
      return !outline && !phantom ? WHITE : BLACK_BLUE
    case ButtonType.Gray:
      return !outline && !phantom ? WHITE : LIGHT_GRAY
  }
}

const getOverColor = (btnType?: ButtonType, outline?: boolean, phantom?: boolean) => {
  if (phantom) return 'transparent'
  return !outline ? darken(0.1, getBgColor(btnType)) : getBgColor(btnType)
}

const getActiveShadow = (phantom?: boolean) => {
  return phantom
    ? ''
    : `0px 5px 5px -3px ${alpha(BLACK_BLUE, 0.14)}, 0px 8px 10px 1px ${alpha(
      BLACK_BLUE,
      0.14
    )},0px 3px 14px 2px ${alpha(BLACK_BLUE, 0.14)}`
}

const getPadding = (size?: ButtonSize, phantom?: boolean) => {
  if (phantom) {
    return '0'
  }
  switch (size) {
    case ButtonSize.sm:
      return ''
    default:
    case ButtonSize.md:
      return '8px 20px'
    case ButtonSize.lg:
      return '20px 4rem'
  }
}

export const buttonStyleFactory = (tag: any) =>
  styled(tag, {
    shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'outline'
  }) <IButtonProps>`
    user-select: none;
    outline: none;
    padding: ${(props) => getPadding(props.size, props.phantom)};
    line-height: 1.75;
    display: inline-block;
    text-align: center;

    background-color: ${(props) =>
      props.outline || props.phantom ? 'transparent' : getBgColor(props.btnType, props.phantom)};
    font-size: ${({ size = ButtonSize.Normal }) => (size === ButtonSize.Large ? '19px' : '15px')};

    border: 1px solid ${(props) => getBgColor(props.btnType, props.phantom)};
    border-radius: ${BORDER_RADIUS_CARD};

    color: ${(props) => getColor(props.btnType, props.outline, props.phantom)};
    cursor: pointer;
    text-decoration: none;

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    & > .content {
      display: inline-block;
      border-bottom: ${(props) =>
      props.phantom ? `solid ${getColor(props.btnType, props.outline, props.phantom)} 1px` : ''};
    }
    &:hover {
      background-color: ${(props) => getOverColor(props.btnType, props.outline, props.phantom)};
      color: ${(props) => getColor(props.btnType, false, props.phantom)};
    }

    &:active {
      box-shadow: ${(props) => getActiveShadow(props.phantom)};
    }

    &:disabled {
      opacity: 0.3;
    }
  `

export const StyledButton = buttonStyleFactory('button')

export default styled(withRippleEffect(AppButton))``
