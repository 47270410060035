import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IOTProperties, IStream } from '../entities'
import { IMediaStream } from './IMediaStream'
import { StreamContext } from './StreamSession'

interface IStreamSubscriberProps {
  mediaStream?: IMediaStream
  stream: IStream
  className?: string
  style?: { [key: string]: string }
  properties: IOTProperties | undefined
  retry: boolean
  maxRetryAttempts: number
  retryAttemptTimeout: number
  eventHandlers: { [key: string]: (event: any) => void }
  subscriberRef: React.RefObject<any>
  onSubscribe: () => void
  onError: (err: any) => void
}

export const StreamSubscriber: React.FC<IStreamSubscriberProps> = ({
  mediaStream,
  stream,
  className,
  style,
  properties,
  retry,
  maxRetryAttempts,
  retryAttemptTimeout,
  eventHandlers,
  subscriberRef,
  onSubscribe,
  onError
}) => {
  const context = useContext(StreamContext)
  const [currentMediaStream, setCurrentMediaStream] = useState<IMediaStream | null>(null)
  const [currentStream, setCurrentStream] = useState<IStream | null>(null)
  const [currentMaxRetryAttempts, setCurrentMaxRetryAttempts] = useState(5)
  const [currentRetryAttemptTimeout, setCurrentRetryAttemptTimeout] = useState(1000)

  const createSubscriber = useCallback(() => {
    if (!currentMediaStream || !currentStream) {
      return
    }
    currentMediaStream.createSubscriber(
      subscriberRef,
      currentStream,
      retry,
      currentMaxRetryAttempts,
      currentRetryAttemptTimeout,
      properties,
      eventHandlers,
      onSubscribe,
      onError
    )
  }, [
    currentRetryAttemptTimeout,
    currentMaxRetryAttempts,
    currentMediaStream,
    currentStream,
    properties,
    retry,
    subscriberRef,
    eventHandlers,
    onError,
    onSubscribe
  ])

  const destroySubscriber = useCallback(() => {
    if (currentMediaStream && currentStream) {
      currentMediaStream.destroySubscriber(currentStream)
    }
  }, [currentMediaStream, currentStream])

  useEffect(() => {
    setCurrentStream(stream)
  }, [stream])

  useEffect(() => {
    setCurrentMediaStream(mediaStream || context.mediaStream)
  }, [mediaStream, context])

  useEffect(() => {
    setCurrentMaxRetryAttempts(maxRetryAttempts || 5)
  }, [maxRetryAttempts])

  useEffect(() => {
    setCurrentRetryAttemptTimeout(retryAttemptTimeout || 1000)
  }, [retryAttemptTimeout])

  useEffect(() => {
    createSubscriber()

    return () => {
      destroySubscriber()
    }
    // to mount and unmount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMediaStream])

  return <div className={className} style={style} ref={subscriberRef} />
}
