import { IContact, IContactNote } from '../../entities'
import { IFilters } from '../../entities/filters'
import { IContactKeyword, IKeyword } from '../../entities/keyword'
import { ActionsTypes } from './model'

export const setParticipantsSearch = (searchParticipants?: string | undefined) => ({
  type: ActionsTypes.SET_SEARCH_PARTICIPANT,
  searchParticipants
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_LOADING_PARTICIPANT,
  loading
})

export const setLoadingContacts = (loadingContacts: boolean) => ({
  type: ActionsTypes.SET_LOADING_CONTACTS,
  loadingContacts
})

export const setLoadingContactsFiltered = (loadingContactsFiltered: boolean) => ({
  type: ActionsTypes.SET_LOADING_CONTACTS_FILTERED,
  loadingContactsFiltered
})

export const setAdding = (adding: number | null) => ({
  type: ActionsTypes.SET_ADDING_PARTICIPANT,
  adding
})

export const setDeleting = (deleting: number | null) => ({
  type: ActionsTypes.SET_DELETING_PARTICIPANT,
  deleting
})

export const setRefreshingContact = (refreshingContact: number | null) => ({
  type: ActionsTypes.SET_REFRESHING_CONTACT,
  refreshingContact
})

export const setErrorContacts = (errorContacts?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_CONTACTS,
  errorContacts
})

export const setErrorContactsFiltered = (errorContactsFiltered?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_CONTACTS_FILTERED,
  errorContactsFiltered
})

export const setErrorAdding = (errorAdding?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_ADDING,
  errorAdding
})

export const setErrorDeleting = (errorDeleting?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_DELETING,
  errorDeleting
})

export const setErrorPresences = (errorPresences?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_PRESENCES,
  errorPresences
})

export const setErrorParticipants = (errorParticipants?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_PARTICIPANTS,
  errorParticipants
})

export const setParticipants = (participants: { total: number; items: IContact[] }) => ({
  type: ActionsTypes.SET_PARTICIPANTS,
  participants
})

export const setPresenceUsersCount = (presenceUsersCount: number) => ({
  type: ActionsTypes.SET_PRESENCE_USERS_COUNT,
  presenceUsersCount
})

export const incrementPresenceUsersCount = (presenceUsersCount: number) => ({
  type: ActionsTypes.INCREMENT_PRESENCE_USERS_COUNT,
  presenceUsersCount
})

export const decrementPresenceUsersCount = (presenceUsersCount: number) => ({
  type: ActionsTypes.DECREMENT_PRESENCE_USERS_COUNT,
  presenceUsersCount
})

export const setPresenceUsers = (presenceUsers: IContact[]) => ({
  type: ActionsTypes.SET_PRESENCE_USERS,
  presenceUsers
})

export const setAroundUsersCount = (aroundUsersCount: number) => ({
  type: ActionsTypes.SET_AROUND_USERS_COUNT,
  aroundUsersCount
})

export const incrementAroundUsersCount = (aroundUsersCount: number) => ({
  type: ActionsTypes.INCREMENT_AROUND_USERS_COUNT,
  aroundUsersCount
})

export const decrementAroundUsersCount = (aroundUsersCount: number) => ({
  type: ActionsTypes.DECREMENT_AROUND_USERS_COUNT,
  aroundUsersCount
})

export const setAroundUsers = (aroundUsers: IContact[]) => ({
  type: ActionsTypes.SET_AROUND_USERS,
  aroundUsers
})

export const setContacts = (contacts: IContact[]) => ({
  type: ActionsTypes.SET_CONTACTS,
  contacts
})

export const setErrorUpdateContact = (errorUpdateContact: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_UPDATE_CONTACT,
  errorUpdateContact
})

export const setLoadingUpdateContact = (loadingUpdateContact: boolean) => ({
  type: ActionsTypes.SET_LOADING_UPDATE_CONTACT,
  loadingUpdateContact
})

export const setContactsFiltered = (contactsFiltered: IContact[]) => ({
  type: ActionsTypes.SET_CONTACTS_FILTERED,
  contactsFiltered
})

export const refreshContact = (contactRefreshed: IContact) => ({
  type: ActionsTypes.REFRESH_CONTACT,
  contactRefreshed
})

export const updateContactOnline = (contactRefreshedIds: number[], isOnline: boolean) => ({
  type: ActionsTypes.UPDATE_CONTACT_ONLINE,
  contactRefreshedIds,
  isOnline
})

export const updateContactIsContact = (contactId: number, isContact: boolean) => ({
  type: ActionsTypes.UPDATE_CONTACT_IS_CONTACT,
  contactId,
  isContact
})

export const mergeContact = (contact: IContact) => ({
  type: ActionsTypes.MERGE_CONTACT,
  contact
})

export const removeContact = (contactId: number) => ({
  type: ActionsTypes.REMOVE_CONTACT,
  contactId
})

export const setUserFilters = (filters: IFilters) => ({
  type: ActionsTypes.SET_USERS_FILTERS,
  filters
})

export const setErrorUserFilters = (errorFilters?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_USERS_FILTERS,
  errorFilters
})

export const setLoadingUserFilters = (loadingFilters: boolean) => ({
  type: ActionsTypes.SET_LOADING_USERS_FILTERS,
  loadingFilters
})

export const setLoadingUpdateNote = (contactId: number, loadingUpdateContactNote: boolean) => ({
  type: ActionsTypes.SET_LOADING_UPDATE_NOTE,
  contactId,
  loadingUpdateContactNote
})

export const setLoadingUpdateTags = (contactId: number, loadingUpdateContactTags: boolean) => ({
  type: ActionsTypes.SET_LOADING_UPDATE_TAGS,
  contactId,
  loadingUpdateContactTags
})

export const addContactRefreshed = (contactRefreshed: IContact) => ({
  type: ActionsTypes.ADD_CONTACT_REFRESHED,
  contactRefreshed
})

export const addKeywordCreatedByUser = (keywordCreatedByUser: IKeyword) => ({
  type: ActionsTypes.ADD_USERS_KEYWORD,
  keywordCreatedByUser
})

export const editKeywordCreatedByUser = (editedKeyword: IKeyword) => ({
  type: ActionsTypes.EDIT_USERS_KEYWORD,
  editedKeyword
})

export const removeKeywordCreatedByUser = (keywordCreatedByUser: IKeyword) => ({
  type: ActionsTypes.REMOVE_USERS_KEYWORD,
  keywordCreatedByUser
})

export const setKeywordsCreatedByUser = (keywordsCreatedByUser: IKeyword[]) => ({
  type: ActionsTypes.SET_USERS_KEYWORDS,
  keywordsCreatedByUser
})

export const setKeywordsCreatedByUserError = (errorKeywordsCreatedByUser: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_USERS_KEYWORDS,
  errorKeywordsCreatedByUser
})

export const setKeywordsCreatedByUserLoading = (loadingKeywordsCreatedByUser: boolean) => ({
  type: ActionsTypes.SET_LOADING_USERS_KEYWORDS,
  loadingKeywordsCreatedByUser
})

export const setKeywordsCreatedByUserLoaded = (loadedKeywordsCreatedByUser: boolean) => ({
  type: ActionsTypes.SET_LOADED_USERS_KEYWORDS,
  loadedKeywordsCreatedByUser
})

export const setLoadingParticipantsFiltered = (loadingParticipantsFiltered: boolean) => ({
  type: ActionsTypes.SET_LOADING_PARTICIPANTS_FILTERED,
  loadingParticipantsFiltered
})

export const setParticipantsFiltered = (participantsFiltered: {
  total: number
  items: IContact[]
}) => ({
  type: ActionsTypes.SET_PARTICIPANTS_FILTERED,
  participantsFiltered
})

export const setErrorParticipantsFiltered = (errorParticipantsFiltered?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_PARTICIPANTS_FILTERED,
  errorParticipantsFiltered
})

export const removeContactKeywordUsers = (keyword: IKeyword) => ({
  type: ActionsTypes.REMOVE_CONTACT_KEYWORD_USERS,
  keyword
})

export const editContactKeywordUsers = (keyword: IKeyword) => ({
  type: ActionsTypes.EDIT_CONTACT_KEYWORD_USERS,
  keyword
})

export const deleteContactKeyword = (id: number, contactKeywordRemoved: IContactKeyword) => ({
  type: ActionsTypes.REMOVE_CONTACT_KEYWORD,
  id,
  contactKeywordRemoved
})

export const addNewContactKeyword = (id: number, contactKeyword: IContactKeyword) => ({
  type: ActionsTypes.ADD_NEW_CONTACT_KEYWORD,
  id,
  contactKeyword
})

export const addNewContactNote = (id: number, note: IContactNote) => ({
  type: ActionsTypes.ADD_NEW_CONTACT_NOTE,
  id,
  note
})
export const editContactNote = (id: number, note: IContactNote) => ({
  type: ActionsTypes.EDIT_CONTACT_NOTE,
  id,
  note
})

export const deleteContactNote = (id: number) => ({
  type: ActionsTypes.REMOVE_CONTACT_NOTE,
  id
})

export const setErrorFollowers = (errorFollowers?: string | undefined) => ({
  type: ActionsTypes.SET_ERROR_FOLLOWERS,
  errorFollowers
})

export const setLoadingFollowers = (loadingFollowers: boolean) => ({
  type: ActionsTypes.SET_LOADING_FOLLOWERS,
  loadingFollowers
})

export const setFollowers = (followers: IContact[]) => ({
  type: ActionsTypes.SET_FOLLOWERS,
  followers
})
