import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IQuestion } from '../../../entities/questions'
import { useDateFns, useThunkDispatch } from '../../../hooks'
import { IRootState } from '../../../redux'
import { removeQuestion, updateQuestion } from '../../../store/questions'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../theme'
import { GRAY, RED } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import { formatName, urlRegex } from '../../../utils'
import Loading from '../../Loading'
import UserAvatar from '../UserAvatar/UserAvatar'

interface IQuestionProps {
  question: IQuestion
  editMode?: boolean
}

const Question: FC<IQuestionProps> = ({ question, editMode }) => {
  const intl = useIntl()
  const user = useSelector((state: IRootState) => state.appState.user)
  const updating = useSelector((state: IRootState) => state.questionsState.updating)
  const [formattedQuestion, setFormattedQuestion] = useState('')
  const [htmlQuestion, setHtmlQuestion] = useState(false)
  const dispatch = useThunkDispatch()
  const dateFns = useDateFns()

  const likeQuestion = (like: boolean) => {
    dispatch(updateQuestion(question.id, like))
  }

  const deleteQuestion = () => {
    dispatch(removeQuestion(question.id))
  }

  const formatQuestionClipboardText = (q: IQuestion) => {
    // fullname-partnerName:question
    return `${formatName(q.user).full}${q.user.partnerName ? '-' + q.user.partnerName : ''}:${q.question
      }`
  }

  const questionDeletable = useMemo(
    () => editMode || (user && question.user.id === user.id),
    [user, editMode, question]
  )

  useEffect(() => {
    if (question.question) {
      const regex = new RegExp(urlRegex)
      const urls = question.question.match(regex)
      if (urls) {
        // url in Question
        setHtmlQuestion(true)
        setFormattedQuestion(
          question.question.indexOf('</a>') === -1
            ? question.question.replace(
              regex,
              (url) => `<a href="${url}" target="_blank">${url}</a>`
            )
            : question.question
        )
      } else if (question.question.indexOf('<br />') > -1) {
        // special case withtextarea br
        setHtmlQuestion(true)
        setFormattedQuestion(question.question)
      } else if (question.question.indexOf('<b>') > -1 || question.question.indexOf('<i>') > -1) {
        // fonts special case
        setHtmlQuestion(true)
        setFormattedQuestion(question.question)
      } else {
        // display question
        setFormattedQuestion(question.question)
      }
    }
  }, [question, intl])

  return (
    <StyledQuestionContainer>
      <StyledQuestionContent>
        <StyledUserAvatar>
          <UserAvatar user={question.user} showDetails={question.user.id !== user?.id} />
        </StyledUserAvatar>
        {htmlQuestion ? (
          <StyledQuestion
            isMe={question.user.id === user?.id}
            dangerouslySetInnerHTML={{ __html: formattedQuestion }}
          />
        ) : (
          <StyledQuestion isMe={question.user.id === user?.id}>{formattedQuestion}</StyledQuestion>
        )}
        {updating[question.id] && <Loading />}
      </StyledQuestionContent>
      <StyledQuestionActions>
        <span>
          <FormattedMessage id="question.votes" values={{ votes: question.countUpVotes }} />
        </span>
        <StyledVotedButton haveVoted={question.haveVoted}>
          <IconButton
            title={
              question.haveVoted
                ? intl.formatMessage({ id: 'question.button.vote_down' })
                : intl.formatMessage({ id: 'question.button.vote_up' })
            }
            onClick={() => likeQuestion(!question.haveVoted)}
          >
            <ThumbUpIcon
              titleAccess={
                question.haveVoted
                  ? intl.formatMessage({ id: 'titleAccess.Question.ThumbDownIcon' })
                  : intl.formatMessage({ id: 'titleAccess.Question.ThumbUpIcon' })
              }
            />
          </IconButton>
        </StyledVotedButton>
        {questionDeletable && (
          <>
            <StyledIconDelete
              title={intl.formatMessage({ id: 'question.button.delete' })}
              onClick={deleteQuestion}
            >
              <DeleteIcon
                titleAccess={intl.formatMessage({ id: 'titleAccess.Question.DeleteIcon' })}
              />
            </StyledIconDelete>
            <CopyToClipboard text={formatQuestionClipboardText(question)}>
              <IconButton>
                <NoteAddIcon
                  titleAccess={intl.formatMessage({ id: 'titleAccess.Question.CopyToClipboard' })}
                />
              </IconButton>
            </CopyToClipboard>
          </>
        )}
      </StyledQuestionActions>
      <StyledDate>{dateFns.formatDistanceToNow(question.createDate)}</StyledDate>
    </StyledQuestionContainer>
  )
}

// language=SCSS
const buttonVoted = css`
  svg {
    color: ${PRIMARY_COLOR};
  }
`

// language=SCSS
const StyledQuestionContainer = styled.div`
  & {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    padding-right: 3px;
  }
`

// language=SCSS
const StyledUserAvatar = styled.div`
  & {
    flex: 0 0 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .MuiAvatar-root {
      width: 30px;
      height: 30px;
    }
  }
`

// language=SCSS
const StyledVotedButton = styled.div<{ haveVoted: boolean }>`
  & {
    ${(props) => (props.haveVoted ? buttonVoted : '')}
  }
`

// language=SCSS
const StyledIconDelete = styled(IconButton)`
  & {
    svg {
      color: ${RED};
    }
  }
`

// language=SCSS
const StyledQuestion = styled.div<{ isMe: boolean }>`
  & {
    background-color: ${(props) => (props.isMe ? `${PRIMARY_COLOR}` : `${SECONDARY_COLOR}`)};
    color: #fff;
    border-radius: ${BORDER_RADIUS_CARD};
    padding: 7px;

    a {
      color: #fff;
    }
  }
`

// language=SCSS
const StyledQuestionContent = styled.div`
  & {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }
`

// language=SCSS
const StyledQuestionActions = styled.div`
  & {
    transform: translateY(-5px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 42px;

    .MuiIconButton-root {
      padding: 6px;
      margin-left: 5px;
    }
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }
`

const StyledDate = styled.span`
  position: absolute;
  right: 3px;
  bottom: 1px;
  font-size: 10px;
  color: ${GRAY};
`

export default Question
