import styled from '@emotion/styled'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FullScreenHandle } from 'react-full-screen'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IRoom, RoomType } from '../../../../entities'
import { useThunkDispatch } from '../../../../hooks'
import { IRootState } from '../../../../redux'
import settings from '../../../../settings'
import { setOpentokStreaming } from '../../../../store/room'
import { formatName } from '../../../../utils'
import consoleUtils from '../../../../utils/consoleUtils'
import JitsiActions from './JitsiActions'

interface IJitsiIFrame {
  room: IRoom
  viewer?: boolean
  broadcaster?: boolean
  handleFullscreen?: FullScreenHandle
  handleLeave?: () => void
}

interface IParticipant {
  id: string
  displayName: string
  formattedDisplayName: string
}

interface IJitsiApi {
  addEventListeners?: () => void
  getParticipantsInfo?: () => IParticipant[]
  executeCommand?: (command: string) => void
  captureLargeVideoScreenshot?: () => Promise<{ dataURL: string }>
  getIFrame?: () => HTMLIFrameElement
}

const JitsiIFrame: React.FC<IJitsiIFrame> = ({
  room,
  viewer,
  broadcaster,
  handleFullscreen,
  handleLeave
}) => {
  const intl = useIntl()
  const divRef = useRef<HTMLDivElement>(null)
  const [isStarted, setStarted] = useState(false)
  const preferedVideoDisplay = useSelector(
    (state: IRootState) => state.appState.preferedVideoDisplay
  )
  const [publishVideo, setPublishVideo] = useState(
    broadcaster ? true : viewer ? false : preferedVideoDisplay
  )
  const [publishAudio, setPublishAudio] = useState(
    broadcaster ? true : viewer ? false : settings.opentok.autoStart
  )
  const [api, setApi] = useState<IJitsiApi>({})
  const jitsiRoom = useSelector((state: IRootState) => state.jitsiState.jitsiRoom)
  const loadingJitsi = useSelector((state: IRootState) => state.jitsiState.loadingJitsi)
  const user = useSelector((state: IRootState) => state.appState.user)
  const dispatch = useThunkDispatch()

  const handlePublishVideo = () => {
    executeCommand('toggleVideo')
  }

  const handlePublishAudio = () => {
    executeCommand('toggleAudio')
  }

  const handleMuteStatus = useCallback((audio: { muted: boolean }) => {
    setPublishAudio(!audio.muted)
  }, [])

  const handleVideoStatus = useCallback((video: { muted: boolean }) => {
    setPublishVideo(!video.muted)
  }, [])

  // custom events
  const executeCommand = useCallback(
    (command: string) => {
      api.executeCommand && api.executeCommand(command)
      if (command === 'hangup') {
        return handleLeave && handleLeave()
      }
      if (command === 'toggleAudio') {
        setPublishAudio(!publishAudio)
      }
      if (command === 'toggleVideo') {
        setPublishVideo(!publishVideo)
      }
      // toggleShareScreen
    },
    [api, publishAudio, publishVideo, handleLeave]
  )

  const handleClose = useCallback(() => {
    executeCommand('hangup')
  }, [executeCommand])

  const startMeet = useCallback(() => {
    const options = {
      roomName: jitsiRoom?.externalMeetingId || 'error-room',
      width: '100%',
      height: '100%',
      configOverwrite: {
        defaultLanguage: 'fr',
        prejoinPageEnabled: false,
        // Start the conference in audio only mode (no video is being received nor sent)
        // startAudioOnly: viewer,
        // Every participant after the Nth will start audio muted.
        // startAudioMuted: 10,
        startWithAudioMuted: viewer || !publishAudio,
        // Every participant after the Nth will start video muted.
        // startVideoMuted: 10,
        startWithVideoMuted: viewer || !publishVideo,
        debug: false,
        debugAudioLevels: false,
        disableResponsiveTiles: viewer || broadcaster,
        disableJoinLeaveSounds: viewer || broadcaster,
        toolbarButtons: viewer
          ? []
          : [
            'microphone',
            'camera',
            'closedcaptions',
            'desktop',
            'fodeviceselection',
            'profile',
            'livestreaming',
            'etherpad',
            'sharedvideo',
            'shareaudio',
            'raisehand',
            'videoquality',
            'select-background',
            'mute-everyone',
            'tileview'
            // 'embedmeeting', 'fullscreen', 'hangup', 'chat', 'recording', 'settings', 'filmstrip', 'invite',
            // 'feedback', 'stats', 'shortcuts', 'download', 'help', 'mute-video-everyone', 'security'
          ],
        dynamicBrandingUrl: {
          // The domain url to apply (will replace the domain in the sharing conference link/embed section)
          inviteDomain: 'example-company.org',
          // The hex value for the colour used as background
          backgroundColor: '#fff',
          // The url for the image used as background
          backgroundImageUrl: 'https://example.com/background-img.png',
          // The anchor url used when clicking the logo image
          logoClickUrl: 'https://example-company.org',
          // The url used for the image used as logo
          logoImageUrl: 'https://example.com/logo-img.png'
        },
        remoteVideoMenu: {
          // If set to true the 'Kick out' button will be disabled.
          disableKick: true,
          // If set to true the 'Grant moderator' button will be disabled.
          disableGrantModerator: false
        },
        // If set to true all muting operations of remote participants will be disabled.
        disableRemoteMute: true
      }, // https://github.com/jitsi/jitsi-meet/blob/master/config.js
      interfaceConfigOverwrite: {
        // overwrite interface properties
        DEFAULT_BACKGROUND: '#000000',
        DEFAULT_LOCAL_DISPLAY_NAME: intl.formatMessage({ id: 'jitsi.local.display.name' }),
        JITSI_WATERMARK_LINK: 'https://www.google.fr',
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        TOOLBAR_ALWAYS_VISIBLE: false
      },
      loggingConfig: {
        defaultLogLevel: 'error'
      },
      parentNode: divRef.current,
      userInfo: {
        email: user?.email,
        displayName: user ? formatName(user).full : ''
      }
    }
    const jitsiApi = new window.JitsiMeetExternalAPI(
      room.type === RoomType.JitsiVideoChat || room.type === RoomType.JitsiBroadcast ?
        settings.jitsi.default : settings.jitsi.ideal
      , options)

    jitsiApi.addEventListeners({
      readyToClose: handleClose,
      audioMuteStatusChanged: handleMuteStatus,
      videoMuteStatusChanged: handleVideoStatus
    })

    setApi(jitsiApi)
    dispatch(setOpentokStreaming(true))
  }, [
    intl,
    jitsiRoom,
    room.type,
    viewer,
    broadcaster,
    publishAudio,
    publishVideo,
    user,
    handleClose,
    handleMuteStatus,
    handleVideoStatus,
    dispatch
  ])

  useEffect(() => {
    if (!isStarted && !loadingJitsi && jitsiRoom?.externalMeetingId && divRef && divRef.current) {
      if (window.JitsiMeetExternalAPI) {
        startMeet()
        setStarted(true)
      } else {
        consoleUtils.info('JitsiMeetExternalAPI not loaded')
      }
    }
  }, [isStarted, loadingJitsi, jitsiRoom, divRef, startMeet])

  if (loadingJitsi) {
    return (
      <Center>
        <FormattedMessage id="jitsi.loading" />
      </Center>
    )
  }

  return (
    <>
      <JitsiContainer ref={divRef} />
      <JitsiActions
        viewer={viewer}
        handlePublishVideo={handlePublishVideo}
        publishVideo={publishVideo}
        handlePublishAudio={handlePublishAudio}
        publishAudio={publishAudio}
        handleFullscreen={handleFullscreen}
        handleLeave={handleClose}
      />
    </>
  )
}

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`

const JitsiContainer = styled.div``

export default JitsiIFrame
