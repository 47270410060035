import styled from '@emotion/styled'
import { IconButton, Input, InputAdornment } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'

interface IChatBoxProps {
  handleTextChange: (text: string) => void
}

const ChatBox: FC<IChatBoxProps> = ({ handleTextChange }) => {
  const intl = useIntl()
  const [text, setText] = useState('')
  const [send, setSend] = useState(false)

  return (
    <StyledChatBox>
      <StyledInput
        type="text"
        value={text}
        multiline
        maxRows={4}
        placeholder={intl.formatMessage({ id: 'chat.input' })}
        className="form-control"
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          if (send) {
            handleTextChange(text)
            setText('')
            setSend(false)
          } else {
            setText(event.target.value)
          }
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          if (event.keyCode === 13 && !event.shiftKey) {
            setSend(true)
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (text !== '') {
                  handleTextChange(text)
                  setText('')
                }
              }}
              onMouseDown={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.preventDefault()
              }}
            >
              <SendIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </StyledChatBox>
  )
}

const StyledChatBox = styled.div`
  display: flex;
  justify-content: center;
`

const StyledInput = styled(Input)`
  width: 100%;
  margin: 0 5px;
`

export default ChatBox
