import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import ProfileForm from '../../components/profile/ProfileForm'
import Title from '../../components/ui/Title/Title'
import { useEffectOnce, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import settings from '../../settings'
import { setPageReferral } from '../../store/gtm'
import { BLACK_BLUE } from '../../theme/colors'

const ProfilePage = () => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const dispatch = useThunkDispatch()

  // gtm DATA
  useEffectOnce(() => {
    const title = intl.formatMessage({ id: 'gtm.profil.title' })
    dispatch(setPageReferral(title))
    sendDataToGTM({
      event: 'page-view',
      pageTitle: title,
      pageType: intl.formatMessage({ id: 'gtm.profil.type' }),
      pageReferral
    })
  })

  return (
    <StyledContent>
      <Title titleId="profile.title" variant="h1" />
      <ProfileForm loadData withSubmit />
      <Helmet>
        <title>
          {`${intl.formatMessage({ id: 'gtm.profil.title' })} - ${settings.theme.header.title}`}
        </title>
      </Helmet>
    </StyledContent>
  )
}

const StyledContent = styled.div`
  & {
    max-width: 780px;
    margin: 20px auto 0;

    .MuiInputBase-input {
      border-bottom: 1px solid ${BLACK_BLUE};
    }
  }
`

export default ProfilePage
