import { Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import React from 'react'

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
    return <Slide direction="up" ref={ref} {...props} />
  }
)

export default Transition
