import styled from '@emotion/styled'
import { Fab, Toolbar } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { addUserLog as addUserLogApi } from '../../api'
import AgendaDrawer from '../../components/agenda/AgendaDrawer'
import { breakpoints } from '../../components/breakpoints'
import CheckVersion from '../../components/CheckVersion'
import ContactsDrawer from '../../components/contacts/ContactsDrawer'
import AnnounceRender from '../../components/globals/AnnounceRender'
import ChatPopup from '../../components/globals/Chat/ChatPopup'
import Footer from '../../components/globals/Footer/Footer'
import Header from '../../components/globals/Header/Header'
import IncomingCall from '../../components/globals/IncomingCall'
import Menu from '../../components/globals/Menu'
import ContactTakeAppointmentDialog from '../../components/modals/ContactTakeAppointmentDialog'
import NotificationsDialog from '../../components/modals/NotificationsDialog'
import OnboardingInfos from '../../components/modals/onboarding/OnboardingInfos'
import OnboardingOpenTokModal from '../../components/modals/onboarding/OnboardingOpenTokModal'
import OnboardingProfiling from '../../components/modals/onboarding/OnboardingProfiling'
import OnboardingWelcomeModal from '../../components/modals/onboarding/OnboardingWelcomeModal'
import ScrollTop from '../../components/ScrollTop'
import AppLoading from '../../components/ui/AppLoading/AppLoading'
import {
  IBindUser,
  IContactUserStatus,
  IUserLog,
  LogUserActions,
  LogUserUrlTypes,
  NotificationType
} from '../../entities'
import { useEffectOnce, useMagicStream, useThunkDispatch } from '../../hooks'
import useIsProfilingEnabled from '../../hooks/useIsProfilingEnabled'
import { IRootState } from '../../redux'
import settings from '../../settings'
import {
  loadAgenda,
  loadKeywordsXByType,
  loadKeywordsYByType,
  readFromStorage,
  setHasPassedTunnelFromStorage,
  setLocation
} from '../../store/app'
import { setCall, setInvitationInProgress, setNotification } from '../../store/chat'
import { setPresenceUsers, setPresenceUsersCount } from '../../store/participant'
import {
  loadPresenceUsers,
  loadTotalUsersConnected,
  updateContactsOnline
} from '../../store/participant/thunks'
import { BACKGROUND_PRIMARY_LIGHT } from '../../theme/colors'
import { CONTACTS_MENU_WIDTH, HEADER_HEIGHT, LEFT_MENU_WIDTH } from '../../theme/sizes'
import asyncLocalStorage from '../../utils/asyncLocalstorage'
import consoleUtils from '../../utils/consoleUtils'

type IProps = {
  children: React.ReactNode
}

interface IStep {
  step: number
  title: string
  opened: boolean
  finishStep: boolean
  onNext: () => void
  Component: React.FC<any>
}

const mappingPathnameUrlTypes: { [key: string]: LogUserUrlTypes } = {
  '/accueil': LogUserUrlTypes.home,
  '/participant': LogUserUrlTypes.visitorList,
  '/conference': LogUserUrlTypes.conferenceList,
  '/conference/1': LogUserUrlTypes.conference,
  '/exposant': LogUserUrlTypes.exhibitorList,
  '/exposant/2': LogUserUrlTypes.exhibitor,
  '/mes-infos': LogUserUrlTypes.home
}

const Layout: FC<IProps> = ({ children }) => {
  const intl = useIntl()
  const location = useLocation()
  const magicStream = useMagicStream()
  const [step, setStep] = useState(0)
  const [steps, setSteps] = useState<IStep[]>([])
  const user = useSelector((state: IRootState) => state.appState.user)
  const refreshRequired = useSelector((state: IRootState) => state.appState.refreshRequired)
  const loading = useSelector((state: IRootState) => state.appState.loading)
  const currentLocation = useSelector((state: IRootState) => state.appState.location)
  const hasPassedTunnel = useSelector((state: IRootState) => state.appState.hasPassedTunnel)
  const presenceUsers = useSelector((state: IRootState) => state.participantState.presenceUsers)
  const call = useSelector((state: IRootState) => state.chatState.call)
  const currentConference = useSelector((state: IRootState) => state.conferenceState.conference)
  const currentExhibitor = useSelector((state: IRootState) => state.exhibitorState.exhibitor)
  const invitationInProgress = useSelector(
    (state: IRootState) => state.chatState.invitationInProgress
  )
  const notification = useSelector((state: IRootState) => state.chatState.notification)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const isProfilingEnabled = useIsProfilingEnabled()
  const dispatch = useThunkDispatch()

  const onNext = useCallback(() => {
    setStep(step + 1)
    return true
  }, [step])

  const onFinish = useCallback(async () => {
    await dispatch(setHasPassedTunnelFromStorage(asyncLocalStorage, user?.id!))
    if (refreshRequired) {
      window.location.reload()
    }
    return true
  }, [user, dispatch, refreshRequired])

  const enterBind = useCallback(
    (usersEnter: IBindUser) => {
      if (usersEnter && user) {
        const filterUsersEnter = usersEnter.users.filter((userEnter) => userEnter.id !== user.id)
        if (filterUsersEnter.length > 0) {
          const filterUsersEnterIds = filterUsersEnter.map((filterUserLeave) => filterUserLeave.id)
          consoleUtils.log(
            '[EnterBind] > ids=',
            filterUsersEnterIds,
            ', groupId=',
            settings.eventGroupId,
            'usersEnter.display=',
            usersEnter.display
          )
          // set number of visitor
          dispatch(setPresenceUsersCount(usersEnter.count))
          if (usersEnter.display) {
            // add all enter users into presence users and remove those users from aroundUsers
            dispatch(
              setPresenceUsers(
                [
                  ...filterUsersEnter.map((filterUserEnter) => ({
                    id: filterUserEnter.id,
                    userPresence: {
                      isOnline: true,
                      user: {
                        ...filterUserEnter,
                        partner_id: {
                          name: filterUserEnter.partnerName || ''
                        }
                      }
                    },
                    userStatus:
                      filterUserEnter.status && (filterUserEnter.status as IContactUserStatus).id
                        ? filterUserEnter.status
                        : filterUserEnter.status
                          ? JSON.parse(filterUserEnter.status as string)
                          : null
                  })),
                  ...presenceUsers.filter(
                    (presenceUser) =>
                      !presenceUser.id || filterUsersEnterIds.indexOf(presenceUser.id) === -1
                  )
                ].slice(0, settings.list.contacts)
              )
            )
          }
          // update contacts online
          dispatch(updateContactsOnline(filterUsersEnterIds, true))
        }
      }
    },
    [presenceUsers, user, dispatch]
  )

  const leaveBind = useCallback(
    (usersLeave: IBindUser) => {
      if (usersLeave && user) {
        const filterUsersLeaveIds = usersLeave.users
          .filter((userLeave) => userLeave.id !== user.id)
          .map((filterUserLeave) => filterUserLeave.id)
        if (filterUsersLeaveIds.length > 0) {
          consoleUtils.log(
            '[LeaveBind] > ids=',
            filterUsersLeaveIds,
            ', groupId=',
            settings.eventGroupId
          )
          // set number of visitor
          dispatch(setPresenceUsersCount(usersLeave.count))
          // remove all leaving users from list
          dispatch(
            setPresenceUsers(
              presenceUsers.filter(
                (presenceUser) =>
                  !presenceUser.id || filterUsersLeaveIds.indexOf(presenceUser.id) === -1
              )
            )
          )
          // update contacts not online
          dispatch(updateContactsOnline(filterUsersLeaveIds, false))
        }
      }
    },
    [presenceUsers, user, dispatch]
  )

  const mappingPathnameDescription: { [key: string]: string } = useMemo(
    () => ({
      '/accueil': intl.formatMessage({ id: 'pathname.description.home' }),
      '/participant': intl.formatMessage({ id: 'pathname.description.visitors' }),
      '/conference': intl.formatMessage({ id: 'pathname.description.conferences' }),
      '/conference/': intl.formatMessage({ id: 'pathname.description.conference' }),
      '/exposant': intl.formatMessage({ id: 'pathname.description.exhibitors' }),
      '/exposant/': intl.formatMessage({ id: 'pathname.description.exhibitor' }),
      '/mes-infos': intl.formatMessage({ id: 'pathname.description.home' })
    }),
    [intl]
  )

  useEffect(() => {
    let currentStep = 0
    const isAppointmentApp = eventCo && eventCo.eventcoAppointment
    const currentSteps: IStep[] = []
    // first step
    currentSteps.push({
      title: 'onboarding.step0.mainTitle',
      step: currentStep,
      opened: step === currentStep,
      onNext,
      finishStep: false,
      Component: OnboardingInfos
    })
    if (isProfilingEnabled) {
      // profile step
      currentStep++
      currentSteps.push({
        title: 'onboarding.step2.mainTitle',
        step: currentStep,
        opened: step === currentStep,
        onNext,
        finishStep: false,
        Component: OnboardingProfiling
      })
    }
    if (settings.theme.onboarding && settings.theme.onboarding.video) {
      // video intro step
      currentStep++
      currentSteps.push({
        title: 'onboarding.step1.mainTitle',
        step: currentStep,
        opened: step === currentStep,
        onNext,
        finishStep: false,
        Component: OnboardingWelcomeModal
      })
    }
    if (!isAppointmentApp) {
      // camera step
      currentStep++
      currentSteps.push({
        title: 'onboarding.step3.mainTitle',
        step: currentStep,
        opened: step === currentStep,
        onNext,
        finishStep: false,
        Component: OnboardingOpenTokModal
      })
    }
    // define last step
    currentSteps[currentStep].onNext = onFinish
    currentSteps[currentStep].finishStep = true
    setSteps(currentSteps)
  }, [step, isProfilingEnabled, eventCo, onNext, onFinish])

  useEffect(() => {
    const startCurrentLocation = currentLocation.split('?')[0]
    const startLocation = location.pathname.split('?')[0]
    if (startCurrentLocation !== startLocation) {
      dispatch(setLocation(location.pathname))
      if (location.pathname !== '/') {
        if (
          !(location.pathname.indexOf('/exposant/') > -1 && !currentExhibitor) &&
          !(location.pathname.indexOf('/conference/') > -1 && !currentConference)
        ) {
          const logUser: IUserLog = {
            action: LogUserActions.view,
            description: `${mappingPathnameDescription[
              location.pathname.indexOf('/conference/') > -1
                ? '/conference/'
                : location.pathname.indexOf('/exposant/') > -1
                  ? '/exposant/'
                  : location.pathname
            ]
              }${location.pathname.indexOf('/exposant/') > -1 && currentExhibitor
                ? ' ' + currentExhibitor.name
                : location.pathname.indexOf('/conference/') > -1 && currentConference
                  ? ' ' + currentConference.title
                  : ''
              }`,
            url: location.pathname,
            urlType: mappingPathnameUrlTypes[location.pathname]
          }
          addUserLogApi(logUser)
        }
      }
    }
  }, [
    location,
    currentLocation,
    currentExhibitor,
    currentConference,
    mappingPathnameDescription,
    dispatch
  ])

  // useEffectOnce to cancel double call in app start
  useEffectOnce(() => {
    dispatch(readFromStorage(asyncLocalStorage, user?.id!))
    dispatch(loadPresenceUsers(settings.eventGroupId))
    dispatch(loadTotalUsersConnected())
    if (eventCo?.type_x_id?.id) {
      dispatch(loadKeywordsXByType(eventCo?.type_x_id))
    }
    if (eventCo?.type_y_id?.id) {
      dispatch(loadKeywordsYByType(eventCo?.type_y_id))
    }
    dispatch(loadAgenda())
  })

  useEffect(() => {
    if (window.pusher && settings.debug.events) {
      const channelEvent = window.pusher.channel(`group-${settings.eventGroupId}`)
      if (channelEvent) {
        channelEvent.bind('enter', enterBind)
        channelEvent.bind('leave', leaveBind)
      }
    }
    return () => {
      if (window.pusher && settings.debug.events) {
        const channelEvent = window.pusher.channel(`group-${settings.eventGroupId}`)
        if (channelEvent) {
          channelEvent.unbind('enter')
          channelEvent.unbind('leave')
        }
      }
    }
  }, [enterBind, leaveBind])

  useEffect(() => {
    let timer: number | null = null
    if (call && call.url) {
      timer = window.setTimeout(() => {
        dispatch(setCall({}))
      }, 30000) // call during 30 secondes
    }
    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [call, dispatch])

  useEffect(() => {
    let timer: number | null = null
    if (invitationInProgress) {
      timer = window.setTimeout(() => {
        // magicStream.success(intl.formatMessage({ id: 'invitation.sending' }))
        dispatch(setInvitationInProgress(false))
      }, 5000) // call during 5 secondes
    }
    return () => {
      if (timer) window.clearTimeout(timer)
    }
  }, [invitationInProgress, magicStream, intl, dispatch])

  // notification announce
  useEffect(() => {
    if (notification) {
      consoleUtils.log('[GLOBAL notification]=', notification)
      if (
        notification.message &&
        (notification.type === NotificationType.GLOBAL_ANNOUNCE ||
          notification.type === NotificationType.PAGE_ANNOUNCE)
      ) {
        const toastContent = (
          <AnnounceRender
            notification={{
              ...notification,
              params: { ...notification.params, createDate: new Date().toISOString() }
            }}
          />
        )
        // announce during 15s
        magicStream.announce(
          toastContent,
          notification.type,
          notification.params?.priority === 'high' /* withToast */
        )
        dispatch(setNotification(null))
      }
    }
  }, [notification, intl, magicStream, dispatch])

  if (loading || !user) return <AppLoading />

  return (
    <LayoutContainer>
      <CheckVersion />
      <StyledHeader />
      <StyledToolbar id="back-to-top-anchor" />
      {!window.lightTheme && <Menu />}
      <ChatPopup />
      <StyledContent light={window.lightTheme}>
        {children}
        <Footer />
      </StyledContent>
      <ContactsDrawer />
      <AgendaDrawer />
      <NotificationsDialog />
      {eventCo?.showTunnel &&
        !hasPassedTunnel &&
        steps.map((currentStep, index) => (
          <currentStep.Component
            key={index}
            step={currentStep.step}
            total={steps.length}
            title={currentStep.title}
            opened={currentStep.opened}
            onNext={currentStep.onNext}
            finishStep={currentStep.finishStep}
          />
        ))}
      <ScrollTop>
        <Fab
          color="primary"
          size="small"
          aria-label={intl.formatMessage({ id: 'ariaLabel.Layout.KeyboardArrowUpIcon' })}
        >
          <KeyboardArrowUpIcon
            titleAccess={intl.formatMessage({ id: 'titleAccess.Layout.KeyboardArrowUpIcon' })}
          />
        </Fab>
      </ScrollTop>
      {call && call.url && <IncomingCall />}
      <ContactTakeAppointmentDialog showDate />
    </LayoutContainer>
  )
}

// Style
const LayoutContainer = styled.div`
  & {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${BACKGROUND_PRIMARY_LIGHT};
  }
`

const StyledHeader = styled(Header)`
  & {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    background-color: #ffffff;
    z-index: 1000;
  }
`

const StyledToolbar = styled(Toolbar)`
  & {
    height: ${HEADER_HEIGHT};
  }
`

const StyledContent = styled.div<{ light: boolean }>`
  & {
    min-height: 70vh;
    flex-grow: 1;
    padding: 0 ${CONTACTS_MENU_WIDTH} 10px ${(props) => (props.light ? '10px' : LEFT_MENU_WIDTH)};

    @media (max-width: ${breakpoints.lg}) {
      padding: 0 15px;
    }

    @media (max-width: ${breakpoints.md}) {
      padding: 0 5px;
    }

    & > div:first-of-type {
      padding: ${(props) => (props.light ? '0 10px 0 0' : '0 10px')};
    }
  }
`

export default Layout
