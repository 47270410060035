import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IOTProperties } from '../entities'
import { IMediaStream } from './IMediaStream'
import { StreamContext } from './StreamSession'

interface IStreamPublisherProps {
  mediaStream?: IMediaStream
  className?: string
  style?: { [key: string]: string }
  properties: IOTProperties | undefined
  eventHandlers?: { [key: string]: (event: any) => void }
  publisherRef: React.RefObject<any>
  onInit?: () => void
  onPublish?: () => void
  onError?: (err: any) => void
  withoutPublish?: boolean
  pip?: boolean
}

export const StreamPublisher: React.FC<IStreamPublisherProps> = ({
  mediaStream,
  className,
  style,
  properties,
  eventHandlers,
  publisherRef,
  onInit,
  onPublish,
  onError,
  withoutPublish,
  pip
}) => {
  const context = useContext(StreamContext)
  const [currentMediaStream, setCurrentMediaStream] = useState<IMediaStream | null>(null)
  const [publishVideo, setPublishVideo] = useState<boolean | undefined>(properties?.publishVideo)
  const [publishAudio, setPublishAudio] = useState<boolean | undefined>(properties?.publishAudio)
  const [audioSource, setAudioSource] = useState<
    string | boolean | MediaStreamTrack | null | undefined
  >(properties?.audioSource)
  const [videoSource, setVideoSource] = useState<
    string | boolean | MediaStreamTrack | null | undefined
  >(properties?.videoSource)
  const [resolution, setResolution] = useState<
    '1280x960' | '1280x720' | '640x480' | '640x360' | '320x240' | '320x180' | undefined
  >(properties?.resolution)

  const createPublisher = useCallback(() => {
    if (!currentMediaStream) {
      return
    }

    currentMediaStream.createPublisher(
      publisherRef,
      properties,
      eventHandlers,
      pip,
      onInit,
      onError
    )
    if (!withoutPublish) {
      currentMediaStream.publish(pip, onPublish, onError)
    }
  }, [
    currentMediaStream,
    publisherRef,
    properties,
    eventHandlers,
    withoutPublish,
    pip,
    onError,
    onInit,
    onPublish
  ])

  const destroyPublisher = useCallback(() => {
    if (currentMediaStream) {
      currentMediaStream.destroyPublisher(pip)
    }
  }, [pip, currentMediaStream])

  useEffect(() => {
    setCurrentMediaStream(mediaStream || context.mediaStream)
  }, [mediaStream, context])

  useEffect(() => {
    if (currentMediaStream) {
      let update = false
      if (publishVideo !== properties?.publishVideo) {
        setPublishVideo(properties?.publishVideo)
        currentMediaStream.publishVideo(properties?.publishVideo || false)
      }
      if (publishAudio !== properties?.publishAudio) {
        setPublishAudio(properties?.publishAudio)
        currentMediaStream.publishAudio(properties?.publishAudio || false)
      }
      if (audioSource !== properties?.audioSource) {
        setAudioSource(properties?.audioSource)
        update = true
      }
      if (videoSource !== properties?.videoSource) {
        setVideoSource(properties?.videoSource)
        update = true
      }
      if (resolution !== properties?.resolution) {
        setResolution(properties?.resolution)
        update = true
      }
      if (update) {
        destroyPublisher()
        createPublisher()
      }
    }
  }, [
    properties,
    currentMediaStream,
    publishVideo,
    publishAudio,
    audioSource,
    videoSource,
    resolution,
    createPublisher,
    destroyPublisher
  ])

  useEffect(() => {
    createPublisher()

    return () => {
      destroyPublisher()
    }
    // to mount and unmount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMediaStream])

  return <div className={className} style={style} ref={publisherRef} />
}
