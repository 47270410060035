import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IAlertState } from './model'

const initialState: IAlertState = {
  alert: null
}

const actionHandlers: IReducerHandler<IAlertState> = {
  [ActionsTypes.SET_ALERT]: (
    state: IAlertState | undefined,
    { alert }: AnyAction
  ): IAlertState => ({
    ...state,
    alert
  }),
  [ActionsTypes.RESET_ALERT]: (): IAlertState => ({
    ...initialState
  })
}

export const reducer = createReducer(initialState, actionHandlers)
