import React from 'react'

const SvgShare: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 50 50" {...props}>
    <path
      fill={props.fill || 'currentColor'}
      fillRule="evenodd"
      d="M32.8571429,0 C28.1402143,0 24.2857143,3.85442857 24.2857143,8.57142857 C24.2857143,9.97357143 24.6206429,11.3065 25.2232143,12.4776429 L14.1517857,20.6472857 C12.6500714,19.3550714 10.6975714,18.5714286 8.57142857,18.5714286 C3.8545,18.5714286 0,22.4258571 0,27.1428714 C0,31.8598 3.8545,35.7143 8.57142857,35.7143 C10.8837857,35.7143 12.9859286,34.8037286 14.5312857,33.3035857 L32.9687143,40.0669429 C32.8967143,40.5123 32.8571429,40.9636571 32.8571429,41.4285857 C32.8571429,46.1455143 36.7116429,50.0000143 41.4285714,50.0000143 C46.1455,50.0000143 50,46.1455143 50,41.4285857 C50,36.7115857 46.1455,32.8571571 41.4285714,32.8571571 C38.1857143,32.8571571 35.3392857,34.6946571 33.8839286,37.3660857 L16.2722857,30.8928714 C16.8266429,29.7600143 17.1428571,28.4838714 17.1428571,27.1428714 C17.1428571,25.5777286 16.7215714,24.1230857 15.9821429,22.8571429 L26.942,14.7544286 C28.484,16.2345 30.5623571,17.1428571 32.8571429,17.1428571 C37.5740714,17.1428571 41.4285714,13.2883571 41.4285714,8.57142857 C41.4285714,3.85442857 37.5740714,0 32.8571429,0 L32.8571429,0 Z M32.8571429,2.85714286 C36.0299286,2.85714286 38.5714286,5.39857143 38.5714286,8.57142857 C38.5714286,11.7442143 36.0299286,14.2857143 32.8571429,14.2857143 C29.6843571,14.2857143 27.1428571,11.7442143 27.1428571,8.57142857 C27.1428571,5.39857143 29.6843571,2.85714286 32.8571429,2.85714286 Z M8.57142857,21.4285714 C11.7442143,21.4285714 14.2857143,23.9700143 14.2857143,27.1428714 C14.2857143,30.3156571 11.7442143,32.8571571 8.57142857,32.8571571 C5.39864286,32.8571571 2.85714286,30.3156571 2.85714286,27.1428714 C2.85714286,23.9700143 5.39864286,21.4285714 8.57142857,21.4285714 Z M41.4285714,35.7143 C44.6013571,35.7143 47.1428571,38.2557286 47.1428571,41.4285857 C47.1428571,44.6013714 44.6013571,47.1428714 41.4285714,47.1428714 C38.2557857,47.1428714 35.7142857,44.6013714 35.7142857,41.4285857 C35.7142857,38.2557286 38.2557857,35.7143 41.4285714,35.7143 L41.4285714,35.7143 Z"
    />
  </svg>
)

export default SvgShare
