import styled from '@emotion/styled'
import QueueIcon from '@material-ui/icons/Queue'
import React, { useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IMagicStream, MagicStreamStatus } from '../../entities'
import { useDateFns, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { sendInvitation } from '../../store/chat'
import { removeRoomQueue, setStartOfficeCall } from '../../store/room'
import { GRAY, VERY_LIGHT_GRAY, WHITE } from '../../theme/colors'
import consoleUtils from '../../utils/consoleUtils'
import UserAvatar from '../globals/UserAvatar/UserAvatar'
import StreamCallActions from './StreamCallActions'

type IStreamTableQueueProps = {
  stream: IMagicStream
}

const StreamTableQueue: React.FC<IStreamTableQueueProps> = ({ stream }) => {
  const intl = useIntl()
  const dateFns = useDateFns()
  const user = useSelector((state: IRootState) => state.appState.user)
  const location = useSelector((state: IRootState) => state.appState.location)
  const callEnded = useSelector((state: IRootState) => state.chatState.callEnded)
  const invitationInProgress = useSelector(
    (state: IRootState) => state.chatState.invitationInProgress
  )
  const startOfficeCall = useSelector((state: IRootState) => state.roomState.startOfficeCall)
  const onOfficeRoom = useSelector((state: IRootState) => state.roomState.onOfficeRoom)
  const dispatch = useThunkDispatch()

  const expirated = useMemo(
    () =>
      (stream.expirationDate ? new Date() > new Date(stream.expirationDate) : false) ||
      callEnded[stream.id] ||
      stream.status === MagicStreamStatus.ACCEPTED ||
      stream.status === MagicStreamStatus.DECLINED ||
      stream.status === MagicStreamStatus.REJECTED,
    [stream, callEnded]
  )

  const isMyOffice = useMemo(
    () =>
      user &&
        stream.subjectVideoRoom &&
        stream.subjectVideoRoom.userPresence &&
        stream.subjectVideoRoom.userPresence.user ?
        stream.subjectVideoRoom.userPresence.user.id === user.id
        : false,
    [stream, user])

  // auto handle next person in case of office
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (
        !invitationInProgress &&
        startOfficeCall &&
        stream.creatorUser &&
        location.indexOf('?salon') > -1 &&
        onOfficeRoom
      ) {
        consoleUtils.log('SEND invitation to location=', location, ', roomId=', stream.objectId)
        if (stream.objectId) {
          dispatch(removeRoomQueue(`${stream.objectId}`))
        }
        dispatch(setStartOfficeCall(false))
        dispatch(
          sendInvitation(
            stream.creatorUser.id,
            intl.formatMessage({ id: 'invitation.sended' }),
            location,
            stream.objectId
          )
        )
      }
    }, 1000)
    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [stream, intl, invitationInProgress, startOfficeCall, onOfficeRoom, location, dispatch])

  return (
    <StreamTableQueueContainer>
      <StyledContent>
        {!stream.creatorUser && !stream.subjectVideoRoom && (
          <StyledIcon className="icon-hover">
            <QueueIcon fontSize="large" htmlColor="orange" />
          </StyledIcon>
        )}
        {stream.creatorUser && (
          <UserAvatar
            popper={{ placement: 'left' }}
            user={stream.creatorUser}
            showDetails
            disabledAnimation
          />
        )}
        {!stream.creatorUser &&
          stream.subjectVideoRoom &&
          stream.subjectVideoRoom.userPresence &&
          stream.subjectVideoRoom.userPresence.user && (
            <UserAvatar
              popper={{ placement: 'left' }}
              user={stream.subjectVideoRoom.userPresence.user}
              showDetails
              disabledAnimation
            />
          )}
        <StreamTableQueueMessage expirated={expirated}>
          <FormattedMessage
            id={`stream.table_queue.${stream.status === MagicStreamStatus.RUNNING && onOfficeRoom
              ? 'waitingList'
              : stream.status === MagicStreamStatus.ACCEPTED && isMyOffice ?
                'inMyOffice'
                : stream.status
              }`}
            values={{
              date: dateFns.formatDistanceToNow(stream.writeDate || stream.createDate)
            }}
          />
        </StreamTableQueueMessage>
      </StyledContent>
      {(stream.status === MagicStreamStatus.INVITED ||
        (stream.status === MagicStreamStatus.RUNNING && !onOfficeRoom)) && (
          <StreamCallActions stream={stream} expirated={expirated} />
        )}
      <StyledDate>{dateFns.formatDistanceToNow(stream.writeDate || stream.createDate)}</StyledDate>
    </StreamTableQueueContainer>
  )
}

const StreamTableQueueContainer = styled.div`
  padding: 7px 0;
  transition: all 0.3s;

  &:hover {
    span {
      opacity: 1;
    }
    .icon-hover {
      background-color: ${WHITE};
    }
  }
`

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  font-size: 12px;
`

const StyledIcon = styled.div`
  background-color: ${VERY_LIGHT_GRAY};
  border-radius: 50%;
  padding: 5px;
`

const StreamTableQueueMessage = styled.span<{ expirated: boolean }>`
  ${(props) => props.expirated && 'text-decoration: line-through;'}
  margin-left: 12px;
`

const StyledDate = styled.span`
  position: absolute;
  right: 3px;
  bottom: 1px;
  opacity: 0;
  font-size: 10px;
  color: ${GRAY};
`

export default StreamTableQueue
