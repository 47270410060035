import styled from '@emotion/styled'
import { Badge, BadgeProps } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import React from 'react'
import { useIntl } from 'react-intl'

interface IProps extends BadgeProps {
  count?: number
}

const Viewers: React.FC<IProps> = ({ count, ...props }) => {
  const intl = useIntl()

  return (
    <StyledBadge badgeContent={count || 0} color="primary" showZero {...props}>
      <VisibilityIcon
        titleAccess={intl.formatMessage({ id: 'titleAccess.Viewer.VisibilityIcon' })}
      />
    </StyledBadge>
  )
}
const StyledBadge = styled(Badge)`
  margin: 0 1em;
`

export default Viewers
