import { IAlert } from '../../entities/alert'

export enum ActionsTypes {
  SET_ALERT = 'SET_ALERT',
  RESET_ALERT = 'RESET_ALERT'
}

export interface IAlertState {
  alert: IAlert | null
}
