import { IEnterRoomZoom } from '../../entities'

export enum ActionsTypes {
  SET_LOADING_ZOOM = 'SET_LOADING_ZOOM',
  SET_ZOOM_ROOM = 'SET_ZOOM_ROOM',
  SET_ERROR_ZOOM = 'SET_ERROR_ZOOM'
}

export interface IZoomState {
  loadingZoom: boolean
  zoomRoom?: IEnterRoomZoom | null
  errorZoom?: string
}
