import styled from '@emotion/styled'
import { alpha } from '@material-ui/core'
import React, { FC } from 'react'
import { IConversation } from '../../../entities'
import { BLACK_BLUE } from '../../../theme/colors'
import VisitorInformations from '../../visitor/VisitorInformations'

interface IChatHeaderProps {
  conversation: IConversation
}

const ChatHeader: FC<IChatHeaderProps> = ({ conversation }) => {
  return (
    <StyledChatHeader>
      <VisitorInformations hasUserAvatar visitor={conversation.contact} />
    </StyledChatHeader>
  )
}

const StyledChatHeader = styled.div`
  & {
    padding: 5px;
    background: ${alpha(BLACK_BLUE, 0.05)};
  }
`

export default ChatHeader
