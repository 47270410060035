export enum ActionsTypes {
  SET_TOKEN = 'SET_TOKEN',
  RESET_TOKEN = 'RESET_TOKEN',
  SET_AUTH_LOADING = 'SET_AUTH_LOADING',
  SET_AUTH_ERROR = 'SET_AUTH_ERROR'
}

export interface IAuthState {
  token: string | null
  loading: boolean
  error?: string
}

export const TOKEN_KEY = 'idealco_auth'
