import styled from '@emotion/styled'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as DemoTagIcon } from '../../../assets/images/icons/demo.svg'
import { PRIMARY, SECONDARY, WHITE } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'

const DemoTag = () => {
  return (
    <StyledDemoTag>
      <FormattedMessage id="tag.demo" />
      <DemoTagIcon />
    </StyledDemoTag>
  )
}

const StyledDemoTag = styled.span`
  & {
    position: absolute;
    top: 12px;
    left: 12px;
    padding: 5px 8px;
    color: #fff;
    background-color: ${PRIMARY};
    background-image: linear-gradient(45deg, ${SECONDARY}, ${PRIMARY});
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    border-radius: ${BORDER_RADIUS_CARD};
    z-index: 1;
    display: flex;
    align-items: center;

    svg {
      height: 15px;
      color: ${WHITE};
    }
  }
`

export default DemoTag
