import { IAuthResponse } from '../entities'
import settings from '../settings'
import { apiFormRequest } from './requests'

export const getToken = async () => {
  const response = await apiFormRequest<IAuthResponse>(
    'GET',
    `${settings.url.auth}/user/token`,
    {},
    false
  )
  return response
}
