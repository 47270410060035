import styled from '@emotion/styled'
import { alpha, Chip } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { IAgenda } from '../../entities/agenda'
import { useDateFns } from '../../hooks'
import { browserHistory } from '../../redux'
import { PRIMARY_COLOR } from '../../theme'
import { BLACK_BLUE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import AgendaItemMessage from './AgendaItemMessage'

interface IAgendaConferenceProps {
  item: IAgenda
}

const AgendaConference: FC<IAgendaConferenceProps> = ({ item }) => {
  const intl = useIntl()
  const dateFns = useDateFns()

  const handleClick = () => {
    browserHistory.push(`/conference/${item.id}`)
  }

  return (
    <StyledAgendaConference>
      <ConferenceItem>
        <div>
          <StyledChipHours
            label={`${dateFns.format(
              item.dateStart,
              intl.formatMessage({ id: 'date.format.duration' })
            )} - ${dateFns.format(
              item.dateEnd,
              intl.formatMessage({ id: 'date.format.duration' })
            )}`}
            color="secondary"
          />
          <StyledChip
            label={
              <>
                <span title={item.summary.length > 20 ? item.summary : undefined}>
                  {item.summary.length > 20 ? `${item.summary.substring(0, 17)}...` : item.summary}
                </span>
                <VisibilityIcon fontSize="small" />
              </>
            }
            color="primary"
            onClick={handleClick}
          />
        </div>
        {item.description && (
          <AgendaItemMessage right>{item.description}...</AgendaItemMessage>
        )}
      </ConferenceItem>
    </StyledAgendaConference>
  )
}

const StyledAgendaConference = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 5px 3px 10px;
  border-radius: ${BORDER_RADIUS_CARD};
  margin-bottom: 10px;

  &:hover {
    transition: all 0.3s;
    background-color: ${alpha(BLACK_BLUE, 0.05)};

    &:after {
      background-color: ${PRIMARY_COLOR};
    }
  }
`

const ConferenceItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  & > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
`

const StyledChip = styled(Chip)`
  max-width: 250px;
  cursor: pointer;

  & > span.MuiChip-label {
    white-space: break-spaces;

    & > span {
      margin-right: 5px;
    }
  }
`

const StyledChipHours = styled(Chip)`
  max-width: 250px;
  margin-right: 10px;
`

export default AgendaConference
