import { alpha } from '@material-ui/core'
import { BLACK_BLUE } from './colors'

export const HEADER_HEIGHT = '79px'

export const LEFT_MENU_WIDTH = '86px'

export const LIVE_CARD_SMALL_HEIGHT = '140px'
export const LIVE_CARD_LARGE_HEIGHT = '300px'

export const CONTACTS_MENU_WIDTH = '350px'

export const FILTERS_HEIGHT_BUTTON_MOBILE = '50px'

export const BORDER_RADIUS_CARD = '8px'

export const HEADER_CONTACTS_HEIGHT = '45px'

export const MAGIC_STREAMS_HEIGHT = '200px'

export const BOX_SHADOW = `0 2px 1px -1px ${alpha(BLACK_BLUE, 0.14)}, 0px 1px 1px 0px ${alpha(
  BLACK_BLUE,
  0.14
)}, 0px 1px 3px 0px ${alpha(BLACK_BLUE, 0.14)}`
