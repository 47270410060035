import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Box, Button, FormControl, IconButton } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'
import EventIcon from '@material-ui/icons/Event'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IContactUser } from '../../entities'
import { useDateFns, useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { setErrorSending, setSended, takeAppointment } from '../../store/chat'
import { formatName, getPlainText } from '../../utils'
import UserAvatar from '../globals/UserAvatar/UserAvatar'
import UserAvatarGroup from '../globals/UserAvatarGroup/UserAvatarGroup'
import SendDialog from '../modals/SendDialog'
import DateTimePicker from '../ui/DateTimePicker/DateTimePicker'
import Editor from '../ui/Editor/Editor'
import FormContainer from '../ui/Form/FormContainer'
import { Label } from '../ui/LabelWrapper/LabelWrapper'

interface IExhibitorTakeAppointmentProps {
  exhibitorId?: number
  moderators?: IContactUser[]
  showDate?: boolean
  iconButton?: boolean
  title?: string
  disabled?: boolean
  onlyMessage?: boolean
  appendMessageText?: string
}

const ExhibitorTakeAppointment: React.FC<IExhibitorTakeAppointmentProps> = ({
  exhibitorId,
  moderators,
  showDate,
  iconButton,
  title,
  disabled,
  onlyMessage,
  appendMessageText
}) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const dateFns = useDateFns()
  const magicStream = useMagicStream()
  const [message, setMessage] = useState('')
  const [date, setDate] = useState(new Date())
  const [appointment, setAppointment] = useState(false)
  const [selectedModerators, setSelectedModerators] = useState<IContactUser[]>([])
  const user = useSelector((state: IRootState) => state.appState.user)
  const sending = useSelector((state: IRootState) => state.chatState.sending)
  const sended = useSelector((state: IRootState) => state.chatState.sended)
  const errorSending = useSelector((state: IRootState) => state.chatState.errorSending)
  const dispatch = useThunkDispatch()

  const memoizedModerators = useMemo(() => selectedModerators.map((moderator) => moderator.id), [
    selectedModerators
  ])

  const handleTakeAppointment = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    // Si appendMessageText est défini alors = product
    if (appendMessageText) {
      sendDataToGTM({ event: 'product-info', productName: appendMessageText })
    } else {
      sendDataToGTM({ event: 'open-appointment', label: 'Exhibitor page' })
    }
    setAppointment(true)
  }

  const toggleModerator = (moderator: IContactUser) => {
    if (selectedModerators.find((mod) => mod.id === moderator.id)) {
      setSelectedModerators(selectedModerators.filter((mod) => mod.id !== moderator.id))
    } else {
      setSelectedModerators([...selectedModerators, moderator])
    }
  }

  const send = () => {
    if (selectedModerators.length && user) {
      sendDataToGTM({
        event: appendMessageText ? 'product-appointment' : 'send-appointment',
        appointmentProduct: appendMessageText ? appendMessageText : 'appointment',
        appointmentModerator: formatName(selectedModerators[0]).full,
        appointmentDate: `${dateFns.format(
          date.toString(),
          intl.formatMessage({ id: 'date.format' })
        )}`,
        appointmentMessage: `${getPlainText(message)}`
      })
      const chatMessage = intl.formatMessage(
        { id: 'product.ask' },
        {
          user: `<b>${formatName(user).full}</b>`,
          message: `<i>${getPlainText(message)}</i>`,
          date: dateFns.format(date.toString(), intl.formatMessage({ id: 'date.format' })),
          product: appendMessageText ? `<b>${appendMessageText}</b>` : ''
        }
      )
      dispatch(
        takeAppointment(
          memoizedModerators,
          onlyMessage ? chatMessage : getPlainText(message),
          date,
          exhibitorId ? `/exposant/${exhibitorId}` : undefined,
          onlyMessage
        )
      )
    }
  }

  useEffect(() => {
    if (sended && appointment) {
      setAppointment(false)
      setSelectedModerators([])
      setMessage('')
      setDate(new Date())
      dispatch(setSended(false))
    }
  }, [intl, sended, appointment, dispatch])

  useEffect(() => {
    if (errorSending && appointment) {
      magicStream.error(errorSending)
      dispatch(setErrorSending(undefined))
    }
  }, [errorSending, appointment, magicStream, dispatch])

  const WrapperComponent = !iconButton ? StyledDiv : Box

  if (moderators && moderators.length === 0) return <></>

  return (
    <WrapperComponent>
      {iconButton ? (
        <StyledIconButton
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleTakeAppointment}
          color="inherit"
          aria-label={intl.formatMessage({ id: 'ariaLabel.ProductCard.ChatIcon' })}
          disabled={disabled}
        >
          <ChatIcon
            fontSize="large"
            titleAccess={intl.formatMessage({ id: 'titleAccess.ProductCard.ChatIcon' })}
          />
        </StyledIconButton>
      ) : (
        <StyledButton
          variant="contained"
          startIcon={<EventIcon fontSize="small" />}
          color="primary"
          onClick={handleTakeAppointment}
          disabled={disabled}
        >
          <FormattedMessage id="button.appointment" />
        </StyledButton>
      )}
      <SendDialog
        title={title ?? intl.formatMessage({ id: 'button.appointment' })}
        open={appointment}
        disableSend={!message || !date || memoizedModerators.length === 0 || sending}
        sending={sending}
        sendEvent={send}
        cancelEvent={() => setAppointment(false)}
        maxWidth="sm"
        fullWidth
      >
        <FormContainer>
          {moderators && (
            <FormControl>
              <Label>
                <FormattedMessage id="rdv.to" />
              </Label>
              <UserAvatarGroup total={moderators.length}>
                {moderators.map((moderator, index) => (
                  <UserAvatar
                    key={index}
                    user={moderator}
                    showDetails
                    isModerator={!!selectedModerators.find((mod) => mod.id === moderator.id)}
                    onClick={() => toggleModerator(moderator)}
                  />
                ))}
              </UserAvatarGroup>
            </FormControl>
          )}
          <div>
            <Label>
              <FormattedMessage id="rdv.title" />
            </Label>
            <Editor
              content={message}
              readonly={sending}
              placeholder={intl.formatMessage({ id: 'rdv.placeholder' })}
              onContentChange={setMessage}
              startupFocus
            />
          </div>
          {showDate && (
            <FormControl>
              <DateTimePicker
                id="date-time-picker-appointment"
                label={intl.formatMessage({ id: 'rdv.date.title' })}
                format="dd/MM/yyyy HH:mm"
                fullWidth
                minDate={new Date()}
                minDateMessage="La date ne peut pas être inférieur à aujourd'hui"
                showTodayButton
                disabled={sending}
                value={date}
                onChange={(dateTime: MaterialUiPickersDate) => {
                  if (dateTime) setDate(dateTime)
                }}
              />
            </FormControl>
          )}
        </FormContainer>
      </SendDialog>
    </WrapperComponent>
  )
}

const StyledDiv = styled.div`
  margin-bottom: 20px;
`

const StyledButton = styled(Button)`
  text-transform: uppercase;
  text-align: left;
`

const StyledIconButton = styled(IconButton)`
  & {
    min-width: auto;
  }
`

export default ExhibitorTakeAppointment
