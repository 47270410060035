import styled from '@emotion/styled'
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { urlRegex } from '../../../utils'
import Loading from '../../Loading'

interface IChatMessageProps {
  id: number
  message: string
  sending: boolean
  odd: boolean
}

const ChatMessage: FC<IChatMessageProps> = ({ id, message, sending, odd }) => {
  const intl = useIntl()
  const [formattedMessage, setFormattedMessage] = useState('')
  const [htmlMessage, setHtmlMessage] = useState(false)

  useEffect(() => {
    if (message) {
      const regex = new RegExp(urlRegex)
      const urls = message.match(regex)
      if (urls) {
        // url in message
        setHtmlMessage(true)
        setFormattedMessage(
          message.indexOf('</a>') === -1
            ? message.replace(regex, (url) => `<a href="${url}" target="_blank">${url}</a>`)
            : message
        )
      } else if (message.indexOf('<br />') > -1) {
        // special case withtextarea br
        setHtmlMessage(true)
        setFormattedMessage(message)
      } else if (message.indexOf('<b>') > -1 || message.indexOf('<i>') > -1) {
        // fonts special case
        setHtmlMessage(true)
        setFormattedMessage(message)
      } else {
        // display message
        setFormattedMessage(message)
      }
    }
  }, [id, message, intl])

  return (
    <StyledMessage odd={odd}>
      {htmlMessage ? (
        <span dangerouslySetInnerHTML={{ __html: formattedMessage }}></span>
      ) : (
        <span>{formattedMessage}</span>
      )}
      {sending && <Loading size="1rem" />}
    </StyledMessage>
  )
}

const StyledMessage = styled.div<{ odd: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.odd ? 'row-reverse' : 'row')};
`

export default ChatMessage
