import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import EventIcon from '@material-ui/icons/Event'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { IMagicStream, MagicStreamStatus } from '../../entities'
import { useMagicStream, useThunkDispatch } from '../../hooks'
import { IRootState } from '../../redux'
import { sendResponse, setErrorSending } from '../../store/chat'
import UserAvatar from '../globals/UserAvatar/UserAvatar'
import RescheduleDialog from '../modals/RescheduleDialog'
import AnimatedIconButton from '../ui/AnimatedIconButton'

type IStreamAppointmentActionsProps = {
  stream: IMagicStream
  withAvatar?: boolean
}

const StreamAppointmentActions: React.FC<IStreamAppointmentActionsProps> = ({ stream, withAvatar }) => {
  const intl = useIntl()
  const magicStream = useMagicStream()
  const [open, setOpen] = useState(false)
  const user = useSelector((state: IRootState) => state.appState.user)
  const sending = useSelector((state: IRootState) => state.chatState.sending)
  const errorSending = useSelector((state: IRootState) => state.chatState.errorSending)
  const dispatch = useThunkDispatch()

  const isMe = useMemo(() => stream.creatorUser && user && stream.creatorUser.id === user.id, [
    stream,
    user
  ])

  const onStartAppointment = () => {
    if (stream) {
      dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'appointment.accept'
          }),
          1,
          undefined, // stream.creatorUser && stream.creatorUser.id,
          stream.id
        )
      )
    }
  }

  const onEndAppointment = () => {
    if (stream) {
      dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'appointment.refuse'
          }),
          0,
          undefined, // stream.creatorUser && stream.creatorUser.id,
          stream.id
        )
      )
    }
  }

  const onReschedule = (newDate: string) => {
    if (stream) {
      dispatch(
        sendResponse(
          intl.formatMessage({
            id: 'appointment.reschedule'
          }),
          2,
          undefined, // stream.creatorUser && stream.creatorUser.id,
          stream.id,
          newDate
        )
      )
    }
  }

  useEffect(() => {
    if (errorSending) {
      magicStream.error(errorSending)
      dispatch(setErrorSending(undefined))
    }
  }, [errorSending, magicStream, dispatch])

  return (
    <StreamActions>
      {withAvatar && !isMe && stream.creatorUser && (
        <UserAvatar
          popper={{ placement: 'left' }}
          user={stream.creatorUser}
          showDetails
          disabledAnimation
        />
      )}
      {withAvatar && isMe && stream.subjectUser && (
        <UserAvatar
          popper={{ placement: 'left' }}
          user={stream.subjectUser}
          showDetails
          disabledAnimation
        />
      )}
      {stream.status === MagicStreamStatus.INVITED && (
        <>
          <AnimatedIconButton
            onClick={onStartAppointment}
            title={intl.formatMessage({ id: 'appointment.accept' })}
            disabled={sending}
          >
            <DoneIcon
              fontSize="large"
              htmlColor="green"
              titleAccess={intl.formatMessage({ id: 'titleAccess.Appointment.DoneIcon' })}
            />
          </AnimatedIconButton>
          <IconButton
            onClick={onEndAppointment}
            title={intl.formatMessage({ id: 'appointment.refuse' })}
            disabled={sending}
          >
            <CloseIcon
              fontSize="large"
              htmlColor="red"
              titleAccess={intl.formatMessage({ id: 'titleAccess.Appointment.CloseIcon' })}
            />
          </IconButton>
          {stream.creatorUser && stream.dueDate && (
            <>
              <IconButton
                onClick={() => setOpen(true)}
                title={intl.formatMessage({ id: 'appointment.reschedule' })}
                disabled={sending}
              >
                <EventIcon
                  fontSize="large"
                  htmlColor="gray"
                  titleAccess={intl.formatMessage({ id: 'titleAccess.Appointment.EventIcon' })}
                />
              </IconButton>
              <RescheduleDialog
                userId={stream.creatorUser.id}
                initDate={new Date(stream.dueDate)}
                open={open}
                setOpen={setOpen}
                onReschedule={onReschedule}
              />
            </>
          )}
        </>
      )}
    </StreamActions>
  )
}

const StreamActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  & svg {
    height: 30px;
    width: 30px;
  }

  & > .MuiFormControl-root {
    margin-top: 0;
    font-size: 12px;
  }
`

export default StreamAppointmentActions
