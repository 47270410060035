import { css } from '@emotion/react'
import styled from '@emotion/styled'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import React from 'react'

interface ILoadingProps extends CircularProgressProps {
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'center'
}

const Loading: React.FC<ILoadingProps> = ({ position, ...props }) => {
  return (
    <StyledLoading position={position} size={props.size}>
      <CircularProgress size={props.size || '1.5rem'} color={props.color || 'secondary'} />
    </StyledLoading>
  )
}

// language=SCSS
const CssTopLeft = css`
  & {
    position: absolute;
    top: 0;
    left: 0;
  }
`

// language=SCSS
const CssTopRight = css`
  & {
    position: absolute;
    top: 0;
    right: 0;
  }
`

// language=SCSS
const CssBottomLeft = css`
  & {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

// language=SCSS
const CssBottomRight = css`
  & {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

// language=SCSS
const CssCenter = css`
  & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

// language=SCSS
const StyledLoading = styled.div<{ position?: string; size?: string | number }>`
  & {
    z-index: 1000;
    ${(props) => (props.size ? `height: calc(${props.size} + 5px)` : '')};
    ${(props) => (props.size ? `width: calc(${props.size} + 5px)` : '')};
    padding: ${(props) => (props.position ? '15px' : 0)};
    position: ${(props) => (!props.position ? 'relative' : 'absolute')};
    ${(props) => (props.position === 'topLeft' ? CssTopLeft : '')}
    ${(props) => (props.position === 'topRight' ? CssTopRight : '')}
    ${(props) => (props.position === 'bottomLeft' ? CssBottomLeft : '')}
    ${(props) => (props.position === 'bottomRight' ? CssBottomRight : '')}
    ${(props) => (props.position === 'center' ? CssCenter : '')}
  }
`

export default Loading
