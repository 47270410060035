import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import VisitorCard from '../../components/cards/VisitorCard'
import AppPagination from '../../components/globals/AppPagination/AppPagination'
import LoadingCard from '../../components/LoadingCard'
import VisitorFiltersSearch from '../../components/visitor/VisitorFiltersSearch'
import { IContact } from '../../entities'

interface IContactsListProps {
  contacts: { total: number; items: IContact[] }
  loading: boolean
  count: number
  page: number
  setPage: (page: number) => void
  setFilterSearch: (value: string) => void
  filterSearch: string
  setFilterCustom: (value: {
    [key: string]: number | string | { id: number; name: string }
  }) => void
  filterCustom: { [key: string]: number | string | { id: number; name: string } }
  setFilterIsOnline: (value: boolean) => void
  filterIsOnline: boolean
  setFilterIsContact: (value: boolean) => void
  filterIsContact: boolean
  filterTags: { [key: string]: boolean }
  setFilterTags: (value: { [key: string]: boolean }) => void
  filterKeywordsX: { [key: string]: boolean }
  setFilterKeywordsX: (value: { [key: string]: boolean }) => void
  filterKeywordsY: { [key: string]: boolean }
  setFilterKeywordsY: (value: { [key: string]: boolean }) => void
  filterUserRoles?: string
  setFilterUserRoles?: (value: string) => void
}

const ContactsList: FC<IContactsListProps> = ({
  contacts,
  loading,
  count,
  page,
  setPage,
  setFilterSearch,
  filterSearch,
  setFilterCustom,
  filterCustom,
  setFilterIsOnline,
  filterIsOnline,
  setFilterIsContact,
  filterIsContact,
  filterTags,
  setFilterTags,
  filterKeywordsX,
  setFilterKeywordsX,
  filterKeywordsY,
  setFilterKeywordsY,
  filterUserRoles,
  setFilterUserRoles
}) => {
  return (
    <StyledFilters>
      <Grid container spacing={5}>
        <Grid item xs={12} lg={4} xl={3}>
          <VisitorFiltersSearch
            setPage={setPage}
            setFilterSearch={setFilterSearch}
            filterSearch={filterSearch}
            setFilterCustom={setFilterCustom}
            filterCustom={filterCustom}
            setFilterIsOnline={setFilterIsOnline}
            filterIsOnline={filterIsOnline}
            setFilterIsContact={setFilterIsContact}
            filterIsContact={filterIsContact}
            filterTags={filterTags}
            setFilterTags={setFilterTags}
            filterKeywordsX={filterKeywordsX}
            setFilterKeywordsX={setFilterKeywordsX}
            filterKeywordsY={filterKeywordsY}
            setFilterKeywordsY={setFilterKeywordsY}
            filterUserRoles={filterUserRoles}
            setFilterUserRoles={setFilterUserRoles}
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <StyledGridContainerList container spacing={1}>
            {loading &&
              contacts.total === 0 &&
              Array(10)
                .fill(0)
                .map((value, index) => (
                  <Grid key={index} item xs={12}>
                    <LoadingCard cardType="visitor" />
                  </Grid>
                ))}
            {contacts.total > 0 &&
              contacts.items.map((participant, index) => (
                <Grid key={index} item xs={12}>
                  <VisitorCard visitor={participant} extendedNote />
                </Grid>
              ))}
            {!loading && contacts.total === 0 && (
              <StyledGridEmptyList item xs={12}>
                <Alert severity="warning">
                  <FormattedMessage id="visitor.list.empty" />
                </Alert>
              </StyledGridEmptyList>
            )}
          </StyledGridContainerList>
          {contacts.total > 0 && (
            <AppPagination total={contacts.total} count={count} page={page} setPage={setPage} />
          )}
        </Grid>
      </Grid>
    </StyledFilters>
  )
}

const StyledFilters = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }
`

const StyledGridContainerList = styled(Grid)`
  & {
    position: relative;
    margin-bottom: 1em;
  }
`

const StyledGridEmptyList = styled(Grid)`
  & {
    margin-top: 1em;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
`

export default ContactsList
