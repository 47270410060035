import { CircularProgress } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getParticipantsWithMe as getParticipantsWithMeApi } from '../../api'
import { IContactAutocomplete, IContactUser } from '../../entities'
import { IRootState } from '../../redux'
import { formatName } from '../../utils'
import UserAvatar from '../globals/UserAvatar/UserAvatar'

const filter = createFilterOptions<IContactAutocomplete>()

type AutocompleteVisitorsProps = {
  contact?: IContactUser
  onChange?: (newUser: IContactUser | undefined) => void
}

export default function AutocompleteVisitors({ contact, onChange }: AutocompleteVisitorsProps) {
  const intl = useIntl()
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<IContactAutocomplete[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const visitors = useSelector((state: IRootState) => state.participantState.participants)

  const loading = useMemo(() => isLoading && open && options.length === 0 && inputValue !== '', [
    isLoading,
    open,
    options,
    inputValue
  ])

  useEffect(() => {
    if (inputValue === '') {
      setOptions(
        visitors.items.map((visitor) => ({
          id: visitor.id,
          contact: visitor.userPresence.user
        }))
      )
      return undefined
    }

    if (!open) {
      return undefined
    }

    const timer = window.setTimeout(async () => {
      setIsLoading(true)
      const visitorsSearch = await getParticipantsWithMeApi(1, inputValue)
      setIsLoading(false)
      let newOptions = [] as IContactAutocomplete[]

      if (contact) {
        newOptions = [{ id: contact.id, contact }]
      }

      if (visitorsSearch.items) {
        const res = visitorsSearch.items.map((result) => ({
          id: result.id,
          contact: result.userPresence.user
        }))
        newOptions = [...newOptions, ...res]
      }

      setOptions(newOptions)
    }, 250)

    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [visitors, inputValue, open, contact])

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <div>
      <Autocomplete
        defaultValue={contact ? { id: contact!.id, contact } : undefined}
        onChange={async (event, newValue, reason, details) => {
          if (reason === 'clear') {
            onChange && onChange(undefined)
          } else if (reason === 'select-option') {
            const choice = newValue as IContactAutocomplete
            const contactUser = { ...choice.contact, id: choice.id }
            choice.id && onChange && onChange(contactUser as IContactUser)
          }
        }}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        filterOptions={(opts, params) => {
          const filtered = filter(opts, params)
          return filtered
        }}
        clearOnBlur
        handleHomeEndKeys
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          // Regular option
          if (option.contact) {
            return formatName(option.contact).full
          }
          return ''
        }}
        renderOption={(option) => (
          <>
            {option.contact && (
              <>
                <UserAvatar user={option.contact} disabledAnimation />
                &nbsp;
                <span>{formatName(option.contact).full}</span>
              </>
            )}
          </>
        )}
        style={{ width: '100%' }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={intl.formatMessage({ id: 'autocomplete.contacts.label' })}
            placeholder={intl.formatMessage({ id: 'autocomplete.contacts.label' })}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
            helperText={intl.formatMessage({ id: 'autocomplete.contacts.helper' })}
          />
        )}
        renderTags={() => false}
        options={options}
        loading={loading}
      />
    </div>
  )
}
