import React from 'react'

const SvgOption: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <path
        fill={props.fill || 'currentColor'}
        fillRule="evenodd"
        d="M25.06 12.121A6.06 6.06 0 1 1 25.06 0a6.06 6.06 0 0 1 0 12.121zm0 18.182a6.06 6.06 0 1 1 0-12.121 6.06 6.06 0 0 1 0 12.121zm0 19.697a6.06 6.06 0 1 1 0-12.121 6.06 6.06 0 0 1 0 12.121z"
      />
    </svg>
  )
}

export default SvgOption
