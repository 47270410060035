import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { alpha } from '@material-ui/core'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IChat } from '../../../entities/chat'
import { useDateFns } from '../../../hooks'
import { IRootState } from '../../../redux'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../theme'
import { BACKGROUND_SECONDARY_LIGHT, BLACK_BLUE, GRAY } from '../../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../../theme/sizes'
import { formatName } from '../../../utils'
import Loading from '../../Loading'
import InvisibleButton from '../../ui/InvisibleButton/InvisibleButton'
import UserAvatar from '../UserAvatar/UserAvatar'
import ChatMessage from './ChatMessage'

interface IChatListProps {
  loading?: boolean
  fixed?: boolean
  chats: IChat[]
  newMessage: boolean
  sendings: IChat[]
  setNewMessage: (newer: boolean) => void
  onScrollTop?: () => void
}

const ChatList: FC<IChatListProps> = ({
  loading,
  fixed,
  chats,
  newMessage,
  sendings,
  setNewMessage,
  onScrollTop
}) => {
  const refList = React.createRef<HTMLUListElement>()
  const refBottom = React.createRef<HTMLDivElement>()
  const dateFns = useDateFns()
  const [hasScroll, setScroll] = useState(false)
  const [isScrollTop, setScrollTop] = useState(false)
  const user = useSelector((state: IRootState) => state.appState.user)
  const currentConversation = useSelector(
    (state: IRootState) => state.chatState.currentConversation
  )

  const isNextMessageSameUser = (index: number) => {
    // message inférieur à 2 minutes sont stackés (liste inversée, différence négative)
    return (
      index > 0 &&
      memoChats[index].user.id === memoChats[index - 1].user.id &&
      dateFns.differenceInMinutes(memoChats[index].createDate, memoChats[index - 1].createDate) >=
      -2
    )
  }

  const isPrevMessageSameUser = (index: number) => {
    // message inférieur à 2 minutes sont stackés (liste inversée, différence négative)
    return (
      index < memoChats.length - 1 &&
      memoChats[index].user.id === memoChats[index + 1].user.id &&
      dateFns.differenceInMinutes(memoChats[index + 1].createDate, memoChats[index].createDate) >=
      -2
    )
  }

  const onScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    setScroll(Math.abs(event.currentTarget.scrollTop) > 0)
    // trigger when 99% of top
    if (
      Math.abs(event.currentTarget.scrollTop) >=
      (99 * Math.abs(event.currentTarget.scrollHeight - event.currentTarget.offsetHeight)) /
      100 &&
      onScrollTop
    ) {
      setScrollTop(true)
      if (!isScrollTop) {
        // call only one time
        onScrollTop()
      }
    } else {
      setScrollTop(false)
    }
  }

  const memoChats = useMemo(() => [...sendings, ...chats], [sendings, chats])

  useEffect(() => {
    if (refBottom && refBottom.current && newMessage) {
      refBottom.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      setNewMessage(false)
    }
  }, [refBottom, newMessage, setNewMessage])

  useEffect(() => {
    if (currentConversation > -1) {
      setNewMessage(true)
    }
  }, [currentConversation, setNewMessage])

  return (
    <StyledChatList ref={refList} onScroll={onScroll} fixed={!!fixed}>
      {memoChats.length > 0 && <div style={{ float: 'left', clear: 'both' }} ref={refBottom}></div>}
      {memoChats.length === 0 && (
        <EmptyChatMessage>
          <FormattedMessage id="no.messages" />
        </EmptyChatMessage>
      )}
      {memoChats.map((chat, index) => (
        <StyledChatMessage
          key={index}
          title={chat.createDate}
          odd={chat.user.id === user?.id}
          previous={isNextMessageSameUser(index)}
        >
          <StyledBox
            odd={chat.user.id === user?.id}
            prev={!(memoChats.length === 0 || !isPrevMessageSameUser(index))}
          >
            {!isPrevMessageSameUser(index) && (
              <StyledHeaderMessage>
                {chat.user.id !== user?.id && (
                  <StyledUser>
                    <UserAvatar
                      user={chat.user}
                      isOnline={chat.user.isOnline}
                      showDetails
                      disabledSendMessage
                      disabledAnimation
                      title={`${chat.user.id}`}
                    />
                  </StyledUser>
                )}
                <StyledUsername>
                  {formatName(chat.user).full}
                </StyledUsername>
                <StyledDate>{dateFns.formatDistanceToNow(chat.createDate)}</StyledDate>
              </StyledHeaderMessage>
            )}
            <StyledBoxContent
              odd={chat.user.id === user?.id}
              previous={isPrevMessageSameUser(index)}
              next={isNextMessageSameUser(index)}
            >
              <ChatMessage
                id={chat.user.id}
                message={chat.message}
                sending={sendings.length > 0 && index < sendings.length}
                odd={chat.user.id === user?.id}
              />
            </StyledBoxContent>
          </StyledBox>
        </StyledChatMessage>
      ))}

      {!loading && hasScroll && (
        <StyledLoadPrevious onClick={onScrollTop}>
          <FormattedMessage id="load.previous.messages" />
        </StyledLoadPrevious>
      )}

      {loading && (
        <StyledDiv>
          <Loading position="center" />
        </StyledDiv>
      )}
    </StyledChatList>
  )
}

const StyledChatList = styled.ul<{ fixed: boolean }>`
  & {
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex: 1;
    overflow-y: auto;
    align-items: flex-end;
    display: flex;
    flex-direction: column-reverse;
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px ${alpha(BLACK_BLUE, 0.3)};
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${BACKGROUND_SECONDARY_LIGHT};
    }
  }
`

const StyledLoadPrevious = styled(InvisibleButton)`
  & {
    font-size: 10px;
    color: ${GRAY};
    padding: 5px;
  }
`

const StyledChatMessage = styled.li<{ odd: boolean; previous: boolean }>`
  & {
    position: relative;
    border-radius: ${BORDER_RADIUS_CARD};
    margin: ${(props) => (props.previous ? '0 auto 0 auto' : '0 auto 5px auto')};
    width: 100%;
    display: flex;
    flex-direction: ${(props) => (props.odd ? 'row-reverse' : 'row')};
  }
`

const StyledBox = styled.div<{ odd: boolean; prev: boolean }>`
  & {
    flex: auto;
    /*flex-direction:
     ${(props) =>
    props.prev ? (props.odd ? 'margin-right: 40px;' : 'margin-left: 40px;') : ''}; */
  }
`

const CssTopChat = css`
  & {
    border-top-right-radius: ${BORDER_RADIUS_CARD};
    border-top-left-radius: ${BORDER_RADIUS_CARD};
    padding-top: 7px;
  }
`

const CssBottomChat = css`
  & {
    border-bottom-right-radius: ${BORDER_RADIUS_CARD};
    border-bottom-left-radius: ${BORDER_RADIUS_CARD};
    padding-bottom: 7px;
  }
`

const StyledBoxContent = styled.div<{ odd: boolean; previous: boolean; next: boolean }>`
  & {
    background-color: ${(props) => (props.odd ? PRIMARY_COLOR : SECONDARY_COLOR)};
    color: white;
    padding: ${(props) => (props.odd ? '0px 7px 0px 7px' : '0px 7px 0px 7px')};
    ${(props) => (!props.previous ? CssTopChat : '')};
    ${(props) => (!props.next ? CssBottomChat : '')};
    text-align: ${(props) => (props.odd ? 'right' : 'left')};
    word-break: break-all;

    & a {
      color: white;
    }
  }
`

const StyledHeaderMessage = styled.div`
  & {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`

const StyledDiv = styled.div`
  & {
    position: relative;
    display: block;
    padding: 20px;
    width: 100%;
    height: 20px;
  }
`
const StyledUsername = styled.div`
  & {
    font-size: 8px;
  }
`

const StyledDate = styled.div`
  & {
    font-size: 8px;
  }
`

const StyledUser = styled.div`
  & {
    .MuiAvatar-root {
      width: 30px;
      height: 30px;
    }
  }
`

const EmptyChatMessage = styled.li`
  & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 20px);
    width: 100%;
  }
`

export default ChatList
