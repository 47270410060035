import { useEffect, useState } from 'react'
import placeholder from '../assets/images/placeholder.png'
import settings from '../settings'

export const useDetectImageExist = (imageUrl?: string, noPlaceholder?: boolean, size?: string) => {
  const [image, setImage] = useState(placeholder)

  useEffect(() => {
    const imgUrl =
      imageUrl && imageUrl.indexOf('://') === -1 && imageUrl.indexOf('data:') === -1
        ? `http${settings.prod ? 's' : ''}://${imageUrl}`
        : imageUrl

    if (imageUrl && window.images[imageUrl] !== undefined) {
      setImage(
        window.images[imageUrl] +
          (size && window.images[imageUrl].indexOf('data:') === -1 ? size : '')
      )
    } else if (imgUrl) {
      const img = new Image()
      img.onload = () => {
        setImage(imgUrl + (size ? size : ''))
        if (imageUrl) window.images[imageUrl] = imgUrl
      }
      img.onerror = () => {
        setImage(noPlaceholder ? '' : placeholder)
        if (imageUrl) window.images[imageUrl] = noPlaceholder ? '' : placeholder
      }
      img.src = imgUrl + (size ? size : '')
    }
  }, [imageUrl, noPlaceholder, size])

  return image
}
