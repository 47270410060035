import { AnyAction } from 'redux'
import { createReducer, IReducerHandler } from '../utils'
import { ActionsTypes, IParticipantState } from './model'

const initialState: IParticipantState = {
  participants: { total: 0, items: [] },
  participantsFiltered: { total: 0, items: [] },
  presenceUsersCount: 0,
  presenceUsers: [],
  aroundUsersCount: 0,
  aroundUsers: [],
  contacts: [],
  contactsFiltered: [],
  loading: false,
  loadingContacts: false,
  loadingContactsFiltered: false,
  loadingParticipantsFiltered: false,
  adding: null,
  deleting: null,
  contactsRefreshed: {},
  refreshingContact: null,
  filters: {},
  loadingFilters: false,
  keywordsCreatedByUser: [],
  loadedKeywordsCreatedByUser: false,
  loadingKeywordsCreatedByUser: false,
  loadingUpdateContact: false,
  loadingUpdateNote: {},
  loadingUpdateTags: {},
  loadingFollowers: false,
  followers: []
}

const actionHandlers: IReducerHandler<IParticipantState> = {
  [ActionsTypes.SET_SEARCH_PARTICIPANT]: (
    state: IParticipantState | undefined,
    { searchParticipants }: AnyAction
  ): IParticipantState => ({
    ...state!,
    searchParticipants
  }),

  [ActionsTypes.SET_PARTICIPANTS]: (
    state: IParticipantState | undefined,
    { participants }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants
  }),

  [ActionsTypes.SET_PRESENCE_USERS_COUNT]: (
    state: IParticipantState | undefined,
    { presenceUsersCount }: AnyAction
  ): IParticipantState => ({
    ...state!,
    presenceUsersCount
  }),

  [ActionsTypes.INCREMENT_PRESENCE_USERS_COUNT]: (
    state: IParticipantState | undefined,
    { presenceUsersCount }: AnyAction
  ): IParticipantState => ({
    ...state!,
    presenceUsersCount:
      state && state.presenceUsersCount > 0
        ? state.presenceUsersCount + presenceUsersCount
        : presenceUsersCount
  }),

  [ActionsTypes.DECREMENT_PRESENCE_USERS_COUNT]: (
    state: IParticipantState | undefined,
    { presenceUsersCount }: AnyAction
  ): IParticipantState => ({
    ...state!,
    presenceUsersCount:
      state && state.presenceUsersCount - presenceUsersCount > 0
        ? state.presenceUsersCount - presenceUsersCount
        : 0
  }),

  [ActionsTypes.SET_PRESENCE_USERS]: (
    state: IParticipantState | undefined,
    { presenceUsers }: AnyAction
  ): IParticipantState => ({
    ...state!,
    presenceUsers
  }),

  [ActionsTypes.SET_AROUND_USERS_COUNT]: (
    state: IParticipantState | undefined,
    { aroundUsersCount }: AnyAction
  ): IParticipantState => ({
    ...state!,
    aroundUsersCount
  }),

  [ActionsTypes.INCREMENT_AROUND_USERS_COUNT]: (
    state: IParticipantState | undefined,
    { aroundUsersCount }: AnyAction
  ): IParticipantState => ({
    ...state!,
    aroundUsersCount:
      state && state.aroundUsersCount > 0
        ? state.aroundUsersCount + aroundUsersCount
        : aroundUsersCount
  }),

  [ActionsTypes.DECREMENT_AROUND_USERS_COUNT]: (
    state: IParticipantState | undefined,
    { aroundUsersCount }: AnyAction
  ): IParticipantState => ({
    ...state!,
    aroundUsersCount:
      state && state.aroundUsersCount - aroundUsersCount > 0
        ? state.aroundUsersCount - aroundUsersCount
        : 0
  }),

  [ActionsTypes.SET_AROUND_USERS]: (
    state: IParticipantState | undefined,
    { aroundUsers }: AnyAction
  ): IParticipantState => ({
    ...state!,
    aroundUsers
  }),

  [ActionsTypes.SET_CONTACTS]: (
    state: IParticipantState | undefined,
    { contacts }: AnyAction
  ): IParticipantState => ({
    ...state!,
    contacts
  }),

  [ActionsTypes.SET_CONTACTS_FILTERED]: (
    state: IParticipantState | undefined,
    { contactsFiltered }: AnyAction
  ): IParticipantState => ({
    ...state!,
    contactsFiltered
  }),

  [ActionsTypes.REMOVE_CONTACT_KEYWORD_USERS]: (
    state: IParticipantState | undefined,
    { keyword }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => ({
            ...participant,
            contactKeywords: participant.contactKeywords
              ? participant.contactKeywords.filter(
                  (contactKeyword) => keyword.id !== contactKeyword.keyword.id
                )
              : []
          }))
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participant) => ({
            ...participant,
            contactKeywords: participant.contactKeywords
              ? participant.contactKeywords.filter(
                  (contactKeyword) => keyword.id !== contactKeyword.keyword.id
                )
              : []
          }))
        }
      : { total: 0, items: [] },
    contacts: state
      ? state.contacts.map((contact) => ({
          ...contact,
          contactKeywords: contact.contactKeywords
            ? contact.contactKeywords.filter(
                (contactKeyword) => keyword.id !== contactKeyword.keyword.id
              )
            : []
        }))
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contact) => ({
          ...contact,
          contactKeywords: contact.contactKeywords
            ? contact.contactKeywords.filter(
                (contactKeyword) => keyword.id !== contactKeyword.keyword.id
              )
            : []
        }))
      : []
  }),

  [ActionsTypes.EDIT_CONTACT_KEYWORD_USERS]: (
    state: IParticipantState | undefined,
    { keyword }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => ({
            ...participant,
            contactKeywords: participant.contactKeywords
              ? participant.contactKeywords.map((contactKeyword) =>
                  keyword.id === contactKeyword.keyword.id
                    ? { ...contactKeyword, keyword }
                    : contactKeyword
                )
              : []
          }))
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participant) => ({
            ...participant,
            contactKeywords: participant.contactKeywords
              ? participant.contactKeywords.map((contactKeyword) =>
                  keyword.id === contactKeyword.keyword.id
                    ? { ...contactKeyword, keyword }
                    : contactKeyword
                )
              : []
          }))
        }
      : { total: 0, items: [] },
    contacts: state
      ? state.contacts.map((contact) => ({
          ...contact,
          contactKeywords: contact.contactKeywords
            ? contact.contactKeywords.map((contactKeyword) =>
                keyword.id === contactKeyword.keyword.id
                  ? { ...contactKeyword, keyword }
                  : contactKeyword
              )
            : []
        }))
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contact) => ({
          ...contact,
          contactKeywords: contact.contactKeywords
            ? contact.contactKeywords.map((contactKeyword) =>
                keyword.id === contactKeyword.keyword.id
                  ? { ...contactKeyword, keyword }
                  : contactKeyword
              )
            : []
        }))
      : []
  }),

  [ActionsTypes.REMOVE_CONTACT_KEYWORD]: (
    state: IParticipantState | undefined,
    { id, contactKeywordRemoved }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => ({
            ...participant,
            contactKeywords: participant.contactKeywords
              ? participant.id === id
                ? participant.contactKeywords.filter(
                    (contactKeyword) => contactKeywordRemoved.id !== contactKeyword.id
                  )
                : participant.contactKeywords
              : []
          }))
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participant) => ({
            ...participant,
            contactKeywords: participant.contactKeywords
              ? participant.id === id
                ? participant.contactKeywords.filter(
                    (contactKeyword) => contactKeywordRemoved.id !== contactKeyword.id
                  )
                : participant.contactKeywords
              : []
          }))
        }
      : { total: 0, items: [] },
    contacts: state
      ? state.contacts.map((contact) => ({
          ...contact,
          contactKeywords: contact.contactKeywords
            ? contact.id === id
              ? contact.contactKeywords.filter(
                  (contactKeyword) => contactKeywordRemoved.id !== contactKeyword.id
                )
              : contact.contactKeywords
            : []
        }))
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contact) => ({
          ...contact,
          contactKeywords: contact.contactKeywords
            ? contact.id === id
              ? contact.contactKeywords.filter(
                  (contactKeyword) => contactKeywordRemoved.id !== contactKeyword.id
                )
              : contact.contactKeywords
            : []
        }))
      : []
  }),

  [ActionsTypes.ADD_NEW_CONTACT_KEYWORD]: (
    state: IParticipantState | undefined,
    { id, contactKeyword }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => ({
            ...participant,
            contactKeywords: participant.contactKeywords
              ? participant.id === id
                ? [...participant.contactKeywords, contactKeyword]
                : participant.contactKeywords
              : []
          }))
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participant) => ({
            ...participant,
            contactKeywords: participant.contactKeywords
              ? participant.id === id
                ? [...participant.contactKeywords, contactKeyword]
                : participant.contactKeywords
              : []
          }))
        }
      : { total: 0, items: [] },
    contacts: state
      ? state.contacts.map((contact) => ({
          ...contact,
          contactKeywords: contact.contactKeywords
            ? contact.id === id
              ? [...contact.contactKeywords, contactKeyword]
              : contact.contactKeywords
            : []
        }))
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contact) => ({
          ...contact,
          contactKeywords: contact.contactKeywords
            ? contact.id === id
              ? [...contact.contactKeywords, contactKeyword]
              : contact.contactKeywords
            : []
        }))
      : []
  }),

  [ActionsTypes.REFRESH_CONTACT]: (
    state: IParticipantState | undefined,
    { contactRefreshed }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => {
            if (participant.id === contactRefreshed.id) {
              return {
                ...contactRefreshed,
                score: participant.score,
                userPresence: {
                  user: contactRefreshed.userPresence.user,
                  isOnline: participant.userPresence.isOnline
                }
              }
            } else {
              return participant
            }
          })
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participantFiltered) => {
            if (participantFiltered.id === contactRefreshed.id) {
              return {
                ...contactRefreshed,
                score: participantFiltered.score,
                userPresence: {
                  user: contactRefreshed.userPresence.user,
                  isOnline: participantFiltered.userPresence.isOnline
                }
              }
            } else {
              return participantFiltered
            }
          })
        }
      : { total: 0, items: [] },
    presenceUsers: state
      ? state.presenceUsers.map((presenceUser) => {
          if (presenceUser.id === contactRefreshed.id) {
            return {
              ...contactRefreshed,
              score: presenceUser.score,
              userPresence: {
                user: contactRefreshed.userPresence.user,
                isOnline: presenceUser.userPresence.isOnline
              }
            }
          } else {
            return presenceUser
          }
        })
      : [],
    aroundUsers: state
      ? state.aroundUsers.map((aroundUser) => {
          if (aroundUser.id === contactRefreshed.id) {
            return {
              ...contactRefreshed,
              score: aroundUser.score,
              userPresence: {
                user: contactRefreshed.userPresence.user,
                isOnline: aroundUser.userPresence.isOnline
              }
            }
          } else {
            return aroundUser
          }
        })
      : [],
    contacts: state
      ? state.contacts.map((contact) => {
          if (contact.id === contactRefreshed.id) {
            return {
              ...contactRefreshed,
              score: contact.score,
              userPresence: {
                user: contactRefreshed.userPresence.user,
                isOnline: contact.userPresence.isOnline
              }
            }
          } else {
            return contact
          }
        })
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contactFiltered) => {
          if (contactFiltered.id === contactRefreshed.id) {
            return {
              ...contactRefreshed,
              score: contactFiltered.score,
              userPresence: {
                user: contactRefreshed.userPresence.user,
                isOnline: contactFiltered.userPresence.isOnline
              }
            }
          } else {
            return contactFiltered
          }
        })
      : []
  }),

  [ActionsTypes.MERGE_CONTACT]: (
    state: IParticipantState | undefined,
    { contact }: AnyAction
  ): IParticipantState => ({
    ...state!,
    contacts: state ? [...state.contacts, contact] : []
  }),

  [ActionsTypes.REMOVE_CONTACT]: (
    state: IParticipantState | undefined,
    { contactId }: AnyAction
  ): IParticipantState => ({
    ...state!,
    contacts: state ? state.contacts.filter((stateContact) => stateContact.id !== contactId) : []
  }),

  [ActionsTypes.SET_LOADING_PARTICIPANT]: (
    state: IParticipantState | undefined,
    { loading }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loading
  }),

  [ActionsTypes.SET_ADDING_PARTICIPANT]: (
    state: IParticipantState | undefined,
    { adding }: AnyAction
  ): IParticipantState => ({
    ...state!,
    adding
  }),

  [ActionsTypes.SET_DELETING_PARTICIPANT]: (
    state: IParticipantState | undefined,
    { deleting }: AnyAction
  ): IParticipantState => ({
    ...state!,
    deleting
  }),

  [ActionsTypes.SET_REFRESHING_CONTACT]: (
    state: IParticipantState | undefined,
    { refreshingContact }: AnyAction
  ): IParticipantState => ({
    ...state!,
    refreshingContact
  }),

  [ActionsTypes.SET_USERS_FILTERS]: (
    state: IParticipantState | undefined,
    { filters }: AnyAction
  ): IParticipantState => ({
    ...state!,
    filters
  }),

  [ActionsTypes.SET_LOADING_USERS_FILTERS]: (
    state: IParticipantState | undefined,
    { loadingFilters }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loadingFilters
  }),

  [ActionsTypes.SET_LOADING_CONTACTS]: (
    state: IParticipantState | undefined,
    { loadingContacts }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loadingContacts
  }),

  [ActionsTypes.SET_LOADING_CONTACTS_FILTERED]: (
    state: IParticipantState | undefined,
    { loadingContactsFiltered }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loadingContactsFiltered
  }),

  [ActionsTypes.SET_LOADING_UPDATE_CONTACT]: (
    state: IParticipantState | undefined,
    { loadingUpdateContact }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loadingUpdateContact
  }),

  [ActionsTypes.SET_LOADING_UPDATE_NOTE]: (
    state: IParticipantState | undefined,
    { contactId, loadingUpdateContactNote }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loadingUpdateNote:
      state && state.loadingUpdateNote
        ? { ...state.loadingUpdateNote, [contactId]: loadingUpdateContactNote }
        : {}
  }),

  [ActionsTypes.SET_LOADING_UPDATE_TAGS]: (
    state: IParticipantState | undefined,
    { contactId, loadingUpdateContactTags }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loadingUpdateTags:
      state && state.loadingUpdateTags
        ? { ...state.loadingUpdateTags, [contactId]: loadingUpdateContactTags }
        : {}
  }),

  [ActionsTypes.SET_ERROR_UPDATE_CONTACT]: (
    state: IParticipantState | undefined,
    { errorUpdateContact }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorUpdateContact
  }),

  [ActionsTypes.SET_ERROR_USERS_FILTERS]: (
    state: IParticipantState | undefined,
    { errorFilters }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorFilters
  }),

  [ActionsTypes.SET_ERROR_CONTACTS]: (
    state: IParticipantState | undefined,
    { errorContacts }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorContacts
  }),

  [ActionsTypes.SET_ERROR_CONTACTS_FILTERED]: (
    state: IParticipantState | undefined,
    { errorContactsFiltered }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorContactsFiltered
  }),

  [ActionsTypes.SET_ERROR_ADDING]: (
    state: IParticipantState | undefined,
    { errorAdding }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorAdding
  }),

  [ActionsTypes.SET_ERROR_DELETING]: (
    state: IParticipantState | undefined,
    { errorDeleting }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorDeleting
  }),

  [ActionsTypes.SET_ERROR_PARTICIPANTS]: (
    state: IParticipantState | undefined,
    { errorParticipants }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorParticipants
  }),

  [ActionsTypes.SET_ERROR_PRESENCES]: (
    state: IParticipantState | undefined,
    { errorPresences }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorPresences
  }),

  [ActionsTypes.ADD_CONTACT_REFRESHED]: (
    state: IParticipantState | undefined,
    { contactRefreshed }: AnyAction
  ): IParticipantState => ({
    ...state!,
    contactsRefreshed: state
      ? {
          ...state.contactsRefreshed,
          [contactRefreshed.id]: contactRefreshed
        }
      : {}
  }),

  [ActionsTypes.ADD_USERS_KEYWORD]: (
    state: IParticipantState | undefined,
    { keywordCreatedByUser }: AnyAction
  ): IParticipantState => ({
    ...state!,
    keywordsCreatedByUser:
      state && state.keywordsCreatedByUser
        ? [
            ...state.keywordsCreatedByUser.filter(
              (keyword) => keyword.id !== keywordCreatedByUser.id
            ),
            keywordCreatedByUser
          ]
        : []
  }),

  [ActionsTypes.EDIT_USERS_KEYWORD]: (
    state: IParticipantState | undefined,
    { editedKeyword }: AnyAction
  ): IParticipantState => ({
    ...state!,
    keywordsCreatedByUser:
      state && state.keywordsCreatedByUser
        ? [
            ...state.keywordsCreatedByUser.map((keyword) =>
              keyword.id === editedKeyword.id ? editedKeyword : keyword
            )
          ]
        : []
  }),

  [ActionsTypes.REMOVE_USERS_KEYWORD]: (
    state: IParticipantState | undefined,
    { keywordCreatedByUser }: AnyAction
  ): IParticipantState => ({
    ...state!,
    keywordsCreatedByUser:
      state && state.keywordsCreatedByUser
        ? state.keywordsCreatedByUser.filter((keyword) => keyword.id !== keywordCreatedByUser.id)
        : []
  }),

  [ActionsTypes.SET_USERS_KEYWORDS]: (
    state: IParticipantState | undefined,
    { keywordsCreatedByUser }: AnyAction
  ): IParticipantState => ({
    ...state!,
    keywordsCreatedByUser
  }),

  [ActionsTypes.SET_ERROR_USERS_KEYWORDS]: (
    state: IParticipantState | undefined,
    { errorKeywordsCreatedByUser }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorKeywordsCreatedByUser
  }),

  [ActionsTypes.SET_LOADING_USERS_KEYWORDS]: (
    state: IParticipantState | undefined,
    { loadingKeywordsCreatedByUser }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loadingKeywordsCreatedByUser
  }),

  [ActionsTypes.SET_LOADED_USERS_KEYWORDS]: (
    state: IParticipantState | undefined,
    { loadedKeywordsCreatedByUser }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loadedKeywordsCreatedByUser
  }),

  [ActionsTypes.SET_LOADING_PARTICIPANTS_FILTERED]: (
    state: IParticipantState | undefined,
    { loadingParticipantsFiltered }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loadingParticipantsFiltered
  }),

  [ActionsTypes.SET_PARTICIPANTS_FILTERED]: (
    state: IParticipantState | undefined,
    { participantsFiltered }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participantsFiltered
  }),

  [ActionsTypes.SET_ERROR_PARTICIPANTS_FILTERED]: (
    state: IParticipantState | undefined,
    { errorParticipantsFiltered }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorParticipantsFiltered
  }),

  [ActionsTypes.UPDATE_CONTACT_ONLINE]: (
    state: IParticipantState | undefined,
    { contactRefreshedIds, isOnline }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => {
            if (contactRefreshedIds.indexOf(participant.id) > -1) {
              return {
                ...participant,
                userPresence: {
                  user: participant.userPresence.user,
                  isOnline
                }
              }
            } else {
              return participant
            }
          })
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participantFiltered) => {
            if (contactRefreshedIds.indexOf(participantFiltered.id) > -1) {
              return {
                ...participantFiltered,
                userPresence: {
                  user: participantFiltered.userPresence.user,
                  isOnline
                }
              }
            } else {
              return participantFiltered
            }
          })
        }
      : { total: 0, items: [] },
    contacts: state
      ? state.contacts.map((contact) => {
          if (contactRefreshedIds.indexOf(contact.id) > -1) {
            return {
              ...contact,
              userPresence: {
                user: contact.userPresence.user,
                isOnline
              }
            }
          } else {
            return contact
          }
        })
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contactFiltered) => {
          if (contactRefreshedIds.indexOf(contactFiltered.id) > -1) {
            return {
              ...contactFiltered,
              userPresence: {
                user: contactFiltered.userPresence.user,
                isOnline
              }
            }
          } else {
            return contactFiltered
          }
        })
      : []
  }),

  [ActionsTypes.UPDATE_CONTACT_IS_CONTACT]: (
    state: IParticipantState | undefined,
    { contactId, isContact }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => {
            if (contactId === participant.id) {
              return {
                ...participant,
                isContact
              }
            } else {
              return participant
            }
          })
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participantFiltered) => {
            if (contactId === participantFiltered.id) {
              return {
                ...participantFiltered,
                isContact
              }
            } else {
              return participantFiltered
            }
          })
        }
      : { total: 0, items: [] },
    contacts: state
      ? state.contacts.map((contact) => {
          if (contactId === contact.id) {
            return {
              ...contact,
              isContact
            }
          } else {
            return contact
          }
        })
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contactFiltered) => {
          if (contactId === contactFiltered.id) {
            return {
              ...contactFiltered,
              isContact
            }
          } else {
            return contactFiltered
          }
        })
      : [],
    presenceUsers: state
      ? state.presenceUsers.map((presenceUser) => {
          if (presenceUser.id === contactId) {
            return {
              ...presenceUser,
              isContact
            }
          } else {
            return presenceUser
          }
        })
      : [],
    aroundUsers: state
      ? state.aroundUsers.map((aroundUser) => {
          if (aroundUser.id === contactId) {
            return {
              ...aroundUser,
              isContact
            }
          } else {
            return aroundUser
          }
        })
      : []
  }),

  [ActionsTypes.ADD_NEW_CONTACT_NOTE]: (
    state: IParticipantState | undefined,
    { id, note }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => ({
            ...participant,
            note: participant.id === id ? note : participant.note
          }))
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participant) => ({
            ...participant,
            note: participant.id === id ? note : participant.note
          }))
        }
      : { total: 0, items: [] },
    contacts: state
      ? state.contacts.map((contact) => ({
          ...contact,
          note: contact.id === id ? note : contact.note
        }))
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contact) => ({
          ...contact,
          note: contact.id === id ? note : contact.note
        }))
      : []
  }),

  [ActionsTypes.EDIT_CONTACT_NOTE]: (
    state: IParticipantState | undefined,
    { id, note }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => ({
            ...participant,
            note: participant.id === id ? note : participant.note
          }))
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participant) => ({
            ...participant,
            note: participant.id === id ? note : participant.note
          }))
        }
      : { total: 0, items: [] },
    contacts: state
      ? state.contacts.map((contact) => ({
          ...contact,
          note: contact.id === id ? note : contact.note
        }))
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contact) => ({
          ...contact,
          note: contact.id === id ? note : contact.note
        }))
      : []
  }),

  [ActionsTypes.EDIT_CONTACT_NOTE]: (
    state: IParticipantState | undefined,
    { id, note }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => ({
            ...participant,
            note: participant.id === id ? note : participant.note
          }))
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participant) => ({
            ...participant,
            note: participant.id === id ? note : participant.note
          }))
        }
      : { total: 0, items: [] },
    contacts: state
      ? state.contacts.map((contact) => ({
          ...contact,
          note: contact.id === id ? note : contact.note
        }))
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contact) => ({
          ...contact,
          note: contact.id === id ? note : contact.note
        }))
      : []
  }),

  [ActionsTypes.REMOVE_CONTACT_NOTE]: (
    state: IParticipantState | undefined,
    { id }: AnyAction
  ): IParticipantState => ({
    ...state!,
    participants: state
      ? {
          total: state.participants.total,
          items: state.participants.items.map((participant) => ({
            ...participant,
            note: undefined
          }))
        }
      : { total: 0, items: [] },
    participantsFiltered: state
      ? {
          total: state.participantsFiltered.total,
          items: state.participantsFiltered.items.map((participant) => ({
            ...participant,
            note: undefined
          }))
        }
      : { total: 0, items: [] },
    contacts: state
      ? state.contacts.map((contact) => ({
          ...contact,
          note: undefined
        }))
      : [],
    contactsFiltered: state
      ? state.contactsFiltered.map((contact) => ({
          ...contact,
          note: undefined
        }))
      : []
  }),

  [ActionsTypes.SET_ERROR_FOLLOWERS]: (
    state: IParticipantState | undefined,
    { errorFollowers }: AnyAction
  ): IParticipantState => ({
    ...state!,
    errorFollowers
  }),

  [ActionsTypes.SET_LOADING_FOLLOWERS]: (
    state: IParticipantState | undefined,
    { loadingFollowers }: AnyAction
  ): IParticipantState => ({
    ...state!,
    loadingFollowers
  }),

  [ActionsTypes.SET_FOLLOWERS]: (
    state: IParticipantState | undefined,
    { followers }: AnyAction
  ): IParticipantState => ({
    ...state!,
    followers
  })
}

export const reducer = createReducer(initialState, actionHandlers)
