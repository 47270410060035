import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Prompt } from 'react-router-dom'

/**
 * Hook to detect route change or page reload
 *
 * @param messageKey i18n key
 * @example `const [Prompt, setDirty, setPristine] = useBeforeRouteChange()`
 */
export const useBeforeRouteChange: (messageKey?: string) => {
  Prompt: () => JSX.Element
  setDirty: () => void
  setPristine: () => void
} = (messageKey = 'before.route.change') => {
  const [isDirty, setDirty] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    if (isDirty) {
      // detecting browser closing
      window.onbeforeunload = () => intl.formatMessage({ id: messageKey })
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [isDirty, messageKey, intl])

  const routerPrompt = () => {
    return <Prompt when={isDirty} message={intl.formatMessage({ id: messageKey })} />
  }

  return {
    Prompt: routerPrompt,
    setDirty: () => setDirty(true),
    setPristine: () => setDirty(false)
  }
}
