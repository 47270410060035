import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface ViewDialogProps extends DialogProps {
  okLabel?: string
  okColor?: string
  hasCloseButton?: boolean
  disableButtonOk?: boolean
  disableEscapeKeyDown?: boolean
  disableBackdropClick?: boolean
  closeEvent?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  okEvent: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ViewDialog: React.FC<ViewDialogProps> = ({
  open,
  title,
  children,
  okLabel,
  okColor,
  hasCloseButton,
  disableButtonOk,
  disableEscapeKeyDown,
  disableBackdropClick,
  okEvent,
  closeEvent,
  ...props
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (closeEvent) {
      closeEvent(event)
    }
  }

  const onClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: 'backdropClick' | 'escapeKeyDown') => {
    // onClose prop with the `reason` argument to filter the `backdropClick` events.
    if (reason === 'backdropClick' && disableBackdropClick) {
      event.preventDefault()
      event.stopPropagation()
      return
    } else if (reason === 'escapeKeyDown' && disableEscapeKeyDown) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
    okEvent(event)
  }

  const clickOk = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    okEvent(event)
  }

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      {(title || hasCloseButton) && (
        <DialogTitle id="alert-dialog-title">
          {title && <>{title}</>}
          {hasCloseButton && (
            <StyledCloseButton>
              <IconButton onClick={handleClose} color="primary">
                <CloseIcon />
              </IconButton>
            </StyledCloseButton>
          )}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {!disableButtonOk && (
        <DialogActions style={{ color: okColor }}>
          <Button
            onClick={clickOk}
            color={okColor ? 'inherit' : 'primary'}
            variant="contained"
            autoFocus
          >
            <FormattedMessage id={okLabel || 'dialog.ok'} />
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

const StyledCloseButton = styled.div`
  & {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`

export default ViewDialog
