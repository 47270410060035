import styled from '@emotion/styled'
import { useScrollTrigger, Zoom } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { useIsMobile } from '../hooks'
import { CONTACTS_MENU_WIDTH } from '../theme/sizes'
import { breakpointsValues } from './breakpoints'

interface IScrollTopProps {
  children: ReactNode
}

const ScrollTop: React.FC<IScrollTopProps> = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  })
  const isMobile = useIsMobile(breakpointsValues.lg)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    )

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <Zoom in={trigger}>
      <Container onClick={handleClick} role="presentation" isMobile={isMobile}>
        {children}
      </Container>
    </Zoom>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  position: fixed;
  bottom: 15px;
  right: ${(props) => (props.isMobile ? '10px' : CONTACTS_MENU_WIDTH)};
  margin-right: 10px;
  z-index: 1300;
`

export default ScrollTop
