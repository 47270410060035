import styled from '@emotion/styled'
import React from 'react'
import { PRIMARY_COLOR } from '../../../theme'

interface IProps {
  talking: boolean
  fullscreen?: boolean
  hidden?: boolean
}

const TalkingBorder: React.FC<IProps> = ({ talking, fullscreen, hidden }) => {
  return <StyledTalkingBorder talking={talking} hidden={hidden} fullscreen={fullscreen} />
}

const StyledTalkingBorder = styled.div<{
  talking: boolean
  fullscreen?: boolean
  hidden?: boolean
}>`
  position: absolute;
  z-index: ${(props) => (props.fullscreen ? 10001 : 100)};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: ${(props) => (props.hidden ? '0' : '5')}px solid
    ${(props) => (props.talking ? PRIMARY_COLOR : 'transparent')};
`

export default TalkingBorder
