import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import TimerIcon from '@material-ui/icons/Timer'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import AppLoading from '../../components/ui/AppLoading/AppLoading'
import { IRoom, PROFILINGS } from '../../entities'
import { useDateFns, useThunkDispatch } from '../../hooks'
import useIsProfilingEnabled from '../../hooks/useIsProfilingEnabled'
import { IRootState } from '../../redux'
import { updateConference } from '../../store/conference'
import { WHITE } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import ContactItem from '../contacts/ContactItem'
import FollowersDialog from '../contacts/FollowersDialog'
import Announce from '../globals/Announce'
import ZoomButton from '../globals/Media/Zoom/ZoomButton'
import Profiling from '../globals/Profiling'
import UserAvatar from '../globals/UserAvatar/UserAvatar'
import UserAvatarGroup from '../globals/UserAvatarGroup/UserAvatarGroup'
import VimeoId from '../globals/VimeoId'
import Loading from '../Loading'
import AppButton from '../ui/AppButton/AppButton'
import Editor from '../ui/Editor/Editor'
import InputText from '../ui/InputText/InputText'
import ConferenceFavoriteButton from './ConferenceFavoriteButton'
import ConferenceLiveButton from './ConferenceLiveButton'
import ConferenceRelatedButtons from './ConferenceRelatedButtons'
import { getDurationConference } from './ConferenceUtils'

interface IConferenceInfosProps {
  status: string
  updateConferenceStatus: (confStatus: string, notify: boolean) => void
}

const ConferenceInfos: FC<IConferenceInfosProps> = ({ status, updateConferenceStatus }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const dateFns = useDateFns()
  const [body, setBody] = useState('')
  const [title, setTitle] = useState('')
  // const [goals, setGoals] = useState('')
  const [vimeoId, setVimeoId] = useState('')
  const [zoomRoom, setZoomRoom] = useState<IRoom | null>(null)
  const [viewMoreFollowersOpen, setViewMoreFollowersOpen] = useState(false)
  const user = useSelector((state: IRootState) => state.appState.user)
  const groupId = useSelector((state: IRootState) => state.appState.groupId)
  const conference = useSelector((state: IRootState) => state.conferenceState.conference)
  const loading = useSelector((state: IRootState) => state.conferenceState.loading)
  const updating = useSelector((state: IRootState) => state.conferenceState.updating)
  const editMode = useSelector((state: IRootState) => state.conferenceState.editMode)
  const autoEnterRoom = useSelector((state: IRootState) => state.standState.autoEnterRoom)
  const eventCo = useSelector((state: IRootState) => state.settingsState.eventCo)
  const isProfilingEnabled = useIsProfilingEnabled()
  const dispatch = useThunkDispatch()

  const isMePresenter = useMemo(
    () =>
      user &&
      conference &&
      conference.presenters &&
      conference.presenters.find((p) => p.id === user.id),
    [conference, user]
  )

  const memoFollowers = useMemo(
    () => user && conference?.followersLite.filter((follower) => follower.id !== user.id),
    [conference, user]
  )

  const updateConferenceTitle = (value: string) => {
    setTitle(value)
    if (conference) {
      dispatch(updateConference(conference.id, { title: value }))
    }
  }

  // const updateConferenceGoals = (value: string) => {
  //   setGoals(value)
  //   if (conference) {
  //     dispatch(updateConference(conference.id, { goals: value }))
  //   }
  // }

  const updateConferenceBody = (description: string) => {
    setBody(description)
    if (conference) {
      dispatch(updateConference(conference.id, { description }))
    }
  }

  const updateConferenceVimeoId = (value: string | undefined) => {
    if (conference) {
      dispatch(updateConference(conference.id, { live_vimeo_id: value }))
    }
  }

  const handleClickViewMoreFollowers = () => {
    sendDataToGTM({ event: 'list-followers', label: conference?.title })
    setViewMoreFollowersOpen(true)
  }

  // save zoomRoom
  useEffect(() => {
    if (conference && autoEnterRoom && autoEnterRoom.roomActive > -1) {
      setZoomRoom(conference.rooms[autoEnterRoom.roomActive])
    }
  }, [conference, autoEnterRoom])

  useEffect(() => {
    if (conference) {
      setBody(conference.description)
      // setGoals(conference.goals)
      setTitle(conference.title)
      setVimeoId(conference.live_vimeo_id)
    }
  }, [conference, body])

  if (loading || !conference) return <AppLoading />

  return (
    <StyledConferenceInfos>
      <StyledConferenceInfosCard>
        <StyledTopButton>
          <ConferenceFavoriteButton conference={conference} hasHelpToolTip />
        </StyledTopButton>
        <StyledH1>
          {editMode ? (
            <InputText
              onChangeValue={updateConferenceTitle}
              placeholder={intl.formatMessage({
                id: 'conference.title.placeholder'
              })}
              value={title}
              disabled={!editMode}
            />
          ) : (
            <>{conference.title}</>
          )}
        </StyledH1>
        {conference.editable && (
          <>
            <VimeoId url={vimeoId} onChange={updateConferenceVimeoId} />
            {vimeoId && (
              <ConferenceLiveButton
                status={status}
                updateConferenceStatus={updateConferenceStatus}
              />
            )}
            <ConferenceRelatedButtons />
            <Announce groupId={groupId} />
            {isMePresenter && zoomRoom && (
              <ZoomButton externalMeetingId={zoomRoom.sessionId} password={eventCo?.zoomPassword} />
            )}
            {isProfilingEnabled && (
              <Profiling type={PROFILINGS.CONFERENCE} conferenceId={conference.id} />
            )}
          </>
        )}
        <StyledList>
          {conference.duration > 0 && (
            <ListItem>
              <ListItemIcon>
                <AccessTimeIcon
                  titleAccess={intl.formatMessage({
                    id: 'titleAccess.ConferenceInfo.AccessTimeIcon'
                  })}
                />
              </ListItemIcon>
              <ListItemText primary={getDurationConference(conference)} />
            </ListItem>
          )}
          {conference.start_date && (
            <ListItem>
              <ListItemIcon>
                <TimerIcon
                  titleAccess={intl.formatMessage({ id: 'titleAccess.ConferenceInfo.TimerIcon' })}
                />
              </ListItemIcon>
              <ListItemText
                primary={dateFns.format(
                  conference.start_date,
                  intl.formatMessage({ id: 'date.format.simple' })
                )}
              />
            </ListItem>
          )}
        </StyledList>
        {/*{(editMode || goals) && (*/}
        {/*  <StyledGoals>*/}
        {/*    <Editor*/}
        {/*      readonly={!editMode}*/}
        {/*      content={goals}*/}
        {/*      placeholder={intl.formatMessage({ id: 'conference.goals.placeholder' })}*/}
        {/*      onChange={(content: string) => updateConferenceGoals(content)}*/}
        {/*    />*/}
        {/*  </StyledGoals>*/}
        {/*)}*/}
        <Editor
          readonly={!editMode}
          content={body}
          placeholder={intl.formatMessage({ id: 'conference.infos.placeholder' })}
          onChange={(content: string) => updateConferenceBody(content)}
        />
        {updating && <Loading position="bottomRight" />}
      </StyledConferenceInfosCard>
      {conference.presenters.length > 0 && (
        <StyledConferenceInfosCard>
          <StyledUsersPresenters>
            <StyledUsersPresentersTitle>
              <FormattedMessage id="conference.presenters.title" />
            </StyledUsersPresentersTitle>
            <StyledUsersPresentersOnline>
              {conference.presenters.map(
                (presenter, index) =>
                  presenter.userPresence.isOnline && (
                    <ContactItem
                      key={index}
                      contact={presenter}
                      showModerator
                      add={false}
                      more
                      note
                    />
                  )
              )}
            </StyledUsersPresentersOnline>
            <StyledUsersPresentersOffline>
              <UserAvatarGroup total={conference.presenters.length} max={6} spacing={15}>
                {conference.presenters.map(
                  (presenter, index) =>
                    !presenter.userPresence.isOnline && (
                      <UserAvatar
                        key={index}
                        user={presenter.userPresence.user}
                        showModerator
                        showDetails
                      />
                    )
                )}
              </UserAvatarGroup>
            </StyledUsersPresentersOffline>
          </StyledUsersPresenters>
        </StyledConferenceInfosCard>
      )}
      {memoFollowers && memoFollowers.length > 0 && (
        <StyledConferenceInfosCard>
          <StyledUsersPresentersTitle>
            <FormattedMessage id="conference.followers.title" />
          </StyledUsersPresentersTitle>
          <StyledUsersPresentersOnline>
            {memoFollowers.slice(0, 3).map((follower, index) => (
              <ContactItem key={index} contact={follower} add={false} note={false} more={false} />
            ))}
          </StyledUsersPresentersOnline>
          <AppButton onClick={handleClickViewMoreFollowers} ButtonType="primary">
            <FormattedMessage id="conference.followers.button" />
          </AppButton>
          {viewMoreFollowersOpen && (
            <FollowersDialog
              open={viewMoreFollowersOpen}
              titleKey={'conference.followers.title'}
              objectId={conference.id}
              objectType={'event'}
              onClose={() => setViewMoreFollowersOpen(false)}
            />
          )}
        </StyledConferenceInfosCard>
      )}
    </StyledConferenceInfos>
  )
}

const StyledConferenceInfos = styled.div`
  & {
    position: relative;
    font-size: 10px;
    padding-top: 15px;
  }
`

const StyledConferenceInfosCard = styled.div`
  & {
    position: relative;
    margin: 10px 10px 0;
    background-color: ${WHITE};
    border-radius: ${BORDER_RADIUS_CARD};
    padding: 20px;
  }
`

const StyledTopButton = styled.div`
  & {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row-reverse;
  }
`

const StyledList = styled(List)`
  & {
    margin-top: 15px;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;

    li {
      padding: 8px 0;
    }
    .MuiListItemIcon-root {
      min-width: 30px;
    }
  }
`

// const StyledGoals = styled.div`
//   & {
//     font-size: 12px;
//     line-height: 14px;
//     color: ${alpha(BLACK_BLUE, 0.6)};
//     text-transform: uppercase;
//     margin-bottom: 20px;
//   }
// `

const StyledH1 = styled.h1`
  &,
  & .MuiInput-root {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
  }
`

const StyledUsersPresenters = styled.div`
  & {
    margin-bottom: 15px;
  }
`

const StyledUsersPresentersOnline = styled.div`
  & {
    margin-bottom: 20px;
  }
`

const StyledUsersPresentersOffline = styled.div`
  & {
    margin-bottom: 15px;

    > div {
      display: flex;
      justify-content: center;
    }
  }
`

const StyledUsersPresentersTitle = styled(Typography)`
  & {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 20px;
  }
`

export default ConferenceInfos
