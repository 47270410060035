import useGTM from '@elgorditosalsero/react-gtm-hook'
import styled from '@emotion/styled'
import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ReactComponent as Clock } from '../../assets/images/icons/clock.svg'
import { ConferenceStatus, IConference } from '../../entities/conference'
import { useDateFns, useDetectImageExist, useThunkDispatch } from '../../hooks'
import { browserHistory, IRootState } from '../../redux'
import { setPageReferral } from '../../store/gtm'
import { SECONDARY_COLOR } from '../../theme'
import { GRAY, GRAY_200, ORANGE, PRIMARY } from '../../theme/colors'
import { BORDER_RADIUS_CARD } from '../../theme/sizes'
import ConferenceFavoriteButton from '../conference/ConferenceFavoriteButton'
import { getDurationConference, getProgressBarNumber } from '../conference/ConferenceUtils'
import LiveCounterSvg from '../globals/LiveCounterSvg/LiveCounterSvg'
import LiveTag from '../globals/LiveTag/LiveTag'
// import { ReactComponent as UserFavorite } from '../../assets/images/icons/user-favorite.svg'
import ProgressBar from '../ui/ProgressBar/ProgressBar'

interface IConferenceCardProps {
  conference: IConference
  goBackList?: boolean
}

const ConferenceCard: React.FC<IConferenceCardProps> = ({ conference, goBackList }) => {
  const intl = useIntl()
  const { sendDataToGTM } = useGTM()
  const pageReferral = useSelector((state: IRootState) => state.gtmState.pageReferral)
  const dateFns = useDateFns()
  const image = useDetectImageExist(conference.picture, false, '&size=small')
  const timestampNow = new Date().getTime()
  const timestampConference = new Date(conference.start_date).getTime()
  const dispatch = useThunkDispatch()

  const onClickCard = () => {
    dispatch(setPageReferral(`${pageReferral}::card-conference`))
    sendDataToGTM({ event: 'click-card', cardType: 'Conférence', cardName: `${conference.title}` })
    // auto enter room with redirect_url
    const [pathname, search] = conference.redirect_url ? conference.redirect_url.split('?') : []
    browserHistory.push({
      pathname: conference.redirect_url ? pathname : `/conference/${conference.id}`, // or url of conference
      state: { goBack: goBackList || false },
      search: conference.redirect_url ? search : undefined
    })
  }

  const durationMemo = useMemo(() => getDurationConference(conference), [conference])

  return (
    <StyledCard elevation={1} onClick={onClickCard}>
      <StyledCardActionArea>
        <StyledCardMedia image={image} title={conference.title} />
        {conference.status === ConferenceStatus.IN_PROGRESS && <LiveTag />}
        {timestampConference > timestampNow && (
          <StyledTimeStart>{dateFns.formatDistanceToNow(conference.start_date)}</StyledTimeStart>
        )}
        <LiveCounterSvg display={conference.nbUsers > 0} contentText={`${conference.nbUsers}`} />
        {conference.status === ConferenceStatus.ARCHIVE && conference.eventCoUrlVimeo !== '' && (
          <StyledVodTag>
            <PlayCircleOutlineIcon fontSize="small" />
            <span>
              <FormattedMessage id="conference.tag.vod" />
            </span>
          </StyledVodTag>
        )}
        <ProgressBar progress={getProgressBarNumber(conference)} bottom="small" />
      </StyledCardActionArea>
      <StyledCardContent>
        <StyledTopContent>
          <StyledDate>
            {dateFns.format(conference.start_date, intl.formatMessage({ id: 'date.format' }))}
          </StyledDate>
          {durationMemo !== '' && (
            <StyledDuration>
              <Clock />
              {`${durationMemo}`}
            </StyledDuration>
          )}
        </StyledTopContent>
        <StyledText title={conference.title}>
          <StyledName>{conference.title}</StyledName>
        </StyledText>
        <StyledBottomText>
          {/*<StyledUsersFavorite>*/}
          {/*  <UserFavorite />*/}
          {/*  {intl.formatMessage(*/}
          {/*    { id: 'person.interested' },*/}
          {/*    { count: Math.floor(Math.random() * 100) }*/}
          {/*  )}*/}
          {/*</StyledUsersFavorite>*/}
          {conference.status !== ConferenceStatus.ARCHIVE && (
            <ConferenceFavoriteButton conference={conference} />
          )}
        </StyledBottomText>
      </StyledCardContent>
      {conference.status === ConferenceStatus.ARCHIVE && conference.eventCoUrlVimeo === '' && (
        <StyledArchiveConference />
      )}
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  & {
    position: relative;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    margin: 8px;
    cursor: pointer;
  }
`

const StyledCardContent = styled(CardContent)`
  & {
    position: relative;
    padding: 10px !important;
    display: flex;
    flex-direction: column;
  }
`

const StyledCardActionArea = styled(CardActionArea)`
  & {
    position: relative;
  }
`

const StyledCardMedia = styled(CardMedia)`
  & {
    height: 140px;
    background-size: contain;
    background-color: ${GRAY_200};
  }
`

const StyledTopContent = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
`

const StyledText = styled.div`
  & {
    flex-grow: 1;
    margin-bottom: 10px;
  }
`

const StyledBottomText = styled.div`
  & {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`

const StyledDuration = styled.div`
  & {
    display: flex;
    align-items: center;
    color: ${GRAY};
    white-space: nowrap;

    svg {
      margin-right: 5px;
      color: ${GRAY};
    }
  }
`

const StyledVodTag = styled.div`
  & {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: ${PRIMARY};
    padding: 5px 10px;
    color: #fff;
    border-radius: ${BORDER_RADIUS_CARD};
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
    }
  }
`

const StyledTimeStart = styled.span`
  & {
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 5px 10px;
    color: #fff;
    background-color: ${ORANGE};
    font-size: 12px;
    line-height: 14px;
    border-radius: ${BORDER_RADIUS_CARD};
  }
`

const StyledDate = styled.div`
  & {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: ${SECONDARY_COLOR};
  }
`

const StyledName = styled.h3`
  & {
    margin-top: 0;
    font-size: 16px;
    line-height: 24px;
    height: 96px;
    font-weight: 600;
    margin-bottom: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

// const StyledUsersFavorite = styled.div`
//   & {
//     color: ${GRAY};
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 18px;
//
//     svg {
//       margin-right: 5px;
//       color: ${GRAY};
//     }
//   }
// `

const StyledArchiveConference = styled.div`
  & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${BORDER_RADIUS_CARD};
    background-color: ${GRAY};
    opacity: 0.4;
  }
`

export default ConferenceCard
