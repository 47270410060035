import styled from '@emotion/styled'
import { alpha, ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core'
import React, { FC, useEffect, useRef, useState } from 'react'
import { WHITE } from '../../theme/colors'
import { BOX_SHADOW } from '../../theme/sizes'

interface IContactDescriptionMoreProps {
  description: string
  maxLength?: number
  maxWidth?: number
}

const ContactDescriptionMore: FC<IContactDescriptionMoreProps> = ({
  description,
  maxLength,
  maxWidth
}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [mouseover, setMouseover] = useState(false)

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    let timer: number | null = null
    if (mouseover) {
      timer = window.setTimeout(() => {
        setOpen(true)
      }, 250)
    } else {
      timer = window.setTimeout(() => {
        setOpen(false)
      }, 250)
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [mouseover])

  return (
    <StyledContainer>
      {description.length > (maxLength || 90) ? (
        <StyledFakeEllipsisDescription>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </StyledFakeEllipsisDescription>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      )}
      <StyledContainerEffect
        ref={anchorRef}
        onMouseOver={() => {
          setMouseover(true)
        }}
        onMouseOut={() => {
          setMouseover(false)
        }}
      >
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: 1401, width: `${maxWidth || 220}px` }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <StyledDescriptionContainer dangerouslySetInnerHTML={{ __html: description }} />
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </StyledContainerEffect>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  position: relative;
  height: 50px;
  overflow: hidden;
  margin-bottom: 10px;
`

const StyledContainerEffect = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(180deg, ${alpha(WHITE, 0)} 0%, ${alpha(WHITE, 1)} 100%);
`

const StyledDescriptionContainer = styled.div`
  padding: 5px;
  box-shadow: ${BOX_SHADOW};
`

const StyledFakeEllipsisDescription = styled.div`
  max-height: 200px;
  overflow: hidden;
`

export default ContactDescriptionMore
