import { QualityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testQuality'
import { IEventCo } from '../../entities'
import { IOpentokSettings } from '../../entities/mediaStream'
import { ActionsTypes } from './model'

export const setSettingsLoading = (loadingSettings: boolean) => ({
  type: ActionsTypes.SET_LOADING_SETTINGS,
  loadingSettings
})

export const setOpentokTestsLoading = (loadingOpentokTests: boolean) => ({
  type: ActionsTypes.SET_LOADING_TESTS,
  loadingOpentokTests
})

export const setEventLoading = (loadingEvent: boolean) => ({
  type: ActionsTypes.SET_LOADING_EVENT,
  loadingEvent
})

export const setSettingsOpentok = (opentokSettings: IOpentokSettings) => ({
  type: ActionsTypes.SET_SETTINGS_OPENTOK,
  opentokSettings
})

export const setQualityTestResultsOpentok = (
  opentokQualityTestResults: QualityTestResults | null
) => ({
  type: ActionsTypes.SET_QUALITY_TEST_RESULTS_OPENTOK,
  opentokQualityTestResults
})

export const setTestsOpentok = (opentokTests: IOpentokSettings) => ({
  type: ActionsTypes.SET_TESTS_OPENTOK,
  opentokTests
})

export const setEventCo = (eventCo: IEventCo) => ({
  type: ActionsTypes.SET_EVENT_CO,
  eventCo
})

export const setEventCoDates = (eventCoDates: string[]) => ({
  type: ActionsTypes.SET_EVENT_CO_DATES,
  eventCoDates
})

export const setEventCoDatesLoaded = (eventCoDatesLoaded: boolean) => ({
  type: ActionsTypes.SET_EVENT_CO_DATES_LOADED,
  eventCoDatesLoaded
})

export const setSettingsError = (errorSettings?: string | undefined) => ({
  type: ActionsTypes.SET_SETTINGS_ERROR,
  errorSettings
})

export const setOpentokTestsError = (errorOpentokTests?: string | undefined) => ({
  type: ActionsTypes.SET_TESTS_ERROR,
  errorOpentokTests
})

export const setEventError = (errorEvent?: string | undefined) => ({
  type: ActionsTypes.SET_EVENT_ERROR,
  errorEvent
})
