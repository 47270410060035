import styled from '@emotion/styled'
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

const FullScreenDialog: React.FC<DialogProps> = ({ open, title, children, onClose, ...props }) => {
  const [openDialog, setOpenDialog] = useState(open)

  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClose!(event, 'backdropClick')
  }

  return (
    <StyledDialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      onClose={onClose}
      {...props}
    >
      <StyledAppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <StyledTypography id="alert-dialog-title" variant="h6">
            {title}
          </StyledTypography>
        </Toolbar>
      </StyledAppBar>
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  )
}

const StyledAppBar = styled(AppBar)`
  & {
    position: 'relative';
  }
`

const StyledDialog = styled(Dialog)`
  & {
    padding-top: 60px;
    z-index: 1500;

    .MuiDialogContent-root {
      height: 100%;
    }
  }
`

const StyledTypography = styled(Typography)`
  & {
    margin-left: 15px;
    flex: 1;
  }
`

export default FullScreenDialog
