const env = window._env_

export default {
  prod: env.REACT_APP_PROD,
  debug: {
    level: env.REACT_APP_DEBUG_LEVEL || 'log',
    notification: true,
    toast: false,
    magicstream: true,
    events: true,
    locations: true
  },
  version: env.REACT_APP_CURRENT_VERSION || '0.0.0',
  sentry: {
    dsn: 'https://67ddf0016f3c4596865696761718ae6f@o545706.ingest.sentry.io/5668095',
    environment: env.REACT_APP_SENTRY_ENVIRONMENT || 'staging'
  },
  url: {
    auth: env.REACT_APP_AUTH_URL || 'https://www.idealco.fr',
    api: env.REACT_APP_API_URL || 'https://api.idealco.fr',
    login_page:
      env.REACT_APP_LOGIN_PAGE_URL ||
      'https://www.idealco.fr/connexion?eventGroupId=' +
      env.REACT_APP_EVENT_GROUP_ID +
      '&_target_path={target_path}' /* +
      env.REACT_APP_ENDPOINT*/,
    logout_page:
      env.REACT_APP_LOGOUT_PAGE_URL ||
      'https://www.idealco.fr/deconnexion?eventGroupId=' +
      env.REACT_APP_EVENT_GROUP_ID +
      '&_target_path=' +
      env.REACT_APP_ENDPOINT
  },
  opentok: {
    minJs: 'https://static.opentok.com/v2/js/opentok.min.js',
    apiKey: env.REACT_APP_OPENTOK_API_KEY || '',
    settingsSessionId: env.REACT_APP_OPENTOK_SETTINGS_SESSION_ID || '',
    settingsRoomId: env.REACT_APP_OPENTOK_SETTINGS_ROOM_ID || '',
    autoStart: env.REACT_APP_OPENTOK_AUTO_START === 1,
    audioDetection: false
  },
  zoom: {
    apiKey: env.REACT_APP_ZOOM_API_KEY || ''
  },
  jitsi: {
    default: 'meet.jit.si',
    ideal: 'jitsidev.idealco.fr'
  },
  video: {
    autoStart: env.REACT_APP_EXHIBITOR_AUTO_START === 1,
    svgEditable: true
  },
  broadcast: {
    stream: env.REACT_APP_BROADCAST_STREAM || ''
  },
  toastify: {
    duration: 2500
  },
  list: {
    itemsPerPage: env.REACT_APP_ITEMS_PER_PAGE || 15,
    contacts: env.REACT_APP_CONTACTS_SIZE || 100,
    locations: env.REACT_APP_LOCATIONS_SIZE || 500
  },
  eventGroupId: env.REACT_APP_EVENT_GROUP_ID || '',
  disableFeatureContacts: env.REACT_APP_DISABLE_FEATURE_CONTACTS === 1 || false,
  disableFeatureChat: env.REACT_APP_DISABLE_FEATURE_CHAT === 1 || false,
  useUserRole: env.REACT_APP_USE_USER_ROLE || '1,2,3',
  eventLanguages: env.REACT_APP_EVENT_LANGUAGES || 'fr',
  meetingLocation: env.REACT_APP_ONSITE_MEETNG_LOCATION || false,
  pusher: {
    id: env.REACT_APP_PUSHER_ID || '',
    auth: env.REACT_APP_PUSHER_AUTH_URL || 'https://www.idealco.fr'
  },
  gtmId: 'GTM-W6M3FGW', // 'GTM-T9LLDMF',
  chromeExtensionId: env.CHROME_EXTENSION_ID || 'idealco',
  profilingUrl: 'https://profiling.idealco.fr',
  theme: (() => {
    switch (env.REACT_APP_THEME) {
      case 'longevity':
        return {
          name: 'longevity',
          light: false,
          header: {
            title: 'Longevity - EventCo',
            description: `Longevity - EventCo`,
            url: 'https://longevity.idealco.fr/',
            image: '/logos/logo-longevity.png',
            banner: '/banners/banner-longevity.png?v=9'
          },
          colors: {
            primaryR: 5,
            primaryG: 156,
            primaryB: 145,
            secondaryR: 211,
            secondaryG: 84,
            secondaryB: 48
          }
        }
      case 'longevity-martinique':
        return {
          name: 'longevity-martinique',
          light: false,
          header: {
            title: 'Longevity - EventCo',
            description: `Longevity - EventCo`,
            url: 'https://longevity-martinique.idealco.fr/',
            image: '/logos/logo-longevity.png?v=2',
            banner: '/banners/banner-longevity-martinique.png?v=4'
          },
          colors: {
            primaryR: 5,
            primaryG: 156,
            primaryB: 145,
            secondaryR: 211,
            secondaryG: 84,
            secondaryB: 48
          },
          onboarding: {
            video: 'https://vimeo.com/570184333'
          }
        }
      case 'irises':
        return {
          name: 'irises',
          light: false,
          header: {
            title: 'Irises - EventCo',
            description: `Les Irisés : tous les ans, un Forum d’Information sur les Risques Majeurs pour mieux prendre en compte dans les collectivités la prévention et la sensibilisation autour des risques majeurs.`,
            url: 'https://irises.idealco.fr/',
            image: '/logos/logo-irises.png?v=1',
            banner: '/banners/banner-irises.jpg?v=2'
          },
          colors: {
            primaryR: 72,
            primaryG: 38,
            primaryB: 132,
            secondaryR: 20,
            secondaryG: 174,
            secondaryB: 228
          }
        }
      case 'bienvieillirsudgironde':
        return {
          name: 'bienvieillirsudgironde',
          light: false,
          header: {
            title: 'Bien vieillir Sud Gironde - EventCo',
            description: `Bien vieillir Sud Gironde - EventCo`,
            url: 'https://bienvieillirsudgironde.idealco.fr/',
            image: '/logos/logo-bienvieillirsudgironde.png'
          },
          colors: {
            primaryR: 0,
            primaryG: 100,
            primaryB: 160,
            secondaryR: 239,
            secondaryG: 125,
            secondaryB: 0
          }
        }
      case 'convention-annuelle-xylem':
        return {
          name: 'convention-annuelle-xylem',
          light: true,
          header: {
            title: 'Convention annuelle Xylem - EventCo',
            description: `Convention annuelle Xylem - EventCo`,
            url: 'https://convention-annuelle-xylem.idealco.fr/',
            image: '/logos/logo-convention-annuelle-xylem.png'
          },
          colors: {
            primaryR: 0,
            primaryG: 133,
            primaryB: 173,
            secondaryR: 0,
            secondaryG: 154,
            secondaryB: 68
          }
        }
      case 'lmde':
        return {
          name: 'lmde',
          light: true,
          header: {
            title: 'LMDE - EventCo',
            description: `LMDE - EventCo`,
            url: 'https://lmde.idealco.fr/',
            image: '/logos/logo-lmde.png'
          },
          colors: {
            primaryR: 103,
            primaryG: 136,
            primaryB: 255,
            secondaryR: 55,
            secondaryG: 55,
            secondaryB: 89
          }
        }
      case 'in':
        return {
          name: 'in',
          light: true,
          header: {
            title: 'IN - EventCo',
            description: `IN - EventCo`,
            url: 'https://in.idealco.fr/',
            image: '/logos/logo-in.png'
          },
          colors: {
            primaryR: 103,
            primaryG: 136,
            primaryB: 255,
            secondaryR: 55,
            secondaryG: 55,
            secondaryB: 89
          }
        }
      case 'forum-16-25':
        return {
          name: 'forum-16-25',
          light: true,
          header: {
            title: 'Forum 16/25 - EventCo',
            description: `Forum 16/25 - EventCo`,
            url: 'https://forum-16-25.idealco.fr/',
            image: '/logos/logo-forum-16-25.png'
          },
          colors: {
            primaryR: 103,
            primaryG: 136,
            primaryB: 255,
            secondaryR: 55,
            secondaryG: 55,
            secondaryB: 89
          }
        }
      case 'cgle':
        return {
          name: 'cgle',
          light: false,
          header: {
            title: `Carrefour de l'Eau 2022`,
            description: `Rencontrez tous les acteurs de l'eau. En direct. Les 29 et 30 juin 2022, participez au 23ème Carrefour de l'Eau à Rennes ou en digital : 13000 participants, 130 conférences, 500 exposant`,
            url: 'https://cgle.idealco.fr/',
            image: '/logos/logo-cgle.png',
            banner: '/banners/banner-cgle.jpg?v=5'
          },
          colors: {
            primaryR: 39,
            primaryG: 130,
            primaryB: 186,
            secondaryR: 230,
            secondaryG: 0,
            secondaryB: 126
          },
          onboarding: {
            video: 'https://vimeo.com/719840832'
          }
        }
      case 'carrefourco':
        return {
          name: 'carrefourco',
          light: false,
          locales: 'carrefourco',
          header: {
            title: `Carrefour CO`,
            description: `Carrefour CO`,
            url: 'https://carrefourco.idealco.fr/',
            image: '/logos/logo-carrefourco.png',
            banner: '/banners/banner-carrefourco.png?v=1'
          },
          colors: {
            primaryR: 70,
            primaryG: 48,
            primaryB: 84,
            secondaryR: 70,
            secondaryG: 67,
            secondaryB: 65
          },
          onboarding: {
            video: 'https://vimeo.com/648501731'
          }
        }
      case 'isarm':
        return {
          name: 'isarm',
          light: false,
          header: {
            title: `ISARM`,
            description: `ISARM`,
            url: 'https://isarm.idealco.fr/',
            image: '/logos/logo-isarm.png',
            banner: '/banners/banner-isarm.png?v=1'
          },
          colors: {
            primaryR: 103,
            primaryG: 136,
            primaryB: 255,
            secondaryR: 55,
            secondaryG: 55,
            secondaryB: 89
          }
        }
      case 'meetup-solideo':
        return {
          name: 'meetup-solideo',
          light: true,
          appointment: true,
          header: {
            title: 'Meetup solideo',
            description: `Meetup solideo`,
            url: 'https://meetup-solideo.idealco.fr/',
            image: '/logos/logo-meetup-solideo.jpg',
            banner: '/banners/banner-meetup-solideo.png'
          },
          colors: {
            primaryR: 0,
            primaryG: 55,
            primaryB: 97,
            secondaryR: 249,
            secondaryG: 176,
            secondaryB: 51
          }
        }
      case 'assises-nationales-marche-en-ville':
        return {
          name: 'assises-nationales-marche-en-ville',
          light: false,
          header: {
            title: `Assises nationales marche en ville`,
            description: `Assises nationales marche en ville`,
            url: 'https://assises-nationales-marche-en-ville.idealco.fr/',
            image: '/logos/logo-assises-nationales-marche-en-ville.png',
            banner: '/banners/banner-assises-nationales-marche-en-ville.png?v=1'
          },
          colors: {
            primaryR: 103,
            primaryG: 136,
            primaryB: 255,
            secondaryR: 55,
            secondaryG: 55,
            secondaryB: 89
          }
        }
      case 'bouches-du-rhone-randonnee':
        return {
          name: 'bouches-du-rhone-randonnee',
          light: false,
          header: {
            title: `Bouches-du-rhône randonnée`,
            description: `Bouches-du-rhône randonnée`,
            url: 'https://bouches-du-rhone-randonnee.idealco.fr/',
            image: '/logos/logo-bouches-du-rhone-randonnee.jpg',
            banner: '/banners/banner-bouches-du-rhone-randonnee.png?v=1'
          },
          colors: {
            primaryR: 103,
            primaryG: 136,
            primaryB: 255,
            secondaryR: 55,
            secondaryG: 55,
            secondaryB: 89
          }
        }
      case 'territorialis':
        return {
          name: 'territorialis',
          light: false,
          header: {
            title: `Territorialis`,
            description: `Territorialis`,
            url: 'https://territorialis.idealco.fr/',
            image: '/logos/logo.png',
            banner: '/banners/banner-territorialis.png?v=1'
          },
          colors: {
            primaryR: 103,
            primaryG: 136,
            primaryB: 255,
            secondaryR: 55,
            secondaryG: 55,
            secondaryB: 89
          }
        }
      case 'journee-regionale-des-aidants':
        return {
          name: 'journee-regionale-des-aidants',
          light: false,
          header: {
            title: `Journée régionale des aidants`,
            description: `Journée régionale des aidants`,
            url: 'https://journee-regionale-des-aidants.idealco.fr/',
            image: '/logos/logo.png',
            banner: '/banners/banner-journee-regionale-des-aidants.jpg?v=1'
          },
          colors: {
            primaryR: 103,
            primaryG: 136,
            primaryB: 255,
            secondaryR: 55,
            secondaryG: 55,
            secondaryB: 89
          }
        }
      case 'eaumega':
        return {
          name: 'Eaumega',
          light: false,
          locales: 'eaumega',
          header: {
            title: `Eaumega`,
            description: `Eaumega`,
            url: 'https://eaumega.idealco.fr/',
            image: '/logos/logo-eaumega.png',
            banner: '/banners/banner-eaumega.png?v=3',
            image_en: '/logos/logo-eaumega-en.jpg',
            banner_en: '/banners/banner-eaumega-en.png?v=1'
          },
          colors: {
            primaryR: 0,
            primaryG: 52,
            primaryB: 80,
            secondaryR: 145,
            secondaryG: 200,
            secondaryB: 65
          }
        }
      case 'rpp-dakar2022':
        return {
          name: 'RPP Dakar2022',
          light: false,
          header: {
            title: `RPP Dakar2022`,
            description: `RPP Dakar2022`,
            url: 'https://rpp-dakar2022.idealco.fr/',
            image: '/logos/logo-rpp-dakar2022.png'
          },
          colors: {
            primaryR: 28,
            primaryG: 59,
            primaryB: 114,
            secondaryR: 0,
            secondaryG: 164,
            secondaryB: 204
          }
        }
      case 'biodiversite':
        return {
          name: 'Biodiversité',
          light: false,
          header: {
            title: `Biodiversité`,
            description: `Biodiversité`,
            url: 'https://biodiversite.idealco.fr/',
            image: '/logos/logo.png',
            banner: '/banners/banner-biodiversite.jpg?v=2'
          },
          colors: {
            primaryR: 80,
            primaryG: 171,
            primaryB: 50,
            secondaryR: 11,
            secondaryG: 122,
            secondaryB: 191
          }
        }
      case 'seminaire-afa':
        return {
          name: 'seminaire-afa',
          light: false,
          header: {
            title: `Seminaire Agence Française de l'Adoption`,
            description: `Seminaire Agence Française de l'Adoption`,
            url: 'https://seminaire-afa.idealco.fr/',
            image: '/logos/logo-seminaire-afa.png'
          },
          colors: {
            primaryR: 47,
            primaryG: 153,
            primaryB: 193,
            secondaryR: 223,
            secondaryG: 58,
            secondaryB: 160
          }
        }
      case 'rdv-expert-cgle':
        return {
          name: 'rdv-expert-cgle',
          light: false,
          header: {
            title: 'Rendez-vous experts CGLE',
            description: '',
            url: 'https://rdv-expert-cgle.idealco.fr/',
            image: '/logos/logo-rdv-expert-cgle.png',
            banner: '/banners/banner-rdv-expert-cgle.jpg'
          },
          colors: {
            primaryR: 39,
            primaryG: 130,
            primaryB: 186,
            secondaryR: 230,
            secondaryG: 0,
            secondaryB: 126
          }
        }
      case 'achats-impactants':
        return {
          name: 'achats-impactants',
          light: true,
          appointment: true,
          header: {
            title: 'Tournée des Achats Impactants',
            description: `Tournée des Achats Impactants`,
            url: 'https://achats-impactants.idealco.fr',
            image: '/logos/logo-achats-impactants.jpg',
            banner: '/banners/banner-achats-impactants.png?v=4'
          },
          colors: {
            primaryR: 99,
            primaryG: 99,
            primaryB: 99,
            secondaryR: 251,
            secondaryG: 231,
            secondaryB: 20
          }
        }
      case 'achats-locaux':
        return {
          name: 'achats-locaux',
          light: true,
          appointment: true,
          header: {
            title: 'Achats Locaux',
            description: `Boostez vos démarches commerciales B to B`,
            url: 'https://achats-locaux.idealco.fr',
            image: '/logos/logo-achats-locaux.png',
          },
          colors: {
            primaryR: 99,
            primaryG: 99,
            primaryB: 99,
            secondaryR: 251,
            secondaryG: 231,
            secondaryB: 20
          }
        }
      case 'tournee-achats':
        return {
          name: 'tournee-achats',
          light: true,
          appointment: true,
          header: {
            title: 'Tournée des Achats Impactants',
            description: `Tournée des Achats Impactants`,
            url: 'https://tournee-achats-impactants.idealco.fr',
            image: '/logos/logo-tournee-achats.jpg',
            banner: '/banners/banner-tournee-achats.png'
          },
          colors: {
            primaryR: 99,
            primaryG: 99,
            primaryB: 99,
            secondaryR: 251,
            secondaryG: 231,
            secondaryB: 20
          }
        }
      default:
        return {
          name: 'eventco',
          light: false,
          header: {
            title: 'EventCo',
            description: `Démo EventCo app`,
            url: 'https://eventco-demo.idealco.fr/',
            image: '/logos/logo.png'
          },
          colors: {
            primaryR: 103,
            primaryG: 136,
            primaryB: 255,
            secondaryR: 55,
            secondaryG: 55,
            secondaryB: 89
          }
        }
    }
  })()
}
